import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import {  createGiftItem, createMultipleGiftItems, listGiftTypes } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function AddGiftItem() {
    const classes = useStyles();
    const [values, setValues] = useState({
        title: "",
        code: "",
        image: "",
        giftType: "",
        error: "",
        redirectToReferrer: false,
        formData: ""
    });
    const [mood, setMood] = useState({
        single: true,
        multiple: false
    })
    const [giftTypes, setGiftTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();
    const { title, code, image, giftType, error, redirectToReferrer, formData } = values;
    const { single, multiple } = mood;

    useEffect(() => {
        setValues({ ...values, formData: new FormData() });
        async function fetchData() {
            await listGiftTypes(user._id, token)
                .then(res => {
                    setGiftTypes(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const handleChange = name => event => {
        if (single) {
            setValues({ ...values, error: false, [name]: event.target.value });
        } else {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.set(`giftItems`, event.target.files[i]);
            }
        }
    };

    const handleCheckBoxChange = name => event => {
        if (name == "single") {
            setMood({ 'single': event.target.checked, 'multiple': !event.target.checked });
        } else {
            setMood({ 'single': !event.target.checked, 'multiple': event.target.checked });
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (!single && !multiple) {
            return;
        }
        setLoading(true);
        if (single) {
            createGiftItem({ title, code, image, giftType }, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: dict[data.error], redirectToReferrer: false });
                    } else {
                        setValues({
                            title: "",
                            code: "",
                            image: "",
                            giftType: "",
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        } else {
            createMultipleGiftItems(formData, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: data.error, redirectToReferrer: false });
                    } else {
                        setValues({
                            title: "",
                            code: "",
                            image: "",
                            giftType: "",
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        }
    }

    const giftTypeSelector = () => {
        return (
            <select onChange={handleChange("giftType")} className="inputField form-control" value={giftType}>
                <option value={null}></option>
                {
                    giftTypes.sort((a, b) => a.value - b.value).map((t, index) => {
                        return (
                            <option key={index} value={t._id}>{t.name}</option>
                        )
                    })

                }
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/gift-items', state: { prevPath: "create", error } }} />
            )
        }
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const singleGift = () => {
        return (
            <div className="signleCategoryForm" style={{ display: single ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["title"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("title")} type="text" value={title} className="inputField form-control" id="name" placeholder={dict["title"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["code"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("code")} type="text" value={code} className="inputField form-control" id="name" placeholder={dict["code"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("image")} type="text" value={image} className="inputField form-control" id="name" placeholder={dict["image"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["gift_type"]} <span className="redStar">*</span></label><br />
                {giftTypeSelector()}
            </div>
        )
    }

    const multipleGifts = () => {
        return (
            <div className="multipleCategoriesForm" style={{ display: multiple ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="photo" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_gift"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("single")} type="checkbox" checked={single} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_one_gift"]}</label>
                    </div>
                    {singleGift()}
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("multiple")} type="checkbox" checked={multiple} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_multiple_gifts"]}</label>
                    </div>
                    {multipleGifts()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="333"
            data={form}
        />
    )
}

export default AddGiftItem;