import React, { useState, useEffect } from "react";
import { signin, authenticate, isAuthenticated, isAdmin } from "../../auth"
import * as qs from 'query-string';
import { Redirect } from "react-router-dom";
import { dict } from "../../dictionary";
import { default_url } from "../../index"
import { getCartFromDB, getCart, updateCartOnDB } from "../cartHelpers";
import { getWishlistFromDB } from "../wishlistHelpers";
import { store } from 'react-notifications-component';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function SignIn(props) {
    const classes = useStyles();
    const [values, setValues] = useState({
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        showActivationSuccess();
        showSignupSuccess();
        window.scrollTo(0, 0);
    }, [])

    const { email, password, error, redirectToReferrer } = values;

    const arDir = {
        direction: "rtl"
    }
    const enDir = {
        direction: "ltr"
    }

    const arStyleAlert = {
        textAlign: "right",
    }

    const enStyleAlert = {
        textAlign: "left",
    }

    const signinForm = () => {
        return (
            <div>
                <h3 className="formContainerTitle" dir={default_url == "/en/" ? "ltr" : "rtl"}>{dict["login_or_create_an_account"]}</h3>
                {showError()}
                <form>
                    <input dir="auto" onChange={handleChange("email")} className="inputField form-control" type="email" name="user-email" value={email} placeholder={dict["email"]} required /><br />
                    <input dir="auto" onChange={handleChange("password")} className="inputField form-control" type="password" name="user-password" value={password} placeholder={dict["password"]} required /><br />
                    <div className="forgetPass">
                        <a href={default_url+"forgetpassword"}>{dict["forget_password?"]}</a>
                    </div>
                    <div className="createAccount">
                        <a href={default_url + "signup"}>{dict["don't_have_an_account?_resister_now!"]}</a>
                    </div>
                    <button onClick={clickSubmit} type="submit" className="loginBtn" style={{ marginBottom: "30px" }}>{dict["login"]}</button>
                    <div className="row loginWithContainer" style={{ display: "none" }}>
                        <div className="col-6">
                            <a className="fcLogin" href="#">
                                <i className="fab fa-facebook-f"></i>
                                <span>{dict["login_with_facebook"]}</span>
                            </a>
                        </div>
                        <div className="col-6">
                            <a className="googleLogin" href="#">
                                <i className="fab fa-google"></i>
                                <span>{dict["login_with_google"]}</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });
        setLoading(true);
        signin({ email, password })
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false });
                } else {
                    authenticate(data, () => {
                        getCartFromDB(() => {
                            getWishlistFromDB(() => {
                                setValues({
                                    ...values,
                                    redirectToReferrer: true
                                });
                            })
                        });
                    })
                }
                setLoading(false);
            })
    }

    const showError = () => {
        return <h6 className="signinError" style={{ display: error ? "" : "none" }}>{dict[error]}</h6>
    }

    const showSignupSuccess = () => {
        if (props.location.state && props.location.state.prevPath === "signup") {
            store.addNotification({
                message: dict["new_account_has_been_created_._please_signin"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const showActivationSuccess = () => {
        if (props.location.state && props.location.state.prevPath === "activate") {
            store.addNotification({
                message: dict["your_acount_has_been_activated._please_signin."],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            window.location.href = default_url
        }
    }

    if (isAuthenticated()) {
        if (isAuthenticated().user.role === 1) {
            window.location.href = default_url + "admin/account-information"
        } else if (isAuthenticated().user.role === 0) {
            window.location.href = default_url + "user/account-information"
        }else if (isAuthenticated().user.role === 2){
            window.location.href = default_url + "storage/account-information"
        }

    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <div className="signinContainer">
            {redirectUser()}
            {showBackdrop()}
            {signinForm()}
        </div>
    )
}

export default SignIn;