import React, { useState, useEffect, Fragment } from 'react';
import { default_url, dir } from "../index"
import { dict, areas, ar_dic, en_dic } from '../dictionary';
import { getCart, clearCart } from "./cartHelpers";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { getOrderWithUser } from './apiCore';
import { isAuthenticated } from '../auth';
import { Redirect } from 'react-router-dom';
import { IMAGE_URL } from '../config';
import { sha256 } from 'js-sha256';

function ThankYou(props) {
    const [products, setProducts] = useState([]);
    const [orderNumber, setOrderNumber] = useState();
    const [addressAsString, setAddressAsString] = useState("");
    const [total, setTotal] = useState(0);
    const [delivery, setDelivery] = useState(2.000);
    const [subtotal, setSubTotal] = useState();
    const [discount, setDiscount] = useState();
    const [grandTotal, setGrandTotal] = useState(0);
    const [discountType, setDiscountType] = useState();
    const [loading, setLoading] = useState(true);
    const [gift, setGift] = useState({ found: false, name: null, image: null });
    const [freeItems, setFreeItems] = useState([]);
    const [error, setError] = useState(false);
    let isMobile = window.innerWidth < 1024;

    useEffect(() => {
        if (props.location.state) {
            getOrderWithUser("orderNumber", props.location.state.orderNumber).then(response => {
                if (response.error) {
                    console.log(response.error);
                } else {                    
                    setAddressAsString(default_url === "/en/" ? response.enAddress : response.arAddress);
                    setTotal(fixLength(response.amount + response.discount - response.delivery));
                    setSubTotal(fixLength(parseFloat(response.amount) + parseFloat(response.discount)));
                    setGrandTotal(fixLength(response.amount));
                    setDelivery(fixLength(response.delivery));
                    setDiscount(fixLength(response.discount));
                    setDiscountType(response.discountType);
                    setProducts(response.products);
                    setOrderNumber((response.orderNumber));
                    setLoading(false);
                    window.ttq.identify({
                        "email": response.email ? sha256(response.email) : "", // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                        "phone_number": response.user ? response.user.mobile ? sha256(response.user.mobile) : "" : "",
                        "external_id": response.user ? sha256(response.user._id) : "" // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
                    });
                    let contents = [];
                    for (let i = 0; i < response.products.length; i++) {
                        contents.push({
                            "content_type": "product", // string. Either product or product_group.
                            "content_name": response.products[i].itemCode, // string. The name of the page or product. Example: "shirt".
                            "quantity": response.products[i].count
                        })
                    }
                    window.ttq.track('PlaceAnOrder', {
                        "contents": contents,
                        "value": response.amount, // number. Value of the order or items sold. Example: 100.
                        "currency": "KWD" // string. The 4217 currency code. Example: "USD".
                    });
                    if (response.gift && response.gift.id) {
                        setGift({ found: true, name: response.gift.name, image: response.gift.image });
                    }
                    if (response.freeItems && response.freeItems.length > 0) {
                        setFreeItems([...response.freeItems]);
                    }
                }
            });
        } else {
            setError(true);
        }

    }, []);

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const showItems = () => {
        return (
            products.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <a href={item.link}>
                                <img className="checkout-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                            </a>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info" style={{ maxWidth: "88%" }}>{default_url === "/en/" ? item.enName : item.arName}</h6>
                                    {item.color !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Color " : "اللون "} {item.color}</h6>}
                                    {item.size !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Size " : "حجم "} {item.size}</h6>}
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-75px" : "-60px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "12px" }}
                                    >
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", textDecoration: item.discountPrice ? "line-through" : "" }}>{(fixLength(item.price)) + " " + dict['kd']}</h6>
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", display: item.discountPrice ? "" : "none", color: "red" }}>{fixLength(item.discountPrice) + " " + dict['kd']}</h6>

                                        <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{item.count + " " + dict["piece"]}</h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-120px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "13px" }}
                                    >

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const showGift = () => {
        return (
            <Fragment>
                <div className="cart-item">
                    <img className="checkout-item-image" src={IMAGE_URL + gift.image + "_small.jpg"}></img>
                    <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                        <div className="cart-item-info-container">
                            <h6 className="cart-item-info">{gift.name}</h6>
                        </div>
                    </div>
                    <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                        <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-75px" : "-60px" }}>
                            <div
                                className="cart-item-info cart-item-info-remove"
                                style={{ fontSize: "12px" }}
                            >
                                <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_gift"]}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </Fragment>
        );
    }

    const showFreeItems = () => {
        return (
            freeItems.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <img className="checkout-item-image" src={IMAGE_URL + item.childImage + "_small.jpg"}></img>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info">{default_url == "/en/" ? item.childEnTitle : item.childArTitle}</h6>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-75px" : "-60px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "12px" }}
                                    >
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_item"]}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                )

            })
        )
    }


    const showPaymentResponse = () => {
        return showPaymentSuccess();
    }

    const showPaymentSuccessSummary = () => {
        return (
            <div style={{ textAlign: "end", padding: "0 10px" }}>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["total"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{total + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["delivery"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{(Math.round(delivery * 1000) / 1000).toFixed(3) + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["subtotal"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{subtotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "13px", color: "red" }}>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount && discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "left" : "right" }}>{discountType == "points" ? dict['points_discount'] : dict["promo_discount"]}</div>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount && discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "" : "" }}>{discount + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "left" : "right", fontFamily: "sans-serif" }}>{dict["grand_total"]}</div>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block" }}>{grandTotal + " " + dict['kd']}</div>
                </div>
            </div>
        )
    }

    const showDeliveryAddress = () => {
        let deliveryAddress = (addressAsString).split("\n");
        return (
            <div style={{ textAlign: "start", padding: "0 10px", marginBottom: isMobile ? "50px" : "" }}>
                <h1 style={{ fontSize: "18px", fontWeight: "900", fontFamily: "sans-serif", marginBottom: "10px" }}>{dict['delivery_address']}</h1>
                {
                    deliveryAddress.map((line, i) => {
                        return <h1 key={i} style={{ marginBottom: i != deliveryAddress.length - 1 ? "6px" : "0" }}>{line}</h1>
                    })
                }
            </div>
        )
    }

    const showPaymentSuccess = () => {
        return (
            <div className="checkout-response-container">
                <CheckCircleOutlineIcon style={{ width: "100px", height: "100px", color: "#E4BC4B", marginBottom: "10px" }} />
                <h1 style={{ fontSize: "22px", fontWeight: "900", fontFamily: "sans-serif" }}>{dict['your_order_has_been_placed_please_keep_your_order_number']}</h1>
                {orderNumber && <h1 style={{ fontSize: "18px", marginTop: "8px", fontFamily: "sans-serif" }}>{dict['your_order_number_is']} {orderNumber}</h1>}
                <hr />
                {showItems()}
                {gift.found && showGift()}
                {freeItems.length > 0 && showFreeItems()}
                {showPaymentSuccessSummary()}
                <hr />
                {addressAsString != null && addressAsString.length > 0 && showDeliveryAddress()}
            </div>
        )
    }

    return (
        <div className="container-fluid" style={{ margin: isMobile ? "0" : "15px 0 70px", padding: isMobile ? "0" : "15px" }}>
            {error && <Redirect to={{ pathname: default_url }} />}
            {!loading && showPaymentResponse()}
        </div>
    )
}

export default ThankYou;