import { API } from "../../config.js"
import { saveAs } from 'file-saver';
import axios from 'axios';

export const createGiftType = (giftType, userId, token) => {
    return fetch(`${API}/gift-type/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(giftType)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateGiftType = (giftType, userId, token) => {
    return fetch(`${API}/gift-type/${giftType._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(giftType)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteGiftType = (giftTypeId, userId, token) => {
    return fetch(`${API}/gift-type/${giftTypeId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listGiftTypes = (userId, token) => {
    return fetch(`${API}/gift-types/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getGiftType = (giftTypeId, userId, token) => {
    return fetch(`${API}/gift-type/${giftTypeId}/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createGiftItem = (giftItem, userId, token) => {
    return fetch(`${API}/gift-item/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(giftItem)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleGiftItems = (giftItems, userId, token) => {
    return fetch(`${API}/gift-item/createMultiple/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: giftItems
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateGiftItem = (giftItem, userId, token) => {
    return fetch(`${API}/gift-item/${giftItem._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(giftItem)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteGiftItem = (giftItemId, userId, token) => {
    return fetch(`${API}/gift-item/${giftItemId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listGiftItems = (userId, token) => {
    return fetch(`${API}/gift-items/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getGiftItem = (giftItemId, userId, token) => {
    return fetch(`${API}/gift-item/${giftItemId}/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}



export const createCity = (city, userId, token) => {
    return fetch(`${API}/city/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(city)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleCities = (cities, userId, token) => {
    return fetch(`${API}/city/createMultiple/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: cities
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateCity = (city, userId, token) => {
    return fetch(`${API}/city/${city._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(city)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteCity = (cityId, userId, token) => {
    return fetch(`${API}/city/${cityId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createArea = (area, userId, token) => {
    return fetch(`${API}/area/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(area)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleAreas = (areas, userId, token) => {
    return fetch(`${API}/area/createMultiple/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: areas
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateArea = (area, userId, token) => {
    return fetch(`${API}/area/${area._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(area)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteArea = (areaId, userId, token) => {
    return fetch(`${API}/area/${areaId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createCategory = (category, userId, token) => {
    return fetch(`${API}/category/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(category)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleCategories = (categories, userId, token) => {
    return fetch(`${API}/category/createMultiple/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: categories
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const updateCategory = (category, userId, token) => {
    return fetch(`${API}/category/${category._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(category)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateCategoryPosition = (oldPosition, newPosition, categoryId, userId, token) => {
    return fetch(`${API}/category/update-position/${categoryId}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({
            oldPosition: oldPosition,
            newPosition: newPosition
        })

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteCategory = (categoryId, userId, token) => {
    return fetch(`${API}/category/${categoryId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createSubcategory = (subcategory, userId, token) => {
    return fetch(`${API}/subcategory/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(subcategory)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateSubcategory = (subcategory, userId, token) => {
    return fetch(`${API}/subcategory/${subcategory._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(subcategory)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateSubcategoryPosition = (oldPosition, newPosition, subcategoryId, category, userId, token) => {
    return fetch(`${API}/subcategory/update-position/${subcategoryId}/${userId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({
            oldPosition: oldPosition,
            newPosition: newPosition,
            category: category
        })

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateManySubcategories = (field, oldValue, newValue, userId, token) => {
    return fetch(`${API}/subcategory/update/many/${userId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({
            field: field,
            oldValue: oldValue,
            newValue: newValue
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteSubcategory = (subcategoryId, userId, token) => {
    return fetch(`${API}/subcategory/${subcategoryId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createProduct = (product, userId, token) => {
    return fetch(`${API}/product/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleProducts = (products, userId, token) => {
    return fetch(`${API}/product/create/by/excel/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateProduct = (product, userId, token) => {
    return fetch(`${API}/product/${product._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateManyProducts = (products, userId, token) => {
    return fetch(`${API}/products/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteProduct = (prductId, userId, token) => {
    return fetch(`${API}/product/${prductId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteMultipleProducts = (products, userId, token) => {
    return fetch(`${API}/product/delete/by/excel/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const exportAllProducts = (userId, token) => {
    return fetch(`${API}/products/export/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadExportedFile = (userId, token) => {
    return axios.get(`${API}/send-exported-file/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/csv' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " exported products.csv"))
        })
        .catch(err => {
            console.log(err);
        });
}

export const listOrders = (userId, token, skip, limit) => {
    return fetch(`${API}/order/list/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({
            skip: skip,
            limit: limit
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const countNewOrders = (userId, token) => {
    return fetch(`${API}/order/count-new-orders/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: "include"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getStatusValues = (userId, token) => {
    return fetch(`${API}/order/status-values/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const updateOrderStatus = (userId, token, orderId, status) => {
    return fetch(`${API}/order/${orderId}/status/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ status: status })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateOrderAddress = (arAddress, enAddress, orderNumber, userId, token) => {
    return fetch(`${API}/order/address/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({
            arAddress: arAddress,
            enAddress: enAddress,
            orderNumber: orderNumber
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const exportOrders = (fromNumber, toNumber, userId, token) => {
    return fetch(`${API}/orders/export/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ fromNumber: fromNumber, toNumber: toNumber })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadExportedOrders = (userId, token) => {
    return axios.get(`${API}/send-exported-orders/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/csv' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " exported orders.csv"))
        })
        .catch(err => {
            console.log(err);
        });
}

export const exportNewOrders = (userId, token) => {
    return fetch(`${API}/orders/new-orders/export/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadExportedNewOrders = (userId, token) => {
    return axios.get(`${API}/send-exported-new-orders/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/csv' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " exported new orders.csv"))
        })
        .catch(err => {
            console.log(err);
        });
}

export const generateManyInvoices = (userId, token) => {
    return fetch(`${API}/generate-many-invoices/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadPrintedNewOrders = (userId, token) => {
    return axios.get(`${API}/send-exported-new-orders/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/csv' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " exported new orders.csv"))
        })
        .catch(err => {
            console.log(err);
        });
}


export const deleteNewArrivalProduct = (productId, userId, token) => {
    return fetch(`${API}/new-arrival/${productId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const addNewArrivalProducts = (products, userId, token) => {
    return fetch(`${API}/new-arrival/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ products: products })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const replaceAllNewArrivals = (products, userId, token) => {
    return fetch(`${API}/new-arrival/replace/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const deleteOffersProduct = (productId, userId, token) => {
    return fetch(`${API}/offers/${productId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const addOffersProducts = (products, userId, token) => {
    return fetch(`${API}/offers/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ products: products })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const replaceAllOffers = (products, userId, token) => {
    return fetch(`${API}/offers/replace/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const deleteAd = (adId, userId, token) => {
    return fetch(`${API}/ads/${adId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const addAdsImage = (image, userId, token) => {
    return fetch(`${API}/ads/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: image
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getTypeOptions = (userId, token) => {
    return fetch(`${API}/coupon/type-options/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createCoupon = (coupon, userId, token) => {
    return fetch(`${API}/coupon/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(coupon)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleCoupons = (coupons, userId, token) => {
    return fetch(`${API}/coupon/create/by/excel/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: coupons
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteCoupon = (couponId, userId, token) => {
    return fetch(`${API}/coupon/${couponId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const updateCoupon = (coupon, userId, token) => {
    return fetch(`${API}/coupon/${coupon._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(coupon)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const createGeneral = (general, userId, token) => {
    return fetch(`${API}/general/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(general)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateGeneral = (general, userId, token) => {
    return fetch(`${API}/general/${general._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(general)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const uploadImages = (images, userId, token) => {
    return fetch(`${API}/images/upload/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: images
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadImages = (userId, token) => {
    return axios.get(`${API}/send-images/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/zip' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " images.zip"))
        })
        .catch(err => {
            console.log(err);
        });
}

export const findUserByEmail = (email, userId, token) => {
    return fetch(`${API}/user/find-by-email/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ email: email })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const generateInvoice = (order, userId, token) => {
    return fetch(`${API}/generate-invoice/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ order: order })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadInvoice = (orderNumber, userId, token) => {
    return axios.get(`${API}/send-invoice/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            saveAs(pdfBlob, orderNumber)
        })
        .catch(err => {
            console.log(err);
        });
}

export const listOrdersByFilters = (userId, token, skip, limit, filters = undefined) => {
    let data = {
        skip,
        limit,
        filters
    }
    return fetch(`${API}/orders/by/search/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createGift = (gift, userId, token) => {
    return fetch(`${API}/gift/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(gift)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listGifts = (userId, token) => {
    return fetch(`${API}/gifts/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteGift = (giftId, userId, token) => {
    return fetch(`${API}/gift/${giftId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getGift = (giftId, userId, token) => {
    return fetch(`${API}/gift/get/${giftId}/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateGift = (gift, userId, token) => {
    return fetch(`${API}/gift/${gift._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(gift)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const exportAllUsers = (userId, token) => {
    return fetch(`${API}/users/export/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadExportedUsers = (userId, token) => {
    return axios.get(`${API}/send-exported-users/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/csv' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " exported users.csv"))
        })
        .catch(err => {
            console.log(err);
        });
}



export const createList = (list, userId, token) => {
    return fetch(`${API}/list/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(list)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const listLists = () => {
    return fetch(`${API}/lists`, {
        method: "GET",
        credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateList = (list, userId, token) => {
    return fetch(`${API}/list/${list._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(list)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const replaceAllProductsInList = (products, listId, userId, token) => {
    return fetch(`${API}/list/replace/${listId}/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteList = (listId, userId, token) => {
    return fetch(`${API}/list/${listId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const listWholesaleRequests = (userId, token) => {
    return fetch(`${API}/wholesales/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const countNewWholesaleRequests = (userId, token) => {
    return fetch(`${API}/wholesale/count-new-wholesale-requests/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateWholesaleRequest = (wholesale, userId, token) => {
    return fetch(`${API}/wholesale/${wholesale._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(wholesale)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const wholesaleByMobile = (mobile, userId, token) => {
    return fetch(`${API}/wholesale/by-mobile/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ mobile: mobile })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const listFeedbacks = (userId, token) => {
    return fetch(`${API}/feedbacks/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const countNewFeedbacks = (userId, token) => {
    return fetch(`${API}/feedback/count-new-feedbacks/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateFeedback = (feedback, userId, token) => {
    return fetch(`${API}/feedback/${feedback._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(feedback)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const feedbackByMobile = (mobile, userId, token) => {
    return fetch(`${API}/feedback/by-mobile/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ mobile: mobile })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createDistributor = (distributor, userId, token) => {
    return fetch(`${API}/distributor/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(distributor)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteDistributor = (distributorId, userId, token) => {
    return fetch(`${API}/distributor/${distributorId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const replaceAllDistributors = (distributors, userId, token) => {
    return fetch(`${API}/distributor/replace/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: distributors
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const exportAllDistributors = (userId, token) => {
    return fetch(`${API}/distributors/export/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const downloadExportedDistributors = (userId, token) => {
    return axios.get(`${API}/send-exported-distributors/${userId}`, {
        responseType: 'blob',
        headers: {
            "Authorization": `Bearer ${token}`
        }, withCredentials: true
    })
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/csv' });
            saveAs(pdfBlob, (new Date().toISOString().slice(0, 10) + " exported distributors.csv"))
        })
        .catch(err => {
            console.log(err);
        });
}

export const createBlog = (blog, userId, token) => {
    return fetch(`${API}/blog/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(blog)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateBlog = (blog, userId, token) => {
    return fetch(`${API}/blog/${blog._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(blog)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteFromBlog = (blogId, userId, token) => {
    return fetch(`${API}/blog/${blogId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createColor = (color, userId, token) => {
    return fetch(`${API}/color/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(color)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateColor = (color, userId, token) => {
    return fetch(`${API}/color/${color._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(color)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteColor = (colorId, userId, token) => {
    return fetch(`${API}/color/${colorId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createCollection = (collection, userId, token) => {
    return fetch(`${API}/collection/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(collection)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateCollection = (collection, userId, token) => {
    return fetch(`${API}/collection/${collection._id}/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(collection)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteCollection = (collectionId, userId, token) => {
    return fetch(`${API}/collection/${collectionId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const listPoints = (userId, token) => {
    return fetch(`${API}/points/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updatePointStatus = (userId, token, pointId, status) => {
    return fetch(`${API}/point/${pointId}/status/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ status: status })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateUsedPointsAdmin = (ids, used, userId, token) => {
    return fetch(`${API}/points/admin/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ ids: ids, used: used })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteUser = (id, userId, token) => {
    return fetch(`${API}/user/delete/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ id: id })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createPoint = (point, userId, token) => {
    return fetch(`${API}/point/create-by-admin/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(point)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createFreeItem = (freeItem, userId, token) => {
    return fetch(`${API}/free-item/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(freeItem)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createMultipleFreeItems = (products, productId, userId, token) => {
    return fetch(`${API}/free-item/create-many/${productId}/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: products
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listAllFreeItems = (parentId, userId, token) => {
    return fetch(`${API}/free-items/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify({ parentId })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteFreeItem = (freeItemId, userId, token) => {
    return fetch(`${API}/free-item/${freeItemId}/${userId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${token}`
        }, credentials: 'include'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}