import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import {  } from "../../apiAdmin"
import { listOrders, getStatusValues, updateOrderStatus, generateInvoice, downloadInvoice, listOrdersByFilters, getGift, listGifts, listPoints, updatePointStatus, updateUsedPointsAdmin, updateOrderAddress, downloadExportedNewOrders, exportNewOrders, generateManyInvoices } from "../../apiAdmin";
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CancelIcon from '@material-ui/icons/Cancel';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PrintIcon from '@material-ui/icons/Print';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';

const useStyles3 = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '&MuiOutlinedInput-input': {
            padding: "10px 14px"
        }
    },
}));

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: "black"
    },
    ExpansionPanelDetails: {
        display: "inline",
        padding: "0"
    },
    panelHeader: {
        backgroundColor: "#f7f7f7"
    }
}));

const ref = React.createRef();
const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2]
};

function OrderAdmin() {
    const classes = useStyles();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const [showLoadingBackdrop, setShowBackdrop] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [statusValues, setStatusValues] = useState([]);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState(null);
    const [filteredStatus, setFilteredStatus] = useState("all_statuses");
    const [gifts, setGifts] = useState([]);
    const [points, setPoints] = useState([]);
    const [openDialog, setOpenDialog] = useState("0");
    const [editableAddress1, setEditableAddress1] = useState("");
    const [editableAddress2, setEditableAddress2] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);


    const { user, token } = isAuthenticated();

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={showLoadingBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const loadOrders = () => {
        listOrdersByFilters(user._id, token, 0, limit, { filters: filter }).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setOrders(data.orders);
                setSize(data.size);
            }
        })
    }

    const loadGift = () => {
        listGifts(user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setGifts(data);
            }
        })
    }

    const loadStatusValues = () => {
        getStatusValues(user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setStatusValues(data);
            }
        });
    }

    const loadPoints = () => {
        listPoints(user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setPoints(data)
            }
        })
    }

    useEffect(() => {
        loadOrders();
        loadStatusValues();
        loadGift();
        loadPoints();
    }, [])

    const handlePrintClick = (order) => e => {
        setShowBackdrop(true);
        generateInvoice(order, user._id, token).then(res => {
            downloadInvoice(order.orderNumber, user._id, token).then(res2 => {
                setShowBackdrop(false);
            });
        });
    }

    const handleChangePanel = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeStatus = (orderId) => (e) => {
        let v = e.target.value;
        updateOrderStatus(user._id, token, orderId, e.target.value).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                let p = null;
                for (let i = 0; i < points.length; i++) {
                    if (points[i].order._id == orderId) {
                        p = points[i];
                        break;
                    }
                }
                if (p) {
                    let s = v;
                    let ns = p.status
                    if (s == 'new_order' || s == 'processing' || s == 'ready_for_delivering' || s == "waiting_for_online_payment") {
                        ns = "pendding"
                    } else if (s == 'delivered') {
                        ns = "active"
                    } else if (s == 'cancelled' || s == "failed") {
                        ns = "inactive"
                    }
                    updatePointStatus(user._id, token, p._id, ns).then(res => {
                        if (res.error) {
                            console.log(res.error);
                        } else {
                            loadPoints();
                        }
                        loadOrdersAfterUpdateStatus();
                    })
                } else {
                    loadOrdersAfterUpdateStatus();
                }

            }
        })
    }

    const handleReturnPoints = (orderNumber, user) => (e) => {
        let ret = e.target.value == "yes" ? true : false;
        let ids = [];
        let usedPoints = [];
        for (let i = 0; i < points.length; i++) {
            if (points[i].user._id == user._id) {
                for (let j = 0; j < points[i].used.length; j++) {
                    if (points[i].used[j].order == orderNumber) {
                        ids.push(points[i]._id);
                        let tmp = [...points[i].used]
                        let tmp2 = { ...points[i].used[j], returned: ret };
                        tmp[j] = { ...tmp2 };
                        usedPoints.push([...tmp]);
                    }
                }
            }
        }
        updateUsedPointsAdmin(ids, usedPoints, isAuthenticated().user._id, token).then((res) => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadPoints();
            }
        })
    }

    const loadOrdersAfterUpdateStatus = () => {
        let newLimit = skip + limit;
        listOrdersByFilters(user._id, token, 0, newLimit, { filters: filter ? filter : filteredStatus != "all_statuses" ? filteredStatus : undefined }).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setOrders(data.orders);
            }
        })
    }

    const handleAddress1Change = () => (e) => {
        setEditableAddress1(e.target.value);
    }

    const handleAddress2Change = () => (e) => {
        setEditableAddress2(e.target.value);
    }

    const handleAddressSave = (orderNumber) => (e) => {
        let arAdress = default_url == "/ar/" ? editableAddress1 : editableAddress2;
        let enAddress = default_url == "/en/" ? editableAddress1 : editableAddress2;
        updateOrderAddress(arAdress, enAddress, orderNumber, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadOrders();
            }
            setOpenDialog(-1);
        })
    }

    const loadMore = () => {
        let toSkip = skip + limit;
        listOrdersByFilters(user._id, token, toSkip, limit, { filters: filteredStatus != "all_statuses" ? filteredStatus : undefined }).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setOrders([...orders, ...data.orders]);
                setSize(data.size);
                setSkip(toSkip);
            }
        })
    }

    const getGiftById = (giftId) => {
        for (let i = 0; i < gifts.length; i++) {
            if (gifts[i]._id == giftId) {
                return gifts[i];
            }
        }
        return false;
    }

    const exportAllNewOrders = (event) => {
        event.preventDefault();
        setShowBackdrop(true);
        exportNewOrders(user._id, token).then(res => {
            if (res.error) {
                setError(res.error);
                showError();
            } else {
                downloadExportedNewOrders(user._id, token).then(res2 => {
                    showAlert();
                    setShowBackdrop(false);
                })
            }
        })
    }

    const printAllNewOrders = (event) => {
        event.preventDefault();
        setShowBackdrop(true);
        generateManyInvoices(user._id, token).then(res => {
            downloadInvoice("orders", user._id, token).then(res2 => {
                setShowBackdrop(false);
            });
        });
    }

    const exportOrders = () => {
        window.location.href = default_url + "admin/orders/export"
    }


    const showError = () => {
        return null;
    }

    const showAlert = () => {
        return null;
    }

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const arStylelabel = {
        display: 'inline-block',
        float: "right",
        fontSize: "18px",
        textAlign: "start"
    }
    const enStylelabel = {
        display: 'inline-block',
        float: "left",
        fontSize: "18px",
        textAlign: "start"
    }

    function formatTime(date) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return (day + "").padStart(2, '0') + "/" + (month + "").padStart(2, '0') + "/" + year;
    }


    const orderDetailsView = (o, oIndex) => {
        let address1 = default_url === "/en/" ? o.enAddress : o.arAddress;
        let address2 = default_url === "/ar/" ? o.enAddress : o.arAddress;
        let total = fixLength(o.amount + o.discount - o.delivery);
        let subtotal = fixLength(parseFloat(o.amount) + parseFloat(o.discount));
        let grandTotal = fixLength(o.amount);
        let delivery = fixLength(o.delivery);
        let discount = fixLength(o.discount);
        let discountType = o.discountType
        return (
            <Fragment>
                <div style={{ marginTop: "20px" }}>
                    {showHeadLine()}
                    {showItems(o.products)}
                    {o.gift && o.gift.id && showGift(o.gift)}
                    {o.gift && (typeof o.gift !== "object") && getGiftById(o.gift) && showGift(getGiftById(o.gift))}
                    {o.freeItems && o.freeItems.length > 0 && showFreeItems(o.freeItems)}
                </div>
                <div style={{ margin: "10px 0 20px" }}>
                    {showSummary(total, delivery, subtotal, discount, discountType, grandTotal, o.paymentMethod, o.status, o.paymentStatus, o.couponCode)}
                </div>
                <hr />
                <div>
                    {showDeliveryAddress(address1, address2, o.orderNumber)}
                </div>
                <hr />
                <div>
                    {showPoints(o._id)}
                </div>
                {
                    discountType == "points" &&
                    <Fragment>
                        <hr />
                        <div>
                            {returnPoints(o.orderNumber, o.user)}
                        </div>
                    </Fragment>
                }
            </Fragment>
        );
    }

    const showHeadLine = () => {
        return (
            <Fragment>
                <div className="cart-item" style={{ minHeight: "0", color: "gray" }}>
                    <div style={{ height: "auto", width: "40%", position: "relative", display: "inline-block" }}>
                        <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                            <h6 className="cart-item-info">{dict['item']}</h6>
                        </div>
                    </div>
                    <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                        <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                            <div
                                className="cart-item-info cart-item-info-remove"
                                style={{ fontSize: "12px" }}
                            >
                                <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{dict["quantity"]}</h6>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                        <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                            <div
                                className="cart-item-info cart-item-info-remove"
                                style={{ fontSize: "12px" }}
                            >
                                <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{dict["price"]}</h6>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "end" }}>
                        <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                            <div
                                className="cart-item-info cart-item-info-remove"
                                style={{ fontSize: "12px" }}
                            >
                                <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{dict['total']}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </Fragment>
        )
    }


    const showItems = (products) => {
        return (
            products.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item" style={{ minHeight: "0" }}>
                            <div style={{ height: "auto", width: "40%", position: "relative", display: "inline-block" }}>
                                <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                    <h6 className="cart-item-info">{item.itemCode}</h6>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                                <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "12px" }}
                                    >
                                        <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{item.count + " " + dict["piece"]}</h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                                <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "12px" }}
                                    >
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", textDecoration: item.discountPrice ? "line-through" : "" }}>{fixLength(item.price) + " " + dict['kd']}</h6>
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", display: item.discountPrice ? "" : "none", color: "red" }}>{fixLength(item.discountPrice) + " " + dict['kd']}</h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "end" }}>
                                <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "12px" }}
                                    >
                                        <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{item.discountPrice ? (fixLength(item.discountPrice * item.count) + " " + dict['kd']) : (fixLength(item.price * item.count) + " " + dict['kd'])}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const showGift = (gift) => {
        return (
            <div className="cart-item" style={{ minHeight: "0" }}>
                <div style={{ height: "auto", width: "40%", position: "relative", display: "inline-block" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <h6 className="cart-item-info">{gift.name}</h6>
                    </div>
                </div>
                <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <div
                            className="cart-item-info cart-item-info-remove"
                            style={{ fontSize: "12px" }}
                        >
                            <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{"1 " + dict["piece"]}</h6>
                        </div>
                    </div>
                </div>
                <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <div
                            className="cart-item-info cart-item-info-remove"
                            style={{ fontSize: "12px" }}
                        >
                            <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_gift"]}</h6>
                        </div>
                    </div>
                </div>
                <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "end" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <div
                            className="cart-item-info cart-item-info-remove"
                            style={{ fontSize: "12px" }}
                        >
                            <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_gift"]}</h6>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        )
    }


    const showFreeItems = (freeItems) => {
        return (
            freeItems.map((item, i) => {
                return (
                    <div className="cart-item" style={{ minHeight: "0" }} key={i}>
                        <div style={{ height: "auto", width: "40%", position: "relative", display: "inline-block" }}>
                            <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                <h6 className="cart-item-info">{item.childItemCode}</h6>
                            </div>
                        </div>
                        <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                            <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                <div
                                    className="cart-item-info cart-item-info-remove"
                                    style={{ fontSize: "12px" }}
                                >
                                    <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{"1 " + dict["piece"]}</h6>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                            <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                <div
                                    className="cart-item-info cart-item-info-remove"
                                    style={{ fontSize: "12px" }}
                                >
                                    <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_item"]}</h6>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "end" }}>
                            <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                                <div
                                    className="cart-item-info cart-item-info-remove"
                                    style={{ fontSize: "12px" }}
                                >
                                    <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_item"]}</h6>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                )
            })
        )
    }

    const showSummary = (total, delivery, subtotal, discount, discountType, grandTotal, paymentMethod, status, paymentStatus, couponCode) => {
        return (
            <div style={{ textAlign: "end", padding: "0 10px" }}>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["total"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{total + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["delivery"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{delivery + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["subtotal"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{subtotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px", color: "red" }}>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "left" : "right" }}>{discountType == "points" ? dict['points_discount'] : dict["promo_discount"]}</div>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount > 0 ? "inline-block" : "none" }}>{discount + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "left" : "right", fontFamily: "sans-serif" }}>{dict["grand_total"]}</div>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block" }}>{grandTotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "left" : "right", fontFamily: "sans-serif" }}>{dict["payment_method"]}</div>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block", color: paymentStatus === true ? "green" : "red" }}>{paymentMethod == "cash" ? dict['cash_(not_paid)'] : paymentMethod == "online" ? paymentStatus == true ? dict['knet_(paid)'] : dict['knet_(not_paid)'] : paymentMethod == "points" ? dict["paid_by_points"] : paymentMethod == "coupon" ? dict['paid_by_coupon'] : dict["free_checkout"]}</div>
                </div>
                <div style={{ display: couponCode ? "block" : "none", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["coupon_code"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{couponCode}</div>
                </div>
            </div>
        )
    }

    const handleDialogClose = () => {
        setOpenDialog(-1);
    }

    const editAddress = (orderNumber) => {
        return (
            <div>
                <Dialog open={openDialog == orderNumber} onClose={handleDialogClose} aria-labelledby="max-width-dialog-title" fullWidth={true} maxWidth={"lg"}>
                    <DialogTitle id="form-dialog-title" style={{ textAlign: "start" }}>{dict['edit_delivery_address']}</DialogTitle>
                    <DialogContent style={{ width: "100%" }}>
                        <TextField
                            fullWidth
                            multiline
                            autoFocus
                            margin="dense"
                            id="address"
                            label={dict["delivery_address_in_arabic"]}
                            value={editableAddress1}
                            onChange={handleAddress1Change()}
                            InputLabelProps={{ style: { left: "auto" } }}
                        />
                        <TextField
                            fullWidth
                            multiline
                            margin="dense"
                            id="address"
                            label={dict["delivery_address_in_english"]}
                            value={editableAddress2}
                            onChange={handleAddress2Change()}
                            InputLabelProps={{ style: { left: "auto" } }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            {dict["cancel"]}
                        </Button>
                        <Button onClick={handleAddressSave(orderNumber)} color="primary">
                            {dict["save"]}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    const showDeliveryAddress = (address1, address2, orderNumber) => {
        let deliveryAddress = (address1).split("\n");
        return (
            <div style={{ textAlign: "start", padding: "0 10px" }}>
                <h1 style={{ fontSize: "18px", fontWeight: "900", fontFamily: "sans-serif", marginBottom: "10px" }}>{dict['delivery_address']} <EditIcon onClick={() => { setOpenDialog(orderNumber); setEditableAddress1(address1); setEditableAddress2(address2) }} /></h1>
                {editAddress(orderNumber)}
                {
                    deliveryAddress.map((line, i) => {
                        return <h1 key={i} style={{ marginBottom: i != deliveryAddress.length - 1 ? "6px" : "0" }}>{line}</h1>
                    })
                }
            </div>
        )
    }

    const showPoints = (id) => {
        return (
            <div style={{ textAlign: "start", padding: "0 10px" }}>
                {
                    points.map((p, i) => {
                        return (
                            p.order._id == id ?
                                <div key={i} style={{ marginBottom: "20px" }}>
                                    <div style={{ marginBottom: "10px" }}>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{dict['earned_points'] + ": "}</span>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{p.amount + " " + dict['points']}</span>
                                    </div>
                                    <div style={{ marginBottom: "10px" }}>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{dict['email'] + ": "}</span>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{p.user.email}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{dict['status'] + ": "}</span>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{p.status}</span>
                                    </div>

                                </div>
                                : <Fragment></Fragment>
                        )
                    })
                }
            </div>
        )
    }

    const returnPoints = (orderNumber, user) => {
        let ret = true;
        for (let i = 0; i < points.length; i++) {
            if (points[i].user && points[i].user._id == user._id) {
                for (let j = 0; j < points[i].used.length; j++) {
                    if (points[i].used[j].order == orderNumber) {
                        ret &= points[i].used[j].returned;
                    }
                }
            }
        }
        return (
            <div style={{ textAlign: "start", padding: "0 10px" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["return_used_points"]}</label><br />
                {returnPointsSelector(orderNumber, user, ret == true ? "yes" : "no")}
            </div>
        )
    }

    const returnPointsSelector = (orderNumber, user, ret) => {
        return (
            <select onChange={handleReturnPoints(orderNumber, user)} className="inputField form-control" value={ret} style={{ fontSize: "14px", width: "100%", margin: "10px 0 10px" }}>
                <option value={"no"}>{dict["no"]}</option>
                <option value={"yes"}>{dict["yes"]}</option>
            </select>
        )
    }


    const statusSelector = (id, status) => {
        return (
            <select onChange={handleChangeStatus(id)} className="inputField form-control" value={status} style={{ fontSize: "14px", width: "100%", margin: "0 0 10px", color: status === "new_order" ? "red" : status === "delivered" ? "green" : status === "processing" ? "#feb72b" : status === "ready_for_delivering" ? "#96bb7c" : "" }}>
                <option value={null}></option>
                {
                    statusValues.map((s, index) => {
                        return (
                            <option key={index} value={s}>{dict[s]}</option>
                        )
                    })

                }
            </select>
        )
    }

    const getDate = (date) => {
        var created_date = new Date(date);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        var year = created_date.getFullYear();
        var month = months[created_date.getMonth()];
        var day = created_date.getDate();
        var hour = created_date.getHours();
        var min = created_date.getMinutes();
        var sec = created_date.getSeconds();
        var date = (day + "").padStart(2, '0') + "/" + (month + "").padStart(2, '0') + "/" + year;
        var timein24 = (hour + "").padStart(2, '0') + ':' + (min + "").padStart(2, '0');

        return (date + " - " + tConvert(timein24))
    }

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            time[0] = (time[0] + "").padStart(2, '0');
        }
        return time.join(''); // return adjusted time or original string
    }

    const orderView = (o, oIndex) => {
        return (
            <ExpansionPanel
                expanded={expanded === ("panel" + oIndex)}
                onChange={handleChangePanel(("panel" + oIndex))}
            >
                <ExpansionPanelSummary
                    className={classes.panelHeader}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.secondaryHeading} style={{ textAlign: "start", width: "30%" }}>
                        <div>
                            {dict['order_number'] + " " + (o.orderNumber)}
                            <div style={{ textAlign: "start", marginTop: "5px", width: "auto" }}>
                                <button
                                    onClick={handlePrintClick(o)}
                                    style={{
                                        border: "none",
                                        background: "transparent"
                                    }}
                                >
                                    <PrintIcon style={{ fontSize: "35px" }} />
                                </button>
                            </div>
                        </div>

                    </Typography>
                    <Typography className={classes.secondaryHeading} style={{ textAlign: "center", width: "35%", color: o.status === "new_order" ? "red" : o.status === "delivered" ? "green" : o.status === "processing" ? "#feb72b" : o.status === "ready_for_delivering" ? "#96bb7c" : "" }}>
                        {/* {
                            dict["status"] + ": " + dict[o.status]
                        }
                        {
                            o.status === "new_order" && <FiberNewIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "cancelled" || o.status === "failed" && <CancelIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "processing" && <AutorenewIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "ready_for_delivering" && <DoneOutlineIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "delivered" && <LocalShippingIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        } */}
                        {statusSelector(o._id, o.status)}


                    </Typography>
                    <Typography className={classes.secondaryHeading} dir="ltr" style={{ width: "30%" }}>{getDate(o.createdAt)}</Typography>
                    {/* <Typography className={classes.secondaryHeading} dir="ltr" style={{ width: "10%" }}>
                        <div style={{ textAlign: "center"}}>
                            <button
                                onClick={handlePrintClick(o)}
                                style={{
                                    border: "none",
                                    background:"transparent"
                                }}
                            >
                                <PrintIcon style={{ fontSize: "30px" }} />
                            </button>
                        </div>
                    </Typography> */}

                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                    {orderDetailsView(o, oIndex)}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }


    const loadMoreBtn = () => {
        return (
            size > 0 && size >= limit && (
                <button onClick={loadMore} type="submit" className="dashboardBtn" style={{ marginBottom: "5px" }}>{dict["show_more"]}</button>
            )
        )
    }

    const exportNewOrdersBtn = () => {
        return (
            <button onClick={exportAllNewOrders} type="submit" className="dashboardBtn" style={{ marginBottom: "0px" }}>{dict["export_new_orders"]}</button>
        )
    }

    const printNewOrdersBtn = () => {
        return (
            <button onClick={printAllNewOrders} type="submit" className="dashboardBtn" style={{ marginBottom: "0px" }}>{dict["print_new_orders"]}</button>
        )
    }

    const exportBtn = () => {
        return (
            <button onClick={exportOrders} type="submit" className="dashboardBtn" style={{ marginBottom: "0px" }}>{dict["export_orders"]}</button>
        )
    }

    const handleFilteredStatusChanged = (e) => {
        setFilteredStatus(e.target.value)
        setFilter(null);
        listOrdersByFilters(user._id, token, 0, limit, { filters: e.target.value == "all_statuses" ? undefined : e.target.value }).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setOrders(res.orders);
                setSize(res.size);
            }
        })
    }

    const statusCheckBoxes = () => {
        return (
            <select onChange={handleFilteredStatusChanged} className="form-control" value={filteredStatus} style={{ fontSize: "14px", height: "56px", width: "40%" }}>
                <option value={"all_statuses"}>{dict["all_statuses"]}</option>
                {
                    statusValues.map((s, index) => {
                        return (
                            <option key={index} value={s}>{dict[s]}</option>
                        )
                    })
                }
            </select>
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSkip(0);
        listOrdersByFilters(user._id, token, 0, limit, { filters: filter }).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setOrders(res.orders);
                setSize(res.size);
            }
        })
    }

    const form = () => {
        return (
            <Fragment>
                {showBackdrop()}
                <div ref={ref} className="userProfileDataContainer" style={{ position: "relative" }}>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                        <form onSubmit={handleSubmit} className={classes3.root} style={{ width: "60%" }} noValidate autoComplete="off">
                            <TextField value={filter} id="standard-basic" label="Order Num OR Mobile" variant="outlined" style={{ margin: "0px 0", direction: "ltr", width: "100%" }} onChange={(event) => { setFilter(event.target.value) }} />
                        </form>
                        {showAlert()}
                        {statusCheckBoxes()}
                    </div>

                    <div>
                        <h3 className="formContainerTitle">{dict["orders_list"]}</h3>
                        <div>
                            {
                                orders.map((o, oIndex) => {
                                    return orderView(o, oIndex);
                                })
                            }
                        </div>
                        {loadMoreBtn()}
                        {exportNewOrdersBtn()}
                        {printNewOrdersBtn()}
                        {exportBtn()}

                    </div>

                </div>

            </Fragment>

        )
    }

    return (
        <AdminDashboard
            active="5"
            data={form}
            categories={[]}
            subcategories={[]}
        />
    )
}

export default OrderAdmin;