import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createGiftType } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import { from } from "form-data";
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function AddGiftType() {
    const classes = useStyles();
    const [values, setValues] = useState({
        name: "",
        value: "",
        numberOfGifts: "",
        showLowerTypes: "",
        error: "",
        redirectToReferrer: false
    });
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();
    const { name, value, numberOfGifts, showLowerTypes, error, redirectToReferrer } = values;
    

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        createGiftType({ name, value, numberOfGifts, showLowerTypes }, user._id, token)
            .then(data => {
                setLoading(false);
                if (data.error) {
                    setValues({ ...values, error: dict[data.error], redirectToReferrer: false });
                } else {
                    setValues({
                        name: "",
                        value: "",
                        numberOfGifts: "",
                        showLowerTypes: "",
                        error: undefined,
                        redirectToReferrer: true
                    });
                }
            })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/gift-types', state: { prevPath: "create", error } }} />
            )
        }
    }

    const showLowerTypesSelector = () => {
        return (
            <select onChange={handleChange("showLowerTypes")} name="showLowerTypes" className="inputField form-control" value={showLowerTypes}>
                <option value=""></option>
                <option value="no">{dict["no"]}</option>
                <option value="yes">{dict["yes"]}</option>
            </select>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const giftTypeForm = () => {
        return (
            <div className="signleCategoryForm">
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["gift_type_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("name")} type="text" value={name} className="inputField form-control" id="name" placeholder={dict["gift_type_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["gift_type_value"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("value")} type="number" value={value} className="inputField form-control" id="name" placeholder={dict["gift_type_value"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["number_of_gifts"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("numberOfGifts")} type="number" value={numberOfGifts} className="inputField form-control" id="name" placeholder={dict["number_of_gifts"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["show_lower_types"]} <span className="redStar">*</span></label><br />
                {showLowerTypesSelector()}
            </div>
        )
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_gift_type"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    {giftTypeForm()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="222"
            data={form}
        />
    )
}

export default AddGiftType;