import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { Redirect } from "react-router-dom";
import { updateCity } from "../../apiAdmin"
import { getCity} from "../../../apiCore"
import * as qs from 'query-string';
import { from } from "form-data";
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";

function UpdateCity() {
    const [values, setValues] = useState({
        _id: "",
        enName: "",
        arName: "",
        error: "",
        redirectToReferrer: false
    });

    const parsed = qs.parse(window.location.search);

    useEffect(() => {
        async function fetchData() {
            const city = await getCity(parsed.city_id)
                .then(res => {
                    if (res.error) {
                        setValues({ ...values, error: res.error, redirectToReferrer: true });
                    } else {
                        setValues({ _id: res._id, enName: res.enName, arName: res.arName });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const { user, token } = isAuthenticated();
    const { _id, enName, arName, error, redirectToReferrer } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        updateCity({ _id, enName, arName }, user._id, token)
            .then(data => {
                console.log(data)
                if (data.error) {
                    setValues({ ...values, error: dict[data.error], redirectToReferrer: false });
                } else {
                    setValues({
                        enName: "",
                        arName: "",
                        error: undefined,
                        redirectToReferrer: true
                    });
                }
            })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" , textAlign: 'start'}}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/cities', state: { prevPath: "edit", error } }} />
            )
        }
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["edit_city"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{"ID"}</label>
                <input type="text" value={_id || ""} className="inputField form-control" disabled ></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city_english_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enName")} type="text" value={enName || ""} className="inputField form-control" id="name" placeholder={dict["city_english_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city_arabic_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("arName")} type="text" value={arName || ""} className="inputField form-control" id="name" placeholder={dict["city_arabic_name"]}></input>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="222"
            data={form}
        />
    )
}

export default UpdateCity;