import React, { useState, useEffect } from "react";
import AdsIndicators from "./ads components/AdsIndicators";
import AdsItem from "./ads components/AdsItem";
import { listAds } from "./apiCore";
import { IMAGE_URL } from "../config"

function AdsContainer() {
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024 ? true : false);

    const loadAds = () => {
        listAds().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setData(res);
                let d1 = [];
                let d2 = [];
                let d3 = [];
                for (let i = 0; i < res.length; i++) {
                    if(res[i].position == 1){
                        d1.push(res[i]);
                    }else if(res[i].position == 2){
                        d2.push(res[i]);
                    }else if(res[i].position == 3){
                        d3.push(res[i]);
                    }
                }
                setData1(d1);
                setData2(d2);
                setData3(d3);
            }
        });
    }

    useEffect(() => {
        loadAds();
    }, [])

    if (window.innerWidth >= 1024) {
        return (
            <div className="container-fluid ads-container" style={{ marginTop: "10px", textAlign: "start", display: "block" }}>
                <div style={{ width: "35%", display: "inline-block", verticalAlign: "top" }}>
                    <div id="carouselExampleIndicators" className="carousel slide ads-slider" data-ride="carousel1" style={{ width: "100%", marginInlineStart: "0", paddingInlineStart: "15px", display: "inline-block", verticalAlign: "top", marginBottom: "20px" }}>
                        <ol className="carousel-indicators">
                            {data1.map((ad, index) => {
                                return (
                                    <AdsIndicators
                                        key={index}
                                        num={index}
                                    />
                                )
                            })}
                        </ol>
                        <div className="carousel-inner">
                            {data1.map((ad, index) => {
                                return (
                                    <AdsItem
                                        key={index}
                                        num={index}
                                        img={IMAGE_URL + ad.image}
                                        link={ad.link}
                                    />
                                )
                            })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                    <br />
                    <div id="carouselExampleIndicators" className="carousel slide ads-slider" data-ride="carousel2" style={{ width: "100%", marginInlineStart: "0", paddingInlineStart: "15px", display: "inline-block", verticalAlign: "top" }}>
                        <ol className="carousel-indicators">
                            {data2.map((ad, index) => {
                                return (
                                    <AdsIndicators
                                        key={index}
                                        num={index}
                                    />
                                )
                            })}
                        </ol>
                        <div className="carousel-inner">
                            {data2.map((ad, index) => {
                                return (
                                    <AdsItem
                                        key={index}
                                        num={index}
                                        img={IMAGE_URL + ad.image}
                                        link={ad.link}
                                    />
                                )
                            })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>

                <div style={{ width: "65%", display: "inline-block", verticalAlign: "top" }}>
                    <div id="carouselExampleIndicators" className="carousel slide ads-slider" data-ride="carousel3" style={{ width: "100%", marginInlineStart: "0", paddingInlineStart: "15px", display: "inline-block", verticalAlign: "top" }}>
                        <ol className="carousel-indicators">
                            {data3.map((ad, index) => {
                                return (
                                    <AdsIndicators
                                        key={index}
                                        num={index}
                                    />
                                )
                            })}
                        </ol>
                        <div className="carousel-inner">
                            {data3.map((ad, index) => {
                                return (
                                    <AdsItem
                                        key={index}
                                        num={index}
                                        img={IMAGE_URL + ad.image}
                                        link={ad.link}
                                    />
                                )
                            })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-fluid ads-container" style={{ marginTop: "0", textAlign: "start", display: "block" }}>
                <div style={{ width: "100%", display: "inline-block", verticalAlign: "top" }}>
                    <div id="carouselExampleIndicators" className="carousel slide ads-slider" data-ride="carousel" style={{ width: "100%", marginInlineStart: "0", paddingInlineStart: "0", display: "inline-block", verticalAlign: "top", marginBottom: "20px" }}>
                        <ol className="carousel-indicators">
                            {data.map((ad, index) => {
                                return (
                                    <AdsIndicators
                                        key={index}
                                        num={index}
                                    />
                                )
                            })}
                        </ol>
                        <div className="carousel-inner">
                            {data.map((ad, index) => {
                                return (
                                    <AdsItem
                                        key={index}
                                        num={index}
                                        img={IMAGE_URL + ad.image}
                                        link={ad.link}
                                    />
                                )
                            })}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdsContainer;