import React, { useState, useEffect } from 'react';
import AdsContainer from "./AdsContainer"
import NewArrivals from "./NewArrivals"
import {Helmet} from "react-helmet";
import {default_url} from "../index"
import Offers from './Offers';
import { getGeneral } from './apiCore';

function Home(){

    const [showOffers, setShowOffers] = useState(false);
    const [showNewArrival, setShowNewArrival] = useState(false);

    const loadShowOffers = () => {
        getGeneral("name", "showOffers").then(res => {
            if(res.error){
                console.log(res.error);
            }else{
                setShowOffers(res);
            }
        })
    }

    const loadShowNewArrival = () => {
        getGeneral("name", "showNewArrival").then(res => {
            if(res.error){
                console.log(res.error);
            }else{
                setShowNewArrival(res);
            }
        })
    }

    useEffect(() => {
        // loadShowNewArrival();
        loadShowOffers();
    }, [])

    return(
        <div>
            <AdsContainer />
            { showOffers.booleanValue &&  <Offers /> }
            {/* { showNewArrival.booleanValue && <NewArrivals /> } */}
        </div>
    )    
}

export default Home;