import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { addOffersProducts } from "../../apiAdmin"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { listProductsByFilters, listCategories, listSubcategoriesByCategory } from "../../../apiCore";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { from } from "form-data";
import { set } from "js-cookie";
import OffersCheckBox from "./OffersCheckBox";


function AddToOffers() {
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();
    const [subcategories, setSubcategories] = useState([]);
    const [subcategory, setSubcategory] = useState();
    const [products, setProducts] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const { user, token } = isAuthenticated();

    const loadCategories = () => {
        listCategories().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setCategories(res);
            }
        })
    }

    const loadSubcategories = (category) => {
        listSubcategoriesByCategory(category).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                if (res.length === 0) {
                    loadProducts({ category: category });
                }
                setSubcategories(res);
            }
        })
    }

    const loadProducts = (filters) => {
        listProductsByFilters(0, 10000000, filters).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setProducts(res.data);
            }
        })
    }

    useEffect(() => {
        loadCategories();
    }, [])

    const handleChange = name => event => {
        if (name === "category") {
            let selectedCategory = event.target.value;
            if (selectedCategory) {
                loadSubcategories(selectedCategory);
            } else {
                setSubcategories([]);
            }
            setCategory(selectedCategory);
        } else if (name === "subcategory") {
            let selectedSubcategory = event.target.value;
            if (selectedSubcategory) {
                loadProducts({ category: category, subcategory: selectedSubcategory });
            } else {
                setProducts([]);
            }
            setSubcategory(selectedSubcategory);
        }
        setError(null);
    };

    const clickSubmit = () => {
        if (productsList.length === 0) {
            setError("please_select_a_product");
        } else {
            addOffersProducts(productsList, user._id, token).then(res => {
                if (res.error) {
                    setError(res.error);
                } else {
                    setSuccess(true);
                    setError(false);
                    setProductsList([]);
                    setSubcategory(null);
                    setCategory("");
                    setSubcategories([]);
                    setProducts([]);
                }
            })
        }
    }

    const setCheckedProducts = (list) => {
        let newList = [];
        list.map(i => {
            newList.push({ product: i });
        });
        setProductsList(newList);
    }

    const showAlert = () => {
        if (error) {
            return (
                <div className="alert alert-danger" style={{ textAlign: 'start' }}>
                    {dict[error] != undefined ? dict[error] : error}
                </div>
            )
        } else if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_product_has_been_added_to_offers.add_more_or']} <a href={default_url + "admin/offers"} style={{ fontSize: "18px" }}>{dict["go_back_to_offers_list"]}</a>
                </div>
            )
        }

    }

    const categoriesSelector = () => {
        return (
            <select onChange={handleChange("category")} className="inputField form-control" value={category}>
                <option value={null}></option>
                {
                    categories.sort((a, b) => a.position - b.position).map((category, index) => {
                        return (
                            <option key={index} value={category._id}>{category.enName + " | " + category.arName}</option>
                        )
                    })
                }
            </select>
        )
    }


    const disabledSubcategoriesSelector = () => {
        return (
            <select onChange={handleChange("subcategory")} className="inputField form-control" value={subcategory} disabled>
                <option value={null}></option>
                {
                    subcategories.sort((a, b) => a.position - b.position).map((subcategory, index) => {
                        return (
                            <option key={index} value={subcategory._id}>{default_url === "/en/" ? subcategory.enName : subcategory.arName ? subcategory.arName : subcategory.enName}</option>
                        )
                    })
                }
            </select>
        )
    }

    const enabledSubcategoriesSelector = () => {
        return (
            <select onChange={handleChange("subcategory")} className="inputField form-control" value={subcategory}>
                <option value={null}></option>
                {
                    subcategories.sort((a, b) => a.position - b.position).map((subcategory, index) => {
                        return (
                            <option key={index} value={subcategory._id}>{default_url === "/en/" ? subcategory.enName : subcategory.arName ? subcategory.arName : subcategory.enName}</option>
                        )
                    })
                }
            </select>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const addNewProductForm = () => {
        return (
            <form>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["category"]} <span className="redStar">*</span></label><br />
                {categoriesSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory"]} <span className="redStar">*</span></label><br />
                {subcategories.length === 0 ? disabledSubcategoriesSelector() : enabledSubcategoriesSelector()}
                {products.length > 0 && <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["products"]} <span className="redStar">*</span></label>}<br />
                {
                    products.length > 0 &&
                    <OffersCheckBox
                        products={products}
                        setCheckedProducts={list => {
                            setCheckedProducts(list);
                        }}
                    />
                }
            </form>
        )

    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["add_new_item_to_offers"]}</h3>
                {addNewProductForm()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="7"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default AddToOffers;