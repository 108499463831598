import React, {useState, useEffect} from "react";
import { isAuthenticated } from "../../../../auth"
import { deleteCategory, deleteCity, updateManySubcategories } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import * as qs from 'query-string';
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";

function DeleteCity(props) {
    const [state, setState] = useState({
        deleted: false,
        error: "",
        redirect: false
    })
    const { user, token } = isAuthenticated();
    const parsed = qs.parse(window.location.search);
    useEffect(() => {
        async function fetchData() {
            if (parsed.city_id) {
              await deleteCity(parsed.city_id, user._id, token)
                    .then(data => {
                        if (data.error) {        
                            setState({...state, error: data.error, redirect: true})
                        }else{
                            setState({...state, error: undefined, redirect: true})
                        }
                    })
            } else {
                setState({error: dict["no_city_is_found"], redirect: true})
            }
        }
        fetchData();
    }, []);

    return (
        <div>
            {state.redirect && <Redirect to={{ pathname: default_url + 'admin/cities', state: { prevPath: "delete", error: state.error } }} />}
        </div>
    )

}

export default DeleteCity;