import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { productsCount } from "../../../apiCore";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline',
    },
    formControl: {
        margin: theme.spacing(0),
    },
    FormGroup: {
        border: "1px solid rgba(0, 0, 0, 1)",
        padding: "5px",
        marginTop: "5px"
    },
    FormGroupSubCheckbox: {
        marginLeft: "15px"
    },
    FormControlLabelSubCheckbox: {
        fontSize: "5px"
    }
}));

const CustemCheckbox = ({ categories, subcategories, categoriesFilter, subcategoriesFilter, numOfProducts1, numOfProducts2 }) => {
    const classes = useStyles();
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [checkedSubcategories, setCheckedSubcategories] = useState([]);

    const handleCategoriesChange = name => event => {
        let newCheked = [...checkedCategories];
        if (checkedCategories.indexOf(name) === -1) {
            newCheked.push(name);
        } else {
            newCheked.splice(checkedCategories.indexOf(name), 1);
            let newSubcategoriesChecked = [...checkedSubcategories];
            subcategories.map(sub => {
                if (sub.category == name && checkedSubcategories.indexOf(sub._id) != -1) {
                    newSubcategoriesChecked.splice(sub._id, 1);
                }
            });
            setCheckedSubcategories(newSubcategoriesChecked);
            subcategoriesFilter(newSubcategoriesChecked);
        }
        setCheckedCategories(newCheked);
        categoriesFilter(newCheked);
    };

    const handleSubcategoriesChange = name => event => {
        let newCheked = [...checkedSubcategories];
        if (checkedSubcategories.indexOf(name) === -1) {
            newCheked.push(name);
        } else {
            newCheked.splice(checkedSubcategories.indexOf(name), 1);
        }
        setCheckedSubcategories(newCheked);
        subcategoriesFilter(newCheked);
    };

    const subCheckbox = (categoryId) => (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup className={classes.FormGroupSubCheckbox}>
                {
                    subcategories.map((sub, i) => {
                        if (sub.category == categoryId) {
                            return (
                                <FormControlLabel
                                    key={i}
                                    className={classes.FormControlLabelSubCheckbox}
                                    control={
                                        <Checkbox
                                            checked={checkedSubcategories.indexOf(sub._id) != -1}
                                            onChange={handleSubcategoriesChange(sub._id)}
                                            name={sub._id}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography
                                            style={{fontSize: "14px", textAlign: "start", marginLeft: "0", marginRight: "0", direction: default_url == '/en/' || (default_url == "/ar/" && !sub.arName) ? "ltr" : "rtl"}}>
                                            {(default_url == '/en/' || (default_url == "/ar/" && !sub.arName) ? sub.enName : sub.arName) + (numOfProducts2 ? " (" + numOfProducts2[sub._id] + ")" : "")}
                                        </Typography>}
                                />
                            )
                        }
                    })
                }
            </FormGroup>
        </FormControl>

    )

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" style={{ textAlign: "start" }}>{dict["categories"]}</FormLabel>
                <FormGroup className={classes.FormGroup}>
                    {
                        categories.sort((a, b) => a.position - b.position).map((c, i) => {
                            return (
                                <Fragment key={i}>
                                    <FormControlLabel
                                        key={i}
                                        control={
                                            <Checkbox
                                                checked={checkedCategories.indexOf(c._id) != -1}
                                                onChange={handleCategoriesChange(c._id)}
                                                name={c._id}
                                                color="primary"
                                            />
                                        }
                                        label={(default_url == "/en/" ? c.enName : c.arName) + (numOfProducts1 ? " (" + numOfProducts1[c._id] + ")" : "")}
                                        style={{ textAlign: "start", marginLeft: "0", marginRight: "0", fontSize: "10px" }}
                                    />
                                    {checkedCategories.indexOf(c._id) != -1 && subCheckbox(c._id)}
                                </Fragment>
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        </div>

    )

};

export default CustemCheckbox;