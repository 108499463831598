import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { dict } from '../../dictionary';
import { default_url } from '../..';
import { IMAGE_URL } from '../../config';

function PaymentMethods({ paymentMethod, grandTotal }) {
  const [value, setValue] = useState('online');

  const handleChange = (event) => {
    setValue(event.target.value);
    paymentMethod(event.target.value)
  };

  return (
    <FormControl component="fieldset" >
      <RadioGroup aria-label="method" name="method" value={grandTotal == 0 ? "" : value} onChange={handleChange} >
        {grandTotal > 0 && <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="online" control={<Radio />} label={<><div style={{ height: "inherit", display: "inline-block" }}>
          <img style={{ height: "30px", marginInlineEnd: "10px" }} src={IMAGE_URL + "KnetLogo.jpg"}></img> <div style={{paddingTop: "10px", display: "contents", position: "absolute", width: "100%"}}>{dict["knet"]}</div>
        </div></>} />}
        {grandTotal > 0 && <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="cash" control={<Radio />} label={<><div style={{ height: "inherit", display: "inline-block" }}>
          <img style={{ height: "30px", marginInlineEnd: "10px" }} src={IMAGE_URL + "cashLogo.jpg"}></img> <div style={{paddingTop: "10px", display: "contents", position: "absolute", width: "100%"}}>{dict["cash_on_delivery"]}</div>
        </div></>} />}

        {grandTotal == 0 && <FormControlLabel disabled style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="online" control={<Radio />} label={<><div style={{ height: "inherit", display: "inline-block" }}>
          <img style={{ height: "30px", marginInlineEnd: "10px" }} src={IMAGE_URL + "KnetLogo.jpg"}></img> <div style={{paddingTop: "10px", display: "inline-block", position: "absolute", width: "100%"}}>{dict["knet"]}</div>
        </div></>} />}
        {grandTotal == 0 && <FormControlLabel disabled style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="cash" control={<Radio />} label={<><div style={{ height: "inherit", display: "inline-block" }}>
          <img style={{ height: "30px", marginInlineEnd: "10px" }} src={IMAGE_URL + "cashLogo.jpg"}></img> <div style={{paddingTop: "10px", display: "contents", position: "absolute", width: "100%"}}>{dict["cash_on_delivery"]}</div>
        </div></>} />}
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentMethods;