import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict, enAreas, areas, cities } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { createGeneral, updateGeneral } from "../../apiAdmin"
import { getGeneral } from "../../../apiCore";
import { store } from 'react-notifications-component';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

function MoreSettings() {
    const classes = useStyles();

    const [announcement, setAnnouncement] = useState({
        _id: "",
        stringValue: "",
        booleanValue: "",
        error: "",
        success: false
    });

    const [minimumCart, setMinimumCart] = useState({
        _id: "",
        numberValue: "",
        error: "",
        success: false
    });

    const [delivery, setDelivery] = useState({
        _id: "",
        numberValue: "",
        error: "",
        success: false
    });

    const [freeDelivery, setFreeDelivery] = useState({
        _id: "",
        numberValue: "",
        error: "",
        success: false
    });

    const [freeDeliveryForSelectedAreas, setFreeDeliveryForSelectedAreas] = useState({
        _id: "",
        numberValue: "",
        error: "",
        success: false
    });

    const [deliveryForSelectedAreas, setDeliveryForSelectedAreas] = useState({
        _id: "",
        numberValue: "",
        error: "",
        success: false
    });

    const [deliveryMessageInArabic, setDeliveryMessageInArabic] = useState({
        _id: "",
        stringValue: "",
        booleanValue: "",
        error: "",
        success: false
    });

    const [deliveryMessageInEnglish, setDeliveryMessageInEnglish] = useState({
        _id: "",
        stringValue: "",
        booleanValue: "",
        error: "",
        success: false
    });

    const [selectedAreas, setSelectedAreas] = useState({
        _id: "",
        arrayValue: [],
        error: "",
        success: false
    });

    const [areasOutsideDelivery, setAreasOutsideDelivery] = useState({
        _id: "",
        arrayValue: [],
        error: "",
        success: false
    });

    const [loading, setLoading] = useState();

    const [city, setCity] = useState("");
    const [city2, setCity2] = useState("");

    const [areasInCity, setAreasInCity] = useState([]);

    const [area, setArea] = useState("");
    const [area2, setArea2] = useState("");

    const { user, token } = isAuthenticated();

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const goToAreas = (event) => {
        event.preventDefault();
        window.location.href = default_url + "admin/areas"
    }

    const goToCities = (event) => {
        event.preventDefault();
        window.location.href = default_url + "admin/cities"
    }
    
    const handleDelete = (index) => () => {
        let areas = [...selectedAreas.arrayValue];
        areas.splice(index, 1);
        setLoading(true);
        updateGeneral({ ...selectedAreas, arrayValue: areas }, user._id, token).then(res => {
            if (res.error) {
                showError(res.error);
            } else {
                setSelectedAreas({ ...selectedAreas, arrayValue: res.arrayValue, error: "", success: true });
                showAlert();
            }
            setLoading(false);
        });

    };

    const handleDeleteAreasOutsideDelivery = (index) => () => {
        let areas = [...areasOutsideDelivery.arrayValue];
        areas.splice(index, 1);
        setLoading(true);
        updateGeneral({ ...areasOutsideDelivery, arrayValue: areas }, user._id, token).then(res => {
            if (res.error) {
                showError(res.error);
            } else {
                setAreasOutsideDelivery({ ...areasOutsideDelivery, arrayValue: res.arrayValue, error: "", success: true });
                showAlert();
            }
            setLoading(false);
        });

    };

    const showAlert = () => {
        store.addNotification({
            message: dict['updated_successfully'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showError = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const addToGeneral = () => {
        // createGeneral({name: "freeDeliveryForSelectedAreas", numberValue: 40}, user._id, token);
        // createGeneral({name: "showOffers", booleanValue: true}, user._id, token);
        // createGeneral({ name: "selectedAreas", arrayValue: ["Subah Al Ahmed", "Um al heiman"] }, user._id, token);
    }

    const loadDelivery = () => {
        getGeneral("name", "delivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDelivery({ _id: res._id, numberValue: fixLength(res.numberValue) });
            }
        });
        getGeneral("name", "freeDelivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setFreeDelivery({ _id: res._id, numberValue: fixLength(res.numberValue) });
            }
        });
        getGeneral("name", "freeDeliveryForSelectedAreas").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setFreeDeliveryForSelectedAreas({ _id: res._id, numberValue: fixLength(res.numberValue) });
            }
        });
        getGeneral("name", "deliveryForSelectedAreas").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDeliveryForSelectedAreas({ _id: res._id, numberValue: fixLength(res.numberValue) });
            }
        });
        getGeneral("name", "selectedAreas").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setSelectedAreas({ _id: res._id, arrayValue: res.arrayValue });
            }
        });
        getGeneral("name", "deliveryMessageInArabic").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDeliveryMessageInArabic({ _id: res._id, stringValue: res.stringValue });
            }
        });
        getGeneral("name", "deliveryMessageInEnglish").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDeliveryMessageInEnglish({ _id: res._id, stringValue: res.stringValue });
            }
        });
        getGeneral("name", "areasOutsideDelivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAreasOutsideDelivery({ _id: res._id, arrayValue: res.arrayValue });
            }
        });
    }

    const loadAnnouncement = () => {
        getGeneral("name", "announcement").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAnnouncement({ _id: res._id, stringValue: res.stringValue, booleanValue: res.booleanValue });
            }
        });
    }

    const loadMinimumCart = () => {
        getGeneral("name", "minimumCart").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setMinimumCart({ _id: res._id, numberValue: res.numberValue });
            }
        });
    }

    useEffect(() => {
        // addToGeneral();
        loadDelivery();
        loadAnnouncement();
        loadMinimumCart();
    }, []);

    const handleChange = (name) => (event) => {
        if (name === "delivery") {
            setDelivery({ ...delivery, numberValue: event.target.value, error: "", success: false });
        } else if (name === "free_delivery") {
            setFreeDelivery({ ...freeDelivery, numberValue: event.target.value, error: "", success: false });
        } else if (name === "free_delivery_for_selected_areas") {
            setFreeDeliveryForSelectedAreas({ ...freeDeliveryForSelectedAreas, numberValue: event.target.value, error: "", success: false });
        } else if (name === "delivery_for_selected_areas") {
            setDeliveryForSelectedAreas({ ...deliveryForSelectedAreas, numberValue: event.target.value, error: "", success: false })
        } else if (name === "area") {
            setArea(event.target.value);
        } else if (name === "city") {
            setAreasInCity(areas[cities[event.target.value]]);
            setCity(event.target.value);
            setArea("");
        } else if (name === "area2") {
            setArea2(event.target.value);
        } else if (name === "city2") {
            setAreasInCity(areas[cities[event.target.value]]);
            setCity2(event.target.value);
            setArea2("");
        } else if (name == "announcement") {
            setAnnouncement({ ...announcement, stringValue: event.target.value, error: "", success: false })
        } else if (name == "activeAnnouncement") {
            setAnnouncement({ ...announcement, booleanValue: event.target.value, error: "", success: false })
        } else if (name == "minimumCart") {
            setMinimumCart({ ...minimumCart, numberValue: event.target.value, error: "", success: false })
        } else if (name == "delivery_message_in_arabic") {
            setDeliveryMessageInArabic({ ...deliveryMessageInArabic, stringValue: event.target.value, error: "", success: false })
        } else if (name == "delivery_message_in_english") {
            setDeliveryMessageInEnglish({ ...deliveryMessageInEnglish, stringValue: event.target.value, error: "", success: false })
        }
    }

    const clickSubmit = (value) => (event) => {
        event.preventDefault();
        setLoading(true);
        updateGeneral(value, user._id, token).then(res => {
            if (res.error) {
                showError(res.error);
            } else {
                if (res.name === "delivery") {
                    setDelivery({ ...delivery, numberValue: fixLength(res.numberValue), error: "", success: true });
                } else if (res.name === "freeDelivery") {
                    setFreeDelivery({ ...freeDelivery, numberValue: fixLength(res.numberValue), error: "", success: true });
                } else if (res.name === "free_delivery_for_selected_areas") {
                    setFreeDeliveryForSelectedAreas({ ...freeDeliveryForSelectedAreas, numberValue: fixLength(res.numberValue), error: "", success: true });
                } else if (res.name === "deliveryForSelectedAreas") {
                    setDeliveryForSelectedAreas({ ...deliveryForSelectedAreas, numberValue: fixLength(res.numberValue), error: "", success: true });
                }
                showAlert();
            }
            setLoading(false);
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleAreaAdd = (src, value) => (event) => {
        event.preventDefault();
        if (area === "") {
            showError("please_select_an_area");
            return;
        }
        setLoading(true);
        updateGeneral(value, user._id, token).then(res => {
            if (res.error) {
                showError(res.error);
            } else {
                setSelectedAreas({ ...selectedAreas, arrayValue: res.arrayValue, error: "", success: true });
                showAlert();
            }
            setLoading(false);
        });
    }

    const handleAreaOutsideDelivery = (src, value) => (event) => {
        event.preventDefault();
        if (src == 1 && area == "") {
            showError("please_select_an_area");
            return;
        }
        if (src == 2 && area2 == "") {
            showError("please_select_an_area");
            return;
        }
        setLoading(true);
        updateGeneral(value, user._id, token).then(res => {
            if (res.error) {
                showError(res.error);
            } else {
                setAreasOutsideDelivery({ ...areasOutsideDelivery, arrayValue: res.arrayValue, error: "", success: true });
                showAlert();
            }
            setLoading(false);
        });
    }

    const option = (title, name, value) => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict[title]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    <input onChange={handleChange(name)} type="number" value={(value.numberValue)} className="inputField form-control" id="delivery" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "84%" }}></input>
                    <button onClick={clickSubmit(value)} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["save"]}</button>
                </div>
            </Fragment>
        )
    }

    const deliveryMessageInArabicOption = (title, name, value) => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict[title]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    <input onChange={handleChange(name)} type="text" placeholder={dict[title]} value={(value.stringValue)} className="inputField form-control" id="deliveryMessageInArabic" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "84%" }}></input>
                    <button onClick={clickSubmit(value)} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["save"]}</button>
                </div>
            </Fragment>
        )
    }

    const deliveryMessageInEnglishOption = (title, name, value) => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict[title]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    <input onChange={handleChange(name)} type="text" placeholder={dict[title]} value={(value.stringValue)} className="inputField form-control" id="deliveryMessageInEnglish" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "84%" }}></input>
                    <button onClick={clickSubmit(value)} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["save"]}</button>
                </div>
            </Fragment>
        )
    }

    const announcementOption = (title, name, value) => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict[title]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    {activeSelector()}
                    <input onChange={handleChange(name)} type="text" placeholder={dict['announcement']} value={(value.stringValue)} className="inputField form-control" id="announcemet" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "64%" }}></input>
                    <button onClick={clickSubmit(value)} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["save"]}</button>
                </div>
            </Fragment>
        )
    }

    const minimumCartOption = (title, name, value) => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict[title]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    <input onChange={handleChange(name)} type="number" placeholder={dict['minimumCart']} value={(value.numberValue)} className="inputField form-control" id="minimumCart" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "84%" }}></input>
                    <button onClick={clickSubmit(value)} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["save"]}</button>
                </div>
            </Fragment>
        )
    }

    const activeSelector = () => {
        return (
            <select onChange={handleChange("activeAnnouncement")} className="inputField form-control" value={announcement.booleanValue} style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "20%" }}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const citySelector = (src) => {
        return (
            <select style={{ width: default_url === "/en/" ? "14%" : "15%", height: "40px", borderRadius: "0.3rem", border: "1px solid rgba(0, 0, 0, 0.3)", display: "inline-block", float: default_url === "/en/" ? "" : "right", marginInlineStart: "1%", marginTop: "0", marginBottom: "0", position: default_url === "/en/" ? "absolute" : "relative" }} onChange={handleChange(src == 1 ? "city" : "city2")} name="hasSubcategory" className="inputField form-control" value={src == 1 ? city : city2}>
                <option value="">{dict["select_city"]}</option>
                {
                    cities.map((d, i) => {
                        return <option key={i} value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const areaSelector = (src) => {
        return (
            <select style={{ width: default_url === "/en/" ? "14%" : "15%", height: "40px", borderRadius: "0.3rem", border: "1px solid rgba(0, 0, 0, 0.3)", display: "inline-block", float: default_url === "/en/" ? "" : "right", marginInlineStart: "1%", marginTop: "0", marginBottom: "0", position: default_url === "/en/" ? "absolute" : "relative" }} onChange={handleChange(src == 1 ? "area" : "area2")} name="hasSubcategory" className="inputField form-control" value={src == 1 ? area : area2}>
                <option value="">{dict["select_area"]}</option>
                {
                    areasInCity.map((d, i) => {
                        return src == 1 && selectedAreas.arrayValue.indexOf(d) >= 0 ? null : <option key={i} value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const listSelectedAreas = () => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["selected_areas"]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    <div className={(default_url === "/en/" ? "" : "selectedAreaAdmin")} style={{ textAlign: "start", padding: "3px 0 0", width: "52%", height: "40px", borderRadius: "0.3rem", border: "1px solid rgba(0, 0, 0, 0.3)", display: "inline-block", float: default_url === "/en/" ? "" : "right", margin: "0" }}>
                        {selectedAreas.arrayValue.map((a, i) => {
                            return (
                                <Chip label={a} onDelete={handleDelete(i)} />
                            )
                        })}
                    </div>
                    {citySelector(1)}
                    {areaSelector(1)}
                    <button onClick={handleAreaAdd(1, { ...selectedAreas, arrayValue: area !== "" ? [...selectedAreas.arrayValue, areasInCity[area]] : selectedAreas.arrayValue })} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["add"]}</button>
                </div>
            </Fragment>
        )
    }

    const listOfAreasOutsideDelivery = () => {
        return (
            <Fragment>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["stop_delivery_for_areas"]} <span className="redStar">*</span></label>
                <div style={{ marginTop: "40px", marginBottom: "15px" }}>
                    <div className={(default_url === "/en/" ? "" : "selectedAreaAdmin")} style={{ textAlign: "start", padding: "3px 0 0", width: "52%", height: "40px", borderRadius: "0.3rem", border: "1px solid rgba(0, 0, 0, 0.3)", display: "inline-block", float: default_url === "/en/" ? "" : "right", margin: "0" }}>
                        {areasOutsideDelivery.arrayValue.map((a, i) => {
                            return (
                                <Chip label={a} onDelete={handleDeleteAreasOutsideDelivery(i)} />
                            )
                        })}
                    </div>
                    {citySelector(2)}
                    {areaSelector(2)}
                    <button onClick={handleAreaOutsideDelivery(2, { ...areasOutsideDelivery, arrayValue: area2 !== "" ? [...areasOutsideDelivery.arrayValue, areasInCity[area2]] : areasOutsideDelivery.arrayValue })} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["add"]}</button>
                </div>
            </Fragment>
        )
    }

    const citiesAndAreas = () => {
        return (
            <Fragment>
                <button onClick={goToCities} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "49%" }}>{dict["cities"]}</button>
                <button onClick={goToAreas} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "49%" }}>{dict["areas"]}</button>
            </Fragment>
        )
    }

    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                <h3 className="formContainerTitle">{dict["more_settings"]}</h3>
                <hr />
                {announcementOption("announcement", "announcement", announcement)}
                {minimumCartOption("minimumCart", "minimumCart", minimumCart)}
                {option("delivery", "delivery", delivery)}
                {option("free_delivery_for_odrders_greater_than", "free_delivery", freeDelivery)}
                {option("free_delivery_for_selected_areas_for_odrders_greater_than", "free_delivery_for_selected_areas", freeDeliveryForSelectedAreas)}
                {option("delivery_for_selected_areas", "delivery_for_selected_areas", deliveryForSelectedAreas)}
                {deliveryMessageInArabicOption("delivery_message_in_arabic", "delivery_message_in_arabic", deliveryMessageInArabic)}
                {deliveryMessageInEnglishOption("delivery_message_in_english", "delivery_message_in_english", deliveryMessageInEnglish)}
                {listSelectedAreas()}
                {listOfAreasOutsideDelivery()}
                {citiesAndAreas()}
            </div>
        )
    }

    return (
        <AdminDashboard
            active="17"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default MoreSettings;