import React, { useState, useEffect, Fragment } from "react";
import { default_url } from "../index"
import { IMAGE_URL } from "../config";
import ProductsMenu from "../components/product components/product list/ProductsMenu"
import ProductItem from "../components/product components/product list/ProductItem"
import { dict } from "../dictionary"
import { listProductsByFilters, listOffers, getList, listLists, findListsByIds } from "./apiCore";
import Slider from '@bit/akiran.react-slick.slider';
import slider from "@bit/akiran.react-slick.slider/dist/slider";
import { Divider } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

function Offers() {
    let history = useHistory();
    const [lists, setLists] = useState([]);
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState(10);
    const [skip, setSkip] = useState(1);
    const [limit, setLimit] = useState(6);
    const [allLoaded, setAllLoaded] = useState(false);
    const silderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const loadLists = () => {
        listLists().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setLists(res);
            }
        });
    }

    const loadEachList = () => {
        listLists().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let ids = [];
                for (let i = 0; i < res.length; i++) {
                    ids.push(res[i]._id);
                }
                findListsByIds(ids).then(res2 => {
                    if (res2.error) {
                        console.log(res.error);
                    } else {
                        let res3 = [...res2];
                        for (let i = 0; i < res3.length; i++) {
                            let p = [...res3[i].products];
                            let arr = [...adjustProducts(p)];
                            res3[i].products = [...arr]
                        }
                        setLists(res3);
                    }
                })
            }
        });
    }

    const adjustProducts = (products => {
        let arr = [];
        let found = false
        for (let i = 0; i < products.length; i++) {
            found = false;
            for (let j = 0; j < arr.length; j++) {
                if (products[i].enTitle == arr[j].enTitle) {
                    arr[j]._id.push(products[i]._id);
                    arr[j].quantity.push(products[i].quantity);
                    arr[j].price.push(products[i].price);
                    arr[j].discountPrice.push(products[i].discountPrice);
                    arr[j].size.push(products[i].size);
                    arr[j].images.push([]);
                    for (let k = 0; products[i].images && k < products[i].images.length; k++) {
                        arr[j].images[arr[j].images.length - 1].push(products[i].images[k]);
                    }
                    found = true
                    break;
                }
            }
            if (!found) {
                arr.push({ ...products[i], _id: [products[i]._id], quantity: [products[i].quantity], price: [products[i].price], discountPrice: [products[i].discountPrice], size: products[i].size ? [products[i].size] : [], images: [[]] });
                for (let m = 0; products[i].images && m < products[i].images.length; m++) {
                    arr[arr.length - 1].images[0].push(products[i].images[m]);
                }
            }
        }
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
            arr2.push({ ...arr[i] });
            let at = arr2.length - 1;
            let sizes = [...arr2[at].size];
            let newIds = [...arr2[at]._id]
            let newQuantity = [...arr2[at].quantity]
            let newSizes = [...arr2[at].size]
            let newPrices = [...arr2[at].price]
            let newDiscountPrices = [...arr2[at].discountPrice]
            let newImages = [...arr2[at].images]
            for (let j = 0; j < sizes.length; j++) {
                let cnt = 0;
                for (let k = 0; k < sizes.length; k++) {
                    if (j == k) continue;
                    if (sizes[j] < sizes[k]) cnt += 1;
                }
                newIds[(sizes.length - cnt - 1)] = arr2[at]._id[j];
                newQuantity[(sizes.length - cnt - 1)] = arr2[at].quantity[j];
                newSizes[(sizes.length - cnt - 1)] = sizes[j];
                newPrices[(sizes.length - cnt - 1)] = arr2[at].price[j];
                newDiscountPrices[(sizes.length - cnt - 1)] = arr2[at].discountPrice[j];
                newImages[(sizes.length - cnt - 1)] = arr2[at].images[j];

            }
            arr2[at]._id = [...newIds];
            arr2[at].quantity = [...newQuantity];
            arr2[at].size = [...newSizes];
            arr2[at].price = [...newPrices];
            arr2[at].discountPrice = [...newDiscountPrices];
            arr2[at].images = [...newImages];
        }
        return adjustProducts2(arr2);
    });

    const adjustProducts2 = (products => {
        let items3 = [...products]
        for (let i = 0; i < items3.length; i++) {
            let minPrice = 10000, maxPrice = -1000;
            for (let j = 0; items3[i].price && j < items3[i].price.length; j++) {
                minPrice = Math.min(minPrice, items3[i].price[j]);
                maxPrice = Math.max(maxPrice, items3[i].price[j]);
            }

            let a = []
            for (let j = 0; items3[i].price && j < items3[i].price.length; j++) {
                if (items3[i].discountPrice[j]) {
                    a.push(items3[i].discountPrice[j])
                } else {
                    a.push(items3[i].price[j]);
                }
            }
            let b = a.sort((a, b) => {
                return a - b
            });
            let minPriceToShow = b[0];
            let maxPriceToShow = b[b.length - 1];
            let discount = false;
            if (minPrice > minPriceToShow || maxPrice > maxPriceToShow) {
                discount = true;
            }

            items3[i] = { ...items3[i], minPrice, maxPrice, discount, minPriceToShow, maxPriceToShow }

            let image1 = null, image2 = null;

            for (let j = 0; items3[i].images && j < items3[i].images.length; j++) {
                for (let k = 0; k < items3[i].images[j].length; k++) {
                    let tmp = items3[i].images[j][k].split("/");
                    items3[i].images[j][k] = IMAGE_URL + tmp[tmp.length - 1] + "_small.jpg";
                    if (image1 == null && k == 0) {
                        image1 = items3[i].images[j][k];
                    }
                    if (image2 == null && k == items3[i].images[j].length - 1) {
                        image2 = items3[i].images[j][k];
                    }
                }
            }
            items3[i] = { ...items3[i], image1, image2, link: default_url + "products/" + (items3[i].category && items3[i].category.enName) + "/" + (items3[i].subcategory && items3[i].subcategory.enName ? items3[i].subcategory.enName : items3[i].category.enName) + "/" + items3[i].enTitle }
        }
        return items3;
    })

    const loadOffers = () => {
        listOffers().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                if (res.length > 0) {
                    let productsId = [];
                    res.map(i => {
                        productsId.push(i.product);
                    })
                    loadProducts({ _id: productsId, hidden: false });
                    setFilters({ _id: productsId, hidden: false })
                }
            }
        })
    }

    const loadProducts = (filters) => {
        listProductsByFilters(0, 1000000, filters).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setProducts(res.data);
            }
        })
    }

    // useEffect(() => {
    //     //loadOffers();
    //     loadLists();
    // }, [])

    useEffect(() => {
        loadEachList();
    }, [])

    const loadMore = () => {
        setSkip(skip + 1);
    }

    const doneLoadMore = () => {
        setAllLoaded(true);
    }

    const clickSubmit = (list) => (e) => {
        e.preventDefault();
        history.push(default_url + "show-list/" + list)
        //window.location.href = default_url + "show-list/" + list;
    }

    const getImages = () => {
        let img1 = "";
        let img2 = "";
        for (let i = 0; i < products.length; i++) {
            let imgLen = products[i].images.length;
            let a = imgLen > 0 ? products[i].images[0].split("/") : "";
            img1 = IMAGE_URL + a[a.length - 1] + "_small.jpg";
            img2 = "";
            if (products[i].category.enName && (products[i].category.enName).toLowerCase() === "thermos" && imgLen > 0) {
                a = products[i].images[imgLen - 1].split("/");
                img2 = IMAGE_URL + a[a.length - 1] + "_small.jpg";
            } else if (imgLen > 0) {
                if (imgLen >= 2) {
                    a = products[i].images[1].split("/");
                } else {
                    a = products[i].images[0].split("/");
                }
                img2 = IMAGE_URL + a[a.length - 1] + "_small.jpg";
            }
        }
        return [img1, img2]
    }

    const cssstyle = `
.silderContainer {
  margin: 0 0 40px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  background: white;
}
.silderDiv {
    #background: black;
    color: #fff;
    font-size: 36px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.slick-dots{
    direction: rtl;
}
`
    const rearrangeArray = (arr) => {
        if (default_url == "/en/") return arr;
        let ret = [];
        let mod = arr.length % silderSettings.slidesToShow;
        if (mod > 0) {
            for (let i = arr.length - 1; i >= arr.length - mod; i--) {
                ret.push(arr[i]);
            }
        }
        for (let i = (arr.length - mod) - 1; i >= 0; i -= silderSettings.slidesToShow) {
            for (let j = Math.max(i - (silderSettings.slidesToShow - 1), 0); j <= i; j++) {
                ret.push(arr[j]);
            }
        }
        return ret;
    }

    return (
       lists && lists.length > 0 && lists.map((list, index) => {
            return (
                <div className={"container-fluid offers-container"} style={{ marginBottom: allLoaded ? "50px" : "" }}>
                    <h5 className="productsContainerTitle" style={{ textAlign: "start" }}>{default_url == "/en/" ? list.enName : list.arName}</h5>
                    <div className="container-fluid break-line"></div>
                    <button onClick={clickSubmit(list._id)} type="submit" className="dashboardBtn" style={{fontSize: "16px", width: "auto", marginBottom: "11.2px", marginTop: "0", padding: "7px 15px 3.5px" }}>{dict["show_all"]}</button>
                    <div className="silderContainer" style={{paddingTop: "20px"}}>
                        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                        <style>{cssstyle}</style>
                        
                        <Slider {...silderSettings}>
                            {
                                list.products && list.products.length > 0 && list.products[0].enTitle && rearrangeArray(list.products).map((item, index) => {
                                    return (
                                        <div>
                                            <div className="silderDiv">

                                                <ProductItem
                                                    key={index}
                                                    divClass={""}
                                                    title={default_url == "/en/" ? item.enTitle : item.arTitle}
                                                    discount={item.discount}
                                                    prices={item.price}
                                                    discountPrices={item.discountPrice}
                                                    model={""}
                                                    img1={item.image1}
                                                    img2={item.image2}
                                                    link={item.link}
                                                    minPrice={item.minPrice}
                                                    maxPrice={item.maxPrice}
                                                    minPriceToShow={item.minPriceToShow}
                                                    maxPriceToShow={item.maxPriceToShow}
                                                    size={item.size}
                                                    ids={item._id}
                                                    images={item.images}
                                                    quantity={item.quantity}
                                                    listName={list.arName}
                                                />

                                                {/* <ProductItem
                                                    key={index}
                                                    divClass=""
                                                    title={default_url == "/en/" ? item.enTitle : item.arTitle}
                                                    discount={item.discountPrice}
                                                    prices={[item.price]}
                                                    discountPrices={[item.discountPrice]}
                                                    model={""}
                                                    img1={getImages()[0]}
                                                    img2={getImages()[1]}
                                                    link={item.link}
                                                /> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>

                    </div>

                    {/* <ProductsMenu
                divClass={"col-lg-2 col-md-4 col-6"}
                items={products}
                limit={limit}
                skip={skip}
                doneLoadMore={() => {doneLoadMore()}}
            />
            {
                !allLoaded && 
                <div className="show-more-container" style={{marginBottom: "50px"}}>
                    <button className="show-more-btn" name="button" onClick={loadMore}>
                        <i className="fas fa-chevron-circle-down show-more-icon"></i>
                        {dict["show_more"]}
                    </button>
                </div>
            } */}
                </div>
            )
        })
    )
}

export default Offers;