import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { productsCount } from "../../../apiCore";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "auto"
    },
    formControl: {
        margin: theme.spacing(0),
    },
}));

const OffersCheckBox = ({ products, setCheckedProducts }) => {
    const classes = useStyles();
    const [state, setState] = useState({
        gilad: true,
        jason: false,
        antoine: false,
    });
    const [checked, setChecked] = useState([]);

    const handleChange = (event) => {
        let newChecked = [...checked];
        let name = event.target.name;
        if (newChecked.indexOf(name) === -1) {
            newChecked.push(name);
        } else {
            newChecked.splice(checked.indexOf(name), 1);
        }
        setChecked(newChecked);
        setCheckedProducts(newChecked)
    };

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl} style={{ marginTop: "20px", marginInlineStart: default_url === "/en/" ? "-50px" : "-65px" }}>
                <FormGroup>
                    {
                        products.map((item, index) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox checked={checked.indexOf(item._id) != -1} onChange={handleChange} name={item._id} />}
                                    label={item.enName}
                                />
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        </div>
    );

};

export default OffersCheckBox;