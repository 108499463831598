import React from "react";
import { Link } from "react-router-dom";
import { default_url } from "../..";

function HeaderRight(props) {
    return (
        <Link className="services-top" to={default_url + props.link}>
            {props.details}
        </Link>
    )
}

export default HeaderRight;