import React, { useState, useEffect } from "react";
import UserDashboard from "../../UserDashboard"
import { isAuthenticated } from "../../../../auth"
import { listAddresses, updateAddress } from "../../apiUser"
import { Redirect } from "react-router-dom";
import { default_url } from "../../../..";
import { dict, enAreas, ar_dic, en_dic } from "../../../../dictionary";
import { store } from 'react-notifications-component';
import * as qs from 'query-string';

function UpdateAddress() {
    const [values, setValues] = useState({
        data: [],
        error: "",
        success: false
    });
    const [address, setAddress] = useState({
        fName: "",
        lName: "",
        mobile: "",
        area: "",
        block: "",
        street: "",
        lane: "",
        building: "",
        floor: "",
        apartment: "",
        extra: "",
        error: "",
        success: false
    });
    const [index, setIndex] = useState(-1);

    const { user, token } = isAuthenticated();
    const { fName, lName, mobile, area, block, street, lane, building, floor, apartment, extra, error, success } = address;
    const parsed = qs.parse(window.location.search);

    const loadAddresses = () => {
        listAddresses(user._id, token).then(res => {
            if (res.error) {
                setAddress({ ...address, error: res.error, success: false });
            } else {
                setValues({ ...values, data: res, error: "" });
                for (let i = 0; i < res.length; i++) {
                    if (res[i]._id === parsed.address) {
                        setIndex(i);
                        setAddress({
                            fName: res[i].fName,
                            lName: res[i].lName,
                            mobile: res[i].mobile,
                            area: enAreas.indexOf(res[i].area),
                            block: res[i].block,
                            street: res[i].street,
                            lane: res[i].lane,
                            building: res[i].building,
                            floor: res[i].floor,
                            apartment: res[i].apartment,
                            extra: res[i].extra,
                            error: "",
                            success: false
                        });
                    }
                }
            }
        })
    }

    useEffect(() => {
        loadAddresses();
    }, []);



    const handleChange = name => event => {
        setAddress({ ...address, [name]: event.target.value, error: "", success: false })

    }


    const clickSubmit = (event) => {
        event.preventDefault();
        updateAddress(user._id, token, parsed.address, {...address, area: enAreas[area], _id: parsed.address}).then(res => {
            if(res.error){
                setAddress({...address, error: res.error, success: false});
            }else{
                setAddress({
                    ...address,
                    error: "",
                    success: true
                });
            }
        })
    }

    const showError = () => {
        if (error) {
            store.addNotification({
                message: dict[error],
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const redirectUser = () => {
        if (success) {
            store.addNotification({
                message: dict["address_has_been_updated"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const areaSelector = () => {
        return (
            <select onChange={handleChange("area")} name="hasSubcategory" className="inputField form-control" value={area}>
                <option value=""></option>
                {
                    enAreas.sort((a, b) => a - b).map((d, i) => {
                        return <option value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const showAddressForm = () => {
        return (
            <div>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["first_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="first_name" placeholder={dict["first_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["last_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="last_name" placeholder={dict["last_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("mobile")} type="number" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area"]} <span className="redStar">*</span></label><br />
                {areaSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["block"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("block")} type="text" value={block} className="inputField form-control" id="block" placeholder={dict["block"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["street"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("street")} type="text" value={street} className="inputField form-control" id="street" placeholder={dict["street"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["lane"]}</label>
                <input onChange={handleChange("lane")} type="text" value={lane} className="inputField form-control" id="lane" placeholder={dict["lane"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["building"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("building")} type="text" value={building} className="inputField form-control" id="building" placeholder={dict["building"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["floor"]}</label>
                <input onChange={handleChange("floor")} type="text" value={floor} className="inputField form-control" id="floor" placeholder={dict["floor"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["apartment"]}</label>
                <input onChange={handleChange("apartment")} type="text" value={apartment} className="inputField form-control" id="apartment" placeholder={dict["apartment"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["extra_details"]}</label>
                <input onChange={handleChange("extra")} type="text" value={extra} className="inputField form-control" id="extra" placeholder={dict["extra_details"]}></input>
            </div>
        )

    }



    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["edit_address"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form>
                    {showAddressForm()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>

            </div>
        )
    }

    return (
        <UserDashboard
            active="2"
            data={form}
        />
    )
}

export default UpdateAddress;