import React, { useState, useEffect } from "react";
import UserDashboard from "../../UserDashboard"
import { isAuthenticated } from "../../../../auth"
import { addAddress } from "../../apiUser"
import { Redirect } from "react-router-dom";
import { default_url } from "../../../..";
import { dict, enAreas, ar_dic, en_dic, cities, areas, areasArEn, areasEnAr, citiesArEn, citiesEnAr } from "../../../../dictionary";
import { store } from 'react-notifications-component';

function AddNewAddress() {
    const [address, setAddress] = useState({
        fName: "",
        lName: "",
        mobile: "",
        city: "",
        areasInCity: [],
        area: "",
        block: "",
        street: "",
        lane: "",
        building: "",
        floor: "",
        apartment: "",
        extra: "",
        error: "",
        success: false
    });

    const { user, token } = isAuthenticated();
    const { fName, lName, mobile, city, areasInCity, area, block, street, lane, building, floor, apartment, extra, error, success } = address;

    useEffect(() => {

    }, []);



    const handleChange = name => event => {
        if (name === "city") {
            setAddress({ ...address, [name]: event.target.value, areasInCity: areas[cities[event.target.value]], error: "" });
        } else {
            setAddress({ ...address, [name]: event.target.value, error: "" });
        }

    }


    const clickSubmit = (event) => {
        event.preventDefault();
        let isnum = /^\d+$/.test(mobile);
        if (mobile.length != 8 || !isnum) {
            showMobileError();
            return;
        }
        let myAddress = {
            ...address,
            enCity: (default_url === "/en/" ? cities[city] : citiesArEn[cities[city]]),
            arCity: (default_url === "/en/" ? citiesEnAr[cities[city]] : cities[city]),
            enArea: (default_url === "/en/" ? areasInCity[area] : areasArEn[areasInCity[area]]),
            arArea: (default_url === "/en/" ? areasEnAr[areasInCity[area]] : areasInCity[area])
        }
        addAddress(user._id, token, myAddress).then(res => {
            if (res.error) {
                setAddress({ ...address, error: res.error, success: false });
            } else {
                setAddress({
                    fName: "",
                    lName: "",
                    mobile: "",
                    city: "",
                    areasInCity: [],
                    area: "",
                    block: "",
                    street: "",
                    lane: "",
                    building: "",
                    floor: "",
                    apartment: "",
                    extra: "",
                    error: "",
                    success: true
                });
            }
        });
    }

    const showError = () => {
        if (error) {
            store.addNotification({
                message: dict[error],
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const showMobileError = () => {
        store.addNotification({
            message: dict["please_check_your_mobile_number"],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const redirectUser = () => {
        if (success) {
            store.addNotification({
                message: dict["new_address_has_been_added"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const citySelector = () => {
        return (
            <select onChange={handleChange("city")} name="hasSubcategory" className="inputField form-control" value={city}>
                <option value=""></option>
                {
                    cities.map((d, i) => {
                        return <option value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const areaSelector = () => {
        return (
            <select onChange={handleChange("area")} name="hasSubcategory" className="inputField form-control" value={area}>
                <option value=""></option>
                {
                    areasInCity.map((d, i) => {
                        return <option value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const showAddressForm = () => {
        return (
            <div>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["first_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="first_name" placeholder={dict["first_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["last_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="last_name" placeholder={dict["last_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("mobile")} type="number" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city"]} <span className="redStar">*</span></label><br />
                {citySelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area"]} <span className="redStar">*</span></label><br />
                {areaSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["block"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("block")} type="text" value={block} className="inputField form-control" id="block" placeholder={dict["block"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["street"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("street")} type="text" value={street} className="inputField form-control" id="street" placeholder={dict["street"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["lane"]}</label>
                <input onChange={handleChange("lane")} type="text" value={lane} className="inputField form-control" id="lane" placeholder={dict["lane"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["building"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("building")} type="text" value={building} className="inputField form-control" id="building" placeholder={dict["building"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["floor"]}</label>
                <input onChange={handleChange("floor")} type="text" value={floor} className="inputField form-control" id="floor" placeholder={dict["floor"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["apartment"]}</label>
                <input onChange={handleChange("apartment")} type="text" value={apartment} className="inputField form-control" id="apartment" placeholder={dict["apartment"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["extra_details"]}</label>
                <input onChange={handleChange("extra")} type="text" value={extra} className="inputField form-control" id="extra" placeholder={dict["extra_details"]}></input>
            </div>
        )

    }



    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_address"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form>
                    {showAddressForm()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>

            </div>
        )
    }

    return (
        <UserDashboard
            active="2"
            data={form}
        />
    )
}

export default AddNewAddress;