import React, { useState, useEffect, Fragment } from "react";
import ProductsNavLink from "./product components/ProductsNavLink"
import ProductsFilter from "./product components/Filter/ProductsFilter"
import ProductsContainer from "./product components/product list/ProductsContainer"
import { useParams } from "react-router-dom";
import { getCategoryDetails, getSubcategoryDetails, listProductsByFilters, getSubcategory, listColors, getList, findListsByIds } from "./apiCore"
import { default_url } from "../index"
import { dict } from "../dictionary";
import { Helmet } from "react-helmet";
import { getByTitle } from "@testing-library/react";
import { Sync } from "@material-ui/icons";

function List(props) {
    let { listId } = useParams();
    let { subcategory } = useParams();
    const params = new URLSearchParams(window.location.search);
    let price = params.get('price')
    let prices = []
    if (price) {
        prices = price.split("-");
    }
    let size = params.get('size')
    let sizes = []
    if (size) {
        sizes = size.split(",");
    }
    let color = params.get('color')
    let colors = []
    if (color) {
        colors = color.split(",");
    }
    const [list, setList] = useState();
    const [allProducts, setAllProducts] = useState([]);
    const [products1, setProducts1] = useState([]);
    const [products2, setProducts2] = useState([]);
    const [products3, setProducts3] = useState([]);
    const [products4, setProducts4] = useState([]);
    const [categoryInfo, setCategoryInfo] = useState();
    const [subcategoryInfo, setSubcategoryInfo] = useState();
    const [sizeFilters, setSizeFilters] = useState([]);
    const [allColors, setAllColors] = useState([]);
    const [allColors3, setAllColors3] = useState([]);
    const [error, setError] = useState([]);

    useEffect(() => {
        if (!listId) {

        } else {
            loadList()
        }
    }, [])

    // useEffect(() => {
    //     if (list) {
    //         loadSizes(categoryInfo.enName.toLowerCase());
    //     }
    // }, [allProducts])

    // useEffect(() => {
    //     if (list) {
    //         loadSizes(categoryInfo.enName.toLowerCase());
    //     }
    // }, [allProducts])

    useEffect(() => {
        getColors()
    }, [])

    const getColors = () => {
        listColors().then(res => {
            if (res.error) {

            } else {
                setAllColors(res);
                return res;
            }
        })
    }

    const loadList = () => {
        findListsByIds([listId]).then(res2 => {
            if (res2.error || res2.length == 0) {
                setError(res2.error);
            } else {
                let res = {...res2[0]}
                setList(res);
                listColors().then(colorsRes => {
                    if (colorsRes.error) {

                    } else {
                        let set = new Set();
                        for (let i = 0; i < res.products.length; i++) {
                            if (res.products[i].color) {
                                set.add(res.products[i].color);
                            }
                        }
                        let tmpColors = Array.from(set).sort((a, b) => {
                            return a - b;
                        });
                        let tmpColors2 = [];
                        for (let i = 0; i < colorsRes.length; i++) {
                            for (let j = 0; j < tmpColors.length; j++) {
                                if (colorsRes[i].number == tmpColors[j]) {
                                    tmpColors2.push({ ...colorsRes[i] })
                                }
                            }
                        }
                        setAllColors3(tmpColors2);
                        let arr = [];
                        let found = false
                        for (let i = 0; i < res.products.length; i++) {
                            if (res.products[i].color && colors.length > 0 && !colors.includes(res.products[i].color)) {
                                continue;
                            }
                            found = false;
                            for (let j = 0; j < arr.length; j++) {
                                if (res.products[i].enTitle == arr[j].enTitle) {
                                    if ((!sizes || sizes.length == 0) || (res.products[i].size && sizes && sizes.includes(res.products.size))) {
                                        arr[j]._id.push(res.products[i]._id);
                                        arr[j].quantity.push(res.products[i].quantity);
                                        arr[j].price.push(res.products[i].price);
                                        arr[j].discountPrice.push(res.products[i].discountPrice);
                                        arr[j].size.push(res.products[i].size);
                                        arr[j].images.push([]);
                                        for (let k = 0; k < res.products[i].images.length; k++) {
                                            arr[j].images[arr[j].images.length - 1].push(res.products[i].images[k]);
                                        }
                                    }
                                    found = true
                                    break;
                                }
                            }
                            if (!found) {
                                if ((!sizes || sizes.length == 0) || (res.products[i].size && sizes && sizes.includes(res.products[i].size))) {
                                    arr.push({ ...res.products[i], _id: [res.products[i]._id], quantity: [res.products[i].quantity], price: [res.products[i].price], discountPrice: [res.products[i].discountPrice], size: res.products[i].size ? [res.products[i].size] : [], images: [[]] });
                                    for (let m = 0; m < res.products[i].images.length; m++) {
                                        arr[arr.length - 1].images[0].push(res.products[i].images[m]);
                                    }
                                }
                            }
                        }
                        console.log(arr);
                        let arr2 = [];
                        for (let i = 0; i < arr.length; i++) {
                            arr2.push({ ...arr[i] });
                            let at = arr2.length - 1;
                            let sizes = [...arr2[at].size];
                            let newIds = [...arr2[at]._id]
                            let newQuantity = [...arr2[at].quantity]
                            let newSizes = [...arr2[at].size]
                            let newPrices = [...arr2[at].price]
                            let newDiscountPrices = [...arr2[at].discountPrice]
                            let newImages = [...arr2[at].images]
                            for (let j = 0; j < sizes.length; j++) {
                                let cnt = 0;
                                for (let k = 0; k < sizes.length; k++) {
                                    if (j == k) continue;
                                    if (sizes[j] < sizes[k]) cnt += 1;
                                }
                                newIds[(sizes.length - cnt - 1)] = arr2[at]._id[j];
                                newQuantity[(sizes.length - cnt - 1)] = arr2[at].quantity[j];
                                newSizes[(sizes.length - cnt - 1)] = sizes[j];
                                newPrices[(sizes.length - cnt - 1)] = arr2[at].price[j];
                                newDiscountPrices[(sizes.length - cnt - 1)] = arr2[at].discountPrice[j];
                                newImages[(sizes.length - cnt - 1)] = arr2[at].images[j];

                            }
                            arr2[at]._id = [...newIds];
                            arr2[at].quantity = [...newQuantity];
                            arr2[at].size = [...newSizes];
                            arr2[at].price = [...newPrices];
                            arr2[at].discountPrice = [...newDiscountPrices];
                            arr2[at].images = [...newImages];
                        }
                        setProducts1(res.products);
                        setProducts3(arr2);
                        setAllProducts(res.products);
                    }
                })


            }
        });
    }

    const loadSizes = (category) => {
        if (!category) return;
        if (category === "thermos") {
            if (default_url == "/en/") {
                setSizeFilters([{ name: "Small", sizes: ["0.5L", "0.6L"] }, { name: "Medium", sizes: ["0.9L", "1.0L"] }, { name: "Large", sizes: ["1.3L", "1.4L", "1.5L"] }]);
            }
            else {
                setSizeFilters([{ name: "صغير", sizes: ["0.5L", "0.6L"] }, { name: "وسط", sizes: ["0.9L", "1.0L"] }, { name: "كبير", sizes: ["1.3L", "1.4L", "1.5L"] }]);
            }
        } else {
            let set = new Set();
            for (let i = 0; i < allProducts.length; i++) {
                if (allProducts[i].size) {
                    set.add(allProducts[i].size);
                }
            }
            let sizes = [];
            set.forEach(value => {
                sizes.push({ name: value, sizes: [value] });
            });
            setSizeFilters([...sizes])
        }
        // else if (category === "tea & coffee sets") {
        //     setSizeFilters(["12pcs", "13pcs", "27pcs", "51pcs"]);
        // }
    }


    const getTitle = () => {
        let ret = ""
        if (list) {
            if (default_url === "/en/") {
                ret += list.enName
            } else {
                ret += list.arName
            }
        }
        return ret;
    }

    return (
        <div >
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className="productsNavLinkContainer">
                {
                    list &&
                    <ProductsNavLink
                        title={dict["home"]}
                        slash="/"
                        link={default_url}
                    />
                }
                {
                    list &&
                    <ProductsNavLink
                        title={default_url === "/ar/" ? list.arName : list.enName}
                    />
                }
            </div>
            <div className="productsMegaCountainer row" style={{ display: "block", marginInlineEnd: "0px !important"}}>
                <ProductsFilter
                    sizes={[]}
                    colors={allColors3}
                    {...props}
                />
                <div className="productsMenuContainer col-lg-10 col-md-12 row" style={{ display: "inline-block", marginBottom: "40px" }}>
                    <ProductsContainer
                        title={default_url === "/en/" ? categoryInfo ? categoryInfo.enName : "" : categoryInfo ? categoryInfo.arName : ""}
                        items={products1}
                        items3={products3}
                        {...props}
                        sizes={[]}
                        colors={allColors3}
                        list={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default List;