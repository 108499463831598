import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { createGeneral, updateGeneral, findUserByEmail, exportAllUsers, downloadExportedUsers, deleteUser, createPoint } from "../../apiAdmin"
import { getGeneral } from "../../../apiCore";
import { store } from 'react-notifications-component';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { getPointsByUser, update, updateFromAdmin } from "../../../user/apiUser";
import { event } from "jquery";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function ViewUsers() {
    const classes = useStyles();
    const [values, setValues] = useState({
        _id: "",
        fName: "",
        lName: "",
        email: "",
        mobile: "",
        dateOfBirth: "",
        nationality: "",
        gender: "",
        address: [],
        history: [],
        wishlist: [],
        role: "",
        newsletter: "",
        active: "",
        points: 0,
        extraPoints: 0
    })
    const [error, setError] = useState();
    const [foundUser, setFoundUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [targetEmail, setTargetEmail] = useState("");
    const [show, setShow] = useState(false);


    const { user, token } = isAuthenticated();

    const { _id, fName, lName, email, mobile, dateOfBirth, nationality, gender, address, history, wishlist, role, newsletter, active, points, extraPoints } = values;

    useEffect(() => {

    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        //setDeletedItem({ name: event.target.name, id: event.target.id })
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_user"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete this user?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد من حذف هذا المستخدم؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    const handleChange = name => event => {
        let target = "";
        if (name === "active") {
            target = event.target.checked;
        } else {
            target = event.target.value;
        }
        setValues({ ...values, error: "", success: false, [name]: target });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let updatedUser = { _id, role: role, active }
        updateFromAdmin(user._id, token, updatedUser).then(res => {
            if (res.error) {
                setValues({ ...values, success: false, error: res.error });
                showError(res.error);
            } else {
                showAlert();
            }
            setLoading(false);
        });
    }

    const clickAddExtraPoints = (event) => {
        event.preventDefault();
        setLoading(true);
        if(extraPoints <= 0){
            setLoading(false);
            return;
        }
        let createdDated = new Date();
        let n = new Date(createdDated.setMonth(createdDated.getMonth() + 6))
        let m = n.getMonth()
        let y = n.getFullYear()
        let numberOfDays = new Date(y, m + 1, 0).getDate()
        let expireDate = new Date(y, m, numberOfDays, 23, 59, 59)
        createPoint({ amount: extraPoints, user: _id, isGift: true, status: "active", expireDate: expireDate }, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setValues({...values, extraPoints: 0})
                findUser();
            }
            setLoading(false);
        })
    }

    const handleDelete = () => {
        handleClose();
        setLoading(true);
        deleteUser(_id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                showUserDeleteAlert();
                setFoundUser(false);
            }
            setLoading(false);
        })
    }

    const clickDelete = (event) => {
        event.preventDefault();
        handleShow();
    }

    const clickExport = (event) => {
        event.preventDefault();
        setLoading(true);
        exportAllUsers(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                downloadExportedUsers(user._id, token).then(res2 => {
                    setLoading(false);
                })
            }
        })

    }

    const showUserDeleteAlert = () => {
        store.addNotification({
            message: dict['user_has_been_deleted'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showAlert = () => {
        store.addNotification({
            message: dict['user_has_been_updated'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showError = (msg) => {
        store.addNotification({
            message: dict[msg] ? dict[msg] : dict['something_went_wrong'],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const findUser = () => {
        setLoading(true);
        findUserByEmail(targetEmail, user._id, token).then(res => {
            if (res.error) {
                setError(res.error);
                setFoundUser(false);
                showError(res.error);
            } else {
                setError(null);
                getPointsByUser(res._id, token, { status: "active" }).then(res2 => {
                    let t = 0;
                    let u = 0;
                    for (let i = 0; i < res2.length; i++) {
                        t += res2[i].amount;
                        for (let j = 0; j < res2[i].used.length; j++) {
                            if (!res2[i].used[j].returned) {
                                u += res2[i].used[j].amount;
                            }
                        }
                    }
                    setFoundUser(true);
                    var date = new Date(res.dateOfBirth);
                    var dd = String(date.getDate()).padStart(2, '0');
                    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = date.getFullYear();
                    date = yyyy + '-' + mm + '-' + dd;
                    setValues({ ...res, dateOfBirth: date, points: (t - u) });
                });

            }
            setLoading(false);
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        findUser();
    }

    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                <h3 className="formContainerTitle">{dict["find_user"]}</h3>
                <hr />
                <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off" style={{ left: default_url === "/en/" ? "15px" : "", right: default_url === "/en/" ? "" : "15px", top: "0px" }}>
                    <TextField value={targetEmail} id="standard-basic" label="Enter Email" variant="outlined" style={{ textAlign: "start", margin: "15px 0", direction: "ltr", width: "100%" }} onChange={(event) => { setTargetEmail(event.target.value); setError(null); setFoundUser(false) }} />
                </form>
                {showUserProfile()}
                <hr />
                <button onClick={clickExport} type="submit" className="dashboardBtn">{dict["export_all_users"]}</button>
            </div>
        )
    }

    const roleSelector = () => {
        return (
            <select onChange={handleChange("role")} className="inputField form-control" value={role}>
                <option value={null}>{dict["select_role"]}</option>
                {
                    [dict['regular_user'], dict['admin'], dict['storage']].map((type, index) => {
                        return (
                            <option key={index} value={index}>{type}</option>
                        )
                    })

                }
            </select>
        )
    }

    const showUserProfile = () => {
        if (foundUser) {
            return (
                <Fragment>
                    <hr />
                    <form >
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['first_name']}</label>
                        <input disabled onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="fName" placeholder={dict['first_name']}></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['last_name']}</label>
                        <input disabled onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="lName" placeholder={dict['last_name']}></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email_address"]}</label>
                        <input disabled onChange={handleChange("email")} type="email" value={email} className="inputField form-control" id="email" aria-describedby="emailHelp" placeholder={dict["email_address"]}></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]}</label>
                        <input disabled onChange={handleChange("mobile")} type="number" value={mobile} className="inputField form-control" placeholder={dict["mobile"]}></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["birth_date"]}</label>
                        <input disabled onChange={handleChange("dateOfBirth")} type="date" value={dateOfBirth} className="inputField form-control"></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['nationality']}</label>
                        <input disabled onChange={handleChange("nationality")} type="text" value={nationality} className="inputField form-control"></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["gender"]}</label>
                        <input disabled onChange={handleChange("gender")} type="text" value={gender} className="inputField form-control"></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["points"]}</label>
                        <input disabled onChange={handleChange("points")} type="text" value={points} className="inputField form-control"></input>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["add_extra_points"]}</label>
                        <div style={{ marginTop: "20px", marginBottom: "15px" }}>
                            <input onChange={handleChange("extraPoints")} type="number" value={(extraPoints)} min="0" className="inputField form-control" id="extraPoints" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "" : "right", width: "84%" }}></input>
                            <button onClick={clickAddExtraPoints} type="submit" className="dashboardBtn" style={{ margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "15%" }}>{dict["add"]}</button>
                        </div>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["account_type"]}</label>
                        {roleSelector()}
                        <div className="form-check checkBoxUserDashboard">
                            <input disabled onChange={handleChange("newsletter")} type="checkbox" checked={newsletter} className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" for="exampleCheck1">{dict["sign_up_for_newsletter"]}</label>
                        </div>
                        <div className="form-check checkBoxUserDashboard">
                            <input onChange={handleChange("active")} type="checkbox" checked={active} className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" for="exampleCheck1">{dict["user_is_active"]}</label>
                        </div>
                        <button onClick={clickSubmit} type="submit" className="dashboardBtn" style={{ width: "73%", marginInlineEnd: "4%" }}>{dict["save"]}</button>
                        <button onClick={clickDelete} type="submit" className="dashboardBtn" style={{ width: "23%", backgroundColor: "red" }}>{dict["delete"]}</button>
                        {popUp()}
                    </form>
                </Fragment>
            )
        }
    }

    return (
        <AdminDashboard
            active="11"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default ViewUsers;