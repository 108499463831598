import React, { useState, Fragment, useEffect } from "react";
import { singout, isAuthenticated } from "../../auth";
import {default_url} from "../../index"
import { dict } from "../../dictionary";
import Badge from '@material-ui/core/Badge';
import { clearCompareList } from "../compareHelpers";
import { clearWishlist } from "../wishlistHelpers";
import { clearCart } from "../cartHelpers";
import { countNewOrders } from "../admin/apiAdmin";


function StorageDashboard(props) {
    const [newOrders, setNewOrders] = useState(0);

    const {user, token} = isAuthenticated();

    useEffect(() => {
        countNewOrders(user._id, token).then(data => {
            if(data.error){
                console.log(data.error);
            }else{
                setNewOrders(data);
            }
        })
    }, [])

    const clearCartAndWishlistAndCompare = () => {
        clearCompareList();
        clearWishlist();
        clearCart();
    }

    return (
        <Fragment >
            <div className="userProfileContainer">
            <h1 className="userProfileTitle userProfileTitleOnDesktop" style={{right: default_url === "/en/" ? "" : "15px", position: default_url === "/en/" ? "" : "absolute"}}>{dict["my_account"]}</h1>
            <a href={default_url + "signin"} onClick={() => singout(clearCartAndWishlistAndCompare)} className="signoutLink signoutLinkOnDesktop" style={{position: default_url === "/en/" ? "absolute" : "relative", right: default_url === "/en/" ? "15px" : "", marginBottom: "10px"}}><i className="fas fa-sign-out-alt signouticon"  style={{transform: default_url === "/en/" ? "" : "scaleX(-1)"}}></i>{dict["signout"]}</a>
                <nav className="navbar navbar-expand-lg navbar-light bg-light userProfileNavbar">
                    <h1 className="userProfileTitle userProfileTitleOnMob">My Account</h1>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item signoutLinkOnMob">
                                <a href={default_url + "signin"} onClick={() => singout(clearCartAndWishlistAndCompare)} className="nav-link signoutLink">{dict["signout"]}</a>
                            </li>
                            <li className={"nav-item " + (props.active === "1" ? "activeUserProfileItem" : "")}>
                                <a className={"nav-link " + (props.active === "1" ? "activeUserProfileLink" : "")} href={default_url + "storage/account-information"}>{dict["account_information"]}</a>
                            </li>
                            <li className={"nav-item " + (props.active === "2" ? "activeUserProfileItem" : "")}>
                                <a className={"nav-link " + (props.active === "2" ? "activeUserProfileLink" : "")} href={default_url + "storage/orders"}>{dict["orders"]} <Badge badgeContent={newOrders} style={{marginInlineEnd: "0px", marginTop: "-40px"}} /></a>
                            </li>
                        </ul>
                    </div>
                </nav>
                {props.data()}
            </div>
        </Fragment>
    )
}

export default StorageDashboard;