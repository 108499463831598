import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createCity, createMultipleCities } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import { from } from "form-data";
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function AddCity() {
    const classes = useStyles();
    const [values, setValues] = useState({
        enName: "",
        arName: "",
        hasSubcategory: "",
        hidden: "",
        image: "",
        error: "",
        redirectToReferrer: false,
        formData: ""
    });
    const [mood, setMood] = useState({
        single: true,
        multiple: false
    })
    const { user, token } = isAuthenticated();
    const { enName, arName, error, redirectToReferrer, formData } = values;
    const { single, multiple } = mood;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValues({ ...values, formData: new FormData() });
    }, []);

    const handleChange = name => event => {
        if (single) {
            setValues({ ...values, error: false, [name]: event.target.value });
        } else {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.set(`cities`, event.target.files[i]);
            }
        }
    };

    const handleCheckBoxChange = name => event => {
        if (name == "single") {
            setMood({ 'single': event.target.checked, 'multiple': !event.target.checked });
        } else {
            setMood({ 'single': !event.target.checked, 'multiple': event.target.checked });
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (!single && !multiple) {
            return;
        }
        setLoading(true);
        if (single) {
            createCity({ enName, arName }, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: dict[data.error], redirectToReferrer: false });
                    } else {
                        setValues({
                            enName: "",
                            arName: "",
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        } else {
            createMultipleCities(formData, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: data.error, redirectToReferrer: false });
                    } else {
                        setValues({
                            enName: "",
                            arName: "",
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        }
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" , textAlign: 'start'}}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/cities', state: { prevPath: "create", error } }} />
            )
        }
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const singleCity = () => {
        return (
            <div className="signleCategoryForm" style={{ display: single ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city_english_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="name" placeholder={dict["city_english_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city_arabic_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="name" placeholder={dict["city_arabic_name"]}></input>
            </div>
        )
    }
    const multipleCities = () => {
        return (
            <div className="multipleCategoriesForm" style={{ display: multiple ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="photo" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }
    
    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_city"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("single")} type="checkbox" checked={single} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_one_city"]}</label>
                    </div>
                    {singleCity()}
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("multiple")} type="checkbox" checked={multiple} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_multiple_cities"]}</label>
                    </div>
                    {multipleCities()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="222"
            data={form}
        />
    )
}

export default AddCity;