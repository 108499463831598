import React from "react";

function AdsIndicators(props){
    return(
        <li
                className={"ad-pointer" + (props.num == 0 ? "active" : "")}
                data-target="#carouselExampleIndicators" 
                data-slide-to={props.num} 
            />
    )
}

export default AdsIndicators;