import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../../../../auth"
import { deleteProduct } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import * as qs from 'query-string';
import { default_url } from "../../../..";

function DeleteProduct() {
    const [values, setValues] = useState({
        error: "",
        redirect: false
    });
    const { user, token } = isAuthenticated();
    const parsed = qs.parse(window.location.search);
    useEffect(() => {
        deleteProduct(parsed.product_id, user._id, token).then(data => {
            if (data.error) {
                setValues({ error: data.error, redirect: true })
            } else {
                setValues({ error: undefined, redirect: true })
            }
        })
    }, [])

    let st = "";
    const params = new URLSearchParams(window.location.search);
    let itemCode2 = params.get('itemCode');
    if (itemCode2) {
        st += ("itemCode=" + itemCode2)
    }
    let show = parseInt(params.get('show'));
    if (show) {
        if (st == "") {
            st += ("show=" + show)
        } else {
            st += ("&show=" + show)
        }
    }

    return (
        <div>
            {values.redirect && <Redirect to={{ pathname: default_url + 'admin/products', search: st, state: { prevPath: "delete", error: values.error } }} />}
        </div>
    )

}

export default DeleteProduct;