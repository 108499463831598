import React, { useState, useEffect, Fragment } from "react";
import UserDashboard from "../UserDashboard"
import { isAuthenticated } from "../../../auth"
import { dict, en_dic, ar_dic } from "../../../dictionary";
import { default_url } from "../../..";
import { activatePointsProgram, getPointsByUser, read } from "../apiUser"
import { store } from 'react-notifications-component';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { IMAGE_URL } from "../../../config";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function UserPoints() {
    let history = useHistory();
    const classes = useStyles();
    const [points, setPoints] = useState([]);
    const [activePoints, setActivePoints] = useState(null);
    const [agreement, setAgreement] = useState(false);
    const [data2, setData2] = useState([]);
    const [mouseOverRemove, setMouseOverRemove] = useState(-1);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();

    if (!user.isPointsActive) {
        history.push(default_url + "points")
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const loadPoints = () => {
        read(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                if (res.isPointsActive) {
                    setActivePoints(true)
                    getPointsByUser(user._id, token, { status: "active" }).then(res => {
                        if (res.error) {
                            console.log(res.error);
                        } else {
                            setPoints(res);
                        }
                    })
                } else {
                    history.push(default_url + "points")
                    setActivePoints(false)
                }
            }
        })

    }

    const handleAgreementChange = (event) => {
        setAgreement(event.target.checked);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!agreement) {
            showAgrementError();
            setLoading(false);
        } else {
            activatePointsProgram(user._id, token).then(res => {
                if (res.error) {
                    console.log(res.error);
                    showActivationError();
                } else {
                    window.location.reload();
                }
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        if(user.isPointsActive){
            loadPoints();
        }
        
    }, []);

    const getDate = (date) => {
        var created_date = new Date(date);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        var year = created_date.getFullYear();
        var month = months[created_date.getMonth()];
        var day = created_date.getDate();
        var hour = created_date.getHours();
        var min = created_date.getMinutes();
        var sec = created_date.getSeconds();
        var date = (day + "").padStart(2, '0') + "/" + (month + "").padStart(2, '0') + "/" + year;
        var timein24 = (hour + "").padStart(2, '0') + ':' + (min + "").padStart(2, '0');

        return (date)
    }

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            time[0] = (time[0] + "").padStart(2, '0');
        }
        return time.join(''); // return adjusted time or original string
    }

    const getUsedPoints = (u) => {
        let ret = 0;
        for (let i = 0; i < u.length; i++) {
            if (!u[i].returned) {
                ret += u[i].amount
            }
        }
        return ret;
    }

    const getRemainingPoints = (p) => {
        let ret = 0;
        for (let i = 0; i < p.used.length; i++) {
            if (!p.used[i].returned) {
                ret += p.used[i].amount
            }
        }
        return p.amount - ret;
    }

    const showPoints = () => {
        return (
            points.map((p, i) => {
                return (
                    <div dir={default_url == "/en/" ? 'ltr' : "rtl"} style={{ display: "block", marginBottom: "20px" }}>
                        <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px", fontWeight: '900', textDecoration: 'underline' }}>{dict['earned_points'] + ": " + p.amount} </div>
                        <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px", color: 'red' }}>{dict['used_points_from_it'] + ": " + getUsedPoints(p.used)} <span style={{ color: 'red' }}>{" (" + dict['equal_to'] + " " + (getUsedPoints(p.used) / 10).toFixed(3) + " " + dict['kd'] + ")"}</span></div>
                        <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px", color: 'green', fontWeight: '800' }}>{dict['remaining_points_from_it'] + ": " + getRemainingPoints(p)} <span style={{ color: 'green' }}>{" (" + dict['equal_to'] + " " + (getRemainingPoints(p) / 10).toFixed(3) + " " + dict['kd'] + ")"}</span></div>
                        <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px" }}>{dict['valid_until'] + ": " + getDate(p.expireDate)}</div>
                        {p.order && <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px" }}>{dict['earned_from_order_number'] + ": " + p.order.orderNumber}</div>}
                        {p.isGift && <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px" }}>{dict['earned_as_a_gift']}</div>}
                        <hr></hr>
                    </div>
                )
            })
        );
    }

    const showTotalPoints = () => {
        let t = 0;
        let u = 0;
        for (let i = 0; i < points.length; i++) {
            t += points[i].amount;
            for (let j = 0; j < points[i].used.length; j++) {
                if (!points[i].used[j].returned) {
                    u += points[i].used[j].amount
                }
            }
        }
        return (
            <div dir={default_url == "/en/" ? 'ltr' : "rtl"} style={{ display: "block", marginBottom: "20px", color: 'red' }}>
                <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "18px", fontWeight: "700", color: 'gray' }}>{dict['total_used_points'] + ": " + u} <span >{" (" + dict['equal_to'] + " " + (u / 10).toFixed(3) + " " + dict['kd'] + ")"}</span></div>
                <div style={{ width: '100%', margin: '10px 0', padding: "0", display: "inline-block", textAlign: 'start', fontSize: "20px", fontWeight: "900", color: 'green' }}>{dict['total_remaining_points'] + ": " + (t - u)} <span>{" (" + dict['equal_to'] + " " + ((t - u) / 10).toFixed(3) + " " + dict['kd'] + ")"}</span></div>
            </div>
        );
    }

    const noItemsMsg = () => {
        return (<h2 style={{ textAlign: "start" }}>{dict['no_points'] + "."}</h2>);
    }

    const redirectToActivation = () => {

    }

    const showActivation = () => {
        return (
            <div>
                <div style={{ textAlign: "start" }}>
                    ماهو برنامج النقاط؟
                </div>
                <div style={{ textAlign: "start" }}>
                    هو برنامج خصومات حصرية لدى رويال هاوس، يقدم البرنامج تجربة مميزة لجمع النقاط واستخدامها للحصول على خصومات خاصة لعملائنا المميزين
                </div>

                <br />
                <br />

                <div style={{ textAlign: "start" }}>
                    كيفية جمع النقاط واستخدامها
                </div>
                <div style={{ textAlign: "start" }}>
                    - قم بإنشاء حساب في المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - اجمع النقاط عبر الطلب من المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - استبدل هذه النقاط عند الرغبة بالطلب مرة اخرى واحصل على خصم خاص ( الاستبدال غير ممكن في العروض والخصومات )
                </div>

                <br />
                <br />

                <div style={{ textAlign: "start" }}>
                    الشروط والاحكام
                </div>
                <div style={{ textAlign: "start" }}>
                    - يحصل العميل على نقطة واحدة مقابل كل دينار يصرف في المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - النقطة تعادل 100 فلس
                </div>
                <div style={{ textAlign: "start" }}>
                    - النقاط صالحة لمدة 6 شهور من تاريخ الطلب (لكل طلب على حدا)
                </div>
                <div style={{ textAlign: "start" }}>
                    - لا يمكن استخدام النقاط مع العروض والخصومات
                </div>
                <div style={{ textAlign: "start" }}>
                    - برنامج النقاط حصريا عبر المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - يجب على العميل انشاء حساب في المتجر الاكتروني للانضمام الى البرنامج
                </div>

                <br />

                <div class="form-check checkBoxSignUp" style={{ paddingInlineStart: "2%" }}>
                    <input onClick={handleAgreementChange} type="checkbox" checked={agreement} class="form-check-input" id="exampleCheck1" />
                    <label style={{ fontSize: "16px", fontWeight: "700" }} class="form-check-label" for="exampleCheck1">{dict["i_agree_to_the_terms_and_conditions"]} </label>
                </div>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["join_points_program"]}</button>
            </div>
        );
    }

    const showAgrementError = () => {
        store.addNotification({
            message: dict["please_agree_on_terms_and_conditions"],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 3500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showActivationError = () => {
        store.addNotification({
            message: dict["something_went_wrong._try_again_later_or_contact_support"],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 3500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {activePoints == false && <h3 className="formContainerTitle">{dict['points_program']}</h3>}
                {activePoints == true && <h3 className="formContainerTitle">{dict['my_points']}</h3>}
                <hr />
                {activePoints == false && showActivation()}
                {activePoints == true && points.length == 0 && noItemsMsg()}
                {activePoints == true && points.length > 0 && showPoints()}
                {activePoints == true && points.length > 0 && showTotalPoints()}
            </div>
        )
    }

    return (
        <UserDashboard
            active="6"
            data={form}
        />
    )

}

export default UserPoints;