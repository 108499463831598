import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { getTypeOptions, createCoupon, createMultipleCoupons } from "../../apiAdmin"
import { countOfSubcategories, listCategories, listCities } from "../../../apiCore"
import { Redirect } from "react-router-dom";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import CityCheckBox from "./CityCheckBox";

function AddNewCoupon() {
    const [values, setValues] = useState({
        code: "",
        type: null,
        amount: "",
        limit: "",
        requiresSignin: null,
        active: null,
        error: "",
        success: false,
    });
    const [mood, setMood] = useState({
        single: false,
        multiple: true
    })

    const [typeOptions, setTypeOptions] = useState([]);
    const [cities, setCities] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const { user, token } = isAuthenticated();
    const { code, type, amount, limit, requiresSignin, active, error, success, formData } = values;
    const { single, multiple } = mood;

    const loadTypeOptions = () => {
        getTypeOptions(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setTypeOptions(res);
            }
        })
    }

    const loadCities = () => {
        listCities().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setCities(res);
            }
        })
    }

    useEffect(() => {
        setValues({ ...values, formData: new FormData() });
        loadTypeOptions();
        loadCities();
    }, []);

    const handleChange = name => event => {
        if (single) {
            setValues({ ...values, error: false, [name]: event.target.value });
        } else {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.set(`products`, event.target.files[i]);
            }
        }
    };

    const handleCheckBoxChange = name => event => {
        if (name == "single") {
            setMood({ 'single': event.target.checked, 'multiple': !event.target.checked });
        } else {
            setMood({ 'single': !event.target.checked, 'multiple': event.target.checked });
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (!single && !multiple) {
            return;
        }
        if (single) {
            let coupon = { code: code, type: type, amount: amount, limit: limit, requiresSignin: requiresSignin, active: active, cities: [...citiesList] }
            createCoupon(coupon, user._id, token).then(res => {
                if (res.error) {
                    setValues({ ...values, error: res.error, success: false });
                } else {
                    setValues({ ...values, error: null, success: true, formData: "" });
                }
            })
        } else {
            createMultipleCoupons(formData, user._id, token).then(res => {
                if (res.error) {
                    setValues({ ...values, error: res.error, success: false });
                } else {
                    setValues({ ...values, error: null, success: true, formData: "" });
                }
            });
        }

    }

    const typeSelector = () => {
        return (
            <select onChange={handleChange("type")} className="inputField form-control" value={type}>
                <option value={null}></option>
                {
                    typeOptions.map((t, index) => {
                        return (
                            <option key={index} value={t}>{dict[t]}</option>
                        )
                    })

                }
            </select>
        )
    }

    const requiresSigninSelector = () => {
        return (
            <select onChange={handleChange("requiresSignin")} className="inputField form-control" value={requiresSignin}>
                <option value={null}></option>
                <option value={"yes"}>{dict['yes']}</option>
                <option value={"no"}>{dict['no']}</option>
            </select>
        )
    }

    const activeSelector = () => {
        return (
            <select onChange={handleChange("active")} className="inputField form-control" value={active}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const addCities = () => {
        return (
            <form>
                {cities.length >= 0 && <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["cities"]}</label>}<br />
                {
                    cities.length > 0 &&
                    <CityCheckBox
                        cities={cities}
                        setCheckedCities={list => {
                            setCheckedCities(list);
                        }}
                    />
                }
            </form>
        )
    }

    const setCheckedCities = (list) => {
        let newList = [];
        list.map(i => {
            newList.push(i);
        });
        setCitiesList(newList);
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_coupon_has_been_added.add_more_or']} <a href={default_url + "admin/coupons"} style={{ fontSize: "18px" }}>{dict["go_back_to_coupon_list"]}</a>
                </div>
            )
        }
    }

    const signleCoupon = () => {
        return (
            <div className="signleCategoryForm" style={{ display: single ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["coupon_code"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("code")} type="text" value={code} className="inputField form-control" id="code" placeholder={dict["coupon_code"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["type"]} <span className="redStar">*</span></label>
                {typeSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["amount"]}</label>
                <input onChange={handleChange("amount")} type="number" value={amount} className="inputField form-control" id="amount" placeholder={dict["amount"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["requires_signin"]} <span className="redStar">*</span></label><br />
                {requiresSigninSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["active"]} <span className="redStar">*</span></label><br />
                {activeSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["limit_of_use"]}</label>
                <input onChange={handleChange("limit")} type="number" value={limit} className="inputField form-control" id="amount" placeholder={dict["limit"]}></input>
                {addCities()}
            </div>
        )
    }

    const multipleCoupons = () => {
        return (
            <div className="multipleCategoriesForm" style={{ display: multiple ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="excel" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_new_coupon"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("single")} type="checkbox" checked={single} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_one_coupon"]}</label>
                    </div>
                    {signleCoupon()}
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("multiple")} type="checkbox" checked={multiple} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_multiple_coupons"]}</label>
                    </div>
                    {multipleCoupons()}

                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="9"
            data={form}
        />
    )
}

export default AddNewCoupon;