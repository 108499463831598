import React, { useEffect } from "react";
import HeaderLeft from "./header components/HeaderLeft";
import HeaderRight from "./header components/HeaderRight";
import { default_url } from "../index"
import { dict } from "../dictionary"
import MailIcon from '@material-ui/icons/Mail';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { Helmet } from "react-helmet";
import AssignmentIcon from '@material-ui/icons/Assignment';
import FeedbackIcon from '@material-ui/icons/Feedback';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import BookIcon from '@material-ui/icons/Book';
import { URL } from "../config";
import { Link, useHistory } from "react-router-dom";
import { pageView } from "./apiCore";
import { useCookies } from 'react-cookie';
import { isAuthenticated } from "../auth";

function Header() {
    // const history = useHistory()
    // const [cookies, setCookie] = useCookies(['_fbp']);
    // const { user } = isAuthenticated();
    
    // useEffect(() => {
    //     const url = window.location.pathname;
    //     pageView(cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, url, user && user.mobile ? user.mobile : null).then(response => {
    //         if (response.error) {
    //             console.log(response.error);
    //         }
    //     });
    // }, []);

    // useEffect(() => {
    //     return history.listen((location) => {
    //         pageView(cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, location.pathname, user && user.mobile ? user.mobile : null).then(response => {
    //             if (response.error) {
    //                 console.log(response.error);
    //             }
    //         });
    //     })
    // }, [history]);

    return (
        <div className="container-fluid top-section row" dir="ltr">
            <Helmet>
                <title>متجر رويال هاوس الالكتروني - وكيل ترامس روز - توصيل اواني منزلية في الكويت</title>
            </Helmet>
            <div className="col top-left">
                <div dir="rtl">
                    <AssignmentIcon style={{ color: "white", position: "absolute", marginTop: "6px", padding: "0 3px 0 0" }} />
                    <Link className="contact-top services-top-left" to={default_url + "wholesale"}>
                        {dict['wholesale']}
                    </Link>
                    <FeedbackIcon style={{ color: "white", position: "absolute", marginTop: "6px", padding: "0 3px 0 0" }} />
                    <Link className="contact-top services-top-left" to={default_url + "feedback"}>
                        {dict['feedbacks']}
                    </Link>
                    <LocationOnIcon style={{ color: "white", position: "absolute", marginTop: "6px", padding: "0 3px 0 0" }} />
                    <Link className="contact-top services-top-left" to={default_url + "distributors"}>
                        {dict['find_a_store']}
                    </Link>
                    <BookIcon style={{ color: "white", position: "absolute", marginTop: "6px", padding: "0 3px 0 0" }} />
                    <Link className="contact-top services-top-left" to={default_url + "blog"}>
                        {dict['blog']}
                    </Link>
                </div>

            </div>
            <div className="col top-right">
                <HeaderRight
                    link="delivery"
                    details={dict["shipping"]}
                />
                <HeaderRight
                    link="terms-and-conditions"
                    details={dict["terms_and_conditions"]}
                />
                <HeaderRight
                    link="contact-us"
                    details={dict["contact_us"]}
                />
            </div>
        </div>
    )
}

export default Header;