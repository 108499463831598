import React, { useState, useEffect, Fragment } from 'react';
import { default_url } from "../index"
import { dict } from '../dictionary';
import { getWishlist, removeItem } from './wishlistHelpers';
import { store } from 'react-notifications-component';
import { IMAGE_URL } from '../config';
import { listProductsByFilters } from './apiCore';

function Wishlist() {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [mouseOverRemove, setMouseOverRemove] = useState(-1);

    let loadItems = ((filters, wishlist) => {
        listProductsByFilters(0, 1000000, filters).then(p => {
            if (p.error) {

            } else {
                let items = [];
                let ids = filters._id;
                p.data.map((item, index) => {
                    for (let i = 0; i < wishlist.length; i++) {
                        if (wishlist[i].itemId === item._id) {
                            items.push({...item, link: wishlist[i].link});
                        }
                    }
                });
                console.log(items);
                setData(items)
            }
        });
    });

    useEffect(() => {
        let wishlist = getWishlist();
        let ids = [];
        wishlist.map(p => {
            ids.push(p.itemId);
        });
        loadItems({_id: ids}, wishlist);
    }, [data2]);


    const deleteItem = productId => event => {
        setData2(removeItem(productId));
        store.addNotification({
            message: dict['product_has_been_removed_from_wishlist'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showItems = () => {
        return (
            data.length > 0 && data.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <a href={item.link}>
                                <img className="cart-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                            </a>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info">{default_url === "/en/" ? item.enName : item.arName}</h6>
                                    {item.color !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Color " : "اللون "} {item.color}</h6>}
                                    {item.size !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Size " : "حجم "} {item.size}</h6>}
                                    <h6 className="cart-item-info cart-item-info-price" style={{ textDecoration: item.discountPrice ? "line-through" : "" }}>{item.price + " " + dict['kd']}</h6>
                                    <h6 className="cart-item-info cart-item-info-price" style={{ display: item.discountPrice ? "" : "none", color: "red" }}>{item.discountPrice + " " + dict['kd']}</h6>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-25px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ textDecoration: "underline", fontSize: "16px", cursor: mouseOverRemove === i ? "pointer" : "", color: mouseOverRemove === i ? "#E4BC4B" : "" }}
                                        onMouseOver={() => setMouseOverRemove(i)}
                                        onMouseOut={() => setMouseOverRemove(-1)}
                                        onClick={deleteItem(item._id)}
                                    >
                                        {dict["remove"]}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const noItemsMsg = () => {
        return (<h2 style={{ textAlign: "start" }}>{dict['wishlist_is_empty'] + "."}</h2>);
    }


    return (
        <div className="container-fluid" style={{ margin: "15px 0 70px" }}>
            <h1 style={{ textAlign: "start", fontSize: "18px" }}>{dict['wishlist']}</h1>
            <div style={{ display: "flex" }}>
                <div className="wishlist-items-container">
                    <h3 style={{ textAlign: "start" }}>{dict["items"]}</h3>
                    <hr />
                    {data.length > 0 && showItems()}
                    {data.length === 0 && noItemsMsg()}
                </div>
            </div>

        </div>
    )
}

export default Wishlist;