import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from "react-router-dom";
import {getResponse} from "./components/checkoutHelpers"


function Result() {
    const params = new URLSearchParams(window.location.search);
    let data = (params.get('data'));
    if(data){
        
    }
    return (
        <div className="container-fluid" style={{ margin: "15px 0 70px" }}>
            HDHDHHD
        </div>
    )
}

export default Result;