import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { API } from "../config.js"
import { default_url } from "../index.js";
import { dict } from "../dictionary.js";
import Button from '@material-ui/core/Button';
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

function Activate({ match }) {

    const [success, setSuccess] = useState(false);

    const userId = match.params.userId;
    const token = match.params.token;

    const history = useHistory();

    const clickSubmit = (event) => {
        return fetch(`${API}/users/activate/${userId}/${token}`, {
            method: "GET"
        })
            .then(response => {
                setSuccess(true);
            })
            .catch(err => {
                console.log(err);
            });
    }

    return (
        success ?
            <Redirect to={{ pathname: default_url + 'signin', state: { prevPath: "activate" } }} />
            :
            <div className="activateContainer">
                <Helmet>
                    <title>{default_url == "/en/" ? "Your Cart" : "سلة المشتريات"}</title>
                </Helmet>
                <h1 className="activateContainerH1" style={{ fontSize: "20px" }}>{dict["just_one_more_step"]}</h1>
                <p style={{ margin: "10px 0 20px", fontSize: "18px" }}>{dict["click_on_the_button_to_activate_your_account"]}</p>
                <Button onClick={clickSubmit} variant="contained" style={{ backgroundColor: "#E4BC4B", color: "white" }}>{dict["activate_account"]}</Button>
            </div>
    )
}

export default Activate;