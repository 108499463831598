import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { default_url } from "../../../..";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "auto"
    },
    formControl: {
        margin: theme.spacing(0),
    },
}));

const CityCheckBox = ({ cities, setCheckedCities, citiesList = [] }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(citiesList);

    const handleChange = (event) => {
        let newChecked = [...checked];
        let name = event.target.name;
        if (newChecked.indexOf(name) === -1) {
            newChecked.push(name);
        } else {
            newChecked.splice(checked.indexOf(name), 1);
        }
        setChecked(newChecked);
        setCheckedCities(newChecked)
    };

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl} style={{ marginBottom: "20px"}}>
                <FormGroup>
                    {
                        cities.map((city, index) => {
                            return (
                                <FormControlLabel
                                    control={<Checkbox checked={checked.indexOf(city._id) != -1} onChange={handleChange} name={city._id} />}
                                    label={city.arName}
                                />
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        </div>
    );

};

export default CityCheckBox;