import { Backdrop, CircularProgress, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Paper, Grid, Checkbox } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import { IMAGE_URL } from "../config"
import { default_url } from '..';
import { isAuthenticated } from '../auth';
import { dict } from '../dictionary';
import { prepareCheckout } from './apiCore';
import { getCart } from './cartHelpers';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CheckOut3(props) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const [donePayment, setDonePayment] = useState(false);
    const [loading, setLoading] = useState(true);
    const [addressIndex, setAddressIndex] = useState(0);
    const [userAddresses, setUserAddresses] = useState([]);
    const [chooseGift, setChooseGift] = useState(false);
    const [gift1, setGift1] = useState();
    const [gift2, setGift2] = useState();
    const [checked, setChecked] = useState([])
    const [showLowerTypes, setShowLowerTypes] = useState(false);

    const { user, token } = isAuthenticated();

    useEffect(() => {
        prepare();
    }, [])

    const prepare = async () => {
        let tmpCart = await getCart();
        let tot = 0;
        for (let i = 0; i < tmpCart.length; i++) {
            if (tmpCart[i].discountPrice && tmpCart[i].discountPrice > 0) {
                tot += (tmpCart[i].discountPrice * tmpCart[i].count);
            } else {
                tot += (tmpCart[i].price * tmpCart[i].count);
            }
        }
        prepareCheckout(user._id, tot).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                console.log(res);
                if (res.chooseGifts && res.chooseGifts.length > 0) {
                    setGift1(res.chooseGifts[0]);
                    let tmp = [];
                    for (let i = 1; i < res.chooseGifts.length; i++) {
                        for (let j = 0; j < res.chooseGifts[i].items.length; j++) {
                            tmp.push({ ...res.chooseGifts[i].items[j] })
                        }
                    }
                    console.log(tmp);
                    setGift2(tmp);
                    setChooseGift(true);
                }
                setUserAddresses(res.addresses);
                setLoading(false);
            }
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleRadioBtnChange = (e) => {

    }

    const showUserAddresses = () => {
        if (!isAuthenticated()) {
            return null;
        }
        return (
            <FormControl component="fieldset" style={{ width: "100%" }}>
                <RadioGroup aria-label="method" name="method" value={addressIndex} onChange={handleRadioBtnChange} >
                    {
                        userAddresses.map((a, i) => {
                            const curntAddress = (a.fName + " " + a.lName + ", " + a.mobile + "\n" + (default_url === "/en/" ? (a.enCity + ", " + a.enArea) : (a.arCity + ", " + a.arArea)) + "\n" + dict['block'] + ": " + a.block + ", " + dict['street'] + ": " + a.street + ", " + dict['lane'] + ": " + (a.lane != "" ? a.lane : dict['don\'t_exist']) + "\n" + dict['building'] + ": " + a.building + ", " + dict['floor'] + ": " + (a.floor != "" ? a.floor : dict['don\'t_exist']) + ", " + dict['apartment'] + ": " + (a.apartment != "" ? a.apartment : dict['don\'t_exist']) + "\n" + a.extra).split("\n");
                            let text = "<div>";
                            curntAddress.forEach(l => {
                                text += "<h1>" + l + "</h1>";
                            });
                            text += "</div>"
                            return (
                                i === userAddresses.length - 1 ?
                                    <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px", textAlign: "start" }} value={i} control={<Radio />} label={dict[a]} /> :
                                    <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px", textAlign: "start", marginBottom: "15px" }} value={i} control={<Radio />} label={<div>{curntAddress.map(l => { return <h1>{l}</h1> })}</div>} />
                            )
                        })
                    }
                </RadioGroup>
            </FormControl>
        )
    }

    const showLogin = () => {
        // if (isAuthenticated()) {
        //     return null;
        // } else {
        //     return (
        //         <div>
        //             <div>
        //                 <h3 className="formContainerTitle" dir={default_url == "/en/" ? "ltr" : "rtl"} style={{ marginBottom: "10px", fontSize: "14px" }}>{dict["login"]}</h3>
        //                 <form>
        //                     <input onChange={handleChangeSignIn("email")} className="inputField form-control" type="email" name="user-email" value={email} placeholder={dict["email"]} style={default_url == "/en/" ? enStylelabel : arStylelabel} required /><br />
        //                     <input onChange={handleChangeSignIn("password")} className="inputField form-control" type="password" name="user-password" value={password} placeholder={dict["password"]} required /><br />
        //                     <button onClick={clickSubmit} type="submit" className="loginBtn" style={{ marginTop: "5px", marginBottom: "20px" }}>{dict["login"]}</button>
        //                 </form>
        //             </div>
        //         </div>
        //     )
        // }
    }

    const showBreakLine = () => {
        // if (isAuthenticated()) {
        //     return null;
        // } else {
        //     return (
        //         <div style={{ marginBottom: "10px", marginTop: "20px" }}>
        //             <div style={{ width: "100%", height: "1px", backgroundColor: "black" }}></div>
        //             <div style={{ marginInlineStart: "24%", position: "relative", textAlign: "center", top: "-17.5px", padding: "5px 1%", backgroundColor: "white", width: "50%" }}>{dict['or_continue_as_guest']}</div>
        //         </div>
        //     )

        // }
    }

    const showAddressForm = () => {
        // return (
        //     <form style={{ display: (isAuthenticated() && addressIndex === userAddresses.length - 1) || !isAuthenticated() ? "" : "none" }}>
        //         {showError()}
        //         <h6 style={{ float: default_url === "/en/" ? "right" : "left", fontSize: "12px", color: "red", marginTop: "-5px" }}>{dict["required"]}</h6>
        //         <div>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["first_name"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="first_name" placeholder={dict["first_name"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["last_name"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="last_name" placeholder={dict["last_name"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("mobile")} type="number" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("usedEmail")} type="email" value={usedEmail} className="inputField form-control" id="usedEmail" placeholder="email@example.com"></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city"]} <span className="redStar">*</span></label><br />
        //             {citySelector()}
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area"]} <span className="redStar">*</span></label><br />
        //             {areaSelector()}
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["block"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("block")} type="text" value={block} className="inputField form-control" id="block" placeholder={dict["block"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["street"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("street")} type="text" value={street} className="inputField form-control" id="street" placeholder={dict["street"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["lane"]}</label>
        //             <input onChange={handleChange("lane")} type="text" value={lane} className="inputField form-control" id="lane" placeholder={dict["lane"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["building"]} <span className="redStar">*</span></label>
        //             <input onChange={handleChange("building")} type="text" value={building} className="inputField form-control" id="building" placeholder={dict["building"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["floor"]}</label>
        //             <input onChange={handleChange("floor")} type="text" value={floor} className="inputField form-control" id="floor" placeholder={dict["floor"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["apartment"]}</label>
        //             <input onChange={handleChange("apartment")} type="text" value={apartment} className="inputField form-control" id="apartment" placeholder={dict["apartment"]}></input>
        //             <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["extra_details"]}</label>
        //             <input onChange={handleChange("extra_details")} type="text" value={extra_details} className="inputField form-control" id="extra_details" placeholder={dict["extra_details"]}></input>
        //         </div>
        //     </form>
        // )
    }

    const showCheckOut = () => {
        return (
            <Fragment>
                {showBackdrop()}
                <h1 style={{ textAlign: "start", fontSize: "18px" }}>{dict['checkout']}</h1>
                <div style={{ display: window.innerWidth < 1024 ? "block" : "block", textAlign: "start" }}>
                    <div className="checkout-address-container" style={{ display: "inline-block", verticalAlign: 'top' }}>
                        <h3 style={{ textAlign: "start" }}>{dict["billing_address"]}</h3>
                        <hr />
                        {showUserAddresses()}
                        {showLogin()}
                        {showBreakLine()}
                        {showAddressForm()}
                    </div>
                    {/* <div className="checkout-payments-container" style={{ display: "inline-block", verticalAlign: 'top', marginInLineEnd: "3%", padding: 0, backgroundColor: "var(--background)", boxShadow: 'none', }}>
                        <div className="checkout-payments-container" style={{ display: "inline-block", verticalAlign: 'top', width: "100%", marginTop: "0" }}>
                            <h3 style={{ textAlign: "start" }}>{dict["payment_methods"]}</h3>
                            <hr />
                            <PaymentMethods
                                paymentMethod={(method) => {
                                    getPaymentMethod(method);
                                }}
                                grandTotal={parseFloat(grandTotal)}
                            />
                        </div>
                        {
                            user && activePoints == true && <Fragment>
                                <br />
                                <br />
                                <div className="checkout-payments-container" style={{ display: "inline-block", verticalAlign: 'top', width: "100%", marginTop: "0" }}>
                                    <h3 style={{ textAlign: "start" }}>{dict["points"]}</h3>
                                    <hr />
                                    {maxPoints == 0 ? dict["you_don't_have_any_points"] : dict['you_have'] + " " + maxPoints + " " + (default_url == "/en/" ? "points" : " نقطة") + " " + dict['valid_to_use'] + ". " + dict['would_you_like_to_use_them?']}
                                    {maxPoints > 0 && <div style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>
                                        {"(" + dict["you_can't_use_points_with_coupons"] + ")"}
                                    </div>}
                                    {maxPoints > 0 && <FormControl component="fieldset" >
                                        <RadioGroup aria-label="use" name="use" value={usePoint} onChange={handleUsePointChange} >
                                            <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="no" control={<Radio />} label={dict["no_i_wouldn't_like_to_use_them"]} />
                                            <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="yes" control={<Radio />} label={dict["yes_i_would_like_to_use_them"]} />
                                        </RadioGroup>
                                    </FormControl>}
                                </div>
                            </Fragment>
                        }
                    </div> */}
                    {/* <div className="checkout-items-container" style={{ display: "inline-block", verticalAlign: 'top' }}>
                        <h3 style={{ textAlign: "start" }}>{dict["order_review"]}</h3>
                        <hr />
                        {products.length > 0 && showItems()}
                        {gift.name && showGift()}
                        {products.length > 0 && showSummary()}
                        {products.length === 0 && noItemsMsg()}
                    </div> */}
                </div>
            </Fragment>

        )
    }

    const handleChekedChange = (id) => (event) => {
        let newChecked = [...checked];
        if (checked.indexOf(id) === -1) {
            if (checked.length < gift1.numberOfGifts) {
                newChecked.push(id);
            }

        } else {
            newChecked.splice(checked.indexOf(id), 1);
        }
        setChecked(newChecked)
    }

    const showChooseGift = () => {
        return (
            <Fragment>
                <div style={{ marginTop: "20px", padding: '0px 20px' }}>
                    <br />
                    <div>
                        <h5 className="productsContainerTitle" style={{ textAlign: "start", fontSize: "18px" }}>
                            {dict['gifts']}
                        </h5>
                        <div style={{ textAlign: "start", display: checked.length == gift1.numberOfGifts ? "none" : "", padding: "0 20px", fontSize: "12px" }}>
                            يرجى اختيار هديتك  (عدد الهدايا المتبيقة هو {gift1.numberOfGifts - checked.length})
                        </div>
                        <span style={{ textAlign: "start", display: checked.length == gift1.numberOfGifts ? "none" : "" }}>

                        </span>
                        {checked.length < gift1.numberOfGifts && <button disabled style={{ fontSize: "20px", padding: '10px' }} className="productViewAddToCart outOfStockButton" onClick={() => { }}>{dict["place_order"]}</button>}
                        {checked.length == gift1.numberOfGifts && <button style={{ fontSize: "20px", padding: '10px' }} className="productViewAddToCart" onClick={() => { }}>{dict["place_order"]}</button>}

                    </div>

                    <div className="container-fluid break-line"></div>
                    <div className={classes.root}>
                        <Grid container spacing={3}>
                            {
                                gift1.items && gift1.items.length > 0 && gift1.items.map((item, i) => {
                                    return (
                                        <Grid xs={6} sm={2} >
                                            <Paper className={classes.paper} style={{ marginBottom: "40px" }}>
                                                <FormControlLabel
                                                    value={item._id}
                                                    control={
                                                        <Checkbox
                                                            style={{ color: 'rgb(228, 188, 75)', fontSize: '30px' }}
                                                            checked={checked.indexOf(item._id) != -1}
                                                            onChange={handleChekedChange(item._id)}
                                                        />}
                                                    label={
                                                        <div style={{ color: 'black' }}>
                                                            <div>

                                                                <img style={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.3)' }} src={IMAGE_URL + item.image + "_small.jpg"} />
                                                                <h3 className="title" style={{ height: "auto", textAlign: "center", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>{item.title}</h3>
                                                            </div>
                                                        </div>
                                                    }
                                                    labelPlacement="top"
                                                />
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                            {
                               showLowerTypes && gift2.length > 0 && gift2.map((item, i) => {
                                    return (
                                        <Grid xs={6} sm={2} >
                                            <Paper className={classes.paper} style={{ marginBottom: "40px" }}>
                                                <FormControlLabel
                                                    value={item._id}
                                                    control={
                                                        <Checkbox
                                                            style={{ color: 'rgb(228, 188, 75)', fontSize: '30px' }}
                                                            checked={checked.indexOf(item._id) != -1}
                                                            onChange={handleChekedChange(item._id)}
                                                        />}
                                                    label={
                                                        <div style={{ color: 'black' }}>
                                                            <div>

                                                                <img style={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.3)' }} src={IMAGE_URL + item.image + "_small.jpg"} />
                                                                <h3 className="title" style={{ height: "auto", textAlign: "center", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>{item.title}</h3>
                                                            </div>
                                                        </div>
                                                    }
                                                    labelPlacement="top"
                                                />
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                            
                        </Grid>
                        <br />
                            <div style={{ textAlign: "center", fontSize: '20px', marginTop: "5px" }}>
                                {gift1.showLowerTypes && gift2 && gift2.length > 0 && !showLowerTypes && <button style={{ fontSize: "20px", padding: '10px', width: '250px' }} className="productViewAddToCart" onClick={() => {setShowLowerTypes(true)}}>{dict["show_lower_types"]}</button>}
                            </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <div className="container-fluid" style={{ margin: "15px 0 70px" }}>
            <title>{default_url == "/en/" ? "Checkout" : "الدفع"}</title>
            {!donePayment && !chooseGift && showCheckOut()}
            {!donePayment && chooseGift && showChooseGift()}
            {/* {donePayment && <Redirect to={{ pathname: default_url + (paymentResponse && paymentResponse.status === true ? 'thankyou' : "failed"), state: { orderNumber: orderNumber } }} />} */}
        </div>
    )
}

export default CheckOut3;