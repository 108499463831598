import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import CustemCheckbox from "./CustemCheckbox";
import { isAuthenticated } from "../../../../auth";
import { listCategories, listSubcategories, listProductsByFilters, productsCount } from "../../../apiCore";
import { exportAllProducts, downloadExportedFile } from "../../apiAdmin"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as qs from 'query-string';
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const ProductsAdminView = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const classes2 = useStyles2();
    const parsed = qs.parse(window.location.search);
    const [showLoadingBackdrop, setShowBackdrop] = useState(false);
    const [categoryFiletrs, setCategoryFilters] = useState([])
    const [subcategoryFiletrs, setSubcategoryFilters] = useState([])
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [filterdProducts, setFilterdProducts] = useState([]);
    const [numOfProducts1, setNumOfProducts1] = useState([]);
    const [numOfProducts2, setNumOfProducts2] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [error, setError] = useState(false);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [limit, setLimit] = useState(10);
    const [itemCode, setItemCode] = useState(parsed.itemCode);
    const [show, setShow] = useState(false);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: ""
    });

    const { user, token } = isAuthenticated();


    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={showLoadingBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const init = () => {
        listCategories().then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setCategories(data);
                let arr = new Map();
                data.map((c, i) => {
                    productsCount({ category: c._id }).then(data1 => {
                        if (data1.error) {
                            setError(data1.error);
                        } else {
                            arr[c._id] = parseInt(data1);
                            if (i == data.length - 1) {
                                setNumOfProducts1(arr);
                            }
                        }
                    })
                })
            }
        })
        listSubcategories().then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setSubcategories(data);
                let arr = new Map();
                data.map((s, i) => {
                    productsCount({ subcategory: s._id }).then(data1 => {
                        if (data1.error) {
                            setError(data1.error);
                        } else {
                            arr[s._id] = parseInt(data1);
                            if (i == data.length - 1) {
                                setNumOfProducts2(arr);
                            }
                        }
                    })
                })
            }
        })
    }

    const loadFilterdProducts = (cats, subcats) => {
        let show = parsed.show;
        listProductsByFilters(show ? 0 : skip, show ? show : limit, { itemCode: itemCode, category: cats, subcategory: subcats })
            .then(data => {
                if (data.error) {
                    setError(data.error)
                } else {
                    setFilterdProducts(data.data);
                    setSize(data.size);
                    setSkip(0);
                }
            })
    }

    const loadMore = () => {
        const params = new URLSearchParams(window.location.search);
        let show = parseInt(params.get('show'));
        if (!show) {
            show = 10
        }
        let toShow = show + 10;
        if (parsed.show) {
            console.log(changeShowParam(toShow));
            window.location.search = changeShowParam(toShow)
        } else {
            window.location.search += "&show=" + (toShow);
        }
        // listProductsByFilters(toSkip, limit, { itemCode: itemCode, category: categoryFiletrs, subcategory: subcategoryFiletrs })
        //     .then(data => {
        //         if (data.error) {
        //             setError(data.error)
        //         } else {
        //             setFilterdProducts([...filterdProducts, ...data.data]);
        //             setSize(data.size);
        //             setSkip(toSkip);
        //         }
        //     })
    }

    const changeShowParam = (show) => {
        let search = props.location.search.split("&");
        let res = "";
        let done = false;
        for (let i = 0; i < search.length; i++) {
            if (search[i].includes("show=")) {
                if (i == 0) {
                    res += "?"
                }
                res += ("show=" + show + "&");
                done = true;
            } else {
                res += search[i] + "&";
            }
        }
        if (!done) {
            res += ("show=" + show + "&");
        }
        return (res.substring(0, res.length - 1));
    }

    useEffect(() => {
        setItemCode(parsed.itemCode);
        init();
        loadFilterdProducts([], []);
    }, []);


    const handleCategoriesFilter = catsFilters => {
        let count = 0;
        if (catsFilters.length == 0) {
            categories.map(c => {
                count += numOfProducts1[c._id];
            });
        } else {
            let current = [...catsFilters]
            current.map(c => {
                count += numOfProducts1[c];
            });
        }
        loadFilterdProducts(catsFilters, subcategoryFiletrs, skip, limit);
        setCategoryFilters(catsFilters);
        setTotalProducts(count);
        setItemCode("");
    }

    const handleSubcategoriesFilter = subcatsFilters => {
        if (subcatsFilters.length == 0) {
            handleCategoriesFilter(categoryFiletrs);
        } else {
            let count = 0;
            let current = [...subcatsFilters]
            current.map(s => {
                count += numOfProducts2[s];
            });
            setTotalProducts(count);
        }
        setSubcategoryFilters(subcatsFilters);
        loadFilterdProducts(categoryFiletrs, subcatsFilters, skip, limit);
        setItemCode("");
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        let st = "";
        const params = new URLSearchParams(window.location.search);
        let itemCode2 = params.get('itemCode');
        if (itemCode2) {
            st += ("?itemCode=" + itemCode2)
        }
        let show = parseInt(params.get('show'));
        if (show) {
            if (st == "") {
                st += ("?show=" + show)
            } else {
                st += ("&show=" + show)
            }
        }
        history.push(default_url + "admin/products/create" + st);
        //window.location.href = default_url + "admin/products/create" + st
    }

    const clickSubmitManageColors = (event) => {
        event.preventDefault();
        history.push(default_url + "admin/colors");
        //window.location.href = default_url + "admin/colors"
    }

    const clickSubmitCollections = (event) => {
        event.preventDefault();
        history.push(default_url + "admin/collections");
        //window.location.href = default_url + "admin/collections"
    }

    const clickSubmitUpdateProdcts = (event) => {
        event.preventDefault();
        history.push(default_url + "admin/products/update-many");
        //window.location.href = default_url + "admin/products/update-many"
    }

    const clickSubmitDeleteProdcts = (event) => {
        event.preventDefault();
        history.push(default_url + "admin/products/delete-many");
        //window.location.href = default_url + "admin/products/delete-many"
    }

    const clickSubmitExportProducts = (event) => {
        event.preventDefault();
        setShowBackdrop(true);
        exportAllProducts(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                downloadExportedFile(user._id, token).then(res2 => {
                    setShowBackdrop(false);
                })
            }
        })
    }

    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        let st = "";
        const params = new URLSearchParams(window.location.search);
        let itemCode2 = params.get('itemCode');
        if (itemCode2) {
            st += ("&itemCode=" + itemCode2)
        }
        let show = parseInt(params.get('show'));
        if (show) {
            st += ("&show=" + show)
        }
        window.location.href = (default_url + "admin/products/delete?product_id=" + deletedItem.id + st)
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_product"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + " product?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد حذف المنتج " + deletedItem.name + "؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const loadMoreBtn = () => {
        const params = new URLSearchParams(window.location.search);
        let show = parseInt(params.get('show'));
        if (!show) {
            show = 10
        }
        return (
            size > 0 && size >= show && (
                <button onClick={loadMore} type="submit" className="dashboardBtn" style={{ marginBottom: "0px" }}>{dict["show_more"]}</button>
            )
        )
    }

    const allProducts = () => {
        return (
            <Fragment>
                <h3 className="formContainerTitle">{dict["filterd_products"]} {/*"(" + totalProducts + " products)"*/}</h3>
                {filterdProducts.length === 0 && <p style={{ textAlign: "start" }}>{dict["no_products_found"]}</p>}
                {filterdProducts.sort((a, b) => (a.itemCode) - (b.itemCode)).map((p, i) => {
                    return (
                        <div key={i} className={"dataContainer " + (i === filterdProducts.length - 1 ? "lastDataContainer" : "")}>
                            <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right" }}>{(i + 1)}</span>
                            <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right" }}>{p.itemCode}</span>
                            <a className="dataContainerAction"
                                href="#"
                                onClick={handleShow}
                                name={p.itemCode}
                                id={p._id}
                                style={{ float: default_url == "/en/" ? "right" : "left" }}
                            >{dict["delete"]}</a>
                            {popUp()}
                            <Link className="dataContainerAction" to={default_url + "admin/products/edit?product_id=" + p._id + (itemCode ? "&itemCode=" + itemCode : "") + (parseInt((new URLSearchParams(window.location.search)).get('show')) ? "&show=" + parseInt((new URLSearchParams(window.location.search)).get('show')) : "")} style={{ float: default_url == "/en/" ? "right" : "left" }}>{dict["edit"]}</Link>
                            <br></br>
                        </div>
                    )
                })}
            </Fragment>
        )
    }

    const showAlert = () => {
        if ((props.location.state)) {
            let msg = ""
            if (props.location.state) {
                switch (props.location.state.prevPath) {
                    case "create": msg = props.location.state.error ? props.location.state.error : dict["new_product_has_been_created"]; break;
                    case "delete": msg = props.location.state.error ? props.location.state.error : dict["product_has_been_deleted"]; break;
                    case "edit": msg = props.location.state.error ? props.location.state.error : dict["product_has_been_updated"]; break;
                    default: msg = "";
                }
            }
            return (
                <div
                    className={"alert " + (((props.location.state && props.location.state.error)) ? "alert-danger" : "alert-info") + " alert-dismissible fade show"}
                    role="alert"
                    style={{ width: "75%" }}
                >
                    <h2 style={{ textAlign: "center" }}>{msg}</h2>
                    <button type="button" class="close alertCloseBtn" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!parsed.length) {
            window.location.search = "?itemCode=" + itemCode;
        } else {
            window.location.search = "&itemCode=" + itemCode;
        }

        setSkip(0);
        listProductsByFilters(0, limit, { category: [], subcategory: [], itemCode: itemCode }).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setFilterdProducts(res.data);
                setSize(res.size);
            }
        })
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer" style={{ position: "relative" }}>
                {showBackdrop()}
                {/* <h3 className="formContainerTitle">{dict["productsـlist"]}</h3> */}
                <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off" style={{ position: "absolute", left: default_url === "/en/" ? "" : "15px", right: default_url === "/en/" ? "15px" : "", top: "0px" }}>
                    <TextField value={itemCode} id="standard-basic" label="Item Code" variant="outlined" style={{ margin: "15px 0", direction: "ltr" }} onChange={(event) => { setItemCode(event.target.value) }} />
                </form>
                {showAlert()}
                <div className="row" style={{ marginTop: "35px" }}>
                    <div className="col-lg-2 col-sm-12">
                        <CustemCheckbox
                            categories={categories}
                            subcategories={subcategories}
                            categoriesFilter={catsFilters => {
                                handleCategoriesFilter(catsFilters)
                            }}
                            subcategoriesFilter={subcatsFilters => {
                                handleSubcategoriesFilter(subcatsFilters)
                            }}
                            numOfProducts1={numOfProducts1}
                            numOfProducts2={numOfProducts2}
                        />
                        <div >
                            <button style={{marginBottom: "5px"}} onClick={clickSubmitManageColors} type="submit" className="dashboardBtn">{dict["manage_colors"]}</button>
                        </div>
                        <div >
                            <button  onClick={clickSubmitCollections} type="submit" className="dashboardBtn">{dict["collections"]}</button>
                        </div>
                    </div>
                    <div className="col-lg-10 col-sm-12">
                        {
                            allProducts()
                        }
                        {loadMoreBtn()}
                        <div className="row">
                            <div className="col-3">
                                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_product"]}</button>
                            </div>
                            <div className="col-3">
                                <button onClick={clickSubmitUpdateProdcts} type="submit" className="dashboardBtn">{dict["update_products"]}</button>
                            </div>
                            <div className="col-3">
                                <button onClick={clickSubmitDeleteProdcts} type="submit" className="dashboardBtn">{dict["delete_multiple_products"]}</button>
                            </div>
                            <div className="col-3">
                                <button onClick={clickSubmitExportProducts} type="submit" className="dashboardBtn">{dict["export_all_products"]}</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default ProductsAdminView;