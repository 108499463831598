import React, { useState, useEffect, Fragment, useRef } from 'react';
import ProductItem from './ProductItem'
import { default_url } from "../../../index"
import { getSubcategory } from "../../apiCore"
import { Item } from 'semantic-ui-react';
import { IMAGE_URL } from "../../../config";
import { max } from 'moment';
import { InfoRounded } from '@material-ui/icons';
import $ from "jquery";
import Slider from '@bit/akiran.react-slick.slider';

function ProductsMenu(props) {
    const [products1, setProducts1] = useState([]);
    const divRef = useRef(null);
    const silderSettings = {
        infinite: false,
        rtl: default_url == "/en/" ? false : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const params = new URLSearchParams(window.location.search);
    let order = params.get('order');
    let dir;
    if (order && order == "price") {
        dir = params.get('dir');
    }

    var data2 = [];
    var myItems = props.items;
    let data = myItems.sort((a, b) => {
        if (a.enTitle === b.enTitle) {
            return a.price - b.price;
        }
        return (a.enTitle - b.enTitle);
    });
    let items3 = [...props.items3];

    useEffect(() => {
        for (let i = 0; i < items3.length; i++) {
            let minPrice = 10000, maxPrice = -1000;
            for (let j = 0; items3[i].price && j < items3[i].price.length; j++) {
                minPrice = Math.min(minPrice, items3[i].price[j]);
                maxPrice = Math.max(maxPrice, items3[i].price[j]);
            }

            let a = []
            for (let j = 0; items3[i].price && j < items3[i].price.length; j++) {
                if (items3[i].discountPrice[j]) {
                    a.push(items3[i].discountPrice[j])
                } else {
                    a.push(items3[i].price[j]);
                }
            }
            let b = a.sort((a, b) => {
                return a - b
            });
            let minPriceToShow = b[0];
            let maxPriceToShow = b[b.length - 1];
            let discount = false;
            if (minPrice > minPriceToShow || maxPrice > maxPriceToShow) {
                discount = true;
            }

            // let minDiscountPrice = 10000;
            // 
            // for (let j = 0; items3[i].discountPrice && j < items3[i].discountPrice.length; j++) {
            //     if (items3[i].discountPrice[j] && items3[i].discountPrice[j] < minPrice) {
            //         minDiscountPrice = Math.min(minDiscountPrice, items3[i].discountPrice[j]);
            //         discount = true;
            //     }
            // }

            items3[i] = { ...items3[i], minPrice, maxPrice, discount, minPriceToShow, maxPriceToShow }

            let image1 = null, image2 = null;

            for (let j = 0; items3[i].images && j < items3[i].images.length; j++) {
                for (let k = 0; k < items3[i].images[j].length; k++) {
                    let tmp = items3[i].images[j][k].split("/");
                    items3[i].images[j][k] = IMAGE_URL + tmp[tmp.length - 1] + "_small.jpg";
                    if (image1 == null && k == 0) {
                        image1 = items3[i].images[j][k];
                    }
                    if (image2 == null && k == items3[i].images[j].length - 1) {
                        image2 = items3[i].images[j][k];
                    }
                }
            }
            items3[i] = { ...items3[i], image1, image2, link: default_url + "products/" + (items3[i].category && items3[i].category.enName) + "/" + (items3[i].subcategory && items3[i].subcategory.enName ? items3[i].subcategory.enName : items3[i].category.enName) + "/" + items3[i].enTitle, }
        }
        setProducts1(items3)
    }, [props.items3])



    for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let found = false;
        let imgLen = data[i].images.length;
        let a = imgLen > 0 ? data[i].images[0].split("/") : "";
        let img1 = IMAGE_URL + a[a.length - 1] + "_small.jpg";
        let img2 = "";
        if (props.category != undefined && (props.category === "thermos" || (data[i].category.enName).toLowerCase() === "thermos" && imgLen > 0)) {
            a = data[i].images[imgLen - 1].split("/");
            img2 = IMAGE_URL + a[a.length - 1] + "_small.jpg";
        } else if (imgLen > 0) {
            if (imgLen >= 2) {
                a = data[i].images[1].split("/");
            } else {
                a = data[i].images[0].split("/");
            }
            img2 = IMAGE_URL + a[a.length - 1] + "_small.jpg";
        }

        for (let j = 0; (props.category === "thermos" || props.search || props.list) && j < data2.length; j++) {

            if (data2[j] && data2[j].enTitle && data2[j].enTitle == item.enTitle) {
                found = true;
                data2[j].prices = [...data2[j].prices, data[i].price]
                data2[j].prices.sort(function (a, b) {
                    return a - b;
                });

                data2[j].discountPrices = [...data2[j].discountPrices, data[i].discountPrice ? data[i].discountPrice : data[i].price]
                data2[j].discountPrices.sort(function (a, b) {
                    return a - b;
                });

                data2[j].sizes = [data2[j].sizes, data[i].size]

                data2[j].sold += parseInt(data[i].sold);
                data2[j].img1 = data[i].price > data2[j].minPrice ? img1 : data2[j].img1
                break;
            }
        }
        if (!found) {
            data2.push(
                {
                    arTitle: data[i].arTitle,
                    enTitle: data[i].enTitle,
                    prices: [data[i].price],
                    discountPrices: [data[i].discountPrice ? data[i].discountPrice : data[i].price],
                    link: default_url + "products/" + (data[i].category && data[i].category.enName) + "/" + (data[i].subcategory && data[i].subcategory.enName ? data[i].subcategory.enName : data[i].category.enName) + "/" + data[i].enTitle,
                    sold: parseInt(data[i].sold),
                    img1: img1,
                    img2: img2
                }
            )
            if (item.size) {
                data2[data2.length - 1] = { ...data2[data2.length - 1], sizes: [item.size] }
            }
        }
    }
    let x = parseInt(Math.ceil(parseInt(data2.length) / props.limit));
    let arr = Array.from({ length: x }, (_, i) => i + 1);


    const shadowStyle = { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }

    const cssstyle = `
    .silderContainer {
      margin: 0 0 40px;
      padding: 20px 20px;
      background: white;
    }
    .silderDiv {
        #background: black;
        color: #fff;
        font-size: 36px;
        margin: 10px;
        padding: 2%;
        position: relative;
        text-align: center;
    }
    .slick-next:before, .slick-prev:before {
        color: #000;
    }
    .slick-dots{
        direction: rtl;
    }
    .slick-slide{
        float: left !important;
    }
    `

    const cssstyle2 = `
.container {
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
  width: 800px;
}
h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}
.center h3 {
    transition: all .3s ease;
}
.slick-slide{
    float: left;
}
`

    // return (
    //     <Fragment>
    //         <div style={{ width: "100%" }}>
    //             {
    //                 products1.length > 0 ?
    //                     <div className="product-container productMenu" style={props.divClass === "col-lg-2 col-md-4 col-6" ? shadowStyle : {}}>
    //                         <div className="row">
    //                             {products1.sort((a, b) => {
    //                                 if (order && order == "price") {
    //                                     if (dir && dir == "asc") {
    //                                         return (
    //                                             a.minPrice - b.minPrice
    //                                         )
    //                                     } else if (dir && dir == 'desc') {
    //                                         return (
    //                                             b.maxPrice - a.maxPrice
    //                                         )
    //                                     }
    //                                 }
    //                                 return b.sold - a.sold
    //                             }).slice(startAt, props.skip ? props.skip * props.limit : startAt + props.limit).map((item, index) => {
    //                                 return (
    //                                     <ProductItem
    //                                         key={index}
    //                                         divClass={props.divClass}
    //                                         title={default_url == "/en/" ? item.enTitle : item.arTitle}
    //                                         discount={item.discount}
    //                                         prices={item.price}
    //                                         discountPrices={item.discountPrice}
    //                                         model={""}
    //                                         img1={item.image1}
    //                                         img2={item.image2}
    //                                         link={item.link}
    //                                         minPrice={item.minPrice}
    //                                         maxPrice={item.maxPrice}
    //                                         minPriceToShow={item.minPriceToShow}
    //                                         maxPriceToShow={item.maxPriceToShow}
    //                                         size={item.size}
    //                                         ids={item._id}
    //                                         images={item.images}
    //                                     />
    //                                 )
    //                             })}
    //                         </div>
    //                     </div>
    //                     : <div style={{ marginTop: "15px" }}>No Products Found!</div>
    //             }
    //         </div>
    //     </Fragment>
    // )


    return (
        <Fragment>
            <div style={{ width: "100%" }}>
                {
                    products1.length > 0 ?
                        <div className="product-container productMenu" style={props.divClass === "col-lg-2 col-md-4 col-6" ? shadowStyle : {}}>
                            <div className="silderContainer">
                                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                                <style>{cssstyle}</style>
                                <Slider {...silderSettings}>
                                    {products1.sort((a, b) => {
                                        if (order && order == "price") {
                                            if (dir && dir == "asc") {
                                                return (
                                                    a.minPrice - b.minPrice
                                                )
                                            } else if (dir && dir == 'desc') {
                                                return (
                                                    b.maxPrice - a.maxPrice
                                                )
                                            }
                                        }
                                        return b.sold - a.sold
                                    }).map((item, index) => {
                                        return (
                                            <div>
                                                <div className="silderDiv">
                                                    <ProductItem
                                                        key={index}
                                                        divClass={""}
                                                        title={default_url == "/en/" ? item.enTitle : item.arTitle}
                                                        discount={item.discount}
                                                        prices={item.price}
                                                        discountPrices={item.discountPrice}
                                                        model={""}
                                                        img1={item.image1}
                                                        img2={item.image2}
                                                        link={item.link}
                                                        minPrice={item.minPrice}
                                                        maxPrice={item.maxPrice}
                                                        minPriceToShow={item.minPriceToShow}
                                                        maxPriceToShow={item.maxPriceToShow}
                                                        size={item.size}
                                                        ids={item._id}
                                                        images={item.images}
                                                        quantity={item.quantity}
                                                        category={props.category}
                                                        subcategory={props.subcategory}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                        : <div style={{ marginTop: "15px" }}>No Products Found!</div>
                }
            </div>
        </Fragment>
    )

}

export default ProductsMenu;