import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createList } from "../../apiAdmin"
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";

function AddList() {
    const [values, setValues] = useState({
        arName: "",
        enName: "",
        active: null,
        error: "",
        success: false,
    });


    const { user, token } = isAuthenticated();
    const { arName, enName, active, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let list = { arName: arName, enName: enName, active: active }
        createList(list, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ arName: "", enName: "", active: null, error: null, success: true });
            }
        })
    }


    const activeSelector = () => {
        return (
            <select onChange={handleChange("active")} className="inputField form-control" value={active}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_list_has_been_added.add_more_or']} <a href={default_url + "admin/lists"} style={{ fontSize: "18px" }}>{dict["go_back_to_lists"]}</a>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_new_list"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["list_name_in_arabic"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="arName" placeholder={dict["list_name_in_arabic"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["list_name_in_english"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="enName" placeholder={dict["list_name_in_english"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["active"]} <span className="redStar">*</span></label><br />
                    {activeSelector()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="7"
            data={form}
        />
    )
}

export default AddList;