import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LanguageIcon from '@material-ui/icons/Language';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { itemTotal, getCart } from "../cartHelpers"
import { setDic } from '../../dictionary';
import { numOfWishlistItems } from '../wishlistHelpers';
import { numOfCompareItems } from '../compareHelpers';
import { isAuthenticated } from '../../auth';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(2),
        },
    },
}));

function MainBarItem(props) {
    const [totalCartItem, setTotalCartItem] = useState();
    const [totalWishlistItem, setTotalWishlistItem] = useState();
    const [totalCompareItem, setTotalCompareItem] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024 ? true : false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTotalCartItem(itemTotal());
        setTotalWishlistItem(numOfWishlistItems());
        setTotalCompareItem(numOfCompareItems());
    }, []);


    const defaultProps = {
        color: 'secondary',
        children: props.type == "login" ? <AccountBoxIcon /> : props.type == "points" ? <MonetizationOnIcon /> : props.type == "wishlist" ? <FavoriteIcon /> : props.type == "language" ? <LanguageIcon /> : <ShoppingCartIcon />
    };
    const classes = useStyles();

    if (props.type === "login" && isAuthenticated()) {
        return (
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    Open Menu
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu>
            </div>
        )
    }

    let languagesStyle = {
        padding: "6.5% 0px 0px",
        marginInlineEnd: props.mobile ? "0" : "15px",
        textAlign: "start",
        paddingInlineStart: "5%"
    }

    return (
        <div className={"main-bar-" + props.type + (props.type === "languages" ? " col-2" : "")} style={props.type === "languages" ? languagesStyle : {}}>
            <div className={"main-bar-" + props.type + "-content"} style={{paddingTop: props.mobile && props.type == "languages" ? "8px" : "0"}}>
                <Link className="main-bar-link" to={props.link} onClick={props.onClick} style={props.type === "languages" ? {fontSize: "18px"} : {}}>
                    {
                        props.type == "login" && <Badge {...defaultProps} style={{ marginInlineEnd: "10px" }} />
                    }
                    {
                        props.type == "language" && <Badge {...defaultProps} style={{ marginInlineEnd: "10px" }} />
                    }
                    {
                        props.type == "points" && <Badge {...defaultProps} badgeContent={totalCompareItem} style={{ marginInlineEnd: "10px" }} />
                    }
                    {
                        props.type == "wishlist" && <Badge {...defaultProps} badgeContent={totalWishlistItem} style={{ marginInlineEnd: "10px" }} />
                    }
                    {
                        props.type == "cart" && <Badge {...defaultProps} badgeContent={totalCartItem} style={{ marginInlineEnd: "10px" }} />
                    }
                    <span>{props.details}</span>
                </Link>
            </div>
        </div>
    )
}

export default MainBarItem;