import React from "react";

function ProductsNavLink(props){
    return(
        <div >
            <a className="productsNavLink" href={props.link}>{props.title}</a>
            <span className="slash">{props.slash}</span>
        </div>
    )
}

export default ProductsNavLink;