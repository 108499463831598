import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { deleteDistributor, downloadExportedDistributors, exportAllDistributors } from "../../apiAdmin"
import { listDistributors } from "../../../apiCore"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link, useHistory } from "react-router-dom";


function DistributorsAdmin() {
    let history = useHistory();
    const [distributors, setDistributors] = useState([]);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState(false);

    const { user, token } = isAuthenticated();

    const loadDistributors = () => {
        listDistributors().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDistributors(res);
            }
        });
    }

    useEffect(() => {
        loadDistributors();
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        deleteDistributor(deletedItem.id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadDistributors();
                setDeletedItem({ ...deletedItem, success: true });
            }
        })
    }

    const clickSubmit = target => () => {
        if (target == "add") {
            history.push(default_url + "admin/distributors/add");
        } else if (target == "replace") {
            history.push(default_url + "admin/distributors/replace");
        } else if (target == "export") {
            //event.preventDefault();
            // setLoading(true);
            exportAllDistributors(user._id, token).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    downloadExportedDistributors(user._id, token).then(res2 => {
                        // setLoading(false);
                    })
                }
            })
        }
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_distributor"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete "} <span style={{ color: 'red' }}> {deletedItem.name} </span> {" from distributors?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد ازالة  "} <span style={{ color: 'red' }}> {deletedItem.name} </span> {" من قائمة الموزعين؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAlert = () => {
        if (deletedItem.success) {
            return (
                <div
                    className={"alert alert-info alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{dict["distributor_has_been_deleted"]}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const allDistributors = () => {
        return (
            distributors.map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === distributors.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right" }}>{item.name + " - " + (default_url == "/ar/" ? item.arArea : item.enArea)}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.name + " - " + (default_url == "/ar/" ? item.arArea : item.enArea)}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left" }}
                        >{dict["delete"]}</a>
                        {popUp()}
                        {/* <a className="dataContainerAction" href={default_url + "admin/distributors/edit?distributor_id=" + item._id} style={{ float: default_url == "/en/" ? "right" : "left" }}>{dict["edit"]}</a> */}
                    </div>
                )
            })
        )
    }

    const showEmpty = () => {
        return (
            <h1 style={{ textAlign: "start" }}>{dict["no_products_found"]}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["distributors"]}</h3>
                {distributors.length > 0 ? allDistributors() : showEmpty()}
                <button onClick={clickSubmit("add")} type="submit" className="dashboardBtn" style={{ marginInlineEnd: "2%", width: "32%" }}>{dict["add_new_distributor"]}</button>
                <button onClick={clickSubmit("replace")} type="submit" className="dashboardBtn" style={{ marginInlineEnd: "2%", width: "32%" }}>{dict["replace_current_distributors"]}</button>
                <button onClick={clickSubmit("export")} type="submit" className="dashboardBtn" style={{ marginInlineEnd: "0", width: "32%" }}>{dict["export_all_distributors"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="15"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default DistributorsAdmin;