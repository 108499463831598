import React, { useState, useEffect, Fragment } from "react";
import MobileMainBarItem from "./category components/MobileMainBarItem"
import CategoryItem from "./category components/CategoryItem"
import CategoryItemWithChildren from "./category components/CategoryItemWithChildren"
import { isAuthenticated } from "../auth"
import { listCategories, listSubcategories } from "./apiCore"
import { from } from "form-data";
import Categories from "./admin/admin dashboard components/catogery/Categories";
import CategoriesMenu from "./category components/CategoriesMenu"
import Hide from "./category components/Hide"
import { default_url, setDir } from "../index"
import { dict } from "../dictionary"
import { IMAGE_URL } from "../config";
import TextField from '@material-ui/core/TextField';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import MainBarItem from "./main bar components/MainBarItem"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from "react-router-dom";
var MediaQuery = require('react-responsive');


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

function Category() {
    let history = useHistory();
    const classes = useStyles();
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024 ? true : false);
    const [isMobile2, setIsMobile2] = useState(window.innerWidth < 1024 ? true : false);
    const [isHidden, setIsHidden] = useState(false)
    //const [isDropDownActive, setIsDropDownActive] = useState(window.location.pathname == "/" && !isMobile ? true : false);
    const [isDropDownActive, setIsDropDownActive] = useState(false);
    const [search, setSearch] = useState("");

    const { user, token } = isAuthenticated();

    const params = new URLSearchParams(window.location.search);
    let q = params.get('q');

    const loadCategories = async () => {
        await listCategories().then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setCategories(data);
                cat = data;
            }
        })
    }

    const loadSubcategories = async () => {
        await listSubcategories().then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setSubcategories(data);
            }
        })
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (search.length > 0) {
            if(window.location.pathname.includes("search")){
                window.location.href = default_url + "search/products?q=" + search;
            }else{
                history.push(default_url + "search/products?q=" + search);
            }
            //window.location.href = default_url + "search/products?q=" + search;
        }
    }

    useEffect(() => {
        if (q) {
            setSearch(q);
        }else{
            setSearch("");
        }
    }, [q])

    useEffect(() => {
        const fetchData = async () => {
            await loadCategories();
            await loadSubcategories();
            await setLoading(false);
            // let paths = window.location.pathname.split("/");
            // if ((paths.length == 2 || (paths.length == 3 && paths[2] == "")) && !isMobile) {
            //     setIsDropDownActive(true);
            // }
        }
        fetchData();
    }, []);

    let cat = [
        {
            title: "Thermos",
            items: [
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                }
            ]
        },
        {
            title: "Thermos Acc",
            items: [
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                }
            ]
        },
        {
            title: "Tea & Coffee Sets",
            items: [
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                }
            ]
        },
        {
            title: "Steal Tray",
            items: [
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                }
            ]
        },
        {
            title: "Porcelain",
            items: [
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                },
                {
                    imgSrc: "/img/thermos/rs_707/RS-707-a01.jpg",
                    titleText: "RS-707"
                }
            ]
        }
    ];

    const handleClose = () => {
        if (isDropDownActive) {
            handleHidden(false);
        }
        setIsDropDownActive(!isDropDownActive);
        setIsMobile(false);
    }

    const closeCategories = () => {
        setIsHidden(true);
    }

    const handleHidden = (value) => {
        setIsHidden(value);
    }

    if (loading) {
        return (
            <div>Loading...</div>
        )
    }

    const handleChangeLang = () => {
        if (default_url === "/en/") {
            setDir("rtl");
        } else {
            setDir("ltr");
        }
        let paths = window.location.pathname.split("/");
        let newPath = default_url;
        for (let i = 2; i < paths.length; i++) {
            newPath += paths[i] + "/"
        }
        window.location.href = (newPath.substring(0, newPath.length - 1));
    }

    const clickSubmit = (e) => {
        e.preventDefault();
        history.push(default_url + "collections")
        //window.location.href = default_url + "collections"
    }

    return (
        <div style={{ display: !isMobile2 ? "flex" : "relative" }}>
            <header>
                <div className={"cd-dropdown-wrapper " + (default_url == "/ar/" ? " open-to-left" : "")}>
                    <div className="main-bar-mobile">
                        <div className="row" style={{ marginInlineStart: "0", marginInlineEnd: "0" }}>
                            <div className="col-2 text-center align-self-center" style={{ padding: "0" }}>
                                <a className={"cd-dropdown-trigger " + (isDropDownActive ? " dropdown-is-active" : "")} onClick={handleClose} href="#">
                                    <h3 className="cd-dropdown-trigger-title">{dict["categories"]}</h3>
                                    <div className="burger">
                                        <div className="line"></div>
                                        <div className="line"></div>
                                        <div className="line" style={{marginBottom: "0"}}></div>
                                        <span style={{fontSize: "12px"}}>
                                            {dict["categories"]}
                                        </span>
                                    </div>

                                </a>
                            </div>
                            <div className="col-2 text-center align-self-center mobileOnly" style={{ padding: 0 }}>
                                <div className="logo-in-mobile ">
                                    <Link className="logo-in-mobile-link text-center align-self-center" to={default_url}>

                                        <img src={IMAGE_URL + "logo_mob.jpg"} className="img-logo-in-mobile" alt="" />
                                        <img src={IMAGE_URL + "arabic_logo.jpg"}></img>
                                    </Link>
                                </div>
                            </div>
                            {isMobile2 && <MainBarItem
                                type="languages"
                                details={dict["lang"]}
                                onClick={handleChangeLang}
                                link="#"
                                mobile={true}
                            />}
                            {/* <MobileMainBarItem
                                type="cart"
                                icon="fas fa-shopping-cart"
                                label="icon-label-cart-in-mobile"
                                labelText="22"
                                link={`${default_url}cart/`}
                            /> */}
                            {!isAuthenticated() &&
                                <MobileMainBarItem
                                    type="login"
                                    icon="fas fa-user-circle"
                                    link={`${default_url}signin/`}
                                />
                            }
                            {isAuthenticated() &&
                                <MobileMainBarItem
                                    type="login"
                                    icon="fas fa-user-circle"
                                    link={(isAuthenticated().user.role === 0 ? `${default_url}user/` : isAuthenticated().user.role === 1 ? `${default_url}admin/` : `${default_url}storage/`) + "account-information"}
                                />
                            }
                            {/* <MobileMainBarItem
                                type="wishlist"
                                icon="fas fa-heart"
                                label="icon-label-cart-in-mobile"
                                labelText="21"
                                link={`${default_url}wishlist`}
                            /> */}

                            <MobileMainBarItem
                                type="cart"
                                icon="fas fa-shopping-cart"
                                label="icon-label-cart-in-mobile"
                                labelText="22"
                                link={`${default_url}cart/`}
                            />
                            <MobileMainBarItem
                                type="points"
                                icon="fas fa-clipboard-check"
                                link={user && user.isPointsActive ? default_url + "user/points" : default_url + "points"}
                                label="icon-label-cart-in-mobile"
                                labelText="22"
                            />
                        </div>
                    </div>
                    {/* <nav className={"cd-dropdown " + (isDropDownActive && !isMobile ? " dropdown-is-active" : "")}>
                        <h2>{dict["menu"]}</h2>
                        <a href="#" className="cd-close" style={{ display: isHidden ? "none" : "" }} onClick={handleClose}>{dict["close"]}</a>
                        <ul className={"cd-dropdown-content " + (isHidden ? "move-out" : "")}>
                            {isMobile2 && !isHidden && <li>
                                <form onSubmit={handleSearchSubmit}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        id="input-with-icon-textfield"
                                        value={search}
                                        label={dict["search"]}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" >
                                                    <SearchOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ dir: "auto", style: { padding: "0 10px 10px" } }}
                                        InputLabelProps={{ style: { left: "auto" } }}
                                        onChange={event => { setSearch(event.target.value) }}

                                    />
                                </form>

                            </li>}
                            {
                                categories.sort((a, b) => a.position - b.position).map((c, i) => {
                                    if (!c.hidden) {
                                        if (c.hasSubcategory) {
                                            let currentSubcategories = subcategories.filter(s => {
                                                return (s.category == c._id && !s.hidden);
                                            })
                                            return (
                                                <CategoryItemWithChildren
                                                    key={i}
                                                    link={`${default_url}products/` + (c.enName).toLowerCase()}
                                                    description={default_url == "/en/" ? c.enName : c.arName}
                                                    enName={c.enName}
                                                    items={currentSubcategories.length != 0 ? currentSubcategories : []}
                                                    handleHidden={value => {
                                                        handleHidden(value);
                                                    }}
                                                    hidden={false}
                                                />
                                            )
                                        } else {
                                            return (
                                                <CategoryItem
                                                    key={i}
                                                    link={`${default_url}products/` + (c.enName).toLowerCase()}
                                                    description={default_url == "/en/" ? c.enName : c.arName}
                                                />
                                            )

                                        }
                                    }
                                })
                            }
                        </ul>
                        <div className="dropdown-footer">
                            <a className="dropdown-footer-link" href={default_url + "wishlist"}>{dict["wishlist"]}</a>
                            <a className="dropdown-footer-link" href={default_url + "compare"}>{dict["compare"]}</a>
                            <a className="dropdown-footer-link" href="#">{dict["about_us"]}</a>
                            <a className="dropdown-footer-link" href="#" onClick={handleChangeLang}>{dict['lang']}</a>
                        </div>
                    </nav> */}


                    <nav className={"cd-dropdown " + (isDropDownActive && !isMobile ? " dropdown-is-active" : "")}>
                        <h2>{dict["menu"]}</h2>
                        <a href="#" className="cd-close" style={{ display: isHidden ? "none" : "" }} onClick={handleClose}>{dict["close"]}</a>
                        <ul className={"cd-dropdown-content " + (isHidden ? "move-out" : "")} style={{ width: "100%" }}>
                            <Grid container spacing={2} style={{ marginBottom: '0', padding: window.innerWidth < 1024 ? "10px" : '20px 20px 0', width: window.innerWidth < 1024 ? "100%" : window.innerWidth / 3 + "px" }}>

                                {
                                    categories.sort((a, b) => a.position - b.position).map((c, i) => {
                                        if (!c.hidden) {
                                            if (c.hasSubcategory) {
                                                let currentSubcategories = subcategories.filter(s => {
                                                    return (s.category == c._id && !s.hidden);
                                                })
                                                return (
                                                    <Grid item xs={6} sm={6} style={{ padding: "0" }}>
                                                        <Paper className={classes.paper} >
                                                            <CategoryItem
                                                                key={i}
                                                                link={`${default_url}` + c._id + "/subcategories"}
                                                                description={default_url == "/en/" ? c.enName : c.arName}
                                                                image={c.image}
                                                                updatedAt={c.updatedAt}
                                                                handleClick={handleClose}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                )
                                            } else {
                                                return (
                                                    <Grid item xs={6} sm={6} style={{ padding: "0" }}>
                                                        <Paper className={classes.paper}>
                                                            <CategoryItem
                                                                key={i}
                                                                link={`${default_url}products/` + (c.enName).toLowerCase()}
                                                                description={default_url == "/en/" ? c.enName : c.arName}
                                                                image={c.image}
                                                                updatedAt={c.updatedAt}
                                                                handleClick={handleClose}
                                                            />
                                                        </Paper>
                                                    </Grid>
                                                )

                                            }
                                        }
                                    })
                                }

                            </Grid>
                        </ul>
                        {/* <ul className={"cd-dropdown-content " + (isHidden ? "move-out" : "")}>
                            {isMobile2 && !isHidden && <li>
                                <form onSubmit={handleSearchSubmit}>
                                    <TextField
                                        style={{ width: "100%" }}
                                        id="input-with-icon-textfield"
                                        value={search}
                                        label={dict["search"]}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" >
                                                    <SearchOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ dir: "auto", style: { padding: "0 10px 10px" } }}
                                        InputLabelProps={{ style: { left: "auto" } }}
                                        onChange={event => { setSearch(event.target.value) }}

                                    />
                                </form>
                            </li>}

                        </ul> */}
                        {/* <div className="dropdown-footer">
                            <a className="dropdown-footer-link" href={default_url + "wishlist"}>{dict["wishlist"]}</a>
                            <a className="dropdown-footer-link" href={default_url + "user/points"}>{dict["my_points"]}</a>
                            <a className="dropdown-footer-link" href="#">{dict["about_us"]}</a>
                            <a className="dropdown-footer-link" href="#" onClick={handleChangeLang}>{dict['lang']}</a>
                        </div> */}
                        <div className="dropdown-footer">
                            <a className="dropdown-footer-link" href={default_url + "distributors"}>{dict["find_a_store"]}</a>
                            <a className="dropdown-footer-link" href={default_url + "feedback"}>{dict["feedbacks"]}</a>
                            <a className="dropdown-footer-link" href={default_url + "wholesale"}>{dict["wholesale"]}</a>
                            <a className="dropdown-footer-link" href={default_url + "blog"}>{dict["blog"]}</a>
                        </div>
                    </nav>

                </div>
            </header>
            {
                isMobile2 && <div className="container-fluid search-mobile-container" style={{ height: "auto" }}>
                    <div style={{ width: "100%", verticalAlign: 'top', height: "inherit" }}>
                        <form onSubmit={handleSearchSubmit}>
                            <TextField
                                style={{ width: "100%", backgroundColor: "white", border: "1px solid #ced4da", borderRadius: ".25rem", borderBottom: "none" }}
                                id="input-with-icon-textfield"
                                value={search}
                                label={dict["search"]}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" >
                                            <SearchOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ dir: "auto", style: { padding: "0 10px 10px" } }}
                                InputLabelProps={{ style: { left: "auto" } }}
                                onChange={event => { setSearch(event.target.value) }}

                            />
                        </form>
                    </div>
                    <div style={{ width: "100%", textAlign: "center", height: "auto" }}>
                        <button onClick={clickSubmit} type="submit" className="dashboardBtn" style={{ width: "100%", marginBottom: "0", marginTop: "10px", textAlign: "start", height: "55px", textAlign: "center", display: "block" }}>
                            <div style={{ height: "inherit", width: "20%", display: "inline-block" }}>
                                <img style={{ height: "inherit" }} src={IMAGE_URL + "collection-icon.gif"}></img>
                            </div>

                            <div style={{ lineHeight: '55px', width: "75%", display: "inline-block", verticalAlign: 'top', paddingTop: "0", height: 'inherit', paddingInlineStart: "0" }}>
                                {dict["get_your_collection_now"]}
                            </div>

                        </button>
                    </div>

                </div>
            }
        </div>
    )
}

export default Category;