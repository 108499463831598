import { HESABE_MERCHANT_CODE, HESABE_ACCESS_CODE, HESABE_SECRET_KEY, HESABE_IV_KEY } from "../config"
import hesabeCrypt from "hesabe-crypt";
import aesjs from "aes-js";
import axios from "axios";
import { default_url } from "../index";
import { URL } from "../config"

let data1 = {
    msg: "Welcome to Hesabe Vue.js App",
    secret: HESABE_SECRET_KEY,
    ivKey: HESABE_IV_KEY,
    payment: {},
    currency: "KWD",
    encrypted: "",
    decrypted: "",
    accessCode: HESABE_ACCESS_CODE,
    merchantCode: HESABE_MERCHANT_CODE,
    orderReferenceNumber: Math.floor(Date.now() / 1000),
    version: "2.0"
};

// let data1 = {
//     msg: "Welcome to Hesabe Vue.js App",
//     secret: "PkW64zMe5NVdrlPVNnjo2Jy9nOb7v1Xg",
//     ivKey: "5NVdrlPVNnjo2Jy9",
//     payment: {},
//     currency: "KWD",
//     encrypted: "",
//     decrypted: "",
//     accessCode: "c333729b-d060-4b74-a49d-7686a8353481",
//     merchantCode: "842217",
//     orderReferenceNumber: Math.floor(Date.now() / 1000),
//     version: "2.0"
// };

const initiatePayment = (orderNumber) => {
    let key = aesjs.utils.utf8.toBytes(data1.secret);
    let iv = aesjs.utils.utf8.toBytes(data1.ivKey);
    data1 = {...data1, orderReferenceNumber: orderNumber}
    data1.payment = new hesabeCrypt(key, iv);
}

const pay = (data2) => {
    data1.decrypted = data1.payment.decryptAes(data2);
    let result = JSON.parse(data1.decrypted);
    if (result.status === false) {
        alert(`Error Code: ${result.code}`);
        return false;
    }
    let paymentData = result.response.data;
    window.location.href = `https://api.hesabe.com/payment?data=${paymentData}`;
}

export const getResponse = (res) => {
    let key = aesjs.utils.utf8.toBytes(data1.secret);
    let iv = aesjs.utils.utf8.toBytes(data1.ivKey);
    data1.payment = new hesabeCrypt(key, iv);
    try {
        let result = (data1.payment.decryptAes(res));
        return JSON.parse(result);
    } catch (error) {
        return {error: error}
    }
}

export const checkout = (amout, penddingOrderId, orderNumber, orderId) => {
    initiatePayment(orderNumber);
    let request = {
        merchantCode: data1.merchantCode,
        amount: amout,
        currency: data1.currency,
        paymentType: 0,
        responseUrl: "https://royalhousekw.com" + default_url + "checkout",
        failureUrl: "https://royalhousekw.com" + default_url + "checkout",
        version: data1.version,
        orderReferenceNumber: data1.orderReferenceNumber,
        variable1: penddingOrderId,
        variable2: orderId
    };

    // let request = {
    //     merchantCode: data1.merchantCode,
    //     amount: amout,
    //     currency: data1.currency,
    //     paymentType: 0,
    //     responseUrl: "http://localhost:3000" + default_url + "checkout",
    //     failureUrl: "http://localhost:3000" + default_url + "checkout",
    //     version: data1.version,
    //     orderReferenceNumber: data1.orderReferenceNumber,
    //     variable1: penddingOrderId,
    //     variable2: orderId
    // };
    
    data1.encrypted = data1.payment.encryptAes(JSON.stringify(request));
    axios
        .post(
            "https://api.hesabe.com/checkout",
            {
                data: data1.encrypted
            },
            {
                headers: {
                    accessCode: data1.accessCode,
                    "Content-Type": "application/json"
                }
            }
        )
        .then(response => {
            pay(response.data);
        })
        .catch(error => {
            console.log(error.response);
            alert(`Error Code: 422`);
            return false;
        });
}