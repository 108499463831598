import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createDistributor } from "../../apiAdmin"
import { default_url } from "../../../../index"
import { dict, cities, areas, areasArEn, areasEnAr, citiesArEn, citiesEnAr } from "../../../../dictionary";

function AddDistributor() {
    const [values, setValues] = useState({
        name: "",
        city: "",
        areasInCity: [],
        area: "",
        location: "",
        error: "",
        success: false
    });

    const { user, token } = isAuthenticated();
    const { name, city, areasInCity, area, location, error, success } = values;

    const handleChange = name => event => {
        if (name == 'city') {
            if (event.target.value == "") {
                setValues({ ...values, error: false, [name]: event.target.value, areasInCity: [] });
            } else {
                setValues({ ...values, error: false, [name]: event.target.value, areasInCity: areas[cities[event.target.value]], area: "" });
            }
        } else {
            setValues({ ...values, error: false, [name]: event.target.value });
        }

    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let distributor = {
            name: name, location: location,
            enCity: (default_url === "/en/" ? cities[city] : citiesArEn[cities[city]]),
            arCity: (default_url === "/en/" ? citiesEnAr[cities[city]] : cities[city]),
            enArea: (default_url === "/en/" ? areasInCity[area] : areasArEn[areasInCity[area]]),
            arArea: (default_url === "/en/" ? areasEnAr[areasInCity[area]] : areasInCity[area])
        }
        createDistributor(distributor, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ name: "", city: "", areasInCity: [], area: "", location: "", error: null, success: true });
            }
        })
    }


    const citySelector = () => {
        return (
            <select onChange={handleChange("city")} name="hasSubcategory" className="inputField form-control" value={city}>
                <option value=""></option>
                {
                    cities.map((d, i) => {
                        return <option value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const areaSelector = () => {
        return (
            <select onChange={handleChange("area")} name="hasSubcategory" className="inputField form-control" value={area}>
                <option value=""></option>
                {
                    areasInCity.map((d, i) => {
                        return <option value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_distributor_has_been_added.add_more_or']} <a href={default_url + "admin/distributors"} style={{ fontSize: "18px" }}>{dict["go_back_to_distributors_list"]}</a>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_new_distributor"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["distributor_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("name")} type="text" value={name} className="inputField form-control" id="name" placeholder={dict["distributor_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city"]} <span className="redStar">*</span></label><br />
                    {citySelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area"]} <span className="redStar">*</span></label><br />
                    {areaSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["location"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("location")} type="text" value={location} className="inputField form-control" id="location" placeholder={dict["location"]}></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="15"
            data={form}
        />
    )
}

export default AddDistributor;