import React, { useState, useEffect, Fragment } from 'react';
import { default_url, dir } from "../index"
import { dict, enAreas, ar_dic, en_dic, cities, areas, areasArEn, areasEnAr, citiesArEn, citiesEnAr } from '../dictionary';
import { getCart, clearCart, getCartFromDB, getItemQuantity, updateItem, removeItem, getFreeItemsIds } from "./cartHelpers";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";
import { checkout, getResponse, saveAddress, getAddress, saveEmail, getEmail } from './checkoutHelpers';
import PaymentMethods from './checkout components/PaymentMethods';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { createOrder, getOrder, updateUserHistory, decreaseQuantity, getGeneral, getCouponByCode, createPenddingOrder, getPenddingOrder, orderUpdate, getGiftByAmount, decreaseGiftQuantity, deletePenddingOrder, createPoint, listFreeItemsById } from './apiCore';
import { isAuthenticated, signin, authenticate } from '../auth';
import { Redirect } from 'react-router-dom';
import { store } from 'react-notifications-component';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getPointsByUser, listAddresses, read, updateUsedPoints } from './user/apiUser';
import { getWishlistFromDB } from './wishlistHelpers';
import { IMAGE_URL } from '../config';
import { set } from 'js-cookie';
import { Helmet } from 'react-helmet';
import { wait } from '@testing-library/react';
import { updateOrderStatus } from './admin/apiAdmin';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsePoints from './checkout components/UsePoints';
import { InfoSharp } from '@material-ui/icons';
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';


const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: "#E4BC4B" // customized
                }
            }
        }
    })
);

function CheckOut(props) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const [isLoading, setIsLoading] = useState(0);
    const [showLoadingBackdrop, setShowBackdrop] = useState(false);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(true);
    const [loading6, setLoading6] = useState(true);
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [loadingPoints, setLoadingPoints] = useState(false);
    const [values, setValues] = useState({
        email: "",
        password: "",
        signinError: "",
        loading: false,
        signinSuccess: false
    });
    const [products, setProducts] = useState([]);
    const [products2, setProducts2] = useState([]);
    const [orderNumber, setOrderNumber] = useState();
    const [address, setAddress] = useState({
        fName: "",
        lName: "",
        mobile: "",
        email: "",
        city: "",
        areasInCity: [],
        area: "",
        block: "",
        street: "",
        lane: "",
        building: "",
        floor: "",
        apartment: "",
        extra_details: "",
        error: null
    });
    const [userAddresses, setUserAddresses] = useState([]);
    const [addressIndex, setAddressIndex] = useState(0);
    const [addressAsString, setAddressAsString] = useState("");
    const [usedEmail, setUsedEmail] = useState();
    const [focused, setFocused] = useState(false);
    const [total, setTotal] = useState(0);
    const [delivery, setDelivery] = useState();
    const [normalDelivery, setNormalDelivery] = useState();
    const [amountForFreeDelivery, setAmountForFreeDelivery] = useState();
    const [amountForFreeDeliveryForSelectedAreas, setAmountForFreeDeliveryForSelectedAreas] = useState();
    const [deliveryForSelectedAreas, setDeliveryForSelectedAreas] = useState();
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [areasOutsideDelivery, setAreasOutsideDelivery] = useState([]);
    const [fromSelectedAreas, setFromSelectedAreas] = useState(false);
    const [subtotal, setSubTotal] = useState();
    const [grandTotal, setGrandTotal] = useState(0);
    const [couponCode, setCouponCode] = useState("");
    const [couponDetails, setCouponDetails] = useState();
    const [freeDelivery, setFreeDelivery] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('online');
    const [usePoint, setUsePoint] = useState('no');
    const [donePayment, setDonePayment] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState({});
    const [gift, setGift] = useState({ found: false, name: null, id: null, image: null })
    const [points, setPoints] = useState([]);
    const [maxPoints, setMaxPoints] = useState(0);
    const [activePoints, setActivePoints] = useState(null);
    const [prevAmountForGift, setPrevAmountForGift] = useState(-1);
    const [minimumCart, setMinimumCart] = useState(null);
    const [deliveryMessage, setDeliveryMessage] = useState(null);
    const [cookies, setCookie] = useCookies(['_fbp']);
    const [currentFreeItems, setCurrentFreeItems] = useState([]);
    const [freeItemsToSend, setFreeItemsToSend] = useState([]);
    const [currentCities, setCurrentCities] = useState([]);

    const params = new URLSearchParams(window.location.search);
    let data = (params.get('data'));
    let usedCoupon = (params.get('coupon'));

    const { email, password, signinError, signinSuccess } = values;
    const { fName, lName, mobile, city, areasInCity, area, block, street, lane, building, floor, apartment, extra_details, error } = address;
    const { user, token } = isAuthenticated();

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={showLoadingBackdrop || loading1 || loading2 || loading3 || loading4 || loading5 || loading6 || loadingPoints || loadingCoupon}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    // const showBackdrop = () => {
    //     return (
    //         <Backdrop className={classes2.backdrop} open={isLoading != 0}>
    //             <CircularProgress color="inherit" />
    //         </Backdrop>
    //     )
    // }

    const loadPointsProgram = () => {
        if (user) {
            read(user._id, token).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    if (res.isPointsActive) {
                        setActivePoints(true)
                    } else {
                        setActivePoints(false)
                    }
                }
            })
        }
    }

    const loadSummary = () => {
        if (fromSelectedAreas) {
            setDelivery(parseFloat(deliveryForSelectedAreas));
        } else {
            setDelivery(normalDelivery);
        }
        let disAmount = 0, deliveryTmp = delivery;
        let tot = 0;
        products.map((p, i) => {
            tot += (p.count * (p.discountPrice ? p.discountPrice : p.price));
        });
        let totalTmp = ((tot * 1000) / 1000).toFixed(3);
        setFreeDelivery(false);
        if (couponDetails) {
            if (couponDetails.type === "percentage") {
                disAmount = couponDetails.amount * (totalTmp / 100.0);
                disAmount = ((disAmount * 100) / 100).toFixed(3);
            } else if (couponDetails.type === "fixed_amount") {
                let del = 0;
                let dis = 0;
                if (fromSelectedAreas) {
                    del = deliveryForSelectedAreas;
                } else if (parseFloat(totalTmp) >= parseFloat(amountForFreeDelivery)) {
                    del = 0;
                } else {
                    del = deliveryTmp;
                }
                disAmount = Math.min(parseFloat(totalTmp), couponDetails.amount);
            } else {
                setFreeDelivery(true);
                deliveryTmp = 0.000;
            }
        }
        if (!fromSelectedAreas && tot >= amountForFreeDelivery) {
            setFreeDelivery(true);
            deliveryTmp = 0.000;
        }
        if (fromSelectedAreas && tot >= amountForFreeDeliveryForSelectedAreas) {
            setFreeDelivery(true);
            deliveryTmp = 0.000;
        }
        let subtoatlTmp = tot + parseFloat(deliveryTmp);
        if (usePoint == 'yes' && maxPoints && maxPoints > 0) {
            disAmount = parseFloat((maxPoints / 10).toFixed(3));
            disAmount = Math.min(subtoatlTmp, disAmount)
        }
        let grandTotalTmp = subtoatlTmp - disAmount;
        setTotal(fixLength(totalTmp));
        setSubTotal(fixLength(subtoatlTmp));
        setDiscount(fixLength(disAmount));
        setGrandTotal(fixLength(grandTotalTmp));
        //loadGift(grandTotalTmp - parseFloat(deliveryTmp));
    }

    const loadUserAddresses = () => {
        const { user, token } = isAuthenticated();
        setLoading1(true);
        setIsLoading(isLoading + 1);
        listAddresses(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let tmpAddresses = [...res];
                let tmpAddresses1 = [];
                if (areasOutsideDelivery.arrayValue) {
                    for (let i = 0; i < tmpAddresses.length; i++) {
                        let currentArea = tmpAddresses[i].arArea;
                        if (areasOutsideDelivery.arrayValue.indexOf(currentArea) < 0) {
                            tmpAddresses1.push({ ...tmpAddresses[i] });
                        }
                    }
                } else {
                    tmpAddresses1 = [...tmpAddresses];
                }
                let tmpAddresses2 = [];
                if (couponDetails && couponDetails.cities && couponDetails.cities.length > 0) {
                    for (let i = 0; i < tmpAddresses1.length; i++) {
                        let currentCityAr = tmpAddresses1[i].arCity;
                        let currentCityEn = tmpAddresses1[i].enCity;
                        for (let j = 0; j < couponDetails.cities.length; j++) {
                            if (couponDetails.cities[j].enName == currentCityEn || couponDetails.cities[j].arName == currentCityAr) {
                                tmpAddresses2.push({ ...tmpAddresses1[i] });
                                break;
                            }
                        }
                    }
                } else {
                    tmpAddresses2 = [...tmpAddresses1];
                }
                tmpAddresses2.push("new_address")
                //setUserAddresses([...res, "new_address"]);
                setUserAddresses([...tmpAddresses2]);
                if (tmpAddresses2.length > 0) {
                    if (tmpAddresses2[0] == "new_address") {
                        setAddress({
                            fName: "",
                            lName: "",
                            mobile: "",
                            city: "",
                            areasInCity: [],
                            area: "",
                            block: "",
                            street: "",
                            lane: "",
                            building: "",
                            floor: "",
                            apartment: "",
                            extra_details: "",
                            error: null
                        })
                    } else {
                        let tempCity = default_url === "/en/" ? tmpAddresses2[0].enCity : tmpAddresses2[0].arCity;
                        let tempArea = default_url === "/en/" ? tmpAddresses2[0].enArea : tmpAddresses2[0].arArea;
                        if (selectedAreas.indexOf(tmpAddresses2[0].enArea) >= 0 || selectedAreas.indexOf(tmpAddresses2[0].arArea) >= 0) {
                            setFromSelectedAreas(true);
                        } else {
                            setFromSelectedAreas(false);
                        }
                        setAddress({
                            fName: tmpAddresses2[0].fName,
                            lName: tmpAddresses2[0].lName,
                            mobile: tmpAddresses2[0].mobile,
                            city: currentCities.indexOf(tempCity) ? currentCities.indexOf(tempCity) : "",
                            areasInCity: areas[tempCity] ? areas[tempCity] : [],
                            area: areas[tempCity] ? areas[tempCity].indexOf(tempArea) : "",
                            block: tmpAddresses2[0].block,
                            street: tmpAddresses2[0].street,
                            lane: tmpAddresses2[0].lane,
                            building: tmpAddresses2[0].building,
                            floor: tmpAddresses2[0].floor,
                            apartment: tmpAddresses2[0].apartment,
                            extra_details: tmpAddresses2[0].extra,
                            error: null
                        })
                    }
                }

            }
            setIsLoading(isLoading - 1);
            setLoading1(false);
        })
    }

    const loadPoints = () => {
        if (user) {
            setLoadingPoints(true);
            setIsLoading(isLoading + 1);
            getPointsByUser(user._id, token, { status: "active" }).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setPoints(res);
                }
                setIsLoading(isLoading - 1);
                setLoadingPoints(false);
            })
        } else {
            setLoadingPoints(false);
        }
    }

    const getTotalPoints = (res = points) => {
        let t = 0;
        for (let i = 0; i < res.length; i++) {
            let u = 0;
            for (let j = 0; j < res[i].used.length; j++) {
                if (!res[i].used[j].returned) {
                    u += res[i].used[j].amount;
                }
            }
            t += (res[i].amount - u)
        }
        return t;
    }

    const getMaxPoints = () => {
        let t = getTotalPoints();
        let d = 0;
        if (parseFloat(total) < parseFloat(amountForFreeDelivery)) {
            if (fromSelectedAreas) {
                d = parseFloat(deliveryForSelectedAreas);
            } else {
                d = parseFloat(normalDelivery);
            }
        }
        let tot = parseFloat(parseFloat(total) + d) * 10;
        let tot2 = Math.floor(tot);
        if (tot > tot2) {
            tot2 += 1;
        }
        let tmp = tot2;
        let mx = Math.min(t, tmp);
        setMaxPoints(Math.min(t, tmp));
    }

    const loadItems = async () => {
        let tmpCart = await getCart();
        if (tmpCart.length === 0) {
            window.location.href = default_url
        } else {
            setProducts(tmpCart);
            let tot = 0;
            tmpCart.map((item, i) => {
                if (item.discountPrice && item.discountPrice > 0) {
                    tot += (item.discountPrice * item.count);
                } else {
                    tot += (item.price * item.count);
                }
                // if(i == tmpCart.length - 1){
                //     loadGift(tot);
                // }
            });

        }
    }

    const loadGift = (tot) => {
        setShowBackdrop(true);
        setIsLoading(isLoading + 1);
        getGiftByAmount(tot).then(res => {
            if (res.error) {

            } else {
                if (res.name) {
                    setGift({ found: true, name: res.name, id: res._id, image: res.image })
                } else {
                    setGift({ found: false, name: null, id: null, image: null })
                }

            }
            setShowBackdrop(false);
            setIsLoading(isLoading - 1);
        });
    }

    const loadFreeItems = () => {
        let freeItemIds = getFreeItemsIds();
        listFreeItemsById(freeItemIds).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let arr = [];
                let arr2 = [];
                for (let i = 0; i < freeItemIds.length; i++) {
                    for (let j = 0; j < res.length; j++) {
                        if (freeItemIds[i] == res[j]._id) {
                            arr.push({ ...res[j] });
                            arr2.push({ id: res[j]._id, childId: res[j].child._id, childEnTitle: res[j].child.enTitle, childImage: res[j].child.images[0], childArTitle: res[j].child.arTitle, childItemCode: res[j].child.itemCode, parentId: res[j].parent })
                        }
                    }
                }
                setCurrentFreeItems([...arr]);
                setFreeItemsToSend([...arr2]);
            }
        })
    }

    const loadDeliveryPrices = () => {
        setLoading2(true);
        setLoading3(true);
        setLoading4(true);
        setLoading5(true);
        setLoading6(true);
        setIsLoading(isLoading + 5);
        getGeneral("name", "delivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setNormalDelivery(fixLength(res.numberValue));
            }
            setLoading2(false);
            setIsLoading(isLoading - 1);
        });
        getGeneral("name", "freeDelivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAmountForFreeDelivery(fixLength(res.numberValue));

            }
            setLoading3(false);
            setIsLoading(isLoading - 1);
        });
        getGeneral("name", "freeDeliveryForSelectedAreas").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAmountForFreeDeliveryForSelectedAreas(fixLength(res.numberValue));

            }
            setLoading3(false);
            setIsLoading(isLoading - 1);
        });
        getGeneral("name", "deliveryForSelectedAreas").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDeliveryForSelectedAreas(fixLength(res.numberValue));
            }
            setLoading4(false);
            setIsLoading(isLoading - 1);
        });
        getGeneral("name", "selectedAreas").then(async (res) => {
            if (res.error) {
                console.log(res.error);
            } else {
                setSelectedAreas(res.arrayValue);
            }
            setLoading5(false);
            setIsLoading(isLoading - 1);
        });
        getGeneral("name", "areasOutsideDelivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAreasOutsideDelivery({ _id: res._id, arrayValue: res.arrayValue });
            }
            setLoading6(false);
            setIsLoading(isLoading - 1);
        });
    }

    const loadDeliveryMessages = () => {
        setIsLoading(isLoading + 1);
        if (default_url == "/en/") {
            getGeneral("name", "deliveryMessageInEnglish").then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setDeliveryMessage(res.stringValue);
                    setIsLoading(isLoading - 1);
                }
            });
        } else {
            getGeneral("name", "deliveryMessageInArabic").then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setDeliveryMessage(res.stringValue);
                    setIsLoading(isLoading - 1);
                }
            });
        }

    }

    const loadMinimumCart = () => {
        setIsLoading(isLoading + 1);
        getGeneral("name", "minimumCart").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setMinimumCart(fixLength(res.numberValue));
                setIsLoading(isLoading - 1);
            }
        });
    }

    const checkCities = () => {
        let arr = [];
        if(couponDetails && couponDetails.cities && couponDetails.cities.length > 0){
            for (let i = 0; i < cities.length; i++) {
                for (let j = 0; j < couponDetails.cities.length; j++) {
                    if(cities[i] == couponDetails.cities[j].enName || cities[i] == couponDetails.cities[j].arName){
                        arr.push(cities[i]);
                    }
                }
            }
            setCurrentCities([...arr]);
            setAddress({...address, city: "", area: "", areasInCity: [] })
        }else{
            setCurrentCities([...cities]);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        loadFreeItems();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        loadFreeItems();
    }, [])

    useEffect(() => {
        if (!data) {
            let del = freeDelivery ? 0 : delivery;
            let tot = grandTotal - del;
            if (tot && tot != prevAmountForGift) {
                setPrevAmountForGift(tot);
                loadGift(tot);
            }
        }
    }, [grandTotal, delivery])

    useEffect(() => {
        if (!data) {
            loadPointsProgram();
            loadPoints();
            loadDeliveryPrices();
            loadMinimumCart();
            loadDeliveryMessages();
            loadItems();
            if (props.location.state && !couponCode) {
                if (props.location.state.coupon) {
                    console.log(props.location.state.coupon);
                    setCouponDetails(props.location.state.coupon);
                    setCouponCode(props.location.state.coupon.code);
                }
            }
        }
    }, [products2]);

    useEffect(() => {
        if (!data) {
            loadSummary();
            getMaxPoints();
        }
    }, [discount, delivery, normalDelivery, deliveryForSelectedAreas, amountForFreeDelivery, selectedAreas, couponDetails, products, fromSelectedAreas, freeDelivery, usePoint, points]);

    useEffect(() => {
        if (isAuthenticated() && !data) {
            loadUserAddresses();
            setUsedEmail(isAuthenticated().user.email);
        } else {
            setLoading1(false);
        }
       
    }, [selectedAreas, areasOutsideDelivery, couponDetails, currentCities]);

    useEffect(() => {
        checkCities();
    }, [cities, couponDetails])

    useEffect(() => {
        getMaxPoints();
    }, [grandTotal, points]);

    useEffect(() => {
        loadSummary();
    }, [maxPoints]);

    useEffect(() => {
        if (data) {
            setShowBackdrop(true);
            setIsLoading(isLoading + 1);
            let hesabiRes = (getResponse(data));
            if (hesabiRes.error || hesabiRes.status === undefined) {
                window.location.href = default_url;
            } else {
                if (hesabiRes.status !== undefined) {
                    getPenddingOrder(hesabiRes.response.variable1).then(res => {
                        if (res.error) {
                            console.log(res.error);
                        } else {
                            let order = res;
                            let items = order.products;
                            if (items.length > 0) {
                                setAddressAsString(default_url === "/en/" ? order.enAddress : order.arAddress);
                                orderUpdate(res.orderNumber, hesabiRes.status == true ? "new_order" : "failed", hesabiRes.response.paymentId, hesabiRes.status, (default_url === "/en/" ? "en" : "ar")).then(res2 => {
                                    if (res2.error) {

                                    } else {
                                        if (hesabiRes.status == true) {
                                            clearCart(() => {
                                            });
                                            let tmpProducts = [...items];
                                            for (let m = 0; m < freeItemsToSend.length; m++) {
                                                tmpProducts.push({
                                                    _id: freeItemsToSend[m].childId,
                                                    count: 1
                                                });
                                            }
                                            decreaseQuantity(tmpProducts);
                                            if (order.gift && order.gift.id != null) {
                                                decreaseGiftQuantity(order.gift.id);
                                            }
                                            if (user) {
                                                if (res.discount && res.discountType && res.discount > 0 && res.discountType == "points") {
                                                    getPointsByUser(user._id, token, { status: "active" }).then(resPoints => {
                                                        let usedArr = [];
                                                        let pointsIds = [];
                                                        for (let i = 0; i < resPoints.length; i++) {
                                                            for (let j = 0; j < resPoints[i].used.length; j++) {
                                                                if (resPoints[i].used[j].order == res.orderNumber) {
                                                                    let tmp = [...resPoints[i].used]
                                                                    let tmp2 = { ...tmp[j], returned: false }
                                                                    tmp[j] = { ...tmp2 };
                                                                    pointsIds.push(resPoints[i]._id);
                                                                    usedArr.push([...tmp]);
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        updateUsedPoints(pointsIds, usedArr, user._id, token).then((resUpdatedPoints) => {
                                                            if (resUpdatedPoints.error) {
                                                                console.log(resUpdatedPoints.error);
                                                            } else {
                                                                deletePenddingOrder(res._id);
                                                                setPaymentResponse(hesabiRes);
                                                                setOrderNumber(res.orderNumber);
                                                                setDonePayment(true);
                                                            }
                                                        })
                                                    });
                                                } else {
                                                    deletePenddingOrder(res._id);
                                                    setPaymentResponse(hesabiRes);
                                                    setOrderNumber(res.orderNumber);
                                                    setDonePayment(true);
                                                }
                                            } else {
                                                deletePenddingOrder(res._id);
                                                setPaymentResponse(hesabiRes);
                                                setOrderNumber(res.orderNumber);
                                                setDonePayment(true);
                                            }
                                           
                                        } else {
                                            deletePenddingOrder(res._id);
                                            setPaymentResponse(hesabiRes);
                                            setOrderNumber(res.orderNumber);
                                            setDonePayment(true);
                                        }

                                    }
                                })
                            } else {
                                getOrder("payment_id", res.response.paymentId).then(response => {
                                    if (response.error) {

                                    } else {
                                        setAddressAsString(default_url === "/en/" ? response.enAddress : response.arAddress);
                                        setTotal(fixLength(response.amount + response.discount - response.delivery));
                                        setSubTotal(fixLength(parseFloat(response.amount) + parseFloat(response.discount)));
                                        setGrandTotal(fixLength(response.amount));
                                        setDelivery(fixLength(response.delivery));
                                        setDiscount(fixLength(response.discount));
                                        setProducts(response.products);
                                        setOrderNumber((response.orderNumber));
                                    }
                                });
                            }
                        }
                    });
                }
            }
        }
    }, []);

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, signinSuccess: false, loading: true });
        setIsLoading(isLoading + 1);
        signin({ email, password })
            .then(data => {
                if (data.error) {
                    showSigninError(data.error);
                    setValues({ ...values, signinSuccess: data.error, loading: false });
                } else {
                    authenticate(data, () => {
                        getCartFromDB(() => {
                            getWishlistFromDB(() => {
                                setValues({
                                    ...values,
                                    signinSuccess: true
                                });
                                window.location.reload();
                            })
                        });
                    })
                }
                setIsLoading(isLoading - 1);
            })
    }

    const handleRadioBtnChange = (event) => {
        let value = parseInt(event.target.value)
        setAddressIndex(value);
        if (value < userAddresses.length - 1) {
            let tempCity = default_url === "/en/" ? userAddresses[value].enCity : userAddresses[value].arCity;
            let tempArea = default_url === "/en/" ? userAddresses[value].enArea : userAddresses[value].arArea;
            if (selectedAreas.indexOf(userAddresses[value].enArea) >= 0 || selectedAreas.indexOf(userAddresses[value].arArea) >= 0) {
                setFromSelectedAreas(true);
            } else {
                setFromSelectedAreas(false);
            }
            setAddress({
                fName: userAddresses[value].fName,
                lName: userAddresses[value].lName,
                mobile: userAddresses[value].mobile,
                city: currentCities.indexOf(tempCity) ? currentCities.indexOf(tempCity) : "",
                areasInCity: areas[tempCity] ? areas[tempCity] : [],
                area: areas[tempCity] ? areas[tempCity].indexOf(tempArea) : "",
                block: userAddresses[value].block,
                street: userAddresses[value].street,
                lane: userAddresses[value].lane,
                building: userAddresses[value].building,
                floor: userAddresses[value].floor,
                apartment: userAddresses[value].apartment,
                extra_details: userAddresses[value].extra,
                error: null
            })
        } else {
            setFromSelectedAreas(false);
            setAddress({
                fName: "",
                lName: "",
                mobile: "",
                city: "",
                areasInCity: [],
                area: "",
                block: "",
                street: "",
                lane: "",
                building: "",
                floor: "",
                apartment: "",
                extra_details: "",
                error: null
            })
        }
    };

    const handleCouponChange = (event) => {
        setCouponCode(event.target.value);
    }

    const handleCouponSubmit = (event) => {
        event.preventDefault();
        if (discount > 0) {
            setDiscount(0);
        }
        if (freeDelivery) {
            setFreeDelivery(false)
        }
        setCouponDetails("");
        if (!couponCode) {
            showCouponError("please_enter_the_coupon_code")
        } else {
            checkCoupon(couponCode);
        }
    }

    const checkCoupon = (coupon, showSuccessMsg = true, showErrorMsg = true) => {
        setLoadingCoupon(true);
        getCouponByCode(coupon).then(res => {
            if (res === null && showErrorMsg) {
                showCouponError("invalid_coupon");
                setCouponCode("");
            } else if (res.error) {
                console.log(res.error);
            } else if ((!res.active || (res.code).toLowerCase() !== coupon.toLowerCase() || (res.limit && res.limit <= res.usedTimes)) && showErrorMsg) {
                showCouponError("invalid_coupon");
            } else if (res.requiresSignin && !isAuthenticated() && showErrorMsg) {
                showCouponError("pleae_signin_to_use_the_coupon");
            } else {
                if (showSuccessMsg) {
                    showSuccess("valid_coupon");
                }
                handleDiscount(res)
                setCouponDetails(res);
                setUsePoint('no')
                setLoadingCoupon(false);
                return (res);
            }
            setLoadingCoupon(false);
            setCouponDetails("");
            return null;
        })
    }

    const handleDiscount = ({ type, amount }) => {
        if (type === "free_delivery") {
            setFreeDelivery(true)
            //setSubTotal((((total) * 100) / 100).toFixed(3));
            //setGrandTotal((((total - discount) * 100) / 100).toFixed(3));
        } else if (type === "percentage") {
            let discountAmount = amount * (total / 100.0);
            discountAmount = ((discountAmount * 100) / 100).toFixed(3)
            //setGrandTotal((((parseFloat(total) + parseFloat(delivery) - parseFloat(discountAmount)) * 100) / 100).toFixed(3));
            setDiscount(((parseFloat(discountAmount) * 100) / 100).toFixed(3));
        } else {
            //setGrandTotal((((parseFloat(total) + parseFloat(delivery) - parseFloat(amount)) * 100) / 100).toFixed(3));
            let d = parseFloat(amount).toFixed(3)
            d = Math.min(d, parseFloat(total));
            setDiscount(parseFloat(d).toFixed(3));
        }

    }

    const showCouponError = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showSuccess = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showWarning = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 4000,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showMinimumCartMessage = (t) => {
        store.addNotification({
            message: dict['the_minimum_cart_limit_is'] + " " + minimumCart + " " + dict["kd"] + ". " + dict["your_cart_is"] + " " + fixLength(t) + " " + dict['kd'] + " " + dict['without_delivery'],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 10000,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const onlinePayCheckOut = async (enAddress, arAddress) => {
        let chaneged = false;
        setIsLoading(isLoading + 1);
        return await products.map(async (p, i) => {
            let q = await getItemQuantity(p._id);
            if (q <= 0) {
                await removeItem(p._id);
                chaneged = true
            } else if (q < p.count) {
                await updateItem(p._id, q);
                chaneged = true
            }
            setIsLoading(isLoading - 1);
            if (i === products.length - 1) {
                if (chaneged) {
                    let tmpCart = await getCart();
                    setProducts2(tmpCart);
                    showWarning("your_cart_has_been_updated._please_review_your_cart_before_checking_out")
                } else {
                    let user = null, token = null;
                    if (isAuthenticated()) {
                        user = isAuthenticated().user;
                        token = isAuthenticated().token;
                    }
                    if (usePoint == 'yes') {
                        let oldMax = maxPoints;
                        getPointsByUser(user._id, token, { status: "active" }).then(res4 => {
                            if (res4.error) {
                                console.log(res4.error);
                            } else {
                                setPoints(res4);
                                let t = getTotalPoints(res4);
                                let d = 0;
                                if (parseFloat(total) < parseFloat(amountForFreeDelivery)) {
                                    if (fromSelectedAreas) {
                                        d = parseFloat(deliveryForSelectedAreas);
                                    } else {
                                        d = parseFloat(normalDelivery);
                                    }
                                }
                                let tot = parseFloat(parseFloat(total) + d) * 10;
                                let tot2 = Math.floor(tot);
                                if (tot > tot2) {
                                    tot2 += 1;
                                }
                                let tmp = tot2;
                                let mx = Math.min(t, tmp);
                                setMaxPoints(Math.min(t, tmp));
                                let change = false;
                                if (mx != oldMax) {
                                    change = true;
                                    showWarning("your_points_been_updated._please_review_your_points_before_checking_out");
                                    setShowBackdrop(false);
                                } else {
                                    const orderData = { email: usedEmail, products: products, gift: { id: gift.id, name: gift.name }, freeItems: freeItemsToSend, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, discountType: discount && discount > 0 ? usePoint == 'yes' ? "points" : "coupon" : "", couponCode: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: "online", paymentStatus: false }
                                    createOrder({ ...orderData, payment_id: null, status: "waiting_for_online_payment" }, user ? user._id : null, token, (default_url === "/en/" ? "en" : "ar"), cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null).then(response => {
                                        if (response.error) {

                                        } else {
                                            createPenddingOrder({ ...orderData, orderNumber: response.orderNumber }).then(res => {
                                                if (res.error) {
                                                    console.log(res.error);
                                                } else {
                                                    if (user) {
                                                        updateUserHistory(user._id, token, response.orderNumber);
                                                        if (Math.floor(orderData.amount - orderData.delivery) >= 1) {
                                                            let createdDated = new Date(response.createdAt);
                                                            let n = new Date(createdDated.setMonth(createdDated.getMonth() + 6))
                                                            let m = n.getMonth()
                                                            let y = n.getFullYear()
                                                            let numberOfDays = new Date(y, m + 1, 0).getDate()
                                                            let expireDate = new Date(y, m, numberOfDays, 23, 59, 59)
                                                            createPoint({ amount: Math.floor(orderData.amount - orderData.delivery), user: user._id, order: response._id, status: "pendding", expireDate: expireDate }, user._id, token).then(resCreatedPoints => {
                                                                if (resCreatedPoints.error) {
                                                                    console.log(resCreatedPoints.error);
                                                                } else {
                                                                    if (usePoint == "yes" && discount > 0) {
                                                                        let p = []
                                                                        let m = maxPoints;
                                                                        for (let i = 0; i < points.length; i++) {
                                                                            p.push({ ...points[i] });
                                                                            let indx = p.length - 1;
                                                                            let u = 0;
                                                                            for (let j = 0; j < points[i].used.length; j++) {
                                                                                if (!points[i].used[j].returned) {
                                                                                    u += points[i].used[j].amount;
                                                                                }
                                                                            }
                                                                            let unused = points[i].amount - u;
                                                                            if (unused > 0) {
                                                                                if (unused <= m) {
                                                                                    let tmp = { amount: unused, order: response.orderNumber, returned: true }
                                                                                    let tmp2 = [...p[indx].used, { ...tmp }]
                                                                                    p[indx].used = [...tmp2]
                                                                                    m -= unused;
                                                                                } else {
                                                                                    let tmp = { amount: m, order: response.orderNumber, returned: true }
                                                                                    let tmp2 = [...p[indx].used, { ...tmp }]
                                                                                    p[indx].used = [...tmp2]
                                                                                    m = 0;
                                                                                }
                                                                            }
                                                                        }
                                                                        let ids = [];
                                                                        let used = [];
                                                                        for (let i = 0; i < p.length; i++) {
                                                                            ids.push(p[i]._id)
                                                                            used.push([...p[i].used]);
                                                                        }
                                                                        updateUsedPoints(ids, used, user._id, token).then((res2) => {
                                                                            if (res2.error) {
                                                                                console.log(res2.error);
                                                                            } else {
                                                                                checkout(grandTotal, res._id, response.orderNumber, response._id)
                                                                            }
                                                                        })
                                                                    } else {
                                                                        checkout(grandTotal, res._id, response.orderNumber, response._id)
                                                                    }
                                                                }
                                                            })
                                                        } else {
                                                            if (usePoint == "yes" && discount > 0) {
                                                                let p = []
                                                                let m = maxPoints;
                                                                for (let i = 0; i < points.length; i++) {
                                                                    p.push({ ...points[i] });
                                                                    let indx = p.length - 1;
                                                                    let u = 0;
                                                                    for (let j = 0; j < points[i].used.length; j++) {
                                                                        if (!points[i].used[j].returned) {
                                                                            u += points[i].used[j].amount;
                                                                        }
                                                                    }
                                                                    let unused = points[i].amount - u;
                                                                    if (unused > 0) {
                                                                        if (unused <= m) {
                                                                            let tmp = { amount: unused, order: response.orderNumber, returned: true }
                                                                            let tmp2 = [...p[indx].used, { ...tmp }]
                                                                            p[indx].used = [...tmp2]
                                                                            m -= unused;
                                                                        } else {
                                                                            let tmp = { amount: m, order: response.orderNumber, returned: true }
                                                                            let tmp2 = [...p[indx].used, { ...tmp }]
                                                                            p[indx].used = [...tmp2]
                                                                            m = 0;
                                                                        }
                                                                    }
                                                                }
                                                                let ids = [];
                                                                let used = [];
                                                                for (let i = 0; i < p.length; i++) {
                                                                    ids.push(p[i]._id)
                                                                    used.push([...p[i].used]);
                                                                }
                                                                updateUsedPoints(ids, used, user._id, token).then((res2) => {
                                                                    if (res2.error) {
                                                                        console.log(res2.error);
                                                                    } else {
                                                                        checkout(grandTotal, res._id, response.orderNumber, response._id)
                                                                    }
                                                                })
                                                            } else {
                                                                checkout(grandTotal, res._id, response.orderNumber, response._id)
                                                            }
                                                        }
                                                    } else {
                                                        checkout(grandTotal, res._id, response.orderNumber, response._id)
                                                    }
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                        })
                    } else {
                        const orderData = { email: usedEmail, products: products, gift: { id: gift.id, name: gift.name }, freeItems: freeItemsToSend, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, discountType: discount && discount > 0 ? usePoint == 'yes' ? "points" : "coupon" : "", couponCode: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: "online", paymentStatus: false }
                        createOrder({ ...orderData, payment_id: null, status: "waiting_for_online_payment" }, user ? user._id : null, token, (default_url === "/en/" ? "en" : "ar"), cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null).then(response => {
                            if (response.error) {

                            } else {
                                createPenddingOrder({ ...orderData, orderNumber: response.orderNumber }).then(res => {
                                    if (res.error) {
                                        console.log(res.error);
                                    } else {
                                        if (user) {
                                            updateUserHistory(user._id, token, response.orderNumber);
                                            if (Math.floor(orderData.amount - orderData.delivery) >= 1) {
                                                let createdDated = new Date(response.createdAt);
                                                let n = new Date(createdDated.setMonth(createdDated.getMonth() + 6))
                                                let m = n.getMonth()
                                                let y = n.getFullYear()
                                                let numberOfDays = new Date(y, m + 1, 0).getDate()
                                                let expireDate = new Date(y, m, numberOfDays, 23, 59, 59)
                                                createPoint({ amount: Math.floor(orderData.amount - orderData.delivery), user: user._id, order: response._id, status: "pendding", expireDate: expireDate }, user._id, token).then(resCreatedPoints => {
                                                    if (resCreatedPoints.error) {
                                                        console.log(resCreatedPoints.error);
                                                    } else {
                                                        checkout(grandTotal, res._id, response.orderNumber, response._id);
                                                    }
                                                })
                                            } else {
                                                checkout(grandTotal, res._id, response.orderNumber, response._id);
                                            }
                                        } else {
                                            checkout(grandTotal, res._id, response.orderNumber, response._id)
                                        }
                                    }
                                });
                            }
                        });
                    }



                }
                return chaneged;
            }
        });
    }

    const cashOnDeliveryCheckOut = async (enAddress, arAddress) => {
        let chaneged = false;
        setIsLoading(isLoading + 1);
        return await products.map(async (p, i) => {
            let q = await getItemQuantity(p._id);
            if (q <= 0 || p.count <= 0) {
                await removeItem(p._id);
                chaneged = true
            } else if (q < p.count) {
                await updateItem(p._id, q);
                chaneged = true
            }
            setIsLoading(isLoading - 1);
            if (i === products.length - 1) {
                if (chaneged) {
                    let tmpCart = getCart();
                    setProducts2(tmpCart);
                    showWarning("your_cart_has_been_updated._please_review_your_cart_before_checking_out")
                } else {
                    let user = null, token = null;
                    if (isAuthenticated()) {
                        user = isAuthenticated().user;
                        token = isAuthenticated().token;
                    }
                    if (usePoint == 'yes') {
                        let oldMax = maxPoints;
                        getPointsByUser(user._id, token, { status: "active" }).then(res => {
                            if (res.error) {
                                console.log(res.error);
                            } else {
                                setPoints(res);
                                let t = getTotalPoints(res);
                                let d = 0;
                                if (parseFloat(total) < parseFloat(amountForFreeDelivery)) {
                                    if (fromSelectedAreas) {
                                        d = parseFloat(deliveryForSelectedAreas);
                                    } else {
                                        d = parseFloat(normalDelivery);
                                    }
                                }
                                let tot = parseFloat(parseFloat(total) + d) * 10;
                                let tot2 = Math.floor(tot);
                                if (tot > tot2) {
                                    tot2 += 1;
                                }
                                let tmp = tot2;
                                let mx = Math.min(t, tmp);
                                setMaxPoints(Math.min(t, tmp));
                                let change = false;
                                if (mx != oldMax) {
                                    change = true;
                                    showWarning("your_points_been_updated._please_review_your_points_before_checking_out");
                                    setShowBackdrop(false);
                                } else {
                                    const orderData = { email: usedEmail, products: products, gift: { id: gift.id, name: gift.name }, freeItems: freeItemsToSend, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, discountType: discount && discount > 0 ? usePoint == 'yes' ? "points" : "coupon" : "", couponCode: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: "cash", paymentStatus: false }
                                    createOrder(orderData, user ? user._id : null, token, (default_url === "/en/" ? "en" : "ar"), cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null).then(response => {
                                        if (response.error) {

                                        } else {
                                            let tmpProducts = [...products];
                                            for (let m = 0; m < freeItemsToSend.length; m++) {
                                                tmpProducts.push({
                                                    _id: freeItemsToSend[m].childId,
                                                    count: 1
                                                });
                                            }
                                            decreaseQuantity(tmpProducts);
                                            if (gift.id) {
                                                decreaseGiftQuantity(gift.id);
                                            }
                                            if (usePoint == "yes" && discount > 0) {
                                                let p = []
                                                let m = maxPoints;
                                                for (let i = 0; i < points.length; i++) {
                                                    p.push({ ...points[i] });
                                                    let indx = p.length - 1;
                                                    let u = 0;
                                                    for (let j = 0; j < points[i].used.length; j++) {
                                                        if (!points[i].used[j].returned) {
                                                            u += points[i].used[j].amount;
                                                        }
                                                    }
                                                    let unused = points[i].amount - u;
                                                    if (unused > 0) {
                                                        if (unused <= m) {
                                                            let tmp = { amount: unused, order: response.orderNumber, returned: false }
                                                            let tmp2 = [...p[indx].used, { ...tmp }]
                                                            p[indx].used = [...tmp2]
                                                            m -= unused;
                                                        } else {
                                                            let tmp = { amount: m, order: response.orderNumber, returned: false }
                                                            let tmp2 = [...p[indx].used, { ...tmp }]
                                                            p[indx].used = [...tmp2]
                                                            m = 0;
                                                        }
                                                    }
                                                }
                                                let ids = [];
                                                let used = [];
                                                for (let i = 0; i < p.length; i++) {
                                                    ids.push(p[i]._id)
                                                    used.push([...p[i].used]);
                                                }
                                                updateUsedPoints(ids, used, user._id, token).then((res) => {
                                                    if (res.error) {
                                                        console.log(res.error);
                                                    } else {

                                                    }
                                                })
                                            }
                                            clearCart(() => { });
                                            setPaymentResponse({ status: true });
                                            setOrderNumber((response.orderNumber));
                                            setDonePayment(true);
                                            
                                            if (user) {
                                                updateUserHistory(user._id, token, response.orderNumber);
                                                let createdDated = new Date(response.createdAt);
                                                let n = new Date(createdDated.setMonth(createdDated.getMonth() + 6))
                                                let m = n.getMonth()
                                                let y = n.getFullYear()
                                                let numberOfDays = new Date(y, m + 1, 0).getDate()
                                                let expireDate = new Date(y, m, numberOfDays, 23, 59, 59)
                                                let totPoints = Math.floor(grandTotal - (freeDelivery ? 0 : delivery))
                                                if (totPoints > 0) {
                                                    createPoint({ amount: Math.floor(totPoints), user: user._id, order: response._id, status: "pendding", expireDate: expireDate }, user._id, token).then(res => {
                                                        if (res.error) {
                                                            console.log(error);
                                                        }
                                                    })
                                                }

                                            }
                                        }
                                    });
                                }
                            }
                        })
                    } else {
                        const orderData = { email: usedEmail, products: products, gift: { id: gift.id, name: gift.name }, freeItems: freeItemsToSend, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, discountType: discount && discount > 0 ? usePoint == 'yes' ? "points" : "coupon" : "", couponCode: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: "cash", paymentStatus: false }
                        createOrder(orderData, user ? user._id : null, token, (default_url === "/en/" ? "en" : "ar"), cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null).then(response => {
                            if (response.error) {

                            } else {
                                let tmpProducts = [...products];
                                for (let m = 0; m < freeItemsToSend.length; m++) {
                                    tmpProducts.push({
                                        _id: freeItemsToSend[m].childId,
                                        count: 1
                                    });
                                }
                                decreaseQuantity(tmpProducts);
                                if (gift.id) {
                                    decreaseGiftQuantity(gift.id);
                                }
                                if (usePoint == "yes" && discount > 0) {
                                    let p = []
                                    let m = maxPoints;
                                    for (let i = 0; i < points.length; i++) {
                                        p.push({ ...points[i] });
                                        let indx = p.length - 1;
                                        let u = 0;
                                        for (let j = 0; j < points[i].used.length; j++) {
                                            if (!points[i].used[j].returned) {
                                                u += points[i].used[j].amount;
                                            }
                                        }
                                        let unused = points[i].amount - u;
                                        if (unused > 0) {
                                            if (unused <= m) {
                                                let tmp = { amount: unused, order: response.orderNumber, returned: false }
                                                let tmp2 = [...p[indx].used, { ...tmp }]
                                                p[indx].used = [...tmp2]
                                                m -= unused;
                                            } else {
                                                let tmp = { amount: m, order: response.orderNumber, returned: false }
                                                let tmp2 = [...p[indx].used, { ...tmp }]
                                                p[indx].used = [...tmp2]
                                                m = 0;
                                            }
                                        }
                                    }
                                    let ids = [];
                                    let used = [];
                                    for (let i = 0; i < p.length; i++) {
                                        ids.push(p[i]._id)
                                        used.push([...p[i].used]);
                                    }
                                    updateUsedPoints(ids, used, user._id, token).then((res) => {
                                        if (res.error) {
                                            console.log(res.error);
                                        } else {

                                        }
                                    })
                                }
                                clearCart(() => { });
                                setPaymentResponse({ status: true });
                                setOrderNumber((response.orderNumber));
                                setDonePayment(true);
                                
                                if (user) {
                                    updateUserHistory(user._id, token, response.orderNumber);
                                    let createdDated = new Date(response.createdAt);
                                    let n = new Date(createdDated.setMonth(createdDated.getMonth() + 6))
                                    let m = n.getMonth()
                                    let y = n.getFullYear()
                                    let numberOfDays = new Date(y, m + 1, 0).getDate()
                                    let expireDate = new Date(y, m, numberOfDays, 23, 59, 59)
                                    let totPoints = Math.floor(grandTotal - (freeDelivery ? 0 : delivery))
                                    if (totPoints > 0) {
                                        createPoint({ amount: Math.floor(totPoints), user: user._id, order: response._id, status: "pendding", expireDate: expireDate }, user._id, token).then(res => {
                                            if (res.error) {
                                                console.log(error);
                                            }
                                        })
                                    }
                                }
                            }
                        });
                    }


                }
                return chaneged;
            }
        });
    }

    const freeCheckout = async (enAddress, arAddress) => {
        let chaneged = false;
        setIsLoading(isLoading + 1);
        return await products.map(async (p, i) => {
            let q = await getItemQuantity(p._id);
            if (q <= 0 || p.count <= 0) {
                await removeItem(p._id);
                chaneged = true
            } else if (q < p.count) {
                await updateItem(p._id, q);
                chaneged = true
            }
            setIsLoading(isLoading - 1);
            if (i === products.length - 1) {
                if (chaneged) {
                    let tmpCart = getCart();
                    setProducts2(tmpCart);
                    showWarning("your_cart_has_been_updated._please_review_your_cart_before_checking_out");
                    setShowBackdrop(false);
                } else {
                    let user = null, token = null;
                    if (isAuthenticated()) {
                        user = isAuthenticated().user;
                        token = isAuthenticated().token;
                    }
                    if (usePoint == 'yes') {
                        let oldMax = maxPoints;
                        getPointsByUser(user._id, token, { status: "active" }).then(res => {
                            if (res.error) {
                                console.log(res.error);
                            } else {
                                setPoints(res);
                                let t = getTotalPoints(res);
                                let d = 0;
                                if (parseFloat(total) < parseFloat(amountForFreeDelivery)) {
                                    if (fromSelectedAreas) {
                                        d = parseFloat(deliveryForSelectedAreas);
                                    } else {
                                        d = parseFloat(normalDelivery);
                                    }
                                }
                                let tot = parseFloat(parseFloat(total) + d) * 10;
                                let tot2 = Math.floor(tot);
                                if (tot > tot2) {
                                    tot2 += 1;
                                }
                                let tmp = tot2;
                                let mx = Math.min(t, tmp);
                                setMaxPoints(Math.min(t, tmp));
                                let change = false;
                                if (mx != oldMax) {
                                    change = true;
                                    showWarning("your_points_been_updated._please_review_your_points_before_checking_out");
                                    setShowBackdrop(false);
                                } else {
                                    const orderData = { email: usedEmail, products: products, gift: { id: gift.id, name: gift.name }, freeItems: freeItemsToSend, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, discountType: discount && discount > 0 ? usePoint == 'yes' ? "points" : "coupon" : "", couponCode: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: couponDetails ? "coupon" : usePoint == "yes" ? "points" : "freeCheckout", paymentStatus: true }
                                    createOrder(orderData, user ? user._id : null, token, (default_url === "/en/" ? "en" : "ar"), cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null).then(response => {
                                        if (response.error) {

                                        } else {
                                            let tmpProducts = [...products];
                                            for (let m = 0; m < freeItemsToSend.length; m++) {
                                                tmpProducts.push({
                                                    _id: freeItemsToSend[m].childId,
                                                    count: 1
                                                });
                                            }
                                            decreaseQuantity(tmpProducts);
                                            if (gift.id) {
                                                decreaseGiftQuantity(gift.id);
                                            }
                                            if (usePoint == "yes" && discount > 0) {
                                                let p = []
                                                let m = maxPoints;
                                                for (let i = 0; i < points.length; i++) {
                                                    p.push({ ...points[i] });
                                                    let indx = p.length - 1;
                                                    let u = 0;
                                                    for (let j = 0; j < points[i].used.length; j++) {
                                                        if (!points[i].used[j].returned) {
                                                            u += points[i].used[j].amount;
                                                        }
                                                    }
                                                    let unused = points[i].amount - u;
                                                    if (unused > 0) {
                                                        if (unused <= m) {
                                                            let tmp = { amount: unused, order: response.orderNumber, returned: false }
                                                            let tmp2 = [...p[indx].used, { ...tmp }]
                                                            p[indx].used = [...tmp2]
                                                            m -= unused;
                                                        } else {
                                                            let tmp = { amount: m, order: response.orderNumber, returned: false }
                                                            let tmp2 = [...p[indx].used, { ...tmp }]
                                                            p[indx].used = [...tmp2]
                                                            m = 0;
                                                        }
                                                    }
                                                }
                                                let ids = [];
                                                let used = [];
                                                for (let i = 0; i < p.length; i++) {
                                                    ids.push(p[i]._id)
                                                    used.push([...p[i].used]);
                                                }
                                                updateUsedPoints(ids, used, user._id, token).then((res) => {
                                                    if (res.error) {
                                                        console.log(res.error);
                                                    } else {

                                                    }
                                                })
                                            }
                                            clearCart(() => { });
                                            setPaymentResponse({ status: true });
                                            setOrderNumber((response.orderNumber));
                                            setDonePayment(true);
                                            if (user) {
                                                updateUserHistory(user._id, token, response.orderNumber);
                                            }
                                        }
                                    });
                                }
                            }
                        })
                    } else {
                        const orderData = { email: usedEmail, products: products, gift: { id: gift.id, name: gift.name }, freeItems: freeItemsToSend, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, discountType: discount && discount > 0 ? usePoint == 'yes' ? "points" : "coupon" : "", couponCode: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: couponDetails ? "coupon" : usePoint == "yes" ? "points" : "freeCheckout", paymentStatus: true }
                        createOrder(orderData, user ? user._id : null, token, (default_url === "/en/" ? "en" : "ar"), cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null).then(response => {
                            if (response.error) {

                            } else {
                                let tmpProducts = [...products];
                                for (let m = 0; m < freeItemsToSend.length; m++) {
                                    tmpProducts.push({
                                        _id: freeItemsToSend[m].childId,
                                        count: 1
                                    });
                                }
                                decreaseQuantity(tmpProducts);
                                if (gift.id) {
                                    decreaseGiftQuantity(gift.id);
                                }
                                if (usePoint == "yes" && discount > 0) {
                                    let p = []
                                    let m = maxPoints;
                                    for (let i = 0; i < points.length; i++) {
                                        p.push({ ...points[i] });
                                        let indx = p.length - 1;
                                        let u = 0;
                                        for (let j = 0; j < points[i].used.length; j++) {
                                            if (!points[i].used[j].returned) {
                                                u += points[i].used[j].amount;
                                            }
                                        }
                                        let unused = points[i].amount - u;
                                        if (unused > 0) {
                                            if (unused <= m) {
                                                let tmp = { amount: unused, order: response.orderNumber, returned: false }
                                                let tmp2 = [...p[indx].used, { ...tmp }]
                                                p[indx].used = [...tmp2]
                                                m -= unused;
                                            } else {
                                                let tmp = { amount: m, order: response.orderNumber, returned: false }
                                                let tmp2 = [...p[indx].used, { ...tmp }]
                                                p[indx].used = [...tmp2]
                                                m = 0;
                                            }
                                        }
                                    }
                                    let ids = [];
                                    let used = [];
                                    for (let i = 0; i < p.length; i++) {
                                        ids.push(p[i]._id)
                                        used.push([...p[i].used]);
                                    }
                                    updateUsedPoints(ids, used, user._id, token).then((res) => {
                                        if (res.error) {
                                            console.log(res.error);
                                        } else {

                                        }
                                    })
                                }
                                clearCart(() => { });
                                setPaymentResponse({ status: true });
                                setOrderNumber((response.orderNumber));
                                setDonePayment(true);
                                if (user) {
                                    updateUserHistory(user._id, token, response.orderNumber);
                                }
                            }
                        });
                    }
                }
                return chaneged;
            }
        });
    }

    const getPaymentMethod = (method) => {
        setPaymentMethod(method);
    }

    const handleUsePointChange = (event) => {
        setUsePoint(event.target.value);
        if (event.target.value == 'yes') {
            setCouponCode("");
            setCouponDetails();
        }
    }

    String.prototype.ArtoEn = function () {
        return this.replace(/[\u0660-\u0669]/g,
            d => d.charCodeAt() - 1632)
    }

    const handleChange = name => event => {

        if (name === "usedEmail") {
            setUsedEmail(event.target.value);
        } else {
            if (name === "city") {
                let tmpAreas = currentCities[event.target.value] ? [...areas[currentCities[event.target.value]]] : [];
                let tmpAreas2 = [];
                if (areasOutsideDelivery.arrayValue) {
                    for (let i = 0; i < tmpAreas.length; i++) {
                        let currentArea = tmpAreas[i];
                        if (default_url == "/en/") {
                            currentArea = areasEnAr[currentArea];
                            console.log(currentArea);
                        }
                        if (areasOutsideDelivery.arrayValue.indexOf(currentArea) < 0) {
                            tmpAreas2.push(tmpAreas[i]);
                        }
                    }
                } else {
                    tmpAreas2 = [...tmpAreas]
                }
                setAddress({ ...address, [name]: event.target.value, areasInCity: tmpAreas2, area: "", error: null })
            } else if (name === "area") {
                let newArea = areasInCity[event.target.value]
                if (selectedAreas.indexOf(newArea) >= 0 || (default_url === "/en/" ? selectedAreas.indexOf(areasEnAr[newArea]) >= 0 : selectedAreas.indexOf(areasArEn[newArea]) >= 0)) {
                    setFromSelectedAreas(true);
                } else {
                    setFromSelectedAreas(false);
                }
                setAddress({ ...address, [name]: event.target.value, error: null })
            } else {
                var s = event.target.value;
                if (name === "mobile") {
                    s = s.replace(/\D/g, '');
                    s = s.ArtoEn()
                }
                setAddress({ ...address, [name]: s, error: null })
            }

        }

    }


    const handleChangeSignIn = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    }

    const handleCheckoutClick = async (event) => {
        event.preventDefault();
        setShowBackdrop(true);
        if (addressValidation()) {
            const enAddress = (fName + " " + lName + ", " + mobile + "\n" + (default_url === "/en/" ? currentCities[city] : citiesArEn[currentCities[city]]) + ", " + (default_url === "/en/" ? areasInCity[area] : areasArEn[areasInCity[area]]) + "\n" + en_dic['block'] + ": " + block + ", " + en_dic['street'] + ": " + street + ", " + en_dic['lane'] + ": " + (lane != "" ? lane : en_dic['don\'t_exist']) + "\n" + en_dic['building'] + ": " + building + ", " + en_dic['floor'] + ": " + (floor != "" ? floor : en_dic['don\'t_exist']) + ", " + en_dic['apartment'] + ": " + (apartment != "" ? apartment : en_dic['don\'t_exist']) + "\n" + extra_details);
            const arAddress = (fName + " " + lName + ", " + mobile + "\n" + (default_url === "/en/" ? citiesEnAr[currentCities[city]] : currentCities[city]) + ", " + (default_url === "/en/" ? areasEnAr[areasInCity[area]] : areasInCity[area]) + "\n" + ar_dic['block'] + ": " + block + ", " + ar_dic['street'] + ": " + street + ", " + ar_dic['lane'] + ": " + (lane != "" ? lane : ar_dic['don\'t_exist']) + "\n" + ar_dic['building'] + ": " + building + ", " + ar_dic['floor'] + ": " + (floor != "" ? floor : ar_dic['don\'t_exist']) + ", " + ar_dic['apartment'] + ": " + (apartment != "" ? apartment : ar_dic['don\'t_exist']) + "\n" + extra_details);
            if (parseFloat(grandTotal) > 0) {
                let t = total - (discount ? discount : 0);
                if (minimumCart && t < minimumCart) {
                    showMinimumCartMessage(t);
                    setShowBackdrop(false);
                } else {
                    if (paymentMethod === "online") {
                        const orderData = { email: usedEmail, products: products, amount: grandTotal, delivery: freeDelivery ? 0.000 : delivery, discount: discount, couponCoude: couponCode, enAddress: enAddress, arAddress: arAddress, paymentMethod: "online" }
                        onlinePayCheckOut(enAddress, arAddress);
                    } else if (paymentMethod === "cash") {
                        cashOnDeliveryCheckOut(enAddress, arAddress);
                    }
                }
            } else {
                freeCheckout(enAddress, arAddress)
            }
        }
    }

    const addressValidation = () => {
        if (fName === "" || usedEmail === "" || mobile === "" || area === "" || block === "" || street === "" || building === "") {
            setAddress({ ...address, error: dict['please_fill_the_required_fields'] })
            setShowBackdrop(false);
            return false;
        }
        let isnum = /^\d+$/.test((mobile + ""));
        if ((mobile + "").length != 8 || !isnum) {
            setAddress({ ...address, error: dict['please_check_your_mobile_number'] })
            setShowBackdrop(false);
            return false;
        }
        return true;
    }

    const showItems = () => {
        return (
            <>{
                products.map((item, i) => {
                    return (
                        <Fragment key={i}>
                            <div className="cart-item">
                                <a href={item.link}>
                                    <img className="checkout-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                                </a>
                                <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                    <div className="cart-item-info-container">
                                        <h6 className="cart-item-info" style={{ maxWidth: "88%" }}>{default_url === "/en/" ? item.enName : item.arName}</h6>
                                        {item.color !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Color " : "اللون "} {item.color}</h6>}
                                        {item.size !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Size " : "حجم "} {item.size}</h6>}
                                    </div>
                                </div>
                                <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                    <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-75px" : "-65px" }}>
                                        <div
                                            className="cart-item-info cart-item-info-remove"
                                            style={{ fontSize: "12px" }}
                                        >
                                            <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", textDecoration: item.discountPrice ? "line-through" : "" }}>{fixLength(item.price) + " " + dict['kd']}</h6>
                                            <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", display: item.discountPrice ? "" : "none", color: "red" }}>{fixLength(item.discountPrice) + " " + dict['kd']}</h6>

                                            <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{item.count + " " + dict["piece"]}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                    <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-120px" }}>
                                        <div
                                            className="cart-item-info cart-item-info-remove"
                                            style={{ fontSize: "13px" }}
                                        >
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />
                        </Fragment>

                    );

                })}

            </>
        );
    }

    const showGift = () => {
        return (
            <Fragment>
                <div className="cart-item">
                    <img className="checkout-item-image" src={IMAGE_URL + gift.image + "_small.jpg"}></img>
                    <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                        <div className="cart-item-info-container">
                            <h6 className="cart-item-info">{gift.name}</h6>
                            <h6 className="cart-item-info cart-item-info-price" style={{ color: "red" }}>{dict["free_gift"]}</h6>
                        </div>
                    </div>
                </div>
                <hr />
            </Fragment>
        );
    }

    const showsFreeItems = () => {
        return (
            <Fragment>
                {
                    currentFreeItems.map((item) => {
                        return (
                            <>
                                <div className="cart-item">
                                    <img className="checkout-item-image" src={IMAGE_URL + item.child.images[0] + "_small.jpg"}></img>
                                    <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                        <div className="cart-item-info-container">
                                            <h6 className="cart-item-info">{default_url == "/en/" ? item.child.enTitle : item.child.arTitle}</h6>
                                            <h6 className="cart-item-info cart-item-info-price" style={{ color: "red" }}>{dict["free_item"]}</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                            </>
                        )
                    })
                }
            </Fragment>
        );
    }

    const noItemsMsg = () => {
        return (<h2 style={{ textAlign: "start" }}>{dict['your_cart_is_empty'] + "."}</h2>);
    }

    const showSummary = (done = false) => {
        return (
            <div>
                <div style={{ height: "auto", display: "flex" }}>
                    <TextField
                        classes={{ root: classes.root }}
                        id="outlined-basic"
                        value={couponCode}
                        label={dict['have_a_promo_code?']}
                        variant="outlined"
                        style={{ float: default_url === "/en/" ? "left" : "right", textAlign: "left", width: "69%", height: "30px" }}
                        inputProps={{ style: { textAlign: "left", direction: "ltr", padding: "10px 6px" } }}
                        InputLabelProps={{ style: { fontSize: default_url === "/en/" ? "13px" : "16px", color: focused ? "#E4BC4B" : "", transform: focused || couponCode ? "translate(14px, -6px) scale(0.75)" : "translate(14px, 12px) scale(1)", backgroundColor: focused || couponCode ? "white" : "" } }}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onChange={handleCouponChange}
                    />
                    <Button
                        variant="contained"
                        style={{ fontSize: default_url === "/en/" ? "13px" : "16px", float: default_url === "/en/" ? "left" : "right", marginInlineStart: "2%", width: "29%", height: "38px", color: "white", backgroundColor: "#E4BC4B" }}
                        onClick={handleCouponSubmit}
                    >
                        {dict['apply']}
                    </Button>
                </div>
                <div style={{ display: "flow-root", marginTop: "18px", color: "gray" }}>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["total"]}</div>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>{total + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "flow-root", margin: "10px 0 5px", color: "gray" }}>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["delivery"]}</div>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>{((freeDelivery ? 0.000 : (delivery * 100) / 100).toFixed(3)) + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "flow-root", margin: "5px 0 5px", color: "red", textAlign: "start" }}>
                    <div style={{ fontSize: "12px", display: "inline-block" }} >{deliveryMessage}</div>
                </div>
                <hr />
                <div style={{ display: "flow-root", marginTop: "18px", color: "gray" }}>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["subtotal"]}</div>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>{subtotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "flow-root", marginTop: "13px", color: "red" }}>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount && discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "left" : "right" }}>{usePoint == 'yes' ? dict['points_discount'] : dict["promo_discount"]}</div>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount && discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "right" : "left" }}>{discount + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "flow-root", margin: "10px 0 18px", color: "gray" }}>
                    <div style={{ fontSize: "18px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["grand_total"]}</div>
                    <div style={{ fontSize: "18px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>{grandTotal + " " + dict['kd']}</div>
                </div>
                <Button
                    variant="contained"
                    onClick={handleCheckoutClick}
                    //disabled
                    //style={{ fontSize: default_url === "/en/" ? "13px" : "16px", float: window.innerWidth < 1024 ? "" : default_url === "/en/" ? "left" : "right", width: "100%", height: "38px", color: "white", backgroundColor: "gray", marginBottom: "10px" }}
                    style={{ fontSize: default_url === "/en/" ? "13px" : "16px", float: window.innerWidth < 1024 ? "" : default_url === "/en/" ? "left" : "right", width: "100%", height: "38px", color: "white", backgroundColor: "#E4BC4B", marginBottom: "10px" }}
                >
                    {dict["place_order"]}
                </Button>
                <div style={{ display: "block", margin: "5px 0 30px", color: "red" }}>
                    <div style={{ fontSize: "12px", display: "inline-block", float: default_url === "/en/" ? "" : "right" }}>{dict["by_clicking_“place_order”,_i_confirm_i_have_read_and_acknowledge_all_terms_and_policies."]}</div>
                </div>
            </div>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const citySelector = () => {
        return (
            <select onChange={handleChange("city")} name="hasSubcategory" className="inputField form-control" value={city}>
                <option value=""></option>
                {
                    currentCities.map((d, i) => {
                        return <option key={i} value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const areaSelector = () => {
        return (
            <select onChange={handleChange("area")} name="hasSubcategory" className="inputField form-control" value={area}>
                <option value=""></option>
                {
                    city && areasInCity.map((d, i) => {
                        return <option key={i} value={i + ""}>{d}</option>
                    })
                }
            </select>
        )
    }

    const showError = () => {
        if (!error) {
            return;
        }
        store.addNotification({
            message: error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        })
        setAddress({ ...address, error: "" });
    }

    const showSigninError = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        })
    }

    const showUserAddresses = () => {
        if (!isAuthenticated()) {
            return null;
        }
        return (
            <FormControl component="fieldset" style={{ width: "100%" }}>
                <RadioGroup aria-label="method" name="method" value={addressIndex} onChange={handleRadioBtnChange} >
                    {
                        userAddresses.map((a, i) => {
                            const curntAddress = (a.fName + " " + a.lName + ", " + a.mobile + "\n" + (default_url === "/en/" ? (a.enCity + ", " + a.enArea) : (a.arCity + ", " + a.arArea)) + "\n" + dict['block'] + ": " + a.block + ", " + dict['street'] + ": " + a.street + ", " + dict['lane'] + ": " + (a.lane != "" ? a.lane : dict['don\'t_exist']) + "\n" + dict['building'] + ": " + a.building + ", " + dict['floor'] + ": " + (a.floor != "" ? a.floor : dict['don\'t_exist']) + ", " + dict['apartment'] + ": " + (a.apartment != "" ? a.apartment : dict['don\'t_exist']) + "\n" + a.extra).split("\n");
                            let text = "<div>";
                            curntAddress.forEach(l => {
                                text += "<h1>" + l + "</h1>";
                            });
                            text += "</div>"
                            return (
                                i === userAddresses.length - 1 ?
                                    <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px", textAlign: "start" }} value={i} control={<Radio />} label={dict[a]} /> :
                                    <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px", textAlign: "start", marginBottom: "15px" }} value={i} control={<Radio />} label={<div>{curntAddress.map(l => { return <h1>{l}</h1> })}</div>} />
                            )
                        })
                    }
                </RadioGroup>
            </FormControl>
        )
    }

    const showLogin = () => {
        if (isAuthenticated()) {
            return null;
        } else {
            return (
                <div>
                    <div>
                        <h3 className="formContainerTitle" dir={default_url == "/en/" ? "ltr" : "rtl"} style={{ marginBottom: "10px", fontSize: "14px" }}>{dict["login"]}</h3>
                        <form>
                            <input onChange={handleChangeSignIn("email")} className="inputField form-control" type="email" name="user-email" value={email} placeholder={dict["email"]} style={default_url == "/en/" ? enStylelabel : arStylelabel} required /><br />
                            <input onChange={handleChangeSignIn("password")} className="inputField form-control" type="password" name="user-password" value={password} placeholder={dict["password"]} required /><br />
                            <button onClick={clickSubmit} type="submit" className="loginBtn" style={{ marginTop: "5px", marginBottom: "20px" }}>{dict["login"]}</button>
                        </form>
                    </div>
                </div>
            )
        }
    }

    const showBreakLine = () => {
        if (isAuthenticated()) {
            return null;
        } else {
            return (
                <div style={{ marginBottom: "10px", marginTop: "20px" }}>
                    <div style={{ width: "100%", height: "1px", backgroundColor: "black" }}></div>
                    <div style={{ marginInlineStart: "24%", position: "relative", textAlign: "center", top: "-17.5px", padding: "5px 1%", backgroundColor: "white", width: "50%" }}>{dict['or_continue_as_guest']}</div>
                </div>
            )

        }
    }

    const showAddressForm = () => {
        return (
            <form style={{ display: (isAuthenticated() && addressIndex === userAddresses.length - 1) || !isAuthenticated() ? "" : "none" }}>
                {showError()}
                <h6 style={{ float: default_url === "/en/" ? "right" : "left", fontSize: "12px", color: "red", marginTop: "-5px" }}>{dict["required"]}</h6>
                <div>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["first_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="first_name" placeholder={dict["first_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["last_name"]}</label>
                    <input onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="last_name" placeholder={dict["last_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("mobile")} type="text" inputmode="numeric" pattern="[0-9]+" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("usedEmail")} type="email" value={usedEmail} className="inputField form-control" id="usedEmail" placeholder="email@example.com"></input>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city"]} <span className="redStar">*</span></label><br />
                            {citySelector()}
                        </div>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area"]} <span className="redStar">*</span></label><br />
                            {areaSelector()}
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["block"]} <span className="redStar">*</span></label>
                            <input onChange={handleChange("block")} type="text" value={block} className="inputField form-control" id="block" placeholder={dict["block"]}></input>
                        </div>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["street"]} <span className="redStar">*</span></label>
                            <input onChange={handleChange("street")} type="text" value={street} className="inputField form-control" id="street" placeholder={dict["street"]}></input>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["lane"]}</label>
                            <input onChange={handleChange("lane")} type="text" value={lane} className="inputField form-control" id="lane" placeholder={dict["lane"]}></input>
                        </div>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["building"]} <span className="redStar">*</span></label>
                            <input onChange={handleChange("building")} type="text" value={building} className="inputField form-control" id="building" placeholder={dict["building"]}></input>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["floor"]}</label>
                            <input onChange={handleChange("floor")} type="text" value={floor} className="inputField form-control" id="floor" placeholder={dict["floor"]}></input>
                        </div>
                        <div style={{ width: "50%", display: "block" }}>
                            <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["apartment"]}</label>
                            <input onChange={handleChange("apartment")} type="text" value={apartment} className="inputField form-control" id="apartment" placeholder={dict["apartment"]}></input>
                        </div>
                    </div>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["extra_details"]}</label>
                    <input onChange={handleChange("extra_details")} type="text" value={extra_details} className="inputField form-control" id="extra_details" placeholder={dict["extra_details"]}></input>
                </div>
            </form>

        )

    }

    const showCheckOut = () => {
        return (
            <Fragment>
                {showBackdrop()}
                <h1 style={{ textAlign: "start", fontSize: "18px" }}>{dict['checkout']}</h1>
                <div style={{ display: window.innerWidth < 1024 ? "block" : "block", textAlign: "start" }}>
                    <div className="checkout-address-container" style={{ display: "inline-block", verticalAlign: 'top' }}>
                        <h3 style={{ textAlign: "start" }}>{dict["billing_address"]}</h3>
                        <hr />
                        {showUserAddresses()}
                        {/* {showLogin()}
                        {showBreakLine()} */}
                        {showAddressForm()}
                    </div>
                    <div className="checkout-payments-container" style={{ display: "inline-block", verticalAlign: 'top', marginInLineEnd: "3%", padding: 0, backgroundColor: "var(--background)", boxShadow: 'none', }}>
                        <div className="checkout-payments-container" style={{ display: "inline-block", verticalAlign: 'top', width: "100%", marginTop: "0" }}>
                            <h3 style={{ textAlign: "start" }}>{dict["payment_methods"]}</h3>
                            <hr />
                            <PaymentMethods
                                paymentMethod={(method) => {
                                    getPaymentMethod(method);
                                }}
                                grandTotal={parseFloat(grandTotal)}
                            />
                        </div>
                        {
                            user && activePoints == true && <Fragment>
                                <br />
                                <br />
                                <div className="checkout-payments-container" style={{ display: "inline-block", verticalAlign: 'top', width: "100%", marginTop: "0" }}>
                                    <h3 style={{ textAlign: "start" }}>{dict["points"]}</h3>
                                    <hr />
                                    {maxPoints == 0 ? dict["you_don't_have_any_points"] : dict['you_have'] + " " + maxPoints + " " + (default_url == "/en/" ? "points" : " نقطة") + " " + dict['valid_to_use'] + ". " + dict['would_you_like_to_use_them?']}
                                    {maxPoints > 0 && <div style={{ fontSize: "12px", color: "red", marginTop: "5px" }}>
                                        {"(" + dict["you_can't_use_points_with_coupons"] + ")"}
                                    </div>}
                                    {/* <UsePoints
                                        usePoint={(use) => {
                                            getUsePoint(use);
                                        }}
                                    /> */}
                                    {maxPoints > 0 && <FormControl component="fieldset" >
                                        <RadioGroup aria-label="use" name="use" value={usePoint} onChange={handleUsePointChange} >
                                            <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="no" control={<Radio />} label={dict["no_i_wouldn't_like_to_use_them"]} />
                                            <FormControlLabel style={{ marginInlineStart: "-11px", marginInlineEnd: "16px" }} value="yes" control={<Radio />} label={dict["yes_i_would_like_to_use_them"]} />
                                        </RadioGroup>
                                    </FormControl>}
                                </div>
                            </Fragment>
                        }

                    </div>
                    <div className="checkout-items-container" style={{ display: "inline-block", verticalAlign: 'top' }}>
                        <h3 style={{ textAlign: "start" }}>{dict["order_review"]}</h3>
                        <hr />
                        {products.length > 0 && showItems()}
                        {gift.name && showGift()}
                        {currentFreeItems.length > 0 && showsFreeItems()}
                        <div style={{ marginBottom: "10px" }}>
                            <Link to={default_url + "cart"} style={{ fontSize: "12px", color: "red", textDecoration: "underline" }}>
                                {dict['return_to_cart']}
                            </Link>
                        </div>
                        {products.length > 0 && showSummary()}
                        {products.length === 0 && noItemsMsg()}
                    </div>
                </div>
            </Fragment>

        )
    }

    const showPaymentResponse = () => {
        if (paymentResponse.status === true) {
            return showPaymentSuccess();
        }
        return (<h1>Error!</h1>)
    }

    const showPaymentSuccessSummary = () => {
        return (
            <div style={{ textAlign: "end", padding: "0 10px" }}>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["total"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{total + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["delivery"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{(Math.round(delivery * 1000) / 1000).toFixed(3) + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["subtotal"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{subtotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "13px", color: "red" }}>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["promo_discount"]}</div>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: "inline-block" }}>{discount + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "16px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "left" : "right", fontFamily: "sans-serif" }}>{dict["grand_total"]}</div>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block" }}>{grandTotal + " " + dict['kd']}</div>
                </div>
            </div>
        )
    }

    const showDeliveryAddress = () => {
        let deliveryAddress = (addressAsString).split("\n");
        return (
            <div style={{ textAlign: "start", padding: "0 10px" }}>
                <h1 style={{ fontSize: "18px", fontWeight: "900", fontFamily: "sans-serif", marginBottom: "10px" }}>{dict['delivery_address']}</h1>
                {
                    deliveryAddress.map((line, i) => {
                        return <h1 key={i} style={{ marginBottom: i != deliveryAddress.length - 1 ? "6px" : "0" }}>{line}</h1>
                    })
                }
            </div>
        )
    }

    const showPaymentSuccess = () => {
        return (
            <div className="checkout-response-container">
                <CheckCircleOutlineIcon style={{ width: "100px", height: "100px", color: "#E4BC4B", marginBottom: "10px" }} />
                <h1 style={{ fontSize: "22px", fontWeight: "900", fontFamily: "sans-serif" }}>{dict['your_order_has_been_placed']}</h1>
                {orderNumber && <h1 style={{ fontSize: "18px", marginTop: "8px", fontFamily: "sans-serif" }}>{dict['your_order_number_is']} {orderNumber}</h1>}
                <hr />
                {showItems()}
                {showPaymentSuccessSummary()}
                <hr />
                {addressAsString != null && addressAsString.length > 0 && showDeliveryAddress()}
            </div>
        )
    }

    return (
        <div className="container-fluid" style={{ margin: "15px 0 70px" }}>
            <Helmet>
                <title>{default_url == "/en/" ? "Checkout" : "الدفع"}</title>
            </Helmet>
            {!donePayment && showCheckOut()}
            {donePayment && <Redirect to={{ pathname: default_url + (paymentResponse && paymentResponse.status === true ? 'thankyou' : "failed"), state: { orderNumber: orderNumber } }} />}
        </div>
    )
}

export default CheckOut;