import React, { useState, useEffect, Fragment } from "react";
import UserDashboard from "../UserDashboard"
import { isAuthenticated } from "../../../auth"
import { dict } from "../../../dictionary";
import { default_url } from "../../..";
import { listOrders, getPointsByUser } from "../apiUser"
import { store } from 'react-notifications-component';
import { getOrders } from "../../apiCore";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment"
import FiberNewIcon from '@material-ui/icons/FiberNew';
import CancelIcon from '@material-ui/icons/Cancel';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { IMAGE_URL } from "../../../config";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    ExpansionPanelDetails: {
        display: "inline",
        padding: "0"
    },
    panelHeader: {
        backgroundColor: "#f7f7f7"
    }
}));

function UserOrders() {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    let isMobile = window.innerWidth < 1024;

    const [values, setValues] = useState({
        ordersNumbers: [],
        data: [],
        error: "",
        success: false
    });
    const [points, setPoints] = useState([]);

    const { ordersNumbers, data, error, success } = values;
    const { user, token } = isAuthenticated();

    const loadOrders = () => {
        listOrders(user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, success: false, error: res.error })
            } else {
                getOrders(res).then(res2 => {
                    if (res2.error) {
                        setValues({ ...values, success: false, error: res2.error })
                    } else {
                        setValues({ ...values, success: false, error: "", ordersNumbers: res, data: res2 })
                    }
                });
            }
        })
    }

    const loadPoints = () => {
        getPointsByUser(user._id, token, {}).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setPoints(res);
            }
        })
    }

    useEffect(() => {
        loadOrders();
        loadPoints();
    }, []);

    const handleChangePanel = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const showAlert = () => {
        if (error) {
            store.addNotification({
                message: dict[error],
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        } else if (success) {
            store.addNotification({
                message: dict["address_has_been_deleted"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const showOrders = () => {
        if (data.length === 0) {
            return <h6 style={{ textAlign: "start" }}>{dict['no_orders_found']}</h6>
        }
        return (
            data.sort((a, b) => a.createdAt - b.createdAt).map((o, oIndex) => {
                if (o.status === "failed" || o.status === "waiting_for_online_payment") {
                    return (null);
                }
                return orderView(o, oIndex);
            })
        )
    }

    const arStylelabel = {
        display: 'inline-block',
        float: "right",
        fontSize: "18px",
        textAlign: "start"
    }
    const enStylelabel = {
        display: 'inline-block',
        float: "left",
        fontSize: "18px",
        textAlign: "start"
    }


    const showItems = (products) => {
        return (
            products.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <a href={item.link}>
                                <img className="checkout-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                            </a>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info">{item.itemCode}</h6>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-45px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "12px" }}
                                    >
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", textDecoration: item.discountPrice ? "line-through" : "" }}>{item.price + " " + dict['kd']}</h6>
                                        <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px", display: item.discountPrice ? "" : "none", color: "red" }}>{item.discountPrice + " " + dict['kd']}</h6>

                                        <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{item.count + " " + dict["piece"]}</h6>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-120px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ fontSize: "13px" }}
                                    >

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const showGift = (gift) => {
        return (
            <div className="cart-item" style={{ minHeight: "0" }}>
                <div style={{ height: "auto", width: "40%", position: "relative", display: "inline-block" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <h6 className="cart-item-info">{gift.name}</h6>
                    </div>
                </div>
                <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <div
                            className="cart-item-info cart-item-info-remove"
                            style={{ fontSize: "12px" }}
                        >
                            <h6 className="cart-item-info" style={{ fontSize: "13px" }}>{"1 " + dict["piece"]}</h6>
                        </div>
                    </div>
                </div>
                <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "start" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <div
                            className="cart-item-info cart-item-info-remove"
                            style={{ fontSize: "12px" }}
                        >
                            <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_gift"]}</h6>
                        </div>
                    </div>
                </div>
                <div style={{ height: "auto", width: "20%", position: "relative", display: "inline-block", textAlign: "end" }}>
                    <div className="cart-item-info-container" style={{ position: "relative", transform: "translateY(0)" }}>
                        <div
                            className="cart-item-info cart-item-info-remove"
                            style={{ fontSize: "12px" }}
                        >
                            <h6 className="cart-item-info cart-item-info-price" style={{ fontSize: "13px" }}>{dict["free_gift"]}</h6>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        )
    }

    const showSummary = (total, delivery, subtotal, discount, grandTotal) => {
        return (
            <div style={{ textAlign: "end", padding: "0 10px" }}>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["total"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{total + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["delivery"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{delivery + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["subtotal"]}</div>
                    <div style={{ fontSize: "16px", display: "inline-block" }}>{subtotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: discount > 0 ? "block" : "none", marginTop: "12px", color: "red" }}>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: "inline-block", float: default_url === "/en/" ? "left" : "right" }}>{dict["promo_discount"]}</div>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: "inline-block" }}>{discount + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "12px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "left" : "right", fontFamily: "sans-serif" }}>{dict["grand_total"]}</div>
                    <div style={{ fontSize: "16px", fontWeight: "bold", display: "inline-block" }}>{grandTotal + " " + dict['kd']}</div>
                </div>
            </div>
        )
    }

    const showDeliveryAddress = (address) => {
        let deliveryAddress = (address).split("\n");
        return (
            <div style={{ textAlign: "start", padding: "0 10px" }}>
                <h1 style={{ fontSize: "18px", fontWeight: "900", fontFamily: "sans-serif", marginBottom: "10px" }}>{dict['delivery_address']}</h1>
                {
                    deliveryAddress.map((line, i) => {
                        return <h1 key={i} style={{ marginBottom: i != deliveryAddress.length - 1 ? "6px" : "0" }}>{line}</h1>
                    })
                }
            </div>
        )
    }

    const showPoints = (id) => {
        return (
            <div style={{ textAlign: "start", padding: "0 10px" }}>
                {
                    points.map((p, i) => {
                        return (
                            p.order._id == id ?
                                <div key={i} style={{ marginBottom: "20px" }}>
                                    <div style={{ marginBottom: "10px" }}>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{dict['earned_points'] + ": "}</span>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{p.amount + " " + dict['points']}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{dict['status'] + ": "}</span>
                                        <span style={{ fontSize: "18px", fontFamily: "sans-serif" }}>{p.status}</span>
                                    </div>

                                </div>
                                : <Fragment></Fragment>
                        )
                    })
                }
            </div>
        )
    }

    const orderDetailsView = (o, oIndex) => {
        let address = default_url === "/en/" ? o.enAddress : o.arAddress;
        let total = fixLength(o.amount + o.discount - o.delivery);
        let subtotal = fixLength(parseFloat(o.amount) + parseFloat(o.discount));
        let grandTotal = fixLength(o.amount);
        let delivery = fixLength(o.delivery);
        let discount = fixLength(o.discount);
        console.log(o);
        return (
            <Fragment>
                <div style={{ marginTop: "20px" }}>
                    {showItems(o.products)}
                    {o.gift && o.gift.id && showGift(o.gift)}
                </div>
                <div style={{ margin: "10px 0 20px" }}>
                    {showSummary(total, delivery, subtotal, discount, grandTotal)}
                </div>
                <hr />
                <div>
                    {showDeliveryAddress(address)}
                </div>
                {/* <hr />
                <div>
                    {showPoints(o._id)}
                </div> */}
            </Fragment>

        );
    }

    const orderView = (o, oIndex) => {
        return (
            <ExpansionPanel
                expanded={expanded === ("panel" + oIndex)}
                onChange={handleChangePanel(("panel" + oIndex))}
            >
                <ExpansionPanelSummary
                    className={classes.panelHeader}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading} style={{ textAlign: "start", marginInlineEnd: isMobile ? '30px' : "" }}>{dict['order_number'] + " " + (o.orderNumber)}</Typography>
                    <Typography className={classes.secondaryHeading} style={{ textAlign: "start", width: isMobile ? "auto" : "430px", color: o.status === "new_order" ? "red" : o.status === "delivered" ? "green" : o.status === "processing" ? "#feb72b" : o.status === "ready_for_delivering" ? "#96bb7c" : "" }}>
                        {
                            dict[o.status]
                        }
                        {
                            o.status === "new_order" && <FiberNewIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "cancelled" && <CancelIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "processing" && <AutorenewIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "ready_for_delivering" && <DoneOutlineIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                        {
                            o.status === "delivered" && <LocalShippingIcon style={{ marginInlineStart: "8px", position: "absolute" }} />
                        }
                    </Typography>
                    <Typography className={classes.secondaryHeading} dir="ltr" style={{ display: isMobile ? "none" : "" }} >{moment(o.createdAt).fromNow()}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                    {orderDetailsView(o, oIndex)}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict['my_orders']}</h3>
                <hr />
                {showOrders()}
            </div>
        )
    }

    return (
        <UserDashboard
            active="3"
            data={form}
        />
    )
}

export default UserOrders;