import React, { useState } from "react";
import {isAuthenticated} from "../../../auth"
import {default_url} from "../../../index"
import { dict } from "../../../dictionary";
import StorageDashboard from "../StorageDashboard";

function StorageInfo() {
    const {user} = isAuthenticated();
    const [values, setValues] = useState({
        fName: user.fName,
        lName: user.lName,
        email: user.email,
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        mobile: user.mobile,
        birthDate: user.dateOfBirth,
        nationality: user.nationality,
        gender: user.gender,
        newsletter: user.newsletter,
        changePassword: false,
        error: "",
        success: false
    });
    const { fName, lName, email, oldPassword, newPassword, confirmNewPassword, mobile, birthDate, nationality, gender, newsletter, changePassword, error, success } = values;
    
    const handleChange = name => event => {
        let target = "";
        if (name === "newsletter" || name === "changePassword") {
            target = event.target.checked;
        } else {
            target = event.target.value;
        }
        console.log(event.target.value);
        
        setValues({ ...values, error: false, [name]: target });
    };
    const clickSubmit = (event) => {

    }

    const changePasswordFields = () => {
        return (
            <div className="changePasswordForm" style={{display: changePassword ? "" : "none"}}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["old_password"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("oldPassword")} type="password" value={oldPassword} className="inputField form-control" id="password" placeholder={dict["old_password"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["new_password"]}<span className="redStar">*</span></label>
                <input onChange={handleChange("newPassword")} type="password" value={newPassword} className="inputField form-control" placeholder={dict["new_password"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["confirm_new_password"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("confirmNewPassword")} type="password" value={confirmNewPassword} className="inputField form-control" placeholder={dict["confirm_new_password"]}></input>
            </div>
        )
    }

    const arStyleRequiredStart = {
        left: "30px",
    }
    const enStyleRequiredStart = {
        right: "30px",
    }
    const arStylelabel = {
        float: "right"
    }

    const enStylelabel = {
        float: "left"
    }
    const form = () => {
        return (
            <div className="userProfileDataContainer">
                <h3 className="formContainerTitle">{dict["edit_account_information"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["first_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="fName" placeholder={dict["first_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["last_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="lName" placeholder={dict["last_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email_address"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("email")} type="email" value={email} className="inputField form-control" id="email" aria-describedby="emailHelp" placeholder={dict["email_address"]}></input>
                    <div className="form-check checkBoxUserDashboard">
                        <input onChange={handleChange("changePassword")} type="checkbox" checked={changePassword} className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["change_password"]}</label>
                    </div>
                    {changePasswordFields()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <StorageDashboard
            active="1"
            data={form}
        />
    )
}

export default StorageInfo;