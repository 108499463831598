import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { dict } from '../../dictionary';
import { default_url } from '../..';

function UsePoints({usePoint}) {
  const [value, setValue] = useState('no');

  const handleChange = (event) => {
    setValue(event.target.value);
    //usePoint(event.target.value)
  };

  return (
    <FormControl component="fieldset" >
      <RadioGroup aria-label="use" name="use" value={value} onChange={handleChange} >
        <FormControlLabel style={{marginInlineStart: "-11px", marginInlineEnd: "16px"}} value="no" control={<Radio />} label={dict["no_i_wouldn't_like_to_use_them"]} />
        <FormControlLabel style={{marginInlineStart: "-11px", marginInlineEnd: "16px"}} value="yes" control={<Radio />} label={dict["yes_i_would_like_to_use_them"]} />
      </RadioGroup>
    </FormControl>
  );
}

export default UsePoints;