import React, { useState, Fragment, useEffect } from "react";
import MainBarItem from "./main bar components/MainBarItem"
import { isAuthenticated, singout } from "../auth"
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { default_url, setDir, dir } from "../index"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { dict } from "../dictionary"
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { clearCart } from "./cartHelpers";
import { clearWishlist } from "./wishlistHelpers";
import { clearCompareList } from "./compareHelpers";
import { searchProducts } from "./apiCore";
import { IMAGE_URL } from "../config.js"
import { Link, useHistory } from "react-router-dom";

const searchStyle = {
    display: "inline-block",
    width: "45%",
    textAlign: "center",
    height: "auto",
    marginTop: "1rem",
    padding: "0 30px"
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: "black",
    },
}));

function MainBar() {
    let history = useHistory();
    const classes = useStyles();
    const [mouseOverLogo, setMouseOverLogo] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState("");

    const params = new URLSearchParams(window.location.search);
    let q = params.get('q');

    const { user, token } = isAuthenticated();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSignout = () => {
        singout(clearCartAndWishlistAndCompare);
        setAnchorEl(null);
        window.location.href = default_url + "signin";
    }
    const clearCartAndWishlistAndCompare = () => {
        clearCart();
        clearWishlist();
        clearCompareList();
    }

    const handleChangeLang = () => {
        if (default_url === "/en/") {
            setDir("rtl");
        } else {
            setDir("ltr");
        }
        let paths = window.location.pathname.split("/");
        let newPath = default_url;
        for (let i = 2; i < paths.length; i++) {
            newPath += paths[i] + "/"
        }
        window.location.href = (newPath.substring(0, newPath.length - 1) + window.location.search);
    }

    const handleMouseOver = () => {
        setMouseOverLogo(true);
    }
    const handleMouseOut = () => {
        setMouseOverLogo(false);
    }
    const handleMouseClick = () => {
        history.push(default_url)
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (search.length > 0) {
            if(window.location.pathname.includes("search")){
                window.location.href = default_url + "search/products?q=" + search;
            }else{
                history.push(default_url + "search/products?q=" + search);
            }
            //window.location.href = default_url + "search/products?q=" + search;
        }
    }

    const clickSubmit = (e) => {
        e.preventDefault();
        history.push(default_url + "collections");
        //window.location.href = default_url + "collections"
    }

    useEffect(() => {
        if (q) {
            setSearch(q);
        }
    }, [])

    const defaultProps = {
        color: 'secondary',
        children: <AccountBoxIcon />
    };

    return (
        <div className="container-fluid main-bar">
            <div className="main-bar-logo">
                <div className="main-bar-content">
                    <Link to={default_url}>
                        <img src={IMAGE_URL + "logo.jpg"} className="img-logo" alt="" />
                    </Link>
                </div>
            </div>
            <div className="arabicLogoContainer">
                <img
                    className="arabicLogo"
                    src={IMAGE_URL + "arabic_logo.jpg"}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={handleMouseClick}
                    style={{ cursor: mouseOverLogo ? "pointer" : "" }}
                />
            </div>
            <div className="searchStyle" style={{ width: "25%" }}>
                <form onSubmit={handleSearchSubmit}>
                    <TextField
                        style={{ width: "100%" }}
                        id="input-with-icon-textfield"
                        value={search}
                        label={dict["search"]}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" >
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{ dir: "auto" }}
                        InputLabelProps={{ style: { left: "auto" } }}
                        onChange={event => { setSearch(event.target.value) }}

                    />
                </form>

            </div>
            <div style={{ width: "20%", textAlign: "center" }}>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn" style={{ width: "90%", marginBottom: "0", marginTop: "20px", textAlign: "start", height: "45px" }}>
                    <div style={{ height: "inherit", width: "20%", display: "inline-block" }}>
                        <img style={{ height: "inherit" }} src={IMAGE_URL + "collection-icon.gif"}></img>
                    </div>

                    <div style={{ width: "75%", display: "inline-block", verticalAlign: 'top', paddingTop: "13px", height: 'inherit', paddingInlineStart: "10px", textAlign: "center" }}>
                        {dict["get_your_collection_now"]}
                    </div>

                </button>
            </div>
            <MainBarItem
                type="language"
                details={dict["lang"]}
                onClick={handleChangeLang}
                link="#"
            />
            {!isAuthenticated() &&
                <MainBarItem
                    type="login"
                    icon="fas fa-user-circle main-bar-icon"
                    details={dict["login_signup"]}
                    link={`${default_url}signin`}
                />
            }

            {
                isAuthenticated() &&
                <div className="main-bar-login" style={{ textAlign: "start" }}>
                    <div className={"main-bar-login-content"}
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        }}
                    >
                        <Badge {...defaultProps} style={{ marginInlineEnd: "10px", display: "inline-block" }} />
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ padding: "0", display: "inline-block" }}>
                            {dict["my_account"]}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => {
                                handleClose();
                                return(
                                    history.push(default_url + (isAuthenticated().user.role === 0 ? "user/" : isAuthenticated().user.role === 1 ? "admin/" : "storage/") + "account-information")
                                )
                                
                            }}>{dict["my_account"]}</MenuItem>
                            <MenuItem onClick={handleSignout}>{dict['signout']}</MenuItem>
                        </Menu>
                    </div>
                </div>
            }
            <MainBarItem
                type="wishlist"
                icon="fas fa-heart main-bar-icon"
                details={dict["wishlist"]}
                label="icon-label"
                labelText="12"
                link={default_url + "wishlist"}
            />
            <MainBarItem
                type="cart"
                icon="fas fa-shopping-cart main-bar-icon"
                details={dict["cart"]}
                label="icon-label"
                labelText="22"
                link={default_url + "cart"}
            />

            <MainBarItem
                type="points"
                icon="fas fa-clipboard-check main-bar-icon"
                details={dict["my_points"]}
                link={user && user.isPointsActive ? default_url + "user/points" : default_url + "points"}
            />
        </div>
    )
}

export default MainBar;