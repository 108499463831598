import React, { useState, useEffect } from "react";
import { dict } from "../../../dictionary";

function SizeFilterBox(props) {
    let params = new URLSearchParams(window.location.search);
    let size = params.get('size') ? params.get('size').split(",") : [];
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        props.filteredSizes(props.size.sizes);
        setChecked(!checked);
    }

    useEffect(() => {
        let bool = false;
        for (let i = 0; i < props.size.sizes.length; i++) {
            bool = bool || (size.indexOf(props.size.sizes[i]) >= 0)
        }
        setChecked(bool);
    }, [])

    return (
        <div className="form-check sizeFilter">
            <input type="checkbox" className="form-check-input sizeFilterCheckbox" id={props.size.name} checked={checked} onChange={handleChange}/>
            <label className="form-check-label sizeFilterLabel" htmlFor="exampleCheck1">{props.size.name}</label>
        </div>
    )
}

export default SizeFilterBox;