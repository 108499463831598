import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { createGeneral, downloadImages, updateGeneral, uploadImages } from "../../apiAdmin"
import { getGeneral } from "../../../apiCore";
import { store } from 'react-notifications-component';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function UploadImages() {
    const classes = useStyles();
    const [formData, setFormData] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();

    useEffect(() => {
        setFormData(new FormData());
    }, [])

    const handleChange = event => {
        setError(null);
        var array = event.target.files;
        for (var i = 0; i < array.length; i++) {
            formData.append('images[]', array[i]);
        }
        //formData.set("image", event.target.files[0]);
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        uploadImages(formData, user._id, token).then(res => {
            setLoading(false);
            if (res.error) {
                setError(res.error);
                showError();
            } else {
                showAlert();
            }
        })
    }

    const clickExport = (event) => {
        event.preventDefault();
        setLoading(true);
        downloadImages( user._id, token).then(res => {
            setLoading(false);
            if (res.error) {
                setError(res.error);
                showError();
            } else {
                showAlert();
            }
        })
    }

    const showAlert = () => {
        store.addNotification({
            message: dict['images_have_been_uploaded'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showError = () => {
        store.addNotification({
            message: error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                <h3 className="formContainerTitle">{dict["add_images"]}</h3>
                <hr />
                <form>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["upload_image"]} <span className="redStar">*</span></label><br />
                    <input onChange={handleChange} type="file" className="inputField form-control" name="images" accept="image/x-png,image/gif,image/jpeg" multiple></input>
                </form>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                <button onClick={clickExport} type="submit" className="dashboardBtn">{dict["export"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="10"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default UploadImages;