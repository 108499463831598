import React from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"

function TermsAndConditions() {
    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            <div className="footerTagsTextContainer">
                <h1 style={{fontSize:"20px", fontWeight:"900"}}>نطاق سياسة الخصوصية</h1>
                <br />
                <p>
                    تصف سياسة الخصوصية كيفية التعامل مع بياناتك الشخصية مع خدماتنا على موقع Royalhousekw.com ، فهي تنطبق بصورة عامة على موقع Royalhousekw.com ومحتواها والمواقع الأخرى ذات الصلة والتي تظهر بها هذه الخصوصية في نهاية الصفحة ، قد تخضع بعض المواقع التي تحمل شعار Royalhousekw.com أو بعض المواقع التي نديرها إلى سياسات خصوصية مختلفة ، فالسياسة التي تنطبق على أي مواقع أساسية أو فرعية نقوم بإدارتها هي دائماً السياسات التي تظهر في نهاية الصفحة لهذه المواقع
                <br />
           تقوم الشركات التابعة لنا أو المشتركة معنا بتطبيق نفس ممارسات الخصوصية الموضحة في هذه السياسة ، والتي تخضع لمقتضيات القانون المعمول به ، كما نسعى جاهدين لتوفير مجموعة متناسقة من ممارسات الخصوصية في موقع Royalhousekw.com ، من خلال قبول سياسة الخصوصية وإتفاقية المستخدم عند التسجيل ، أنت توافق على جمع وتخزين وإستخدام والكشف عن المعلومات الشخصية الخاصة بك كما هو موضح في سياسة الخصوصية.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>جمع المعلومات الشخصية</h1>
                <br />
                <p>
                يمكنك تصفح الموقع دون أن تخبرنا عن هويتك أو الكشف عن أية معلومات شخصية عنك ، و عند تسجيلك لبياناتك الشخصية تكون فعلا معروفاً لدينا ، إذا أخترت أن تمدنا بمعلوماتك الشخصية ، فأنت توافق على نقل وتخزين تلك المعلومات على السيرفر الخاص بنا             
                <br />
                قد نقوم بجمع وتخزين المعلومات الشخصية التالية:
                <br/>
                البريد الإلكتروني ، معلومات التواصل معكم.
                <br/>
                معلومات عن تعاملاتك إعتماداً على الأنشطة التي تقوم بها على الموقع.
                <br/>
                الشحن ، الدفع وغيرها من المعلومات التي تقدمها إلينا لشراء منتج أو شحنه.
                <br/>
                تسوية النزاعات ، المراسلات التي تتم عبر موقعنا ، المراسلات المرسلة إلينا.
                <br/>
                معلومات أخرى عن تعاملك مع مواقعنا ، الخدمات والمحتوى والإعلانات ، بما في ذلك جهاز الكمبيوتر و معلومات الإتصال ، إحصاءات عن عدد الصفحات التي تمت مشاهدتها ، حركة التنقل بين المواقع ، معلومات الإعلان ، عنوان IP ومعلومات التسجيل القياسية.
                <br/>
                معلومات إضافية قد تطلب منك لتقدمها للمصادقة الخاصة بك أو إذا مجدت مخالفات لسياسة الموقع ( مثلا : قد يطلب منك إرسال هويتك أو فاتورة للتحقق من عنوانك أو الإجابة عن أسئلة إضافية للتحقق من هويتك أو ملكيتك لمنتج قمت بإضافته إلى قائمتك ).
                <br/>
                بيانات من شركات أخرى ، مثل اليبانات الديموغرافية والملاحة.
                <br/>
                بيانات إضافية أخرى من طرف ثالث ( على سبيل المثال : إذا تكبدت بالديون لموقع Royalhousekw.com فسوف نقوم بالتحقق من الإئتمان عموماً " المراقبة الإئتمانية " عن طريق الحصول على معلومات عنك من مكتب الإئتمان ، بما هو مسموح به بالقانون ).
                <br/>
                لا يتم جمع المعلومات عن الأشخاص الذين يقل أعمارهم عن 13 عاماً.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>التسويق</h1>
                <br />
                <p>
                لا نقوم ببيع أو تأجير معلوماتك الشخصية لأي أطراف ثالثة لأغراض تسويقية دون موافقة صريحة منك ، وقد نجمع معلوماتك الشخصية مع المعلومات التي نحصل عليها من شركات أخرى لتخصيص وتحسين خدماتنا ، إذا كنت لا ترغب في الحصول على الإتصالات التسويقية أو الإعلانات الخاصة بنا يمكنك ببساطة الإشارة في الملف الشخصي الخاص بك على Royalhousekw.com  أو إتباع الإرشادات المتوفرة عن الإتصالات والإعلانات.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>الإستخدام</h1>
                <br />
                <p>
                هدفنا الأساسي في جمع المعلومات الشخصية هو أن نقدم لك تجربة مخصصة و سلسة وفعالة .
                <br/>
                أنت توافق أنه يمكننا إستخدام معلوماتك الشخصية في:
                <br/>
                إمداد خدمة العملاء الخاص بالشركة بطلبك.
                <br/>
                فض النزاعات ، جمع الرسوم ، كشف الخطاء وإصلاحها.
                <br/>
                منع أي أنشطة محظورة أو غير قانونية ، وتنفيذ بنود إتفاقية المستخدم.
                <br/>
                تخصيص وقياس وتحسين مستوى الخدمات والمحتوى والإعلانات التي نقدمها.
                <br/>
                إخبارك عن خدماتنا وخدمات الشركات التي نقوم بإدارتها ، التسويق الهادف ، تحديثات الخدمة ، العروض الترويجية المفضلة إعتماداً على إتصالاتك.
                <br/>
                مقارنة المعلومات والتأكد من دقتها والتحقق من ذلك مع أطراف ثالثة.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>الكشف عن معلوماتك</h1>
                <br />
                <p>
                قد نكشف المعلومات الشخصية للرد على متطلبات قانونية ، تنفيذ سياساتنا ، الرد على المزاعم بأن القوائم أو المحتويات الأخرى تنتهك حقوق الآخرين ، أو حماية أي شخص في مجال الحقوق والممتلكات والسلامة . و سيتم الكشف عن هذه المعلومات طبقاً للقوانين واللوائح المعمول بها.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>كما يجوز لنا مشاركة معلوماتك الشخصية مع:</h1>
                <br />
                <p>
                أفراد شركة Royalhousekw.com لتوفير محتوى وخدمات مشتركة ( مثل التسجيل ، المعاملات ، دعم العملاء ) للمساعدة على كشف ومنع الإعمال غير القانونية والإنتهاكات المحتملة لسياستنا ، وتوجيه القرارات المتعلقة بالمنتجات والخدمات والإتصالات ، وسوف يقوم أفراد الشركة بإرسال الإتصالات التسويقية فقط إذا كنت قد طلبت هذه الخدمات.
                <br/>
                مقدمي الخدمات بموجب عقد ممن يساعدون في العمليات التجارية الخاصة بنا ( مثل التحقيقات الغش ، جمع الفواتير ، برامج المكافئات والبطاقات الإئتمانية المشتركة ).
                <br/>
                أطراف ثالثة أخرى تسأل عن معلوماتك ( أو أطراف أبلغتك صراحة وأنت وافقت على التعامل معهم في إستخدام خدمة معينة ).
                <br/>
                تنفيذ القانون أو مساءلات حكومية أخرى ، في إستجابة لطلب تحقيق جنائي أو نشاط قضائي مزعوم، في هذه الحالة يتم الكشف عن معلوماتك للتحقيقاتمثل الإسم والمدينة والولاية والرمز البريدي ورقم الهاتف وعنوان البريد الإلكتروني ، التاريخ التعريفي للمستخدم ، عنوان IP ، شكاوى النصب ، تاريخ القوائم والمناقصات.
                <br/>
                الهيئات التجارية الأخرى ، التي نخطط للإندماج معها أو الحصول عليها، عند حدوث هذا الجمع سيتتطلب من الهيئة أو الشركة إتباع سياسة الخصوصية مع إحترام معلوماتك الشخصية ، كما انك سوف تتلقى إشعار مسبق إذا تم إستخدام معلوماتك الشخصية بعكس ما تنص عليه سياسة الخصوصية.
                <br/>
                دون تحديد مما سبق ، وفي محاولة منا لإحترام خصوصيتك وقدرتنا على إبقاء مجتمعنا خالياً من العناصر السيئة ، فلن نقوم بالكشف عن معلوماتك إلى منفذي القانون أو الجهات الحكومية أو أطراف ثالثة دون الحصول على مذكرة إستدعاء ، أمر قضائي أو إجراء قانوني مشابه ، إلا عندما نعتقد بحسن نية أن الكشف عن المعلومات ضروري لمنع أذى جسدي وشيك أو خسارة مالية أو للإبلاغ عن نشاط غير قانوني مشتبه به.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>المعلومات التي تشاركها على Royalhousekw.com</h1>
                <br />
                <p>
                يتم عرض اسم المستخدم الخاص بك في كل مكان بموقع Royalhousekw.com ومرتبط بجميع أنشطتك ، إذا تم الدخول إلى موقعنا من كمبيوتر مشترك أو من كمبيوتر بمقهى الإنترنت ، تظل بعض المعلومات عالقة ومرئية لمن يستخدم الكمبيوتر من بعدك مثل إسم المستخدم الخاص بك والأنشطة ورسائل التذكير.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>الكوكيز</h1>
                <br />
                <p>
                نحن نستخدم ملفات تعريف الإرتباط "الكوكيز" فى صفحات معينة من مواقعنا . "الكوكيز" هي ملفات صغيرة توضع على القرص الصلب يمكن أن تساعدنا في تحليل تدفق الصفحة الخاصة بنا على الويب ، تقديم خدمات مخصصة ، تقديم محتويات وإعلانات ، قياس تأثيرات العروض ، تعزيز الثقة والأمان.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>بعض المعلومات الهامة يجب معرفتها عن " الكوكيز " وهي:</h1>
                <br />
                <p>
                أننا نقدم بعض المزايا الحقيقية والتي تكون متوفرة فقط خلال إستخدام الكوكيز.
                <br />
                تساعدنا الكوكيز على معرفة المستخدم مع المحافظة على حالة الدخول.
                <br />
                معظم الكوكيز " ملفات دورية " أي أنها تزول أوتوماتيكياً من القرص الصلب في نهاية الدورة.
                <br />
                لديك الحرية في التخلص من ملفات تعريف الإرتباط لدينا إذا كان متصفحك يسمح بذلك ، على الرغم من ذلك قد تتداخل مع بعض المواقع والخدمات الخاصة بنا.
                <br />
                قد يصادف وجود ملفات إرتباط من طرف ثالث على صفحات ومواقع إخرى لا نتحكم بها ( على سبيل المثال : إذا تصفحت موقع لمستخدم آخر ، يمكنه وضع كوكيز على القرص الصلب لديك ).
                <br />
                لا رسائل بريد مزعجة ، لا برامج تجسس أو رسائل إحتيال.
                <br />
                لا نتسامح نحن ومستخدمينا مع البريد المزعج ، تأكد من إعداد التفضيلات الخاصة بالإتصالات على Royalhousekw.com لنتواصل معك بالطريقة التي ترغبها ، غير مرخص لك بإضافة مستخدمين آخرين لـ Royalhousekw.com ، حتى وإن إشترى منك هذا المستخدم إحدى المنتجات ( عبر البريد الإلكتروني أو المادي ) دون موافقة صريحة منه ، للإبلاغ عن بريد مزعج أو رسائل إحتيال برجاء مراسل الموقع على cs@royalhousekw.com. ، نحن نقوم بعمل مسح أوتوماتيكي للرسائل وتصفية يدوية أيضاً للبحث عن رسائل البريد المزعج ، الفيروسات ، هجمات تصيد المعلومات و الأنشطة الخبيثة الأخرى أو محتويات محظورة أو غير قانونية.
                </p>

                <br />

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>حماية حسابك الخاص</h1>
                <br />
                <p>
                كلمة السر هي المفتاح للدخول إلى حسابك الخاص ، قم بالإستخدام الفريد للأرقام والحروف والرموز الخاصة ، ولا تخبر أحداً عن كلمة السر الخاصة بك على موقع Royalhousekw.com، إذا قمت بمشاركة كلمة السر والمعلومات الشخصية مع آخرين فأنت المسؤول الوحيد عن التغيرات التي من الممكن حدوثها في حسلبك الشخصي ، إذا فقدت السيطرة على كلمة السر الخاصة بك، قد تفقد سيطرة كبيرة على معلوماتك الشخصية ويمكن أن تخضع لإجراءات ملزمة قانونا إتخذت نيابة عنك. لذلك، إذا تم اختراق كلمة المرور الخاصة بك لأي سبب من الأسباب، يجب أن تخطر فورا Royalhousekw.com  وتغير كلمة السر الخاصة بك.
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>الوصول ومراجعة وتغيير المعلومات الشخصية الخاصة بك</h1>
                <br />
                <p>
                يمكنك رؤية ومراجعة وتغيير معظم البيانات الشخصية بالدخول إلى موقع Royalhousekw.com، عموما، فإننا لن نقوم بالتعديل على المعلومات الشخصية الخاصة بك يدوياً لأنه من الصعب جداً التحقق من هويتك عن بعد ، يجب تحديث المعلومات الشخصية الخاصة بك على الفور إذا حدث بها تغييرات أو كانت غير دقيقة ، وإذا قمت بالإرسال إلى العامة فلن تتمكن من تغييره ، وبناءاً على طلبك ، سوف نقوم بإغلاق الحساب الخاص بك وإزالة معلوماتك الشخصية بأسرع وقت إعتماداً على نشاط حسابك وفقاً للقانون الواجب التطبيق . كما نقوم بالإحتفاظ بالمعلومات الشخصية للحسابات المغلقة للإمتثال للقانون ، منع الغش ، جمع أي رسوم مستحقة ، فض النزاعات ، إكتشاف الخطاء وإصلاحها ، المساعدة في أيه تحقيقات ، تطبيق إتفاقية المستخدم لـ Royalhousekw.com وإتخاذ إجراءات أخري يسمح بها القانون
                </p>

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>الأمن</h1>
                <br />
                <p>
                تخزن معلوماتك على السيرفرات الخاصة بنا ، ونحن نتعامل معها كأصول يجب حمايتها بإستخدام العديد من الدوات ( التشفير ، كلمة السر ، الحماية المادية ، ... ) لحماية معلوماتك الشخصية من الدخول غير المصرح به أو الكشف ، ولكن كما تعلمون ، هناك أطراف ثالثة دائماً ما تحاول الدخول إلى المعلومات والإتصالات الخاصة ، مع محاولة إستخدامها بصورة سيئة ، لذلك نعمل بجد لحماية الخصوصية ، كما لا يمكننا أن نعدك ، وانت يجب أن تتوقع ، أن معلوماتك الشخصية أو إتصالاتك الخاصة ستظل دائماً خاصة
                </p>    

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>المعلومات المالية</h1>
                <br />
                <p>
                نقوم بتطبيق مجموعة من الإجراءات الأمنية للمحافظة على سلامة معلوماتك الشخصية عند طلب منتج أو الوصول إلى المعلومات الشخصية الخاصة بك.
                <br/>
                نقوم في Royalhousekw.com بإستخدام سيرفر آمن ، حيث تنقل جميع الموارد الحساسة / المعلومات الإئتمانية عبر مأخذ توصيل آمن يتعامل بتكنولوجيا (SSL) ، بعدها يتم التشفير في قاعدة البيانات الخاصة بالمدفوعات لدينا لتكون في متناول الأشخاص المصرح لهم بذلك فقط مع حقوق خاصة للوصول إلى هذه النظم ، والتي يتطلب بها الحفاظ على سرية المعلومات.
                <br/>
                بعد تمام عملية التحويل ، لن يتم تخزين المعلومات الخاصة بكم ( كروت الإئتمان ، أرقام الضمان الإجتماعي ، البيانات المالية ، .... ) على السيرفرات الخاصة بنا.
                </p>                

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>الطرف الثالث</h1>
                <br />
                <p>
                بإستثناء ما يخالف سياسة الخصوصية ، فإن هذه الوثيقة لا تتناول سوى استخدام والكشف عن المعلومات التي نجمعها منك . فإذا قمت بكشف معلوماتك للآخرين قد تنطبق قواعد مختلفة على إستخدامهم أو الإفصاح عن المعلومات التي تكشف لهم ، لا تتحكم Royalhousekw.com في سياسة الخصوصية للطرف الثالث ، بل تنطبق عليك سياسة الخصوصية التي وضعها الطرف الثالث ، نحن نشجعكم على طرح أسئلة عديدة قبل الكشف عن معلوماتك الشخصية للآخرين.
                </p> 

                <br/>

                <h1 style={{fontSize:"20px", fontWeight:"900"}}>عام</h1>
                <br />
                <p>
                قد نقوم نتعيدل هذه السياسة في أي وقت عن طريق وضع الشروط المعدلة على هذا الموقع ، يؤخذ بالشروط المعدلة بعد 30 يوماً من نشرها على الموقع ، بالإضافة إلى ذلك ، فسوف نقوم بإخطاركم عبر مركز الرسائل الخاص بموقع Royalhousekw.com ، وإذا لم يتم الرد على أسئلتكم ، برجاء مراسلة البريد الإلكتروني التالي info@royalhousekw.com أو مراسلتنا على العنوان التالي:
                <br/>
                شركة رويال هاوس للتجارة العامة والمقاولات.
                <br/>
                الكويت - محافظة الفروانية - منطقة خيطان - قطعة 6
                <br/>
                مجمع سليمان الدبوس التجاري  - الدور الرابع - مكتب 3 
                </p> 


            </div>

        </div>
    )
}

export default TermsAndConditions;