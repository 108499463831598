import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { deleteAd } from "../../apiAdmin"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { listAds, listProductsByFilters } from "../../../apiCore";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { IMAGE_URL } from "../../../../config";
import { Link, useHistory } from "react-router-dom";


function AdsAdmin() {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [active, setActive] = useState(false);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState(false);

    const { user, token } = isAuthenticated();

    const loadAds = () => {
        listAds().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setData(res);
            }
        });
    }

    useEffect(() => {
        loadAds();
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        deleteAd(deletedItem.id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadAds();
                setDeletedItem({ ...deletedItem, success: true });
            }
        })
    }

    const handleChange = (name) => (event) => {
        setActive(!active);
    };

    const clickSubmit = () => {
        history.push(default_url + "admin/ads/add");
        //window.location.href = default_url + "admin/ads/add"
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_ad"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + " from ads?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد ازالة الاعلان " + deletedItem.name + " من قائمة الاعلانات؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAlert = () => {
        if (deletedItem.success) {
            return (
                <div
                    className={"alert alert-info alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{dict["product_has_been_deleted"]}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const showActivationSwitch = () => {
        return (
            <FormControlLabel
                value="start"
                control={
                    <Switch
                        checked={active}
                        onChange={handleChange('active')}
                        color="primary"
                        className="switch"
                    />
                }
                label={dict["activate"]}
                labelPlacement="start"
                className="dashboardSortingSwitch"
                style={{ float: default_url == "/en/" ? "right" : "left" }}
            />
        )
    }

    const allAds = () => {
        return (
            data.map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right",  marginTop: "20px"}}>{index + 1}</span>
                        <img src={IMAGE_URL + item.image} alt="ad image" style={{width: "150px", marginInlineStart: "20px"}}></img>
                        <Link className="dataContainerAction"
                            to="#"
                            onClick={handleShow}
                            name={item.image}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left", marginTop: "20px"}}
                        >{dict["delete"]}</Link>
                        {popUp()}
                    </div>
                )
            })
        )
    }

    const showEmpty = () => {
        return (
            <h1 style={{ textAlign: "start" }}>{dict["no_ads_found"]}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["ads_list"]}</h3>
                {showActivationSwitch()}
                {data.length > 0 ? allAds() : showEmpty()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_ad"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="8"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default AdsAdmin;