import React, { useState, useEffect } from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"
import { cities } from "../dictionary";
import { makeStyles } from '@material-ui/core/styles';
import { listDistributors } from "./apiCore";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { LocationCityRounded } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

function Distributors() {
    const classes = useStyles();
    const [distributors, setDistributors] = useState([]);
    const [distributorsToShow, setDistributorsToShow] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [city, setCity] = useState("");
    const [allAreas, setAllAreas] = useState([]);
    const [area, setArea] = useState("");

    const loadDistributors = () => {
        listDistributors().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                // let set = new Set();
                // for (let i = 0; i < res.length; i++) {
                //     if(default_url == '/en/'){
                //         set.add(res[i].enCity);
                //     }else{
                //         set.add(res[i].arCity);
                //     }
                // }
                // let arr = Array.from(set);
                // setAllCities(arr);
                let set2 = new Set();
                for (let i = 0; i < res.length; i++) {
                    if(default_url == '/en/'){
                        set2.add(res[i].enArea);
                    }else{
                        set2.add(res[i].arArea);
                    }
                }
                setAllAreas(Array.from(set2));
                setDistributors(res);
            }
        });
    }

    useEffect(() => {
        loadDistributors();
    }, [])

    const handleChange = name => event => {
        if(name == 'city'){
            setCity(event.target.value);
            setArea("");
            setDistributorsToShow([]);
            let set = new Set();
            for (let i = 0; i < distributors.length; i++) {
                if(default_url == '/en/'){
                    if(distributors[i].enCity == allCities[event.target.value]){
                        set.add(distributors[i].enArea);
                    }
                }else{
                    if(distributors[i].arCity == allCities[event.target.value]){
                        set.add(distributors[i].arArea);
                    }
                }
            }
            setAllAreas(Array.from(set));
        }else if(name == 'area'){
            if(event.target.value == ""){
                setDistributorsToShow([]);
                setArea(event.target.value);
                return;
            }
            setArea(event.target.value);
            let arr = [];
            for (let i = 0; i < distributors.length; i++) {
                if(distributors[i].arArea == allAreas[event.target.value] || distributors[i].enArea == allAreas[event.target.value]){
                    arr.push(distributors[i])
                }
            }
            setDistributorsToShow(arr);
        }
    };

    const clickSubmit = event => {

    };

    const citySelector = () => {
        return (
            <div style={{ textAlign: "center" }}>
                {/* <select style={{ width: "35%", marginInlineEnd: "20px", marginTop: '10px', marginBottom: '10px' }} onChange={handleChange("city")} name="hasSubcategory" className="inputField form-control" value={city}>
                    <option value="">اختر المحافظة</option>
                    {
                       allCities.length > 0 && allCities.map((d, i) => {
                            return <option value={i + ""}>{d}</option>
                        })
                    }
                </select> */}
                <select style={{ width: "35%", marginTop: '10px', marginBottom: '10px' }} onChange={handleChange("area")} name="hasSubcategory" className="inputField form-control" value={area}>
                    <option value="">اختر المنطقة</option>
                    {
                        allAreas.length > 0 && allAreas.map((d, i) => {
                            return <option value={i + ""}>{d}</option>
                        })
                    }
                </select>

            </div>

        )
    }

    return (
        <div className="container-fluid footerTagsContainer" style={{ padding: '20px 0 0' }} dir={default_url === "/en/" ? "ltr" : "rtl"} >
            <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center', marginBottom: "20px", }}>{dict['find_a_store']}</h1>
            <h1 style={{ color: 'black', fontSize: "14px", fontWeight: "500", textAlign: 'center', marginBottom: "5px" }}>- تبديل واسترجاع مشتريات المتاجر المعتمدة يكون عن طريق المتجر المعتمد نفسه</h1>
            <h1 style={{ color: 'black', fontSize: "14px", fontWeight: "500", textAlign: 'center', marginBottom: "5px" }}>- توفر المنتجات لدى المتاجر المعتمدة يختلف بحسب كل متجر ، وقد لا تتوفر بعض الأصناف</h1>
            <h1 style={{ color: 'black', fontSize: "14px", fontWeight: "500", textAlign: 'center', marginBottom: "5px" }}>- العروض حصرياً عبر المتجر الالكتروني</h1>
            <h1 style={{ color: 'black', fontSize: "14px", fontWeight: "500", textAlign: 'center', marginBottom: "25px" }}>- تبديل واسترجاع طلبات المتجر الالكتروني عن طريق خدمة العملاء فقط</h1>
            <h1 style={{ color: 'gray', fontSize: "16px", fontWeight: "500", textAlign: 'center', marginBottom: "20px" }}>{dict['please_select_an_area_to_locate_a_store']}</h1>
            
            <div className="footerTagsTextContainer" style={{ padding: '5px', marginBottom: "20px" }}>
                {citySelector()}
            </div>
            <div className={classes.root}>
                <Grid container spacing={3} style={{width: "100%"}}>
                    {
                        distributorsToShow.length > 0 && distributorsToShow.map((item, index) => {
                            return(
                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.paper}>
                                        <div dir='ltr' style={{ color: 'red', padding: '10px 0' }}>
                                            {item.name + " - " + item.arArea}
                                        </div>
                                        <iframe src={item.location} width="80%" height="100%" style={{ borderRadius: '10px' }} allowfullscreen="" loading="lazy"></iframe>
                                    </Paper>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>

        </div>
    )
}

export default Distributors;