import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { downloadExportedOrders, exportOrders } from "../../apiAdmin"
import { store } from 'react-notifications-component';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: '25ch'
        },
    },
}));

function ExportOrders() {
    const classes = useStyles();
    const [fromOrderNumber, setFromOrderNumber] = useState();
    const [toOrderNumber, setToOrderNumber] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();

    const validateFrom = (event) => {
        const value = event.target.value;
        const setValue = value >= 0 ? value : fromOrderNumber;
        setFromOrderNumber(setValue);
    }

    const validateTo = (event) => {
        let value = event.target.value;
        let setValue = value >= 0 ? value : fromOrderNumber;
        setToOrderNumber(setValue);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let min = 0
        let max = 999999999
        if(fromOrderNumber){
            min = fromOrderNumber;
        }
        if(toOrderNumber){
           if(fromOrderNumber && toOrderNumber < fromOrderNumber){
               max = fromOrderNumber
           }else{
               max = toOrderNumber
           }
        }
        exportOrders(min, max, user._id, token).then(res => {
            if (res.error) {
                setError(res.error);
                showError();
            } else {
                downloadExportedOrders(user._id, token).then(res2 => {
                    showAlert();
                    setLoading(false);
                })
                
            }
        })
    }

    const showAlert = () => {
        store.addNotification({
            message: dict['orders_have_been_exported'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showError = () => {
        store.addNotification({
            message: error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const ordersNumberRange = () => {
        return (
            <div style={{textAlign: "start"}}>
                <form className={classes.root} noValidate autoComplete="off" >
                    <TextField
                        id="outlined-number"
                        label={dict["from_order_number"]}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={validateFrom}
                        value={fromOrderNumber}
                    />
                    <TextField
                        id="outlined-number"
                        label={dict["to_order_number"]}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        onChange={validateTo}
                        value={toOrderNumber}
                    />
                </form>

            </div>

        )
    }

    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                <h3 className="formContainerTitle">{dict["export_orders"]}</h3>
                <hr />
                {ordersNumberRange()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["export"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="5"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default ExportOrders;