import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createBlog } from "../../apiAdmin"
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Link } from "react-router-dom";

function AddNewBlog() {
    const [values, setValues] = useState({
        title: "",
        body: "",
        image: "",
        link: "",
        linkTitle: "",
        error: "",
        success: false,
    });

    const { user, token } = isAuthenticated();
    const { title, body, image, link, linkTitle, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let blog = { title: title, body: body, image: image }
        createBlog(blog, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ title: "", body: "", image: "", link: "", linkTitle: "", error: null, success: true });
            }
        })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['added_to_blog._add_more_or']} <Link to={default_url + "admin/blog"} style={{ fontSize: "18px" }}>{dict["go_back_to_blog"]}</Link>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_to_blog"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                <div>
                    <TextField
                        id="outlined-textarea"
                        placeholder={dict["image"]}
                        label={dict["image"]}
                        InputLabelProps={{ style: { left: "auto", fontSize: '16px' } }}
                        value={image}
                        style={{
                            width: "100%",
                            direction: default_url == "/en/" ? 'ltr' : "rtl",
                            margin: "5px 0 10px",
                            borderRadius: '0.3rem',
                            paddingInlineStart: '10px',
                            padding: '.375rem .75rem',
                            fontSize: '12px'
                        }}
                        onChange={handleChange("image")}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-textarea"
                        placeholder={dict["title"]}
                        label={dict["title"]}
                        InputLabelProps={{ style: { left: "auto", fontSize: '16px' } }}
                        value={title}
                        style={{
                            width: "100%",
                            direction: default_url == "/en/" ? 'ltr' : "rtl",
                            margin: "5px 0 10px",
                            borderRadius: '0.3rem',
                            paddingInlineStart: '10px',
                            padding: '.375rem .75rem',
                            fontSize: '12px'
                        }}
                        onChange={handleChange("title")}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-textarea"
                        placeholder={dict["body"]}
                        label={dict["body"]}
                        InputLabelProps={{ style: { left: "auto", fontSize: '16px' } }}
                        value={body}
                        multiline
                        style={{
                            width: "100%",
                            direction: default_url == "/en/" ? 'ltr' : "rtl",
                            margin: "5px 0 10px",
                            borderRadius: '0.3rem',
                            paddingInlineStart: '10px',
                            padding: '.375rem .75rem',
                            fontSize: '12px'
                        }}
                        onChange={handleChange("body")}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-textarea"
                        placeholder={dict["link_title"]}
                        label={dict["link_title"]}
                        InputLabelProps={{ style: { left: "auto", fontSize: '16px' } }}
                        value={linkTitle}
                        style={{
                            width: "100%",
                            direction: default_url == "/en/" ? 'ltr' : "rtl",
                            margin: "5px 0 10px",
                            borderRadius: '0.3rem',
                            paddingInlineStart: '10px',
                            padding: '.375rem .75rem',
                            fontSize: '12px'
                        }}
                        onChange={handleChange("linkTitle")}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-textarea"
                        placeholder={dict["link"]}
                        label={dict["link"]}
                        InputLabelProps={{ style: { left: "auto", fontSize: '16px' } }}
                        value={link}
                        style={{
                            width: "100%",
                            direction: default_url == "/en/" ? 'ltr' : "rtl",
                            margin: "5px 0 10px",
                            borderRadius: '0.3rem',
                            paddingInlineStart: '10px',
                            padding: '.375rem .75rem',
                            fontSize: '12px'
                        }}
                        onChange={handleChange("link")}
                    />
                </div>
                </Box>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="16"
            data={form}
        />
    )
}

export default AddNewBlog;