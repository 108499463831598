import React, { useState } from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px'
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

function ContactUs() {
    const classes = useStyles();

    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "ltr" : "rtl"} >
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center' }}>{dict['contact_us']}</h1>
                <br />
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <div className={classes.btnRoot}>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'none',
                                        }}
                                        href="https://api.whatsapp.com/send?phone=96560684250"
                                    >
                                    <WhatsAppIcon style={{marginBottom: '10px', fontSize: "38px", color: 'green'}} />
                                    <br/>
                                    <span style={{color: "green", fontSize: '18px'}}>{dict['customer_service']}</span>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <div className={classes.btnRoot}>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'none',
                                        }}
                                        href="https://www.instagram.com/rosethermos.kwt/"
                                    >
                                    <InstagramIcon style={{marginBottom: '10px', fontSize: "38px", color: '#D82C7D'}} />
                                    <br/>
                                    <span style={{color: "#D82C7D", fontSize: '18px'}}>{dict['rose_thermos']}</span>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <div className={classes.btnRoot}>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'none',
                                        }}
                                        href="https://www.instagram.com/royalhouse.kwt/"
                                    >
                                    <InstagramIcon style={{marginBottom: '10px', fontSize: "38px", color: '#D82C7D'}} />
                                    <br/>
                                    <span style={{color: "#D82C7D", fontSize: '18px'}}>{dict['royal_house_co']}</span>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <div className={classes.btnRoot}>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'none',
                                        }}
                                        href="https://www.youtube.com/channel/UCepKVtZV11YRzWa76MepnFg"
                                    >
                                    <YouTubeIcon style={{marginBottom: '10px', fontSize: "38px", color: '#FF0100'}} />
                                    <br/>
                                    <span style={{color: "#FF0100", fontSize: '18px'}}>{dict['youtube_channel']}</span>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <div className={classes.btnRoot}>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'none',
                                        }}
                                        href="https://www.facebook.com/rosethermos.kwt"
                                    >
                                    <FacebookIcon style={{marginBottom: '10px', fontSize: "38px", color: '#0C8AF0'}} />
                                    <br/>
                                    <span style={{color: "#0C8AF0", fontSize: '18px'}}>{dict['rose_thermos']}</span>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper className={classes.paper}>
                                <div className={classes.btnRoot}>
                                    <Button variant="contained"
                                        style={{
                                            backgroundColor: 'white',
                                            width: '100%',
                                            height: '100%',
                                            boxShadow: 'none',
                                        }}
                                        href="https://www.facebook.com/RHouseQ8"
                                    >
                                    <FacebookIcon style={{marginBottom: '10px', fontSize: "38px", color: '#0C8AF0'}} />
                                    <br/>
                                    <span style={{color: "#0C8AF0", fontSize: '18px'}}>{dict['royal_house_co']}</span>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;