import { updateUserCart, listProductsByFilters, getQuantityFromProducts, getQuantityFromPenddingOrders, getGiftByAmount, addToCart } from "./apiCore";
import { isAuthenticated } from "../auth";
import GiftsAdmin from "./admin/admin dashboard components/gifts/GiftsAdmin";
import { useCookies } from 'react-cookie';
import { sha256 } from 'js-sha256';

const updateCartOnDB = (cart) => {
    const { user, token } = isAuthenticated();
    updateUserCart(user._id, token, { cart: cart });
}

const checkFreeGift = (amount) => {
    getGiftByAmount(amount).then(res => {
        if (res.error) {

        } else {
            console.log(res);
            if (res.name) {
                let done = false;
                let cart = JSON.parse(localStorage.getItem("cart"));
                cart.map((item, i) => {
                    if (item.gift) {
                        item.gift = res.name
                        done = true
                    }
                });
                if (!done) {
                    cart.push({
                        gift: res.name
                    });
                }
                localStorage.setItem('cart', JSON.stringify(cart));
            } else {
                let cart = JSON.parse(localStorage.getItem("cart"));
                cart.map((item, i) => {
                    if (item.gift) {
                        cart.splice(i, 1);
                    }
                });
                localStorage.setItem('cart', JSON.stringify(cart));
            }
        }
    })
}

export const getItemQuantity = (id) => {
    let ret;
    return getQuantityFromProducts(id).then(res1 => {
        if (res1.error) {
            console.log(res1.error);
        } else {
            return getQuantityFromPenddingOrders(id).then(res2 => {

                if (res2.error) {
                    ret = parseInt(res1);
                    return ret;
                }
                ret = parseInt(res1 - res2);
                return ret;
            });
        }

    });
}

export const addItem = async (itemId, count, link, price, fbp = null, fbc = null, next = () => { }) => {
    
    let cart = [];
    if (typeof window !== "undefined" && count > 0) {
        if (localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem("cart"));
        }
        if (cart.length > 0) {
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].itemId == itemId) {
                    count += cart[i].count;
                    cart.splice(i, 1);
                }
            }
            localStorage.setItem('cart', JSON.stringify(cart));
        }
        await getItemQuantity(itemId).then(limit => {
            if (limit > 0) {
                count = Math.min(Math.max(count, 1), limit);
                cart.push({
                    itemId: itemId,
                    count,
                    link
                });
                localStorage.setItem('cart', JSON.stringify(cart));
                let user = {}
                if (isAuthenticated()) {
                    user = isAuthenticated().user;
                    window.ttq.identify({
                        "email": sha256(user.email), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                        "phone_number": user.mobile ? sha256(user.mobile) : "",
                        "external_id": sha256(user._id) // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
                    });
                }
                window.ttq.track('AddToCart', {
                    "value": price, // number. Value of the order or items sold. Example: 100.
                    "currency": "KWD" // string. The 4217 currency code. Example: "USD".
                });
                addToCart({ ...user, itemId: itemId, itemCount: count, fbp: fbp ? fbp : null, fbc: fbc ? fbc : null }).then(res => {
                    if (res.error) {
                        console.log(res.error);
                    }
                })
            }
            if (isAuthenticated()) {
                updateCartOnDB(cart)
            }
            next();
        });
    }
}

export const getCartFromDB = (next) => {
    const { user } = isAuthenticated();
    if (user.cart) {
        let userCart = user.cart;
        let products = [];
        for (let i = 0; i < userCart.length; i++) {
            products.push(userCart[i].itemId);
        }
        let filters = { _id: products };
        listProductsByFilters(0, 1000000, filters).then(p => {
            if (p.error) {

            } else {
                let items = [];
                p.data.map((item, index) => {
                    for (let i = 0; i < userCart.length; i++) {
                        if (userCart[i].itemId === item._id && item.quantity > 0 && !item.hidden) {
                            addItem(item._id, Math.min(Math.max(userCart[i].count, 1), item.quantity), userCart[i].link);
                            break;
                        }
                    }
                });
                next();
            }
        });
    }
}

export const itemTotal = () => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem('cart')) {
            let ret = 0;
            let cart = JSON.parse(localStorage.getItem('cart'));
            cart.map(p => {
                ret += Math.max(p.count, 1);
            })
            return ret;
        }
    }
    return 0;
}

export const getCart = () => {
    let crt = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('cart')) {
            let cart = JSON.parse(localStorage.getItem('cart'));
            let ids = [];
            cart.map((p, i) => {
                if (p.count >= 1) {
                    ids.push(p.itemId);
                }
            })
            crt = listProductsByFilters(0, 100000, { _id: ids }).then((res) => {
                if (!res || res.error || res.size === 0) {
                    return []
                }
                let arr = res.data;
                let ret = [];
                let newCart = [];
                for (let i = 0; i < cart.length; i++) {
                    let cur = cart[i];
                    for (let j = 0; j < arr.length; j++) {
                        if (!arr[j].hidden && arr[j].quantity > 0 && cur.itemId == arr[j]._id) {
                            newCart.push({ ...cart[i], count: Math.min(Math.max(cur.count, 1), arr[j].quantity) });
                            ret.push({ ...cur, ...arr[j], count: Math.min(Math.max(cur.count, 1), arr[j].quantity) });
                            break;
                        }
                    }
                }
                localStorage.setItem('cart', JSON.stringify(newCart));
                return ret;
            });
            return crt
        }
    }
    return [];
}

export const updateItem = async (productId, count) => {
    let cart = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'));
        }
        for (let i = 0; i < cart.length; i++) {
            if (cart[i].itemId == productId) {
                await getItemQuantity(productId).then(limit => {
                    if (limit > 0) {
                        let newCount = Math.min(Math.max(parseInt(count), 1), limit);
                        let oldCount = cart[i].count;
                        cart[i].count = newCount;
                        localStorage.setItem('cart', JSON.stringify(cart));
                        if (newCount > oldCount) {

                        }
                    } else {
                        cart.splice(i, 1);
                        if (typeof window !== "undefined") {
                            let freeItems = [];
                            if (localStorage.getItem('freeItems')) {
                                freeItems = JSON.parse(localStorage.getItem('freeItems'));
                            }
                            let newArray = freeItems.filter((item) => { return item.parent !== productId });
                            localStorage.setItem('freeItems', JSON.stringify(newArray));
                        }
                    }
                });
            }
        }
        if (isAuthenticated()) {
            updateCartOnDB(cart)
        }
        return cart;
    }
}

export const removeItem = (productId) => {
    let cart = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'));
        }
        cart.map((p, i) => {
            if (p.itemId == productId) {
                cart.splice(i, 1);
            }
        })
        localStorage.setItem('cart', JSON.stringify(cart));
        if (isAuthenticated()) {
            updateCartOnDB(cart)
        }
    }
    let freeItems = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('freeItems')) {
            freeItems = JSON.parse(localStorage.getItem('freeItems'));
        }
        let newArray = freeItems.filter((item) => { return item.parent !== productId });
        localStorage.setItem('freeItems', JSON.stringify(newArray));
    }
    return cart;
}

export const clearCart = (next = () => { }) => {
    if (typeof window !== 'undefined' && localStorage.getItem('cart')) {
        localStorage.removeItem('cart');
    }
    if (typeof window !== 'undefined' && localStorage.getItem('freeItems')) {
        localStorage.removeItem('freeItems');
    }
    if (isAuthenticated()) {
        updateCartOnDB([])
    }
    next();
}

export const addFreeItem = (newItems) => {
    let freeItems = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('freeItems')) {
            freeItems = JSON.parse(localStorage.getItem("freeItems"));
        }
        newItems.map((item) => {
            freeItems.push({ ...item });
        })
        localStorage.setItem('freeItems', JSON.stringify(freeItems));
    }
}

export const productHasFreeItem = (productId) => {
    let freeItems = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('freeItems')) {
            freeItems = JSON.parse(localStorage.getItem("freeItems"));
        }
        let found = false;
        freeItems.map((item) => {
            if (item.parent == productId) {
                found = true;
            }
        })
        return found;
    }
}

export const removeFreeItemsByProduct = (productId) => {
    let freeItems = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('freeItems')) {
            freeItems = JSON.parse(localStorage.getItem('freeItems'));
        }
        let newArray = freeItems.filter((item) => { return item.parent !== productId });
        localStorage.setItem('freeItems', JSON.stringify(newArray));
    }
}

export const getFreeItemsIds = () => {
    let freeItems = [];
    let ids = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('freeItems')) {
            freeItems = JSON.parse(localStorage.getItem('freeItems'));
        }
        for (var i = 0; i < freeItems.length; i++) {
            ids.push(freeItems[i]._id);
        }

    }
    return ids;
}

export const getFreeItems = () => {
    let freeItems = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('freeItems')) {
            freeItems = JSON.parse(localStorage.getItem('freeItems'));
        }
    }
    return freeItems;
}