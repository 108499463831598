import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LanguageIcon from '@material-ui/icons/Language';
import { itemTotal } from "../cartHelpers";
import { numOfWishlistItems } from "../wishlistHelpers";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(2),
      },
    },
  }));

function MobileMainBarItem(props) {
    const [totalCartItem, setTotalCartItem] = useState();
    const [totalWishlistItem, setTotalWishlistItem] = useState();

    useEffect(() => {
        setTotalCartItem(itemTotal());
        setTotalWishlistItem(numOfWishlistItems());
    }, []);

    const defaultProps = {
        color: 'secondary',
        children: props.type == "login" ? <AccountBoxIcon style={{fontSize: "37px"}}/> : props.type == "language" ? <FavoriteIcon  style={{fontSize: "37px"}} /> : props.type == "cart" ? <ShoppingCartIcon style={{fontSize: "37px"}} /> : <MonetizationOnIcon style={{fontSize: "37px"}} />
      };
    const classes = useStyles();

    return (
        <div className="col-2  text-center align-self-center mobileOnly" style={props.type === "login" ? {padding: "0"} : {padding:"0"}}>
            <div className="cart-in-mobile">
                <Link className="link-icon-in-mobile" to={props.link}>
                {
                    props.type == "login" && <Badge {...defaultProps} />
                }
                {
                    props.type == "language" && <span>English</span>
                }
                {/* {
                    props.type == "wishlist" && <Badge {...defaultProps} badgeContent={totalWishlistItem} style={{marginInlineEnd: "5px"}} />
                } */}
                {
                    props.type == "cart" && <Badge {...defaultProps} badgeContent={totalCartItem} />
                }

                {
                    props.type == "points" && <Badge {...defaultProps} />
                }
                    <span>{props.details}</span>
                </Link>
            </div>
        </div>
    )
}

export default MobileMainBarItem;

// style={{marginInlineEnd: "5px"}}