import React, { useState, useEffect } from "react";
import SizeFilterBox from "./SizeFilterBox"
import ColorFilterBox from "./ColorFilterBox"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { useParams } from "react-router-dom";
import { dict } from "../../../dictionary";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles2 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));


const useStyles = makeStyles((theme) => ({
    root: {
        width: 300,
    },
}));

function valuetext(value) {
    return `${value}`;
}

function ProductFilter(props) {
    let params = new URLSearchParams(window.location.search);
    let price = params.get('price');
    let size = params.get('size');
    let color = params.get('color');
    const classes = useStyles();
    const classes2 = useStyles2();
    const [value, setValue] = useState([0, 100]);
    const [filtered, setFiltered] = useState([]);
    const [colorsFiltered, setColorsFiltered] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100);
    const [priceFliters, setPriceFilters] = useState([0, 100])

    const filteredSizes = (sizes) => {
        let arr = [...filtered];
        for (let i = 0; i < sizes.length; i++) {
            if (arr.indexOf(sizes[i]) >= 0) {
                arr.splice(arr.indexOf(sizes[i]), 1);
            } else {
                arr.push(sizes[i]);
            }
        }
        // if (arr.indexOf(size) >= 0) {
        //     arr.splice(arr.indexOf(size), 1);
        // } else {
        //     arr.push(size);
        // }
        setFiltered(arr);
        let search = props.location.search.split("&");
        let res = "";
        if (search[0] == "") {
            res += ("?size=" + arr);
        } else {
            let done = false;
            for (let i = 0; i < search.length; i++) {
                if (search[i].includes("size=")) {
                    if (i === 0) {
                        res += "?"
                    }
                    res += ("size=" + arr + "&");
                    done = true;
                } else {
                    if (!search[i].includes('page=')) {
                        res += search[i] + "&";
                    } else if (search[i].includes("?page=")) {
                        res += "?";
                    }
                }
            }
            if (!done) {
                res += ("size=" + arr + "&");
            }
            res = res.substring(0, res.length - 1);
        }
        window.location.href = (res)
    }

    const filteredColors = (color) => {
        let arr = [...colorsFiltered];
        // for (let i = 0; i < colors.length; i++) {
        //     if (arr.indexOf(colors[i]) >= 0) {
        //         arr.splice(arr.indexOf(colors[i]), 1);
        //     } else {
        //         arr.push(colors[i]);
        //     }
        // }
        if (arr.indexOf(color) >= 0) {
            arr.splice(arr.indexOf(color), 1);
        } else {
            arr.push(color);
        }
        setColorsFiltered(arr);
        let search = props.location.search.split("&");
        let res = "";
        if (search[0] == "") {
            res += ("?color=" + arr);
        } else {
            let done = false;
            for (let i = 0; i < search.length; i++) {
                if (search[i].includes("color=")) {
                    if (i === 0) {
                        res += "?"
                    }
                    res += ("color=" + arr + "&");
                    done = true;
                } else {
                    if (!search[i].includes('page=')) {
                        res += search[i] + "&";
                    } else if (search[i].includes("?page=")) {
                        res += "?";
                    }
                }
            }
            if (!done) {
                res += ("color=" + arr + "&");
            }
            res = res.substring(0, res.length - 1);
        }
        window.location.href = (res)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
    };

    const handleTextChange = name => (event) => {
        let tmp = parseInt(event.target.value)
        if (name == 'max') {
            setMaxPrice(tmp);
            let newValue = value;
            newValue[1] = tmp;
            setValue(newValue);
        } else if (name == 'min') {
            setMinPrice(tmp);
            let newValue = value;
            newValue[0] = tmp;
            setValue(newValue);
        }
    };

    const handleMouseUp = () => {
        if (priceFliters.length == 0 || priceFliters[0] != minPrice || priceFliters[1] != maxPrice) {
            setPriceFilters([minPrice, maxPrice]);
            let search = props.location.search.split("&");
            let res = "";
            if (search[0] == "") {
                res += ("?price=" + minPrice + "-" + maxPrice);
            } else {
                let done = false;
                for (let i = 0; i < search.length; i++) {
                    if (search[i].includes("price=")) {
                        if (i === 0) {
                            res += "?"
                        }
                        res += ("price=" + minPrice + "-" + maxPrice + "&");
                        done = true;
                    } else {
                        if (!search[i].includes('page=')) {
                            res += search[i] + "&";
                        } else if (search[i].includes("?page=")) {
                            res += "?";
                        }
                    }
                }
                if (!done) {
                    res += ("price=" + minPrice + "-" + maxPrice + "&");
                }
                res = res.substring(0, res.length - 1);
            }
            window.location.href = (res)
        }
    }
    const handleFouceOut = () => {
        if (priceFliters.length == 0 || priceFliters[0] != minPrice || priceFliters[1] != maxPrice) {
            setPriceFilters([minPrice, maxPrice]);
            let search = props.location.search.split("&");
            let res = "";
            if (search[0] == "") {
                res += ("?price=" + minPrice + "-" + maxPrice);
            } else {
                let done = false;
                for (let i = 0; i < search.length; i++) {
                    if (search[i].includes("price=")) {
                        if (i === 0) {
                            res += "?"
                        }
                        res += ("price=" + minPrice + "-" + maxPrice + "&");
                        done = true;
                    } else {
                        res += search[i] + "&";
                    }
                }
                if (!done) {
                    res += ("price=" + minPrice + "-" + maxPrice + "&");
                }


                res = res.substring(0, res.length - 1);
            }
            window.location.href = (res)
        }
    }
    useEffect(() => {
        if (price) {
            let prices = price.split("-");
            let prices2 = [parseInt(prices[0]), parseInt(prices[1])]
            setValue([...prices2]);
            setMinPrice(prices2[0]);
            setMaxPrice(prices2[1]);
            setPriceFilters([...prices2]);
        }
        if (size) {
            let sizes = size.split(",");
            setFiltered(sizes);
        }
        if (color) {
            let colors = color.split(",");
            setColorsFiltered(colors);
        }
    }, [])
    return (
        <div className="filterContainer col-2" style={{ position: "sticky", top: 0, display: "inline-block", verticalAlign: "top", marginBottom: "40px" }} >

            {
                props.sizes && props.sizes.length > 0 &&
                <div>
                    <h5 className="filterTitle" style={{ textAlign: "start" }}>{dict["size"]}</h5>
                    <form>
                        {props.sizes.map((elem, index) => {
                            return (
                                <SizeFilterBox
                                    key={index}
                                    size={elem}
                                    filteredSizes={size => {
                                        filteredSizes(size);
                                    }}
                                />
                            )
                        })}
                    </form>
                </div>
            }
            {props.sizes && props.sizes.length > 0 && <div className="breakLineFilter"></div>}

            {
                props.colors && props.colors.length > 0 &&
                <div>
                    <h5 className="filterTitle" style={{ textAlign: "start" }}>{dict["colors"]}</h5>
                    <form>
                        <div className={classes2.root}>
                            <Grid container spacing={3}>
                                {props.colors.map((elem, index) => {

                                    return (
                                        <Grid item xs={6}>
                                            <Paper className={classes2.paper}>
                                                <ColorFilterBox
                                                    key={index}
                                                    color={elem}
                                                    filteredColors={color => {
                                                        filteredColors(color);
                                                    }}
                                                />
                                            </Paper>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>

                    </form>
                </div>
            }
            {props.colors && props.colors.length > 0 && <div className="breakLineFilter"></div>}

            <div>
                <Typography id="range-slider" gutterBottom style={{ textAlign: "start" }}>
                    <div>
                        <h5 className="filterTitle" style={{ textAlign: "start" }}>{dict["price"]}</h5>
                    </div>

                </Typography>
                <div className="row">
                    <div className="col-6">
                        <TextField
                            id="outlined-number"
                            label={dict["min"]}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={minPrice}
                            onChange={handleTextChange("min")}
                            onBlur={handleFouceOut}
                        />
                    </div>
                    <div className="col-6">
                        <TextField
                            id="outlined-number"
                            label={dict["max"]}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={maxPrice}
                            onChange={handleTextChange("max")}
                            onBlur={handleFouceOut}
                        />
                    </div>
                </div>

                <div className={classes.root} style={{ width: "90%" }} >
                    <Slider
                        value={value}
                        min={0}
                        max={100}
                        onChange={handleChange}
                        onMouseUp={handleMouseUp}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                        style={{
                            color: "#E4BC4B",
                            marginInlineStart: "5%"
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProductFilter;