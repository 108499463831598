import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { Redirect } from "react-router-dom";
import { getGiftItem, listGiftTypes, updateGiftItem } from "../../apiAdmin"
import * as qs from 'query-string';
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";

function UpdateGiftItem() {

    const [values, setValues] = useState({
        _id: "",
        title: "",
        code: "",
        image: "",
        giftType: "",
        error: "",
        redirectToReferrer: false,
    });
    const [giftTypes, setGiftTypes] = useState([]);

    const { user, token } = isAuthenticated();
    const { _id, title, code, image, giftType, error, redirectToReferrer } = values;
    const parsed = qs.parse(window.location.search);

    useEffect(() => {
        async function fetchData() {
            await getGiftItem(parsed.gift_item_id, user._id, token)
                .then(res => {
                    if (res.error) {
                        setValues({ ...values, error: res.error, redirectToReferrer: true });
                    } else {
                        setValues({ _id: res._id, title: res.title, code: res.code, image: res.image, giftType: res.giftType });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            await listGiftTypes(user._id, token)
                .then(res => {
                    setGiftTypes(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        updateGiftItem({ _id, title, code, image, giftType }, user._id, token)
            .then(data => {
                if (data.error) {
                    console.log(data.error);

                    setValues({ ...values, error: data.error, redirectToReferrer: false });
                } else {
                    setValues({
                        ...values,
                        error: undefined,
                        redirectToReferrer: true
                    });
                }
            })
    }

    const giftTypeSelector = () => {
        return (
            <select onChange={handleChange("giftType")} className="inputField form-control" value={giftType}>
                <option value={null}></option>
                {
                    giftTypes.sort((a, b) => a.value - b.value).map((t, index) => {
                        return (
                            <option key={index} value={t._id}>{t.name}</option>
                        )
                    })

                }
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/gift-items', state: { prevPath: "edit", error } }} />
            )
        }
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["edit_gift"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["title"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("title")} type="text" value={title} className="inputField form-control" id="name" placeholder={dict["title"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["code"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("code")} type="text" value={code} className="inputField form-control" id="name" placeholder={dict["code"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("image")} type="text" value={image} className="inputField form-control" id="name" placeholder={dict["image"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["gift_type"]} <span className="redStar">*</span></label><br />
                    {giftTypeSelector()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="333"
            data={form}
        />
    )
}

export default UpdateGiftItem;