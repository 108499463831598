import React, {useState, useEffect} from "react";
import { isAuthenticated } from "../../../../auth"
import { deleteGiftItem } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import * as qs from 'query-string';
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";

function DeleteGiftItem(props) {
    const [state, setState] = useState({
        error: "",
        redirect: false
    })
    const { user, token } = isAuthenticated();
    const parsed = qs.parse(window.location.search);
    useEffect(() => {
        async function fetchData() {
            if (parsed.gift_item_id) {
              await deleteGiftItem(parsed.gift_item_id, user._id, token)
                    .then(data => {
                        if (data.error) {        
                            setState({error: data.error, redirect: true})
                        }else{
                            setState({error: undefined, redirect: true})
                        }
                    })
            } else {
                setState({error: dict["no_gift_is_found"], redirect: true})
            }
        }
        fetchData();
    }, []);

    return (
        <div>
            {state.redirect && <Redirect to={{ pathname: default_url +  'admin/gift-items', state: { prevPath: "delete", error: state.error } }} />}
        </div>
    )

}

export default DeleteGiftItem;