import React, { useState } from "react";
import { default_url } from "../../index"
import { IMAGE_URL } from "../../config"
import { Link } from "react-router-dom";

function CategoryItem(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const imgStyle = {
        left: default_url === '/en/' ? "20px" : "",
        right: default_url === "/ar/" ? "20px" : ""
    }
    return (
        <li className={props.className} style={{ textAlign: 'center'}} onClick={props.handleClick}>
            {/* <a className={props.herfClassName} href={props.link}>{props.description}
                <div className="subcategoryImages">
                    {props.haveImg2 && <img className="subcategoryImage1" style={isMobile ? imgStyle : {}} src={IMAGE_URL + props.image1 + "_small.jpg"} alt="Product Image" />}
                    {props.haveImg1 && <img className="subcategoryImage2" style={isMobile ? imgStyle : {}} src={IMAGE_URL + props.image2 + "_small.jpg"} alt="Product Image" />}
                </div>
                {props.haveTitle && <h3 className="cd-dropdown-item-title">{props.titleText}</h3>}
            </a> */}


            <Link className={props.herfClassName} to={props.link} style={{display: "block", padding: window.innerWidth < 1024  ? "15px 15px 0" : "15px 15px 0", height: "100%" }}>
                <img src={IMAGE_URL + props.image + ".jpg?u=" + props.updatedAt} style={{width: window.innerWidth < 1024 ? "100%" : "100%", boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)', borderRadius: '5px'}}></img>
            </Link>

        </li>
    )
}

export default CategoryItem;

/*

class
a
img
h3

*/
