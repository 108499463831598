import React, { useEffect, useState } from "react";
import AdminDashboard from "../../AdminDashboard"
import {isAuthenticated} from "../../../../auth"
import {default_url} from "../../../../index"
import { dict } from "../../../../dictionary";
import { read, update } from "../../../user/apiUser";
import { store } from 'react-notifications-component';

function AdminInfo() {
    const [values, setValues] = useState({
        fName: "",
        lName: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        mobile: "",
        dateOfBirth: "",
        nationality: "",
        gender: "",
        newsletter: "",
        changePassword: false,
        error: "",
        success: false
    });
    const { fName, lName, email, oldPassword, newPassword, confirmNewPassword, mobile, dateOfBirth, nationality, gender, newsletter, changePassword, error, success } = values;
    const { user, token } = isAuthenticated();

    const loadUser = () => {
        read(user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: true });
            } else {
                var date = new Date(res.dateOfBirth);
                var dd = String(date.getDate()).padStart(2, '0');
                var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = date.getFullYear();
                date =   yyyy +  '-' + mm + '-' + dd;
                setValues({
                    ...values,
                    fName: res.fName,
                    lName: res.lName,
                    email: res.email,
                    mobile: res.mobile,
                    dateOfBirth: date,
                    nationality: res.nationality,
                    gender: res.gender,
                    newsletter: res.newsletter
                })
            }
        })
    }

    useEffect(() => {
        loadUser();
    }, [])

    const handleChange = name => event => {
        let target = "";
        if (name === "newsletter" || name === "changePassword") {
            target = event.target.checked;
        } else {
            target = event.target.value;
        }
        setValues({ ...values, error: "", success: false, [name]: target });
    };
    const clickSubmit = (event) => {
        event.preventDefault();
        if (changePassword) {
            if (newPassword || confirmNewPassword) {
                if (newPassword !== confirmNewPassword) {
                    setValues({ ...values, success: false, error: "new_password_and_confirm_new_password_are_not_matched" });
                    return;
                }
                if (!oldPassword) {
                    setValues({ ...values, success: false, error: "please_enter_your_old_password" });
                    return;
                }
            } else {
                setValues({ ...values, success: false, error: "please_enter_the_new_password" });
                return;
            }
        }
        let updatedUser = { fName, lName, email, oldPassword, password: newPassword, mobile, dateOfBirth, nationality, gender, newsletter }
        update(user._id, token, updatedUser).then(res => {
            if (res.error) {
                console.log();
                setValues({ ...values, success: false, error: res.error });
            } else {
                setValues({
                    ...values,
                    changePassword: false,
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                    error: "",
                    success: true
                })
            }
        });
        showAlert();

    }

    const showAlert = () => {
        if (error) {
            store.addNotification({
                message: dict[error],
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        } else if (success) {
            store.addNotification({
                message: dict["profile_has_been_updated"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const nationalitySelector = () => {
        return (
            <select name="nationality" className="inputField form-control" value={nationality} onChange={handleChange("nationality")}>
                <option value=""></option>
                <option value="afghan">Afghan</option>
                <option value="albanian">Albanian</option>
                <option value="algerian">Algerian</option>
                <option value="american">American</option>
                <option value="andorran">Andorran</option>
                <option value="angolan">Angolan</option>
                <option value="antiguans">Antiguans</option>
                <option value="argentinean">Argentinean</option>
                <option value="armenian">Armenian</option>
                <option value="australian">Australian</option>
                <option value="austrian">Austrian</option>
                <option value="azerbaijani">Azerbaijani</option>
                <option value="bahamian">Bahamian</option>
                <option value="bahraini">Bahraini</option>
                <option value="bangladeshi">Bangladeshi</option>
                <option value="barbadian">Barbadian</option>
                <option value="barbudans">Barbudans</option>
                <option value="batswana">Batswana</option>
                <option value="belarusian">Belarusian</option>
                <option value="belgian">Belgian</option>
                <option value="belizean">Belizean</option>
                <option value="beninese">Beninese</option>
                <option value="bhutanese">Bhutanese</option>
                <option value="bolivian">Bolivian</option>
                <option value="bosnian">Bosnian</option>
                <option value="brazilian">Brazilian</option>
                <option value="british">British</option>
                <option value="bruneian">Bruneian</option>
                <option value="bulgarian">Bulgarian</option>
                <option value="burkinabe">Burkinabe</option>
                <option value="burmese">Burmese</option>
                <option value="burundian">Burundian</option>
                <option value="cambodian">Cambodian</option>
                <option value="cameroonian">Cameroonian</option>
                <option value="canadian">Canadian</option>
                <option value="cape verdean">Cape Verdean</option>
                <option value="central african">Central African</option>
                <option value="chadian">Chadian</option>
                <option value="chilean">Chilean</option>
                <option value="chinese">Chinese</option>
                <option value="colombian">Colombian</option>
                <option value="comoran">Comoran</option>
                <option value="congolese">Congolese</option>
                <option value="costa rican">Costa Rican</option>
                <option value="croatian">Croatian</option>
                <option value="cuban">Cuban</option>
                <option value="cypriot">Cypriot</option>
                <option value="czech">Czech</option>
                <option value="danish">Danish</option>
                <option value="djibouti">Djibouti</option>
                <option value="dominican">Dominican</option>
                <option value="dutch">Dutch</option>
                <option value="east timorese">East Timorese</option>
                <option value="ecuadorean">Ecuadorean</option>
                <option value="egyptian">Egyptian</option>
                <option value="emirian">Emirian</option>
                <option value="equatorial guinean">Equatorial Guinean</option>
                <option value="eritrean">Eritrean</option>
                <option value="estonian">Estonian</option>
                <option value="ethiopian">Ethiopian</option>
                <option value="fijian">Fijian</option>
                <option value="filipino">Filipino</option>
                <option value="finnish">Finnish</option>
                <option value="french">French</option>
                <option value="gabonese">Gabonese</option>
                <option value="gambian">Gambian</option>
                <option value="georgian">Georgian</option>
                <option value="german">German</option>
                <option value="ghanaian">Ghanaian</option>
                <option value="greek">Greek</option>
                <option value="grenadian">Grenadian</option>
                <option value="guatemalan">Guatemalan</option>
                <option value="guinea-bissauan">Guinea-Bissauan</option>
                <option value="guinean">Guinean</option>
                <option value="guyanese">Guyanese</option>
                <option value="haitian">Haitian</option>
                <option value="herzegovinian">Herzegovinian</option>
                <option value="honduran">Honduran</option>
                <option value="hungarian">Hungarian</option>
                <option value="icelander">Icelander</option>
                <option value="indian">Indian</option>
                <option value="indonesian">Indonesian</option>
                <option value="iranian">Iranian</option>
                <option value="iraqi">Iraqi</option>
                <option value="irish">Irish</option>
                <option value="italian">Italian</option>
                <option value="ivorian">Ivorian</option>
                <option value="jamaican">Jamaican</option>
                <option value="japanese">Japanese</option>
                <option value="jordanian">Jordanian</option>
                <option value="kazakhstani">Kazakhstani</option>
                <option value="kenyan">Kenyan</option>
                <option value="kittian and nevisian">Kittian and Nevisian</option>
                <option value="kuwaiti">Kuwaiti</option>
                <option value="kyrgyz">Kyrgyz</option>
                <option value="laotian">Laotian</option>
                <option value="latvian">Latvian</option>
                <option value="lebanese">Lebanese</option>
                <option value="liberian">Liberian</option>
                <option value="libyan">Libyan</option>
                <option value="liechtensteiner">Liechtensteiner</option>
                <option value="lithuanian">Lithuanian</option>
                <option value="luxembourger">Luxembourger</option>
                <option value="macedonian">Macedonian</option>
                <option value="malagasy">Malagasy</option>
                <option value="malawian">Malawian</option>
                <option value="malaysian">Malaysian</option>
                <option value="maldivan">Maldivan</option>
                <option value="malian">Malian</option>
                <option value="maltese">Maltese</option>
                <option value="marshallese">Marshallese</option>
                <option value="mauritanian">Mauritanian</option>
                <option value="mauritian">Mauritian</option>
                <option value="mexican">Mexican</option>
                <option value="micronesian">Micronesian</option>
                <option value="moldovan">Moldovan</option>
                <option value="monacan">Monacan</option>
                <option value="mongolian">Mongolian</option>
                <option value="moroccan">Moroccan</option>
                <option value="mosotho">Mosotho</option>
                <option value="motswana">Motswana</option>
                <option value="mozambican">Mozambican</option>
                <option value="namibian">Namibian</option>
                <option value="nauruan">Nauruan</option>
                <option value="nepalese">Nepalese</option>
                <option value="new zealander">New Zealander</option>
                <option value="ni-vanuatu">Ni-Vanuatu</option>
                <option value="nicaraguan">Nicaraguan</option>
                <option value="nigerien">Nigerien</option>
                <option value="north korean">North Korean</option>
                <option value="northern irish">Northern Irish</option>
                <option value="norwegian">Norwegian</option>
                <option value="omani">Omani</option>
                <option value="pakistani">Pakistani</option>
                <option value="palauan">Palauan</option>
                <option value="panamanian">Panamanian</option>
                <option value="papua new guinean">Papua New Guinean</option>
                <option value="paraguayan">Paraguayan</option>
                <option value="peruvian">Peruvian</option>
                <option value="polish">Polish</option>
                <option value="portuguese">Portuguese</option>
                <option value="qatari">Qatari</option>
                <option value="romanian">Romanian</option>
                <option value="russian">Russian</option>
                <option value="rwandan">Rwandan</option>
                <option value="saint lucian">Saint Lucian</option>
                <option value="salvadoran">Salvadoran</option>
                <option value="samoan">Samoan</option>
                <option value="san marinese">San Marinese</option>
                <option value="sao tomean">Sao Tomean</option>
                <option value="saudi">Saudi</option>
                <option value="scottish">Scottish</option>
                <option value="senegalese">Senegalese</option>
                <option value="serbian">Serbian</option>
                <option value="seychellois">Seychellois</option>
                <option value="sierra leonean">Sierra Leonean</option>
                <option value="singaporean">Singaporean</option>
                <option value="slovakian">Slovakian</option>
                <option value="slovenian">Slovenian</option>
                <option value="solomon islander">Solomon Islander</option>
                <option value="somali">Somali</option>
                <option value="south african">South African</option>
                <option value="south korean">South Korean</option>
                <option value="spanish">Spanish</option>
                <option value="sri lankan">Sri Lankan</option>
                <option value="sudanese">Sudanese</option>
                <option value="surinamer">Surinamer</option>
                <option value="swazi">Swazi</option>
                <option value="swedish">Swedish</option>
                <option value="swiss">Swiss</option>
                <option value="syrian">Syrian</option>
                <option value="taiwanese">Taiwanese</option>
                <option value="tajik">Tajik</option>
                <option value="tanzanian">Tanzanian</option>
                <option value="thai">Thai</option>
                <option value="togolese">Togolese</option>
                <option value="tongan">Tongan</option>
                <option value="trinidadian or tobagonian">Trinidadian or Tobagonian</option>
                <option value="tunisian">Tunisian</option>
                <option value="turkish">Turkish</option>
                <option value="tuvaluan">Tuvaluan</option>
                <option value="ugandan">Ugandan</option>
                <option value="ukrainian">Ukrainian</option>
                <option value="uruguayan">Uruguayan</option>
                <option value="uzbekistani">Uzbekistani</option>
                <option value="venezuelan">Venezuelan</option>
                <option value="vietnamese">Vietnamese</option>
                <option value="welsh">Welsh</option>
                <option value="yemenite">Yemenite</option>
                <option value="zambian">Zambian</option>
                <option value="zimbabwean">Zimbabwean</option>
            </select>

        )
    }

    const genderSelector = () => {
        return (
            <select name="gender" className="inputField form-control" value={gender} onChange={handleChange("gender")}>
                <option value=""></option>
                <option value="male">{dict['male']}</option>
                <option value="female">{dict["female"]}</option>
            </select>
        )
    }

    const changePasswordFields = () => {
        return (
            <div className="changePasswordForm" style={{ display: changePassword ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["old_password"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("oldPassword")} type="password" value={oldPassword} className="inputField form-control" id="password" placeholder={dict["old_password"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["new_password"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("newPassword")} type="password" value={newPassword} className="inputField form-control" placeholder={dict["new_password"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["confirm_new_password"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("confirmNewPassword")} type="password" value={confirmNewPassword} className="inputField form-control" placeholder={dict["confirm_new_password"]}></input>
            </div>
        )
    }

    const arStyleRequiredStart = {
        left: "30px",
    }
    const enStyleRequiredStart = {
        right: "30px",
    }
    const arStylelabel = {
        float: "right"
    }

    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict['edit_account_information']}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict['required']}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['first_name']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("fName")} type="text" value={fName} className="inputField form-control" id="fName" placeholder={dict['first_name']}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['last_name']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("lName")} type="text" value={lName} className="inputField form-control" id="lName" placeholder={dict['last_name']}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email_address"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("email")} type="email" value={email} className="inputField form-control" id="email" aria-describedby="emailHelp" placeholder={dict["email_address"]}></input>
                    <div className="form-check checkBoxUserDashboard">
                        <input onChange={handleChange("changePassword")} type="checkbox" checked={changePassword} className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["change_password"]}</label>
                    </div>
                    {changePasswordFields()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="1"
            data={form}
        />
    )
}

export default AdminInfo;