import React, { useState, useEffect } from "react";
import { signin, authenticate, isAuthenticated, isAdmin } from "../auth"
import * as qs from 'query-string';
import { Redirect } from "react-router-dom";
import { dict } from "../dictionary";
import { default_url } from "../index"
import { store } from 'react-notifications-component';
import { resetPassword } from "./user/apiUser";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function ResetPassword({ match }) {
    const classes = useStyles();
    const [values, setValues] = useState({
        password: "",
        re_password: "",
        error: "",
        success: false,
        loading: false
    });

    const token = match.params.token;
    
    if(!token || token.length === 0 || token === ""){
        window.location.href = default_url
    }

    let isMobile = window.innerWidth < 1024;

    useEffect(() => {

    }, [])

    const { password, re_password, error, success, loading } = values;

    const arDir = {
        direction: "rtl"
    }
    const enDir = {
        direction: "ltr"
    }

    const arStyleAlert = {
        textAlign: "right",
    }

    const enStyleAlert = {
        textAlign: "left",
    }

    const arStylelabel = {
        float: "right"
    }

    const enStylelabel = {
        float: "left"
    }

    const arStyleRequiredStart = {
        left: "35%",
        textaAlign: "end"
    }

    const enStyleRequiredStart = {
        right: "35%",
        textaAlign: "end"
    }

    const arStyleRequiredStartMob = {
        left: "5%",
    }

    const enStyleRequiredStartMob = {
        right: "5%",
    }

    const forgetPasswordForm = () => {
        return (
            <div>
                <h1 className="formContainerTitle" dir={default_url == "/en/" ? "ltr" : "rtl"}>{dict["reset_password"]}</h1>
                <h6 className="requiredStar" style={isMobile ? default_url === "/en/" ? enStyleRequiredStartMob : arStyleRequiredStartMob : default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["new_password"]} <span className="redStar">*</span></label>
                    <input dir="auto" onChange={handleChange("password")} className="inputField form-control" type="password" name="password" value={password} required /><br />
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["confirm_new_password"]} <span className="redStar">*</span></label>
                    <input dir="auto" onChange={handleChange("re_password")} className="inputField form-control" type="password" name="re_password" value={re_password} required /><br />
                    <button onClick={clickSubmit} type="submit" className="loginBtn" style={{ marginBottom: "30px" }}>{dict["send"]}</button>
                </form>
            </div>
        )
    }

    const handleChange = name => event => {
        setValues({ ...values, error: "", [name]: event.target.value, success: false });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        if (password.length === 0) {
            showNotification("err", "please_enter_new_password")
        } else if (password != re_password) {
            showNotification("err", "new_password_and_confirm_new_password_are_not_matched")
        } else if (password.length > 0 && password.length === re_password.length && password == re_password) {
            setValues({ ...values, loading: true })
            resetPassword(password, token).then(res => {
                setValues({ ...values, false: true })
                if (res.error) {
                    setValues({ ...values, error: res.error });
                    showNotification("err", res.error);
                } else {
                    setValues({ ...values, email: "", error: "", success: true })
                    showNotification("success", res.message);
                }
            })
        }

    }

    const showNotification = (status, msg) => {
        store.addNotification({
            message: dict[msg],
            type: status === "err" ? "danger" : "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    if (isAuthenticated()) {
        if (isAuthenticated().user.role === 1) {
            window.location.href = default_url + "admin/account-information"
        } else {
            window.location.href = default_url + "user/account-information"
        }

    }

    return (
        <div className="signinContainer">
            {showBackdrop()}
            {forgetPasswordForm()}
        </div>
    )
}

export default ResetPassword;