import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { Redirect } from "react-router-dom";
import { updateArea, updateSubcategory } from "../../apiAdmin"
import { getArea, getSubcategory, listCategories, listCities } from "../../../apiCore"
import * as qs from 'query-string';
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";

function UpdateArea() {

    const [values, setValues] = useState({
        _id: "",
        enName: "",
        arName: "",
        city: "",
        error: "",
        redirectToReferrer: false,
    });
    const [cities, setCities] = useState([]);

    const parsed = qs.parse(window.location.search);

    useEffect(() => {
        async function fetchData() {
            const area = await getArea(parsed.area_id)
                .then(res => {
                    if (res.error) {
                        setValues({ ...values, error: res.error, redirectToReferrer: true });
                    } else {
                        setValues({ _id: res._id, enName: res.enName, arName: res.arName, city: res.city });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const c = await listCities()
                .then(res => {
                    setCities(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const { user, token } = isAuthenticated();
    const { _id, enName, arName, city, error, redirectToReferrer } = values;


    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        updateArea({ _id, enName, arName, city }, user._id, token)
            .then(data => {
                if (data.error) {
                    console.log(data.error);

                    setValues({ ...values, error: data.error, redirectToReferrer: false });
                } else {
                    setValues({
                        ...values,
                        error: undefined,
                        redirectToReferrer: true
                    });
                }
            })
    }

    const citySelector = () => {
        return (
            <select onChange={handleChange("city")} className="inputField form-control" value={city}>
                <option value={null}></option>
                {
                    cities.sort((a, b) => a.arName - b.arName).map((city, index) => {
                        return (
                            <option key={index} value={city._id}>{city.enName + " | " + city.arName}</option>
                        )
                    })

                }
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/areas', state: { prevPath: "edit", error } }} />
            )
        }
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["edit_area"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area_english_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="name" placeholder={dict["area_english_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area_arabic_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="name" placeholder={dict["area_arabic_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city"]} <span className="redStar">*</span></label><br />
                    {citySelector()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="333"
            data={form}
        />
    )
}

export default UpdateArea;