import React, { useState, useEffect } from 'react'
import DescriptionItem from './DescriptionItem';
import NewArrivals from '../NewArrivals'
import { default_url } from "../../index"
import { dict } from '../../dictionary';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isAuthenticated } from '../../auth';
import { addReview } from '../user/apiUser';
import { getReviews } from "../apiCore"
import { store } from 'react-notifications-component';


function ProductViewDescription({ data, index, getTotRate }) {
    const [description, setDescription] = useState(true);
    const [mouseOver, setMouseOver] = useState(false);
    const [review, setReview] = useState({
        product: data[index]._id,
        user: isAuthenticated() ? isAuthenticated().user._id : "",
        rate: null,
        comment: "",
        link: window.location.href,
        error: "",
        success: false
    });
    const [reviews, setReviews] = useState([]);
    const [avrgRate, setAvrgRate] = useState(0);

    const { product, user, rate, comment, error, success } = review;

    const loadReviews = () => {
        setReview({
            ...review,
            rate: null,
            comment: "",
            error: "",
            success: false
        });
        getReviews(data[index]._id).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let sum = 0, count = res.length;
                res.map(i => {
                    sum += i.rate;
                });
                if (count === 0) {
                    setAvrgRate(0);
                    getTotRate(0)
                } else {
                    setAvrgRate(parseInt(Math.ceil((sum / count))));
                    getTotRate(parseInt(Math.ceil((sum / count))))
                }
                setReviews(res);
            }
        })
    }

    useEffect(() => {
        loadReviews();
    }, [])

    const handleClick = name => event => {
        if (name == "description") {
            setDescription(true);
        } else {
            setDescription(false);
        }
    }

    const handleMouseMouseOver = () => {
        setMouseOver(true);
    }
    const handleMouseMouseOut = () => {
        setMouseOver(false);
    }

    const handleChange = name => event => {
        setReview({ ...review, comment: event.target.value });
    }

    const clickSubmit = (event) => {
        if (isAuthenticated()) {
            const { token } = isAuthenticated();
            addReview(user, token, review).then(res => {
                if (res.error) {
                    setReview({ ...review, success: false, error: res.error })
                } else {
                    setReview({
                        ...review,
                        rate: null,
                        comment: "",
                        error: "",
                        success: true
                    });
                    loadReviews();
                }
            })
        } else {
            setReview({ ...review, success: false, error: "please_signin_to_send_review" })

        }
    }

    const showDescription = () => {
        return (
            <div
                style={{ display: description ? "" : "none" }}
            >
                <DescriptionItem
                    title={dict["brand"] + " :"}
                    info={data[index].brand ? data[index].brand : "-"}
                />
                <DescriptionItem
                    title={dict["made_in"] + " :"}
                    info={default_url == "/en/" ? data[index].enMadeIn ? data[index].enMadeIn : "-" : data[index].arMadeIn ? data[index].arMadeIn : "-"}
                />
                <DescriptionItem
                    title={dict["warranty"] + " :"}
                    info={default_url == "/en/" ? data[index].enWarranty ? data[index].enWarranty : "-" : data[index].arWarranty ? data[index].arWarranty : "-"}
                />
            </div>
        )
    }

    const showReviews = () => {
        return (
            <div
                onClick={handleClick("reviews")}
                style={{ display: !description ? "" : "none", padding: "20px", textAlign: "start" }}
            >
                {currentReviews()}
                {reviewsForm()}
            </div>
        )
    }

    const currentReviews = () => {
        return (
            reviews.map((rev, i) => {
                return (
                    <div key={i}>
                        <h1 style={{ textAlign: "start" }}>{rev.user.fName.substring(0, 2) + "******"}</h1>
                        <Box component="fieldset" borderColor="transparent" style={{ fontSize: "30px !important", marginBottom: "15px" }}>
                            <Rating
                                readOnly
                                name="read-only"
                                value={rev.rate}
                                style={{ marginTop: "10px", fontSize: "50px !important", direction: "ltr" }}
                            />
                        </Box>
                        <h1 style={{ textAlign: "start" }}>{rev.comment}</h1>
                        <hr />
                    </div>
                )
            })
        )
    }

    const arStylelabel = {
        float: "right",
        fontSize: "18px"
    }

    const enStylelabel = {
        float: "left",
        fontSize: "18px"
    }

    const reviewsForm = () => {
        return (
            <div>
                <Box component="fieldset" borderColor="transparent" style={{ fontSize: "30px !important", marginBottom: "15px" }}>
                    <Typography component="legend" style={{ textAlign: "start", fontSize: "18px" }}>{dict["how_satisfied_were_you_with_this_product?"]}</Typography>
                    <Rating
                        name="simple-controlled"
                        value={rate}
                        onChange={(event, newValue) => {
                            setReview({ ...review, rate: newValue, error: "" });
                        }}
                        style={{ marginTop: "10px", fontSize: "50px !important", direction: "ltr" }}
                    />
                </Box>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['your_comment']}</label>
                <input onChange={handleChange("comment")} type="text" value={comment} className="inputField form-control" id="comment" placeholder={dict['your_comment']}></input>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn" style={{ marginBottom: "10px" }}>{dict["send_review"]}</button>
            </div>
        )
    }

    const showError = () => {
        if (error) {
            store.addNotification({
                message: dict[error],
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const showSuccess = () => {
        if (success) {
            store.addNotification({
                message: dict["your_review_has_been_added"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    return (
        <div className="productViewDescription" style={{ marginBottom: window.innerWidth < 1024 ? "15px" :"60px" }}>
            {showError()}
            {showSuccess()}
            <div className="descriptionOption">
                <ul className="optionsList">
                    <li
                        className="optionItem"
                        onClick={handleClick("description")}
                        onMouseOver={handleMouseMouseOver}
                        onMouseOut={handleMouseMouseOut}
                        style={{ cursor: "pointer" ,     marginTop: "9px"}}
                    >
                        <span className={"optionItemLink " + (description ? "selectedOption" : "")} >{dict["description"]}</span>
                    </li>
                    <li
                        className="optionItem"
                        onClick={handleClick("reviews")}
                        onMouseOver={handleMouseMouseOver}
                        onMouseOut={handleMouseMouseOut}
                        style={{ cursor: "pointer", marginTop: "9px" }}
                    >
                        <span className={"optionItemLink " + (!description ? "selectedOption" : "")} >{dict["reviews"]}</span>
                    </li>
                </ul>
            </div>
            <div className="descriptionBreakLine"></div>
            {showDescription()}
            {showReviews()}
        </div>
    )
}

export default ProductViewDescription;