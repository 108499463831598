import React from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"

function ReturnAndExchange() {
    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900" }}>سياسة الاستبدال او الارجاع</h1>
                <br />
                <p style={{lineHeight: "2"}}>
                    لديك الحق بإستبدال أو إرجاع البضاعة المشتراه من شركة رويال هاوس للتجارة العامة والمقاولات خلال ١٤ يوم من تاريخ الشراء في حالة وجوب الشروط الآتية:
                <ul>
                        <li>- أن تكون البضاعة المراد إسترجاعها أو إستبدالها بها خلل مصنعي لا يمكن إصلاحه بدون المساس بجودة البضاعة أو تكون البضاعة مغشوشة أو مقلدة أومعيبة أو غير مطابقة للمواصفات.</li>
                        <li>- أن تكون البضاعة بالكرتون وعلى نفس حالتها وقت الشراء مع كامل الإكسسوارات.</li>
                        <li>- أن تكون البضاعه فى بحالة جيدة وقابلة للعرض (عدا البضاعة التي وجد فيها خلال مصنعى أو مغشوشة أو مقلدة).</li>
                    </ul>
                    <br />
                لديك الحق عميلنا العزيز بإستلام ثمن البضاعة التي تم إسترجاعها بنفس الطريقة التي تم الدفع بها.
                <br />
                لديك الحق عميلنا العزيز بإستلام ثمن البضاعة التي تم إسترجاعها بالكامل ، ولن تخصم أيه نوع من الرسوم مثل رسوم بطاقة الفيزا او رسوم بطاقة الكي نت او ما شابه ذلك من المبلغ المسترجع.
                <br />
                لديك الحق في أستلام ثمن البضاعة التي تم إسترجاعها خلال عشرة أيام من تاريخ قبول إسترجاع البضاعة.
                <br />
                لديك الحق عميلنا العزيز بإستبدال البضاعة المشتراه خلال ١٤ يوم من تاريخ الشراء على أن تكون البضاعة بالتغليف الأصلي وغير مستخدمة وبكامل إكسسواراتها.
                </p>

                <br />

                <h1 style={{ fontSize: "20px", fontWeight: "900" }}>طريقة ارجاع واستبدال البضاعة</h1>
                <br />
                <p style={{lineHeight: "2"}}>
                الرجاء التواصل مع <a href="https://wa.me/message/CK5RVYN63EBMI1" style={{color: "#E4BC4B", fontWeight: 900, textDecoration: "underline", fontSize: "18px", margin: "0 2px"}}>خدمة العملاء</a> عبر الواتس أب وسيتم التحقق من الشروط السابقة واعلامكم بالطريقة المناسبة للاستبدال
                </p>
            </div>
        </div>
    )
}

export default ReturnAndExchange;