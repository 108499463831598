import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from 'react-router-dom'
import ProductsNavLink from "./product components/ProductsNavLink"
import ProductsFilter from "./product components/Filter/ProductsFilter"
import ProductsContainer from "./product components/product list/ProductsContainer"
import { useParams } from "react-router-dom";
import { getCategoryDetails, getSubcategoryDetails, listProductsByFilters, getSubcategory, listColors } from "./apiCore"
import { default_url } from "../index"
import { dict } from "../dictionary";
import { Helmet } from "react-helmet";
import { getByTitle } from "@testing-library/react";
import { Sync } from "@material-ui/icons";

function Products(props) {
    const history = useHistory()
    let { category } = useParams();
    let { subcategory } = useParams();
    const params = new URLSearchParams(window.location.search);
    let page = parseInt(params.get('page'));
    if (!page) {
        page = 1;
    } else {
        page = parseInt(page);
    }
    let price = params.get('price')
    let prices = []
    if (price) {
        prices = price.split("-");
    }
    let size = params.get('size')
    let sizes = []
    if (size) {
        sizes = size.split(",");
    }
    let color = params.get('color')
    let colors = []
    if (color) {
        colors = color.split(",");
    }
    const [allProducts, setAllProducts] = useState([]);
    const [products1, setProducts1] = useState([]);
    const [products2, setProducts2] = useState([]);
    const [products3, setProducts3] = useState([]);
    const [products4, setProducts4] = useState([]);
    const [categoryInfo, setCategoryInfo] = useState();
    const [subcategoryInfo, setSubcategoryInfo] = useState();
    const [sizeFilters, setSizeFilters] = useState([]);
    const [allColors, setAllColors] = useState([]);
    const [allColors2, setAllColors2] = useState([]);
    const [allColors3, setAllColors3] = useState([]);
    const [limit, setLimit] = useState(1);
    const [neededPages, setNeededPages] = useState([1]);
    const [error, setError] = useState([]);

    // useEffect(() => {
    //     init();
    // }, [])
    const url = window.location.pathname.split('/').pop();

    useEffect(() => {
        init();
    }, [url])

    useEffect(() => {
        if (categoryInfo) {
            loadSizes(categoryInfo.enName.toLowerCase());
        }
    }, [allProducts])

    useEffect(() => {
        if (categoryInfo) {
            loadSizes(categoryInfo.enName.toLowerCase());
        }
    }, [allProducts])

    useEffect(() => {
        getColors()
    }, [])

    const init = () => {
        if (category.toLowerCase() === "thermos") {
            setLimit(100000);
            loadCategory(1);
            loadCategory(2);
        } else {
            loadCategory(1);
        }
    }


    const getColors = () => {
        listColors().then(res => {
            if (res.error) {

            } else {
                setAllColors(res);
                return res;
            }
        })
    }

    const loadCategory = (section) => {
        getCategoryDetails("enName", category).then(res => {
            if (res.error) {
                setError(res.error);
            } else {
                loadSizes(res.enName.toLowerCase());
                if (subcategory) {
                    loadSubCategory(res, section);
                } else {
                    setSubcategoryInfo(null);
                    loadProducts(res, null, section);
                }
                setCategoryInfo(res);
            }
        })
    }

    const loadSubCategory = (category, section) => {
        getSubcategoryDetails("enName", subcategory).then(res => {
            if (res.error) {
                setError(res.error);
            } else {
                loadProducts(category, res, section);
                setSubcategoryInfo(res);
            }
        })
    }

    const loadProducts = (category, subcategory = null, section = 1) => {
        let filters = { category: category._id, hidden: false }
        if (subcategory) {
            filters['subcategory'] = subcategory._id;
        }
        if (prices.length == 2) {
            filters['price'] = prices;
        }
        // if(colors.length > 0){
        //     filters['color'] = colors;
        // }
        // if (sizes.length > 0) {
        //     filters['size'] = sizes;
        // }
        if (category.enName.toLowerCase() === "thermos") {
            if (section == 1) {
                filters['thermosType'] = 'TEA'
            } else {
                filters['thermosType'] = 'CF'
            }
        }
        listProductsByFilters(0, 1000000, filters).then(res => {
            if (res.error) {
                setError(res.error);
            } else {

                listColors().then(colorsRes => {
                    if (colorsRes.error) {

                    } else {
                        let set = new Set();
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].color) {
                                set.add(res.data[i].color);
                            }
                        }
                        let tmpColors = Array.from(set).sort((a, b) => {
                            return a - b;
                        });
                        let tmpColors2 = [];
                        for (let i = 0; i < colorsRes.length; i++) {
                            for (let j = 0; j < tmpColors.length; j++) {
                                if (colorsRes[i].number == tmpColors[j]) {
                                    tmpColors2.push({ ...colorsRes[i] })
                                }
                            }
                        }
                        if (category.enName.toLowerCase() === "thermos") {
                            if (section == 1) {
                                setAllColors2(tmpColors2);
                            } else {
                                let colorsTmp = [...allColors2]
                                for (let i = 0; i < tmpColors2.length; i++) {
                                    colorsTmp.push({...tmpColors2[i]});
                                }
                                setAllColors3(colorsTmp);
                            }
                        }else{
                            setAllColors3(tmpColors2);
                        }
                        let arr = [];
                        let found = false
                        for (let i = 0; i < res.data.length; i++) {
                            let foundColor = false;
                            for (let j = 0; j < res.data[i].tags && res.data[i].tags.length; j++) {
                                for (let k = 0; k < colors.length; k++) {
                                    if(colors[k] == res.data[i].tags[j]){
                                        foundColor = true;
                                    }
                                }
                            }
                            if (res.data[i].color && colors.length > 0 && !colors.includes(res.data[i].color) && !foundColor) {
                                continue;
                            }
                            found = false;
                            for (let j = 0; j < arr.length; j++) {
                                if (res.data[i].enTitle == arr[j].enTitle) {
                                    if ((!sizes || sizes.length == 0) || (res.data[i].size && sizes && sizes.includes(res.data[i].size))) {
                                        arr[j]._id.push(res.data[i]._id);
                                        arr[j].quantity.push(res.data[i].quantity);
                                        arr[j].price.push(res.data[i].price);
                                        arr[j].discountPrice.push(res.data[i].discountPrice);
                                        arr[j].size.push(res.data[i].size);
                                        arr[j].images.push([]);
                                        for (let k = 0; k < res.data[i].images.length; k++) {
                                            arr[j].images[arr[j].images.length - 1].push(res.data[i].images[k]);
                                        }
                                    }
                                    found = true
                                    break;
                                }
                            }
                            if (!found) {
                                if ((!sizes || sizes.length == 0) || (res.data[i].size && sizes && sizes.includes(res.data[i].size))) {
                                    arr.push({ ...res.data[i], _id: [res.data[i]._id], quantity: [res.data[i].quantity], price: [res.data[i].price], discountPrice: [res.data[i].discountPrice], size: res.data[i].size ? [res.data[i].size] : [], images: [[]] });
                                    for (let m = 0; m < res.data[i].images.length; m++) {
                                        arr[arr.length - 1].images[0].push(res.data[i].images[m]);
                                    }
                                }
                            }
                        }
                        let arr2 = [];
                        for (let i = 0; i < arr.length; i++) {
                            arr2.push({ ...arr[i] });
                            let at = arr2.length - 1;
                            let sizes = [...arr2[at].size];
                            let newIds = [...arr2[at]._id]
                            let newSizes = [...arr2[at].size]
                            let newPrices = [...arr2[at].price]
                            let newDiscountPrices = [...arr2[at].discountPrice]
                            let newImages = [...arr2[at].images]
                            for (let j = 0; j < sizes.length; j++) {
                                let cnt = 0;
                                for (let k = 0; k < sizes.length; k++) {
                                    if (j == k) continue;
                                    if (sizes[j] < sizes[k]) cnt += 1;
                                }
                                newIds[(sizes.length - cnt - 1)] = arr2[at]._id[j];
                                newSizes[(sizes.length - cnt - 1)] = sizes[j];
                                newPrices[(sizes.length - cnt - 1)] = arr2[at].price[j];
                                newDiscountPrices[(sizes.length - cnt - 1)] = arr2[at].discountPrice[j];
                                newImages[(sizes.length - cnt - 1)] = arr2[at].images[j];

                            }
                            arr2[at]._id = [...newIds];
                            arr2[at].size = [...newSizes];
                            arr2[at].price = [...newPrices];
                            arr2[at].discountPrice = [...newDiscountPrices];
                            arr2[at].images = [...newImages];
                        }
                        if (section === 1) {
                            setProducts1(res.data);
                            setProducts3(arr2);
                        } else {
                            setProducts2(res.data);
                            setProducts4(arr2);
                        }
                        setAllProducts(res.data);
                    }
                })


            }
        });
    }

    const loadSizes = (category) => {
        if (!category) return;
        if (category === "thermos") {
            if (default_url == "/en/") {
                setSizeFilters([{ name: "Small", sizes: ["0.5L", "0.6L"] }, { name: "Medium", sizes: ["0.9L", "1.0L"] }, { name: "Large", sizes: ["1.3L", "1.4L", "1.5L"] }]);
            }
            else {
                setSizeFilters([{ name: "صغير", sizes: ["0.5L", "0.6L"] }, { name: "وسط", sizes: ["0.9L", "1.0L"] }, { name: "كبير", sizes: ["1.3L", "1.4L", "1.5L"] }]);
            }
        } else {
            let set = new Set();
            for (let i = 0; i < allProducts.length; i++) {
                if (allProducts[i].size) {
                    set.add(allProducts[i].size);
                }
            }
            let sizes = [];
            set.forEach(value => {
                sizes.push({ name: value, sizes: [value] });
            });
            setSizeFilters([...sizes])
        }
        // else if (category === "tea & coffee sets") {
        //     setSizeFilters(["12pcs", "13pcs", "27pcs", "51pcs"]);
        // }
    }

    const handleNeededPages = (needed) => {
        setNeededPages(needed);
    }

    const getTitle = () => {
        let ret = ""
        if (categoryInfo) {
            if (default_url === "/en/") {
                ret += categoryInfo.enName
            } else {
                ret += categoryInfo.arName
            }
        }
        if (subcategoryInfo) {
            ret += " - "
            if (default_url === "/ar/" && subcategoryInfo.arName) {
                ret += subcategoryInfo.arName

            } else {
                ret += subcategoryInfo.enName
            }
        }
        return ret;
    }

    return (
        <div>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className="productsNavLinkContainer">
                {
                    categoryInfo &&
                    <ProductsNavLink
                        title={dict["home"]}
                        slash="/"
                        link={default_url}
                    />
                }
                {
                    categoryInfo &&
                    <ProductsNavLink
                        title={default_url === "/en/" ? categoryInfo.enName : categoryInfo.arName}
                        slash={subcategoryInfo ? "/" : undefined}
                        link={subcategoryInfo ? (default_url + "products/") + categoryInfo.enName : undefined}
                    />
                }
                {
                    subcategoryInfo &&
                    <ProductsNavLink
                        title={default_url === "/ar/" ? subcategoryInfo.arName ? subcategoryInfo.arName : subcategoryInfo.enName : subcategoryInfo.enName}
                    />
                }
            </div>
            <div className="productsMegaCountainer row" style={{ display: "block", marginInlineEnd: "0px !important" }}>
                <ProductsFilter
                    sizes={sizeFilters}
                    colors={allColors3}
                    {...props}
                />
                <div className="productsMenuContainer col-lg-10 col-md-12 row" style={{ display: "inline-block",  marginBottom: "40px" }}>
                    {
                        category.toLowerCase() == "thermos" ?
                            <Fragment>
                                <ProductsContainer
                                    title={dict["tea_thermos"]}
                                    items={products1}
                                    items3={products3}
                                    category={category.toLowerCase()}
                                    subcategory={subcategory ? subcategory.toLowerCase() : category.toLowerCase()}
                                    {...props}
                                    sizes={sizeFilters}
                                    colors={allColors3}
                                />
                                <ProductsContainer
                                    title={dict["coffee_thermos"]}
                                    items={products2}
                                    items3={products4}
                                    class="downProductsMenu"
                                    category={category.toLowerCase()}
                                    subcategory={subcategory ? subcategory.toLowerCase() : category.toLowerCase()}
                                    {...props}
                                    sizes={sizeFilters}
                                    colors={allColors3}
                                />
                            </Fragment>
                            :
                            <ProductsContainer
                                title={default_url === "/en/" ? categoryInfo ? categoryInfo.enName : "" : categoryInfo ? categoryInfo.arName : ""}
                                items={products1}
                                items3={products3}
                                category={category.toLowerCase()}
                                subcategory={subcategory ? subcategory.toLowerCase() : category.toLowerCase()}
                                {...props}
                                sizes={sizeFilters}
                                colors={allColors3}
                            />
                    }
                    {/* <div className="pageNumber">
                        {
                            neededPages.length > 1 && neededPages.map((p, i) => {
                                return <a key={i} href={props.location.search === "" ? "?page=" + p : changePage(p)} className={"pageCircle " + (page == p && "activePage")}>{p}</a>
                            })
                        }
                    </div> */}
                </div>

            </div>
        </div>
    )


























    // const [products1, setProducts1] = useState([]);
    // const [products2, setProducts2] = useState([]);
    // const [sizes1, setSizes1] = useState([]);
    // const [neededPages, setNeededPages] = useState([1]);
    // const [categoryId, setCategoryId] = useState();
    // const [subcategoryId, setSubcategoryId] = useState();
    // const [error, setError] = useState(false);
    // const [filters, setFilters] = useState({
    //     category: [],
    //     subcategory: []
    // });
    // const [skip, setSkip] = useState(0);
    // const [limit, setLimit] = useState(24);
    // const [loading, setLoading] = useState(true);
    // const [categoryName, setCategoryName] = useState();
    // const [subcategoryName, setSubcategoryName] = useState();

    // const initProducts = () => {
    //     getCategoryDetails("enName", category).then(cat => {
    //         if (cat.error) {
    //             setError(cat._id.error);
    //         } else {
    //             if (default_url == "/ar/") {
    //                 setCategoryName(cat.arName);
    //             } else {
    //                 setCategoryName(cat.enName);
    //             }
    //             setCategoryId(cat._id);
    //             if (subcategory) {
    //                 getSubcategoryDetails("enName", subcategory).then(sub => {
    //                     if (sub._id.error) {
    //                         setError(sub._id.error);
    //                     } else {
    //                         setSubcategoryId(sub._id);
    //                         if (default_url == "/ar/" && sub.arName) {
    //                             setSubcategoryName(sub.arName);
    //                         } else {
    //                             setSubcategoryName(sub.enName);
    //                         }
    //                         let filters = {
    //                             category: [cat._id],
    //                             subcategory: [sub._id],
    //                             hidden: false
    //                         }
    //                         if (prices.length == 2) {
    //                             filters['price'] = prices;
    //                         }
    //                         if (sizes.length > 0) {
    //                             filters['size'] = sizes;
    //                         }
    //                         if (category.toLowerCase() === "thermos") {
    //                             filters['thermosType'] = 'TEA'
    //                             listProductsByFilters(skip, 100000, filters).then(p1 => {
    //                                 if (p1.error) {
    //                                     setError(p1.error);
    //                                 } else {
    //                                     setProducts1(p1.data);
    //                                     filters['thermosType'] = 'CF';
    //                                     listProductsByFilters(skip, 100000, filters).then(p2 => {
    //                                         if (p2.error) {
    //                                             setError(p2.error);
    //                                         } else {
    //                                             setProducts2(p2.data);
    //                                         }
    //                                     })
    //                                 }
    //                             })
    //                         } else {
    //                             listProductsByFilters(skip, 100000, filters).then(data => {
    //                                 if (data.error) {
    //                                     setError(data.error);
    //                                 } else {
    //                                     setProducts1(data.data);
    //                                 }
    //                             })
    //                         }
    //                         setFilters(filters);
    //                     }
    //                 })
    //             } else {
    //                 let filters = {
    //                     category: [cat._id],
    //                     hidden: false
    //                 }
    //                 if (prices.length == 2) {
    //                     filters['price'] = prices;
    //                 }
    //                 if (sizes.length > 0) {
    //                     filters['size'] = sizes;
    //                 }
    //                 if (category.toLowerCase() === "thermos") {
    //                     setLimit(12);
    //                     filters['thermosType'] = 'TEA'
    //                     listProductsByFilters(skip, 100000, filters).then(p1 => {
    //                         if (p1.error) {
    //                             setError(p1.error);
    //                         } else {
    //                             setProducts1(p1.data);
    //                             filters['thermosType'] = 'CF';
    //                             listProductsByFilters(skip, 100000, filters).then(p2 => {
    //                                 if (p2.error) {
    //                                     setError(p2.error);
    //                                 } else {
    //                                     setProducts2(p2.data);
    //                                 }
    //                             })
    //                         }
    //                     })
    //                 } else {
    //                     listProductsByFilters(skip, 100000, filters).then(data => {
    //                         if (data.error) {
    //                             setError(data.error);
    //                         } else {
    //                             setProducts1(data.data);
    //                         }
    //                     })
    //                 }
    //             }
    //         }
    //     })
    // }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         await setSizes1(loadSizes());
    //         await initProducts();
    //         await setLoading(false);
    //     };
    //     fetchData();
    // }, []);

    // const loadSizes = () => {
    //     if(category === "thermos"){
    //         return ["0.5L", "0.6L", "0.9L", "1.0L", "1.3L", "1.4L", "1.5L"];
    //     }
    //     if(category === "tea & coffee sets"){
    //         return ["12pcs", "13pcs", "27pcs", "51pcs"];
    //     }
    // }

    // const handleNeededPages = (needed) => {
    //     setNeededPages(needed);
    // }

    // const changePage = (page) => {
    //     let search = props.location.search.split("&");
    //     let res = "";
    //     let done = false;
    //     for (let i = 0; i < search.length; i++) {
    //         if (search[i].includes("page=")) {
    //             if (i == 0) {
    //                 res += "?"
    //             }
    //             res += ("page=" + page + "&");
    //             done = true;
    //         } else {
    //             res += search[i] + "&";
    //         }
    //     }
    //     if (!done) {
    //         res += ("page=" + page + "&");
    //     }
    //     return (res.substring(0, res.length - 1));
    // }

    // return (
    //     <div>
    //         <div className="productsNavLinkContainer">
    //             {
    //                 categoryName &&
    //                 <ProductsNavLink
    //                     title={dict["home"]}
    //                     slash="/"
    //                     link={default_url}
    //                 />
    //             }
    //             {
    //                 categoryName &&
    //                 <ProductsNavLink
    //                     title={categoryName[0].toUpperCase() + categoryName.substring(1, categoryName.length)}
    //                     slash={subcategoryName ? "/" : undefined}
    //                     link={subcategoryName ? (default_url + "products/") + categoryName : undefined}
    //                 />
    //             }
    //             {
    //                 subcategoryName &&
    //                 <ProductsNavLink
    //                     title={subcategoryName == "thermos" ? subcategoryName.toUpperCase() : subcategoryName[0].toUpperCase() + subcategoryName.substring(1, subcategoryName.length)}
    //                 />
    //             }
    //         </div>
    //         <div className="productsMegaCountainer row">
    //             <ProductsFilter
    //                 sizes={sizes1}
    //                 {...props}
    //             />
    //             <div className="productsMenuContainer col-lg-10 col-md-12 row">
    //                 {
    //                     category == "thermos" ?
    //                         <Fragment>
    //                             <ProductsContainer
    //                                 title={dict["tea_thermos"]}
    //                                 items={products1}
    //                                 category={category}
    //                                 subcategory={subcategory ? subcategory : category}
    //                                 neededPages={needed => {
    //                                     handleNeededPages(needed);
    //                                 }}
    //                                 limit={limit}
    //                                 needed={neededPages}
    //                                 {...props}
    //                                 sizes={["0.5L", "0.6L", "0.9L", "1.0L", "1.3L", "1.4L", "1.5L"]}
    //                             />
    //                             <ProductsContainer
    //                                 title={dict["coffee_thermos"]}
    //                                 items={products2}
    //                                 class="downProductsMenu"
    //                                 category={category}
    //                                 subcategory={subcategory ? subcategory : category}
    //                                 neededPages={needed => {
    //                                     handleNeededPages(needed);
    //                                 }}
    //                                 limit={limit}
    //                                 needed={neededPages}
    //                                 {...props}
    //                                 sizes={["0.5L", "0.6L", "0.9L", "1.0L", "1.3L", "1.4L", "1.5L"]}
    //                             />
    //                         </Fragment>
    //                         :
    //                         <ProductsContainer
    //                             title={default_url = category[0].toUpperCase() + category.substring(1, category.length)}
    //                             items={products1}
    //                             category={category}
    //                             subcategory={subcategory ? subcategory : category}
    //                             neededPages={needed => {
    //                                 handleNeededPages(needed);
    //                             }}
    //                             limit={limit}
    //                             needed={neededPages}
    //                             {...props}
    //                             sizes={["0.5L", "0.6L", "0.9L", "1.0L", "1.3L", "1.4L", "1.5L"]}
    //                         />
    //                 }
    //                 <div className="pageNumber">
    //                     {
    //                         neededPages.length > 1 && neededPages.map((p, i) => {
    //                             return <a key={i} href={props.location.search === "" ? "?page=" + p : changePage(p)} className={"pageCircle " + (page == p && "activePage")}>{p}</a>
    //                         })
    //                     }
    //                 </div>
    //             </div>

    //         </div>
    //     </div>
    // )
}

export default Products;