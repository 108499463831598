import React, { useState, useEffect, Fragment, useRef } from 'react'
import ProductViewTitle from './product view info/ProductViewTitle'
import ProductViewInfoList from "./product view info/ProductViewInfoList"
import ProductViewSizeOptions from "./product view info/ProductViewSizeOptions"
import ProductViewPrice from './product view info/ProductViewPrice'
import NumericInput from 'react-numeric-input';
import ProductViewAllImages from './product view images/ProductViewAllImages';
import { default_url } from "../../index"
import { dict } from '../../dictionary'
import { addItem, getItemQuantity } from "../cartHelpers"
import { addItemToCompare } from '../compareHelpers'
import { store } from 'react-notifications-component';
import { addToWishlist } from '../wishlistHelpers'
import { IMAGE_URL } from "../../config"
import FavoriteIcon from '@material-ui/icons/Favorite';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ShareIcon from '@material-ui/icons/Share';
import { Helmet } from 'react-helmet'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core'
import { WhatsappShareButton, PinterestShareButton } from 'react-share';
import Buy1Get1Free from './Buy1Get1Free'
import { listFreeItems } from '../apiCore'
import { useCookies } from 'react-cookie';


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function ProductViewContainer({ data, sizes, index, setIndex, totRate }) {
    const classes = useStyles();
    let isMobile = window.innerWidth < 1024;
    const [quantity, setQuantity] = useState(1);
    const [minQuatity, setMinQuantity] = useState(1);
    const [maxQuatity, setMaxQuantity] = useState(data[index].quantity);
    const [image, setImage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showBuy1Get1Free, setShowBuy1Get1Free] = useState(false);
    const ref1 = useRef(null);
    const [width1, setWidth1] = useState(isMobile ? "100%" : "100%")
    const [height1, setHeight1] = useState(isMobile ? "100%" : "100%")
    const [anchorEl, setAnchorEl] = useState(null);
    const [freeItemsList, setFreeItemsList] = useState([]);
    const [cookies, setCookie] = useCookies(['_fbp']);
    const open = Boolean(anchorEl);

    const loadFreeItems = () => {
        listFreeItems(data[index]._id).then(res => {
            if(res.error){
                console.log(res.error)
            }else{
                setFreeItemsList([...res]);
            }
        });
    }

    useEffect(() => {
        setLoading(true);
        loadFreeItems();
        getItemQuantity(data[index]._id).then(limit => {
            setQuantity(limit <= 0 ? 0 : 1);
            setLoading(false);
        });
    }, [])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCopy = (event) => {
        navigator.clipboard.writeText(window.location.href)
        setAnchorEl(null);
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    // useEffect(() => {
    //     setWidth1(ref1.current ? ref1.current.offsetHeight : isMobile ? "200px" : "600px");
    //     setHeight1(ref1.current ? ref1.current.offsetWidth : isMobile ? "200px" : "600px");
    // }, [ref1.current])

    const changeQuantity = async (newValue) => {
        if (newValue < minQuatity) {
            setQuantity(1);
        } else {
            setLoading(true);
            await getItemQuantity(data[index]._id).then(limit => {
                setQuantity(newValue > limit ? limit <= 0 ? 0 : limit : newValue);
                setLoading(false);
            });
        }
    }

    const changeIndex = (index) => {
        setIndex(index);
        setImage(0);
        setLoading(true);
        getItemQuantity(data[index]._id).then(limit => {
            setQuantity(limit <= 0 ? 0 : 1);
            setMaxQuantity(limit <= 0 ? 0 : limit);
            setLoading(false);
        });
    }

    const changeImage = (index) => {
        setImage(index)
    }

    const handleAddToCart = () => {
        if(data[index].freeItem){
            setShowBuy1Get1Free(true)
        }else{
            addItem(data[index]._id, quantity, window.location.href, data[index].price, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, showSuccess());
            if (maxQuatity - quantity === 0) {
    
            }
        }
        
    }

    const showSuccess = () => {
        store.addNotification({
            message: dict['product_has_been_added_to_cart'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const handleAddToCompare = () => {
        let res = addItemToCompare(data[index], window.location.href);
        store.addNotification({
            message: dict[res.error ? res.error : res.success],
            type: res.error ? "danger" : "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 3500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const handleAddToWishlist = () => {
        addToWishlist(data[index]._id, window.location.href);
        store.addNotification({
            message: dict['product_has_been_added_to_wishlist'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const handleShare = () => {
        setAnchorEl(true)
    }

    const outOfStockStyle = {
        backgroundColor: "red",
        color: "white",
        position: "absolute",
        textAlign: "center",
        fontSize: "12px",
        lineHeight: "25px",
        height: "25px",
        padding: "0 10px",
        right: default_url === "/en/" ? "0" : "",
        left: default_url === "/ar/" ? "0" : "",
    }

    var imgs = ["/img/thermos/rs-1010.jpg", "/images/RS-707-06-A01(1).jpg", "/img/thermos/rs-1010.jpg", "/img/thermos/rs-1010.jpg"]
    let allImagesInDesktop = {
        float: default_url == "/en/" ? "left" : "right", "margin": 0, position: "absolute", top: "50%", msTransform: "translateY(-50%)", transform: "translateY(-50%)", right: default_url === "/en/" ? "" : "20px", left: default_url === "/en/" ? "20px" : ""
    }
    let allImagesInMob = {
        float: default_url == "/en/" ? "left" : "right", "margin": 0
    }
    return (

        <div className="productViewContainer">
            {showBackdrop()}
            {showBuy1Get1Free && <Buy1Get1Free open={showBuy1Get1Free} quantity={quantity} handleClose={() => {setShowBuy1Get1Free(false)}} items={freeItemsList} next={() => addItem(data[index]._id, quantity, window.location.href, 0, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, showSuccess())} />}
            <Helmet>
                <title>{data.length > 0 ? default_url == "/en/" ? data[index].enName : data[index].arName : "Royal House"}</title>
            </Helmet>
            {
                data.length > 0 &&
                <div className="productViewImageContainer">
                    {
                        data.length > 0 && quantity == 0 && <span style={outOfStockStyle}>{dict["out_of_stock"]}</span>
                    }
                    <div className="productViewCurrentImage" style={{ float: default_url == "/en/" ? "right" : "left" }}>
                        <img className="productViewCurrentImg" src={IMAGE_URL + data[index].images[image] + "_big.jpg"} />
                    </div>
                    <div className="productViewAllImages" style={isMobile ? allImagesInMob : allImagesInDesktop}>
                        <ProductViewAllImages
                            imgs={data[index].images}
                            setImage={index => {
                                changeImage(index);
                            }}
                            current={index}
                        />
                    </div>
                </div>
            }

            <div className="productViewInfoContainer">
                {
                    data.length > 0 &&
                    <ProductViewTitle
                        title1={default_url == "/en/" ? data[index].enName : data[index].arName}
                        title2={data[index].brand}
                        stars={["blackStar", "blackStar", "blackStar", "blackStar", "blackStar"]}
                        availability={quantity > 0 ? "inStock" : "outOfStock"}
                        availabilityState={quantity > 0 ? dict["in_stock"] : dict["out_of_stock"]}
                        sku={data[index].sku}
                        totRate={totRate}
                    />
                }

                <div className="productViewInfoBreakLine"></div>
                {
                    data.length > 0 &&
                    <ProductViewInfoList
                        list={default_url == "/en/" ? data[index].enDescription : data[index].arDescription}
                    />
                }
                {
                    sizes.length > 1 &&
                    <ProductViewSizeOptions
                        sizes={sizes}
                        index={index}
                        changeIndex={index => {
                            changeIndex(index);
                        }}
                    />
                }
                {
                    data.length > 0 &&
                    <div style={{ textAlign: "start", marginTop: sizes.length <= 1 ? "15px" : "" }}>
                        <span>{dict["quantity"]}: </span>
                        {quantity == 0 && <NumericInput disabled min={minQuatity} max={maxQuatity} value={quantity} onChange={changeQuantity} />}
                        {quantity > 0 && <NumericInput strict={true} inputmode="numeric" pattern="[0-9]*" min={minQuatity} max={maxQuatity} value={quantity} onChange={changeQuantity} />}
                    </div>
                }
                {
                    data.length > 0 &&
                    <ProductViewPrice
                        price={data[index].price}
                        haveDiscount={data[index].discountPrice != null}
                        discountPrice={data[index].discountPrice}
                    />
                }
                <div style={{ textAlign: "start" }}>
                    {quantity > 0 && <button className="productViewAddToCart" onClick={handleAddToCart}>{dict["add_to_cart"]}</button>}
                    {quantity == 0 && <button className="productViewAddToCart outOfStockButton" onClick={handleAddToCart} disabled >{dict["add_to_cart"]}</button>}
                </div>

                <div style={{ marginTop: "10px", textAlign: "start" }}>
                    <button className="productViewAddTo" onClick={handleAddToWishlist}>
                        <FavoriteIcon style={{ position: "absolute" }} />
                        <span className="productViewAddToLabel">{dict["wishlist"]}</span>
                    </button>
                    <button className="productViewAddTo" onClick={handleAddToCompare}>
                        <AssignmentTurnedInIcon style={{ position: "absolute" }} />
                        <span className="productViewAddToLabel">{dict["compare"]}</span>
                    </button>
                    {/* <Button className="productViewAddTo" onClick={handleShare}>
                        <ShareIcon style={{ position: "absolute" }} />
                        <span className="productViewAddToLabel">{dict["share"]}</span>

                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem >{dict["my_account"]}</MenuItem>
                        <MenuItem>{dict['signout']}</MenuItem>
                    </Menu> */}
                    <div style={{display: "inline-block"}}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <ShareIcon style={{ position: "" }} />
                            <span className="">{dict["share"]}</span>
                            
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >   
                            <MenuItem onClick={handleCopy}>{dict['copy_link']}</MenuItem>
                            <MenuItem onClick={handleClose}>
                                <WhatsappShareButton
                                url={window.location.href}
                                quote={'Check this!'}
                                >Whatsapp</WhatsappShareButton>
                            </MenuItem>
                            
                            <MenuItem onClick={handleClose}>
                                <PinterestShareButton
                                url={window.location.href}
                                quote={'Check this!'}
                                >Pinterest</PinterestShareButton>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductViewContainer;