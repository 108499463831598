import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { ArRoute, EnRoute } from "./LangRoute"
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

export let dir = "LTR";
export let default_url = "/en/";

export const setDir = (value) => {
  dir = value;
  if (value == "ltr") {
    default_url = "/en/"
  } else {
    default_url = "/ar/"
  }
}

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/ar/" component={ArRoute} />
        <Route path="/en/" component={EnRoute} />
        <Route path="/" exact render={() => <Redirect to={{ pathname: "/ar/" }} />} />
        <Route path="*" render={() => <h1>Not Found!</h1>} />
      </Switch>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('root')
);