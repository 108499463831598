import React, {useState, useEffect} from "react";
import { isAuthenticated } from "../../../../auth"
import { deleteCategory, updateManySubcategories } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import * as qs from 'query-string';
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";

function DeleteCategory(props) {
    const [state, setState] = useState({
        deleted: false,
        error: "",
        redirect: false
    })
    const { user, token } = isAuthenticated();
    const parsed = qs.parse(window.location.search);
    useEffect(() => {
        async function fetchData() {
            if (parsed.category_id) {
              await deleteCategory(parsed.category_id, user._id, token)
                    .then(data => {
                        if (data.error) {        
                            setState({...state, error: data.error, redirect: true})
                        }else{
                            setState({...state, error: undefined, deleted: true, redirect: false})
                        }
                    })
            } else {
                setState({error: dict["no_category_is_found"], redirect: true})
            }
        }
        fetchData();
    }, []);

    const updateSubcategory = () => {
        updateManySubcategories("category", parsed.category_id, null, user._id, token)
        .then(data => {
            if(data.error){
                setState({...state, error: data.error, redirect: true, deleted: false})
            }else{
                setState({...state, error: undefined, deleted: false, redirect: true})
            }
        })
    }

    return (
        <div>
            {state.deleted && updateSubcategory()}
            {state.redirect && <Redirect to={{ pathname: default_url + 'admin/categories', state: { prevPath: "delete", error: state.error } }} />}
        </div>
    )

}

export default DeleteCategory;