import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { isAuthenticated } from "../../../../auth"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import { listGiftItems, listGiftTypes } from "../../apiAdmin";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    ExpansionPanelDetails: {
        display: "inline",
        padding: "0"
    },
    panelHeader: {
        backgroundColor: "#f7f7f7"
    }
}));

let count = 0;
let count2 = 0;

const GiftItems = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [seletedGiftType, setSelectedGiftType] = useState(null)
    const [giftTypes, setGiftTypes] = useState([]);
    const [data, setData] = useState([{}]);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: ""
    });
    const [show, setShow] = useState(false);

    const { user, token } = isAuthenticated();

    const handleChangePanel = (panel, giftType) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (isExpanded) {
            setSelectedGiftType(giftType);
        } else {
            setSelectedGiftType(null);
        }
    };

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }

    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        window.location.href = (default_url + "admin/gift-items/delete?gift_item_id=" + deletedItem.id)
    }

    useEffect(() => {
        async function fetchData() {
            await listGiftItems(user._id, token)
                .then(res => {
                    setData(res);
                })
        }
        fetchData();

        async function getGiftTypes() {
            await listGiftTypes(user._id, token)
                .then(res => {
                    setGiftTypes(res);
                })
        }
        getGiftTypes();
    }, []);

    const clickSubmit = (event) => {
        event.preventDefault();
        window.location.href = default_url + "admin/gift-items/create"
    }

    const showAlert = () => {
        if (props.location.state) {
            let msg = ""
            switch (props.location.state.prevPath) {
                case "create": msg = props.location.state.error ? props.location.state.error : dict["new_gift_itme_has_been_created"]; break;
                case "delete": msg = props.location.state.error ? props.location.state.error : dict["gift_item_has_been_deleted"]; break;
                case "edit": msg = props.location.state.error ? props.location.state.error : dict["gift_item_has_been_updated"]; break;
                default: msg = "";
            }
            return (
                <div
                    className={"alert " + ((props.location.state && props.location.state.error) ? "alert-danger" : "alert-info") + " alert-dismissible fade show"}
                    role="alert"
                    style={{ textAlign: "start" }}
                >
                    <h2>{msg}</h2>
                    <button type="button" class="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_gift_item"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + "?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد حذف " + deletedItem.name + "؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const allGiftItems = () => {
        return (
            data && giftTypes &&
            <div className={classes.root}>
                {
                    giftTypes.sort((a, b) => a.value - b.value).map((item, index) => {
                        count = data.filter(giftItem => {
                            return giftItem.giftType && giftItem.giftType._id == item._id
                        }).length;
                        count2 = data.filter(giftItem => {
                            return giftItem.giftType == null
                        }).length;
                        return (
                            <ExpansionPanel
                                key={index}
                                expanded={expanded === ("panel" + (index + 1))}
                                onChange={handleChangePanel(("panel" + (index + 1)), item._id)}
                            >
                                <ExpansionPanelSummary
                                    className={classes.panelHeader}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading} style={{ textAlign: "start" }}>{item.name}</Typography>
                                    {
                                        default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count + " gifts"}</Typography>
                                    }
                                    {
                                        default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count + " هدايا"}</Typography>
                                    }
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                                    {
                                        data.sort((a, b) => a.title - b.title).map((item2, index2) => {
                                            if (item2.giftType && item2.giftType._id == item._id) {
                                                return (
                                                    <div key={index2} className={"dataContainer " + (index2 === data.length - 1 ? "lastDataContainer" : "")}>
                                                        <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.title}</span>
                                                        <a className="dataContainerAction"
                                                            href="#"
                                                            onClick={handleShow}
                                                            name={item2.title}
                                                            id={item2._id}
                                                            style={{ float: default_url == "/ar/" ? "left" : "right" }}
                                                        >{dict["delete"]}</a>
                                                        {popUp()}
                                                        <a className="dataContainerAction" href={default_url + "admin/gift-items/edit?gift_item_id=" + item2._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>{dict["edit"]}</a>
                                                        <br></br>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                }
                <ExpansionPanel
                    expanded={expanded === ("panel" + (giftTypes.length + 1))}
                    onChange={handleChangePanel(("panel" + (giftTypes.length + 1)))}
                >
                    <ExpansionPanelSummary
                        className={classes.panelHeader}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"panel" + (giftTypes.length + 1) + "bh-content"}
                        id={"panel" + (giftTypes.length + 1) + "bh-header"}
                    >
                        <Typography className={classes.heading} style={{ textAlign: "start" }}>Others | اخرى</Typography>
                        {
                            default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count2 + " gifts"}</Typography>
                        }
                        {
                            default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count2 + " هدايا"}</Typography>
                        }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                        {
                            data.sort((a, b) => a.title - b.title).map((item2, index2) => {
                                if (item2.giftType == null) {
                                    return (
                                        <div key={index2} className={"dataContainer " + (index2 === data.length - 1 ? "lastDataContainer" : "")}>
                                            <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.title}</span>
                                            <a className="dataContainerAction"
                                                href="#"
                                                onClick={handleShow}
                                                name={item2.title}
                                                id={item2._id}
                                                style={{ float: default_url == "/ar/" ? "left" : "right" }}
                                            >Delete</a>
                                            {popUp()}
                                            <a className="dataContainerAction" href={default_url + "admin/gift-items/edit?gift_item_id=" + item2._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>Edit</a>
                                            <br></br>
                                        </div>
                                    )
                                }
                            })
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["gifts"]}</h3>
                {allGiftItems()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_gift"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="333"
            data={form}
        />
    )
}

export default GiftItems;