import React, { useState, useEffect, Fragment } from "react";
import ProductsMenu from "../components/product components/product list/ProductsMenu"
import { dict } from "../dictionary"
import { listProductsByFilters } from "./apiCore";
import Slider from '@bit/akiran.react-slick.slider';
import { default_url } from "..";
import ProductItem from "./product components/product list/ProductItem";
import { IMAGE_URL } from "../config";

function RelatedItems(props) {
    const [products, setProducts] = useState([]);
    const [products3, setProducts3] = useState([]);
    const [filters, setFilters] = useState(10);
    const [skip, setSkip] = useState(1);
    const [limit, setLimit] = useState(6);
    const [allLoaded, setAllLoaded] = useState(false);
    const [hideRelated, setHideRelated] = useState(false);
    const silderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const loadRelated = () => {
        loadProducts({ sku: [...props.related], hidden: false });
        setFilters({ sku: [...props.related], hidden: false })
    }

    const loadProducts = (filters) => {
        listProductsByFilters(0, 1000000, filters).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setProducts(res.data);
                let arr = [];
                let found = false
                for (let i = 0; i < res.data.length; i++) {
                    found = false;
                    for (let j = 0; j < arr.length; j++) {
                        if (res.data[i].enTitle == arr[j].enTitle) {
                            arr[j]._id.push(res.data[i]._id);
                            arr[j].price.push(res.data[i].price);
                            arr[j].discountPrice.push(res.data[i].discountPrice);
                            arr[j].size.push(res.data[i].size);
                            arr[j].images.push([]);
                            for (let k = 0; k < res.data[i].images.length; k++) {
                                arr[j].images[arr[j].images.length - 1].push(res.data[i].images[k]);
                            }
                            found = true
                            break;
                        }
                    }
                    if (!found) {
                        arr.push({ ...res.data[i], _id: [res.data[i]._id], price: [res.data[i].price], discountPrice: [res.data[i].discountPrice], size: res.data[i].size ? [res.data[i].size] : [], images: [[]] });
                        for (let m = 0; m < res.data[i].images.length; m++) {
                            arr[arr.length - 1].images[0].push(res.data[i].images[m]);
                        }
                    }
                }
                let arr2 = [];
                for (let i = 0; i < arr.length; i++) {
                    arr2.push({ ...arr[i] });
                    let at = arr2.length - 1;
                    let sizes = [...arr2[at].size];
                    let newIds = [...arr2[at]._id]
                    let newSizes = [...arr2[at].size]
                    let newPrices = [...arr2[at].price]
                    let newDiscountPrices = [...arr2[at].discountPrice]
                    let newImages = [...arr2[at].images]
                    for (let j = 0; j < sizes.length; j++) {
                        let cnt = 0;
                        for (let k = 0; k < sizes.length; k++) {
                            if (j == k) continue;
                            if (sizes[j] < sizes[k]) cnt += 1;
                        }
                        newIds[(sizes.length - cnt - 1)] = arr2[at]._id[j];
                        newSizes[(sizes.length - cnt - 1)] = sizes[j];
                        newPrices[(sizes.length - cnt - 1)] = arr2[at].price[j];
                        newDiscountPrices[(sizes.length - cnt - 1)] = arr2[at].discountPrice[j];
                        newImages[(sizes.length - cnt - 1)] = arr2[at].images[j];

                    }
                    arr2[at]._id = [...newIds];
                    arr2[at].size = [...newSizes];
                    arr2[at].price = [...newPrices];
                    arr2[at].discountPrice = [...newDiscountPrices];
                    arr2[at].images = [...newImages];
                }
                setProducts3(adjustProducts2(arr2));
                if(arr2.length == 0){
                    setHideRelated(true);
                }
            }
        })
    }

    const adjustProducts2 = (products => {
        let items3 = [...products]
        for (let i = 0; i < items3.length; i++) {
            let minPrice = 10000, maxPrice = -1000;
            for (let j = 0; items3[i].price && j < items3[i].price.length; j++) {
                minPrice = Math.min(minPrice, items3[i].price[j]);
                maxPrice = Math.max(maxPrice, items3[i].price[j]);
            }

            let a = []
            for (let j = 0; items3[i].price && j < items3[i].price.length; j++) {
                if (items3[i].discountPrice[j]) {
                    a.push(items3[i].discountPrice[j])
                } else {
                    a.push(items3[i].price[j]);
                }
            }
            let b = a.sort((a, b) => {
                return a - b
            });
            let minPriceToShow = b[0];
            let maxPriceToShow = b[b.length - 1];
            let discount = false;
            if (minPrice > minPriceToShow || maxPrice > maxPriceToShow) {
                discount = true;
            }

            items3[i] = { ...items3[i], minPrice, maxPrice, discount, minPriceToShow, maxPriceToShow }

            let image1 = null, image2 = null;

            for (let j = 0; items3[i].images && j < items3[i].images.length; j++) {
                for (let k = 0; k < items3[i].images[j].length; k++) {
                    let tmp = items3[i].images[j][k].split("/");
                    items3[i].images[j][k] = IMAGE_URL + tmp[tmp.length - 1] + "_small.jpg";
                    if (image1 == null && k == 0) {
                        image1 = items3[i].images[j][k];
                    }
                    if (image2 == null && k == items3[i].images[j].length - 1) {
                        image2 = items3[i].images[j][k];
                    }
                }
            }
            items3[i] = { ...items3[i], image1, image2, link: default_url + "products/" + (items3[i].category && items3[i].category.enName) + "/" + (items3[i].subcategory && items3[i].subcategory.enName ? items3[i].subcategory.enName : items3[i].category.enName) + "/" + items3[i].enTitle }
        }
        return items3;
    })

    useEffect(() => {
        loadRelated();
    }, [])

    const loadMore = () => {
        setSkip(skip + 1);
    }

    const doneLoadMore = () => {
        setAllLoaded(true);
    }

    const cssstyle = `
.silderContainer {
  margin: 0 0 40px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  background: white;
}
.silderDiv {
    #background: black;
    color: #fff;
    font-size: 36px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
.slick-dots{
    direction: rtl;
}
`

    if(!hideRelated){
        return (
            <div className={"container-fluid offers-container"} style={{ marginBottom: allLoaded ? "50px" : "" }}>
            <h5 className="productsContainerTitle" style={{ textAlign: "start" }}>{dict["you_may_like"]}</h5>
            <div className="container-fluid break-line"></div>
            <div className="silderContainer" style={{paddingTop: "20px"}}>
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                <style>{cssstyle}</style>
                
                <Slider {...silderSettings}>
                    {
                        products3.map((item, index) => {
                            return (
                                <div>
                                    <div className="silderDiv">

                                        <ProductItem
                                            key={index}
                                            divClass={""}
                                            title={default_url == "/en/" ? item.enTitle : item.arTitle}
                                            discount={item.discount}
                                            prices={item.price}
                                            discountPrices={item.discountPrice}
                                            model={""}
                                            img1={item.image1}
                                            img2={item.image2}
                                            link={item.link}
                                            minPrice={item.minPrice}
                                            maxPrice={item.maxPrice}
                                            minPriceToShow={item.minPriceToShow}
                                            maxPriceToShow={item.maxPriceToShow}
                                            size={item.size}
                                            ids={item._id}
                                            images={item.images}
                                            quantity={item.quantity}
                                        />

                                        {/* <ProductItem
                                            key={index}
                                            divClass=""
                                            title={default_url == "/en/" ? item.enTitle : item.arTitle}
                                            discount={item.discountPrice}
                                            prices={[item.price]}
                                            discountPrices={[item.discountPrice]}
                                            model={""}
                                            img1={getImages()[0]}
                                            img2={getImages()[1]}
                                            link={item.link}
                                        /> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>

            </div>

            {/* <ProductsMenu
        divClass={"col-lg-2 col-md-4 col-6"}
        items={products}
        limit={limit}
        skip={skip}
        doneLoadMore={() => {doneLoadMore()}}
    />
    {
        !allLoaded && 
        <div className="show-more-container" style={{marginBottom: "50px"}}>
            <button className="show-more-btn" name="button" onClick={loadMore}>
                <i className="fas fa-chevron-circle-down show-more-icon"></i>
                {dict["show_more"]}
            </button>
        </div>
    } */}
        </div>
        )
    }



    // if(!hideRelated){
    //     return (
    //         <div className={"container-fluid related-container"} style={{ marginBottom: allLoaded ? "70px" : "" }}>
    //             <h5 className="productsContainerTitle" style={{ textAlign: "start" }}>{dict["you_may_like"]}</h5>
    //             <div className="container-fluid break-line"></div>
    //             <ProductsMenu
    //                 divClass={"col-lg-2 col-md-4 col-6"}
    //                 items={products}
    //                 items3={products3}
    //                 limit={limit}
    //                 skip={skip}
    //                 doneLoadMore={() => { doneLoadMore() }}
    //             />
    //             {
    //                 !allLoaded &&
    //                 <div className="show-more-container" style={{ marginBottom: "20px" }}>
    //                     <button className="show-more-btn" name="button" onClick={loadMore}>
    //                         <i className="fas fa-chevron-circle-down show-more-icon"></i>
    //                         {dict["show_more"]}
    //                     </button>
    //                 </div>
    //             }
    //         </div>
    //     )
    // }

    return(
        <Fragment></Fragment>
    )
}

export default RelatedItems;