import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { deleteGift, listGifts } from "../../apiAdmin"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


function GiftsAdmin() {
    const [data, setData] = useState([]);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState(false);

    const { user, token } = isAuthenticated();

    const loadGifts = () => {
        listGifts(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setData(res);
            }
        });
    }

    useEffect(() => {
        loadGifts();
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        deleteGift(deletedItem.id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadGifts();
                setDeletedItem({ ...deletedItem, success: true });
            }
        })
    }

    const clickSubmit = () => {
        window.location.href = default_url + "admin/gifts/add"
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_coupon"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete the Gift" + deletedItem.name }</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد حذف الهدية " + deletedItem.name }</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAlert = () => {
        if (deletedItem.success) {
            return (
                <div
                    className={"alert alert-info alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{dict["gift_has_been_deleted"]}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const allGifts = () => {
        return (
            data.map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right" }}>{index + 1}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "500px"}}>{dict['gift_name'] +": " + item.name}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "150px" }}>{dict['quantity'] + ": " + item.quantity}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "300px" }}>{dict['minimum_cart'] + ": " + item.minimumCart}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "150px" }}>{dict['active'] + ": " + (item.active ? dict['yes'] : dict['no'])}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.code}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left" }}
                        >{dict["delete"]}</a>
                        {popUp()}
                        <a className="dataContainerAction" href={default_url + "admin/gifts/edit?gift_id=" + item._id}  style={{float: default_url == "/en/" ? "right": "left"}}>{dict["edit"]}</a>
                    </div>
                )
            })
        )
    }

    const showEmpty = () => {
        return (
            <h1 style={{ textAlign: "start" }}>{dict["no_gifts_found"]}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["gifts_list"]}</h3>
                {data.length > 0 ? allGifts() : showEmpty()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_gift"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="12"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default GiftsAdmin;