import React from 'react'

function ProductViewInfoList(props) {
    return (
        <div className="ProductViewInfoList">
            <ul>
                {
                    props.list.map((item, index) => {
                        return(
                            <li className="ProductViewInfoListItem" key={index} style={{textAlign: 'start'}}>{"- " + item}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default ProductViewInfoList;