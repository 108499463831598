import React from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"

function Delivery() {
    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900" }}>التوصيل</h1>
                <br />
                <p>
                    عميلنا العزيز، نحن في شركة رويال هاوس للتجارة العامة والمقاولات نؤمن أن خدمة العملاء هي حجر الاساس في عملنا كله. فنحن نبذل قصارى جهدنا لتجنب أي مشاكل قد تحدث أثناء تجربة التسوق معنا، ولهذا فنحن حريصين على أن تكون كل سياساتنا شفافة وبسيطة الفهم، وإذا كنت بحاجة إلى أي توضيح فلا تتردد في الاتصال بنا مباشرة.
                </p>

                <br />

                <h1 style={{ fontSize: "20px", fontWeight: "900" }}>سياسة التوصيل</h1>
                <br />
                <p>
                    توصل شركة رويال هاوس للتجارة العامة والمقاولات للبضاعة بنفسها، وستستخدم من حين لآخر شركات لوجستيين حسب الحاجة.
                <br />
                عند استلام البضاعة سنطلب منك نحن أو شريكنا اللوجستي توقيعك الإليكتروني أو الخطي على الفاتورة وهذا التوقيع يكون بمثابة تأكيد على أنك استلمت البضاعة كاملة كما هو مبين في الفاتورة.
                <br />
                في حالة عدم تواجدك بالعنوان الذي قمت بتحديده سنقوم نحن أو شريكنا اللوجستي بتسليم البضاعة لأى شخص متواجد بالعنوان المحدد من قبلكم مع توقيعه علي الفاتورة وبذلك يعتبر العميل قد استلم البضاعة المذكورة في الفاتورة.
                <br />
                إذا تم الوصول إلى العنوان المحدد من قبلكم ولم نستطع تسليم البضاعة يرجى الاتصال بخدمة العملاء لتحديد موعد آخر لتوصيل الطلب، والشركة غير مسؤولة عن أي بضاعة لم يتم توصيلها خلال 30 يوم من تاريخ الشراء.
                </p>
            </div>
        </div>
    )
}

export default Delivery;