import React from "react"

function DescriptionItem(props) {
    return (
        <div className="row descriptionRow">
            <div className="col-lg-4 col-6" style={{textAlign: "start"}}>
                <span className="descriptionRowTitle">{props.title}</span>
            </div>
            <div className="col-lg-4 col-6" style={{textAlign: "start"}}>
                <span className="descriptionRowInfo" >{props.info}</span>
            </div>
        </div>
    )
}

export default DescriptionItem;