import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { dict } from '../dictionary';
import { default_url } from '..';
import ProductViewAllImages from './product view comoinents/product view images/ProductViewAllImages';
import { getCompare, clearCompareList } from './compareHelpers';
import { addItem } from './cartHelpers';
import { Button } from '@material-ui/core';
import { store } from 'react-notifications-component';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IMAGE_URL } from '../config';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';

function Compare() {
    const [data, setData] = useState([]);
    const [image, setImage] = useState({
        image1: 0,
        image2: 0
    });
    const [alignDir, setAlignDir] = useState();
    const [cookies, setCookie] = useCookies(['_fbp']);
    let isMobile = window.innerWidth < 1024;

    useEffect(() => {
        loadCompare();
        if (default_url === "/en/") {
            setAlignDir("left")
        } else {
            setAlignDir("right")
        }
    }, [])

    const loadCompare = () => {
        setData(getCompare());
        console.log(getCompare());
    }

    const changeImage = (index, name) => {
        setImage({ ...image, [name]: index })
    }

    const handleAddToCart = i => event => {
        addItem(data[i], 1, data[i].link, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null);
        window.location.reload(false);
    }

    const handleClickOnClear = () => {
        store.addNotification({
            message: dict['compare_list_has_been_cleared'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showEmpty = () => {
        return <h5 style={{ textAlign: "start" }}>{dict['no_products_in_the_compare_list']}</h5>
    }

    const getWarranty = (i) => {
        if (default_url === "/en/") {
            if (data[i].enWarranty) {
                return data[i].enWarranty;
            }
        }
        return data[i].arWarranty;
    }

    const getMadeIn = (i) => {
        if (default_url === "/en/") {
            if (data[i].enMadeIn) {
                return data[i].enMadeIn;
            }
        }
        return data[i].arMadeIn;
    }

    const imageRow = (i) => {
        let img = "";
        if (i === 0) {
            img = IMAGE_URL + data[i].images[image.image1] + "_big.jpg";
        } else {
            img = IMAGE_URL + data[i].images[image.image2] + "_big.jpg"
        }
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                <div style={{ display: isMobile ? "block" : "inline-block" }}>
                    <div style={{ float: isMobile ? "" : default_url == "/en/" ? "right" : "left", width: isMobile ? "100%" : "63%" }}>
                        <img style={{ width: "100%", marginInlineStart: "2%", display: "inline-block" }} src={img} />
                    </div>
                    <div className="" style={{ width: isMobile ? "100%" : "33%", float: isMobile ? "" : default_url == "/en/" ? "left" : "right", marginTop: isMobile ? "10px" : "" }}>
                        <ProductViewAllImages
                            imgs={data[i].images}
                            setImage={(index) => {
                                changeImage(index, ("image" + (i + 1)));
                            }}
                            width={"100px"}
                            current={0}
                        />
                    </div>
                </div>
            </TableCell>
        )
    }

    const skuRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                {data[i].sku}
            </TableCell>
        )
    }

    const sizeRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                {data[i].size ? data[i].size : "-"}
            </TableCell>
        )
    }

    const priceRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                <span className={"price " + (data[i].discountPrice ? "discount" : "")}>{data[i].price + " " + dict['kd']}</span>
                <span className="afterDiscount" style={{ display: data[i].discountPrice ? "" : "none" }}>{data[i].discountPrice + " " + dict["kd"]}</span>
            </TableCell>
        )
    }

    const warrantyRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                {getWarranty(i) ? getWarranty(i) : "-"}
            </TableCell>
        )
    }

    const madeInRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                {getMadeIn(i) ? getMadeIn(i) : "-"}
            </TableCell>
        )
    }

    const viewProductRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                <a href={data[i].link} style={{ fontSize: "16px" }}>{dict["view_product"]}</a>
            </TableCell>
        )
    }

    const addToCartRow = (i) => {
        return (
            <TableCell align="center" style={{ width: "45%", borderInlineEnd: i === data.length - 1 ? "" : "1px solid black" }}>
                <Button onClick={handleAddToCart(i)}>{dict['add_to_cart']}</Button>
            </TableCell>
        )
    }

    var imgs = ["/img/thermos/rs-1010.jpg", "/img/thermos/rs-1010.jpg", "/images/RS-707-06-A01(1).jpg", "/img/thermos/rs-1010.jpg", "/img/thermos/rs-1010.jpg"]
    return (
        <div className="container-fluid" style={{ margin: "20px 0 50px" }}>
            <Helmet>
                <title>{default_url == "/en/" ? "Compare" : "المقارنة"}</title>
            </Helmet>
            {
                data.length === 0 ?
                    showEmpty()
                    :
                    <Fragment>
                        <Button
                            style={{ float: default_url === "/en/" ? "left" : "right" }}
                            onClick={() => {
                                clearCompareList();
                                loadCompare();
                                handleClickOnClear();
                            }}
                        >
                            {dict['remove_products_from_compare_list']}
                            <DeleteForeverIcon
                            />
                        </Button>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['product']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return imageRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['sku']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return skuRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['size']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return sizeRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['price']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return priceRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['warranty']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return warrantyRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['made_in']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return madeInRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['view_product']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return viewProductRow(i);
                                            })
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={alignDir} style={{ width: "10%", borderInlineEnd: "1px solid black" }}>{dict['add_to_cart']}</TableCell>
                                        {
                                            data.length > 0 && data.map((item, i) => {
                                                return addToCartRow(i);
                                            })
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Fragment>
            }
        </div>

    );
}

export default Compare;