import React, { useState, useEffect } from "react";
import { signin, authenticate, isAuthenticated, isAdmin } from "../auth"
import * as qs from 'query-string';
import { Redirect } from "react-router-dom";
import { dict } from "../dictionary";
import { default_url } from "../index"
import { store } from 'react-notifications-component';
import { forgetpassword } from "./user/apiUser";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function ForgetPassword(props) {
    const classes = useStyles();
    const [values, setValues] = useState({
        email: "",
        error: "",
        loading: false,
        success: false
    });

    let isMobile = window.innerWidth < 1024;

    useEffect(() => {

    }, [])

    const { email, error, success, loading } = values;

    const arDir = {
        direction: "rtl"
    }
    const enDir = {
        direction: "ltr"
    }

    const arStyleAlert = {
        textAlign: "right",
    }

    const enStyleAlert = {
        textAlign: "left",
    }

    const arStylelabel = {
        float: "right"
    }

    const enStylelabel = {
        float: "left"
    }

    const arStyleRequiredStart = {
        left: "35%",
        textaAlign: "end"
    }

    const enStyleRequiredStart = {
        right: "35%",
        textaAlign: "end"
    }

    const arStyleRequiredStartMob = {
        left: "5%",
    }

    const enStyleRequiredStartMob = {
        right: "5%",
    }

    const forgetPasswordForm = () => {
        return (
            <div>
                <h1 className="formContainerTitle" dir={default_url == "/en/" ? "ltr" : "rtl"}>{dict["forget_password?"]}</h1>
                <h6 className="requiredStar" style={ isMobile ? default_url === "/en/" ? enStyleRequiredStartMob : arStyleRequiredStartMob : default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <p dir={default_url == "/en/" ? "ltr" : "rtl"} style={{color: "gray", fontSize:"14px", textAlign:"start", marginBottom: "10px"}}>{dict["please_enter_your_email_address_below._you_will_receive_a_link_to_reset_your_password."]}</p>
                <form>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email_address"]} <span className="redStar">*</span></label>
                    <input dir="auto" onChange={handleChange("email")} className="inputField form-control" type="email" name="user-email" value={email} placeholder="email@example.com" required /><br />
                    <button onClick={clickSubmit} type="submit" className="loginBtn" style={{ marginBottom: "30px" }}>{dict["send"]}</button>
                </form>
            </div>
        )
    }

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({...values, loading: true})
        forgetpassword(email, default_url === "/en/" ? "en" : "ar").then(res => {
            setValues({...values, loading: false})
            if(res.error){
                setValues({...values, error: res.error});
                showNotification("err", res.error);
            }else{
                setValues({...values, email: "", error: "", success: true})
                showNotification("success", res.message);
            }
        })
    }

    const showNotification = (status, msg) => {
        store.addNotification({
            message: msg,
            type: status === "err" ? "danger" : "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    if (isAuthenticated()) {
        if (isAuthenticated().user.role === 1) {
            window.location.href = default_url + "admin/account-information"
        } else {
            window.location.href = default_url + "user/account-information"
        }

    }

    return (
        <div className="signinContainer">
            {showBackdrop()}
            {forgetPasswordForm()}
        </div>
    )
}

export default ForgetPassword;