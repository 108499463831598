import React, { useState, useEffect, Fragment } from "react";
import MobileMainBarItem from "./category components/MobileMainBarItem"
import CategoryItem from "./category components/CategoryItem"
import CategoryItemWithChildren from "./category components/CategoryItemWithChildren"
import { isAuthenticated } from "../auth"
import { listSubcategoriesByCategory2 } from "./apiCore"
import { from } from "form-data";
import Categories from "./admin/admin dashboard components/catogery/Categories";
import CategoriesMenu from "./category components/CategoriesMenu"
import Hide from "./category components/Hide"
import { default_url, setDir } from "../index"
import { dict } from "../dictionary"
import { IMAGE_URL } from "../config";
import TextField from '@material-ui/core/TextField';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import MainBarItem from "./main bar components/MainBarItem"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useParams } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        //marginBottom: "20px"
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Subcategory() {
    
    const classes = useStyles();
    const [subcategories, setSubcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    let { category } = useParams();
    if (!category) {
        window.location.href = default_url;
    }

    const loadSubcategories = async () => {
        setLoading(true);
        await listSubcategoriesByCategory2(category).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setSubcategories(data);
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            await loadSubcategories();
        }
        fetchData();
    }, [category]);

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const clickSubmit = (cat) => (e) => {
        e.preventDefault();
        window.location.href = default_url + "products/" + cat
    }


    return (
        <div className="container-fluid footerTagsContainer" style={{ padding: '20px 0 0' }} dir={default_url === "/en/" ? "ltr" : "rtl"} >
            {showBackdrop()}
            <div className={classes.root}>
                <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
                    {
                        subcategories.sort((a, b) => a.position - b.position).map((sub, i) => {
                            if (!sub.hidden) {
                                return (
                                    <Grid item xs={6} sm={4}>
                                        <Paper className={classes.paper}>
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <Link to={default_url + "products/" + (sub.category.enName).toLowerCase() + "/" + (sub.enName).toLowerCase()} style={{ display: "block", padding: window.innerWidth < 1024 ? "0" :"15px 15px 0", height: "100%" }}>
                                                    <img src={IMAGE_URL + sub.image + ".jpg"} width="100%" height="auto"  style={{ boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)', borderRadius: '5px',}} />
                                                </Link>
                                            </div>
                                        </Paper>
                                    </Grid>
                                )
                            }
                        })
                    }

                </Grid>

            </div>
            {
                subcategories && subcategories.length > 0 && <div style={{ width: "100%", textAlign: "center", padding: "0 22px" }}>
                    <button onClick={clickSubmit((subcategories[0].category.enName).toLowerCase())} type="submit" className="dashboardBtn" style={{fontSize: "18px", width: window.innerWidth < 1024 ? "100%" : "30%", marginBottom: "50px", marginTop: "20px" }}>{dict["show_all"]}</button>
                </div>
            }
        </div>
    )
}

export default Subcategory;