import React, { useState, useEffect, Fragment } from 'react';
import { default_url, dir } from "../index"
import { dict } from '../dictionary';
import NumericInput from 'react-numeric-input';
import { getCart, updateItem, removeItem, getItemQuantity, productHasFreeItem, removeFreeItemsByProduct, getFreeItemsIds, getFreeItems } from "./cartHelpers";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    makeStyles,
    createStyles,
} from "@material-ui/core/styles";
import { getGeneral, getCouponByCode, getGiftByAmount, listFreeItems, listFreeItemsById, listFreeItemsByParents, initiateCheckout } from './apiCore';
import { store } from 'react-notifications-component';
import { isAuthenticated } from '../auth';
import { Redirect } from 'react-router-dom';
import { IMAGE_URL } from "../config"
import { Helmet } from 'react-helmet';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Buy1Get1Free from './product view comoinents/Buy1Get1Free';
import { useCookies } from 'react-cookie';
import { sha256 } from 'js-sha256';

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: "#E4BC4B" // customized
                }
            }
        }
    })
);

function Cart(props) {
    const classes = useStyles();
    const classes2 = useStyles2();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [mouseOverRemove, setMouseOverRemove] = useState(-1);
    const [focused, setFocused] = useState(false);
    const [total, setTotal] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [amountForFreeDelivery, setAmountForFreeDelivery] = useState(0);
    const [subtotal, setSubTotal] = useState();
    const [discount, setDiscount] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [couponCode, setCouponCode] = useState("");
    const [couponDetails, setCouponDetails] = useState();
    const [freeDelivery, setFreeDelivery] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loadingForUpdate, setLoadingForUpdate] = useState(false);
    const [loadingForGetItems, setLoadingForGetItems] = useState(true);
    const [loadingForGifts, setLoadingForGifts] = useState(true);
    const [gift, setGift] = useState({ found: false, name: null, img: null })
    const [minimumCart, setMinimumCart] = useState(null);
    const [deliveryMessage, setDeliveryMessage] = useState(null);
    const [freeItemsList, setFreeItemsList] = useState([]);
    const [currentFreeItems, setCurrentFreeItems] = useState([]);
    const [showBuy1Get1Free, setShowBuy1Get1Free] = useState(false);
    const [showBuy1Get1Free2, setShowBuy1Get1Free2] = useState([]);
    const [freeItemsQuantity, setFreeItemsQuantity] = useState();
    const [missingFreeItems, setMissingFreeItems] = useState([]);
    const [src, setSrc] = useState();
    const [cookies, setCookie] = useCookies(['_fbp']);

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={loading1 || loading2 || loadingForUpdate || loadingForGetItems || loadingForGifts}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const loadFreeItems = () => {
        let freeItemIds = getFreeItemsIds();
        listFreeItemsById(freeItemIds).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let arr = [];
                for (let i = 0; i < freeItemIds.length; i++) {
                    for (let j = 0; j < res.length; j++) {
                        if (freeItemIds[i] == res[j]._id) {
                            arr.push({ ...res[j] });
                        }
                    }
                }
                setCurrentFreeItems([...arr]);
            }
        })
    }

    const loadData = () => {
        let disAmount = 0, deliveryTmp = delivery;
        setFreeDelivery(false);
        let tot = 0;
        data.map((p, i) => {
            tot += (p.count * (p.discountPrice ? p.discountPrice : p.price));
        });
        let totalTmp = ((tot * 1000) / 1000).toFixed(3);
        if (couponDetails) {
            if (couponDetails.type === "percentage") {
                disAmount = couponDetails.amount * (totalTmp / 100.0);
                disAmount = ((disAmount * 100) / 100).toFixed(3);
            } else if (couponDetails.type === "fixed_amount") {
                disAmount = Math.min(parseFloat(totalTmp), couponDetails.amount);
                //disAmount = (((couponDetails.amount) * 100) / 100).toFixed(3);
            } else {
                setFreeDelivery(true);
                deliveryTmp = 0.000;
            }
        }
        if (tot >= amountForFreeDelivery) {
            setFreeDelivery(true);
            deliveryTmp = 0.000;
        }
        let subtoatlTmp = tot + parseFloat(deliveryTmp);
        let grandTotalTmp = subtoatlTmp - disAmount;
        setTotal(fixLength(totalTmp));
        setSubTotal(fixLength(subtoatlTmp));
        setDiscount(fixLength(disAmount));
        setGrandTotal(fixLength(grandTotalTmp));
        loadGift(grandTotalTmp - parseFloat(deliveryTmp));
    }

    const loadDeliveryPrices = () => {
        getGeneral("name", "delivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setDelivery(fixLength(res.numberValue));
            }
            setLoading1(false)
        });
        getGeneral("name", "freeDelivery").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAmountForFreeDelivery(fixLength(res.numberValue));
            }
            setLoading2(false)
        });
    }

    const loadItems = async () => {
        setLoadingForGetItems(true);
        let tmp = await getCart();
        setData(tmp);
        let tot = 0;
        tmp.map((item, i) => {
            if (item.discountPrice && item.discountPrice > 0) {
                tot += (item.discountPrice * item.count);
            } else {
                tot += (item.price * item.count);
            }
        })
        await loadGift(tot);
        await checkForFreeItems(tmp);
        setLoadingForGetItems(false);
    }

    const checkForFreeItems = (products) => {
        let parents = [];
        products.map((p, i) => {
            parents.push(p.itemId);
        });
        listFreeItemsByParents(parents).then(async res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let items = [...res];
                let currentFreeItems = getFreeItems();
                let tmpParents = [];
                items.map((item, i) => {
                    tmpParents.push(item.parent);
                })
                tmpParents = [...new Set(tmpParents)];
                parents = [];
                for (let i = 0; i < tmpParents.length; i++) {
                    for (let j = 0; j < products.length; j++) {
                        if (tmpParents[i] == products[j].itemId) {
                            parents.push({ id: tmpParents[i], count: products[j].count });
                        }
                    }
                }
                let missing = []
                let show = []
                for (let i = 0; i < parents.length; i++) {
                    let c = 0;
                    for (let j = 0; j < currentFreeItems.length; j++) {
                        if (parents[i].id == currentFreeItems[j].parent) {
                            c++;
                        }
                    }
                    let items = await listFreeItems(parents[i].id).then(res => {
                        if (res.error) {
                            console.log(res.error)
                        } else {
                            return res;
                        }
                    });
                    if (parents[i].count > c) {
                        missing.push({ parent: parents[i].id, count: parents[i].count - c, show: true, items: items });
                        show.push(true);
                    }
                }
                setMissingFreeItems(missing)
                setShowBuy1Get1Free2(show)
            }
        })
    }

    const loadGift = (tot) => {
        setLoadingForGifts(true);
        getGiftByAmount(tot).then(res => {
            if (res.error) {

            } else {
                if (res.name) {
                    setGift({ found: true, name: res.name, id: res._id, image: res.image })
                } else {
                    setGift({ found: false, name: null, id: null, image: null })
                }
            }
            setLoadingForGifts(false);
        });
    }

    const loadMinimumCart = () => {
        getGeneral("name", "minimumCart").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setMinimumCart(fixLength(res.numberValue));
            }
        });
    }

    const loadDeliveryMessages = () => {
        if (default_url == "/en/") {
            getGeneral("name", "deliveryMessageInEnglish").then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setDeliveryMessage(res.stringValue);
                }
            });
        } else {
            getGeneral("name", "deliveryMessageInArabic").then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setDeliveryMessage(res.stringValue);
                }
            });
        }

    }

    useEffect(() => {
        loadMinimumCart();
        loadDeliveryMessages();
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        loadItems();
        loadDeliveryPrices();
    }, [data2])

    useEffect(() => {
        loadFreeItems();
    }, [showBuy1Get1Free2, data2])

    useEffect(() => {
        loadData();
    }, [data, freeDelivery, discount, delivery, amountForFreeDelivery]);



    const handleCouponChange = (event) => {
        setCouponCode(event.target.value);
        if (discount > 0) {
            setDiscount(0);
        }
        if (freeDelivery) {
            setFreeDelivery(false)
        }
    }

    const handleCouponSubmit = (event) => {
        event.preventDefault();
        if (!couponCode) {
            showError("please_enter_the_coupon_code")
        } else {
            getCouponByCode(couponCode).then(res => {
                if (res === null) {
                    showError("invalid_coupon");
                } else if (res.error) {
                    console.log(res.error);
                } else if (!res.active || (res.code).toLowerCase() !== couponCode.toLowerCase() || (res.limit && res.limit <= res.usedTimes)) {
                    showError("invalid_coupon");
                } else if (res.requiresSignin && !isAuthenticated()) {
                    showError("pleae_signin_to_use_the_coupon");
                } else {
                    showSuccess("valid_coupon");
                    setCouponDetails(res);
                    handleDiscount(res)
                }
            })
        }
    }

    const handleDiscount = ({ type, amount }) => {
        if (type === "free_delivery") {
            setFreeDelivery(true)
            setSubTotal((((total) * 100) / 100).toFixed(3));
            setGrandTotal((((total - discount) * 100) / 100).toFixed(3));
        } else if (type === "percentage") {
            let discountAmount = amount * (total / 100.0);
            discountAmount = ((discountAmount * 100) / 100).toFixed(3)
            setGrandTotal((((parseFloat(total) + (freeDelivery ? 0 : parseFloat(delivery)) - parseFloat(discountAmount)) * 100) / 100).toFixed(3));
            setDiscount(((parseFloat(discountAmount) * 100) / 100).toFixed(3));
        } else {
            setGrandTotal((((parseFloat(total) + (freeDelivery ? 0 : parseFloat(delivery)) - (Math.min(parseFloat(amount), parseFloat(total)))) * 100) / 100).toFixed(3));
            setDiscount((Math.min(parseFloat(amount), parseFloat(total)).toFixed(3)));
        }
    }

    const showError = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showSuccess = (msg) => {
        store.addNotification({
            message: dict[msg],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showMinimumCartMessage = (t) => {
        store.addNotification({
            message: dict['the_minimum_cart_limit_is'] + " " + minimumCart + " " + dict["kd"] + ". " + dict["your_cart_is"] + " " + fixLength(t) + " " + dict['kd'] + " " + dict['without_delivery'],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 10000,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const goToCheckout = () => {
        let t = total - (discount ? discount : 0);
        if (minimumCart && t < minimumCart) {
            showMinimumCartMessage(t);
        } else {
            let user = {};
            if (isAuthenticated()) {
                user = isAuthenticated().user;
                window.ttq.identify({
                    "email": sha256(user.email), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                    "external_id": sha256(user._id) // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
                });
            }
            let contents = []
            for (let i = 0; i < data.length; i++) {
                contents.push({
                    "content_type": "product",
                    "content_name": data[i].itemCode,
                    "quantity": data[i].count
                })
            }
            window.ttq.track('InitiateCheckout', {
                "contents": contents,
                "value": grandTotal, // number. Value of the order or items sold. Example: 100.
                "currency": "KWD" // string. The 4217 currency code. Example: "USD".
            });
            initiateCheckout({ ...user, amount: grandTotal, fbp: cookies._fbp ? cookies._fbp : null, fbc: cookies._fbc ? cookies._fbc : null }).then(res => {
                if (res.error) {
                    console.log(res.error);
                }
                setRedirect(true);
            })

        }

    }

    // const changeQuantity = (productId) => async (newValue) => {
    //     await getItemQuantity(productId).then(limit => {
    //         setData2(updateItem(productId, newValue > limit ? limit : newValue));
    //     });
    // }

    const changeQuantity = (productId, quantity, count) => async (newValue) => {
        setLoadingForUpdate(true);
        if (newValue) {
            setData2(await updateItem(productId, newValue > 0 ? newValue : 1));
            let tmpValue = Math.max(newValue, 1);
            let pass = productHasFreeItem(productId);
            if (tmpValue > count && pass) {
                listFreeItems(productId).then(res => {
                    if (res.error) {
                        console.log(res.error)
                    } else {
                        setFreeItemsList([...res]);
                        setFreeItemsQuantity(tmpValue - count);
                        setSrc("cart-add")
                        setShowBuy1Get1Free(true);
                    }
                });
            } else if (tmpValue < count && pass) {
                listFreeItems(productId).then(res => {
                    if (res.error) {
                        console.log(res.error)
                    } else {
                        removeFreeItemsByProduct(productId);
                        setFreeItemsList([...res]);
                        setFreeItemsQuantity(tmpValue);
                        setSrc("cart-remove")
                        setShowBuy1Get1Free(true);
                    }
                });
            }
        } else {
            setData2(await updateItem(productId, count));
        }
        setLoadingForUpdate(false);
    }

    const deleteItem = productId => event => {
        setData2(removeItem(productId));
    }

    const showItems = () => {
        return (
            data.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <a href={item.link}>
                                <img className="cart-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                            </a>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info">{default_url === "/en/" ? item.enName : item.arName}</h6>
                                    {item.color !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Color " : "اللون "} {item.color}</h6>}
                                    {item.size !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Size " : "حجم "} {item.size}</h6>}
                                    <h6 className="cart-item-info cart-item-info-price" style={{ textDecoration: item.discountPrice ? "line-through" : "" }}>{fixLength(item.price) + " " + dict['kd']}</h6>
                                    <h6 className="cart-item-info cart-item-info-price" style={{ display: item.discountPrice ? "" : "none", color: "red" }}>{fixLength(item.discountPrice) + " " + dict['kd']}</h6>
                                    <div className="cart-item-info-quantity" style={{ textAlign: "start" }}>
                                        <NumericInput
                                            min={1}
                                            max={1000}
                                            value={item.count}
                                            strict={true}
                                            inputmode="numeric"
                                            pattern="[0-9]*"
                                            onChange={changeQuantity(item._id, item.quantity, item.count)}
                                            style={{ width: "50px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-25px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ textDecoration: "underline", fontSize: "16px", cursor: mouseOverRemove === i ? "pointer" : "", color: mouseOverRemove === i ? "#E4BC4B" : "" }}
                                        onMouseOver={() => setMouseOverRemove(i)}
                                        onMouseOut={() => setMouseOverRemove(-1)}
                                        onClick={deleteItem(item._id)}
                                    >
                                        {dict["remove"]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const showGift = () => {
        return (
            <Fragment>
                <div className="cart-item">
                    <img className="cart-item-image" src={IMAGE_URL + gift.image + "_small.jpg"}></img>
                    <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                        <div className="cart-item-info-container">
                            <h6 className="cart-item-info">{gift.name}</h6>
                            <h6 className="cart-item-info cart-item-info-price" style={{ color: "red" }}>{dict["free_gift"]}</h6>
                        </div>
                    </div>
                </div>
                <hr />
            </Fragment>
        );
    }

    const showsFreeItems = () => {
        return (
            <Fragment>
                {
                    currentFreeItems.map((item) => {
                        return (
                            <>
                                <div className="cart-item">
                                    <img className="cart-item-image" src={IMAGE_URL + item.child.images[0] + "_small.jpg"}></img>
                                    <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                        <div className="cart-item-info-container">
                                            <h6 className="cart-item-info">{default_url == "/en/" ? item.child.enTitle : item.child.arTitle}</h6>
                                            <h6 className="cart-item-info cart-item-info-price" style={{ color: "red" }}>{dict["free_item"]}</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                            </>
                        )
                    })
                }
            </Fragment>
        );
    }

    const handleMissingFreeItems = () => {
        let tmp = [...missingFreeItems];
        return (
            tmp.map((item, i) => {
                return <Buy1Get1Free key={i} open={showBuy1Get1Free2[i]} quantity={item.count} handleClose={() => { let show2 = [...showBuy1Get1Free2]; show2[i] = false; setShowBuy1Get1Free2(show2) }} items={[...item.items]} next={() => { }} src="missing" />
            })

        )
    }

    const noItemsMsg = () => {
        return (<h2 style={{ textAlign: "start" }}>{dict['your_cart_is_empty'] + "."}</h2>);
    }

    const redirectUser = () => {
        if (couponDetails) {
            return <Redirect to={{ pathname: default_url + 'checkout', state: { coupon: couponDetails } }} />
        }
        return <Redirect to={{ pathname: default_url + 'checkout' }} />
    }

    const showSummary = () => {
        if (data.length === 0) {
            return null
        }
        return (
            <div>
                {showBackdrop()}
                {missingFreeItems && missingFreeItems.length > 0 && handleMissingFreeItems()}
                {/* {showBuy1Get1Free && <Buy1Get1Free open={showBuy1Get1Free} quantity={freeItemsQuantity} handleClose={() => { setShowBuy1Get1Free(false) }} items={freeItemsList} next={() => { }} src={src} />} */}
                <div style={{ height: "auto", display: "flex" }}>
                    <TextField
                        classes={{ root: classes.root }}
                        id="outlined-basic"
                        value={couponCode}
                        label={dict['have_a_promo_code?']}
                        variant="outlined"
                        style={{ float: default_url === "/en/" ? "left" : "right", textAlign: "left", width: "69%", height: "30px" }}
                        inputProps={{ style: { textAlign: "left", direction: "ltr", padding: "10px 6px" } }}
                        InputLabelProps={{ style: { fontSize: default_url === "/en/" ? "13px" : "16px", color: focused ? "#E4BC4B" : "", transform: focused || couponCode ? "translate(14px, -6px) scale(0.75)" : "translate(14px, 12px) scale(1)", backgroundColor: focused || couponCode ? "white" : "" } }}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onChange={handleCouponChange}
                    />
                    <Button
                        variant="contained"
                        style={{ fontSize: default_url === "/en/" ? "13px" : "16px", float: default_url === "/en/" ? "left" : "right", marginInlineStart: "2%", width: "29%", height: "38px", color: "white", backgroundColor: "#E4BC4B" }}
                        onClick={handleCouponSubmit}
                    >
                        {dict['apply']}
                    </Button>
                </div>
                <div style={{ display: "block", marginTop: "18px", color: "gray" }}>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "" : "right" }}>{dict["total"]}</div>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "right" : "" }}>{total + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", margin: "10px 0 5px", color: "gray" }}>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "" : "right" }}>{dict["delivery"]}</div>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "right" : "" }}>{((freeDelivery ? 0.000 : (delivery * 100) / 100).toFixed(3)) + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", margin: "0px 0 5px", color: "red", textAlign: "start" }}>
                    <div style={{ fontSize: "12px", display: "inline-block" }} >{deliveryMessage}</div>
                </div>
                {!freeDelivery && <div style={{ display: "block", margin: "15px 0 5px", color: "green", textAlign: "center", lineHeight: "26px", borderStyle: 'dashed', borderWidth: '3px', padding: '5px' }}>
                    <div style={{ fontSize: "16px", display: "inline-block" }} >{dict['add_products_for'] + " " + (((amountForFreeDelivery - total) * 100) / 100).toFixed(3) + " " + dict['kd'] + " " + dict["or_more_and_get_a_free_delivery"]}</div>
                </div>}
                <hr />
                <div style={{ display: "block", marginTop: "18px", color: "gray" }}>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "" : "right" }}>{dict["subtotal"]}</div>
                    <div style={{ fontSize: "14px", display: "inline-block", float: default_url === "/en/" ? "right" : "" }}>{subtotal + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", marginTop: "13px", color: "red" }}>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount && discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "" : "right" }}>{dict["promo_discount"]}</div>
                    <div style={{ fontSize: "12px", fontWeight: "lighter", display: discount && discount > 0 ? "inline-block" : "none", float: default_url === "/en/" ? "right" : "" }}>{discount + " " + dict['kd']}</div>
                </div>
                <div style={{ display: "block", margin: "10px 0 18px", color: "gray" }}>
                    <div style={{ fontSize: "18px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "" : "right" }}>{dict["grand_total"]}</div>
                    <div style={{ fontSize: "18px", fontWeight: "bold", display: "inline-block", float: default_url === "/en/" ? "right" : "" }}>{grandTotal + " " + dict['kd']}</div>
                </div>
                {/* <div style={{ display: "block", margin: "0px 0 5px", color: "red", textAlign: "start" }}>
                    <div style={{ fontSize: "12px", display: "inline-block" }} >{dict['dear_customers,_kindly_note_we_stopped_accepting_new_orders_for_better_service._coming_back_soon']}</div>
                </div> */}
                <Button
                    variant="contained"
                    onClick={goToCheckout}
                    //disabled
                    style={{ fontSize: default_url === "/en/" ? "13px" : "16px", float: window.innerWidth < 1024 ? "" : default_url === "/en/" ? "left" : "right", width: "100%", height: "38px", color: "white", backgroundColor: "#E4BC4B", marginBottom: "10px" }}
                //style={{ fontSize: default_url === "/en/" ? "13px" : "16px", float: window.innerWidth < 1024 ? "" : default_url === "/en/" ? "left" : "right", width: "100%", height: "38px", color: "white", backgroundColor: "gray", marginBottom: "10px" }}
                >
                    {dict["proceed_to_checkout"]}
                </Button>
            </div>
        )
    }

    return (
        <div className="container-fluid" style={{ margin: "15px 0 70px" }}>
            <Helmet>
                <title>{default_url == "/en/" ? "Your Cart" : "سلة المشتريات"}</title>
            </Helmet>
            <h1 style={{ textAlign: "start", fontSize: "18px" }}>{dict['cart']}</h1>
            <div style={{ display: window.innerWidth < 1024 ? "block" : "flex" }}>
                <div className="cart-items-container">
                    <h3 style={{ textAlign: "start" }}>{dict["items"]}</h3>
                    <hr />
                    {data.length > 0 && showItems()}
                    {gift.name && showGift()}
                    {currentFreeItems.length > 0 && showsFreeItems()}
                    {data.length === 0 && noItemsMsg()}
                </div>
                <div className="cart-total-container">
                    <h3 style={{ textAlign: "start" }}>{dict["order_summary"]}</h3>
                    <hr />
                    {showSummary()}
                </div>
            </div>
            {redirect && redirectUser()}

        </div>
    )
}

export default Cart;