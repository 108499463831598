import React from "react";
import { dict } from "../../dictionary";
import { default_url } from "../..";
import { isAuthenticated } from "../../auth";

function FooterInfo(props) {

    const getLink = (word) => {
        let ret = "";
        let arr = word.split("_");
        for (let i = 0; i < arr.length; i++) {
            if (i < arr.length - 1) {
                ret += (arr[i] + "-");
            } else {
                ret += (arr[i]);
            }

        }
        return (default_url + ret);
    }

    return (
        <div className="col-lg-2 col-md-4 col-6 info-section">
            <h6 className="info-title">{dict[props.title]}</h6>
            <ul className="info-list">
                {props.items.map((item, index) => {
                    return (
                        <div key={index}>
                            <li className="info-item">
                                {props.title === "payment_methods" ?
                                    <span className="info-span">
                                        <span>{dict[item]}</span>
                                    </span>
                                    :
                                    props.title === "my_account" ?
                                    <a className="info-link" href={isAuthenticated() ? ( default_url + (isAuthenticated().user.role === 0 ? "user/" : isAuthenticated().user.role === 1 ? "admin/" : "storage/") + item[1]) : (default_url + "signin")}>
                                        <span>{dict[item[0]]}</span>
                                    </a>
                                    :
                                    item === "contact_us" ?
                                    <a className="info-link" href={default_url + "contact-us"}>
                                        <span>{dict[item]}</span>
                                    </a>
                                    :
                                    <a className="info-link" href={getLink(item)}>
                                        <span>{dict[item]}</span>
                                    </a>
                                }
                            </li>
                            {props.title === "where_to_find_us" &&
                                <li className="info-item">
                                    <a className="info-link" href="#">
                                        <i className="fab fa-instagram fa-2x info-icon"></i>
                                    </a>
                                    <a className="info-link" href="#">
                                        <i className="fab fa-facebook-f fa-2x info-icon"></i>
                                    </a>
                                    <a className="info-link" href="#">
                                        <i className="fab fa-whatsapp fa-2x info-icon"></i>
                                    </a>
                                </li>}
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}

export default FooterInfo;