import { updateUserWishlist, listProductsByFilters } from "./apiCore";
import { isAuthenticated } from "../auth";

const updateWishlistOnDB = (wishlist) => {
    const { user, token } = isAuthenticated();
    updateUserWishlist(user._id, token, { wishlist: wishlist });
}

export const addToWishlist = (itemId, link) => {
    let wishlist = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('wishlist')) {
            wishlist = JSON.parse(localStorage.getItem("wishlist"));
        }
        let done = false;
        wishlist.length > 0 ?
        wishlist.map(p => {
            if (p.itemId !== itemId) {
                wishlist.push({
                    itemId,
                    link
                });
            }
        })
        :
        wishlist.push({
            itemId,
            link
        });
        let uniq = [...new Set(wishlist)];
        localStorage.setItem('wishlist', JSON.stringify(uniq));
        if (isAuthenticated()) {
            updateWishlistOnDB(wishlist)
        }
    }
}

export const numOfWishlistItems = () => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem('wishlist')) {
            return JSON.parse(localStorage.getItem('wishlist')).length;
        }
    }
    return 0;
}

export const getWishlist = () => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem('wishlist')) {
            return JSON.parse(localStorage.getItem('wishlist'));
        }
    }
    return [];
}


export const removeItem = (productId) => {
    let wishlist = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('wishlist')) {
            wishlist = JSON.parse(localStorage.getItem('wishlist'));
        }
        wishlist.map((p, i) => {
            if (p.itemId == productId) {
                wishlist.splice(i, 1);
            }
        })
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
        if (isAuthenticated()) {
            updateWishlistOnDB(wishlist)
        }
    }
    return wishlist;
}


export const getWishlistFromDB = (next) => {
    const { user } = isAuthenticated();
    if (user.wishlist) {
        let userWishlist = user.wishlist;
        
        let products = [];
        for (let i = 0; i < userWishlist.length; i++) {
            products.push(userWishlist[i].itemId);
        }
        let filters = { _id: products };
        listProductsByFilters(0, 1000000, filters).then(p => {
            if (p.error) {

            } else {
                let items = [];
                p.data.map((item, index) => {
                    for (let i = 0; i < userWishlist.length; i++) {
                        if (userWishlist[i].itemId === item._id) {
                            addToWishlist(item._id, userWishlist[i].link);
                            break;
                        }
                    }
                });
                next();
            }
        });
    }
}

export const clearWishlist = (next = () => { }) => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('wishlist');
    }
    if (isAuthenticated()) {
        updateWishlistOnDB([])
    }
    next();
}