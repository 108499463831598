import React, { useState, useEffect, Fragment } from 'react';
import ProductsNavLink from "./product components/ProductsNavLink"
import { BrowserRouter as Router, useParams } from "react-router-dom";
import ProductViewContainer from './product view comoinents/ProductViewContainer'
import ProductViewDescription from './product view comoinents/ProductViewDescription'
import ProductsMenu from './ProductsMenu';
import { default_url } from "../index";
import { listProductsByFilters, getCategoryDetails, getSubcategoryDetails } from "./apiCore"
import { dict } from '../dictionary';
import RelatedItems from './RelatedItems';

function ProductView() {
    let { category } = useParams();
    let { subcategory } = useParams();
    let { product } = useParams();
    const [data, setData] = useState([]);
    const [related, setRelated] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [index, setIndex] = useState(0);
    const [categoryDetails, setCategoryDetails] = useState();
    const [subcategoryDetails, setSubcategoryDetails] = useState();
    const [totRate, setTotRate] = useState(0);

    const loadProducts = () => {
        let filters = {
            enTitle: product,
            hidden: false
        }
        listProductsByFilters(0, 1000, filters).then(data => {
            if (data.error) {

            } else {
                let arr = data.data.sort((a, b) => a.price - b.price);
                let arr2 = [];
                let at = 0;
                arr.map((p, i) => {
                    if(p.quantity == 0 && i == at && i != arr.length - 1){
                        at += 1;
                    }
                    if (p.size != null) {
                        arr2.push(p.size);
                    }
                });
                setIndex(at);
                setData(arr);                
                setSizes(arr2);
                if(arr.length > 0){
                    setCategoryDetails(arr[0].category);
                    setSubcategoryDetails(arr[0].subcategory);
                    setRelated(arr[0].relatedProducts);
                }
            }
        })
    }

    const loadCategory = () => {
        getCategoryDetails("enName", category).then(data => {
            if(data.error){

            }else{
                setCategoryDetails(data);
            }
        });
    }
    const loadSubcategory = () => {
        if(category != subcategory){
            getSubcategoryDetails("enName", subcategory).then(data => {
                if(data.error){
                    
                }else{
                    setSubcategoryDetails(data);
                }
            })
        }
    }

    useEffect(() => {
        
    }, [])

    useEffect(() => {
        async function fetchData() {
            await loadProducts();
           // await loadCategory();
            //await loadSubcategory();
        }
        fetchData();
        window.scrollTo(0, 0);
    }, [product]);

    var ralatedItems = [
        {
            title: "Tea Thermos 1.4L",
            price: "6.500",
            img1: "/img/thermos/rs_707/rs_707_a06.jpg",
            img2: "/img/thermos/rs_707/RS-707-A13.jpg"
        },
        {
            title: "Tea Thermos 1.4L",
            price: "6.500",
            img1: "/img/thermos/RS-1111.jpg",
            img2: "/img/thermos/RS-1515.jpg"
        },
        {
            title: "Tea Thermos 1.4L",
            price: "6.500",
            img1: "/img/thermos/rs_707/rs_707_a06.jpg",
            img2: "/img/thermos/rs_707/RS-707-A13.jpg"
        },
        {
            title: "Tea Thermos 1.4L",
            price: "6.500",
            img1: "/img/thermos/rs_707/rs_707_a06.jpg",
            img2: "/img/thermos/rs_707/RS-707-A13.jpg"
        },
        {
            title: "Tea Thermos 1.4L",
            price: "6.500",
            img1: "/img/thermos/rs_707/RS-707-A09.jpg",
            img2: "/img/thermos/rs_707/RS-707-A10.jpg"
        },
        {
            title: "Tea Thermos 1.4L",
            price: "6.500",
            img1: "/img/thermos/rs_707_cf/rs_707_cf.jpg",
            img2: "/img/thermos/rs_707/RS-707-A13.jpg"
        }
    ]

    return (
        <div>
            <div className="productsNavLinkContainer">
            {
                categoryDetails && 
                <ProductsNavLink
                    title={dict["home"]}
                    slash="/"
                    link={default_url}
                />
            }
            {
                categoryDetails && 
                <ProductsNavLink
                    title={default_url == "/en/" ? categoryDetails.enName[0].toUpperCase() + categoryDetails.enName.substring(1, categoryDetails.enName.length) : categoryDetails.arName[0].toUpperCase() + categoryDetails.arName.substring(1, categoryDetails.arName.length)}
                    slash="/"
                    link={default_url + "products/" +  category + "/"}
                />
            }
            {
                subcategoryDetails &&
                <ProductsNavLink
                    title={default_url == "/en/" || !subcategoryDetails.arName ? subcategoryDetails.enName[0].toUpperCase() + subcategoryDetails.enName.substring(1, subcategoryDetails.enName.length) : subcategoryDetails.arName[0].toUpperCase() + subcategoryDetails.arName.substring(1, subcategoryDetails.arName.length)}
                    slash="/"
                    link={default_url + "products/" + category + "/" + subcategory + "/"}
                />
            }
            {
                data.length > 0 && 
                <ProductsNavLink
                    title={default_url == "/en/" ? data[0].enTitle : data[0].arTitle}
                />
            }
                
            </div>
            {
                data.length > 0 &&
                <Fragment>
                    <ProductViewContainer
                        data={data}
                        sizes={sizes}
                        index={index}
                        setIndex={index => {
                            setIndex(index);
                        }}
                        totRate={totRate}
                    />
                    <ProductViewDescription
                        data={data}
                        index={index}
                        getTotRate={rate => {
                            setTotRate(rate)
                        }}
                    />
                    {
                        related.length > 0 && 
                        <RelatedItems
                        related={[...related]}
                    />
                    }
                    
                </Fragment>
            }
        </div>
    )
}

export default ProductView;