import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { updateColor } from "../../apiAdmin"
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import * as qs from 'query-string';
import { getColor } from "../../../apiCore";
import { Link } from "react-router-dom";

function UpdateColor() {
    const [values, setValues] = useState({
        _id: "",
        number: "",
        arName: "",
        enName: "",
        hexCode: "",
        error: "",
        success: false,
    });

    const { user, token } = isAuthenticated();
    const { _id, number, arName, enName, hexCode, error, success } = values;
    const parsed = qs.parse(window.location.search);
    const colorId = parsed.color_id;
    if(!colorId){
        window.location.href = default_url + "admin/colors";
    }

    const loadColor = () => {
        getColor(colorId).then(res => {
            if(res.error){
                window.location.href = default_url + "admin/colors";
            }else{
                console.log(res);
                if(!res){
                    window.location.href = default_url + "admin/colors";
                }
                setValues({_id: res._id, number: res.number, arName: res.arName, enName: res.enName, hexCode: res.hexCode, error: null, success: false});
            }
        })
    }


    useEffect(() => {
        loadColor();
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let color = { _id: _id, number: number, arName: arName, enName: enName, hexCode: hexCode }
        updateColor(color, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ ...values, error: null, success: true });
            }
        })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['color_has_been_updated.']} <Link to={default_url + "admin/colors"} style={{ fontSize: "18px" }}>{dict["go_back_to_colors_list"]}</Link>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["edit_color"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_number']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("number")} type="text" value={number} className="inputField form-control" id="color_number"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_name_in_arabic']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="color_name_in_arabic"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_name_in_english']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("enName")}type="text" value={enName} className="inputField form-control" id="color_name_in_english"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_hex_code']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("hexCode")} type="text" value={hexCode} className="inputField form-control" id="color_hex_code"></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default UpdateColor;