import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { getTypeOptions, updateCoupon } from "../../apiAdmin"
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import * as qs from 'query-string';
import { getCoupon, listCities } from "../../../apiCore";
import CityCheckBox from "./CityCheckBox";

function UpdateCoupon() {
    const [values, setValues] = useState({
        _id: "",
        code: "",
        type: null,
        amount: "",
        limit: "",
        requiresSignin: null,
        active: null,
        cities: [],
        error: "",
        success: false,
    });
    const [citiesList, setCitiesList] = useState([]);
    const [allCities, setAllCities] = useState([]);

    const [typeOptions, setTypeOptions] = useState([]);

    const { user, token } = isAuthenticated();
    const { _id, code, type, amount, limit, requiresSignin, active, cities, error, success } = values;
    const parsed = qs.parse(window.location.search);
    const couponId = parsed.coupon_id;
    if(!couponId){
        window.location.href = default_url + "admin/coupons";
    }

    const loadCoupon = () => {
        getCoupon(couponId).then(res => {
            if(res.error){
                window.location.href = default_url + "admin/coupons";
            }else{
                if(!res){
                    window.location.href = default_url + "admin/coupons";
                }
                setValues({_id: res._id, code: res.code, type: res.type, amount: res.amount, limit: res.limit, requiresSignin: res.requiresSignin, active: res.active, cities: res.cities ? [...res.cities] : [], error: null, success: false});
                if(res.cities){
                    let arr = []
                    for (let i = 0; i < res.cities.length; i++) {
                        arr.push(res.cities[i]._id);
                    }
                    setCitiesList([...arr]);
                }
            }
        })
    }

    const loadTypeOptions = () => {
        getTypeOptions(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setTypeOptions(res);
            }
        })
    }

    const loadCities = () => {
        listCities().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAllCities(res);
            }
        })
    }

    useEffect(() => {
        loadCoupon();
        loadTypeOptions();
        loadCities();
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let coupon = {_id: _id, code: code, type: type, amount: amount, limit: limit, requiresSignin: requiresSignin, active: active, cities: citiesList }
        updateCoupon(coupon, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ ...values, error: null, success: true });
            }
        })
    }

    const typeSelector = () => {
        return (
            <select onChange={handleChange("type")} className="inputField form-control" value={type}>
                <option value={null}></option>
                {
                    typeOptions.map((t, index) => {
                        return (
                            <option key={index} value={t}>{dict[t]}</option>
                        )
                    })

                }
            </select>
        )
    }

    const requiresSigninSelector = () => {
        return (
            <select onChange={handleChange("requiresSignin")} className="inputField form-control" value={requiresSignin}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const activeSelector = () => {
        return (
            <select onChange={handleChange("active")} className="inputField form-control" value={active}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const addCities = () => {
        return (
            <form>
                {allCities.length >= 0 && <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["cities"]}</label>}<br />
                {
                    allCities.length > 0 &&
                    <CityCheckBox
                        cities={allCities}
                        setCheckedCities={list => {
                            setCheckedCities(list);
                        }}
                        citiesList={[...citiesList]}
                    />
                }
            </form>
        )
    }

    const setCheckedCities = (list) => {
        let newList = [];
        list.map(i => {
            newList.push(i);
        });
        setCitiesList(newList);
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['the_coupon_has_been_edited.']} <a href={default_url + "admin/coupons"} style={{ fontSize: "18px" }}>{dict["go_back_to_coupon_list"]}</a>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["edit_coupon"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["coupon_code"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("code")} type="text" value={code} className="inputField form-control" id="code" placeholder={dict["coupon_code"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["type"]} <span className="redStar">*</span></label>
                    {typeSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["amount"]}</label>
                    <input onChange={handleChange("amount")} type="number" value={amount} className="inputField form-control" id="amount" placeholder={dict["amount"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["requires_signin"]} <span className="redStar">*</span></label><br />
                    {requiresSigninSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["active"]} <span className="redStar">*</span></label><br />
                    {activeSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["limit_of_use"]}</label>
                    <input onChange={handleChange("limit")} type="number" value={limit} className="inputField form-control" id="limit" placeholder={dict["limit"]}></input>
                    {addCities()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="9"
            data={form}
        />
    )
}

export default UpdateCoupon;