import React, { useState, Fragment, useEffect } from 'react';
import DropDownFilter from "./DropDownFilter"
import SizeFilterBox from "../Filter/SizeFilterBox"
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { Button, Drawer, ListSubheader, Tab, Tabs, Paper, ListItemText, ListItemIcon, ListItem, Divider, List } from '@material-ui/core';
import { dict } from '../../../dictionary';
import Grid from '@material-ui/core/Grid';

const useStyles2 = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: 'auto',
    },
});

function valuetext(value) {
    return `${value}`;
}

function SortBy(props) {
    const params = new URLSearchParams(window.location.search);
    let order = params.get('order');
    let dir = params.get('dir');
    let price = params.get('price');
    let size = params.get('size');
    let color = params.get('color');
    const classes = useStyles();
    const classes2 = useStyles2();
    const [state, setState] = useState({
        filters: false,
        sortBy: false
    });
    const [value, setValue] = useState([0, 100]);
    const [activeTab, setActiveTab] = useState(0)
    const [filteredSize, setFilteredSize] = useState([]);
    const [filteredColor, setFilteredColor] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100);
    const [priceFliters, setPriceFilters] = useState([0, 100])

    // const filteredSizes = (sizes) => {
    //     let arr = [...filtered];
    //     for (let i = 0; i < sizes.length; i++) {
    //         if (arr.indexOf(sizes[i]) >= 0) {
    //             arr.splice(arr.indexOf(sizes[i]), 1);
    //         } else {
    //             arr.push(sizes[i]);
    //         }
    //     }
    //     // if (arr.indexOf(size) >= 0) {
    //     //     arr.splice(arr.indexOf(size), 1);
    //     // } else {
    //     //     arr.push(size);
    //     // }
    //     setFiltered(arr);
    //     let search = props.location.search.split("&");
    //     let res = "";
    //     if (search[0] == "") {
    //         res += ("?size=" + arr);
    //     } else {
    //         let done = false;
    //         for (let i = 0; i < search.length; i++) {
    //             if (search[i].includes("size=")) {
    //                 if (i === 0) {
    //                     res += "?"
    //                 }
    //                 res += ("size=" + arr + "&");
    //                 done = true;
    //             } else {
    //                 if (!search[i].includes('page=')) {
    //                     res += search[i] + "&";
    //                 } else if (search[i].includes("?page=")) {
    //                     res += "?";
    //                 }
    //             }
    //         }
    //         if (!done) {
    //             res += ("size=" + arr + "&");
    //         }
    //         res = res.substring(0, res.length - 1);
    //     }
    //     window.location.href = (res)
    // }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (!order || order == "recommended") {
            setActiveTab(0);
        } else {
            if (dir == "asc") {
                setActiveTab(1);
            } else {
                setActiveTab(2);
            }
        }
        if (price) {
            let prices = price.split("-");
            let prices2 = [parseInt(prices[0]), parseInt(prices[1])]
            setValue([...prices2]);
            setMinPrice(prices2[0]);
            setMaxPrice(prices2[1]);
            setPriceFilters([...prices2]);
        }
        if (size) {
            let sizes = size.split(",");
            setFilteredSize(sizes);
        }
        if (color) {
            let colors = color.split(",");
            setFilteredColor(colors);
        }
    }, [])

    const sort = (index) => {
        let newValue, newDir;
        if (index == 0) {
            newValue = "recommended"
        } else {
            newValue = 'price'
            if (index == 1) {
                newDir = 'asc'
            } else {
                newDir = 'desc'
            }
        }
        let search = props.location.search.split("&");
        let res = "";
        if (search[0] == "") {
            res += ("?order=" + newValue + "&");
            if (newValue == "price") {
                res += ("dir=" + newDir + "&");
            }
        } else {
            let doneOrder = false;
            let doneDir = false;
            for (let i = 0; i < search.length; i++) {
                if (search[i].includes("order=")) {
                    if (i === 0) {
                        res += "?"
                    }
                    res += ("order=" + newValue + "&");
                    doneOrder = true;
                } else if (search[i].includes("dir=")) {
                    if (newValue == "price") {
                        res += ("dir=" + newDir + "&");
                        doneDir = true;
                    }
                } else {
                    if (!search[i].includes('page=')) {
                        res += search[i] + "&";
                    } else if (search[i].includes("?page=")) {
                        res += "?";
                    }
                }
            }
            if (!doneOrder) {
                res += ("order=" + newValue + "&");
                if (newValue == "price") {
                    res += ("dir=" + newDir + "&");
                }
            }
            if (!doneDir && newDir) {
                res += ("dir=" + newDir + "&");
            }
        }
        res = res.substring(0, res.length - 1);
        window.location.href = (res)
    }

    const handleMobSortClick = (index) => event => {
        event.preventDefault();
        sort(index);
    }

    const handleCheckBox = s => event => {
        let arr = [...filteredSize];
        for (let i = 0; i < s.length; i++) {
            if (arr.indexOf(s[i]) >= 0) {
                arr.splice(arr.indexOf(s[i]), 1);
            } else {
                arr.push(s[i]);
            }
        }
        // if (arr.indexOf(name) === -1) {
        //     arr.push(name);
        // } else {
        //     arr.splice(arr.indexOf(name), 1);
        // }
        setFilteredSize(arr);
        let search = props.location.search.split("&");
        let res = "";
        if (search[0] == "") {
            res += ("?size=" + arr);
        } else {
            let done = false;
            for (let i = 0; i < search.length; i++) {
                if (search[i].includes("size=")) {
                    if (i === 0) {
                        res += "?"
                    }
                    res += ("size=" + arr + "&");
                    done = true;
                } else {
                    if (!search[i].includes('page=')) {
                        res += search[i] + "&";
                    } else if (search[i].includes("?page=")) {
                        res += "?";
                    }
                }
            }
            if (!done) {
                res += ("size=" + arr + "&");
            }
            res = res.substring(0, res.length - 1);
        }
        window.location.href = (res)
    }

    const checkFilteredSizes = s => {
        let ret = false;
        for (let i = 0; i < s.length; i++) {
            for (let j = 0; j < filteredSize.length; j++) {
                if(filteredSize.indexOf(s[i]) >= 0){
                    ret = true;
                }
            }
        }
        return ret;
    }

    const handleColorCheckBox = name => event => {
        let arr = [...filteredColor];
        if (arr.indexOf(name) === -1) {
            arr.push(name);
        } else {
            arr.splice(arr.indexOf(name), 1);
        }
        setFilteredColor(arr);
        let search = props.location.search.split("&");
        let res = "";
        if (search[0] == "") {
            res += ("?color=" + arr);
        } else {
            let done = false;
            for (let i = 0; i < search.length; i++) {
                if (search[i].includes("color=")) {
                    if (i === 0) {
                        res += "?"
                    }
                    res += ("color=" + arr + "&");
                    done = true;
                } else {
                    if (!search[i].includes('page=')) {
                        res += search[i] + "&";
                    } else if (search[i].includes("?page=")) {
                        res += "?";
                    }
                }
            }
            if (!done) {
                res += ("color=" + arr + "&");
            }
            res = res.substring(0, res.length - 1);
        }
        window.location.href = (res)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setMinPrice(newValue[0]);
        setMaxPrice(newValue[1]);
    };

    const handleTextChange = name => (event) => {
        let tmp = parseInt(event.target.value)
        if (name == 'max') {
            setMaxPrice(tmp);
            let newValue = value;
            newValue[1] = tmp;
            setValue(newValue);
        } else if (name == 'min') {
            setMinPrice(tmp);
            let newValue = value;
            newValue[0] = tmp;
            setValue(newValue);
        }
    };
    const handleMouseUp = () => {
        if (priceFliters.length == 0 || priceFliters[0] != minPrice || priceFliters[1] != maxPrice) {
            setPriceFilters([minPrice, maxPrice]);
            let search = props.location.search.split("&");
            let res = "";
            if (search[0] == "") {
                res += ("?price=" + minPrice + "-" + maxPrice);
            } else {
                let done = false;
                for (let i = 0; i < search.length; i++) {
                    if (search[i].includes("price=")) {
                        if (i === 0) {
                            res += "?"
                        }
                        res += ("price=" + minPrice + "-" + maxPrice + "&");
                        done = true;
                    } else {
                        if (!search[i].includes('page=')) {
                            res += search[i] + "&";
                        } else if (search[i].includes("?page=")) {
                            res += "?";
                        }
                    }
                }
                if (!done) {
                    res += ("price=" + minPrice + "-" + maxPrice + "&");
                }
                res = res.substring(0, res.length - 1);
            }
            window.location.href = (res)
        }
    }
    const handleFouceOut = () => {
        if (priceFliters.length == 0 || priceFliters[0] != minPrice || priceFliters[1] != maxPrice) {
            setPriceFilters([minPrice, maxPrice]);
            let search = props.location.search.split("&");
            let res = "";
            if (search[0] == "") {
                res += ("?price=" + minPrice + "-" + maxPrice);
            } else {
                let done = false;
                for (let i = 0; i < search.length; i++) {
                    if (search[i].includes("price=")) {
                        if (i === 0) {
                            res += "?"
                        }
                        res += ("price=" + minPrice + "-" + maxPrice + "&");
                        done = true;
                    } else {
                        res += search[i] + "&";
                    }
                }
                if (!done) {
                    res += ("price=" + minPrice + "-" + maxPrice + "&");
                }


                res = res.substring(0, res.length - 1);
            }
            window.location.href = (res)
        }
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onKeyDown={anchor == "sortBy" ? toggleDrawer(anchor, false) : () => { return }}
            onClick={anchor == "sortBy" ? toggleDrawer(anchor, false) : () => { return }}
        >
            {
                anchor == 'sortBy' ?
                    <List>
                        {
                            ['Recommended', 'Price Low to High', 'Price High to Low'].map((text, index) => {
                                let active;
                                if (index == 0) {
                                    active = (!order || order == "recommended");
                                } else if (index == 1) {
                                    active = (order == 'price' && dir == 'asc');
                                } else {
                                    active = (order == 'price' && dir == 'desc');
                                }
                                return (
                                    <ListItem button key={index} style={{ borderLeft: (active ? "5px solid #E4BC4B" : ""), color: active && "#E4BC4B" }} onClick={handleMobSortClick(index + "")}>
                                        <ListItemIcon>{index === 0 ? <NewReleasesIcon style={{ color: active && "#E4BC4B" }} /> : index === 1 ? <ArrowDownwardIcon style={{ color: active && "#E4BC4B" }} /> : <ArrowUpwardIcon style={{ color: active && "#E4BC4B" }} />}</ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItem>)
                            })
                        }
                    </List>
                    :
                    <Fragment>
                        {props.sizes && props.sizes.length > 0 && <List>
                            <ListSubheader>{dict["size"]}</ListSubheader>
                            {props.sizes.map((text, index) => (
                                <ListItem key={text.name} style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                                    <Fragment>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkFilteredSizes(text.sizes)}
                                                    onChange={handleCheckBox(text.sizes)}
                                                    color="primary"
                                                />
                                            }
                                            label={text.name}
                                        />
                                    </Fragment>
                                    <ListItemIcon></ListItemIcon>
                                </ListItem>
                            ))}
                        </List>}
                        {props.sizes && props.sizes.length > 0 && <Divider />}
                        {props.colors && props.colors.length > 0 && <List>
                            <ListSubheader>{dict["color"]}</ListSubheader>
                            <div className={classes2.root}>
                                <Grid container spacing={3}>
                                    {props.colors.map((text, index) => (
                                        <Grid item xs={6}>
                                            <Paper className={classes2.paper}>
                                                <ListItem key={text.number} style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                                                    <Fragment>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={filteredColor.indexOf(text.number) >= 0}
                                                                    onChange={handleColorCheckBox(text.number)}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={<div style={{ width: "50px", height: "25px", backgroundColor: text.hexCode, boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)' }}></div>}
                                                        />
                                                    </Fragment>
                                                    <ListItemIcon></ListItemIcon>
                                                </ListItem>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </List>}
                        {props.colors && props.colors.length > 0 && <Divider />}
                        <List>
                            <ListSubheader>{dict["price"]}</ListSubheader>
                            <div>
                                <div className="row" style={{ marginBottom: "15px" }}>
                                    <div className="col-6" style={{ padding: "0 35px" }}>
                                        <TextField
                                            id="outlined-number"
                                            label="Min"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            value={minPrice}
                                            onChange={handleTextChange("min")}
                                            onBlur={handleFouceOut}
                                        />
                                    </div>
                                    <div className="col-6" style={{ padding: "0 35px" }}>
                                        <TextField
                                            id="outlined-number"
                                            label="Max"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            value={maxPrice}
                                            onChange={handleTextChange("max")}
                                            onBlur={handleFouceOut}
                                        />
                                    </div>
                                </div>

                                <div className={classes.root} style={{ width: "80%" }} >
                                    <Slider
                                        value={value}
                                        min={0}
                                        max={20}
                                        onChange={handleChange}
                                        onMouseUp={handleMouseUp}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        getAriaValueText={valuetext}
                                        style={{
                                            color: "#E4BC4B",
                                            marginInlineStart: "10%"
                                        }}
                                    />
                                </div>
                            </div>
                        </List>
                    </Fragment>
            }

        </div>
    );

    const handleActiveTab = (event, newValue) => {
        setActiveTab(newValue);
        sort(newValue);
    };

    var sizes = [0.5, 0.6, "1.0", 1.2, 1.4, 1.5, 1.6];

    const activeTabStyle = {
        color: "white",
        backgroundColor: "#E4BC4B",
        fontSize: "13px",
        padding: "0 15px",
        minWidth: "0",
        minHeight: "0",
        textTransform: "none",
        height: "48px",
    }
    const inactiveTabStyle = {
        color: "black",
        fontSize: "13px",
        padding: "0 15px",
        minWidth: "0",
        minHeight: "0",
        textTransform: "none",
        height: "48px",
    }

    return (
        <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
            <div className="sortByContainer">
                <Paper square style={{ boxShadow: "none", marginTop: "10px", }}>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleActiveTab}
                        aria-label="disabled tabs example"
                        style={{
                            color: "white",
                            height: "25px",
                            fontSize: "12px",
                            textTransform: "none",
                            boxShadow: "none",

                        }}
                    >
                        <Tab label={dict["recommended"]} style={activeTab == 0 ? activeTabStyle : inactiveTabStyle} />
                        <Tab label={dict["price_low_to_high"]} style={activeTab == 1 ? activeTabStyle : inactiveTabStyle} />
                        <Tab label={dict["price_high_to_low"]} style={activeTab == 2 ? activeTabStyle : inactiveTabStyle} />
                    </Tabs>
                </Paper>

                <div className="sortByBreakLine"></div>

            </div>
            <div className='productsFilterMob'>
                <Fragment>
                    <Button
                        onClick={toggleDrawer("sortBy", true)}
                        style={{
                            backgroundColor: "#E4BC4B",
                            color: "white",
                            height: "25px",
                            fontSize: "12px",
                            textTransform: "none",
                        }}>
                        <ListItemIcon style={{ minWidth: "0", color: "white", marginInlineEnd: "4px" }}><SortIcon></SortIcon></ListItemIcon>
                        {dict["sort_by"]}</Button>
                    <Drawer anchor="left" open={state["sortBy"]} onClose={toggleDrawer("sortBy", false)} style={{ zIndex: 100000 }}>
                        {list("sortBy")}
                    </Drawer>
                </Fragment>
                <Fragment>
                    <Button
                        onClick={toggleDrawer("filters", true)}
                        style={{
                            backgroundColor: "#E4BC4B",
                            color: "white",
                            height: "25px",
                            fontSize: "12px",
                            textTransform: "none",
                            marginInlineStart: "10px"
                        }}>
                        <ListItemIcon style={{ minWidth: "0", color: "white", marginRight: "4px" }}><FilterListIcon></FilterListIcon></ListItemIcon>
                        {dict["filters"]}</Button>
                    <Drawer anchor="left" open={state["filters"]} style={{ zIndex: 100000 }}>
                        {list("filters")}
                        <CloseOutlinedIcon
                            style={{
                                right: "10px",
                                position: "absolute",
                                top: "20px",
                                zIndex: "10",
                            }}
                            onClick={toggleDrawer("filters", false)}
                        />
                    </Drawer>
                </Fragment>
            </div>

        </div>

    )
}

export default SortBy;