import React, { useState, useEffect } from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"
import { cities } from "../dictionary";
import { makeStyles } from '@material-ui/core/styles';
import { listBlog } from "./apiCore";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Fragment } from "react";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

function Blog() {
    const classes = useStyles();
    const [blog, setBlog] = useState([]);

    const loadBlog = () => {
        listBlog().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setBlog(res);
            }
        });
    }

    useEffect(() => {
        loadBlog();
    }, [])

    const clickSubmit = (link) => (event) => {
        event.preventDefault();
        window.location.href = link;
    }

    const cssstyle = `
    .blog-container .MuiGrid-spacing-xs-3{
        margin: 0;
        width: 100%
    }
    `

    return (
        <div className="container-fluid footerTagsContainer" style={{ padding: '20px 0 0' }} dir={default_url === "/en/" ? "ltr" : "rtl"} >
            <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center', marginBottom: "10px", }}>{dict['blog']}</h1>
            <h1 style={{ color: 'gray', fontSize: "16px", fontWeight: "500", textAlign: 'center', marginBottom: "20px" }}>{dict['new_articals_about_every_thing_related_to_tea_and_coffee']}</h1>
            <div className="footerTagsTextContainer blog-container" style={{ padding: '5px', marginBottom: "30px" }}>
                <div className={classes.root}>
                    <style>{cssstyle}</style>
                    <Grid container spacing={3} style={{ paddingTop: '30px' }}>
                        {
                            blog.length > 0 && blog.map((item, index) => {
                                return (
                                    <Grid container spacing={3} style={{ borderBottom: '1px solid rgba(128, 128, 128, .5)', marginBottom: '15px', padding: '0 10px' }}>
                                        <Grid item xs={12} sm={6}>
                                            <Paper className={classes.paper}>
                                                <img width="100%" src="https://royalhousekw.com/imgs/1919 colors web site.jpg"></img>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Paper className={classes.paper} style={{ textAlign: "start" }}>
                                                <div dir={default_url == "/en/" ? 'ltr' : 'rtl'} style={{ color: 'red', padding: '10px 0 20px', fontSize: '18px', fontWeight: '700' }}>
                                                    {item.title}
                                                </div>
                                                {item.body.split("\n").map((str, i) => {
                                                    return (
                                                        <div dir={default_url == "/en/" ? 'ltr' : 'rtl'} style={{ color: 'green', padding: '0 0', fontSize: '16px' }}>
                                                            {str}
                                                        </div>
                                                    )
                                                })}
                                            </Paper>
                                            <Paper className={classes.paper} style={{ textAlign: "start" }}>
                                                <button onClick={clickSubmit(item.link)} type="submit" className="dashboardBtn" style={{ width: '50%' }}>{item.linkTitle}</button>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            </div>


        </div>
    )
}

export default Blog;