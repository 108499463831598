import React from 'react'
import { dict } from '../../../dictionary';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

function ProductViewTitle(props) {
    return (
        <div className="productViewTitle" style={{ textAlign: "start" }}>
            <span className="productViewSubTitle1" style={{ textAlign: "start" }}>{props.title1}</span><br />
            <span className="productViewSubTitle2" style={{ textAlign: "start" }}>{dict["brand"]}: {props.title2} |
            <Box component="fieldset" borderColor="transparent" style={{display: "inline", position:"absolute", marginInlineStart:"4px"}}>
                    <Rating name="read-only" value={props.totRate} readOnly style={{fontSize:"18px"}} />
                </Box>
                {/* {
                    props.stars.map((star, index) => {
                        return (
                            <i key={index} className={"fas fa-star " + star}></i>
                        )
                    })
                } */}
            </span><br />
            <span className={"productViewAvailability " + props.availability}>{props.availabilityState}</span>
            <span className="productViewSKU">| {dict["sku"]}: {props.sku}</span>
        </div>
    )
}

export default ProductViewTitle;