import React, { useState } from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"
import { createFeedback } from "./apiCore"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { store } from 'react-notifications-component';
import { makeStyles } from '@material-ui/core/styles';
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Feedback() {
    const classes = useStyles();
    const [values, setValues] = useState({
        customerName: "",
        mobile: "",
        feedback: "",
        error: null,
        success: false
    });
    const [loading, setLoading] = useState(false);

    const { customerName, mobile, feedback, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        createFeedback({ customerName, mobile, feedback })
            .then(data => {
                setLoading(false);
                if (data.error) {
                    console.log(data.error);
                    setValues({ ...values, error: data.error, success: false });
                } else {
                    setValues({
                        customerName: "",
                        mobile: "",
                        feedback: "",
                        error: null,
                        success: true
                    });
                }
            })
    }

    const showError = () => {
        if (!error) {
            return;
        }
        store.addNotification({
            message: error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        })
        setValues({ ...values, error: "" });
    }

    const showSuccess = () => {
        if (!success) {
            return;
        }
        store.addNotification({
            message: "تم تقديم الاقتراح بنجاح، سيتم التواصل معكم في اقرب وقت",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                showIcon: true
            },
            className: "successNotification"
        })
        setValues({ ...values, success: false });
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const showFeedbackForm = () => {
        return (
            <form >
                {showError()}
                {showSuccess()}
                <h6 style={{ float: default_url === "/en/" ? "right" : "left", fontSize: "12px", color: "red", marginTop: "-5px" }}>{dict["required"]}</h6>
                <div>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("customerName")} type="text" value={customerName} className="inputField form-control" id="customer_name" placeholder={dict["name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("mobile")} type="text" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["feedback"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("feedback")} type="text" value={feedback} className="inputField form-control" id="feedback" placeholder={dict["feedback"]}></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["send_feedback"]}</button>
                </div>
            </form>

        )

    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            {showBackdrop()}
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center' }}>{dict['feedback']}</h1>
                <br />
                {showFeedbackForm()}
            </div>
        </div>
    )
}

export default Feedback;