import { listAreas, listCities } from "./components/apiCore"

export const ar_dic = {
    "shipping": "التوصيل",
    "terms_and_conditions": "الشروط والاحكام",
    "contact_us": "تواصل معنا",
    "search": "بحث",
    "lang": "EN",
    "my_account": "حسابي الشخصي",
    "compare": "مقارنة",
    "wishlist": "المفضلة",
    "cart": "سلة المشتريات",
    "categories": "الاقسام",
    "new_arrival": "وصل حديثاً",
    "show_more": "اظهار المزيد",
    "subscribe_newsletter": "اشترك بالنشرة الالكترونية",
    "information": "تعرف علينا",
    "services": "خدماتنا",
    "legal": "قانوني",
    "payment_methods": "طرق الدفع",
    "login": "تسجيل الدخول",
    "close": "اغلاق",
    "menu": "القائمة",
    "view_all": "اظهار الكل",
    "about_us": "من نحن",
    "faqs": "اسئلة شائعة",
    "delivery": "التوصيل",
    "return_and_exchange": "الترجيع والتبديل",
    "warranty": "الكفالة",
    "privacy_policy": "سياسة الخصوصية",
    "cash_on_delivery": "كاش عند التوصيل",
    "k_net": "كي نت",
    "visa_mastercard": "فيزا/ماستركارد",
    "my_wishlist": "مفضلتي",
    "my_address": "عناويني",
    "where_to_find_us": "اين تجدنا",
    "copyrights": "حقوق النشر",
    "royal_house": "رويال هاوس",
    "all_rights_reserved": "جميع الحقوق محفوظة",
    "copyright_2022_royal_house_all_rights_reserved": "حقوق النشر 2022 رويال هاوس. جميع الحقوق محفوظة",
    "add_to_cart": "اضف الى العربة",
    "login_or_create_an_account": "تسجيل الدخول او انشاء حساب جديد",
    "forget_password?": "نسيت كلمة المرور؟",
    "email": "البريد الالكتروني",
    "password": "كلمة المرور",
    "don't_have_an_account?_resister_now!": "ليس لديك حساب؟ سجل الآن!",
    "login_with_facebook": "التسجيل بإستخدام الفيس بوك",
    "login_with_google": "التسجيل بإستخدام جوجل",
    "login_signup": "تسجيل الدخول",
    "create_an_account": "انشاء حساب جديد",
    "required": "* مطلوب",
    "first_name": "الاسم الاول",
    "last_name": "الاسم الاخير",
    "email_address": "البريد الالكتروني",
    "confirm_password": "تأكيد كلمة المرور",
    "mobile": "موبايل",
    "sign_up_for_newsletter": "الاشتراك بالنشرة الالكترونية",
    "i_agree_to_the_terms_and_conditions": "اوافق على الشروط والاحكام",
    "create_account": "انشاء حساب",
    "have_an_account_?_login_now_!": "لديك حساب؟ سجل الدخول الآن!",
    "first_name_is_required": "الاسم الاول مطلوب",
    "last_name_is_required": "الاسم الاخير مطلوب",
    "email_must_be_between_4_to_32_characters": "البريد الالكتروني يجب ان يتكون من 4 الى 32 حرف",
    "email_must_contain_@": "البريد الالكتروني يجب ان يحتوي على @",
    "password_is_required": "كلمة المرور مطلوبة",
    "passwords_do_not_match": "كلمة المرور لا تتطابق مع تأكيد كلمة المرور",
    "password_must_contain_at_least_6_characters": "كلمة المرور يجب ان تحتوي على 6 احرف على الاقل",
    "password_must_contain_a_number": "كلمة المرور يجب ان تحتوي على رقم",
    "must_agree_on_terms_and_conditions": "يجب الموافقة علي الشروط والاحكام",
    "new_account_has_been_created_._please_signin": "تم انشاء حساب جديد. يرجى تسجيل الدخول",
    "email_doesn't_exist": "البريد الالكتروني غير مسجل",
    "email_and_password_don't_match": "البريد الالكتروني وكلمة المرور لا يتطابقان",
    "signout": "تسجيل الخروج",
    "account_information": "معلومات الحساب",
    "subcategories": "الاقسام الفرعية",
    "products": "المنتجات",
    "orders": "الطلبات",
    "offers": "العروض",
    "edit_account_information": "تعديل معلومات الحساب",
    "change_password": "تغيير كلمة المرور",
    "old_password": "كلمة المرور القديمة",
    "new_password": "كلمة المرور الجديدة",
    "confirm_new_password": "تأكيد كلمة المرور الجديدة",
    "categories_list": "قائمة الاقسام",
    "sort": "ترتيب",
    "delete": "حذف",
    "edit": "تعديل",
    "add_new_category": "اضافة قسم جديد",
    "deleting_category": "حذف قسم",
    "categories_have_been_sorted_successfully": "تم ترتيب الاقسام بنجاح",
    "new_category_has_been_created": "تم انشاء قسم جديد بنجاح",
    "category_has_been_deleted": "تم حذف القسم بنجاح",
    "category_has_been_updated": "تم تعديل القسم بنجاح",
    "no_category_is_found": "لم يتم ايجاد القسم المطلوب",
    "yes_._it_has_subcategory": "نعم، يوجد اقسام فرعية",
    "no_._it_hasn't_subcategory": "لا، لا يوجد اقسام فرعية",
    "yes_._it_is_hidden": "نعم، مخفي",
    "no_._it_isn't_hidden": "لا، غير مخفي",
    "category_english_name": "اسم القسم باللغة الانجليزية",
    "category_arabic_name": "اسم القسم باللغة العربية",
    "has_subcategory": "لديه اقسام فرعية",
    "hidden": "مخفي",
    "excel_file": "ملف اكسل",
    "save": "حفظ",
    "add_new_category": "اضافة قسم جديد",
    "add_one_category": "اضافة قسم واحد",
    "add_multiple_categories": "اضافة اقسام متعددة",
    "edit_category": "تعديل القسم",
    "english_name_is_required": "الاسم باللغة الانجليزية مطلوب",
    "arabic_name_is_required": "الاسم باللغة العربية مطلوب",
    "has_subcategory_is_required": "لديه اقسام فرعية مطلوب",
    "hidden_is_required": "مخفي مطلوب",
    "subcategories_have_been_sorted_successfully": "تم ترتيب الاقسام الفرعية بنجاح",
    "new_subcategory_has_been_created": "تم انشاء قسم فرعي جديد بنجاح",
    "subcategory_has_been_deleted": "تم حذف القسم الفرعي بنجاح",
    "subcategory_has_been_updated": "تم تعديل القسم الفرعي بنجاح",
    "deleting_subcategory": "حذف القسم الفرعي",
    "subcategories_list": "قائمة الاقسام الفرعية",
    "add_new_subcategory": "اضافة قسم فرعي جديد",
    "subcategory_english_name": "اسم القسم الفرعي باللغة الانجليزية",
    "subcategory_arabic_name": "اسم القسم الفرعي باللغة العربية",
    "category": "القسم الرئيسي",
    "image_1": "صورة 1",
    "image_2": "صورة 2",
    "category_is_required": "القسم الرئيسي مطلوب",
    "no_subcategory_is_found": "لم يتم ايجاد القسم الفرعي المطلوب",
    "edit_subcategory": "تعديل القسم الفرعي",
    "deleting_product": "حذف المنتج",
    "filterd_products": "المنتجات المفلترة",
    "no_products_found": "لا يوجد منتجات!",
    "new_product_has_been_created": "تم انشاء منتج جديد بنجاح",
    "product_has_been_deleted": "تم حذف المنتج بنجاح",
    "product_has_been_updated": "تم تعديل المنتج بنجاح",
    "products_list": "قائمة المنتجات",
    "add_new_product": "اضافة منتج جديد",
    "select_category": "اختر القسم",
    "select_subcategory": "اختر القسم الفرعي",
    "sku": "رقم المنتج",
    "item": "الصنف",
    "product_english_name": "الاسم باللغة الانجليزية",
    "product_arabic_name": "الاسم باللغة العربية",
    "english_description": "الوصف باللغة الانجليزية",
    "arabic_description": "الوصف باللغة العربية",
    "english_title": "العنوان الرئيسي باللغة الانجليزية",
    "arabic_title": "العنوان الرئيسي باللغة العربية",
    "subcategory": "القسم الفرعي",
    "brand": "الماركة",
    "price": "السعر",
    "quantity": "الكمية",
    "size": "الحجم",
    "color": "اللون",
    "warranty_(english)": "الكفالة (باللغة الانجليزية)",
    "warranty_(arabic)": "الكفالة (باللغة العربية)",
    "made_in_(english)": "صنع في (باللغة الانجليزية)",
    "made_in_(arabic)": "صنع في (باللغة العربية)",
    "related": "متعلق",
    "image": "صورة",
    "add_one_product": "اضافة منتج واحد",
    "add_multiple_products": "اضافة منتجات متعددة",
    "edit_product": "تعديل المنتج",
    "tea_thermos": "مطارات شاي",
    "coffee_thermos": "مطارات قهوة",
    "home": "الرئيسية",
    "max": "الى",
    "min": "من",
    "recommended": "الموصى به",
    "price_low_to_high": "السعر من اقل الى اعلى",
    "price_high_to_low": "السعر من اعلى الى اقل",
    "sort_by": "الترتيب حسب",
    "filters": "الفلتر",
    "kd": "د.ك",
    "you_may_like": "قد يعجبك",
    "in_stock": "متوفر",
    "out_of_stock": "غير متوفر",
    "description": "الوصف",
    "reviews": "التقييم",
    "made_in": "صنع في",
    "items": "المنتجات",
    "remove": "ازالة",
    "your_cart_is_empty": "سلة المشتريات فارغة",
    "order_summary": "ملخص الطلب",
    "have_a_promo_code?": "لديك كود خصم؟",
    "apply": "استخدام",
    "total": "المجموع",
    "subtotal": "المجموع الفرعي",
    "grand_total": "المجموع الكلي",
    "proceed_to_checkout": "اتمام عملية الشراء",
    "billing_address": "عنوان الفاتورة",
    "order_review": "مراجعة الطلب",
    "piece": "قطعة",
    "place_order": "اتمام الطلب",
    "checkout": "الدفع",
    "knet": "كي نت",
    "your_order_has_been_placed_please_keep_your_order_number": "تم الطلب بنجاح. يرجى الاحتفاظ برقم الطلب",
    "delivery_address": "عنوان التوصيل",
    "area": "المنطقة",
    "block": "القطعة",
    "street": "الشارع",
    "lane": "الجادة",
    "building": "المنزل",
    "floor": "الطابق",
    "apartment": "الشقة",
    "extra_details": "معلومات اضافية",
    "please_fill_the_required_fields": "يرجى تعبئة جميع الحقول المطلوبة",
    "don\'t_exist": "لا يوجد",
    "promo_discount": "كوبون خصم",
    "discount_price": "سعر الخصم",
    "your_order_number_is": "رقم طلبك هو",
    "orders_list": "قائمة الطلبات",
    "ads": "الاعلانات",
    "order_number": "رقم الطلب",
    "new_order": "طلب جديد",
    "processing": "تحت التجهيز",
    "ready_for_delivering": "تم التجهيز للتوصيل",
    "delivered": "تم التوصيل",
    "cancelled": "ملغي",
    "status": "الحالة",
    "order_on": "تم الطلب منذ",
    "order_status": "حالة الطلب",
    "coupons": "الكوبونات",
    "more": "المزيد",
    "new_arrival_list": "قائمة الوصل حديثاً",
    "activate": "تفعيل",
    "add_new_item_to_new_arrival": "اضافة منتج الى الوصل حديثاً",
    "product": "المنتج",
    "new_product_has_been_added_to_new_arrival.add_more_or": "تم اضافة المنتج لقائمة الوصل حديثاً. اضف المزيد او",
    "go_back_to_new_arrival_list": "ارجع لقائمة الوصل حديثاً",
    "please_select_a_product": "يرجى اختيار منتج",
    "view_product": "مشاهدة المنتج",
    "offers_list": "قائمة العروض",
    "add_new_item_to_offers": "اضافة منتج الى العروض",
    "new_product_has_been_added_to_offers.add_more_or": "تم اضافة منتج لقائمة العروض. اضف المزيد او",
    "go_back_to_offers_list": "ارجع لقائمة العروض",
    "ads_list": "قائمة الاعلانات",
    "no_ads_found": "لا يوجد اعلانات!",
    "add_new_ad": "اضافة اعلان جديد",
    "deleting_ad": "حذف اعلان",
    "upload_image": "اختر صورة",
    "please_upload_image": "يرجى اختيار صورة",
    "new_ad_has_been_added.add_more_or": "تم اضافة اعلان جديد. اضف المزيد او",
    "go_back_to_ads_list": "ارجع لقائمة الاعلانات",
    "coupons_list": "قائمة الكوبونات",
    "type": "النوع",
    "fixed_amount": "قيمة ثابتة",
    "percentage": "نسبة مئوية",
    "free_delivery": "توصيل مجاني",
    "coupon_code": "رمز الكوبون",
    "amount": "القيمة",
    "requires_signin": "يتطلب تسجيل الدخول",
    "how_often_used": "عدد مرات الاستخدام",
    "yes": "نعم",
    "no": "لا",
    "add_new_coupon": "اضافة كوبون جديد",
    "active": "فعال",
    "new_coupon_has_been_added.add_more_or": "تم اضافة كوبون جديد. اضف المزيد او",
    "go_back_to_coupon_list": "ارجع لقائمة الكوبونات",
    "deleting_coupon": "حذف الكوبون",
    "edit_coupon": "تعديل الكوبون",
    "the_coupon_has_been_edited.": "تم تعديل الكوبون.",
    "remove_products_from_compare_list": "ازالة المنتجات من قائمة المقارنة",
    "no_products_in_the_compare_list": "لا يوجد منتجات في قائمة المقارنة",
    "compare_list_has_been_cleared": "تم ازالة المنتجات من قائمة المقارنة",
    "can't_add_more_than_2_products_to_the_compare_list": "لا يمكن اضافة اكثر من منتجين اثنين الى قائمة المقارنة",
    "can't_add_to_products_from_different_categories_to_the_compare_list": "لا يمكن اضافة منتجات من اقسام مختلفة الى قائمة المقارنة",
    "this_product_is_already_in_the_compare_list": "هذا المنتج موجود في قائمة المقارنة",
    "the_product_has_been_added_to_the_compare_list": "تم اضافة المنتج الى قائمة المقارنة",
    "product_has_been_added_to_cart": "تم اضافة المنتج على سلة المشتريات",
    "wishlist_is_empty": "المفضلة فارغة",
    "product_has_been_added_to_wishlist": "تم اضافة المنتج الى المفضلة",
    "product_has_been_removed_from_wishlist": "تم ازالة المنتج من المفضلة",
    "address_book": "دفتر العناوين",
    "my_orders": "طلباتي",
    "my_reviews": "تقييماتي",
    "newsletter": "النشرة الالكترونية",
    "birth_date": "تاريخ الميلاد",
    "nationality": "الجنسية",
    "gender": "الجنس",
    "change_password": "تغيير كلمة المرور",
    "male": "ذكر",
    "female": "انثى",
    "old_password": "كلمة المرور القديمة",
    "new_password": "كلمة المرور الجديدة",
    "confirm_new_password": "تأكيد كلمة المرور الجديدة",
    "new_password_and_confirm_new_password_are_not_matched": "كلمة المرور الجديدة وتأكيد كلمة المرور غير متطابقين",
    "please_enter_your_old_password": "يرجى ادخال كلمة المرور القديمة",
    "please_enter_the_new_password": "يرجى ادخال كلمة المرور الجديدة",
    "profile_has_been_updated": "تم تعديل الملف الشخصي بنجاح",
    "old_password_is_not_correct": "كلمة المرور القديمة غير صحيحة",
    "add_new_address": "اضافة عنوان جديد",
    "mobile_must_contain_numbers_only": "رقم الموبايل يجب ان يحتوي ارقام فقط",
    "mobile_must_contain_8_digits_only": "رقم الموبايل يجب ان يكون 8 ارقام فقط",
    "area_is_required": "يرحى اختيار منطقة",
    "block_is_required": "القطعة مطلوبة",
    "street_is_required": "الشارع مطلوب",
    "building_is_required": "المنزل مطلوب",
    "mobile_is_required": "رقم الموبايل مطلوب",
    "new_address_has_been_added": "تم اضافة عنوان جديد",
    "edit_address": "تعديل العنوان",
    "address_has_been_updated": "تم تعديل العنوان",
    "address_has_been_deleted": "تم حذف العنوان",
    "no_orders_found": "لا يوجد طلبات سابقة",
    "how_satisfied_were_you_with_this_product?": "مدى رضاك عن المنتج؟",
    "your_comment": "تعليقك",
    "send_review": "ارسال التقييم",
    "add_your_review_now": "اضف تقييمك الان",
    "rate_is_required": "يرجى تقييم المنتج من 1 الى 5 نجوم",
    "please_signin_to_send_review": "يرجى تسجيل الدخول لتقييم المنتج",
    "your_review_has_been_added": "تم اضافة تقييمك",
    "review_has_been_deleted": "تم ازالة التقييم",
    "no_addresses_found": "لا يوجد عناوين سابقة",
    "no_reviews_found": "لا يوجد تقييمات سابقة",
    "more_settings": "المزيد من الاعدادت",
    "please_enter_the_coupon_code": "يرجى ادخال رمز الكوبون",
    "invalid_coupon": "كوبون غير صحيح",
    "valid_coupon": "كوبون صحيح. تم تفعيله",
    "pleae_signin_to_use_the_coupon": "يرجى تسجيل الدخول لاستخدام كوبون الخصم",
    "new_address": "عنوان جديد",
    "or_continue_as_guest": "او اكمل كزائر",
    "payment_declined_please_try_again_or_contact_us": "عملية الدفع مرفوضة. يرجى المحاولة مرة اخرى او تواصل معنا",
    "delivery_price_has_been_updated": "تم تعديل سعر التوصيل",
    "add_images": "اضافة صور",
    "images_have_been_uploaded": "تم اضافة الصور",
    "without_extension": "بدون صيغة الصورة",
    "failed": "عملية دفع فاشلة",
    "tags": "الكلمات المفتاحية",
    "payment_method": "طريقة الدفع",
    "cash_(not_paid)": "كاش (غير مدفوع)",
    "knet_(paid)": "كي نت (مدفوع)",
    "(three_working_daysـexcept_sabah_alahmad_7_working_days)": "(خلال 3 ايام عمل ماعدا صباح الاحمد 7 ايام عمل)",
    "by_clicking_“place_order”,_i_confirm_i_have_read_and_acknowledge_all_terms_and_policies.": "بالضغط على ”اتمام الطلب“ اقر انني قرأت واوافق على الشروط والاحكام.",
    "activate_account": "تفعيل الحساب",
    "click_on_the_button_to_activate_your_account": "انقر فوق الزر لتفعيل حسابك",
    "just_one_more_step": "خطوة اضافية اخرى",
    "your_acount_has_been_activated._please_signin.": "تم تفعيل حسابك بنجاح. يرجى تسجيل الدخول",
    "account_is_not_confirmed._please_check_your_email_to_confirm_your_account_or_contact_with_support_team.": "الحساب غير مفعل. يرجى التأكد من البريد الالكتروني لتفعيل الحساب او تواصل مع الدعم الفني.",
    "please_enter_your_email_address_below._you_will_receive_a_link_to_reset_your_password.": "يرجى إدخال عنوان بريدك الإلكتروني في الخانة أدناه ليتم إرسال رابط إعادة تعيين كلمة المرور.",
    "send": "ارسال",
    "free_delivery_for_odrders_greater_than": "التوصيل مجاني للطلبات الاكثر من",
    "free_delivery_for_selected_areas_for_odrders_greater_than": "التوصيل مجاني الى المناطق المحددة للطلبات الاكثر من",
    "delivery_for_selected_areas": "التوصيل للمناطق المحددة",
    "selected_areas": "المناطق المحددة",
    "add_area_to_the_selected_areas": "اضافة منطقة الى المناطق المحددة",
    "add": "اضافة",
    "updated_successfully": "تم التعديل بنجاح",
    "please_select_an_area": "يرجى اختيار منطقة",
    "city": "المحافطة",
    "select_city": "اختر المحافظة",
    "select_area": "اختر المنطقة",
    "users": "المستخدمين",
    "find_user": "البحث عن مستخدم",
    "no_user_found!": "المستخدم غير موجود",
    "user_is_active": "المستخدم فعال",
    "regular_user": "مستخدم عادي",
    "storage": "مخزن",
    "admin": "ادمن",
    "account_type": "نوع الحساب",
    "role_is_required": "نوع الحساب مطلوب",
    "something_went_wrong._try_again_later_or_contact_support": "حصل خطأ ما. يرجى المحاولة لاحقاً او تواصل من الدعم الفني",
    "user_has_been_updated": "تم تعديل الحساب",
    "you_have_subscribed_in_newsletter": "تم الاشتراك بالنشرة الالكترونية",
    "email_already_exists": "البريد الالكتروني مسجل",
    "your_cart_has_been_updated._please_review_your_cart_before_checking_out": "تم تحديث عربة التسوق الخاص بك. يرجى مراجعة عربة التسوق قبل اتمام الطلب",
    "please_enter_new_password": "يرجى ادخال كلمة المرور الجديدة",
    "new_password_and_confirm_new_password_are_not_matched": "كلمة المرور الجديدة وتأكيد كلمة المرور الجديدة لا يتطابقان",
    "the_token_is_incorrect_or_expired": "The token is incorrect or expired",
    "user_with_this_token_doesn't_exist": "المستخدم غير موجود",
    "password_has_been_changed": "تم تغيير كلمة المرور. يرجى تسجيل الدخول",
    "authentication_error!": "خطأ في التحقق",
    "update_products": "تعديل المنتجات",
    "products_have_been_updated": "تم تعديل المنتجات",
    "update": "تعديل",
    "waiting_for_online_payment": "بانتظار الدفع اونلاين",
    "knet_(not_paid)": "كي نت (غير مدفوع)",
    "all_statuses": "جميع الحالات",
    "link": "الرابط",
    "replace_current_products_in_new_arrival": "استبدال المنتجات الحالية في الوصل حديثا",
    "new_arrivals_products_have_been_replaced": "منتجات الوصل حديثاً تم اتستبدالها",
    "replace": "استبدال",
    "gifts": "الهدايا",
    "add_new_gift": "اضافة هدية جديدة",
    "gift_name": "اسم الهدية",
    "minimum_cart": "الحد الادنى لعربة التسوق",
    "new_gift_has_been_added.add_more_or": "تم اضافة هدية جديدة. اضف المزيد او",
    "go_back_to_gitfs_list": "ارجع لقائمة الهدايا",
    "gifts_list": "قائمة الهدايا",
    "no_gifts_found": "لا يوجد هدايا",
    "no_coupons_found": "لا يوجود كوبونات",
    "coupon_has_been_deleted": "تم حذف الكوبون",
    "gift_has_been_deleted": "تم حذف الهدية",
    "update_gift": "تعديل الهدية",
    "gift_has_been_updated": "تم تعديل الهدية",
    "free_gift": "هدية مجانية",
    "delete_multiple_products": "حذف منتجات متعددة",
    "products_have_been_deleted": "تم حذف المنتجات",
    "export_all_products": "تصدير جميع المنتجات",
    "offers_home_page": "الاسعار المميزة",
    "export_orders": "تصدير الطلبات",
    "export": "تصدير",
    "from_order_number": "من رقم الطلب",
    "to_order_number": "الى رقم الطلب",
    "orders_have_been_exported": "تم تصدير الطلبات",
    "replace_current_products_in_offers": "استبدل المنتجات الحالية في العروض",
    "offers_products_have_been_replaced": "تم استبدال المنتجات في العروض",
    "export_all_users": "تصدير جميع المستخدمين",
    "lists": "القوائم",
    "add_new_list": "اضف قائمة جديدة",
    "list_name_in_arabic": "اسم القائمة بالعربي",
    "list_name_in_english": "اسم القائمة بالانجليزي",
    "new_list_has_been_added.add_more_or": "تم اضافة قائمة جديدة. اضف المزيد او ",
    "go_back_to_lists": "ارجع للقوائم",
    "update_list": "تعديل القائمة",
    "order": "الترتيب",
    "show_current_products": "اظهار المنتجات الحالية",
    "add_more_products": "اضافة المزيد من المنتجات",
    "replace_current_products": "استبدال المنتجات الحالية",
    "list_has_been_edited": "تم تعديل القائمة",
    "list_has_been_deleted": "تم حذف القائمة بنجاح",
    "small": "صغير",
    "medium": "وسط",
    "large": "كبير",
    "add_products_for": "اضف منتجات بقيمة",
    "or_more_and_get_a_free_delivery": "او اكثر واحصل على توصيل مجاني",
    "announcement": "الاعلان",
    "please_select_the_size": "يرجى اختيار الحجم",
    "select_size": "اختر الحجم",
    "wholesale": "الجملة",
    "distributors": "الموزعين",
    "blog": "المدونة",
    "business_name": "اسم العمل",
    "owner_name": "اسم صاحب العمل",
    "business_type": "طبيعة العمل",
    "country": "مقر العمل (الدولة)",
    "website": "الموقع الالكتروني",
    "send_request": "ارسال الطلب",
    "wholesale_requests_list": "قائمة طلبات الجملة",
    "feedback": "الاقتراح",
    "feedbacks": "الاقتراحات",
    "send_feedback": "ارسال الاقتراح",
    "name": "الاسم",
    "customer_service": "خدمة العملاء",
    "rose_thermos": "ترامس روز",
    "royal_house_co": "رويال هاوس",
    "youtube_channel": "قناة اليوتيوب",
    "add_new_distributor": "اضف موزع جديد",
    "distributor_name": "اسم الموزع",
    "location": "اللوكيشن",
    "new_distributor_has_been_added.add_more_or": "تم اضافة موزع جديد. اضف المزيد او",
    "go_back_to_distributors_list": "ارجع لقائمة الموزعين",
    "deleting_distributor": "حذف الموزع",
    "distributor_has_been_deleted": "تم حذف الموزع بنجاح",
    "add_new_distributor": "اضف موزع جديد",
    "update_distributor": "تعديل الموزع",
    "find_a_store": "ابحث عن متجر",
    "please_select_an_area_to_locate_a_store": "يرجى اختيار المنطقة لمعرفة اقرب متجر",
    "add_to_blog": "اضف الى المدونة",
    "blog_is_empty": "المدونة فارغة",
    "title": "العنوان",
    "body": "المحتوى",
    "added_to_blog._add_more_or": "تمت الاضافة الى المدونة. اضف المزيد او",
    "go_back_to_blog": "ارجع للمدونة",
    'blog_has_been_updated.': "تم تعديل المدونة",
    "edit_blog": "تعديل المدونة",
    "deleting_from_blog": "الحذف من المدونة",
    "has_been_deleted_from_blog": "تم حذفها من المدونة",
    "new_articals_about_every_thing_related_to_tea_and_coffee": "احدث المقالات لكل مايتعلق بالشاي والقهوة",
    "manage_colors": "تحرير الالوان",
    "colors": "الالوان",
    "add_new_color": "اضف لون جديد",
    "no_colors_found": "لا يوجد الوان",
    "color_number": "رقم اللون",
    "color_has_been_deleted": "تم حذف اللون",
    'deleting_color': 'حذف اللون',
    "color_hex_code": "Hex code",
    "color_name_in_english": "اسم اللون بالانجليزي",
    "color_name_in_arabic": "اسم اللون بالعربي",
    "color_number": "رقم اللون",
    'new_color_has_been_added._add_more_or': 'تمت اضافة اللون الجديد. اضف المزيد او',
    'go_back_to_colors_list': 'ارجع لقائمة الالوان',
    "edit_color": "تعديل اللون",
    "color_has_been_updated": "تم تعديل اللون",
    "add_model_to_collection": "اضف موديل الى الاطقم",
    "accessories": "الاكسسوارات",
    "porcelain": "بورسلان",
    "new_model_has_been_added._add_more_or": "تمت اضافة الموديل الجديد. اضف المزيد او",
    "go_back_to_collections": "ارجع الى الاطقم",
    "model_name": "اسم الموديل",
    "thermos": "المطارات",
    "collections": "الاطقم",
    "add_to_collections": "اضف الى الاطقم",
    "no_collection_found": "لا يوجد اطقم",
    "model_has_been_deleted": "تم حذف الموديل",
    "deleting_model": "حذف الموديل",
    "edit_collection": "تعديل الطقم",
    "collection_has_been_updated.": "تم تعديل الطقم.",
    "get_your_collection_as_you_like": "اختر طقمك كما تحب",
    "choose_model": "اختر الموديل",
    "choose_color": "اختر اللون",
    "total_price": "اجمالي السعر",
    "add_collection_to_cart": "اضف الطقم الى العربة",
    "collection_has_been_added_to_cart": "تم اضافة الطقم للعربة",
    "yes_add_them_to_cart": "نعم، اضفهم الى العربة",
    "earned_points": "النقاط المكتسبة",
    "points": "النقاط",
    "my_points": "نقاطي",
    "no_points": "لا يوجد نقاط",
    "valid_until": "صالحة لتاريخ",
    "equal_to": "مايعادل",
    "total_points": "مجموع النقاط",
    "you_have": "لديك",
    "would_you_like_to_use_them?": "هل تود استخدامهم؟",
    "valid_to_use": "صالحة للاستخدام",
    "no_i_wouldn't_like_to_use_them": "لا، لا ارغب باستخدامهم",
    "yes_i_would_like_to_use_them": "نعم، ارغب باستخدامهم",
    "points_discount": "خصم النقاط",
    "you_don't_have_any_points": "لا يوجد لديك اي نقاط",
    "can_not_use_points_with_coupon_discount": "لا تستطيع استخدام النقاط مع كوبون الخصم",
    "paid_by_points": "تم الدفع بالنقاط",
    "paid_by_coupon": "تم الدفع بالكوبون",
    "free_checkout": "دفع مجاني",
    "used_points_from_it": "النقاط المستخدمة منها",
    "remaining_points_from_it": "النقاط المتبقية منها",
    "total_used_points": "مجموع النقاط المستخدمة",
    "total_remaining_points": "مجموع النقاط المتبقية",
    "earned_from_order_number": "اكتسبت من طلب رقم",
    "your_points_been_updated._please_review_your_points_before_checking_out": "نقاطك تغيرت. يرجى مراجعة النقاط الجديدة قبل اتمام عملية الشراء",
    "return_used_points": "ارجاع النقاط المستخدمة",
    "get_your_collection_now": "أطقم المطارات الكاملة",
    "ad_position": "موضع الاعلان",
    "please_enter_ad_position": "يرجى ادخال موضع الاعلان",
    "you_can't_use_points_with_coupons": "لا يمكن استخدام النقاط مع الكوبون",
    "category_image": "صورة القسم",
    "image_is_required": "الصورة مطلوبة",
    "show_all": "اظهار الكل",
    "model_image": "صورة الموديل",
    "preview_image": "صورة توضيحية",
    "link_title": "عنوان الرابط",
    "subcategory_image": "صورة القسم الفرعي",
    "points_program": "برنامج النقاط",
    "join_points_program": "انضم الآن الى برنامج النقاط",
    "please_agree_on_terms_and_conditions": "يرجى الموافقة عالشروط والاحكام",
    "user_has_been_deleted": "تم حذف المستخدم",
    "deleting_user": "حذف المستخدم",
    "Email already exists": "الايميل موجود بالفعل",
    "thermos_tags": "وسم المطارات",
    "porcelain_tags": "وسم البورسلان",
    "accessories_tags": "وسم الاكسسوارات",
    "minimumCart": "الحد الادنى لعربة التسوق",
    "limit_of_use": "حد الاستخدام",
    "please_check_your_mobile_number": "يرجى التأكد من رقم الموبايل",
    "replace_current_distributors": "استبدال الموزعين الحاليين",
    "distributors_have_been_replaced": "تم استبدال الموزعين",
    "the_minimum_cart_limit_is": "الحد الادنى لعربة التسوق",
    "without_delivery": "بدون التوصيل",
    "your_cart_is": "قيمة عربة التسوق لديك",
    "stop_delivery_for_areas": "ايقاف التوصيل للمناطق",
    "delivery_message_in_arabic": "رسالة التوصيل بالعربي",
    "delivery_message_in_english": "رسالة التوصيل بالانجليزي",
    "edit_delivery_address": "تعديل عنوان التوصيل",
    "cancel": "الغاء",
    "delivery_address_in_english": "عنوان التوصيل بالانجليزي",
    "delivery_address_in_arabic": "عنوان التوصيل بالعربي",
    "add_one_coupon": "اضف كوبون واحد",
    "add_multiple_coupons": "اضف كوبونات متعددة",
    "add_extra_points": "اضافة نقاط",
    "earned_as_a_gift": "اكتسبتهم كهدية",
    "cities_list": "قائمة المحافظات",
    "add_new_city": "اضف محافظة جديدة",
    "city_english_name": "اسم المحافظة بالانجليزي",
    "city_arabic_name": "اسم المحافظة بالعربي",
    "add_one_city": "اضف محافظة واحدة",
    "add_multiple_cities": "اضف محافظات متعددة",
    "new_city_has_been_created": "تم اضافة محافظة جديدة بنجاح",
    "edit_city": "تعديل المحافظة",
    "no_city_is_found": "لا يوجد محافظة",
    "city_has_been_deleted": "تم حذف المحافظة",
    "city_has_been_updated": "تم تعديل المحافظة",
    "deleting_city": "حذف المحافظة",
    "city_is_required": "المحافظة مطلوبة",
    "areas_list": "قائمة المناطق",
    "add_new_area": "اضف منطقة جديدة",
    "add_one_area": "اضف منقطة واحد",
    "add_multiple_areas": "اضف مناطق متعددة",
    "area_english_name": "اسم المنطقة بالانجليزي",
    "area_arabic_name": "اسم المنطقة بالعربي",
    "new_area_has_been_created": "تم اضافة منطقة جديدة",
    "area_has_been_deleted": "تم حذف المنطقة",
    "area_has_been_updated": "تم تعديل المنطقة",
    "deleting_area": "حذف المنطقة",
    "no_area_is_found": "لا يوجد منطقة",
    "edit_area": "تعديل المنطقة",
    "areas": "المناطق",
    "cities": "المحافظات",
    "add_new_gift_type": "اضافة فئة هدايا جديدة",
    "gift_type_name": "اسم الفئة",
    "gift_type_value": "قيمة الفئة",
    "number_of_gifts": "عدد الهدايا",
    "show_lower_types": "اظهار هدايا الفئات الاقل",
    "new_gift_type_has_been_created": "تم اضافة فئة جديدة",
    "gift_type_has_been_deleted": "تم حذف فئة الهدايا",
    "gift_type_has_been_updated": "تم تعديل الفئة",
    "gift_types": "فئات الهدايا",
    "deleting_gift_type": "حذف فئة الهدايا",
    "edit_gift_type": "تعديل الفئة",
    "no_gift_type_is_found": "لا يوجد فئة",
    "new_gift_item_has_been_created": "تم اضافة هدية",
    "gift_item_has_been_deleted": "تم حذف الهدية",
    "gift_item_has_been_updated": "تم تعديل الهدية",
    "deleting_gift_item": "حذف الهدية",
    "add_one_gift": "اضف هدية واحدة",
    "add_multiple_gifts": "اضف هدايا متعددة",
    "code": "الرمز",
    "edit_gift": "تعديل الهدية",
    "no_gift_is_found": "لا يوجد هدية",
    "gift_type": "فئة الهدية",
    "palce_order": "",
    "dear_customers,_kindly_note_we_stopped_accepting_new_orders_for_better_service._coming_back_soon": "زبائننا الكرام حرصاً منا على تقديم خدمة افضل، يرجى العلم انه تم ايقاف الطلب والتوصيل وذلك حتى اشعاراً اخر",
    "royal_house_general_trading_and_contracting_company": "شركة رويال هاوس للتجارة العامة والمقاولات",
    "all_rights_reserved_2023": "جميع الحقوق محفوظة 2023",
    "important_links": "روابط هامة",
    "return_to_cart": "الرجوع لسلة المشتريات",
    "share": "المشاركة",
    "copy_link": "نسخ الرابط",
    "buy_one_get_one_free": "اشتر واحد واحصل على واحد مجاني",
    "free_items": "المنتجات المجانية",
    "add_items": "اضف منتجات",
    "free_item_code": "رمز المنتج المجاني",
    "add_new_free_item": "اضف منتج مجاني جديد",
    "choose_your_gift": "اختر هديتك",
    "please_select_all_gifts": "يرجى اختيار جميع الهدايا",
    "add_gifts": "اضف الهدايا",
    "rechoose_your_gifts_again": "يرجى اعادة اختيار الهدايا",
    "choose_additional_gifts": "اختر الهدايا الاضافية",
    "free_item": "منتج مجاني",
    "export_all_distributors": "تصدير جميع الموزعين",
    "export_new_orders": "تصدير جميع الطلبات الجديدة",
    "print_new_orders": "طباعة فواتير جميع الطلبات الجديدة"
}

export const en_dic = {
    "shipping": "Shipping",
    "terms_and_conditions": "Terms & Conditions",
    "contact_us": "Contact us",
    "search": "Search",
    "lang": "AR",
    "my_account": "My Account",
    "compare": "Compare",
    "wishlist": "Wishlist",
    "cart": "Cart",
    "categories": "Categories",
    "new_arrival": "New Arrival",
    "show_more": "Show more",
    "subscribe_newsletter": "Subscribe our newsletter",
    "information": "Information",
    "services": "Services",
    "legal": "Legal",
    "payment_methods": "Payment Methods",
    "login": "Login",
    "close": "Close",
    "menu": "Menu",
    "view_all": "View All",
    "about_us": "About us",
    "faqs": "FAQ'S",
    "delivery": "Delivery",
    "return_and_exchange": "Return & Exchange",
    "warranty": "Warranty",
    "privacy_policy": "Privacy Policy",
    "cash_on_delivery": "Cash on Delivery",
    "k_net": "K-net",
    "visa_mastercard": "Visa/Mastercard",
    "my_wishlist": "My Wishlist",
    "my_address": "My Address",
    "where_to_find_us": "Where to find us",
    "copyrights": "Copyrights",
    "royal_house": "Royal House",
    "all_rights_reserved": "All Rights Reserved",
    "copyright_2022_royal_house_all_rights_reserved": "Copyright 2022 Royal House. All Rights Reserved",
    "add_to_cart": "Add to cart",
    "login_or_create_an_account": "Login OR Create an Account",
    "forget_password?": "Forget Password?",
    "email": "Email",
    "password": "Password",
    "don't_have_an_account?_resister_now!": "Don't have an Account? Resister Now!",
    "login_with_facebook": "Login With Facebook",
    "login_with_google": "Login With Google",
    "login_signup": "Login/Signup",
    "create_an_account": "Create an Account",
    "required": "* Required",
    "first_name": "First Name",
    "last_name": "Last Name",
    "email_address": "Email address",
    "confirm_password": "Confirm Password",
    "mobile": "Mobile",
    "sign_up_for_newsletter": "Sign Up for Newsletter",
    "i_agree_to_the_terms_and_conditions": "I agree to the terms and conditions",
    "create_account": "Create Account",
    "have_an_account_?_login_now_!": "Have an account? Login now!",
    "first_name_is_required": "First Name is required",
    "last_name_is_required": "Last Name is required",
    "email_must_be_between_4_to_32_characters": "Email must be between 4 to 32 characters",
    "email_must_contain_@": "Email must contain @",
    "password_is_required": "Password is required",
    "passwords_do_not_match": "Passwords do not match",
    "password_must_contain_at_least_6_characters": "Password must contain at least 6 characters",
    "password_must_contain_a_number": "Password must contain a number",
    "must_agree_on_terms_and_conditions": "Must agree on terms and conditions",
    "new_account_has_been_created_._please_signin": "New account has been created. Please Signin",
    "email_doesn't_exist": "Email doesn't exist",
    "email_and_password_don't_match": "Email and password don't match",
    "signout": "Signout",
    "account_information": "Account Information",
    "subcategories": "Subcategories",
    "products": "Products",
    "orders": "Orders",
    "offers": "Offers",
    "edit_account_information": "Edit Account Information",
    "change_password": "Change Password",
    "old_password": "Old Password",
    "new_password": "New Password",
    "confirm_new_password": "Confirm New Password",
    "categories_list": "Categories List",
    "sort": "Sort",
    "delete": "Delete",
    "edit": "Edit",
    "add_new_category": "Add new Category",
    "deleting_category": "Deleting category",
    "categories_have_been_sorted_successfully": "Categories have been sorted successfully",
    "new_category_has_been_created": "New Category has been created",
    "category_has_been_deleted": "Category has been deleted",
    "category_has_been_updated": "Category has been updated",
    "no_category_is_found": "No category is found",
    "yes_._it_has_subcategory": "Yes. It has subcategory",
    "no_._it_hasn't_subcategory": "No. It hasn't subcategory",
    "yes_._it_is_hidden": "Yes. It is hidden",
    "no_._it_isn't_hidden": "No. It isn't hidden",
    "category_english_name": "Category English name",
    "category_arabic_name": "Category Arabic name",
    "has_subcategory": "Has subcategory",
    "hidden": "Hidden",
    "excel_file": "Excel file",
    "save": "Save",
    "add_new_category": "Add new category",
    "add_one_category": "Add one category",
    "add_multiple_categories": "Add multiple categories",
    "edit_category": "Edit Category",
    "english_name_is_required": "English name is required",
    "arabic_name_is_required": "Arabic name is required",
    "has_subcategory_is_required": "Has subcategory is required",
    "hidden_is_required": "Hidden is required",
    "subcategories_have_been_sorted_successfully": "Subcategories have been sorted successfully",
    "new_subcategory_has_been_created": "New subcategory has been created",
    "subcategory_has_been_deleted": "Subcategory has been deleted",
    "subcategory_has_been_updated": "Subcategory has been updated",
    "deleting_subcategory": "Deleting Subcategory",
    "subcategories_list": "Subcategories List",
    "add_new_subcategory": "Add new Subcategory",
    "subcategory_english_name": "Subcategory English name",
    "subcategory_arabic_name": "Subcategory Arabic name",
    "category": "Category",
    "image_1": "Image 1",
    "image_2": "Image 2",
    "category_is_required": "Category is required",
    "no_subcategory_is_found": "No Subcategory is found",
    "edit_subcategory": "Edit Subcategory",
    "deleting_product": "Deleting Product",
    "filterd_products": "Filterd Products",
    "no_products_found": "No products found!",
    "new_product_has_been_created": "New Product has been created",
    "product_has_been_deleted": "Product has been deleted",
    "product_has_been_updated": "Product has been updated",
    "products_list": "Products List",
    "add_new_product": "Add new Product",
    "select_category": "Select Category",
    "select_subcategory": "Select Subcategory",
    "sku": "SKU",
    "item": "Item",
    "product_english_name": "Product English name",
    "product_arabic_name": "Product Arabic name",
    "english_description": "English description",
    "arabic_description": "Arabic description",
    "english_title": "English title",
    "arabic_title": "Arabic title",
    "subcategory": "Subcategory",
    "brand": "Brand",
    "price": "Price",
    "quantity": "Quantity",
    "size": "Size",
    "color": "Color",
    "warranty_(english)": "Warranty (English)",
    "warranty_(arabic)": "Warranty (Arabic)",
    "made_in_(english)": "Made in (English)",
    "made_in_(arabic)": "Made in (Arabic)",
    "related": "Related",
    "image": "Image",
    "add_one_product": "Add one product",
    "add_multiple_products": "Add multiple products",
    "edit_product": "Edit product",
    "tea_thermos": "Tea Thermos",
    "coffee_thermos": "Coffee Thermos",
    "home": "Home",
    "max": "Max",
    "min": "Min",
    "recommended": "Recommended",
    "price_low_to_high": "Price Low to High",
    "price_high_to_low": "Price High to Low",
    "sort_by": "Sort By",
    "filters": "Filters",
    "kd": "KD",
    "you_may_like": "You May Like",
    "in_stock": "In Stock",
    "out_of_stock": "Out of Stock",
    "description": "Description",
    "reviews": "Reviews",
    "made_in": "Made in",
    "items": "Items",
    "remove": "Remove",
    "your_cart_is_empty": "Your cart is empty",
    "order_summary": "Order Summary",
    "have_a_promo_code?": "Have a Promo Code?",
    "apply": "Apply",
    "total": "Total",
    "grand_total": "Grand Total",
    "subtotal": "SubTotal",
    "proceed_to_checkout": "PROCEED TO CHECKOUT",
    "billing_address": "Billing Address",
    "order_review": "Order Review",
    "piece": "Piece",
    "place_order": "Place Order",
    "checkout": "Checkout",
    "knet": "Knet",
    "your_order_has_been_placed_please_keep_your_order_number": "Your Order has been placed. Please keep your order number",
    "delivery_address": "Delivery Address",
    "area": "Area",
    "block": "Block",
    "street": "Street",
    "lane": "Lane",
    "building": "Building",
    "floor": "Floor",
    "apartment": "Apartment",
    "extra_details": "Extra Details",
    "please_fill_the_required_fields": "Please fill the required fields",
    "don\'t_exist": "Don\'t exist",
    "promo_discount": "PROMO Discount",
    "discount_price": "Discount Price",
    "your_order_number_is": "Your order number is",
    "orders_list": "Orders List",
    "ads": "Ads",
    "order_number": "Order number",
    "new_order": "New Order",
    "processing": "Processing",
    "ready_for_delivering": "Ready for delivering",
    "delivered": "Delivered",
    "cancelled": "Cancelled",
    "status": "Status",
    "order_on": "Order On",
    "order_status": "Order Status",
    "coupons": "Coupons",
    "more": "More",
    "new_arrival_list": "New Arrival list",
    "activate": "Activate",
    "add_new_item_to_new_arrival": "Add item to New Arrival",
    "product": "Product",
    "new_product_has_been_added_to_new_arrival.add_more_or": "New product has been added to new arrival. Add more OR",
    "go_back_to_new_arrival_list": "Go back to new arrival list",
    "please_select_a_product": "Please select a product",
    "view_product": "View Product",
    "offers_list": "Offers List",
    "add_new_item_to_new_arrival": "Add item to Offers",
    "new_product_has_been_added_to_offers.add_more_or": "New product has been added to Offers. Add more OR",
    "go_back_to_offers_list": "Go back to offers list",
    "ads_list": "Ads List",
    "no_ads_found": "No Ads found!",
    "add_new_ad": "Add new Ad",
    "deleting_ad": "Deleteing Ad",
    "upload_image": "Upload Image",
    "please_upload_image": "Please upload image",
    "new_ad_has_been_added.add_more_or": "New Ad has been added. Add more OR",
    "go_back_to_ads_list": "Go back to Ads List",
    "coupons_list": "Coupons List",
    "type": "Type",
    "fixed_amount": "Fixed amount",
    "percentage": "Percentage",
    "free_delivery": "Free delivery",
    "coupon_code": "Coupon code",
    "amount": "Amount",
    "requires_signin": "Requires Signin",
    "how_often_used": "How often used",
    "yes": "Yes",
    "no": "No",
    "add_new_coupon": "Add new coupon",
    'active': "Active",
    "new_coupon_has_been_added._add_more_or": "New coupon has been added. Add more OR",
    "go_back_to_coupon_list": "Go back to Coupon List",
    "deleting_coupon": "Deleting Coupon",
    "edit_coupon": "Edit Coupon",
    "the_coupon_has_been_edited.": "The coupon has been edited.",
    "remove_products_from_compare_list": "Remove Products from Compare List",
    "no_products_in_the_compare_list": "No Products in the Compare List",
    "compare_list_has_been_cleared": "Compare list has been cleared",
    "can't_add_more_than_2_products_to_the_compare_list": "Can't add more than 2 products to the compare list",
    "can't_add_to_products_from_different_categories_to_the_compare_list": "Can't add products from different categories to the compare list",
    "this_product_is_already_in_the_compare_list": "this product is already in the compare list",
    "the_product_has_been_added_to_the_compare_list": "The product has been added to the compare list",
    "product_has_been_added_to_cart": "Product has been added to cart",
    "wishlist_is_empty": "Wishlist is empty",
    "product_has_been_added_to_wishlist": "Product has been added to Wishlist",
    "product_has_been_removed_from_wishlist": "Product has been removed from Wishlist",
    "address_book": "Address Book",
    "my_orders": "My Orders",
    "my_reviews": "My Reviews",
    "newsletter": "Newsletter",
    "birth_date": "Birth Date",
    "nationality": "Nationality",
    "gender": "Gender",
    "change_password": "Change Password",
    "old_password": "Old Password",
    "new_password": "New Password",
    "confirm_new_password": "Confirm new Password",
    "new_password_and_confirm_new_password_are_not_matched": "New password and confirm new password are not matched",
    "please_enter_your_old_password": "Please enter your old password",
    "please_enter_the_new_password": "Please enter the new password",
    "old_password_is_not_correct": "Old password is not correct",
    "profile_has_been_updated": "Profile has been updated",
    "add_new_address": "Add new address",
    "mobile_must_contain_numbers_only": "Mobile must contain numbers only",
    "mobile_must_contain_8_digits_only": "Mobile must contain 8 digits only",
    "area_is_required": "Area is required",
    "block_is_required": "Block is required",
    "street_is_required": "Street is required",
    "building_is_required": "Building is required",
    "mobile_is_required": "Mobile is required",
    "new_address_has_been_added": "New address has been added",
    "edit_address": "Edit Address",
    "address_has_been_updated": "Address has been updated",
    "address_has_been_deleted": "Address has been deleted",
    "no_orders_found": "No orders found",
    "how_satisfied_were_you_with_this_product?": "How satisfied were you with this product?",
    "your_comment": "Your Comment",
    "send_review": "Send Review",
    "add_your_review_now": "Add your review now",
    "rate_is_required": "Please rate the product from 1 to 5 stars",
    'please_signin_to_send_review': "Please Signin to send a review",
    "your_review_has_been_added": "Your review has been added",
    'review_has_been_deleted': "Review has been deleted",
    "no_addresses_found": "No addresses found",
    "no_reviews_found": "No reviews found",
    "more_settings": "More Settings",
    "please_enter_the_coupon_code": "Please enter the coupon code",
    "invalid_coupon": "Invalid coupon",
    "valid_coupon": "The coupon is valid and has been applied",
    "pleae_signin_to_use_the_coupon": "Pleae signin to use the coupon",
    "new_address": "New Address",
    "or_continue_as_guest": "OR Continue as guest",
    "payment_declined_please_try_again_or_contact_us": "Payment declined. Please try again or contact us",
    "delivery_price_has_been_updated": "Delivery price has been updated",
    "add_images": "Add images",
    "images_have_been_uploaded": "Images have been uploaded",
    "without_extension": "Without extension (.jpg, .jpeg etc)",
    "failed": "Failed Payment",
    "tags": "Tags",
    "payment_method": "Payment method",
    "cash_(not_paid)": "Cash (NOT PAID)",
    "knet_(paid)": "K-net (PAID)",
    "(three_working_daysـexcept_sabah_alahmad_7_working_days)": "(3 working days except Sabah Al-Ahmad 7 working days)",
    "by_clicking_“place_order”,_i_confirm_i_have_read_and_acknowledge_all_terms_and_policies.": "By clicking “Place Order”, I confirm I have read and acknowledge all terms and policies.",
    "activate_account": "Activate Account",
    "click_on_the_button_to_activate_your_account": "Click on the button to activate your account",
    "just_one_more_step": "just one more step",
    "your_acount_has_been_activated._please_signin.": "Your acount has been activated. Please Signin",
    "account_is_not_confirmed._please_check_your_email_to_confirm_your_account_or_contact_with_support_team.": "Account is not confirmed. Please check your email to confirm your account or contact with support team.",
    "please_enter_your_email_address_below._you_will_receive_a_link_to_reset_your_password.": "Please enter your email address below. You will receive a link to reset your password.",
    "send": "Send",
    "free_delivery_for_odrders_greater_than": "Free delivery for odrders greater than",
    "free_delivery_for_selected_areas_for_odrders_greater_than": "Free delivery for selected areas for odrders greater than",
    "delivery_for_selected_areas": "Delivery for selected areas",
    "selected_areas": "Selected areas",
    "add_area_to_the_selected_areas": "Add area to the selected areas",
    "add": "Add",
    "updated_successfully": "Updated Successfully",
    "please_select_an_area": "Please select an area",
    "city": "City",
    "select_city": "Select City",
    "select_area": "Select Area",
    "users": "Users",
    "find_user": "Find User",
    "no_user_found!": "No user found!",
    "user_is_active": "User is active",
    "regular_user": "Regular User",
    "storage": "Storage",
    "admin": "admin",
    "account_type": "Account type",
    "role_is_required": "Account type is required",
    "something_went_wrong._try_again_later_or_contact_support": "Something went wrong. Try again later or contact support",
    "user_has_been_updated": "User has been updated",
    "you_have_subscribed_in_newsletter": "You have subscribed in newsletter",
    "email_already_exists": "Email already exists",
    "your_cart_has_been_updated._please_review_your_cart_before_checking_out": "Your cart has been updated. Please review your cart before checking out",
    "please_enter_new_password": "Please enter new password",
    "new_password_and_confirm_new_password_are_not_matched": "New password and confirm new password are not matched",
    "the_token_is_incorrect_or_expired": "The token is incorrect or expired",
    "user_with_this_token_doesn't_exist": "User doesn't exist",
    "password_has_been_changed": "Password has been changed. Please signin",
    "authentication_error!": "Authentication error!",
    "update_products": "Update Products",
    "products_have_been_updated": "Products have been updated",
    "update": "Update",
    "waiting_for_online_payment": "Waiting for Online Payment",
    "knet_(not_paid)": "Knet (Not Paid)",
    "all_statuses": "All Statuses",
    "link": "Link",
    "replace_current_products_in_new_arrival": "Replace current products in new arrival",
    "new_arrivals_products_have_been_replaced": "New arrivals products have been replaced",
    "replace": "Replace",
    "gifts": "Gifts",
    "add_new_gift": "Add new gifts",
    "gift_name": "Gift name",
    "minimum_cart": "Minimum cart",
    "new_gift_has_been_added.add_more_or": "New gift has been added. Add more OR",
    "go_back_to_gitfs_list": "Go back to gifts list",
    "gifts_list": "Gifts list",
    "no_gifts_found": "No gifts found",
    "no_coupons_found": "No coupons found",
    "coupon_has_been_deleted": "Coupon has been deleted",
    "gift_has_been_deleted": "Gift has been deleted",
    "update_gift": "Update gift",
    "gift_has_been_updated": "Gift has been updated",
    "free_gift": "Free Gift",
    "delete_multiple_products": "Delete Multiple Products",
    "products_have_been_deleted": "Products have been deleted",
    "export_all_products": "Export all products",
    "offers_home_page": "Special prices",
    "export_orders": "Export Orders",
    "export": "Export",
    "from_order_number": "From order number",
    "to_order_number": "To order number",
    "orders_have_been_exported": "Orders have been exported",
    "replace_current_products_in_offers": "Replace current products in offers",
    "offers_products_have_been_replaced": "Offers products have been replaced",
    "export_all_users": "Export all users",
    "lists": "Lists",
    "add_new_list": "Add new list",
    "list_name_in_arabic": "List name in Arabic",
    "list_name_in_english": "List name in English",
    "new_list_has_been_added.add_more_or": "New list has been added. Add more or",
    "go_back_to_lists": "go back to Lists",
    "update_list": "Update list",
    "order": "Order",
    "show_current_products": "Show current products",
    "add_more_products": "Add more products",
    "replace_current_products": "Replace current products",
    "list_has_been_edited": "List has been edited",
    "list_has_been_deleted": "List has been deleted",
    "small": "Small",
    "medium": "Medium",
    "large": "Large",
    "add_products_for": "Add products for",
    "or_more_and_get_a_free_delivery": "or more and get a FREE DELIVERY",
    "announcement": "Announcement",
    "please_select_the_size": "Please select the size",
    "select_size": "Select size",
    "wholesale": "Wholesale",
    "distributors": "Distributors",
    "blog": "Blog",
    "business_name": "Business name",
    "owner_name": "Owner name",
    "business_type": "Business type",
    "country": "Business location (country)",
    "website": "Website",
    "send_request": "Send request",
    "wholesale_requests_list": "Wholesale requests list",
    "customer_name": "Customer name",
    "feedback": "Feedback",
    "feedbacks": "Feedbacks",
    "send_feedback": "Send feedback",
    "name": "Name",
    "customer_service": "Customer service",
    "rose_thermos": "Rose thermos",
    "royal_house_co": "Royal House",
    "youtube_channel": "Youtube channel",
    "add_new_distributor": "Add new distributor",
    "distributor_name": "Distributor name",
    "location": "Location",
    "new_distributor_has_been_added.add_more_or": "New distributor has been added. Add more or",
    "go_back_to_distributors_list": "go back to distributors list",
    "deleting_distributor": "Deleting distributor",
    "distributor_has_been_deleted": "Distributor has been deleted",
    "add_new_distributor": "Add new distributor",
    "update_distributor": "Update distributor",
    "find_a_store": "Find a store",
    "please_select_an_area_to_locate_a_store": "Please select an area to locate a store",
    "add_to_blog": "Add to blog",
    "blog_is_empty": "Blog is empty",
    "title": "Title",
    "body": "Body",
    "added_to_blog._add_more_or": "Added to blog. Add more or",
    "go_back_to_blog": "go back to blog",
    'blog_has_been_updated.': "Blog has been updated",
    "edit_blog": "Update blog",
    "deleting_from_blog": "Deleting from blog",
    "has_been_deleted_from_blog": "Has been deleted from blog",
    "new_articals_about_every_thing_related_to_tea_and_coffee": "New articals about every thing related to Tea & Coffee",
    "manage_colors": "Manage colors",
    'colors': 'Colors',
    "add_new_color": "Add new color",
    "no_colors_found": "No colors found",
    "color_number": "Color number",
    "color_has_been_deleted": "Color has been deleted",
    'deleting_color': 'Deleting color',
    "color_hex_code": "Hex code",
    "color_name_in_english": "Color name in English",
    "color_name_in_arabic": "Color name in Arabic",
    "color_number": "Color number",
    'new_color_has_been_added._add_more_or': 'New color has been added. Add more or',
    'go_back_to_colors_list': 'go back to colors list',
    "edit_color": "Update color",
    "color_has_been_updated": "Color has been updated",
    "add_model_to_collection": "Add model to collection",
    "accessories": "Accessories",
    "porcelain": "Porcelain",
    "new_model_has_been_added._add_more_or": "New model has been added. Add more or",
    "go_back_to_collections": "go back to collections",
    "model_name": "Model name",
    "thermos": "Thermos",
    "collections": "Collections",
    "add_to_collections": "Add to collections",
    "no_collection_found": "No collection found",
    "model_has_been_deleted": "Model has been deleted",
    "deleting_model": "Deleting model",
    "edit_collection": "Update collection",
    "collection_has_been_updated.": "Collection has been updated.",
    "get_your_collection_as_you_like": "Get your collection as you like",
    "choose_model": "Choose model",
    'choose_color': "Choose color",
    "total_price": "Total Price",
    "add_collection_to_cart": "Add collection to cart",
    "collection_has_been_added_to_cart": "Collection has been added to cart",
    "yes_add_them_to_cart": "Yes. Add them to cart",
    "earned_points": "Earned points",
    "points": "Points",
    "my_points": "My points",
    "no_points": "No points",
    "valid_until": "Valid until",
    "equal_to": "equal to",
    "total_points": "Total points",
    "you_have": "You have",
    "would_you_like_to_use_them?": "Would you like to use them?",
    "valid_to_use": "Valid to use",
    "no_i_wouldn't_like_to_use_them": "No, I wouldn't like to use them",
    "yes_i_would_like_to_use_them": "Yes, I would like to use them",
    "points_discount": "Points discount",
    "you_don't_have_any_points": "You don't have any points",
    "can_not_use_points_with_coupon_discount": "Can not use points with coupon discount",
    "paid_by_points": "Paid by points",
    "paid_by_coupon": "Paid by coupon",
    "free_checkout": "Free checkout",
    "used_points_from_it": "Used points from it",
    "remaining_points_from_it": "Remaining points from it",
    "total_used_points": "Total used points",
    "total_remaining_points": "Total remaining points",
    "earned_from_order_number": "Earned from order number",
    "your_points_been_updated._please_review_your_points_before_checking_out": "Your points been updated. Please review your points before checking out",
    "return_used_points": "Return used points",
    "get_your_collection_now": "Get your collection now",
    "ad_position": "Ad position",
    "please_enter_ad_position": "Please enter ad position",
    "you_can't_use_points_with_coupons": "You can't use points with coupon",
    "category_image": "Category image",
    "image_is_required": "Image is required",
    "show_all": "Show all",
    "model_image": "Model image",
    "preview_image": "Preview image",
    "link_title": "Link title",
    "subcategory_image": "Subcategory image",
    "points_program": "Points program",
    "join_points_program": "Join points program now",
    "please_agree_on_terms_and_conditions": "Please agree on terms and conditions",
    "user_has_been_deleted": "User has been deleted",
    "deleting_user": "Deleting User",
    "Email already exists": "Email already exists",
    "thermos_tags": "Thermos tags",
    "porcelain_tags": "Porcelain tags",
    "accessories_tags": "Accessories tags",
    "minimumCart": "Minimum cart",
    "limit_of_use": "Limit of use",
    "please_check_your_mobile_number": "Please check your mobile number",
    "replace_current_distributors": "Replace current distributors",
    "distributors_have_been_replaced": "Distributors have been replaced",
    "the_minimum_cart_limit_is": "The minimum cart limit is",
    "without_delivery": "without delivery",
    "your_cart_is": "Your cart is",
    "stop_delivery_for_areas": "Stop delivery for areas",
    "delivery_message_in_arabic": "Delivery message in Arabic",
    "delivery_message_in_english": "Delivery message in English",
    "edit_delivery_address": "Edit delivery address",
    "cancel": "Cancel",
    "delivery_address_in_english": "Delivery address in English",
    "delivery_address_in_arabic": "Delivery address in Arabic",
    "add_one_coupon": "Add one coupon",
    "add_multiple_coupons": "Add multiple coupons",
    "add_extra_points": "Add Extra Points",
    "earned_as_a_gift": "Earned as a gift",
    "cities_list": "Cities List",
    "add_new_city": "Add new city",
    "city_english_name": "City English name",
    "city_arabic_name": "City Arabic name",
    "add_one_city": "Add one city",
    "add_multiple_cities": "Add multiple cities",
    "new_city_has_been_created": "New city has been created",
    "edit_city": "Edit city",
    "no_city_is_found": "No city is found",
    "city_has_been_deleted": "City has been deleted",
    "city_has_been_updated": "City has been updated",
    "deleting_city": "Deleting city",
    "city_is_required": "City is required",
    "areas_list": "Areas list",
    "add_new_area": "Add new area",
    "add_one_area": "Add one area",
    "add_multiple_areas": "Add multiple areas",
    "area_english_name": "Area English name",
    "area_arabic_name": "Area Arabic name",
    "new_area_has_been_created": "New area has been created",
    "area_has_been_deleted": "Area has been deleted",
    "area_has_been_updated": "Area has been updated",
    "deleting_area": "Deleting area",
    "no_area_is_found": "No area is found",
    "edit_area": "Edit Area",
    "areas": "Areas",
    "cities": "Cities",
    "add_new_gift_type": "Add new gift type",
    "gift_type_name": "Gift type name",
    "gift_type_value": "Gift type value",
    "number_of_gifts": "Number of gifts",
    "show_lower_types": "Show lower types",
    "new_gift_type_has_been_created": "New gift type has been created",
    "gift_type_has_been_deleted": "Gift type has been deleted",
    "gift_type_has_been_updated": "Gift type has been updated",
    "gift_types": "Gift types",
    "deleting_gift_type": "Deleting gift type",
    "edit_gift_type": "Edit gift type",
    "no_gift_type_is_found": "No gift type is found",
    "new_gift_item_has_been_created": "New gift item has been created",
    "gift_item_has_been_deleted": "Gift item has been deleted",
    "gift_item_has_been_updated": "Gift item has been updated",
    "deleting_gift_item": "Deleting gift item",
    "add_one_gift": "Add one gift",
    "add_multiple_gifts": "Add multiple gifts",
    "code": "Code",
    "edit_gift": "Edit gift",
    "no_gift_is_found": "No gift is found",
    "gift_type": "Gift type",
    "dear_customers,_kindly_note_we_stopped_accepting_new_orders_for_better_service._coming_back_soon":" Dear customers, kindly note we stopped accepting new orders for better service. Coming back soon",
    "royal_house_general_trading_and_contracting_company": "Royal house General trading and Contracting company.",
    "all_rights_reserved_2023": "All Rights Reserved 2023",
    "important_links": "Important links",
    "return_to_cart": "Return to cart",
    "share": "Share",
    "copy_link": "Copy link",
    "buy_one_get_one_free": "Buy one get one free",
    "free_items": "Free items",
    "add_items": "Add Items",
    "free_item_code": "Free Item Code",
    "add_new_free_item": "Add new free item",
    "choose_your_gift": "Choose your gift",
    "please_select_all_gifts": "Please select all gifts",
    "add_gifts": "Add gifts",
    "rechoose_your_gifts_again": "Rechoose your gifts again",
    "choose_additional_gifts": "Choose additional gifts",
    "free_item": "Free item",
    "export_all_distributors": "Export all distributors",
    "export_new_orders": "Export new orders",
    "print_new_orders": "Print new orders invoices"
}

// const arCities = [
//     "محافظة الأحمدي",
//     "محافظة مبارك الكبير",
//     "محافظة حولي",
//     "محافظة الفروانية",
//     "محافظة العاصمة",
//     "محافظة الجهراء"
// ]

// const enCities = [
//     "Ahmadi Governorate",
//     "Mubarak Al-Kabeer Governorate",
//     "Hawalli Governorate",
//     "Farwaniya Governorate",
//     "AL Kuwait Governorate",
//     "Jahra Governorate"
// ]

// export const citiesArEn = {
//     "محافظة الأحمدي": "Ahmadi Governorate",
//     "محافظة مبارك الكبير": "Mubarak Al-Kabeer Governorate",
//     "محافظة حولي": "Hawalli Governorate",
//     "محافظة الفروانية": "Farwaniya Governorate",
//     "محافظة العاصمة": "AL Kuwait Governorate",
//     "محافظة الجهراء": "Jahra Governorate"
// }

// export const citiesEnAr = {
//     "Ahmadi Governorate": "محافظة الأحمدي",
//     "Mubarak Al-Kabeer Governorate": "محافظة مبارك الكبير",
//     "Hawalli Governorate": "محافظة حولي",
//     "Farwaniya Governorate": "محافظة الفروانية",
//     "AL Kuwait Governorate": "محافظة العاصمة",
//     "Jahra Governorate": "محافظة الجهراء",
// }

// const enCitiesAndAreas = {
//     'Ahmadi Governorate': ['AlAhmadi', 'East Al Ahmadi', 'Subah Al Ahmed', 'Abu Halifa', 'Ali Sabah Al Salem', 'Dhaher', 'Egaila', 'Fahad Al Ahmed', 'Fahaheel', 'Fintas', 'Hadiya', 'Jaber Al Ali', 'Mahboula', 'Mangaf', 'Riqqa', 'Sabahiya'],
//     'Mubarak Al-Kabeer Governorate': ['Messila', 'Abu Ftaira', 'Abu Hasaniya', 'Adan', 'Al Qurain', 'Al Qusour', 'Fnaitess', 'Mubarak Al Kabir', 'Sabah Al Salem', 'AL masayil'],
//     'Hawalli Governorate': ['Al Seddeek', 'Al Bedae', 'Bayan', 'Hawally', 'Hitteen', 'Jabriya', 'Maidan Hawally', 'Mishrif', 'Mubarak Al Abdullah', 'Rumaithiya', 'Salmiya', 'Salwa', 'Shuhada', 'South Alsoura', 'Zahra', 'Al Salam', 'AL Noqrua', 'AL Shaab', 'AL Shaab al Bahri'],
//     'Farwaniya Governorate': ['Abdullah Al Mubarak', 'Andalous', 'Ardhiya', 'Dhajeej', 'Eshbilya', 'Farwaniya', 'Ferdous', 'Hasawi', 'Jleeb Al Shiyoukh', 'Khaitan', 'Omariya', 'Rabiya', 'Reggai', 'Rehab', 'Sabah Al Nasser'],
//     'AL Kuwait Governorate': ['Abdullah Al Salem', 'Adailiya', 'Bnaid Al Qar', 'Dasma', 'Dasman', 'Faiha', 'Ghornata', 'Kaifan', 'Khaldiya', 'Kuwait City', 'Mansouriya', 'Mirqab', 'Nuzha', 'Qibla', 'Salhiya', 'Shamiya', 'Rai', 'Sharq', 'Shuwaikh', 'Qadsiya', 'Qortuba', 'Sulaibikhat', 'Surra', 'Yarmouk', 'Rawda', 'Doha', 'Jaber Al Ahmed', 'Al Nahda', 'Querain', 'Dayia'],
//     'Jahra Governorate': ['Sulaibiya', 'Saad Al AbdAllah', 'Jahra', 'Naem', 'Nasseem', 'Qasr', 'Tayma', 'Waha', 'Ayoun', 'Amghara']
// }

// const arCitiesAndAreas = {
//     'محافظة الأحمدي': ['الاحمدي', 'شرق الأحمدي', 'صباح الاحمد', 'ابو حليفة', 'علي صباح السالم', 'الظهر', 'العقيلة', 'فهد الاحمد', 'الفحيحيل', 'الفنطاس', 'هدية', 'جابر العلي', 'المهبولة', 'المنقف', 'الرقة', 'الصباحية',],
//     'محافظة مبارك الكبير': ['المسيلة', 'ابو فطيرة', 'ابو الحصانية', 'العدان', 'القرين', 'القصور', 'الفنيطيس', 'مبارك الكبير', 'صباح السالم', 'المسايل',],
//     'محافظة حولي': ['الصديق', 'البدع', 'بيان', 'حولي', 'حطين', 'الجابرية', 'ميدان حولي', 'مشرف', 'مبارك العبدالله', 'الرميثية', 'السالمية', 'سلوى', 'الشهداء', 'جنوب السرة', 'الزهراء', 'السلام', 'النقرة', 'الشعب', 'الشعب البحري',],
//     'محافظة الفروانية': ['عبدالله المبارك', 'الاندلس', 'العارضية', 'الضجيج', 'اشبيلية', 'الفروانية', 'الفردوس', 'الحساوي', 'جليب الشيوخ', 'خيطان', 'العمرية', 'الرابية', 'الرقعي', 'الرحاب', 'صباح الناصر',],
//     'محافظة العاصمة': ['عبدالله السالم', 'العديلية', 'بنيد القار', 'الدسمة', 'دسمان', 'الفيحاء', 'غرناطة', 'كيفان', 'الخالدية', 'الكويت', 'المنصورية', 'المرقاب', 'النزهة', 'القبلة', 'الصالحية', 'الشامية', 'الري', 'شرق', 'الشويخ', 'القادسية', 'قرطبة', 'الصليبيخات', 'السرة', 'اليرموك', 'الروضة', 'الدوحة', 'جابر الاحمد', 'النهضة', 'القيروان', 'الدعية',],
//     'محافظة الجهراء': ['الصليبية', 'سعد العبدالله', 'الجهراء', 'النعيم', 'النسيم', 'القصر', 'تيماء', 'الواحة', 'العيون', 'أمغرة']
// }

// export const areasEnAr = {
//     'AlAhmadi': 'الاحمدي',
//     'East Al Ahmadi': 'شرق الأحمدي',
//     'Subah Al Ahmed': 'صباح الاحمد',
//     'Abu Halifa': 'ابو حليفة',
//     'Ali Sabah Al Salem': 'علي صباح السالم',
//     'Dhaher': 'الظهر',
//     'Egaila': 'العقيلة',
//     'Fahad Al Ahmed': 'فهد الاحمد',
//     'Fahaheel': 'الفحيحيل',
//     'Fintas': 'الفنطاس',
//     'Hadiya': 'هدية',
//     'Jaber Al Ali': 'جابر العلي',
//     'Mahboula': 'المهبولة',
//     'Mangaf': 'المنقف',
//     'Riqqa': 'الرقة',
//     'Sabahiya': 'الصباحية',
//     'Messila': 'المسيلة',
//     'Abu Ftaira': 'ابو فطيرة',
//     'Abu Hasaniya': 'ابو الحصانية',
//     'Adan': 'العدان',
//     'Al Qurain': 'القرين',
//     'Al Qusour': 'القصور',
//     'Fnaitess': 'الفنيطيس',
//     'Mubarak Al Kabir': 'مبارك الكبير',
//     'Sabah Al Salem': 'صباح السالم',
//     'AL masayil': 'المسايل',
//     'Al Seddeek': 'الصديق',
//     'Al Bedae': 'البدع',
//     'Bayan': 'بيان',
//     'Hawally': 'حولي',
//     'Hitteen': 'حطين',
//     'Jabriya': 'الجابرية',
//     'Maidan Hawally': 'ميدان حولي',
//     'Mishrif': 'مشرف',
//     'Mubarak Al Abdullah': 'مبارك العبدالله',
//     'Rumaithiya': 'الرميثية',
//     'Salmiya': 'السالمية',
//     'Salwa': 'سلوى',
//     'Shuhada': 'الشهداء',
//     'South Alsoura': 'جنوب السرة',
//     'Zahra': 'الزهراء',
//     'Al Salam': 'السلام',
//     'AL Noqrua': 'النقرة',
//     'AL Shaab': 'الشعب',
//     'AL Shaab al Bahri': 'الشعب البحري',
//     'Abdullah Al Mubarak': 'عبدالله المبارك',
//     'Andalous': 'الاندلس',
//     'Ardhiya': 'العارضية',
//     'Dhajeej': 'الضجيج',
//     'Eshbilya': 'اشبيلية',
//     'Farwaniya': 'الفروانية',
//     'Ferdous': 'الفردوس',
//     'Hasawi': 'الحساوي',
//     'Jleeb Al Shiyoukh': 'جليب الشيوخ',
//     'Khaitan': 'خيطان',
//     'Omariya': 'العمرية',
//     'Rabiya': 'الرابية',
//     'Reggai': 'الرقعي',
//     'Rehab': 'الرحاب',
//     'Sabah Al Nasser': 'صباح الناصر',
//     'Abdullah Al Salem': 'عبدالله السالم',
//     'Adailiya': 'العديلية',
//     'Bnaid Al Qar': 'بنيد القار',
//     'Dasma': 'الدسمة',
//     'Dasman': 'دسمان',
//     'Faiha': 'الفيحاء',
//     'Ghornata': 'غرناطة',
//     'Kaifan': 'كيفان',
//     'Khaldiya': 'الخالدية',
//     'Kuwait City': 'الكويت',
//     'Mansouriya': 'المنصورية',
//     'Mirqab': 'المرقاب',
//     'Nuzha': 'النزهة',
//     'Qibla': 'القبلة',
//     'Salhiya': 'الصالحية',
//     'Shamiya': 'الشامية',
//     'Rai': 'الري',
//     'Sharq': 'شرق',
//     'Shuwaikh': 'الشويخ',
//     'Qadsiya': 'القادسية',
//     'Qortuba': 'قرطبة',
//     'Sulaibikhat': 'الصليبيخات',
//     'Surra': 'السرة',
//     'Yarmouk': 'اليرموك',
//     'Rawda': 'الروضة',
//     'Doha': 'الدوحة',
//     'Jaber Al Ahmed': 'جابر الاحمد',
//     'Al Nahda': 'النهضة',
//     'Querain': 'القيروان',
//     'Dayia': 'الدعية',
//     'Sulaibiya': 'الصليبية',
//     'Saad Al AbdAllah': 'سعد العبدالله',
//     'Jahra': 'الجهراء',
//     'Naem': 'النعيم',
//     'Nasseem': 'النسيم',
//     'Qasr': 'القصر',
//     'Tayma': 'تيماء',
//     'Waha': 'الواحة',
//     'Ayoun': 'العيون',
//     'Amghara': 'أمغرة'
// }

// export const areasArEn = {
//     'الاحمدي': 'AlAhmadi',
//     'شرق الأحمدي': 'East Al Ahmadi',
//     'صباح الاحمد': 'Subah Al Ahmed',
//     'ابو حليفة': 'Abu Halifa',
//     'علي صباح السالم': 'Ali Sabah Al Salem',
//     'الظهر': 'Dhaher',
//     'العقيلة': 'Egaila',
//     'فهد الاحمد': 'Fahad Al Ahmed',
//     'الفحيحيل': 'Fahaheel',
//     'الفنطاس': 'Fintas',
//     'هدية': 'Hadiya',
//     'جابر العلي': 'Jaber Al Ali',
//     'المهبولة': 'Mahboula',
//     'المنقف': 'Mangaf',
//     'الرقة': 'Riqqa',
//     'الصباحية': 'Sabahiya',
//     'المسيلة': 'Messila',
//     'ابو فطيرة': 'Abu Ftaira',
//     'ابو الحصانية': 'Abu Hasaniya',
//     'العدان': 'Adan',
//     'القرين': 'Al Qurain',
//     'القصور': 'Al Qusour',
//     'الفنيطيس': 'Fnaitess',
//     'مبارك الكبير': 'Mubarak Al Kabir',
//     'صباح السالم': 'Sabah Al Salem',
//     'المسايل': 'AL masayil',
//     'الصديق': 'Al Seddeek',
//     'البدع': 'Al Bedae',
//     'بيان': 'Bayan',
//     'حولي': 'Hawally',
//     'حطين': 'Hitteen',
//     'الجابرية': 'Jabriya',
//     'ميدان حولي': 'Maidan Hawally',
//     'مشرف': 'Mishrif',
//     'مبارك العبدالله': 'Mubarak Al Abdullah',
//     'الرميثية': 'Rumaithiya',
//     'السالمية': 'Salmiya',
//     'سلوى': 'Salwa',
//     'الشهداء': 'Shuhada',
//     'جنوب السرة': 'South Alsoura',
//     'الزهراء': 'Zahra',
//     'السلام': 'Al Salam',
//     'النقرة': 'AL Noqrua',
//     'الشعب': 'AL Shaab',
//     'الشعب البحري': 'AL Shaab al Bahri',
//     'عبدالله المبارك': 'Abdullah Al Mubarak',
//     'الاندلس': 'Andalous',
//     'العارضية': 'Ardhiya',
//     'الضجيج': 'Dhajeej',
//     'اشبيلية': 'Eshbilya',
//     'الفروانية': 'Farwaniya',
//     'الفردوس': 'Ferdous',
//     'الحساوي': 'Hasawi',
//     'جليب الشيوخ': 'Jleeb Al Shiyoukh',
//     'خيطان': 'Khaitan',
//     'العمرية': 'Omariya',
//     'الرابية': 'Rabiya',
//     'الرقعي': 'Reggai',
//     'الرحاب': 'Rehab',
//     'صباح الناصر': 'Sabah Al Nasser',
//     'عبدالله السالم': 'Abdullah Al Salem',
//     'العديلية': 'Adailiya',
//     'بنيد القار': 'Bnaid Al Qar',
//     'الدسمة': 'Dasma',
//     'دسمان': 'Dasman',
//     'الفيحاء': 'Faiha',
//     'غرناطة': 'Ghornata',
//     'كيفان': 'Kaifan',
//     'الخالدية': 'Khaldiya',
//     'الكويت': 'Kuwait City',
//     'المنصورية': 'Mansouriya',
//     'المرقاب': 'Mirqab',
//     'النزهة': 'Nuzha',
//     'القبلة': 'Qibla',
//     'الصالحية': 'Salhiya',
//     'الشامية': 'Shamiya',
//     'الري': 'Rai',
//     'شرق': 'Sharq',
//     'الشويخ': 'Shuwaikh',
//     'القادسية': 'Qadsiya',
//     'قرطبة': 'Qortuba',
//     'الصليبيخات': 'Sulaibikhat',
//     'السرة': 'Surra',
//     'اليرموك': 'Yarmouk',
//     'الروضة': 'Rawda',
//     'الدوحة': 'Doha',
//     'جابر الاحمد': 'Jaber Al Ahmed',
//     'النهضة': 'Al Nahda',
//     'القيروان': 'Querain',
//     'الدعية': 'Dayia',
//     'الصليبية': 'Sulaibiya',
//     'سعد العبدالله': 'Saad Al AbdAllah',
//     'الجهراء': 'Jahra',
//     'النعيم': 'Naem',
//     'النسيم': 'Nasseem',
//     'القصر': 'Qasr',
//     'تيماء': 'Tayma',
//     'الواحة': 'Waha',
//     'العيون': 'Ayoun',
//     'أمغرة': 'Amghara'
// }

// export const enAreas = [
//     "Abbasiya",
//     "Abdaly",
//     "Abdullah Al-Mubarak - West Jleeb",
//     "Abdullah Al-Salem",
//     "Abraq Khaitan",
//     "Abu Ftaira",
//     "Abu Halifa",
//     "Abu Hasaniya",
//     "Adailiya",
//     "Adan",
//     "Airport",
//     "Al Nahda",
//     "Al-Ahmadi",
//     "Al-Bedae",
//     "Al-Qurain",
//     "Al-Qusour",
//     "Ali Al Salem Base",
//     "Ali Sabah Al-Salem",
//     "Amghara",
//     "Andalous",
//     "Ardhiya",
//     "Ardiya Small Industrial",
//     "Ardiya Storage Zone",
//     "Ashbeliah",
//     "Bayan",
//     "Bnaid Al-Qar",
//     "Bneider",
//     "Coast Strip B",
//     "Dahiyat Al Seddeek",
//     "Daiya",
//     "Dasma",
//     "Dasman",
//     "Dhaher",
//     "Dhajeej",
//     "Doha",
//     "Dubaeya",
//     "East Al Ahmadi",
//     "Egaila",
//     "Eshpilya",
//     "Exhibits - South Khaitan",
//     "Fahad Al Ahmed",
//     "Fahaheel",
//     "Faiha",
//     "Farwaniya",
//     "Ferdous",
//     "Fintas",
//     "Fnaitess",
//     "Furusiyat al ahmadi",
//     "Ghornata",
//     "Hadiya",
//     "Hasawi",
//     "Hawally",
//     "Hitteen",
//     "Jaber Al Ahmed",
//     "Jaber Al-Ali",
//     "Jabriya",
//     "Jahra",
//     "Jleeb Al-Shiyoukh",
//     "Juleya'a",
//     "Kabad",
//     "Kaifan",
//     "Khairan",
//     "Khaitan",
//     "Khaldiya",
//     "Kuwait City",
//     "Magwa",
//     "Mahboula",
//     "Maidan Hawally",
//     "Mangaf",
//     "Mansouriya",
//     "Messila",
//     "Metla",
//     "Mina Abdullah",
//     "Mina Al Ahmadi",
//     "Mina al Shuaiba",
//     "Mina al zour",
//     "Mirqab",
//     "Mishrif",
//     "Mubarak Al-Abdullah",
//     "Mubarak Al-Kabir",
//     "Mubarakiya Camps",
//     "Naem",
//     "Nasseem",
//     "Nouwayseeb",
//     "Nuzha",
//     "Om Al Naqui",
//     "Omariya",
//     "Oudayliya",
//     "Oukeila",
//     "Oyoun",
//     "Qadsiya",
//     "Qairawan - South Doha",
//     "Qasr",
//     "Qibla",
//     "Qortuba",
//     "Querain",
//     "Quosour",
//     "Rabiya",
//     "Rai",
//     "Rawda",
//     "Reggai",
//     "Rehab",
//     "Rihab",
//     "Riqqa",
//     "Rumaithiya",
//     "Sabah Al-Nasser",
//     "Sabah Al-Salem",
//     "Sabahiya",
//     "Sabhan Industrial",
//     "Salam",
//     "Salhiya",
//     "Salmiya",
//     "Salmy",
//     "Salwa",
//     "Sawaber",
//     "Sebya",
//     "Shaab al Bahri",
//     "Shaab",
//     "Shamiya",
//     "Sharq",
//     "Sheikh Saad Al Abdullah Airport",
//     "Shuaiba Port",
//     "Shuhada",
//     "Shuwaikh",
//     "Siddiq",
//     "South Rihab",
//     "South Wista",
//     "Subah Al Ahmed",
//     "Sulaibikhat",
//     "Sulaibiya Indutrial 1",
//     "Sulaibiya Indutrial 2",
//     "Sulaibiya Residential",
//     "Sulaibiya",
//     "Surra",
//     "Tayma",
//     "Um al heiman",
//     "Wafra",
//     "Waha",
//     "West Abu Fetera Small Indust",
//     "West Mishref",
//     "Wista",
//     "Yarmouk",
//     "Zahra",
//     "Amghara"
// ];

// const arAreas = [
//     "ام النقاوي",
//     "أبرق خيطان",
//     "أبو الحصانية",
//     "أبو حليفة",
//     "أشبيلية",
//     "أم الهيمان",
//     "أمغرة",
//     "اشبيلية",
//     "الأحمدي",
//     "الأندلس",
//     "البدع",
//     "الجابرية",
//     "الجهراء",
//     "الحساوي",
//     "الخالدية",
//     "الخالدية",
//     "الخيران",
//     "الدسمة",
//     "الدعية",
//     "الدوحة",
//     "الرابية",
//     "الرحاب",
//     "الرحاب",
//     "الرقة",
//     "الرقعي",
//     "الرميثية",
//     "الروضة",
//     "الري",
//     "الزهراء",
//     "السالمي",
//     "السالمية",
//     "السرة",
//     "السلام",
//     "الشامية",
//     "الشعب البحري",
//     "الشعب",
//     "الشهداء",
//     "الصالحية",
//     "الصباحية",
//     "الصبية",
//     "الصديق",
//     "الصليبية السكنية",
//     "الصليبية الصناعية ١",
//     "الصليبية الصناعية ٢",
//     "الصليبية",
//     "الصليبيخات",
//     "الصوابر",
//     "الضجيج",
//     "الضهر",
//     "العارضية",
//     "العباسية*",
//     "العبدلي",
//     "العدان",
//     "العديلية",
//     "العديلية",
//     "العقيلة",
//     "العقيلة",
//     "العمرية",
//     "العيون",
//     "الفحيحيل",
//     "الفردوس",
//     "الفروانية",
//     "الفنطاس",
//     "الفنيطيس",
//     "الفيحاء",
//     "القادسية",
//     "القبلة",
//     "القرين",
//     "القرين",
//     "القصر",
//     "القصور",
//     "القصور",
//     "المرقاب",
//     "المسيلة",
//     "المطار",
//     "المطلاع",
//     "المقوع",
//     "المنصورية",
//     "المنقف",
//     "المهبولة",
//     "النزهة",
//     "النسيم",
//     "النعيم",
//     "النهضة",
//     "النويصيب",
//     "الواحة",
//     "الوسطى",
//     "اليرموك",
//     "بنيد القار",
//     "بنيدر",
//     "بيان",
//     "تيماء",
//     "جابر الأحمد",
//     "جابر العلي",
//     "جليب الشيوخ",
//     "جليعة",
//     "جنوب الرحاب",
//     "جنوب الوسطى",
//     "حطين",
//     "حولي",
//     "خيطان",
//     "دسمان",
//     "سلوى",
//     "شرق الأحمدي",
//     "شرق",
//     "شريط الساحل ب",
//     "شويخ",
//     "صباح الأحمد",
//     "صبحان الصناعية",
//     "ضاحية أبو فطيرة",
//     "ضاحية الصديق",
//     "ضاحية صباح السالم",
//     "ضاحية صباح الناصر",
//     "ضاحية عبد الله السالم",
//     "ضاحية عبدالله المبارك - غرب الجليب",
//     "ضاحية علي صباح السالم",
//     "ضاحية فهد الأحمد",
//     "ضاحية مبارك الكبير",
//     "ضبعا",
//     "عارضية الصناعية",
//     "عارضية المخازن",
//     "غرب أبو فطيرة الصناعية",
//     "غرب مشرف",
//     "غرناطة",
//     "فروسية الأحمدي",
//     "قاعدة علي السالم",
//     "قرطبة",
//     "كبد",
//     "كيفان",
//     "مبارك العبدالله",
//     "مشرف",
//     "مطار الشيخ سعد العبدالله",
//     "معارض - جنوب خيطان",
//     "معسكرات المباركية",
//     "ميدان حولي",
//     "مينا الزور",
//     "مينا الشعيبة",
//     "ميناء الأحمدي",
//     "ميناء شعيبة",
//     "ميناء عبد الله",
//     "هدية",
//     "وفرة",
//     "أمغرة"
// ];

let arCities = [];
let enCities = [];

export let citiesArEn = {};
export let citiesEnAr = {};

let arAreas = [];
export let enAreas = [];

export let areasArEn = {};
export let areasEnAr = {};

let enCitiesAndAreas = {};
let arCitiesAndAreas = {};

export let dict = [];
export let cities = [];
export let areas = {};

export let setDic = (lang) => {
    if (lang == "en") {
        dict = en_dic;
    } else {
        dict = ar_dic;
    }
    listCities().then(res => {
        arCities = [];
        enCities = [];
        if (res.error) {
            console.log(res.error);
        } else {
            let data = [...res];
            for (let i = 0; i < data.length; i++) {
                arCities.push(data[i].arName);
                enCities.push(data[i].enName);
                citiesArEn = { ...citiesArEn, [data[i].arName]: data[i].enName };
                citiesEnAr = { ...citiesEnAr, [data[i].enName]: data[i].arName };
            }
            listAreas().then(res2 => {
                if (res2.error) {
                    console.log(res2.error);
                } else {
                    let data = [...res2];
                    for (let i = 0; i < data.length; i++) {
                        arAreas.push(data[i].arName);
                        enAreas.push(data[i].enName);
                        areasArEn = { ...areasArEn, [data[i].arName]: data[i].enName };
                        areasEnAr = { ...areasEnAr, [data[i].enName]: data[i].arName };
                    }
                    for (let i = 0; i < arCities.length; i++) {
                        let arr = [];
                        for (let j = 0; j < data.length; j++) {
                            if (arCities[i] == data[j].city.arName) {
                                arr.push(data[j].arName);
                            }
                        }
                        arCitiesAndAreas = { ...arCitiesAndAreas, [arCities[i]]: [...arr] };
                    }
                    for (let i = 0; i < enCities.length; i++) {
                        let arr = [];
                        for (let j = 0; j < data.length; j++) {
                            if (enCities[i] == data[j].city.enName) {
                                arr.push(data[j].enName);
                            }
                        }
                        enCitiesAndAreas = { ...enCitiesAndAreas, [enCities[i]]: [...arr] };
                    }
                    if (lang == "en") {
                        cities = enCities;
                        areas = enCitiesAndAreas
                    } else {
                        cities = arCities;
                        areas = arCitiesAndAreas
                    }
                }
            });
        }
    });

}

