import React, { useState, useEffect, Fragment } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AdminDashboard from "../../AdminDashboard";
import { isAuthenticated } from "../../../../auth"
import { updateCategoryPosition } from "../../apiAdmin";
import { listCategories } from "../../../apiCore";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {default_url} from "../../../../index"
import { dict } from "../../../../dictionary";
import { Link, useHistory } from "react-router-dom";


const Categories = (props) => {
    let history = useHistory();
    const [data, setData] = useState([{}]);
    const [sorting, setSorting] = useState({
        active: false,
        error: "",
        success: false
    });
    const [arr, setArr] = useState([{}]);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: ""
    });
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        if (!sorting.active) {
            setArr(data);
        }
        setSorting({ ...sorting, active: !sorting.active });
    };

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        let pos = -1;
        for (let i = 0; i < data.length; i++) {
            if (deletedItem.id === data[i]._id) {
                pos = data[i].position;
                break;
            }
        }
        updateCategoryPosition(pos, 100000000, deletedItem.id, user._id, token);
        handleClose();
        window.location.href = (default_url + "admin/categories/delete?category_id=" + deletedItem.id)
    }

    useEffect(() => {
        async function fetchData() {
            const categories = await listCategories()
                .then(res => {
                    setData(res);
                })
        }
        fetchData();
    }, []);

    const { user, token } = isAuthenticated();

    const clickSubmit = (event) => {
        event.preventDefault();
        history.push(default_url + "admin/categories/create")
        // window.location.href = default_url + "admin/categories/create"
    }
    
    const showAlert = () => {
        if ((props.location.state || sorting.success || sorting.error)) {
            let msg = ""
            if (sorting.error) {
                msg = sorting.error;
            } else if (sorting.success) {
                msg = dict["categories_have_been_sorted_successfully"]
            } else if (props.location.state) {                
                switch (props.location.state.prevPath) {
                    case "create": msg = props.location.state.error ? props.location.state.error : dict["new_category_has_been_created"]; break;
                    case "delete": msg = props.location.state.error ? props.location.state.error : dict["category_has_been_deleted"]; break;
                    case "edit": msg = props.location.state.error ? props.location.state.error : dict["category_has_been_updated"]; break;
                    default: msg = "";
                }
            }
            return (
                <div
                    className={"alert " + (((props.location.state && props.location.state.error) || sorting.error) ? "alert-danger" : "alert-info") + " alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{textAlign: "start"}}>{msg}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{left: default_url == "/ar/" ?  "0" : "auto", right: default_url == "/ar/" ?  "auto" : "0"}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_category"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{textAlign: "start"}}>{"Are you sure you want to delete " + deletedItem.name + " category?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{textAlign: "start"}}>{"هل انت متأكد تريد حذف قسم " + deletedItem.name + "؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const allCategories = () => {
        return (
            !sorting.active &&
            data.sort((a, b) => a.position - b.position).map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerNumber" style={{float: default_url == "/en/" ? "left": "right"}}>{item.position}</span>
                        <span className="dataContainerTitle" style={{float: default_url == "/en/" ? "left": "right"}}>{item.enName + " | " + item.arName}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.enName + (item.arName != null ? " | " + item.arName : "")}
                            id={item._id}
                            style={{float: default_url == "/en/" ? "right": "left"}}
                        >{dict["delete"]}</a>
                        {popUp()}
                        <Link className="dataContainerAction" to={default_url + "admin/categories/edit?category_id=" + item._id}  style={{float: default_url == "/en/" ? "right": "left"}}>{dict["edit"]}</Link>
                    </div>
                )
            })
        )
    }

    const SortableItem = SortableElement(({ value, index }) => {
        return (
            <div key={value._id} index={index} className={"dataContainer " + (value.position === data.length ? "lastDataContainer" : "")}>
                <span className="dataContainerNumber" style={{float: default_url == "/en/" ? "left": "right"}}>{"#" + value.position}</span>
                <span className="dataContainerTitle" style={{float: default_url == "/en/" ? "left": "right"}}>{value.enName + " | " + value.arName}</span>
                <a className="dataContainerAction" href={default_url + "admin/categories/delete?category_id=" + value._id} style={{float: default_url == "/en/" ? "right": "left"}}>{dict["delete"]}</a>
                <a className="dataContainerAction" href={default_url + "admin/categories/edit?category_id=" + value._id} style={{float: default_url == "/en/" ? "right": "left"}}>{dict["edit"]}</a>
            </div>
        )

    })

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div>
                {
                    items.sort((a, b) => a.position - b.position).map((value, index) => (
                        <SortableItem
                            value={value}
                            index={index}
                            key={value._id}
                        />
                    ))
                }
            </div>
        )
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if(oldIndex == newIndex){
            return;
        }
        var id = "";
        for (let i = 0; i < data.length; i++) {            
            if (data[i].position == oldIndex + 1) {
                id = data[i]._id;
                break;
            }
        }
        updateCategoryPosition(oldIndex + 1, newIndex + 1, id, user._id, token);
        let tmp = arrayMove(arr, oldIndex, newIndex);
        for (let i = 0; i < tmp.length; i++) {
            tmp[i].position = i + 1;
        }
        setArr(tmp);
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["categories_list"]}</h3>
                <FormControlLabel
                    value="start"
                    control={
                        <Switch
                            checked={sorting.active}
                            onChange={handleChange}
                            color="primary"
                            className="switch"
                        />
                    }
                    label={dict["sort"]}
                    labelPlacement="start"
                    className="dashboardSortingSwitch"
                    style={{float: default_url == "/en/" ? "right": "left"}}
                />
                {allCategories()}
                {
                    sorting.active &&
                    <SortableList
                        items={arr}
                        onSortEnd={onSortEnd}
                        axis="y"
                    />
                }
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_category"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="2"
            data={form}
            categories={data}
            subcategories={[]}
        />
    )
}

export default Categories;