import React from 'react';
import {setDir} from "./index"
import Routes from "./Routes"
import { setDic } from './dictionary';

export const ArRoute = () => {
    setDir("rtl");
    setDic("ar");
    document.body.dir = "rtl";
    return(
        <Routes/>
    )
};

export const EnRoute = () => {
    setDir("ltr");
    setDic("en")
    document.body.dir = "ltr";
    return(
        <Routes/>
    )
};