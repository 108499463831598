import React, { useRef, useEffect, useState, Fragment } from "react";
import { dict } from "../../../dictionary";
import { IMAGE_URL } from "../../../config";
import { default_url } from "../../..";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { store } from 'react-notifications-component';
import { addItem } from "../../cartHelpers";
import { Link } from "react-router-dom";
import { checkFreeItemForProduct, listFreeItems } from "../../apiCore";
import Buy1Get1Free from "../../product view comoinents/Buy1Get1Free";
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    rightPaper: {
        textAlign: 'right',
        color: theme.palette.text.secondary,
        fontSize: '16px',
        boxShadow: 'none',
        height: '100%'
    },
    leftPaper: {
        textAlign: default_url == "/en/" ? 'right' : 'left',
        color: theme.palette.text.secondary,
        fontSize: '16px',
        boxShadow: 'none',
        height: '100%'
    },
    formControl: {
        width: "100%",
        minWidth: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function ProductItem(props) {
    const classes = useStyles();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [size, setSize] = useState((props.size && props.size.length == 1) ? 0 : 'empty');
    const [selectedSize, setSelectedSize] = useState(-1);
    const [height1, setHeight1] = useState();
    const [height2, setHeight2] = useState();
    const [showBuy1Get1Free, setShowBuy1Get1Free] = useState(false);
    const [freeItemsList, setFreeItemsList] = useState([]);
    const [hasFreeItem, setHasFreeItem] = useState(false);
    const [cookies, setCookie] = useCookies(['_fbp']);

    var link = props.model;
    link = props.link;
    let divClass = props.divClass;

    const checkFreeItem = () => {

    }

    useEffect(() => {
        setHeight1(ref1.current ? ref1.current.offsetWidth : 0);
        setHeight2(ref2.current ? ref2.current.offsetWidth : 0);
    }, [ref1.current, ref2.current])

    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }

    const handleChange = (event) => {
        setSize(event.target.value);
    };

    const handleAddToCart = async () => {
        if (props.size && props.size.length >= 1 && size == "empty") {
            showError();
        } else {
            let id = "";
            if (size == "empty") {
                id = props.ids[0];
            } else {
                id = props.ids[size];
            }
            checkFreeItemForProduct(id).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    if (res) {
                        listFreeItems(id).then(res => {
                            if (res.error) {
                                console.log(res.error)
                            } else {
                                setFreeItemsList([...res]);
                                setShowBuy1Get1Free(true)
                            }
                        });
                    } else {
                        if (size == "empty") {
                            addItem(props.ids[0], 1, props.link, props.prices[0], cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, showSuccess());
                        } else {
                            addItem(props.ids[size], 1, props.link, props.prices[size], cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, showSuccess());
                        }
                    }
                }
            });
        }
    }

    const handleAddToCart2 = () => {
        if (props.size && props.size.length >= 1 && size == "empty") {
            showError();
        } else {
            if (size == "empty") {
                addItem(props.ids[0], 1, props.link, props.prices[0], cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, showSuccess());
            } else {
                addItem(props.ids[size], 1, props.link, props.prices[size], cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, showSuccess());
            }
        }
    }

    const showSuccess = () => {
        store.addNotification({
            message: dict['product_has_been_added_to_cart'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showError = () => {
        store.addNotification({
            message: dict['please_select_the_size'],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const getImage1 = () => {
        return props.images[size][0];
    }

    const getImage2 = () => {
        return props.images[size][props.images[size].length - 1];
    }

    const loadSizes = () => {
        if (props.size && props.size.length >= 1) {
            return (
                <Grid item xs={8}>
                    <Paper className={classes.rightPaper}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="age-native-simple" style={{
                                transform: 'translate(0, 0) scale(1)',
                                right: default_url == "/en/" ? 'auto' : 0,
                                left: default_url == "/en/" ? 0 : 'auto'
                            }}>
                                {(props.category == "asna" && props.subcategory == "asna tea cups") || (props.category == "asna" && props.subcategory == "asna coffee cups") || props.category == "steel trays" || (props.listName && (props.listName.includes("صواني ستيل") || props.listName.includes("أطفم فناجين قهوة أسنى") || props.listName.includes("فناجين قهوة تركية أسنى") || props.listName.includes("أكواب شاي أسنى"))) ? "الطقم" : dict['size']}
                            </InputLabel>
                            <Select
                                native
                                value={size}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'size',
                                    id: 'age-native-simple',
                                }}
                                style={{
                                    marginTop: '10px',

                                }}
                            >
                                <option aria-label="None" value="empty">{(props.category == "asna" && props.subcategory == "asna tea cups") || (props.category == "asna" && props.subcategory == "asna coffee cups") ||  props.category == "steel trays" || (props.listName && (props.listName.includes("صواني ستيل") || props.listName.includes("أطفم فناجين قهوة أسنى") || props.listName.includes("فناجين قهوة تركية أسنى") || props.listName.includes("أكواب شاي أسنى")))  ? "اختر الطقم" : dict['select_size']}</option>
                                {
                                    props.size.map((s, i) => {
                                        return <option value={i}>{s}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
            )
        }
        return (
            <Grid item xs={6}>

            </Grid>
        )
    }

    const outOfStockStyle = {
        backgroundColor: "red",
        color: "white",
        position: "absolute",
        textAlign: "center",
        fontSize: "12px",
        lineHeight: "25px",
        height: "25px",
        padding: "0 10px",
        right: default_url === "/en/" ? "0" : "",
        left: default_url === "/ar/" ? "0" : "",
        zIndex: 1000,
    }

    return (
        <div className={divClass} style={{ marginBottom: "15px" }}>
            {showBuy1Get1Free && <Buy1Get1Free open={showBuy1Get1Free} quantity={1} handleClose={() => { setShowBuy1Get1Free(false) }} items={freeItemsList} next={handleAddToCart2} />}
            <div className="product-grid productCard">
                {
                    (props.quantity[size] <= 0 || (props.size && props.size.length == 0 && props.quantity[0] <= 0)) && <span style={outOfStockStyle}>{dict["out_of_stock"]}</span>
                }
                <div className="product-image" style={{aspectRatio : "1 / 1"}}>
                    <Link to={link} style={{ height: height1 }}>
                        <img ref={ref1} className={"pic-1 " + (props.img1 !== props.img2 ? "hover_pic-1" : "")} src={size == "empty" ? props.img1 : getImage1()} height={height1} width="100%" effect="blur" />
                        {props.img1 !== props.img2 && <img ref={ref2} className="pic-2" src={size == "empty" ? props.img2 : getImage2()} height={height2} width="100%" effect="blur"/>}
                    </Link>
                    <Link className="add-to-cart" to={link}>{dict["view_product"]}</Link>
                </div>
                <div className="product-content productDescription">
                    <h3 className="title" style={{ textAlign: "start", direction: default_url == "/ar/" ? "rtl" : "ltr", height: "50px" }}><a href={link}>{props.title}<br />{ }</a></h3>
                    <div style={{ height: "43px" }}>


                        <span className={"price " + (props.discount ? "discount" : "")} style={{ display: "inlineBlock", textAlign: "start", float: default_url === "/en/" ? "left" : "right", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>
                            {
                                size == "empty" ?
                                    (props.minPrice === props.maxPrice || props.minPriceToShow == props.maxPriceToShow ?
                                        fixLength(props.minPrice) + " " + dict["kd"] :
                                        fixLength(props.minPrice) + " - " + fixLength(props.maxPrice) + " " + dict["kd"]) :
                                    fixLength(props.prices[size]) + " " + dict["kd"]
                            }
                        </span>
                        <span className="discountValue">{props.discountValue}</span>
                        <span className="afterDiscount" style={{ textAlign: "start", display: (props.discount && size == "empty") || (size != "empty" && props.discountPrices[size]) ? "inlineBlock" : "none", float: default_url === "/en/" ? "left" : "right", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>
                            {
                                size == "empty" ? (
                                    props.minPriceToShow === props.maxPriceToShow ?
                                        fixLength(props.minPriceToShow) + " " + dict["kd"] :
                                        fixLength(props.minPriceToShow) + " - " + fixLength(props.maxPriceToShow) + " " + dict["kd"]) :
                                    fixLength(props.discountPrices[size]) + " " + dict["kd"]
                            }
                        </span>
                    </div>
                </div>


            </div>
            <Grid container spacing={2} style={{ direction: default_url == "/en/" ? "ltr" : "rtl" }}>
                {loadSizes()}
                {
                    props.quantity[size] <= 0 || (props.size && props.size.length == 0 && props.quantity[0] <= 0) ?
                        <Grid item xs={4} >
                            <Paper className={classes.leftPaper}>
                                <Button variant="contained" style={{
                                    padding: '0 0',
                                    minWidth: 0,
                                    backgroundColor: "white",
                                    height: '100%',
                                    boxShadow: 'none'
                                }}
                                    disabled
                                    onClick={handleAddToCart}
                                >
                                    <AddShoppingCartIcon style={{ color: 'gray', fontSize: "38px" }} />
                                </Button>

                            </Paper>
                        </Grid>
                        :
                        <Grid item xs={4} >
                            <Paper className={classes.leftPaper}>
                                <Button variant="contained" style={{
                                    padding: '0 0',
                                    minWidth: 0,
                                    backgroundColor: "white",
                                    height: '100%',
                                    boxShadow: 'none'
                                }}

                                    onClick={handleAddToCart}
                                >
                                    <AddShoppingCartIcon style={{ color: '#E4BC4B', fontSize: "38px" }} />
                                </Button>

                            </Paper>
                        </Grid>
                }

            </Grid>
        </div>
    )
}

export default ProductItem;