import { API } from "../../config"

export const read = (userId, token) => {
    return fetch(`${API}/user/${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },
        credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const update = (userId, token, user) => {
    return fetch(`${API}/user/${userId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateFromAdmin = (userId, token, user) => {
    return fetch(`${API}/user/update-from-admin/${userId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listAddresses = (userId, token) => {
    return fetch(`${API}/user/addresses/${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const addAddress = (userId, token, address) => {
    return fetch(`${API}/user/add/address/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify(address)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateAddress = (userId, token, addressId, address) => {
    return fetch(`${API}/user/address/${userId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({addressId, address})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteAddress = (userId, token, addressId) => {
    return fetch(`${API}/user/delete/address/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({addressId: addressId})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listOrders = (userId, token) => {
    return fetch(`${API}/user/orders/${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const addReview = (userId, token, review) => {
    return fetch(`${API}/review/create/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
        body: JSON.stringify(review)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getReviews = (userId, token) => {
    return fetch(`${API}/reviews/by-user/${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteReview = (userId, token, reviewId) => {
    return fetch(`${API}/review/${reviewId}/${userId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const forgetpassword = (email, lang) => {
    return fetch(`${API}/users/forgetpassword`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },credentials: 'include',
        body: JSON.stringify({email: email, lang: lang})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const resetPassword = (password, token) => {
    return fetch(`${API}/users/resetpassword`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },credentials: 'include',
        body: JSON.stringify({password: password, resetPasswordLink: token})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getPointsByUser = (userId, token, filters) => {
    return fetch(`${API}/points/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({...filters, user: userId})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateUsedPoints = (ids, used, userId, token) => {
    return fetch(`${API}/points/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({ids: ids, used: used})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const activatePointsProgram = (userId, token) => {
    return fetch(`${API}/user/activate-points/${userId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}