import React, { useEffect, useState } from 'react';
import SortBy from './SortBy'
import ProductsMenu from './ProductsMenu'

function ProductsContainer(props) {
    
    const handleNeededPages = (needed) => {
        props.neededPages(needed);
    }

    return (
        <div className={"productsContainer " + props.class}>
            <h5 className="productsContainerTitle" style={{ textAlign: "start" }}>{props.title}</h5>
            <SortBy
                {...props}
                sizes={props.sizes}
                colors={props.colors}
            />
            <ProductsMenu
                divClass="col-lg-3 col-6"
                items={props.items}
                items3={props.items3}
                category={props.category}
                subcategory={props.subcategory}
                list={props.list}
            />
        </div>
    )
}

export default ProductsContainer;