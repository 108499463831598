import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createProduct, createMultipleProducts } from "../../apiAdmin"
import { listCategories, listSubcategories } from "../../../apiCore"
import { Redirect } from "react-router-dom";
import { from } from "form-data";
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function AddProduct() {
    const classes = useStyles();
    const [values, setValues] = useState({
        sku: null,
        category: null,
        subcategory: null,
        itemCode: null,
        arName: null,
        enName: null,
        arDescription: null,
        enDescription: null,
        arTitle: null,
        enTitle: null,
        price: 0.0,
        enMadeIn: null,
        arMadeIn: null,
        enWarranty: null,
        arWarranty: null,
        related1: null,
        related2: null,
        related3: null,
        related4: null,
        related5: null,
        related6: null,
        related7: null,
        related8: null,
        related9: null,
        related10: null,
        brand: null,
        quantity: 0,
        size: null,
        color: null,
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        tags: null,
        hidden: null,
        error: null,
        redirectToReferrer: false,
        formData: ""
    });
    const [mood, setMood] = useState({
        single: false,
        multiple: true
    })
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [err, setErr] = useState(false);
    const { user, token } = isAuthenticated();
    const { sku, category, subcategory, itemCode, arName, enName, arDescription, enDescription, enTitle, arTitle, price, enMadeIn, arMadeIn, enWarranty, arWarranty, related1, related2, related3, related4, related5, related6, related7, related8, related9, related10, brand, quantity, size, color, image1, image2, image3, image4, image5, tags, hidden, error, redirectToReferrer, formData } = values;
    const { single, multiple } = mood;
    const [loading, setLoading] = useState(false);

    const loadCategories = () => {
        listCategories().then(data => {
            if (data.error) {
                setErr(data.error);
            } else {
                setCategories(data);
            }
        })
    }

    const loadSubcategories = () => {
        listSubcategories().then(data => {
            if (data.error) {
                setErr(data.error);
            } else {
                setSubcategories(data);
            }
        })
    }

    useEffect(() => {
        setValues({ ...values, formData: new FormData() });
        loadCategories();
        loadSubcategories();
    }, []);

    const handleChange = name => event => {
        if (single) {
            setValues({ ...values, error: false, [name]: event.target.value });
        } else {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.set(`products`, event.target.files[i]);
            }
        }
    };

    const handleCheckBoxChange = name => event => {
        if (name == "single") {
            setMood({ 'single': event.target.checked, 'multiple': !event.target.checked });
        } else {
            setMood({ 'single': !event.target.checked, 'multiple': event.target.checked });
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (!single && !multiple) {
            return;
        }
        setLoading(true);
        if (single) {
            createProduct({ sku, category, subcategory, itemCode, arName, enName, arDescription, enDescription, arTitle, enTitle, price, related1, related2, related3, related4, related5, related6, related7, related8, related9, related10, brand, quantity, size, color, image1, image2, image3, image4, image5, tags, hidden }, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: data.error, redirectToReferrer: false });
                    } else {
                        setValues({
                            sku: null,
                            category: null,
                            subcategory: null,
                            itemCode: null,
                            arName: null,
                            enName: null,
                            arDescription: null,
                            enDescription: null,
                            arTitle: null,
                            enTitle: null,
                            price: 0.0,
                            related1: null,
                            related2: null,
                            related3: null,
                            related4: null,
                            related5: null,
                            related6: null,
                            related7: null,
                            related8: null,
                            related9: null,
                            related10: null,
                            brand: null,
                            quantity: 0,
                            size: null,
                            color: null,
                            image1: null,
                            image2: null,
                            image3: null,
                            image4: null,
                            image5: null,
                            hidden: false,
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        } else {
            createMultipleProducts(formData, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: data.error, redirectToReferrer: false });
                    } else {
                        setValues({
                            sku: null,
                            category: null,
                            subcategory: null,
                            itemCode: null,
                            arName: null,
                            enName: null,
                            arDescription: null,
                            enDescription: null,
                            arTitle: null,
                            enTitle: null,
                            price: 0.0,
                            related1: null,
                            related2: null,
                            related3: null,
                            related4: null,
                            related5: null,
                            related6: null,
                            related7: null,
                            related8: null,
                            related9: null,
                            related10: null,
                            brand: null,
                            quantity: 0,
                            size: null,
                            color: null,
                            image1: null,
                            image2: null,
                            image3: null,
                            image4: null,
                            image5: null,
                            hidden: false,
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        }
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            let st = "";
            const params = new URLSearchParams(window.location.search);
            let itemCode2 = params.get('itemCode');
            if (itemCode2) {
                st += ("itemCode=" + itemCode2)
            }
            let show = parseInt(params.get('show'));
            if (show) {
                if (st == "") {
                    st += ("show=" + show)
                } else {
                    st += ("&show=" + show)
                }
            }
            return (
                <Redirect to={{ pathname: default_url + 'admin/products', search: st, state: { prevPath: "create", error } }} />
            )
        }
    }

    const categoriesSelector = () => {
        return (
            <select onChange={handleChange("category")} className="inputField form-control" value={category}>
                <option value={null}>{dict["select_category"]}</option>
                {
                    categories.sort((a, b) => a.position - b.position).map((category, index) => {
                        return (
                            <option key={index} value={category._id}>{category.enName + " | " + category.arName}</option>
                        )
                    })

                }
            </select>
        )
    }

    const subcategoriesSelector = () => {
        return (
            <select onChange={handleChange("subcategory")} className="inputField form-control" value={subcategory}>
                <option value={null}>{dict["select_subcategory"]}</option>
                {
                    subcategories.sort((a, b) => a.position - b.position).map((sub, index) => {
                        return (
                            sub.category == category &&
                            <option key={index} value={sub._id}>{sub.enName + (sub.arName != null ? " | " + sub.arName : "")}</option>
                        )
                    })

                }
            </select>
        )
    }

    const hiddenSelector = () => {
        return (
            <select onChange={handleChange("hidden")} name="hidden" className="inputField form-control" value={hidden}>
                <option value=""></option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const singleProduct = () => {
        return (
            <div className="signleCategoryForm" style={{ display: single ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["sku"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("sku")} type="text" value={sku} className="inputField form-control" id="sku" placeholder={dict["sku"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["category"]} <span className="redStar">*</span></label><br />
                {categoriesSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory"]} </label><br />
                {subcategoriesSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["item"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("itemCode")} type="text" value={itemCode} className="inputField form-control" id="itemCode" placeholder={dict["item"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["product_english_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="name" placeholder={dict["product_english_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["product_arabic_name"]} <span className="redStar">*</span></label>
                <input dir="rtl" onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="name" placeholder={dict["product_arabic_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["english_description"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enDescription")} type="text" value={enDescription} className="inputField form-control" id="enDescription" placeholder={dict["english_description"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["arabic_description"]} <span className="redStar">*</span></label>
                <input dir="rtl" onChange={handleChange("arDescription")} type="text" value={arDescription} className="inputField form-control" id="arDescription" placeholder={dict["arabic_description"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["english_title"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enTitle")} type="text" value={enTitle} className="inputField form-control" id="enTitle" placeholder={dict["english_title"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["arabic_title"]} <span className="redStar">*</span></label>
                <input dir="rtl" onChange={handleChange("arTitle")} type="text" value={arTitle} className="inputField form-control" id="arTitle" placeholder={dict["arabic_title"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["hidden"]} </label>
                {hiddenSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["brand"]}</label>
                <input onChange={handleChange("brand")} type="text" value={brand} className="inputField form-control" id="brand" placeholder={dict["brand"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["price"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("price")} type="number" value={price} className="inputField form-control" id="price" placeholder={dict["price"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["quantity"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("quantity")} type="number" value={quantity} className="inputField form-control" id="quantity" placeholder={dict["quantity"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["size"]}</label>
                <input onChange={handleChange("size")} type="text" value={size} className="inputField form-control" id="size" placeholder={dict["size"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["color"]}</label>
                <input onChange={handleChange("color")} type="text" value={color} className="inputField form-control" id="color" placeholder={dict["color"]}></input>
                <div className="row">
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["warranty_(english)"]}</label>
                        <input onChange={handleChange("enWarranty")} type="text" value={enWarranty} className="inputField form-control" id="enWarranty" placeholder={dict["warranty_(english)"]}></input>
                    </div>
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["warranty_(arabic)"]}</label>
                        <input dir="rtl" onChange={handleChange("arWarranty")} type="text" value={arWarranty} className="inputField form-control" id="arWarranty" placeholder={dict["warranty_(arabic)"]}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["made_in_(english)"]}</label>
                        <input onChange={handleChange("enMadeIn")} type="text" value={enMadeIn} className="inputField form-control" id="enMadeIn" placeholder={dict["made_in_(english)"]}></input>
                    </div>
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["made_in_(arabic)"]}</label>
                        <input dir="rtl" onChange={handleChange("arMadeIn")} type="text" value={arMadeIn} className="inputField form-control" id="arMadeIn" placeholder={dict["made_in_(arabic)"]}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 1"}</label>
                        <input onChange={handleChange("related1")} type="text" value={related1} className="inputField form-control" id="related1" placeholder={dict["related"] + " 1"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 2"}</label>
                        <input onChange={handleChange("related2")} type="text" value={related2} className="inputField form-control" id="related2" placeholder={dict["related"] + " 2"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 3"}</label>
                        <input onChange={handleChange("related3")} type="text" value={related3} className="inputField form-control" id="related3" placeholder={dict["related"] + " 3"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 4"}</label>
                        <input onChange={handleChange("related4")} type="text" value={related4} className="inputField form-control" id="related4" placeholder={dict["related"] + " 4"}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 5"}</label>
                        <input onChange={handleChange("related5")} type="text" value={related5} className="inputField form-control" id="related5" placeholder={dict["related"] + " 5"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 6"}</label>
                        <input onChange={handleChange("related6")} type="text" value={related6} className="inputField form-control" id="related6" placeholder={dict["related"] + " 6"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 7"}</label>
                        <input onChange={handleChange("related7")} type="text" value={related7} className="inputField form-control" id="related7" placeholder={dict["related"] + " 7"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 8"}</label>
                        <input onChange={handleChange("related8")} type="text" value={related8} className="inputField form-control" id="related8" placeholder={dict["related"] + " 8"}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 9"}</label>
                        <input onChange={handleChange("related9")} type="text" value={related9} className="inputField form-control" id="related9" placeholder={dict["related"] + " 9"}></input>
                    </div>
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 10"}</label>
                        <input onChange={handleChange("related10")} type="text" value={related10} className="inputField form-control" id="related10" placeholder={dict["related"] + " 10"}></input>
                    </div>
                </div>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 1"}</label>
                <input onChange={handleChange("image1")} type="text" value={image1} className="inputField form-control" id="image1" placeholder={dict["image"] + " 1"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 2"}</label>
                <input onChange={handleChange("image2")} type="text" value={image2} className="inputField form-control" id="image2" placeholder={dict["image"] + " 2"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 3"}</label>
                <input onChange={handleChange("image3")} type="text" value={image3} className="inputField form-control" id="image3" placeholder={dict["image"] + " 3"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 4"}</label>
                <input onChange={handleChange("image4")} type="text" value={image4} className="inputField form-control" id="image4" placeholder={dict["image"] + " 4"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 5"}</label>
                <input onChange={handleChange("image5")} type="text" value={image5} className="inputField form-control" id="image5" placeholder={dict["image"] + " 5"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["tags"]}</label>
                <input onChange={handleChange("tags")} type="text" value={tags} className="inputField form-control" id="tags" placeholder={dict["tags"]}></input>
            </div>
        )
    }
    const multipleProducts = () => {
        return (
            <div className="multipleCategoriesForm" style={{ display: multiple ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="excel" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_product"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("single")} type="checkbox" checked={single} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_one_product"]}</label>
                    </div>
                    {singleProduct()}
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("multiple")} type="checkbox" checked={multiple} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_multiple_products"]}</label>
                    </div>
                    {multipleProducts()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default AddProduct;