import React from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"

function AboutUs() {
    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900", marginBottom: "10px"  }}>تعرف علينا:</h1>
                <p>
                نحن هنا لنجول العالم ونبحث لكم عن أفضل منتجات الأواني المنزلية عالية الجودة ذات طابع ضيافة راقي من مصانع محترفة، مع تطويرها وتخصيصها لتتوافق مع تراثنا العربي الأصيل.
                </p>

                <br />

                <h1 style={{ fontSize: "20px", fontWeight: "900", marginBottom: "10px" }}>الرؤية:</h1>
                <p>
                ان نكون الرائد الأول في الكويت في استيراد وتطوير الأواني المنزلية الراقية الملبية لحاجات العملاء والتي تجمع ما بين متطلبات الحاضر وعراقة الماضي.
                </p>

                <br />

                <h1 style={{ fontSize: "20px", fontWeight: "900", marginBottom: "10px"  }}>الرسالة:</h1>
                <p>
                ان نقدم لكم منتجات راقية تليق بكم.
                </p>
            </div>
        </div>
    )
}

export default AboutUs;