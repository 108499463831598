import React, { useEffect, useState } from "react";
import FooterInfo from "./footer components/FooterInfo"
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { dict } from "../dictionary";
import { default_url } from "../index"
import SendIcon from '@material-ui/icons/Send';
import { subscribeNewsletter } from "./apiCore";
import { store } from 'react-notifications-component';
import { Link, useHistory } from "react-router-dom";
import { pageView } from "./apiCore";
import { useCookies } from 'react-cookie';
import { isAuthenticated } from "../auth";
import { sha256 } from "js-sha256";

const isMobile = window.innerWidth < 1024 ? true : false;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            width: '100%',
            marginInlineEnd: "0",
            marginInlineStart: "0",
            display: "inline-flex",
            borderRadius: "0",
        },
    },
    button: {
        width: isMobile ? "20%" : "10%",
        marginInlineEnd: "0",
        marginInlineStart: "0",
        position: "absolute",
        height: "100%",
        borderRadius: "0"
    },
    input: {
        width: isMobile ? "80%" : "90%",
        borderRadius: "0",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },
    lableStyle: {
        transform: "translate(-12px, 20px) scale(1)",
        right: "0"
    },
    arInputStyle: {
        textAlign: "right !important"
    },
    enInputStyle: {
        textAlign: "left !important"
    },
    flipIcon: {
        transform: "scaleX(-1)"
    }
}));

function Footer() {

    const [email, setEmail] = useState("");
    const [show, setShow] = useState(false);
    const classes = useStyles();

    const history = useHistory()
    const [cookies, setCookie] = useCookies(['_fbp']);
    const { user } = isAuthenticated();

    useEffect(() => {
        const url = window.location.pathname;
        let arr = url.split('/');
        if (arr.length > 0) {
            if (arr[arr.length - 1] != "checkout" && arr[arr.length - 1] != "cart" && arr[arr.length - 1] != "collections") {
                setShow(true);
            } else {
                setShow(false)
            }
        }
        pageView(cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, url, user && user.mobile ? user.mobile : null).then(response => {
            if (response.error) {
                console.log(response.error);
            }
        });
        if (user && arr.length > 1 && arr[2] == "products") {
            window.ttq.identify({
                "email": sha256(user.email), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                "phone_number": user.mobile ? sha256(user.mobile) : "",
                "external_id": sha256(user._id) // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
            });
            window.ttq.track('ViewContent', {
                "contents": [
                    {
                        "brand": arr[3] ? arr[3] : "products" // string. The brand name of the page or product. Example: "Nike".
                    }
                ]
            });
        }
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            let arr = (location.pathname).split('/');
            if (arr.length > 0) {
                if (arr[arr.length - 1] != "checkout" && arr[arr.length - 1] != "cart" && arr[arr.length - 1] != "collections") {
                    setShow(true);
                } else {
                    setShow(false)
                }
            }
            pageView(cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, location.pathname, user && user.mobile ? user.mobile : null).then(response => {
                if (response.error) {
                    console.log(response.error);
                }
            });
        })
    }, [history]);


    var footerItems = [
        {
            title: "information",
            items: ["about_us", "contact_us"]
        },
        {
            title: "services",
            items: ["delivery", "return_and_exchange", "warranty"]
        },
        {
            title: "legal",
            items: ["terms_and_conditions", "privacy_policy"]
        },
        {
            title: "payment_methods",
            items: ["cash_on_delivery", "k_net"]
        },
        {
            title: "my_account",
            items: [["my_account", "account-information"], ["my_wishlist", "wishlist"], ["my_address", "address-book"]]
        },
        {
            title: "important_links",
            items: ["distributors", "wholesale", "feedback", "blog"]
        }
    ]

    const showSuccess = () => {
        store.addNotification({
            message: dict['you_have_subscribed_in_newsletter'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showError = (msg) => {
        store.addNotification({
            message: msg ? msg : "somthing_went_wrong",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const handleSubcribeClick = event => {
        event.preventDefault();
        subscribeNewsletter(email).then(res => {
            if (res.error) {
                showError(res.error);
            } else {
                showSuccess();
                setEmail("");
            }
        })
    }

    return (
        show ? <div className="container-fluid footer">
            <div className="subscription-section">
                <form className={classes.root} noValidate autoComplete="off" style={{ textAlign: "justify" }} onSubmit={handleSubcribeClick}>
                    <TextField className={classes.input} id="outlined-basic" label={dict["subscribe_newsletter"]} variant="filled"
                        InputLabelProps={{ className: default_url == "/ar/" ? classes.lableStyle : "" }}
                        inputProps={{ dir: "auto" }}
                        value={email}
                        onChange={event => { setEmail(event.target.value) }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<SendIcon className={default_url == "/ar/" ? classes.flipIcon : ""} />}
                        onClick={handleSubcribeClick}
                    >
                    </Button>
                </form>
            </div>
            <div className="row info">
                {footerItems.map((item, index) => {
                    return (
                        <FooterInfo
                            key={index}
                            title={item.title}
                            items={item.items}
                        />
                    )
                })}
            </div>
            <div className="copyright">
                <span>{dict["royal_house_general_trading_and_contracting_company"]}</span>
                <br />
                <br />
                <span>{dict["all_rights_reserved_2023"]}</span>
            </div>
            <span id="siteseal"><script async type="text/javascript" src="https://seal.starfieldtech.com/getSeal?sealID=4zncYJKGOZMBHRTfyPMrpSRC8Ess5X5zzIaQqCBpFLpxsuoHOHdoWzbKT1aw"></script></span>
        </div> : <></>
    )
}

export default Footer;