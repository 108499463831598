import React, { useState, useEffect } from "react";
import { getGeneral } from "./apiCore";
import { default_url } from "../index"
import { dict } from "../dictionary"

function AnnouncementBar() {

    const [announcement, setAnnouncement] = useState({
        _id: "",
        stringValue: "",
        booleanValue: "",
        error: "",
        success: false
    });

    const loadAnnouncement = () => {
        getGeneral("name", "announcement").then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAnnouncement({ _id: res._id, stringValue: res.stringValue, booleanValue: res.booleanValue });
            }
        });
    }

    useEffect(() => {
        loadAnnouncement();
    }, []);

    return (
        <div style={{ display: announcement.booleanValue ? '' : 'none', fontSize: "14px", width: "100%", zIndex: '10000', padding: '7px 5px', borderRadius: "0", backgroundColor: "#E4BC4B", textAlign: 'center', color: 'white'}}>
            {announcement.stringValue}
        </div>
    )
}

export default AnnouncementBar;