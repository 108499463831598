import React, { useState } from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"
import { createFeedback } from "./apiCore"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { store } from 'react-notifications-component';
import { makeStyles } from '@material-ui/core/styles';
import zIndex from "@material-ui/core/styles/zIndex";
import { isAuthenticated } from "../auth";
import { Link, useHistory } from "react-router-dom";
import { activatePointsProgram } from "./user/apiUser";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function ActivatePoints() {
    let history = useHistory();
    const classes = useStyles();
    const [values, setValues] = useState({
        customerName: "",
        mobile: "",
        feedback: "",
        error: null,
        success: false
    });
    const [loading, setLoading] = useState(false);
    const [agreement, setAgreement] = useState(false);

    const { user, token } = isAuthenticated();

    const { customerName, mobile, feedback, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    }

    const handleAgreementChange = (event) => {
        setAgreement(event.target.checked);
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (user) {
            event.preventDefault();
            setLoading(true);
            if (!agreement) {
                showAgrementError();
                setLoading(false);
            } else {
                activatePointsProgram(user._id, token).then(res => {
                    if (res.error) {
                        console.log(res.error);
                        showActivationError();
                    } else {
                        let tmp = JSON.parse(localStorage.getItem('jwt'));
                        localStorage.setItem('jwt', JSON.stringify({...tmp, user: {...tmp.user, isPointsActive: true}}));
                        history.push(default_url + "user/points");
                    }
                    setLoading(false);
                })
            }
        } else {
            history.push(default_url + "signin");
        }
        setLoading(true);
        createFeedback({ customerName, mobile, feedback })
            .then(data => {
                setLoading(false);
                if (data.error) {
                    console.log(data.error);
                    setValues({ ...values, error: data.error, success: false });
                } else {
                    setValues({
                        customerName: "",
                        mobile: "",
                        feedback: "",
                        error: null,
                        success: true
                    });
                }
            })
    }

    const showError = () => {
        if (!error) {
            return;
        }
        store.addNotification({
            message: error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        })
        setValues({ ...values, error: "" });
    }

    const showSuccess = () => {
        if (!success) {
            return;
        }
        store.addNotification({
            message: "تم تقديم الاقتراح بنجاح، سيتم التواصل معكم في اقرب وقت",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                showIcon: true
            },
            className: "successNotification"
        })
        setValues({ ...values, success: false });
    }

    const showAgrementError = () => {
        store.addNotification({
            message: dict["please_agree_on_terms_and_conditions"],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 3500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showActivationError = () => {
        store.addNotification({
            message: dict["something_went_wrong._try_again_later_or_contact_support"],
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 3500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const showFeedbackForm = () => {
        return (
            <form >
                {showError()}
                {showSuccess()}
                <h6 style={{ float: default_url === "/en/" ? "right" : "left", fontSize: "12px", color: "red", marginTop: "-5px" }}>{dict["required"]}</h6>
                <div>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("customerName")} type="text" value={customerName} className="inputField form-control" id="customer_name" placeholder={dict["name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("mobile")} type="text" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["feedback"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("feedback")} type="text" value={feedback} className="inputField form-control" id="feedback" placeholder={dict["feedback"]}></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["send_feedback"]}</button>
                </div>
            </form>

        )

    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const showActivation = () => {
        return (
            <div style={{padding: "0 10px"}}>
                <div style={{ textAlign: "start" }}>
                    ماهو برنامج النقاط؟
                </div>
                <div style={{ textAlign: "start" }}>
                    هو برنامج خصومات حصرية لدى رويال هاوس، يقدم البرنامج تجربة مميزة لجمع النقاط واستخدامها للحصول على خصومات خاصة لعملائنا المميزين
                </div>

                <br />
                <br />

                <div style={{ textAlign: "start" }}>
                    كيفية جمع النقاط واستخدامها
                </div>
                <div style={{ textAlign: "start" }}>
                    - قم بإنشاء حساب في المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - اجمع النقاط عبر الطلب من المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - استبدل هذه النقاط عند الرغبة بالطلب مرة اخرى واحصل على خصم خاص ( الاستبدال غير ممكن في العروض والخصومات )
                </div>

                <br />
                <br />

                <div style={{ textAlign: "start" }}>
                    الشروط والاحكام
                </div>
                <div style={{ textAlign: "start" }}>
                    - يحصل العميل على نقطة واحدة مقابل كل دينار يصرف في المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - النقطة تعادل 100 فلس
                </div>
                <div style={{ textAlign: "start" }}>
                    - النقاط صالحة لمدة 6 شهور من تاريخ الطلب (لكل طلب على حدا)
                </div>
                <div style={{ textAlign: "start" }}>
                    - لا يمكن استخدام النقاط مع العروض والخصومات
                </div>
                <div style={{ textAlign: "start" }}>
                    - برنامج النقاط حصريا عبر المتجر الالكتروني
                </div>
                <div style={{ textAlign: "start" }}>
                    - يجب على العميل انشاء حساب في المتجر الاكتروني للانضمام الى البرنامج
                </div>

                <br />

                <div class="form-check checkBoxSignUp" style={{ paddingInlineStart: "2%" }}>
                    <input onClick={handleAgreementChange} type="checkbox" checked={agreement} class="form-check-input" id="exampleCheck1" />
                    <label style={{ fontSize: "16px", fontWeight: "700" }} class="form-check-label" for="exampleCheck1">{dict["i_agree_to_the_terms_and_conditions"]} </label>
                </div>
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["join_points_program"]}</button>
            </div>
        );
    }


    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            {showBackdrop()}
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center' }}>{dict['join_points_program']}</h1>
                <br />
                {showActivation()}
            </div>
        </div>
    )
}

export default ActivatePoints;