import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { Redirect } from "react-router-dom";
import { createMultipleFreeItems, deleteFreeItem, listAllFreeItems, updateProduct } from "../../apiAdmin"
import { getProduct, listCategories, listSubcategories } from "../../../apiCore"
import * as qs from 'query-string';
import { from } from "form-data";
import Chip from '@material-ui/core/Chip';
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";
import { Button, TextField } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function UpdateProduct() {
    const classes = useStyles();

    const [showLoadingBackdrop, setShowBackdrop] = useState(false);
    const [values, setValues] = useState({
        sku: null,
        category: null,
        subcategory: null,
        itemCode: null,
        arName: null,
        enName: null,
        arDescription: null,
        enDescription: null,
        arTitle: null,
        enTitle: null,
        price: 0.0,
        enMadeIn: null,
        arMadeIn: null,
        enWarranty: null,
        arWarranty: null,
        related1: null,
        related2: null,
        related3: null,
        related4: null,
        related5: null,
        related6: null,
        related7: null,
        related8: null,
        related9: null,
        related10: null,
        brand: null,
        quantity: 0,
        size: null,
        color: null,
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        hidden: null,
        freeItem: null,
        tags: null,
        error: null,
        redirectToReferrer: false,
        formData: ""
    });
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [err, setErr] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [newFreeItemCode, setNewFreeItemCode] = useState();
    const [freeItemsList, setFreeItemsList] = useState([]);
    const [values2, setValues2] = useState({
        formData: ""
    });
    const parsed = qs.parse(window.location.search);

    const {formData} = values2;

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={showLoadingBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
    
    const loadCategories = () => {
        listCategories().then(data => {
            if (data.error) {
                setErr(data.error);
            } else {
                setCategories(data);
            }
        })
    }

    const loadSubcategories = () => {
        listSubcategories().then(data => {
            if (data.error) {
                setErr(data.error);
            } else {
                setSubcategories(data);
            }
        })
    }

    const loadFreeItems = () => {
        listAllFreeItems(parsed.product_id, user._id, token).then(res => {
            if(res.error){
                console.log(res.error)
            }else{
                setFreeItemsList([...res]);
            }
        });
    }

    useEffect(() => {
        setValues2({ ...values2, formData: new FormData() });
        loadCategories();
        loadSubcategories();
        loadFreeItems();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const product = await getProduct(parsed.product_id)
                .then(res => {
                    if (res.error) {
                        setValues({ ...values, error: res.error, redirectToReferrer: true });
                    } else {
                        let enDesc = "";
                        for (let i = 0; res.enDescription && i < res.enDescription.length; i++) {
                            if (i != res.enDescription.length - 1) {
                                enDesc += res.enDescription[i] + " / ";
                            } else {
                                enDesc += res.enDescription[i]
                            }
                        }
                        let arDesc = "";
                        for (let i = 0; res.arDescription && i < res.arDescription.length; i++) {
                            if (i != res.arDescription.length - 1) {
                                arDesc += res.arDescription[i] + " / ";
                            } else {
                                arDesc += res.arDescription[i]
                            }
                        }
                        let tags = ""
                        for (let i = 0; res.tags && i < res.tags.length; i++) {
                            if (i != res.tags.length - 1) {
                                tags += res.tags[i] + " / ";
                            } else {
                                tags += res.tags[i]
                            }
                        }
                        let rel1 = res.relatedProducts[0], rel2 = res.relatedProducts[1], rel3 = res.relatedProducts[2], rel4 = res.relatedProducts[3], rel5 = res.relatedProducts[4], rel6 = res.relatedProducts[5], rel7 = res.relatedProducts[6], rel8 = res.relatedProducts[7], rel9 = res.relatedProducts[8], rel10 = res.relatedProducts[9];
                        let img1 = res.images[0], img2 = res.images[1], img3 = res.images[2], img4 = res.images[3], img5 = res.images[4];
                        setValues({ _id: res._id, sku: res.sku, category: res.category, subcategory: res.subcategory, itemCode: res.itemCode, arName: res.arName, enName: res.enName, arDescription: arDesc, enDescription: enDesc, enTitle: res.enTitle, arTitle: res.arTitle, price: res.price, discountPrice: res.discountPrice, enWarranty: res.enWarranty, arWarranty: res.arWarranty, enMadeIn: res.enMadeIn, arMadeIn: res.arMadeIn, related1: rel1, related2: rel2, related3: rel3, related4: rel4, related5: rel5, related6: rel6, related7: rel7, related8: rel8, related9: rel9, related10: rel10, brand: res.brand, quantity: res.quantity, size: res.size, color: res.color, image1: img1, image2: img2, image3: img3, image4: img4, image5: img5, hidden: res.hidden ? "yes" : "no", freeItem: res.freeItem ? "yes" : "no", tags: tags });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const { user, token } = isAuthenticated();
    const { _id, sku, category, subcategory, itemCode, arName, enName, arDescription, enDescription, arTitle, enTitle, price, discountPrice, enMadeIn, arMadeIn, enWarranty, arWarranty, related1, related2, related3, related4, related5, related6, related7, related8, related9, related10, brand, quantity, size, color, image1, image2, image3, image4, image5, hidden, freeItem, tags, error, redirectToReferrer } = values;

    const handleChange = name => event => {
        if(name == "excel"){
            for (let i = 0; i < event.target.files.length; i++) {
                formData.set(`products`, event.target.files[i]);
            }
        }else{
            setValues({...values, [name]: event.target.value});
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handleNewFreeItemCodeChange = () => (e) => {
        setNewFreeItemCode(e.target.value);
    }

    const handleNewFreeItemSave = () => {
        if(formData.has("products")){
            setShowBackdrop(true);
            createMultipleFreeItems(formData, _id, user._id, token).then(res => {
                if(res.error){
                    console.log(res.error);
                }else{
                    setOpenDialog(false);
                    setValues2({...values2, formData: new FormData()})
                    loadFreeItems();
                }
                setShowBackdrop(false);
            })
        }
    }

    const handleDeleteFreeItem = (id) => () => {
        setShowBackdrop(true);
        deleteFreeItem(id, user._id, token).then(res => {
            if(res.error){
                console.log(res.error);
            }
            loadFreeItems();
            setShowBackdrop(false);
        })
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        updateProduct({ _id, sku, category, subcategory, itemCode, arName, enName, arDescription, enDescription, arTitle, enTitle, price, discountPrice, enMadeIn, arMadeIn, enWarranty, arWarranty, related1, related2, related3, related4, related5, related6, related7, related8, related9, related10, brand, quantity, size, color, image1, image2, image3, image4, image5, hidden, freeItem, tags }, user._id, token)
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, redirectToReferrer: false });
                } else {
                    setValues({
                        sku: null,
                        category: null,
                        subcategory: null,
                        itemCode: null,
                        arName: null,
                        enName: null,
                        arDescription: null,
                        enDescription: null,
                        arTitle: null,
                        enTitle: null,
                        price: 0.0,
                        enMadeIn: null,
                        arMadeIn: null,
                        enWarranty: null,
                        arWarranty: null,
                        related1: null,
                        related2: null,
                        related3: null,
                        related4: null,
                        related5: null,
                        related6: null,
                        related7: null,
                        related8: null,
                        related9: null,
                        related10: null,
                        brand: null,
                        quantity: 0,
                        size: null,
                        color: null,
                        image1: null,
                        image2: null,
                        image3: null,
                        image4: null,
                        image5: null,
                        hidden: false,
                        freeItem: false,
                        error: undefined,
                        redirectToReferrer: true,
                        formData: ""
                    });
                }
            })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            let st = "";
            const params = new URLSearchParams(window.location.search);
            let itemCode2 = params.get('itemCode');
            if (itemCode2) {
                st += ("itemCode=" + itemCode2)
            }
            let show = parseInt(params.get('show'));
            if (show) {
                if (st == "") {
                    st += ("show=" + show)
                } else {
                    st += ("&show=" + show)
                }
            }
            return (
                <Redirect to={{ pathname: (default_url + 'admin/products'), search: st, state: { prevPath: "edit", error } }} />
            )
        }
    }
    const categoriesSelector = () => {
        return (
            <select onChange={handleChange("category")} className="inputField form-control" value={category}>
                <option value={null}>{dict["select_category"]}</option>
                {
                    categories.sort((a, b) => a.position - b.position).map((category, index) => {
                        return (
                            <option key={index} value={category._id}>{category.enName + " | " + category.arName}</option>
                        )
                    })

                }
            </select>
        )
    }

    const subcategoriesSelector = () => {
        return (
            <select onChange={handleChange("subcategory")} className="inputField form-control" value={subcategory}>
                <option value={null}>{dict["select_subcategory"]}</option>
                {
                    subcategories.sort((a, b) => a.position - b.position).map((sub, index) => {
                        return (
                            sub.category == category &&
                            <option key={index} value={sub._id}>{sub.enName + (sub.arName != null ? " | " + sub.arName : "")}</option>
                        )
                    })

                }
            </select>
        )
    }

    const hiddenSelector = () => {
        return (
            <select onChange={handleChange("hidden")} name="hidden" className="inputField form-control" value={hidden}>
                <option value=""></option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
        )
    }

    const freeItemSelector = () => {
        return (
            <select onChange={handleChange("freeItem")} name="freeItem" className="inputField form-control" value={freeItem}>
                <option value=""></option>
                <option value="no">No</option>
                <option value="yes">Yes</option>
            </select>
        )
    }

    const multipleProducts = () => {
        return (
            <div className="multipleCategoriesForm">
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="excel" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const freeItemPopUp = () => {
        return (
            <div>
                <Dialog  open={openDialog} onClose={handleDialogClose} aria-labelledby="max-width-dialog-title" fullWidth={true} maxWidth={"lg"}>
                    <DialogTitle id="form-dialog-title" style={{textAlign: "start"}}>{dict['add_new_free_item']}</DialogTitle>
                    <DialogContent style={{width: "100%"}}>
                        {/* <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="newFreeItemCode"
                            label={dict["free_item_code"]}
                            value={newFreeItemCode}
                            onChange={handleNewFreeItemCodeChange()}
                            InputLabelProps={{ style: { left: "auto" } }}
                        /> */}
                        {multipleProducts()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            {dict["cancel"]}
                        </Button>
                        <Button onClick={handleNewFreeItemSave} color="primary">
                            {dict["save"]}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }


    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right",
        width: "100%",
        textAlign: "start"
    }
    const enStylelabel = {
        float: "left",
        width: "100%",
        textAlign: "start"
    }

    const productForm = () => {
        return (
            <div className="signleCategoryForm">
                {freeItemPopUp()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{"ID"}</label>
                <input type="text" value={_id} className="inputField form-control" disabled></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["sku"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("sku")} type="text" value={sku} className="inputField form-control" id="sku" placeholder={dict["sku"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["category"]} <span className="redStar">*</span></label><br />
                {categoriesSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory"]} </label><br />
                {subcategoriesSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["item"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("itemCode")} type="text" value={itemCode} className="inputField form-control" id="itemCode" placeholder={dict["item"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["product_english_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="name" placeholder={dict["product_english_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["product_arabic_name"]} <span className="redStar">*</span></label>
                <input dir="rtl" onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="name" placeholder={dict["product_arabic_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["english_description"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enDescription")} type="text" value={enDescription} className="inputField form-control" id="enDescription" placeholder={dict["english_description"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["arabic_description"]} <span className="redStar">*</span></label>
                <input dir="rtl" onChange={handleChange("arDescription")} type="text" value={arDescription} className="inputField form-control" id="arDescription" placeholder={dict["arabic_description"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["english_title"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enTitle")} type="text" value={enTitle} className="inputField form-control" id="enTitle" placeholder={dict["english_title"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["arabic_title"]} <span className="redStar">*</span></label>
                <input dir="rtl" onChange={handleChange("arTitle")} type="text" value={arTitle} className="inputField form-control" id="arTitle" placeholder={dict["arabic_title"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["hidden"]} </label>
                {hiddenSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["brand"]}</label>
                <input onChange={handleChange("brand")} type="text" value={brand} className="inputField form-control" id="brand" placeholder={dict["brand"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["price"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("price")} type="number" value={price} className="inputField form-control" id="price" placeholder={dict["price"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["discount_price"]}</label>
                <input onChange={handleChange("discountPrice")} type="number" value={discountPrice} className="inputField form-control" id="discountPrice" placeholder={dict["discount_price"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["quantity"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("quantity")} type="number" value={quantity} className="inputField form-control" id="quantity" placeholder={dict["quantity"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["size"]}</label>
                <input onChange={handleChange("size")} type="text" value={size} className="inputField form-control" id="size" placeholder={dict["size"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["color"]}</label>
                <input onChange={handleChange("color")} type="text" value={color} className="inputField form-control" id="color" placeholder={dict["color"]}></input>
                <div className="row">
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["warranty_(english)"]}</label>
                        <input onChange={handleChange("enWarranty")} type="text" value={enWarranty} className="inputField form-control" id="enWarranty" placeholder={dict["warranty_(english)"]}></input>
                    </div>
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["warranty_(arabic)"]}</label>
                        <input dir="rtl" onChange={handleChange("arWarranty")} type="text" value={arWarranty} className="inputField form-control" id="arWarranty" placeholder={dict["warranty_(arabic)"]}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["made_in_(english)"]}</label>
                        <input onChange={handleChange("enMadeIn")} type="text" value={enMadeIn} className="inputField form-control" id="enMadeIn" placeholder={dict["made_in_(english)"]}></input>
                    </div>
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["made_in_(arabic)"]}</label>
                        <input dir="rtl" onChange={handleChange("arMadeIn")} type="text" value={arMadeIn} className="inputField form-control" id="arMadeIn" placeholder={dict["made_in_(arabic)"]}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 1"}</label>
                        <input onChange={handleChange("related1")} type="text" value={related1} className="inputField form-control" id="related1" placeholder={dict["related"] + " 1"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 2"}</label>
                        <input onChange={handleChange("related2")} type="text" value={related2} className="inputField form-control" id="related2" placeholder={dict["related"] + " 2"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 3"}</label>
                        <input onChange={handleChange("related3")} type="text" value={related3} className="inputField form-control" id="related3" placeholder={dict["related"] + " 3"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 4"}</label>
                        <input onChange={handleChange("related4")} type="text" value={related4} className="inputField form-control" id="related4" placeholder={dict["related"] + " 4"}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 5"}</label>
                        <input onChange={handleChange("related5")} type="text" value={related5} className="inputField form-control" id="related5" placeholder={dict["related"] + " 5"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 6"}</label>
                        <input onChange={handleChange("related6")} type="text" value={related6} className="inputField form-control" id="related6" placeholder={dict["related"] + " 6"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 7"}</label>
                        <input onChange={handleChange("related7")} type="text" value={related7} className="inputField form-control" id="related7" placeholder={dict["related"] + " 7"}></input>
                    </div>
                    <div className="col-3">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 8"}</label>
                        <input onChange={handleChange("related8")} type="text" value={related8} className="inputField form-control" id="related8" placeholder={dict["related"] + " 8"}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 9"}</label>
                        <input onChange={handleChange("related9")} type="text" value={related9} className="inputField form-control" id="related9" placeholder={dict["related"] + " 9"}></input>
                    </div>
                    <div className="col-6">
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["related"] + " 10"}</label>
                        <input onChange={handleChange("related10")} type="text" value={related10} className="inputField form-control" id="related10" placeholder={dict["related"] + " 10"}></input>
                    </div>
                </div>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 1"}</label>
                <input onChange={handleChange("image1")} type="text" value={image1} className="inputField form-control" id="image1" placeholder={dict["image"] + " 1"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 2"}</label>
                <input onChange={handleChange("image2")} type="text" value={image2} className="inputField form-control" id="image2" placeholder={dict["image"] + " 2"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 3"}</label>
                <input onChange={handleChange("image3")} type="text" value={image3} className="inputField form-control" id="image3" placeholder={dict["image"] + " 3"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 4"}</label>
                <input onChange={handleChange("image4")} type="text" value={image4} className="inputField form-control" id="image4" placeholder={dict["image"] + " 4"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"] + " 5"}</label>
                <input onChange={handleChange("image5")} type="text" value={image5} className="inputField form-control" id="image5" placeholder={dict["image"] + " 5"}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["tags"]}</label>
                <input onChange={handleChange("tags")} type="text" value={tags} className="inputField form-control" id="tags" placeholder={dict["tags"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["buy_one_get_one_free"]} </label>
                {freeItemSelector()}
                {
                    freeItem == "yes" &&
                    <>
                        <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["free_items"]} </label>
                        <div style={{ display: "block" }}>
                            <div className={(default_url === "/en/" ? "" : "selectedAreaAdmin")} style={{ textAlign: "start", padding: "3px 0 0", width: "75%", height: "auto", minHeight: "40px", borderRadius: "0.3rem", border: "1px solid rgba(0, 0, 0, 0.3)", display: "inline-block", float: default_url === "/en/" ? "" : "right", margin: "0" }}>
                                {freeItemsList.map((a, i) => {
                                    return (
                                        <Chip label={a.child.itemCode} onDelete={handleDeleteFreeItem(a._id)} />
                                    )
                                })}
                            </div>
                            <Button onClick={() => {setOpenDialog(true)}} className="dashboardBtn" style={{ backgroundColor: "rgb(228, 188, 75)", color: "white", display: "inline-block", margin: "0", height: "40px", float: default_url === "/en/" ? "right" : "", width: "25%", fontSize: "14px" }}>{dict["add_items"]}</Button>
                        </div>
                    </>
                }
            </div>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {redirectUser()}
                {showBackdrop()}
                <h3 className="formContainerTitle">{dict["edit_product"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form>
                    {productForm()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>

            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default UpdateProduct;