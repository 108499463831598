import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createSubcategory} from "../../apiAdmin"
import {countOfSubcategories, listCategories} from "../../../apiCore"
import { Redirect } from "react-router-dom";
import {default_url} from "../../../../index"
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function AddSubcategory() {
    const classes = useStyles();
    const [values, setValues] = useState({
        enName: "",
        arName: null,
        category: null,
        image: null,
        hidden: "",
        error: "",
        redirectToReferrer: false,
    });
    const [categories, setCategories] = useState([]);
    const [numOfSubcategories, setNumOfSubcategories] = useState(0);
    const { user, token } = isAuthenticated();
    const { enName, arName, category, image, hidden, error, redirectToReferrer } = values;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await countOfSubcategories()
                .then(res => {
                    setNumOfSubcategories(res);
                })
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const cats = await listCategories()
                .then(res => {
                    setCategories(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
        if(category == ""){
            setValues({ ...values, category: null });
        }
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        async function fetchData() {            
            await countOfSubcategories("category", category)
            .then(res => {
                setValues({ ...values, error: "" });
                let position = res + 1;
                if(category == ""){
                    category = null;
                }
                createSubcategory({ enName, arName, category, image, hidden, position}, user._id, token)
                    .then(data => {
                        setLoading(false);
                        if (data.error) {
                            setValues({ ...values, error: dict[data.error ]});
                        } else {
                            setValues({
                                enName: "",
                                arName: "",
                                image: "",
                                redirectToReferrer: true
                            })
                        }
                    })
            })
        }
        fetchData();
    }

    const CategoriesSelector = () => {
        return (
            <select onChange={handleChange("category")} className="inputField form-control" value={category}>
                <option value={null}></option>
                {
                    categories.sort((a, b) => a.position - b.position).map((category, index) => {
                        return (
                            <option key={index} value={category._id}>{category.enName + " | " + category.arName}</option>
                        )
                    })

                }
            </select>
        )
    }

    const hiddenSelector = () => {
        return (
            <select onChange={handleChange("hidden")} className="inputField form-control">
                <option value=""></option>
                <option value="no">{dict["no_._it_isn't_hidden"]}</option>
                <option value="yes">{dict["yes_._it_is_hidden"]}</option>
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start'}}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/subcategories', state: { prevPath: "create", error } }} />
            )
        }
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_subcategory"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory_english_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="enName" placeholder={dict["subcategory_english_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory_arabic_name"]}</label>
                    <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="arName" placeholder={dict["subcategory_arabic_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["category"]} <span className="redStar">*</span></label><br />
                    {CategoriesSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["hidden"]} <span className="redStar">*</span></label><br />
                    {hiddenSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory_image"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("image")} type="text" value={image} className="inputField form-control" id="image" placeholder={dict["subcategory_image"]}></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="3"
            data={form}
        />
    )
}

export default AddSubcategory;