import React, { useState } from 'react'
import { dict } from '../../../dictionary';

function ProductViewSizeOptions(props){
    const [value, setValue] = useState(props.index);
    const handleChange = event => {
        setValue(parseInt(event.target.value));
        props.changeIndex(parseInt(event.target.value));
    }
    return(
        <div style={{textAlign:"start", margin: "10px 0"}}>
        <span>{dict["size"]}: </span>
            <select onChange={handleChange} className="productViewSizeOptionsBox" value={value}>
                {
                    props.sizes.map((size, index) => {
                        return(
                            <option key={index} value={index}>{size}</option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default ProductViewSizeOptions;