import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { deleteOffersProduct, updateGeneral } from "../../apiAdmin"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { listOffers, listProductsByFilters, getGeneral } from "../../../apiCore";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


function OffersAdmin() {
    const [data, setData] = useState([]);
    const [sorting, setSorting] = useState({
        active: false,
        error: "",
        success: false
    });
    const [active, setActive] = useState(true);
    const [general, setGeneral] = useState();
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState(false);

    const { user, token } = isAuthenticated();

    const loadOffers = () => {
        listOffers().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                if (res.length > 0) {
                    let ids = [];
                    res.map(item => {
                        ids.push(item.product);
                    });
                    let filters = {
                        _id: ids
                    };
                    listProductsByFilters(0, 1000000, filters).then(items => {
                        if (items.error) {
                            console.log(items.error);
                        } else {
                            setData(items.data);
                        }
                    })
                } else {
                    setData([]);
                }
            }
        });
    }

    const loadGeneral = () => {
        getGeneral("name", "showOffers").then(res => {
            if(res.error){
                console.log(res.error);
            }else{
                setGeneral(res);
                setActive(res.booleanValue);
            }
        })
    }

    useEffect(() => {
        loadOffers();
        loadGeneral();
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        deleteOffersProduct(deletedItem.id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadOffers();
                setDeletedItem({ ...deletedItem, success: true });
            }
        })
    }

    const handleChange = (name) => (event) => {
        updateGeneral({...general, booleanValue: !active}, user._id, token).then(res => {
            if(res.error){
                console.log(res.error);
            }else{
                setActive(!active);
                setGeneral(res);
            }
        })
    };

    const clickSubmit = target => () => {
        if(target == "add"){
            window.location.href = default_url + "admin/offers/add"
        }else if(target == "replace"){
            window.location.href = default_url + "admin/offers/replace"
        }
        
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_product"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + " from offers?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد ازالة المنتج " + deletedItem.name + " من قائمة العروض؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAlert = () => {
        if (deletedItem.success) {
            return (
                <div
                    className={"alert alert-info alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{dict["product_has_been_deleted"]}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const showActivationSwitch = () => {
        return (
            <FormControlLabel
                value="start"
                control={
                    <Switch
                        checked={active}
                        onChange={handleChange('active')}
                        color="primary"
                        className="switch"
                    />
                }
                label={dict["activate"]}
                labelPlacement="start"
                className="dashboardSortingSwitch"
                style={{ float: default_url == "/en/" ? "right" : "left" }}
            />
        )
    }

    const allProducts = () => {
        return (
            data.map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right" }}>{index + 1}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right" }}>{item.enName}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.itemCode}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left" }}
                        >{dict["delete"]}</a>
                        {popUp()}
                    </div>
                )
            })
        )
    }

    const showEmpty = () => {
        return (
            <h1 style={{ textAlign: "start" }}>{dict["no_products_found"]}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["offers_list"]}</h3>
                {showActivationSwitch()}
                {data.length > 0 ? allProducts() : showEmpty()}
                <button onClick={clickSubmit("add")} type="submit" className="dashboardBtn" style={{width:"47.5%", marginInlineEnd: "2.5%"}}>{dict["add_new_item_to_offers"]}</button>
                <button onClick={clickSubmit("replace")} type="submit" className="dashboardBtn" style={{width:"47.5%", marginInlineStart: "2.5%"}}>{dict["replace_current_products_in_offers"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="7"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default OffersAdmin;