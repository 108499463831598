import React, { useState } from "react";
import { singout } from "../../auth";
import { clearCart } from "../cartHelpers";
import { clearWishlist } from "../wishlistHelpers";
import { dict } from "../../dictionary";
import { default_url } from "../..";
import { clearCompareList } from "../compareHelpers";

function UserDashboard(props) {    
    const handleSignOut = () => {
        singout(clearCartAndWishlistAndCompare);
    }

    const clearCartAndWishlistAndCompare = () => {
        clearCart();
        clearWishlist();
        clearCompareList();
    }

    let isMobile = window.innerWidth < 1024;
    return (
        <div className="userProfileContainer">
            <h1 className="userProfileTitle userProfileTitleOnDesktop" style={{right: default_url === "/en/" ? "" : "15px", position: default_url === "/en/" ? "" : "absolute"}}>{dict["my_account"]}</h1>
            <a href={default_url + "signin"} onClick={handleSignOut} className="signoutLink signoutLinkOnDesktop" style={{position: default_url === "/en/" ? "absolute" : "relative", right: default_url === "/en/" ? "15px" : "", marginBottom: "10px"}}><i className="fas fa-sign-out-alt signouticon"  style={{transform: default_url === "/en/" ? "" : "scaleX(-1)"}}></i>{dict["signout"]}</a>
            <nav className="navbar navbar-expand-lg navbar-light bg-light userProfileNavbar">
                <h1 className="userProfileTitle userProfileTitleOnMob">{dict["my_account"]}</h1>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item signoutLinkOnMob">
                            <a href={default_url + "signin"} onClick={handleSignOut} className="nav-link signoutLink">{dict["signout"]}</a>
                        </li>
                        <li className={"nav-item " + (props.active === "1" ? "activeUserProfileItem" : "")}>
                            <a className={"nav-link " + (props.active === "1" ? "activeUserProfileLink" : "")} href={default_url + "user/account-information"}>{dict['account_information']}</a>
                        </li>
                        <li className={"nav-item " + (props.active === "2" ? "activeUserProfileItem" : "")}>
                            <a className={"nav-link " + (props.active === "2" ? "activeUserProfileLink" : "")} href={default_url + "user/address-book"}>{dict['address_book']}</a>
                        </li>
                        <li className={"nav-item " + (props.active === "3" ? "activeUserProfileItem" : "")}>
                            <a className={"nav-link " + (props.active === "3" ? "activeUserProfileLink" : "")} href={default_url + "user/orders"}>{dict["my_orders"]}</a>
                        </li>
                        <li className={"nav-item " + (props.active === "4" ? "activeUserProfileItem" : "")}>
                            <a className={"nav-link " + (props.active === "4" ? "activeUserProfileLink" : "")} href={default_url + "user/wishlist"}>{dict['my_wishlist']}</a>
                        </li>
                        <li className={"nav-item " + (props.active === "5" ? "activeUserProfileItem" : "")}>
                            <a className={"nav-link " + (props.active === "5" ? "activeUserProfileLink" : "")} href={default_url + "user/reviews"}>{dict['my_reviews']}</a>
                        </li>
                        <li className={"nav-item " + (props.active === "6" ? "activeUserProfileItem" : "")}>
                            <a className={"nav-link " + (props.active === "6" ? "activeUserProfileLink" : "")} href={default_url + "user/points"}>{dict['points']}</a>
                        </li>
                    </ul>
                </div>
            </nav>
            {props.data()}
        </div>
    )
}

export default UserDashboard;