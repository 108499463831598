import React, { useRef, useState, useEffect } from 'react'
import { logDOM } from '@testing-library/react';
import { IMAGE_URL } from "../../../config"

function ProductViewAllImages(props){
    const [current, setCurrent] = useState(props.current)
    let isMobile = window.innerWidth < 1024;
    const ref1 = useRef(null);
    const [height1, setHeight1] = useState(ref1.current ? ref1.current.offsetWidth : "")


    useEffect(() => {
        setHeight1(ref1.current ? ref1.current.offsetWidth : "");
    }, [ref1.current])

    const handleClick = (index) => () => {
        props.setImage(index);
        setCurrent(index);
    }
    return(
        <div className="allImagesLine" style={{textAlign: "center", height: "auto", width:"100%"}}>
            {
                props.imgs.map((img, index) => {
                    let newImg = IMAGE_URL + img + "_small.jpg"
                    return(
                        <div key={index} className="allImagesLineItemContainer" style={{position:"relative", border: current === index ? ("1px solid rgba(228, 188, 75, 1)") :  "1px solid rgba(0, 0, 0, .3)", display: "inline-block"}}>
                            <img ref={ref1} key={index} className="allImagesLineItem" src={newImg} style={{position:"relative", cursor: "pointer", width: props.width && !isMobile ? props.width : "95%", height: "95%"}} onClick={handleClick(index)} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ProductViewAllImages;