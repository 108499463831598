import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createArea, createMultipleAreas, createSubcategory } from "../../apiAdmin"
import { countOfSubcategories, listCategories, listCities } from "../../../apiCore"
import { Redirect } from "react-router-dom";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function AddArea() {
    const classes = useStyles();
    const [values, setValues] = useState({
        enName: "",
        arName: null,
        city: null,
        error: "",
        redirectToReferrer: false,
        formData: ""
    });
    const [mood, setMood] = useState({
        single: true,
        multiple: false
    })
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();
    const { enName, arName, city, error, redirectToReferrer, formData } = values;
    const { single, multiple } = mood;

    useEffect(() => {
        setValues({ ...values, formData: new FormData() });
        async function fetchData() {
            const c = await listCities()
                .then(res => {
                    setCities(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const handleChange = name => event => {
        if (single) {
            setValues({ ...values, error: false, [name]: event.target.value });
        } else {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.set(`areas`, event.target.files[i]);
            }
        }
    };

    const handleCheckBoxChange = name => event => {
        if (name == "single") {
            setMood({ 'single': event.target.checked, 'multiple': !event.target.checked });
        } else {
            setMood({ 'single': !event.target.checked, 'multiple': event.target.checked });
        }
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        if (!single && !multiple) {
            return;
        }
        setLoading(true);
        if (single) {
            createArea({ enName, arName, city }, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: dict[data.error], redirectToReferrer: false });
                    } else {
                        setValues({
                            enName: "",
                            arName: "",
                            city: "",
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        } else {
            createMultipleAreas(formData, user._id, token)
                .then(data => {
                    setLoading(false);
                    if (data.error) {
                        setValues({ ...values, error: data.error, redirectToReferrer: false });
                    } else {
                        setValues({
                            enName: "",
                            arName: "",
                            city: "",
                            error: undefined,
                            redirectToReferrer: true,
                            formData: ""
                        });
                    }
                })
        }
    }

    const citySelector = () => {
        return (
            <select onChange={handleChange("city")} className="inputField form-control" value={city}>
                <option value={null}></option>
                {
                    cities.sort((a, b) => a.arName - b.arName).map((city, index) => {
                        return (
                            <option key={index} value={city._id}>{city.enName + " | " + city.arName}</option>
                        )
                    })

                }
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/areas', state: { prevPath: "create", error } }} />
            )
        }
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const singleArea = () => {
        return (
            <div className="signleCategoryForm" style={{ display: single ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area_english_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="name" placeholder={dict["area_english_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["area_arabic_name"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="name" placeholder={dict["area_arabic_name"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["city"]} <span className="redStar">*</span></label><br />
                {citySelector()}
            </div>
        )
    }

    const multipleAreas = () => {
        return (
            <div className="multipleCategoriesForm" style={{ display: multiple ? "" : "none" }}>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="photo" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["add_new_area"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("single")} type="checkbox" checked={single} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_one_area"]}</label>
                    </div>
                    {singleArea()}
                    <div className="form-check checkBoxAddCategory">
                        <input onChange={handleCheckBoxChange("multiple")} type="checkbox" checked={multiple} className="form-check-input addCategoryCheckInput" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_multiple_areas"]}</label>
                    </div>
                    {multipleAreas()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="333"
            data={form}
        />
    )
}

export default AddArea;