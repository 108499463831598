import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { deleteColor } from "../../apiAdmin"
import { listColors } from "../../../apiCore";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link, useHistory } from "react-router-dom";


function ColorsAdmin() {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState(false);

    const { user, token } = isAuthenticated();

    const loadBlog = () => {
        listColors().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setData(res);
            }
        });
    }

    useEffect(() => {
        loadBlog();
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        deleteColor(deletedItem.id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadBlog();
                setDeletedItem({ ...deletedItem, success: true });
            }
        })
    }

    const clickSubmit = () => {
        history.push(default_url + "admin/colors/add");
        //window.location.href = default_url + "admin/colors/add"
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_color"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete color number "} <span style={{ color: 'red' }}>{deletedItem.name}</span></Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد حذف اللون "} <span style={{ color: 'red' }}>{deletedItem.name}</span></Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAlert = () => {
        if (deletedItem.success) {
            return (
                <div
                    className={"alert alert-info alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{dict["color_has_been_deleted"]}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const showColors = () => {
        return (
            data.map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right" }}>{index + 1}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "320px" }}>{ item.number}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "320px" }}>{item.arName + " - " + item.enName}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right", width: "320px" }}>{item.hexCode}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.number}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left" }}
                        >{dict["delete"]}</a>
                        {popUp()}
                        <Link className="dataContainerAction" to={default_url + "admin/colors/edit?color_id=" + item._id} style={{ float: default_url == "/en/" ? "right" : "left" }}>{dict["edit"]}</Link>
                    </div>
                )
            })
        )
    }

    const showEmpty = () => {
        return (
            <h1 style={{ textAlign: "start" }}>{dict["no_colors_found"]}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["colors"]}</h3>
                {data.length > 0 ? showColors() : showEmpty()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_color"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default ColorsAdmin;