import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createCollection } from "../../apiAdmin"
import {listCategories} from "../../../apiCore"
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function AddToCollections() {
    const classes = useStyles();
    const [values, setValues] = useState({
        model: "",
        thermos: "",
        porcelain: "",
        accessories: "",
        thermosTags: "",
        porcelainTags: "",
        accessoriesTags: "",
        image: "",
        error: "",
        success: false,
    });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const { user, token } = isAuthenticated();
    const { model, thermos, thermosTags, porcelain, porcelainTags, accessories, accessoriesTags, image, error, success } = values;

    useEffect(() => {
        async function fetchData() {
            const cats = await listCategories()
                .then(res => {
                    setCategories(res);
                })
                .catch(err => {
                    console.log(err);
                });
        }
        fetchData();
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let collection = { model: model, thermos: thermos, thermosTags: thermosTags, porcelain: porcelain, porcelainTags: porcelainTags, accessories: accessories, accessoriesTags: accessoriesTags, image: image }
        setLoading(true);
        createCollection(collection, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ model: "", thermos: "", thermosTags: "", porcelainTags: "", accessoriesTags: "", porcelain: "", accessories: "", image: "", error: null, success: true });
            }
            setLoading(false);
        })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_model_has_been_added._add_more_or']} <Link to={default_url + "admin/collections"} style={{ fontSize: "18px" }}>{dict["go_back_to_collections"]}</Link>
                </div>
            )
        }
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const CategoriesSelector = feild => {
        return (
            <select onChange={handleChange(feild)} className="inputField form-control" value={feild == "thermos" ? thermos : feild == "porcelain" ? porcelain : accessories}>
                <option value={null}></option>
                {
                    categories.sort((a, b) => a.position - b.position).map((category, index) => {
                        return (
                            <option key={index} value={category._id}>{category.enName + " | " + category.arName}</option>
                        )
                    })
                }
            </select>
        )
    }


    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_model_to_collection"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["model_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("model")} type="text" value={model} className="inputField form-control" id="model"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["thermos"]} <span className="redStar">*</span></label>
                    {CategoriesSelector("thermos")}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["thermos_tags"]} </label>
                    <input onChange={handleChange("thermosTags")} type="text" value={thermosTags} className="inputField form-control" id="thermosTags"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["porcelain"]} <span className="redStar">*</span></label>
                    {CategoriesSelector("porcelain")}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["porcelain_tags"]} </label>
                    <input onChange={handleChange("porcelainTags")} type="text" value={porcelainTags} className="inputField form-control" id="porcelainTags"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["accessories"]} <span className="redStar">*</span></label>
                    {CategoriesSelector("accessories")}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["accessories_tags"]} </label>
                    <input onChange={handleChange("accessoriesTags")} type="text" value={accessoriesTags} className="inputField form-control" id="accessoriesTags"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["model_image"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("image")} type="text" value={image} className="inputField form-control" id="image"></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default AddToCollections;