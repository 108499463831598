import React, { useState, useEffect } from "react";
import { dict } from "../../../dictionary";

function ColorFilterBox(props) {
    let params = new URLSearchParams(window.location.search);
    let colors = params.get('color') ? params.get('color').split(",") : [];
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        props.filteredColors(props.color.number);
        setChecked(!checked);
    }

    useEffect(() => {
        let bool = false;
        bool = bool || (colors.indexOf(props.color.number) >= 0)
        setChecked(bool);
    }, [])

    return (
        <div className="form-check sizeFilter">
            <input type="checkbox" className="form-check-input sizeFilterCheckbox" id={props.color.number} checked={checked} onChange={handleChange}/>
            <div className="form-check-label sizeFilterLabel" htmlFor="exampleCheck1" style={{backgroundColor: props.color.hexCode, marginInlineStart: "15px", paddingInlineStart: "0px", width: "40px", height: "20px", boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'}}></div>
        </div>
    )
}

export default ColorFilterBox;