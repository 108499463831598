import React, { useState, Fragment, useEffect } from "react";
import { listCategories, listSubcategories } from "../apiCore"
import { singout, isAuthenticated } from "../../auth";
import Category from "../Category";
import {default_url} from "../../index"
import { dict } from "../../dictionary";
import Badge from '@material-ui/core/Badge';
import { clearCompareList } from "../compareHelpers";
import { clearWishlist } from "../wishlistHelpers";
import { clearCart } from "../cartHelpers";
import { countNewFeedbacks, countNewOrders, countNewWholesaleRequests } from "./apiAdmin";
import { Link } from "react-router-dom";


function AdminDashboard(props) {
    const [newOrders, setNewOrders] = useState(0);
    const [newWholesale, setNewWholesale] = useState(0);
    const [newFeedbacks, setNewFeebacks] = useState(0);

    const {user, token} = isAuthenticated();

    useEffect(() => {
        countNewOrders(user._id, token).then(data => {
            if(data.error){
                console.log(data.error);
            }else{
                setNewOrders(data);
            }
        })
        countNewWholesaleRequests(user._id, token).then(data => {
            if(data.error){
                console.log(data.error);
            }else{
                setNewWholesale(data);
            }
        })
        countNewFeedbacks(user._id, token).then(data => {
            if(data.error){
                console.log(data.error);
            }else{
                setNewFeebacks(data);
            }
        })
    }, [])

    const clearCartAndWishlistAndCompare = () => {
        clearCompareList();
        clearWishlist();
        clearCart();
    }

    return (
        <Fragment >
            <div className="userProfileContainer">
            <h1 className="userProfileTitle userProfileTitleOnDesktop" style={{right: default_url === "/en/" ? "" : "15px", position: default_url === "/en/" ? "" : "absolute"}}>{dict["my_account"]}</h1>
            <Link to={default_url + "signin"} onClick={() => singout(clearCartAndWishlistAndCompare)} className="signoutLink signoutLinkOnDesktop" style={{position: default_url === "/en/" ? "absolute" : "relative", right: default_url === "/en/" ? "15px" : "", marginBottom: "10px"}}><i className="fas fa-sign-out-alt signouticon"  style={{transform: default_url === "/en/" ? "" : "scaleX(-1)"}}></i>{dict["signout"]}</Link>
                <nav className="navbar navbar-expand-lg navbar-light bg-light userProfileNavbar">
                    <h1 className="userProfileTitle userProfileTitleOnMob">My Account</h1>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item signoutLinkOnMob">
                                <Link to={default_url + "signin"} onClick={() => singout(clearCartAndWishlistAndCompare)} className="nav-link signoutLink">{dict["signout"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "1" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "1" ? "activeUserProfileLink" : "")} to={default_url + "admin/account-information"}>{dict["account_information"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "2" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "2" ? "activeUserProfileLink" : "")} to={default_url + "admin/categories"}>{dict["categories"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "3" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "3" ? "activeUserProfileLink" : "")} to={default_url + "admin/subcategories"}>{dict["subcategories"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "4" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "4" ? "activeUserProfileLink" : "")} to={default_url + "admin/products"}>{dict["products"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "5" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "5" ? "activeUserProfileLink" : "")} to={default_url + "admin/orders"}>{dict["orders"]} <Badge max={1000} badgeContent={newOrders} style={{marginInlineEnd: "0px", marginTop: "-40px"}} /></Link>
                            </li>
                            {/* <li className={"nav-item " + (props.active === "6" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "6" ? "activeUserProfileLink" : "")} to={default_url + "admin/new-arrival"}>{dict["new_arrival"]}</Link>
                            </li> */}
                            <li className={"nav-item " + (props.active === "7" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "7" ? "activeUserProfileLink" : "")} to={default_url + "admin/lists"}>{dict["lists"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "8" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "8" ? "activeUserProfileLink" : "")} to={default_url + "admin/ads"}>{dict["ads"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "9" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "9" ? "activeUserProfileLink" : "")} to={default_url + "admin/coupons"}>{dict["coupons"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "10" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "10" ? "activeUserProfileLink" : "")} to={default_url + "admin/upload-images"}>{dict["add_images"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "11" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "11" ? "activeUserProfileLink" : "")} to={default_url + "admin/users"}>{dict["users"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "12" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "12" ? "activeUserProfileLink" : "")} to={default_url + "admin/gifts"}>{dict["gifts"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "13" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "13" ? "activeUserProfileLink" : "")} to={default_url + "admin/wholesale"}>{dict["wholesale"]} <Badge badgeContent={newWholesale} style={{marginInlineEnd: "0px", marginTop: "-40px"}} /></Link>
                            </li>
                            <li className={"nav-item " + (props.active === "14" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "14" ? "activeUserProfileLink" : "")} to={default_url + "admin/feedbacks"}>{dict["feedbacks"]} <Badge badgeContent={newFeedbacks} style={{marginInlineEnd: "0px", marginTop: "-40px"}} /></Link>
                            </li>
                            <li className={"nav-item " + (props.active === "15" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "15" ? "activeUserProfileLink" : "")} to={default_url + "admin/distributors"}>{dict["distributors"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "16" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "16" ? "activeUserProfileLink" : "")} to={default_url + "admin/blog"}>{dict["blog"]}</Link>
                            </li>
                            <li className={"nav-item " + (props.active === "17" ? "activeUserProfileItem" : "")}>
                                <Link className={"nav-link " + (props.active === "17" ? "activeUserProfileLink" : "")} to={default_url + "admin/more-settings"}>{dict["more"]}</Link>
                            </li>
                            
                        </ul>
                    </div>
                </nav>
                {props.data()}
            </div>
        </Fragment>
    )
}

export default AdminDashboard;