import React from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"

function Warranty() {
    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900" }}>الكفالة</h1>
                <br />
                <p style={{lineHeight: "2"}}>
                البضاعة التي تتضمن كفالة رسمية ووقت معين للكفالة على صفحة المنتج وقت الشراء هي فقط المؤهلة للكفالة.
                <br/>
                الكفالة لا تغطي العيوب التي يسببها سوء الاستخدام ، الكسر، الجرح ، ضرر الماء، استخدام ملحقات غير أصلية وغير معتمدة من المُصنّع.
                <br/>
                للاستفادة من الكفالة يرجى التواصل مع <a href="https://wa.me/message/CK5RVYN63EBMI1" style={{color: "#E4BC4B", fontWeight: 900, textDecoration: "underline", fontSize: "18px", margin: "0 2px"}}>خدمة العملاء</a> عبر الواتس اب.
                <br/>
                إذا كنت غير راضياً عن خدمتنا أو لديك أي شكوى أو استفسار يُرجى الإتصال بنا ، ونحن سنتأكد من حصولك على أفضل خدمة تستحقها.
                </p>
            </div>
        </div>
    )
}

export default Warranty;