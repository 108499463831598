import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';


function AdsItem(props){
    return(
        <div className={"carousel-item " + (props.num == 0 ? "active" : "")} data-interval="4000">
        <Link to={props.link}>
          <LazyLoadImage effect="blur" className="d-block w-100" src={props.img} width="100%" height="100%" alt="Ad" />
        </Link>
        </div>
    )
}

export default AdsItem;