import React, { useState, useEffect } from "react";
import { default_url } from "../index"
import { IMAGE_URL } from "../config"
import { dict } from "../dictionary"
import { makeStyles } from '@material-ui/core/styles';
import { listCollections, listProductsForCollections, listColors } from "./apiCore";
import ProductItem from "../components/product components/product list/ProductItem"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Fragment } from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import NumericInput from 'react-numeric-input';
import { addItem } from "./cartHelpers";
import { store } from 'react-notifications-component';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px 10px',
        marginBottom: "20px"
    },
    paper: {
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        backgroundColor: "transparent"
    },
    btnRoot: {
        '& > *': {
            padding: theme.spacing(2),
            display: 'block'
        },
    }
}));

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Collections() {
    const classes = useStyles();
    const classes2 = useStyles2();
    const [showLoadingBackdrop, setShowBackdrop] = useState(false);
    const [values, setValue] = useState([]);
    const [model, setModel] = useState(null)
    const [colors, setColors] = useState([]);
    const [allColors, setAllColors] = useState([]);
    const [color, setColor] = useState(null)
    const [thermos, setThermos] = useState([]);
    const [porcelain, setPorcelain] = useState([]);
    const [accessories, setAccessories] = useState([]);
    const [thermosToShow, setThermosToShow] = useState([]);
    const [porcelainToShow, setPorcelainToShow] = useState([]);
    const [accessoriesToShow, setAccessoriesToShow] = useState([]);
    const [checked, setChecked] = useState([])
    const [quantity, setQuantity] = useState({})
    const [totalPrice, setTotalPrice] = useState(0);
    const [show, setShow] = useState(false);
    const [data, setData] = useState({})
    const [cookies, setCookie] = useCookies(['_fbp']);

    

    const loadCollections = () => {
        listCollections().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setValue(res);
            }
        });
    }

    const getColors = () => {
        listColors().then(res => {
            if (res.error) {

            } else {
                setAllColors(res);
            }
        })
    }

    useEffect(() => {
        getColors();
        loadCollections();
    }, [])

    useEffect(() => {
        if (model) {
            setColor(null);
            setThermosToShow([]);
            setPorcelainToShow([]);
            setAccessoriesToShow([]);
            setQuantity({})
            setChecked([]);
            let set = new Set();
            listProductsForCollections(values[model].thermos, values[model].model, values[model].thermosTags).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setThermos(res.data);
                    for (let i = 0; i < res.data.length; i++) {
                        set.add(res.data[i].color);
                    }
                    let arr = []
                    let arr2 = Array.from(set);
                    for (let j = 0; j < arr2.length; j++) {
                        for (let i = 0; i < allColors.length; i++) {
                            if (arr2[j] == allColors[i].number) {
                                arr.push({ number: arr2[j], hexCode: allColors[i].hexCode });
                                break;
                            }
                        }
                    }
                    setColors(arr);
                }
            });
            listProductsForCollections(values[model].porcelain, values[model].model, values[model].porcelainTags).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setPorcelain(res.data);
                    console.log(res);
                    for (let i = 0; i < res.data.length; i++) {
                        set.add(res.data[i].color);
                    }
                    let arr = []
                    let arr2 = Array.from(set);
                    for (let j = 0; j < arr2.length; j++) {
                        for (let i = 0; i < allColors.length; i++) {
                            if (arr2[j] == allColors[i].number) {
                                arr.push({ number: arr2[j], hexCode: allColors[i].hexCode });
                                break;
                            }
                        }
                    }
                    setColors(arr);
                }
            })
            listProductsForCollections(values[model].accessories, values[model].model, values[model].accessoriesTags).then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    setAccessories(res.data);
                    for (let i = 0; i < res.data.length; i++) {
                        set.add(res.data[i].color);
                    }
                    let arr = []
                    let arr2 = Array.from(set);
                    for (let j = 0; j < arr2.length; j++) {
                        for (let i = 0; i < allColors.length; i++) {
                            if (arr2[j] == allColors[i].number) {
                                arr.push({ number: arr2[j], hexCode: allColors[i].hexCode });
                                break;
                            }
                        }
                    }
                    setColors(arr);
                }
            })
        }
    }, [model])

    useEffect(() => {
        if (color) {
            let quantity2 = {}
            let arr1 = [];
            for (let i = 0; i < thermos.length; i++) {
                if (thermos[i].color == colors[color].number || (thermos[i].tags && thermos[i].tags.includes(colors[color].number))) {
                    arr1.push(thermos[i]);
                    quantity2[thermos[i]._id] = 1;
                }
            }
            setThermosToShow(arr1);
            let arr2 = [];
            for (let i = 0; i < porcelain.length; i++) {
                if (porcelain[i].color == colors[color].number || porcelain[i].tags.includes(colors[color].number)) {
                    arr2.push(porcelain[i]);
                    quantity2[porcelain[i]._id] = 1
                }
            }
            setPorcelainToShow(arr2);
            let arr3 = [];
            for (let i = 0; i < accessories.length; i++) {
                if (accessories[i].color == colors[color].number || accessories[i].tags.includes(colors[color].number)) {
                    arr3.push(accessories[i]);
                    quantity2[accessories[i]._id] = 1
                }
            }
            setAccessoriesToShow(arr3);
            setQuantity(quantity2)
        } else {
            setThermosToShow([]);
            setPorcelainToShow([]);
            setAccessoriesToShow([]);
            setQuantity({})
        }
    }, [color])

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["add_selected_products_to_cart"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Would you like to add the selected products to cart?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل تريد اضافة الاصناف المختارة للعربة؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAddToCart2}>
                        {dict["yes_add_them_to_cart"]}
                    </Button>
                    <Button variant="secondary" onClick={handleNo}>
                        {dict["no"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleNo = (event) => {
        event.preventDefault();
        handleClose();
        if (data.src == 'color') {
            setColor(data.value);
        } else if (data.src == 'model') {
            setModel(data.value);
        }
        setTotalPrice(0)
    }

    const handleModelChange = (event) => {
        if (checked.length > 0) {
            let src = 'model';
            let value = event.target.value
            setShow(true);
            setData({ src: src, value: value })
        } else {
            let selectedModel = event.target.value;
            setModel(selectedModel);
            setTotalPrice(0)
        }
    }

    const handleColorChange = (event) => {
        if (checked.length > 0) {
            let src = 'color';
            let value = event.target.value
            setShow(true);
            setData({ src: src, value: value })
        } else {
            let selectedColor = event.target.value;
            setColor(selectedColor);
            setTotalPrice(0)
        }

    }

    const handleChekedChange = (id, price) => (event) => {
        let newChecked = [...checked];
        let newPrice = totalPrice;
        if (checked.indexOf(id) === -1) {
            newChecked.push(id);
            newPrice += (price * quantity[id])
        } else {
            newChecked.splice(checked.indexOf(id), 1);
            newPrice -= (price * quantity[id])
        }
        setChecked(newChecked)
        setTotalPrice(newPrice)
    }

    const changeQuantity = (id, max, price) => newValue => {
        let oldQuantity = quantity[id];
        let newQuantity = newValue;
        if (newQuantity == 0) {
            newQuantity = 1;
        }
        if (newQuantity > max) {
            newQuantity = max;
        }
        setQuantity({ ...quantity, [id]: newValue <= 0 ? 1 : newValue > max ? max : newValue })
        if (checked.indexOf(id) != -1) {
            let newPrice = totalPrice;
            if (newQuantity < oldQuantity) {
                let dif = oldQuantity - newQuantity;
                newPrice -= (price * dif);
            } else if (oldQuantity < newQuantity) {
                let dif = newQuantity - oldQuantity;
                newPrice += (price * dif);
            }
            setTotalPrice(newPrice);
        }
    }

    const handleAddToCart = async () => {
        setShowBackdrop(true);
        for (let i = 0; i < checked.length; i++) {
            let found = false;
            let link = "";
            for (let j = 0; j < thermosToShow.length; j++) {
                if (thermosToShow[j]._id == checked[i]) {
                    found = true;
                    link = default_url + "products/" + (thermosToShow[j].category && thermosToShow[j].category.enName) + "/" + (thermosToShow[j].subcategory && thermosToShow[j].subcategory.enName ? thermosToShow[j].subcategory.enName : thermosToShow[j].category.enName) + "/" + thermosToShow[j].enTitle;
                }
            }
            for (let j = 0; j < thermosToShow.length; j++) {
                if (thermosToShow[j]._id == checked[i]) {
                    found = true;
                    link = default_url + "products/" + (thermosToShow[j].category && thermosToShow[j].category.enName) + "/" + (thermosToShow[j].subcategory && thermosToShow[j].subcategory.enName ? thermosToShow[j].subcategory.enName : thermosToShow[j].category.enName) + "/" + thermosToShow[j].enTitle;
                }
            }
            if (!found) {
                for (let j = 0; j < porcelainToShow.length; j++) {
                    if (porcelainToShow[j]._id == checked[i]) {
                        found = true;
                        link = default_url + "products/" + (porcelainToShow[j].category && porcelainToShow[j].category.enName) + "/" + (porcelainToShow[j].subcategory && porcelainToShow[j].subcategory.enName ? porcelainToShow[j].subcategory.enName : porcelainToShow[j].category.enName) + "/" + porcelainToShow[j].enTitle;
                    }
                }
            }
            if (!found) {
                for (let j = 0; j < accessoriesToShow.length; j++) {
                    if (accessoriesToShow[j]._id == checked[i]) {
                        found = true;
                        link = default_url + "products/" + (accessoriesToShow[j].category && accessoriesToShow[j].category.enName) + "/" + (accessoriesToShow[j].subcategory && accessoriesToShow[j].subcategory.enName ? accessoriesToShow[j].subcategory.enName : accessoriesToShow[j].category.enName) + "/" + accessoriesToShow[j].enTitle;
                    }
                }
            }
            if (i == checked.length - 1) {
                await addItem(checked[i], quantity[checked[i]], link, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null);
                setShowBackdrop(false);
                showSuccess()
            } else {
                await addItem(checked[i], quantity[checked[i]], link, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null);
            }
        }
    }

    const handleAddToCart2 = async () => {
        handleClose();
        for (let i = 0; i < checked.length; i++) {
            let found = false;
            let link = "";
            for (let j = 0; j < thermosToShow.length; j++) {
                if (thermosToShow[j]._id == checked[i]) {
                    found = true;
                    link = default_url + "products/" + (thermosToShow[j].category && thermosToShow[j].category.enName) + "/" + (thermosToShow[j].subcategory && thermosToShow[j].subcategory.enName ? thermosToShow[j].subcategory.enName : thermosToShow[j].category.enName) + "/" + thermosToShow[j].enTitle;
                }
            }
            if (!found) {
                for (let j = 0; j < porcelainToShow.length; j++) {
                    if (porcelainToShow[j]._id == checked[i]) {
                        found = true;
                        link = default_url + "products/" + (porcelainToShow[j].category && porcelainToShow[j].category.enName) + "/" + (porcelainToShow[j].subcategory && porcelainToShow[j].subcategory.enName ? porcelainToShow[j].subcategory.enName : porcelainToShow[j].category.enName) + "/" + porcelainToShow[j].enTitle;
                    }
                }
            }
            if (!found) {
                for (let j = 0; j < accessoriesToShow.length; j++) {
                    if (accessoriesToShow[j]._id == checked[i]) {
                        found = true;
                        link = default_url + "products/" + (accessoriesToShow[j].category && accessoriesToShow[j].category.enName) + "/" + (accessoriesToShow[j].subcategory && accessoriesToShow[j].subcategory.enName ? accessoriesToShow[j].subcategory.enName : accessoriesToShow[j].category.enName) + "/" + accessoriesToShow[j].enTitle;
                    }
                }
            }
            if (i == checked.length - 1) {
                await addItem(checked[i], quantity[checked[i]], link, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null, () => {
                    showSuccess();
                    if (data.src == "model") {
                        setModel(data.value)
                        setTotalPrice(0);
                    } else if (data.src == "color") {
                        setTotalPrice(0);
                        setColor(data.value);
                    }
                });
                // showSuccess();
                // if(data.src == "model"){
                //     setModel(data.value)
                //     setTotalPrice(0);
                // }else if(data.src == "color"){
                //     setTotalPrice(0);
                //     setColor(data.value);
                // }
            } else {
                await addItem(checked[i], quantity[checked[i]], link, cookies._fbp ? cookies._fbp : null, cookies._fbc ? cookies._fbc : null);
            }
        }
    }

    const showSuccess = () => {
        store.addNotification({
            message: dict['collection_has_been_added_to_cart'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showSuccess2 = () => {
        store.addNotification({
            message: dict['collection_has_been_added_to_cart'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
        if (data.src == 'color') {
            setColor(data.value);
        } else if (data.src == 'model') {
            setModel(data.value);
        }
        setTotalPrice(0)
    }

    const getTitleForThermos = (itemCode) => {
        if (itemCode.includes('CF')) {
            if (default_url == '/en/') {
                return "Coffee"
            }
            return "قهوة"
        } else {
            if (default_url == '/en/') {
                return "Tea"
            }
            return "شاي"
        }
    }

    const cssstyle = `
    .collection-numericInput .react-numeric-input{
        width: 100% !important;
    }

    .collection-numericInput input {
        width: 100% !important;
    }
    `

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={showLoadingBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <div className="container-fluid footerTagsContainer" style={{ padding: '20px 0 0', marginBottom: window.innerWidth < 1024 ? "20px" : model && color ? "20px" : "600px" }} dir={default_url === "/en/" ? "ltr" : "rtl"} >
            {showBackdrop()}
            <style>
                {cssstyle}
            </style>
            {popUp()}
            <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center', marginBottom: "10px", }}>{dict['collections']}</h1>
            <h1 style={{ color: 'gray', fontSize: "16px", fontWeight: "500", textAlign: 'center', marginBottom: "20px" }}>{dict['get_your_collection_as_you_like']}</h1>
            <div className="footerTagsTextContainer" style={{ padding: '20px 5px 15px', marginBottom: "30px" }}>
                <FormControl component="fieldset" style={{ textAlign: "start", padding: "0 20px" }}>
                    <FormLabel component="legend" style={{ fontSize: "18px", color: 'black' }}>{dict['choose_model']}</FormLabel>
                    <RadioGroup row value={model} onChange={handleModelChange} aria-label="gender" name="row-radio-buttons-group">
                        {
                            values.length > 0 && values.map((item, i) => {
                                return <FormControlLabel value={i + ""} control={<Radio style={{ color: 'rgb(228, 188, 75)' }} />} label={<Typography style={{ fontSize: "18px", marginInlineEnd: window.innerWidth < 1024 ? "0" : "10px", marginBottom: window.innerWidth < 1024 ? "15px" : "0" }}>
                                    <div style={{ width: window.innerWidth < 1024 ? "50%" : "75%", textAlign: "center" }}>
                                        {item.model}
                                    </div>
                                    <img style={{ textAlign: "center", boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)', width: window.innerWidth < 1024 ? "50%" : "75%" }} src={IMAGE_URL + item.image + ".jpg"}></img>

                                </Typography>} />
                            })
                        }
                    </RadioGroup>
                </FormControl>
                <br />
                {model && colors.length > 0 && <FormControl component="fieldset" style={{ textAlign: "start", padding: "0 20px", margin: '20px 0' }}>
                    <FormLabel component="legend" style={{ fontSize: "18px", color: 'black' }}>{dict['choose_color']}</FormLabel>
                    <RadioGroup row value={color} onChange={handleColorChange} aria-label="gender" name="row-radio-buttons-group">
                        {
                            colors.length > 0 && colors.sort((a, b) => { return a - b }).map((item, i) => {
                                return <FormControlLabel style={{ margin: "5px 0 10px" }} value={i + ""} control={<Radio style={{ color: 'rgb(228, 188, 75)' }} />} label={<div style={{ fontSize: "18px", width: "60px", height: "40px", backgroundColor: item.hexCode, boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)' }}></div>} />
                            })
                        }
                    </RadioGroup>
                </FormControl>}

                {model && color &&
                    <div style={{ margin: "10px 0 20px", padding: '0px 20px' }}>
                        <br />
                        <h5 className="productsContainerTitle" style={{ textAlign: "start", fontSize: "18px" }}>{dict['preview_image'] + ": "}</h5>
                        <img style={{ boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)', marginTop: "10px" }} src={IMAGE_URL + "SET-" + values[model].model + "-" + colors[color].number + ".jpg"}></img>
                    </div>
                }



                {
                    thermosToShow.length > 0 && <div style={{ marginTop: "20px", padding: '0px 20px' }}>
                        <br />
                        <h5 className="productsContainerTitle" style={{ textAlign: "start", fontSize: "18px" }}>{dict['thermos']}</h5>
                        <div className="container-fluid break-line"></div>
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                {
                                    thermosToShow.length > 0 && thermosToShow.map((item, i) => {
                                        return (
                                            <Grid xs={6} sm={2} >
                                                <Paper className={classes.paper}>
                                                    <FormControlLabel
                                                        value={item._id}
                                                        control={
                                                            <Checkbox
                                                                style={{ color: 'rgb(228, 188, 75)', fontSize: '30px' }}
                                                                checked={checked.indexOf(item._id) != -1}
                                                                onChange={handleChekedChange(item._id, item.discountPrice ? item.discountPrice : item.price)}
                                                            />}
                                                        label={
                                                            <div style={{ color: 'black' }}>
                                                                <div>
                                                                    <h3 className="title" style={{ height: "80px", textAlign: "center", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>{default_url == "/en/" ? item.enName : item.arName}</h3>
                                                                    <img style={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.3)' }} src={IMAGE_URL + item.images[0] + "_small.jpg"} />
                                                                </div>
                                                            </div>
                                                        }
                                                        labelPlacement="bottom"
                                                    />
                                                    <div style={{ color: 'black', margin: '0 16px' }}>
                                                        <div style={{ display: "block" }}>
                                                            <div className={"price " + (item.discountPrice ? "discount" : "")} style={{ display: "inline-block", textAlign: "start", direction: default_url == "/ar/" ? "rtl" : "ltr", width: "auto", marginInlineEnd: "10px" }}>
                                                                {
                                                                    item.price.toFixed(3) + " " + (item.discountPrice || item.discountPrice == 0 ? "" : dict['kd'])
                                                                }
                                                            </div>
                                                            <div className="afterDiscount" style={{ marginInlineStart: "0", textAlign: "start", display: item.discountPrice ? "inline-block" : "none", direction: default_url == "/ar/" ? "rtl" : "ltr", width: "auto", }}>
                                                                {
                                                                    item.discountPrice ? (item.discountPrice.toFixed(3) + " " + dict['kd']) : (item.discountPrice + " " + dict['kd'])
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="collection-numericInput" style={{ textAlign: "start", marginBottom: "20px" }}>
                                                            <div>{dict["quantity"]}: </div>
                                                            {item.quantity === 0 && <NumericInput style={{ width: "100% !important" }} disabled min={0} max={item.quantity} value={0} onChange={changeQuantity(item._id, item.quantity, item.discountPrice ? item.discountPrice : item.price)} />}
                                                            {item.quantity > 0 && <NumericInput style={{ width: "100% !important" }} min={1} max={item.quantity} value={quantity[item._id]} onChange={changeQuantity(item._id, item.quantity, item.discountPrice ? item.discountPrice : item.price)} />}

                                                        </div>
                                                    </div>

                                                </Paper>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </div>
                    </div>
                }


                {
                    porcelainToShow.length > 0 && <div style={{ marginTop: "20px", padding: '0px 20px' }}>
                        <br />
                        <h5 className="productsContainerTitle" style={{ textAlign: "start", fontSize: "18px" }}>{dict['porcelain']}</h5>
                        <div className="container-fluid break-line"></div>
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                {
                                    porcelainToShow.length > 0 && porcelainToShow.map((item, i) => {
                                        return (
                                            <Grid xs={6} sm={2} >
                                                <Paper className={classes.paper}>
                                                    <FormControlLabel
                                                        value={item._id}
                                                        control={
                                                            <Checkbox
                                                                style={{ color: 'rgb(228, 188, 75)', fontSize: '30px' }}
                                                                checked={checked.indexOf(item._id) != -1}
                                                                onChange={handleChekedChange(item._id, item.discountPrice ? item.discountPrice : item.price)}
                                                            />}
                                                        label={
                                                            <div style={{ color: 'black' }}>
                                                                <div>
                                                                    <h3 className="title" style={{ height: "80px", textAlign: "center", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>{default_url == "/en/" ? item.enTitle : item.arTitle}</h3>
                                                                    <img style={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.3)' }} src={IMAGE_URL + item.images[0] + "_small.jpg"} />
                                                                </div>
                                                            </div>
                                                        }
                                                        labelPlacement="bottom"
                                                    />
                                                    <div style={{ color: 'black', margin: '0 16px' }}>
                                                        <div style={{ display: "block" }}>
                                                            <div className={"price " + (item.discountPrice ? "discount" : "")} style={{ display: "inline-block", textAlign: "start", direction: default_url == "/ar/" ? "rtl" : "ltr", width: "auto", marginInlineEnd: "10px" }}>
                                                                {
                                                                    item.price.toFixed(3) + " " + (item.discountPrice || item.discountPrice == 0 ? "" : dict['kd'])
                                                                }
                                                            </div>
                                                            <div className="afterDiscount" style={{ marginInlineStart: "0", textAlign: "start", display: item.discountPrice ? "inline-block" : "none", direction: default_url == "/ar/" ? "rtl" : "ltr", width: "auto", }}>
                                                                {
                                                                    item.discountPrice ? (item.discountPrice.toFixed(3) + " " + dict['kd']) : (item.discountPrice + " " + dict['kd'])
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="collection-numericInput" style={{ textAlign: "start", marginBottom: "20px" }}>
                                                            <div>{dict["quantity"]}: </div>
                                                            {item.quantity === 0 && <NumericInput style={{ width: "100% !important" }} disabled min={0} max={item.quantity} value={0} onChange={changeQuantity(item._id, item.quantity, item.discountPrice ? item.discountPrice : item.price)} />}
                                                            {item.quantity > 0 && <NumericInput style={{ width: "100% !important" }} min={1} max={item.quantity} value={quantity[item._id]} onChange={changeQuantity(item._id, item.quantity, item.discountPrice ? item.discountPrice : item.price)} />}

                                                        </div>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </div>
                    </div>
                }


                {
                    accessoriesToShow.length > 0 && <div style={{ marginTop: "20px", padding: '0px 20px' }}>
                        <br />
                        <h5 className="productsContainerTitle" style={{ textAlign: "start", fontSize: "18px" }}>{dict['accessories']}</h5>
                        <div className="container-fluid break-line"></div>
                        <div className={classes.root}>
                            <Grid container spacing={3}>
                                {
                                    accessoriesToShow.length > 0 && accessoriesToShow.map((item, i) => {
                                        return (
                                            <Grid xs={6} sm={2} >
                                                <Paper className={classes.paper}>
                                                    <FormControlLabel
                                                        value={item._id}
                                                        control={
                                                            <Checkbox
                                                                style={{ color: 'rgb(228, 188, 75)', fontSize: '30px' }}
                                                                checked={checked.indexOf(item._id) != -1}
                                                                onChange={handleChekedChange(item._id, item.discountPrice ? item.discountPrice : item.price)}
                                                            />}
                                                        label={
                                                            <div style={{ color: 'black' }}>
                                                                <div>
                                                                    <h3 className="title" style={{ height: "80px", textAlign: "center", direction: default_url == "/ar/" ? "rtl" : "ltr" }}>{default_url == "/en/" ? item.enTitle : item.arTitle}</h3>
                                                                    <img style={{ width: '100%', border: '1px solid rgba(0, 0, 0, 0.3)' }} src={IMAGE_URL + item.images[0] + "_small.jpg"} />
                                                                </div>
                                                            </div>
                                                        }
                                                        labelPlacement="bottom"
                                                    />
                                                    <div style={{ color: 'black', margin: '0 16px' }}>
                                                        <div style={{ display: "block" }}>
                                                            <div className={"price " + (item.discountPrice ? "discount" : "")} style={{ display: "inline-block", textAlign: "start", direction: default_url == "/ar/" ? "rtl" : "ltr", width: "auto", marginInlineEnd: "10px" }}>
                                                                {
                                                                    item.price.toFixed(3) + " " + (item.discountPrice || item.discountPrice == 0 ? "" : dict['kd'])
                                                                }
                                                            </div>
                                                            <div className="afterDiscount" style={{ marginInlineStart: "0", textAlign: "start", display: item.discountPrice ? "inline-block" : "none", direction: default_url == "/ar/" ? "rtl" : "ltr", width: "auto", }}>
                                                                {
                                                                    item.discountPrice ? (item.discountPrice.toFixed(3) + " " + dict['kd']) : (item.discountPrice + " " + dict['kd'])
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="collection-numericInput" style={{ textAlign: "start", marginBottom: "20px" }}>
                                                            <div>{dict["quantity"]}: </div>
                                                            {item.quantity === 0 && <NumericInput style={{ width: "100% !important" }} disabled min={0} max={item.quantity} value={0} onChange={changeQuantity(item._id, item.quantity, item.discountPrice ? item.discountPrice : item.price)} />}
                                                            {item.quantity > 0 && <NumericInput style={{ width: "100% !important" }} min={1} max={item.quantity} value={quantity[item._id]} onChange={changeQuantity(item._id, item.quantity, item.discountPrice ? item.discountPrice : item.price)} />}

                                                        </div>
                                                    </div>

                                                </Paper>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </div>
                    </div>
                }

                {
                    color && <div style={{ padding: '20px 0 5px', textAlign: "center", fontSize: '20px', marginTop: "20px", color: 'red' }}>
                        <br />
                        {dict['total_price'] + ": " + totalPrice.toFixed(3) + " " + dict['kd']}
                    </div>
                }
                {
                    color && <div style={{ textAlign: "center", fontSize: '20px', marginTop: "5px" }}>
                        {totalPrice > 0 && <button style={{ fontSize: "20px", padding: '10px', width: '250px' }} className="productViewAddToCart" onClick={handleAddToCart}>{dict["add_collection_to_cart"]}</button>}
                        {totalPrice == 0 && <button style={{ fontSize: "20px", padding: '10px', width: '250px' }} className="productViewAddToCart outOfStockButton" onClick={handleAddToCart} disabled >{dict["add_collection_to_cart"]}</button>}
                    </div>
                }

            </div>
        </div>
    )
}

export default Collections;