import React, { useState, useEffect, Fragment } from "react";
import UserDashboard from "../UserDashboard"
import { isAuthenticated } from "../../../auth"
import { dict, en_dic, ar_dic } from "../../../dictionary";
import { default_url } from "../../..";
import { listAddresses, deleteAddress } from "../apiUser"
import { store } from 'react-notifications-component';
import { getWishlist, removeItem } from "../../wishlistHelpers";
import { IMAGE_URL } from "../../../config";

function UserWishlist (){

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [mouseOverRemove, setMouseOverRemove] = useState(-1);

    useEffect(() => {
        let items = getWishlist();
        setData(items);
    }, [data2]);


    const deleteItem = productId => event => {
        setData2(removeItem(productId));
        store.addNotification({
            message: dict['product_has_been_removed_from_wishlist'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const showItems = () => {
        return (
            data.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <a href={item.link}>
                                <img className="cart-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                            </a>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info">{default_url === "/en/" ? item.enName : item.arName}</h6>
                                    {item.color !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Color " : "اللون "} {item.color}</h6>}
                                    {item.size !== null && <h6 className="cart-item-info">{default_url === "/en/" ? "Size " : "حجم "} {item.size}</h6>}
                                    <h6 className="cart-item-info cart-item-info-price" style={{textDecoration: item.discountPrice ? "line-through" : ""}}>{item.price + " " + dict['kd']}</h6>
                                    <h6 className="cart-item-info cart-item-info-price" style={{display: item.discountPrice ? "" : "none", color: "red"}}>{item.discountPrice + " " + dict['kd']}</h6>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-25px"}}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ textDecoration: "underline", fontSize: "16px", cursor: mouseOverRemove === i ? "pointer" : "", color: mouseOverRemove === i ? "#E4BC4B" : "" }}
                                        onMouseOver={() => setMouseOverRemove(i)}
                                        onMouseOut={() => setMouseOverRemove(-1)}
                                        onClick={deleteItem(item._id)}
                                    >
                                        {dict["remove"]}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const noItemsMsg = () => {
        return (<h2 style={{ textAlign: "start" }}>{dict['wishlist_is_empty'] + "."}</h2>);
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                <h3 className="formContainerTitle">{dict['my_wishlist']}</h3>
                <hr />
                {data.length === 0 && noItemsMsg()}
                {showItems()}
            </div>
        )
    }

    return (
        <UserDashboard
            active="4"
            data={form}
        />
    )

}

export default UserWishlist;