import React, { useState, useEffect, Fragment } from "react";
import UserDashboard from "../UserDashboard"
import { isAuthenticated } from "../../../auth"
import { dict, en_dic, ar_dic } from "../../../dictionary";
import { default_url } from "../../..";
import { getReviews, deleteReview } from "../apiUser"
import { store } from 'react-notifications-component';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { IMAGE_URL } from "../../../config";

function UserReviews() {

    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [mouseOverRemove, setMouseOverRemove] = useState(-1);
    const [error, setError] = useState("");

    const { user, token } = isAuthenticated();

    const loadReviews = () => {
        getReviews(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setData(res);
            }
        })
    };

    useEffect(() => {
        loadReviews();
    }, []);


    const deleteItem = reviewId => event => {
        deleteReview(user._id, token, reviewId).then(res => {
            if (res.error) {
                setError(res.error);
            } else {
                setError("")
            }
        })
        store.addNotification({
            message: error ? error : dict['review_has_been_deleted'],
            type: error ? 'danger' : "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
        loadReviews();
    }

    const showItems = () => {
        return (
            data.map((item, i) => {
                return (
                    <Fragment key={i}>
                        <div className="cart-item">
                            <a href={item.link}>
                                <img className="cart-item-image" src={IMAGE_URL + item.images[0] + "_small.jpg"}></img>
                            </a>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block" }}>
                                <div className="cart-item-info-container">
                                    <h6 className="cart-item-info" style={{fontSize:"17px"}}>{default_url === "/en/" ? item.product.enName : item.product.arName}</h6>
                                    <Box component="fieldset" borderColor="transparent" style={{ fontSize: "18px !important", marginBottom: "15px" }}>
                                        <Rating
                                            readOnly
                                            name="read-only"
                                            size="small"
                                            value={item.rate}
                                            style={{ marginTop: "10px", fontSize: "50px !important", direction: "ltr" }}
                                        />
                                    </Box>
                                    <h6 className="cart-item-info" style={{fontSize:"17px"}}>{"\"" + item.comment + "\""}</h6>
                                </div>
                            </div>
                            <div style={{ height: "auto", width: "auto", position: "initial", display: "inline-block", float: default_url === "/en/" ? "right" : "left" }}>
                                <div className="cart-item-info-container" style={{ marginInlineStart: default_url == "/en/" ? "-55px" : "-25px" }}>
                                    <div
                                        className="cart-item-info cart-item-info-remove"
                                        style={{ textDecoration: "underline", fontSize: "16px", cursor: mouseOverRemove === i ? "pointer" : "", color: mouseOverRemove === i ? "#E4BC4B" : "" }}
                                        onMouseOver={() => setMouseOverRemove(i)}
                                        onMouseOut={() => setMouseOverRemove(-1)}
                                        onClick={deleteItem(item._id)}
                                    >
                                        {dict["remove"]}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                    </Fragment>
                );
            })
        );
    }

    const noItemsMsg = () => {
        return (<h2 style={{ textAlign: "start" }}>{dict['no_reviews_found'] + "."}</h2>);
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                <h3 className="formContainerTitle">{dict['my_reviews']}</h3>
                <hr />
                {data.length === 0 && noItemsMsg()}
                {showItems()}
            </div>
        )
    }

    return (
        <UserDashboard
            active="5"
            data={form}
        />
    )

}

export default UserReviews;