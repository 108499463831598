import React, { useState, useEffect, Fragment } from 'react';
import { IMAGE_URL } from "../../config"
import { dict } from "../../dictionary"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { default_url } from '../..';
import { store } from 'react-notifications-component';
import { addFreeItem } from '../cartHelpers';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: "black"
  },
  ExpansionPanelDetails: {
    display: "inline",
    padding: "0"
  },
  panelHeader: {
    backgroundColor: "#f7f7f7",
    width: "100%"
  }
}));

function Buy1Get1Free({ open, quantity, handleClose, items, next, src="" }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel0');
  const [selected, setSelected] = useState(new Array(quantity).fill(null));
  const [itemsToShow, setItemsToShow] = useState([]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < items.length; i++) {
      if(items[i].child.quantity > 0){
        arr.push({...items[i]});
      }
    }
    setItemsToShow([...arr]);
  }, [])

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleItemChange = (index) => (event) => {
    let value = event.target.value
    let tmp = [...selected];
    tmp[index] = value;
    setSelected(tmp);
  }

  const handleAddToCart = () => {
    if(selected.some(el => el == null)){
      showError();
    }else{
      let newItems = [];
      selected.map((item) => {
        newItems.push({
          _id: items[item]._id,
          parent: items[item].parent,
          child: items[item].child._id
        })
      });
      addFreeItem(newItems);
      handleClose();
      next();
    }
  }

  const showError = () => {
    store.addNotification({
        message: dict['please_select_all_gifts'],
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 2500,
            onScreen: true,
            showIcon: true
        }
    });
}

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ zIndex: 100000 }}
    >
      <AppBar style={{ backgroundColor: "#E4BC4B" }} sx={{ position: 'relative' }}>
        <Toolbar>
        {
          src != "cart-add" && src != "cart-remove" && src != "missing" &&
          <IconButton
            edge="start"
            color="inherit"
            onClick={src == "cart-add" || src == "cart-remove" ? () => {} : handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        }
          
          <Typography style={{ textAlign: "start", marginInlineStart: "16px", marginInlineEnd: "0" }} sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {src == "cart-add" ? dict['choose_additional_gifts'] : src == "cart-remove" ? dict['rechoose_your_gifts_again'] : dict['choose_your_gift']}
          </Typography>
          {/* <Button autoFocus style={{fontSize: "18px"}} color="inherit" onClick={handleAddToCart}>
            {src == "cart-add" || src == "cart-remove" ? dict['add_gifts'] : dict['add_to_cart']}
          </Button> */}
        </Toolbar>
      </AppBar>
      <List>
        {
          new Array(quantity).fill(0).map((_, index) => (
            <ListItem key={index} style={{ textAlign: "start" }} button>
              <ExpansionPanel
                expanded={expanded === ("panel" + index)}
                onChange={handleChangePanel(("panel" + index))}
                className={classes.root}
              >
                <ExpansionPanelSummary
                  className={classes.panelHeader}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.secondaryHeading} style={{ textAlign: "start", width: "30%" }}>
                    اختر الهدية {index + 1}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                  <div className="footerTagsTextContainer" style={{ padding: '20px 5px 15px' }}>
                    <FormControl component="fieldset" style={{ textAlign: "start", padding: "0 5px" }}>
                      <RadioGroup row value={selected[index]} onChange={handleItemChange(index)} aria-label="item" name="row-radio-buttons-group">
                        {
                          itemsToShow.length > 0 && itemsToShow.map((item, i) => {
                            return <FormControlLabel value={i + ""} control={<Radio style={{ color: 'rgb(228, 188, 75)' }} />} label={<Typography style={{ fontSize: window.innerWidth < 1024 ? "14px" : "18px", marginInlineEnd: window.innerWidth < 1024 ? "0" : "10px", marginBottom: window.innerWidth < 1024 ? "15px" : "10px" }}>
                              <div style={{ width: window.innerWidth < 1024 ? "85%" : "75%", textAlign: "center", marginBottom: "5px" }}>
                                {default_url == "/en/" ? item.child.enTitle : item.child.arTitle}
                              </div>
                              <img style={{ textAlign: "center", boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)', width: window.innerWidth < 1024 ? "85%" : "75%" }} src={IMAGE_URL + item.child.images[0] + "_small.jpg"}></img>
                            </Typography>} style={{ width: window.innerWidth < 1024 ? "48%" : "", marginBottom: "10px", marginInlineStart: "0", marginInlineEnd: "5px"}} />
                          })
                        }
                      </RadioGroup>
                    </FormControl>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <Divider />
            </ListItem>

          ))
        }
      </List>
      <Button autoFocus style={{fontSize: "20px", fontWeight: "700", color: "white", width: "auto", backgroundColor: "rgb(228, 188, 75)", margin: "0 16px"}} color="inherit" onClick={handleAddToCart}>
            {src == "cart-add" || src == "cart-remove" ? dict['add_gifts'] : dict['add_to_cart']}
      </Button>
    </Dialog>
  )
}

export default Buy1Get1Free;