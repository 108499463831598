import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { deleteNewArrivalProduct, updateGeneral } from "../../apiAdmin"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { listNewArrivals, listProductsByFilters, getGeneral } from "../../../apiCore";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


function NewArrivalAdmin() {
    const [data, setData] = useState([]);
    const [sorting, setSorting] = useState({
        active: false,
        error: "",
        success: false
    });
    const [active, setActive] = useState(true);
    const [general, setGeneral] = useState();
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState();

    const { user, token } = isAuthenticated();

    const loadNewArrivals = () => {
        listNewArrivals().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                if (res.length > 0) {
                    let ids = [];
                    res.map(item => {
                        ids.push(item.product);
                    });
                    let filters = {
                        _id: ids
                    };
                    listProductsByFilters(0, 1000000, filters).then(items => {
                        if (items.error) {
                            console.log(items.error);
                        } else {
                            setData(items.data);
                        }
                    })
                } else {
                    setData([]);
                }
            }
        });
    }

    const loadGeneral = () => {
        getGeneral("name", "showNewArrival").then(res => {
            if(res.error){
                console.log(res.error);
            }else{
                setGeneral(res);
                setActive(res.booleanValue);
            }
        })
    }

    useEffect(() => {
        loadNewArrivals();
        loadGeneral();
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        deleteNewArrivalProduct(deletedItem.id, user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                loadNewArrivals();
                setDeletedItem({ ...deletedItem, success: true });
            }
        })
    }

    const handleChange = (name) => (event) => {
        updateGeneral({...general, booleanValue: !active}, user._id, token).then(res => {
            if(res.error){
                console.log(res.error);
            }else{
                setActive(!active);
                setGeneral(res);
            }
        })
    };

    const clickSubmit = target => () => {
        if(target == "add"){
            window.location.href = default_url + "admin/new-arrival/add"
        }else if(target == "replace"){
            window.location.href = default_url + "admin/new-arrival/replace"
        }
        
    }

    // const showAlert = () => {
    //     if ((props.location.state)) {
    //         let msg = ""
    //         if (props.location.state) {
    //             switch (props.location.state.prevPath) {
    //                 case "replaced": msg = props.location.state.error ? props.location.state.error : dict["new_product_has_been_created"]; break;
    //                 case "delete": msg = props.location.state.error ? props.location.state.error : dict["product_has_been_deleted"]; break;
    //                 case "edit": msg = props.location.state.error ? props.location.state.error : dict["product_has_been_updated"]; break;
    //                 default: msg = "";
    //             }
    //         }
    //         return (
    //             <div
    //                 className={"alert " + (((props.location.state && props.location.state.error)) ? "alert-danger" : "alert-info") + " alert-dismissible fade show"}
    //                 role="alert"
    //                 style={{width:"75%"}}
    //             >
    //                 <h2 style={{textAlign:"center"}}>{msg}</h2>
    //                 <button type="button" class="close alertCloseBtn" data-dismiss="alert" aria-label="Close">
    //                     <span aria-hidden="true">&times;</span>
    //                 </button>
    //             </div>
    //         )
    //     }
    // }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_product"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + " from new arrivals?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد ازالة المنتج " + deletedItem.name + " من قائمة الوصل حديثاً؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showAlert = () => {
        if (deletedItem.success) {
            return (
                <div
                    className={"alert alert-info alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{dict["product_has_been_deleted"]}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const showActivationSwitch = () => {
        return (
            <FormControlLabel
                value="start"
                control={
                    <Switch
                        checked={active}
                        onChange={handleChange('active')}
                        color="primary"
                        className="switch"
                    />
                }
                label={dict["activate"]}
                labelPlacement="start"
                className="dashboardSortingSwitch"
                style={{ float: default_url == "/en/" ? "right" : "left" }}
            />
        )
    }

    const allProducts = () => {
        return (
            data.map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right" }}>{index + 1}</span>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right" }}>{item.itemCode}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.itemCode}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left" }}
                        >{dict["delete"]}</a>
                        {popUp()}
                    </div>
                )
            })
        )
    }

    const showEmpty = () => {
        return (
            <h1 style={{ textAlign: "start" }}>{dict["no_products_found"]}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["new_arrival_list"]}</h3>
                {showActivationSwitch()}
                {data.length > 0 ? allProducts() : showEmpty()}
                <div>
                    <button onClick={clickSubmit("add")} type="submit" className="dashboardBtn" style={{width:"47.5%", marginInlineEnd: "2.5%"}}>{dict["add_new_item_to_new_arrival"]}</button>
                    <button onClick={clickSubmit("replace")} type="submit" className="dashboardBtn" style={{width:"47.5%", marginInlineStart: "2.5%"}}>{dict["replace_current_products_in_new_arrival"]}</button>
                </div>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="6"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default NewArrivalAdmin;