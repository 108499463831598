import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { deleteMultipleProducts } from "../../apiAdmin"
import { Redirect } from "react-router-dom";
import { from } from "form-data";
import { default_url } from "../../../..";
import { dict } from "../../../../dictionary";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function DeleteMultipleProducts() {
    const classes = useStyles();
    const [values, setValues] = useState({
        error: null,
        redirectToReferrer: false,
        formData: ""
    });
    const [err, setErr] = useState(false);
    const { user, token } = isAuthenticated();
    const [loading, setLoading] = useState(false);

    const {formData, redirectToReferrer, error} = values;

    useEffect(() => {
        setValues({ ...values, formData: new FormData() });
    }, []);

    const handleChange = name => event => {
        for (let i = 0; i < event.target.files.length; i++) {
            formData.set(`products`, event.target.files[i]);
        }
    };


    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        deleteMultipleProducts(formData, user._id, token)
            .then(data => {
                setLoading(false);
                if (data.error) {
                    setValues({ ...values, error: data.error, redirectToReferrer: false });
                } else {
                    console.log(data);
                    showSuccess();
                }
            })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none" }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        store.addNotification({
            message: dict['products_have_been_deleted'],
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        });
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            return (
                <Redirect to={{ pathname: default_url + 'admin/products', state: { prevPath: "delete", error } }} />
            )
        }
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const multipleProducts = () => {
        return (
            <div className="multipleCategoriesForm">
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("excel")} type="file" multiple className="inputField form-control" name="excel" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {redirectUser()}
                <h3 className="formContainerTitle">{dict["delete_multiple_products"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    {multipleProducts()}
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["delete"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default DeleteMultipleProducts;