import React, { useState, useEffect, Fragment } from "react";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { addNewArrivalProducts, addAdsImage } from "../../apiAdmin"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { listNewArrivals, listProductsByFilters, listCategories, listSubcategoriesByCategory } from "../../../apiCore";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { from } from "form-data";
import { set } from "js-cookie";
import { Link } from "react-router-dom";


function AddNewAd() {
    const [formData, setFormData] = useState();
    const [link, setLink] = useState("");
    const [position, setPosition] = useState("");
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const { user, token } = isAuthenticated();

    useEffect(() => {
        setFormData(new FormData());
    }, [])

    const handleChange = target => event => {
        if (target == "img") {
            setError(null);
            formData.set("image", event.target.files[0]);
        } else if (target == "link") {
            setError(null);
            formData.set("link", event.target.value)
            setLink(event.target.value);
        }
        else if (target == "position") {
            setError(null);
            formData.set("position", event.target.value)
            setPosition(event.target.value);
        }
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        if (formData.get("image")) {
            if (formData.get("position")) {
                addAdsImage(formData, user._id, token).then(res => {
                    if (res.error) {
                        setError(res.error);
                    } else {
                        setFormData(new FormData());
                        setSuccess(true);
                        setError(null);
                    }
                })
            } else {
                setError("please_enter_ad_position");
            }

        } else {
            setError("please_upload_image");
        }
    }


    const showAlert = () => {
        if (error) {
            return (
                <div className="alert alert-danger" style={{ textAlign: 'start' }}>
                    {dict[error] != undefined ? dict[error] : error}
                </div>
            )
        } else if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_ad_has_been_added.add_more_or']} <Link to={default_url + "admin/ads"} style={{ fontSize: "18px" }}>{dict["go_back_to_ads_list"]}</Link>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const addNewAdForm = () => {
        return (
            <form>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["upload_image"]} <span className="redStar">*</span></label><br />
                <input onChange={handleChange("img")} type="file" className="inputField form-control" name="image" accept="image/x-png,image/gif,image/jpeg"></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["ad_position"]} <span className="redStar">*</span></label>
                <input onChange={handleChange("position")} type="text" value={position} className="inputField form-control" id="position" placeholder={dict["ad_position"]}></input>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["link"]}</label>
                <input onChange={handleChange("link")} type="text" value={link} className="inputField form-control" id="link" placeholder={dict["link"]}></input>
            </form>
        )

    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["add_new_ad"]}</h3>
                {addNewAdForm()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="8"
            data={form}
            categories={[]}
            subcategories={[]}
            newOrders={0}
        />
    )
}

export default AddNewAd;