import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { createColor } from "../../apiAdmin"
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import { Link } from "react-router-dom";

function AddNewColor() {
    const [values, setValues] = useState({
        number: "",
        arName: "",
        enName: "",
        hexCode: "",
        error: "",
        success: false,
    });

    const { user, token } = isAuthenticated();
    const { number, arName, enName, hexCode, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let color = { number: number, arName: arName, enName: enName, hexCode: hexCode }
        createColor(color, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ number: "", arName: "", enName: "", hexCode: "", error: null, success: true });
            }
        })
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_color_has_been_added._add_more_or']} <Link to={default_url + "admin/colors"} style={{ fontSize: "18px" }}>{dict["go_back_to_colors_list"]}</Link>
                </div>
            )
        }
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_new_color"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_number']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("number")} type="text" value={number} className="inputField form-control" id="color_number"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_name_in_arabic']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="color_name_in_arabic"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_name_in_english']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("enName")}type="text" value={enName} className="inputField form-control" id="color_name_in_english"></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict['color_hex_code']} <span className="redStar">*</span></label>
                    <input onChange={handleChange("hexCode")} type="text" value={hexCode} className="inputField form-control" id="color_hex_code"></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="4"
            data={form}
        />
    )
}

export default AddNewColor;