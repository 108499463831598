import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { getTypeOptions, createCoupon, createGift } from "../../apiAdmin"
import { countOfSubcategories, listCategories } from "../../../apiCore"
import { Redirect } from "react-router-dom";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";

function AddNewGift() {
    const [values, setValues] = useState({
        name: null,
        quantity: null,
        minimumCart: null,
        active: null,
        image: null,
        error: "",
        success: false,
    });

    const [typeOptions, setTypeOptions] = useState([]);

    const { user, token } = isAuthenticated();
    const { name, quantity, minimumCart, active, image, error, success } = values;

    const loadTypeOptions = () => {
        getTypeOptions(user._id, token).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setTypeOptions(res);
            }
        })
    }

    useEffect(() => {
        loadTypeOptions();
    }, []);

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        let gift = { name: name, quantity: quantity, minimumCart: minimumCart, active: active, image: image }
        createGift(gift, user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ name: null, quantity: null, minimumCart: null, active: null, image: null, error: null, success: true });
            }
        })
    }

    const activeSelector = () => {
        return (
            <select onChange={handleChange("active")} className="inputField form-control" value={active}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['new_gift_has_been_added.add_more_or']} <a href={default_url + "admin/gifts"} style={{ fontSize: "18px" }}>{dict["go_back_to_gitfs_list"]}</a>
                </div>
            )
        }

    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["add_new_gift"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["gift_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("name")} type="text" value={name} className="inputField form-control" id="code" placeholder={dict["gift_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["quantity"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("quantity")} type="text" value={quantity} className="inputField form-control" id="code" placeholder={dict["quantity"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["minimum_cart"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("minimumCart")} type="text" value={minimumCart} className="inputField form-control" id="code" placeholder={dict["minimum_cart"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["active"]} <span className="redStar">*</span></label><br />
                    {activeSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["image"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("image")} type="text" value={image} className="inputField form-control" id="code" placeholder={dict["image"]}></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="12"
            data={form}
        />
    )
}

export default AddNewGift;