import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AdminDashboard from "../../AdminDashboard";
import { dict } from "../../../../dictionary";
import { default_url } from "../../../..";
import { isAuthenticated } from "../../../../auth";
import { feedbackByMobile, listFeedbacks, updateFeedback } from "../../apiAdmin";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: "black"
    },
    ExpansionPanelDetails: {
        display: "inline",
        padding: "0"
    },
    panelHeader: {
        backgroundColor: "#f7f7f7"
    }
}));

const useStyles3 = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const ref = React.createRef();

function FeedbackAdmin() {
    const classes = useStyles();
    const classes2 = useStyles2();
    const classes3 = useStyles3();
    const [showLoadingBackdrop, setShowBackdrop] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [limit, setLimit] = useState(20);
    const [values, setValues] = useState([])
    const [mobile, setMobile] = useState(null);

    const { user, token } = isAuthenticated();

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={showLoadingBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const loadFeedbacks = () => {
        listFeedbacks(user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setValues(data);
                setSize(data.length);
            }
        })
    }

    useEffect(() => {
        loadFeedbacks();
    }, [])

    const handleChangePanel = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            let index = parseInt(panel);
            if (values[index].newFeedback) {
                updateFeedback({ ...values[index], newFeedback: false }, user._id, token).then(data => {
                    if (data.error) {
                        console.log(data.error);
                    } else {
                        loadFeedbacks();
                    }
                })
            }
        }
        setExpanded(isExpanded ? panel : false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSkip(0);
        feedbackByMobile(mobile, user._id, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setValues(data);
                setSize(data.length);
            }
        })
    }

    const loadMore = () => {
        setSkip(skip + limit);
    }

    const arStylelabel = {
        display: 'inline-block',
        float: "right",
        fontSize: "16px",
        textAlign: "start"
    }
    const enStylelabel = {
        display: 'inline-block',
        float: "left",
        fontSize: "16px",
        textAlign: "start"
    }

    const feedbackDetailsView = (f, fIndex) => {
        return (
            <div className="footerTagsTextContainer">
                <div>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["name"]}</label>
                    <input disabled type="text" value={f.customerName} className="inputField form-control" id="name" placeholder={dict["name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]}</label>
                    <input disabled type="text" value={f.mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["feedback"]}</label>
                    <input disabled type="text" value={f.feedback} className="inputField form-control" id="feedback" placeholder={dict["feedback"]}></input>
                </div>
            </div>

        );
    }

    const getDate = (date) => {
        var created_date = new Date(date);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        var year = created_date.getFullYear();
        var month = months[created_date.getMonth()];
        var day = created_date.getDate();
        var hour = created_date.getHours();
        var min = created_date.getMinutes();
        var sec = created_date.getSeconds();
        var date = (day + "").padStart(2, '0') + "/" + (month + "").padStart(2, '0') + "/" + year;
        var timein24 = (hour + "").padStart(2, '0') + ':' + (min + "").padStart(2, '0');

        return (date + " - " + tConvert(timein24))
    }

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            time[0] = (time[0] + "").padStart(2, '0');
        }
        return time.join(''); // return adjusted time or original string
    }

    const feedbacksView = (f, fIndex) => {
        return (
            <ExpansionPanel
                expanded={expanded === fIndex}
                onChange={handleChangePanel(fIndex)}
            >

                <ExpansionPanelSummary
                    className={classes.panelHeader}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >

                    <Typography className={classes.secondaryHeading} style={{ textAlign: "start", width: "30%" }}>
                        <div>
                            <span>
                                {dict['name'] + ": " + (f.customerName)}
                            </span>
                            {f.newFeedback && <Badge badgeContent="new" style={{ marginInlineStart: "40px", marginTop: "0px" }} />}
                        </div>

                    </Typography>
                    <Typography className={classes.secondaryHeading} dir="ltr" style={{ width: "30%" }}>{getDate(f.createdAt)}</Typography>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                    {feedbackDetailsView(f, fIndex)}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }


    const loadMoreBtn = () => {
        return (
            size > 0 && size - skip > limit && (
                <button onClick={loadMore} type="submit" className="dashboardBtn" style={{ marginBottom: "5px" }}>{dict["show_more"]}</button>
            )
        )
    }

    const sliceArray = (arr) => {
        return arr.slice(0, skip + limit);
    }

    const form = () => {
        return (
            <Fragment>
                {showBackdrop()}
                <div ref={ref} className="userProfileDataContainer" style={{ position: "relative" }}>
                <form onSubmit={handleSubmit} className={classes3.root} noValidate autoComplete="off" style={{ position: "absolute", left: default_url === "/en/" ? "" : "15px", right: default_url === "/en/" ? "15px" : "", top: "0px" }}>
                    <TextField value={mobile} id="standard-basic" label="Mobile" variant="outlined" style={{ margin: "15px 0", direction: "ltr" }} onChange={(event) => { setMobile(event.target.value) }} />
                </form>
                    <div style={{ marginTop: "35px" }}>
                        <h3 className="formContainerTitle">{dict["feedbacks"]}</h3>
                        <div>
                            {
                                sliceArray(values).map((v, vIndex) => {
                                    return feedbacksView(v, vIndex);
                                })
                            }
                        </div>
                        {loadMoreBtn()}
                    </div>
                </div>
            </Fragment>

        )
    }

    return (
        <AdminDashboard
            active="14"
            data={form}
            categories={[]}
            subcategories={[]}
        />
    )
}

export default FeedbackAdmin;