import { API } from "../config.js"
import Wholesale from "./Wholesale.jsx";

export const listCities = () => {
    return fetch(`${API}/cities`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getCity = (cityId) => {
    return fetch(`${API}/city/${cityId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listAreas = () => {
    return fetch(`${API}/areas`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getArea = (areaId) => {
    return fetch(`${API}/area/${areaId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getCategory = (categoryId) => {
    return fetch(`${API}/category/${categoryId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listCategories = () => {
    return fetch(`${API}/categories`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const countOfCategories = () => {
    return fetch(`${API}/categories/count`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listSubcategories = () => {
    return fetch(`${API}/subcategories`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getSubcategory = (subcategoryId) => {
    return fetch(`${API}/subcategory/${subcategoryId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const countOfSubcategories = (field, value) => {
    return fetch(`${API}/subcategories/count`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            field: field,
            value: value
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listSubcategoriesByCategory = (category) => {
    return fetch(`${API}/subcategories/list-by-category`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ category: category })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listSubcategoriesByCategory2 = (category) => {
    return fetch(`${API}/subcategories/list-by-category2`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ category: category })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listProducts = () => {
    return fetch(`${API}/products`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listProductsByFilters = (skip, limit, filters = undefined) => {
    let data = {
        skip,
        limit,
        filters
    }
    return fetch(`${API}/products/by/search`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const productsCount = ((filters = {}) => {
    let data = {
        filters: filters
    }
    return fetch(`${API}/products/count`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
});

export const getProduct = (productId) => {
    return fetch(`${API}/product/${productId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getCategoryDetails = (field, value) => {
    return fetch(`${API}/category/get/id`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            field: field,
            value: value
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getSubcategoryDetails = (field, value) => {
    return fetch(`${API}/subcategory/get/id`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            field: field,
            value: value
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createOrder = (order, userId = null, token = null, lang, fbp, fbc) => {
    return fetch(`${API}/order/create` + (userId ? `/${userId}` : ""), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": token ? `Bearer ${token}` : null
        }, credentials: 'include',
        body: JSON.stringify({ order: order, lang: lang, fbp: fbp, fbc: fbc })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const orderUpdate = (orderNumber, status, paymentId, paymentStatus, lang) => {
    return fetch(`${API}/order`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ orderNumber: orderNumber, status: status, paymentId: paymentId, paymentStatus: paymentStatus, lang: lang })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getOrder = (field, value) => {
    return fetch(`${API}/order/get/`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            field: field,
            value: value
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getOrderWithUser = (field, value) => {
    return fetch(`${API}/order/get/with-user`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            field: field,
            value: value
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateUserHistory = (userId, token, orderNumber) => {
    return fetch(`${API}/user/update/history/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({ orderNumber: orderNumber })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateUserCart = (userId, token, cart) => {
    return fetch(`${API}/user/update/cart/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({ cart: cart })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getUserCart = (userId, token) => {
    return fetch(`${API}/user/cart/${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const updateUserWishlist = (userId, token, wishlist) => {
    return fetch(`${API}/user/update/wishlist/${userId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify({ wishlist: wishlist })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getUserWishlist = (userId, token) => {
    return fetch(`${API}/user/wishlist/${userId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }, credentials: 'include',
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const decreaseQuantity = (products) => {
    return fetch(`${API}/products/decrease-quantity`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ products: products })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listNewArrivals = () => {
    return fetch(`${API}/new-arrivals`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listOffers = () => {
    return fetch(`${API}/offers`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listAds = () => {
    return fetch(`${API}/ads`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listCoupons = () => {
    return fetch(`${API}/coupons`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}



export const getCoupon = (couponId) => {
    return fetch(`${API}/coupon/${couponId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getOrders = (orders) => {
    return fetch(`${API}/orders/get`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orders)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getReviews = (product) => {
    return fetch(`${API}/reviews/by-product/${product}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getGeneral = (field, value) => {
    return fetch(`${API}/general/get-by-name`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            field: field,
            value: value
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getCouponByCode = (code) => {
    return fetch(`${API}/coupon/get-by-code`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const searchProducts = (search, skip, limit) => {
    return fetch(`${API}/products/search`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            search: search,
            skip: skip,
            limit: limit
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const countSearchedProducts = (search) => {
    return fetch(`${API}/products/search/count`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(search)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const createPenddingOrder = (order) => {
    return fetch(`${API}/pendding-order/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getPenddingOrder = (id) => {
    return fetch(`${API}/pendding-order/${id}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deletePenddingOrder = (id) => {
    return fetch(`${API}/pendding-order/${id}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getQuantityFromProducts = (id) => {
    return fetch(`${API}/product/quantity/${id}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const checkFreeItemForProduct = (id) => {
    return fetch(`${API}/product/free-item/${id}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getQuantityFromPenddingOrders = (id) => {
    return fetch(`${API}/pendding-orders/product-quantity`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const subscribeNewsletter = (email) => {
    return fetch(`${API}/newsletter/subscribe`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getGiftByAmount = (amount) => {
    return fetch(`${API}/gifts/by/amount`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const decreaseGiftQuantity = (giftId) => {
    return fetch(`${API}/gifts/decrease-quantity/${giftId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getList = (listId) => {
    return fetch(`${API}/list/${listId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listLists = () => {
    return fetch(`${API}/lists`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const findListsByIds = (ids) => {
    return fetch(`${API}/lists/find-by-ids`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(ids)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createWholesale = (wholesale) => {
    return fetch(`${API}/wholesale/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(wholesale)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const createFeedback = (feedback) => {
    return fetch(`${API}/feedback/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(feedback)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listDistributors = () => {
    return fetch(`${API}/distributors`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getDistributor = (distributorsId) => {
    return fetch(`${API}/distributor/${distributorsId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listBlog = () => {
    return fetch(`${API}/blog`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getBlog = (blogId) => {
    return fetch(`${API}/blog/${blogId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listColors = () => {
    return fetch(`${API}/colors`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getColor = (colorId) => {
    return fetch(`${API}/color/${colorId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listCollections = () => {
    return fetch(`${API}/collections`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}


export const getCollection = (collectionId) => {
    return fetch(`${API}/collection/${collectionId}`, {
        method: "GET",
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listProductsForCollections = (category, model, tags) => {
    return fetch(`${API}/products/list-for-collections`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            category: category,
            model: model,
            tags: tags
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const createPoint = (point, userId, token) => {
    return fetch(`${API}/point/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },credentials: 'include',
        body: JSON.stringify(point)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const pageView = (fbp, fbc, url, phone) => {
    return fetch(`${API}/facebook-event/pageview`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({fbp: fbp, fbc: fbc, url: url, phone: phone})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const prepareCheckout = (userId, tot) => {
    return fetch(`${API}/check-out/prepare`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            userId: userId,
            tot: tot
        })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listFreeItems = (parentId) => {
    return fetch(`${API}/free-items`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({parentId})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listFreeItemsById = (ids) => {
    return fetch(`${API}/free-items-by-ids`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ids})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const listFreeItemsByParents = (parents) => {
    return fetch(`${API}/free-items-by-parents`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({parents})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const addToCart = (data) => {
    return fetch(`${API}/facebook-event/add-to-cart`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({...data})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const initiateCheckout = (data) => {
    return fetch(`${API}/facebook-event/initiate-checkout`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({...data})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}