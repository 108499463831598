export const addItemToCompare = (item, link) => {
    let compare = [];
    if (typeof window !== "undefined") {
        if (localStorage.getItem('compare')) {
            compare = JSON.parse(localStorage.getItem("compare"));
        }
        if (compare.length === 2) {
            return {
                error: "can't_add_more_than_2_products_to_the_compare_list"
            }
        }
        if(compare.length === 1 && compare[0].category._id != item.category._id){
            return {
                error: "can't_add_to_products_from_different_categories_to_the_compare_list"
            }
        }
        if (compare.length > 0) {
            for (let i = 0; i < compare.length; i++) {
                if(compare[i]._id === item._id){
                    return {
                        error: "this_product_is_already_in_the_compare_list"
                    }
                }
            }
        }
        compare.push({
            ...item,
            link
        });
        localStorage.setItem('compare', JSON.stringify(compare));
        return{
            success: "the_product_has_been_added_to_the_compare_list"
        }
    }
}

export const getCompare = () => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem('compare')) {
            return JSON.parse(localStorage.getItem('compare'));
        }
    }
    return [];
}

export const clearCompareList = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('compare');
    }
}


export const numOfCompareItems = () => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem('compare')) {
            return JSON.parse(localStorage.getItem('compare')).length;
        }
    }
    return 0;
}