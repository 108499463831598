import React, { useState, useEffect, Fragment } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AdminDashboard from "../../AdminDashboard";
import { isAuthenticated } from "../../../../auth"
import { updateSubcategoryPosition } from "../../apiAdmin";
import { listCategories, listSubcategories } from "../../../apiCore";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ControlledExpansionPanels from "./SubcategoryExpansionPanel"
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { from } from "form-data";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import { set } from "js-cookie";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
    ExpansionPanelDetails: {
        display: "inline",
        padding: "0"
    },
    panelHeader: {
        backgroundColor: "#f7f7f7"
    }
}));

let count = 0;
let count2 = 0;

const Subcategories = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null)
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([{}]);
    const [sorting, setSorting] = useState({
        active: false,
        error: "",
        success: false
    });
    const [arr, setArr] = useState([{}]);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: ""
    });
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleChangePanel = (panel, category) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (isExpanded) {
            setSelectedCategory(category);
        } else {
            setSelectedCategory(null);
        }
    };
    const handleChange = (event) => {
        if (!sorting.active) {
            setArr(data);
        }
        setSorting({ ...sorting, active: !sorting.active });
    };
    const handleClose = () => {
        setShow(false);
        // setDeletedItem({name: "", id: ""})
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        let pos = -1;
        let category = "";
        for (let i = 0; i < data.length; i++) {
            if (deletedItem.id === data[i]._id) {
                pos = data[i].position;
                category = data[i].category;
                break;
            }
        }
        if (pos < data.length) {
            updateSubcategoryPosition(pos, 100000000, deletedItem.id, category, user._id, token);
        }

        // let tmp = [...data]
        // for (let i = 0; i < data.length; i++) {
        //     if (pos < data[i].position) {
        //         tmp[i].position -= 1;
        //     }
        // }
        // for (let i = 0; i < tmp.length; i++) {
        //     const { _id, position } = tmp[i];
        //     updateSubcategory({ _id, position }, user._id, token)
        //         .then(res => {
        //             if (res.error) {
        //                 setSorting({ active: false, error: data.error, success: false });
        //             } else {
        //                 setSorting({ active: false, error: "", success: true });
        //             }
        //         })
        // }
        handleClose();
        window.location.href = (default_url + "admin/subcategories/delete?subcategory_id=" + deletedItem.id)
    }

    useEffect(() => {
        async function fetchData() {
            const subcategories = await listSubcategories()
                .then(res => {
                    setData(res);
                })
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const categories = await listCategories()
                .then(res => {
                    setCategories(res);
                })
        }
        fetchData();
    }, []);

    const { user, token } = isAuthenticated();

    const clickSubmit = (event) => {
        event.preventDefault();
        window.location.href = default_url + "admin/subcategories/create"
    }

    // const clickSort = (event) => {
    //     event && event.preventDefault();
    //     if (sorting.active) {
    //         for (let i = 0; i < arr.length; i++) {
    //             const { _id, position } = arr[i];
    //             updateCategoryPosition({ _id, position }, user._id, token)
    //                 .then(res => {
    //                     if (res.error) {
    //                         setSorting({ active: false, error: data.error, success: false });
    //                     } else {
    //                         setSorting({ active: false, error: "", success: true });
    //                     }
    //                 })
    //         }
    //     } else {
    //         setArr(data)
    //     }
    //     setSorting({ ...sorting, active: !sorting.active });
    // }

    const showAlert = () => {
        if ((props.location.state || sorting.success || sorting.error)) {
            let msg = ""
            if (sorting.error) {
                msg = sorting.error;
            } else if (sorting.success) {
                msg = dict["subcategories_have_been_sorted_successfully"];
            } else if (props.location.state) {
                switch (props.location.state.prevPath) {
                    case "create": msg = props.location.state.error ? props.location.state.error : dict["new_subcategory_has_been_created"]; break;
                    case "delete": msg = props.location.state.error ? props.location.state.error : dict["subcategory_has_been_deleted"]; break;
                    case "edit": msg = props.location.state.error ? props.location.state.error : dict["subcategory_has_been_updated"]; break;
                    default: msg = "";
                }
            }
            return (
                <div
                    className={"alert " + (((props.location.state && props.location.state.error) || sorting.error) ? "alert-danger" : "alert-info") + " alert-dismissible fade show"}
                    role="alert"
                    style={{ textAlign: "start" }}
                >
                    <h2>{msg}</h2>
                    <button type="button" class="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_subcategory"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + " subcategory?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد حذف القسم الفرعي " + deletedItem.name + "؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    // const allSubcategories = () => {
    //     return (
    //         !sorting.active &&
    //         data.sort((a, b) => a.position - b.position).map((item, index) => {
    //             return (
    //                 <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
    //                     <span className="dataContainerNumber">{"#" + item.position}</span>
    //                     <span className="dataContainerTitle">{item.name}</span>
    //                     <a className="dataContainerAction"
    //                         href="#"
    //                         onClick={handleShow}
    //                         name={item.name}
    //                         id={item._id}
    //                     >Delete</a>
    //                     {popUp()}
    //                     <a className="dataContainerAction" href={"/admin/subcategories/edit?subcategory_id=" + item._id}>Edit</a>
    //                 </div>
    //             )
    //         })
    //     )
    // }

    const allSubcategories = () => {
        return (
            (!sorting.active || !selectedCategory) &&
            <div className={classes.root}>
                {
                    categories.sort((a, b) => a.position - b.position).map((item, index) => {
                        count = data.filter(subcategory => {
                            return subcategory.category == item._id
                        }).length;
                        count2 = data.filter(subcategory => {
                            return subcategory.category == null
                        }).length;
                        return (
                            <ExpansionPanel
                                key={index}
                                expanded={expanded === ("panel" + (index + 1))}
                                onChange={handleChangePanel(("panel" + (index + 1)), item._id)}
                            >
                                <ExpansionPanelSummary
                                    className={classes.panelHeader}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading} style={{ textAlign: "start" }}>{item.enName + " | " + item.arName}</Typography>
                                    {
                                        default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count + " Subcategories"}</Typography>
                                    }
                                    {
                                        default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count + " اقسام فرعية"}</Typography>
                                    }
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                                    {
                                        data.sort((a, b) => a.position - b.position).map((item2, index2) => {
                                            if (item2.category == item._id) {
                                                return (
                                                    <div key={index2} className={"dataContainer " + (index2 === data.length - 1 ? "lastDataContainer" : "")}>
                                                        <span className="dataContainerNumber" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.position}</span>
                                                        <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.enName} {item2.arName && " | " + item2.arName}</span>
                                                        <a className="dataContainerAction"
                                                            href="#"
                                                            onClick={handleShow}
                                                            name={item2.enName + (item2.arName != null ? " | " + item2.arName : "")}
                                                            id={item2._id}
                                                            style={{ float: default_url == "/ar/" ? "left" : "right" }}
                                                        >{dict["delete"]}</a>
                                                        {popUp()}
                                                        <a className="dataContainerAction" href={default_url + "admin/subcategories/edit?subcategory_id=" + item2._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>{dict["edit"]}</a>
                                                        <br></br>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                }
                <ExpansionPanel
                    expanded={expanded === ("panel" + (categories.length + 1))}
                    onChange={handleChangePanel(("panel" + (categories.length + 1)))}
                >
                    <ExpansionPanelSummary
                        className={classes.panelHeader}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"panel" + (categories.length + 1) + "bh-content"}
                        id={"panel" + (categories.length + 1) + "bh-header"}
                    >
                        <Typography className={classes.heading} style={{ textAlign: "start" }}>Others | اخرى</Typography>
                        {
                            default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count2 + " Subcategories"}</Typography>
                        }
                        {
                            default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count2 + " اقسام فرعية"}</Typography>
                        }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                        {
                            data.sort((a, b) => a.position - b.position).map((item2, index2) => {
                                if (item2.category == null) {
                                    return (
                                        <div key={index2} className={"dataContainer " + (index2 === data.length - 1 ? "lastDataContainer" : "")}>
                                            <span className="dataContainerNumber" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.position}</span>
                                            <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.enName} {item2.arName && " | " + item2.arName}</span>
                                            <a className="dataContainerAction"
                                                href="#"
                                                onClick={handleShow}
                                                name={item2.enName}
                                                id={item2._id}
                                                style={{ float: default_url == "/ar/" ? "left" : "right" }}
                                            >Delete</a>
                                            {popUp()}
                                            <a className="dataContainerAction" href={default_url + "admin/subcategories/edit?subcategory_id=" + item2._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>Edit</a>
                                            <br></br>
                                        </div>
                                    )
                                }
                            })
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }

    const sortableMenu = () => {
        return (
            <div className={classes.root}>
                {
                    categories.sort((a, b) => a.position - b.position).map((item, index) => {
                        count = data.filter(subcategory => {
                            return subcategory.category == item._id
                        }).length;
                        count2 = data.filter(subcategory => {
                            return subcategory.category == null
                        }).length;
                        return (
                            <ExpansionPanel
                                key={index}
                                expanded={expanded === ("panel" + (index + 1))}
                                onChange={handleChangePanel(("panel" + (index + 1)), item._id)}
                            >
                                <ExpansionPanelSummary
                                    className={classes.panelHeader}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading} style={{ textAlign: "start" }}>{item.enName + " | " + item.arName}</Typography>
                                    {
                                        default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count + " Subcategories"}</Typography>
                                    }
                                    {
                                        default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count + " اقسام فرعية"}</Typography>
                                    }
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                                    <SortableList
                                        items={data.filter((item) => {
                                            return item.category == selectedCategory
                                        })}
                                        onSortEnd={onSortEnd}
                                        axis="y"
                                    />
                                    {/* {
                                        data.sort((a, b) => a.position - b.position).map((item2, index2) => {
                                            if (item2.category == item._id) {
                                                return (
                                                    <div key={index2} className={"dataContainer " + (index2 === data.length - 1 ? "lastDataContainer" : "")}>
                                                        <span className="dataContainerNumber" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.position}</span>
                                                        <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.enName} {item2.arName && " | " + item2.arName}</span>
                                                        <a className="dataContainerAction"
                                                            href="#"
                                                            onClick={handleShow}
                                                            name={item2.enName + (item2.arName != null ? " | " + item2.arName : "")}
                                                            id={item2._id}
                                                            style={{ float: default_url == "/ar/" ? "left" : "right" }}
                                                        >{dict["delete"]}</a>
                                                        {popUp()}
                                                        <a className="dataContainerAction" href={default_url + "admin/subcategories/edit?subcategory_id=" + item2._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>{dict["edit"]}</a>
                                                        <br></br>
                                                    </div>
                                                )
                                            }
                                        })
                                    } */}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                }
                <ExpansionPanel
                    expanded={expanded === ("panel" + (categories.length + 1))}
                    onChange={handleChangePanel(("panel" + (categories.length + 1)))}
                >
                    <ExpansionPanelSummary
                        className={classes.panelHeader}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"panel" + (categories.length + 1) + "bh-content"}
                        id={"panel" + (categories.length + 1) + "bh-header"}
                    >
                        <Typography className={classes.heading} style={{ textAlign: "start" }}>Others | اخرى</Typography>
                        {
                            default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count2 + " Subcategories"}</Typography>
                        }
                        {
                            default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count2 + " اقسام فرعية"}</Typography>
                        }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                        {
                            data.sort((a, b) => a.position - b.position).map((item2, index2) => {
                                if (item2.category == null) {
                                    return (
                                        <div key={index2} className={"dataContainer " + (index2 === data.length - 1 ? "lastDataContainer" : "")}>
                                            <span className="dataContainerNumber" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.position}</span>
                                            <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{item2.enName} {item2.arName && " | " + item2.arName}</span>
                                            <a className="dataContainerAction"
                                                href="#"
                                                onClick={handleShow}
                                                name={item2.enName}
                                                id={item2._id}
                                                style={{ float: default_url == "/ar/" ? "left" : "right" }}
                                            >Delete</a>
                                            {popUp()}
                                            <a className="dataContainerAction" href={default_url + "admin/subcategories/edit?subcategory_id=" + item2._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>Edit</a>
                                            <br></br>
                                        </div>
                                    )
                                }
                            })
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>

        )
    }

    const SortableItem = SortableElement(({ value, index }) => {
        return (
            <div key={value._id} index={index} className={"dataContainer " + (value.position === data.length ? "lastDataContainer" : "")}>
                <span className="dataContainerNumber" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{value.position}</span>
                <span className="dataContainerTitle" style={{ float: default_url == "/ar/" ? "right" : "left" }}>{value.enName} {value.arName && " | " + value.arName}</span>
                <a className="dataContainerAction" href={"/admin/subcategories/delete?subcategory_id=" + value._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>{dict["delete"]}</a>
                <a className="dataContainerAction" href={"/admin/subcategories/edit?subcategory_id=" + value._id} style={{ float: default_url == "/ar/" ? "left" : "right" }}>{dict["edit"]}</a>
            </div>
        )
    })

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className={classes.root}>
                {
                    categories.sort((a, b) => a.position - b.position).map((item, index) => {
                        count = data.filter(subcategory => {
                            return subcategory.category == item._id
                        }).length
                        count2 = data.filter(subcategory => {
                            return subcategory.category == null
                        }).length
                        return (
                            <ExpansionPanel

                                expanded={expanded === ("panel" + (index + 1))}
                                onChange={handleChangePanel(("panel" + (index + 1)), item._id)}
                            >
                                <ExpansionPanelSummary
                                    className={classes.panelHeader}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading} style={{ textAlign: "start" }}>{item.enName + " | " + item.arName}</Typography>
                                    {
                                        default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count + " Subcategories"}</Typography>
                                    }
                                    {
                                        default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count + " اقسام فرعية"}</Typography>
                                    }
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                                    {
                                        items.sort((a, b) => a.position - b.position).map((item2, index2) => {
                                            if (item2.category == item._id) {
                                                return (
                                                    <SortableItem
                                                        value={item2}
                                                        index={index2}
                                                        key={item2._id}
                                                    />
                                                )
                                            }
                                        })
                                    }
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                }
                <ExpansionPanel
                    expanded={expanded === ("panel" + (categories.length + 1))}
                    onChange={handleChangePanel(("panel" + (categories.length + 1)))}
                >
                    <ExpansionPanelSummary
                        className={classes.panelHeader}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"panel" + (categories.length + 1) + "bh-content"}
                        id={"panel" + (categories.length + 1) + "bh-header"}
                    >
                        <Typography className={classes.heading} style={{ textAlign: "start" }}>{"Others | اخرى"}</Typography>
                        {
                            default_url == "/en/" && <Typography className={classes.secondaryHeading}>{"Has " + count2 + " Subcategories"}</Typography>
                        }
                        {
                            default_url == "/ar/" && <Typography className={classes.secondaryHeading}>{"لديه " + count2 + " اقسام فرعية"}</Typography>
                        }
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails}>
                        {
                            items.sort((a, b) => a.position - b.position).map((item2, index2) => {
                                if (item2.category == null) {
                                    return (
                                        <SortableItem
                                            value={item2}
                                            index={index2}
                                            key={item2._id}
                                        />
                                    )
                                }
                            })
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    })

    const onSortEnd = ({ oldIndex, newIndex }) => {
        console.log(oldIndex);
        console.log(newIndex);
        if (oldIndex == newIndex) {
            return;
        }
        var id = "";
        var category = "";
        let tmpData = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].category == selectedCategory) {
                tmpData.push(data[i]);
            }
        }
        console.log(tmpData);   
        for (let i = 0; i < tmpData.length; i++) {
            if (tmpData[i].position == oldIndex + 1) {
                id = tmpData[i]._id;
                category = tmpData[i].category;
                break;
            }
        }
        updateSubcategoryPosition(oldIndex + 1, newIndex + 1, id, category, user._id, token);
        let tmp = arrayMove(tmpData, oldIndex, newIndex);
        for (let i = 0; i < tmp.length; i++) {
            tmp[i].position = i + 1;
        }
        let tmp2 = [];
        for (let i = 0; i < arr.length; i++) {
            let found = false;
            for (let j = 0; j < tmp.length; j++) {
                if(tmpData[j]._id == arr[i]._id){
                    found = true;
                    tmp2.push(tmp[j]);
                    break;
                }
            }    
            if(!found){
                tmp2.push(arr[i])
            }
        }
        setArr(tmp2);
        setData(tmp2)
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["subcategories_list"]}</h3>
                <FormControlLabel
                    value="start"
                    control={
                        <Switch
                            checked={sorting.active}
                            onChange={handleChange}
                            color="primary"
                            className="switch"
                        />
                    }
                    label={dict["sort"]}
                    labelPlacement="start"
                    className="dashboardSortingSwitch"
                    style={{ float: default_url == "/en/" ? "right" : "left" }}
                />
                {/* <button onClick={clickSort} type="submit" className="dashboardSortingBtn">{sorting.active ? "Save sort" : "Sort"}</button> */}
                {allSubcategories()}
                {
                    sorting.active && selectedCategory &&
                    <SortableList
                        items={arr.filter((item) => {
                            return item.category == selectedCategory
                        })}
                        onSortEnd={onSortEnd}
                        axis="y"
                    />
                }
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_subcategory"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="3"
            data={form}
        />
    )
}

export default Subcategories;