import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { searchProducts, countSearchedProducts, listColors } from "./apiCore";
import ProductFilter from "./product components/Filter/ProductsFilter";
import ProductsContainer from "./product components/product list/ProductsContainer";
import ProductsMenu from "./product components/product list/ProductsMenu";
import SortBy from "./product components/product list/SortBy";
import ProductsNavLink from "./product components/ProductsNavLink";
import { dict } from "../dictionary";
import { default_url } from "..";
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles2 = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function SearchProducts(props) {
    const classes2 = useStyles2();
    const [allProducts, setAllProducts] = useState([]);
    const [products1, setProducts1] = useState([]);
    const [products3, setProducts3] = useState([]);
    const [allColors3, setAllColors3] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(32);
    const [neededPages, setNeededPages] = useState([1]);
    const [showLoadingBackdrop, setShowBackdrop] = useState(false);



    const params = new URLSearchParams(window.location.search);
    let q = params.get('q');
    let page = params.get('page') || 1;
    let price = params.get('price')
    let prices = []
    if (price) {
        prices = price.split("-");
    }
    let size = params.get('size')
    let sizes = []
    if (size) {
        sizes = size.split(",");
    }
    let color = params.get('color')
    let colors = []
    if (color) {
        colors = color.split(",");
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes2.backdrop} open={showLoadingBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const loadProducts = () => {
        setShowBackdrop(true);
        searchProducts(q, skip, 3000).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                //setProducts(res.data);

                listColors().then(colorsRes => {
                    setShowBackdrop(false);
                    if (colorsRes.error) {
                        
                    } else {
                        let set = new Set();
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].color) {
                                set.add(res.data[i].color);
                            }
                        }
                        let tmpColors = Array.from(set).sort((a, b) => {
                            return a - b;
                        });
                        let tmpColors2 = [];
                        for (let i = 0; i < colorsRes.length; i++) {
                            for (let j = 0; j < tmpColors.length; j++) {
                                if (colorsRes[i].number == tmpColors[j]) {
                                    tmpColors2.push({ ...colorsRes[i] })
                                }
                            }
                        }
                        setAllColors3(tmpColors2);
                        let arr = [];
                        let found = false
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].color && colors.length > 0 && !colors.includes(res.data[i].color)) {
                                continue;
                            }
                            found = false;
                            for (let j = 0; j < arr.length; j++) {
                                if (res.data[i].enTitle == arr[j].enTitle) {
                                    if ((!sizes || sizes.length == 0) || (res.data[i].size && sizes && sizes.includes(res.data[i].size))) {
                                        arr[j]._id.push(res.data[i]._id);
                                        arr[j].quantity.push(res.data[i].quantity);
                                        arr[j].price.push(res.data[i].price);
                                        arr[j].discountPrice.push(res.data[i].discountPrice);
                                        arr[j].size.push(res.data[i].size);
                                        arr[j].images.push([]);
                                        for (let k = 0; k < res.data[i].images.length; k++) {
                                            arr[j].images[arr[j].images.length - 1].push(res.data[i].images[k]);
                                        }
                                    }
                                    found = true
                                    break;
                                }
                            }
                            if (!found) {
                                if ((!sizes || sizes.length == 0) || (res.data[i].size && sizes && sizes.includes(res.data[i].size))) {
                                    arr.push({ ...res.data[i], _id: [res.data[i]._id], quantity: [res.data[i].quantity], price: [res.data[i].price], discountPrice: [res.data[i].discountPrice], size: res.data[i].size ? [res.data[i].size] : [], images: [[]] });
                                    for (let m = 0; m < res.data[i].images.length; m++) {
                                        arr[arr.length - 1].images[0].push(res.data[i].images[m]);
                                    }
                                }
                            }
                        }
                        let arr2 = [];
                        for (let i = 0; i < arr.length; i++) {
                            arr2.push({ ...arr[i] });
                            let at = arr2.length - 1;
                            let sizes = [...arr2[at].size];
                            let newIds = [...arr2[at]._id]
                            let newQuantity = [...arr2[at].quantity]
                            let newSizes = [...arr2[at].size]
                            let newPrices = [...arr2[at].price]
                            let newDiscountPrices = [...arr2[at].discountPrice]
                            let newImages = [...arr2[at].images]
                            for (let j = 0; j < sizes.length; j++) {
                                let cnt = 0;
                                for (let k = 0; k < sizes.length; k++) {
                                    if (j == k) continue;
                                    if (sizes[j] < sizes[k]) cnt += 1;
                                }
                                newIds[(sizes.length - cnt - 1)] = arr2[at]._id[j];
                                newSizes[(sizes.length - cnt - 1)] = sizes[j];
                                newQuantity[(sizes.length - cnt - 1)] = arr2[at].quantity[j];
                                newPrices[(sizes.length - cnt - 1)] = arr2[at].price[j];
                                newDiscountPrices[(sizes.length - cnt - 1)] = arr2[at].discountPrice[j];
                                newImages[(sizes.length - cnt - 1)] = arr2[at].images[j];

                            }
                            arr2[at]._id = [...newIds];
                            arr2[at].quantity = [...newQuantity];
                            arr2[at].size = [...newSizes];
                            arr2[at].price = [...newPrices];
                            arr2[at].discountPrice = [...newDiscountPrices];
                            arr2[at].images = [...newImages];
                        }
                        setProducts1(res.data);
                        setProducts3(arr2);
                        setAllProducts(res.data);
                    }
                })
            }
        })
    }

    const getTotal = () => {
        countSearchedProducts(q).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                let needed = 0;
            }
        })
    }

    useEffect(() => {
        loadProducts();
    }, []);

    const handleNeededPages = (needed) => {
        setNeededPages(needed);
    }

    const changePage = (page) => {
        let search = props.location.search.split("&");
        let res = "";
        let done = false;
        for (let i = 0; i < search.length; i++) {
            if (search[i].includes("page=")) {
                if (i == 0) {
                    res += "?"
                }
                res += ("page=" + page + "&");
                done = true;
            } else {
                res += search[i] + "&";
            }
        }
        if (!done) {
            res += ("page=" + page + "&");
        }
        //setSkip(limit*(page-1));
        return (res.substring(0, res.length - 1));
    }

    return (
        <div>
        {showBackdrop()}
            <div className="productsNavLinkContainer">
                {
                    <ProductsNavLink
                        title={dict["home"]}
                        slash="/"
                        link={default_url}
                    />
                }
                {
                    <ProductsNavLink
                        title={dict["search"]}
                    />
                }
            </div>
            <div className="productsMegaCountainer row" style={{ display: "block", marginInlineEnd: "0px !important"}}>
                <ProductFilter
                    {...props}
                    sizes={[]}
                    colors={allColors3}
                />
                <div className="productsMenuContainer col-lg-10 col-md-12 row"style={{ display: "inline-block", marginBottom: "40px" }}>
                    <div className={"productsContainer " + props.class}>
                        <h5 className="productsContainerTitle" style={{ textAlign: "start" }}>{props.title}</h5>
                        <SortBy
                            sizes={[]}
                            colors={allColors3}
                            {...props}  
                        />
                        <ProductsMenu
                            divClass="col-lg-3 col-6"
                            items={products1}
                            items3={products3}
                            search={true}
                        />
                    </div>
                    {/* <div className="pageNumber">
                        {
                            neededPages.length > 1 && neededPages.map((p, i) => {
                                return <a key={i} href={props.location.search === "" ? "?page=" + p : changePage(p)} className={"pageCircle " + (page == p && "activePage")}>{p}</a>
                            })
                        }
                    </div> */}

                </div>

            </div>

        </div>

    )
}

export default SearchProducts;