import React, { useState, useEffect, Fragment } from "react";
import UserDashboard from "../UserDashboard"
import { isAuthenticated } from "../../../auth"
import { dict, en_dic, ar_dic, enAreas } from "../../../dictionary";
import { default_url } from "../../..";
import { listAddresses, deleteAddress } from "../apiUser"
import { store } from 'react-notifications-component';

function AddressBook() {

    const [values, setValues] = useState({
        data: [],
        error: "",
        success: false
    });
    const [mouseOverRemove, setMouseOverRemove] = useState(-1);
    const { data, error, success } = values;
    const { user, token } = isAuthenticated();

    const loadAddresses = () => {
        listAddresses(user._id, token).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ ...values, data: res, error: "" });
            }
        })
    }

    const deleteItem = addressId => event => {
        deleteAddress(user._id, token, addressId).then(res => {
            if (res.error) {
                setValues({ ...values, error: res.error, success: false });
            } else {
                setValues({ ...values, error: "", success: true });
                loadAddresses();
            }
        })
    }

    const editAddress = addressId => event => {
        window.location.href = default_url + "user/address-book/edit?address=" + addressId;
    }

    useEffect(() => {
        loadAddresses();
    }, []);

    const handleAddress = ({_id, fName, lName, mobile, enCity, arCity, enArea, arArea, block, street, lane, building, floor, apartment, extra }, i) => {
        const address = (fName + " " + lName + ", " + mobile + "\n" + (default_url === "/en/" ? (enCity + ", " + enArea) : (arCity + ", " + arArea)) + "\n" + dict['block'] + ": " + block + ", " + dict['street'] + ": " + street + ", " + dict['lane'] + ": " + (lane ? lane : dict['don\'t_exist']) + "\n" + dict['building'] + ": " + building + "\n" + dict['floor'] + ": " + (floor ? floor : dict['don\'t_exist']) + ", " + dict['apartment'] + ": " + (apartment ? apartment : dict['don\'t_exist']) + "\n" + (extra ? extra : "")).split("\n");
        return (
            <div style={{ textAlign: "start", padding: "0 10px", position: "relative" }}>
                {
                    address.map((line, i) => {
                        return <h1 key={i} style={{ marginBottom: i != address.length - 1 ? "6px" : "0" }}>{line}</h1>
                    })
                }
                <div
                    style={{ textDecoration: "underline", fontSize: "16px", cursor: mouseOverRemove === (i + "remove")  ? "pointer" : "", color: mouseOverRemove === (i + "remove") ? "#E4BC4B" : "", position:" absolute", top: "65%", transform: "translateY(-50%)", left: default_url === "/ar/" ? "0" : "", right: default_url === "/en/" ? "0" : "" }}
                    onMouseOver={() => setMouseOverRemove(i + "remove")}
                    onMouseOut={() => setMouseOverRemove("-1")}
                    onClick={deleteItem(_id)}
                >
                    {dict["remove"]}
                </div>
                <div
                    style={{ textDecoration: "underline", fontSize: "16px", cursor: mouseOverRemove === (i + "edit")  ? "pointer" : "", color: mouseOverRemove === (i + "edit") ? "#E4BC4B" : "", position:" absolute", top: "35%", transform: "translateY(-50%)", left: default_url === "/ar/" ? "0" : "", right: default_url === "/en/" ? "0" : "" }}
                    onMouseOver={() => setMouseOverRemove(i + "edit")}
                    onMouseOut={() => setMouseOverRemove("-1")}
                    onClick={editAddress(_id)}
                >
                    {dict["edit"]}
                </div>
                <hr />
            </div>
        )
    }

    const handleChange = name => event => {

    };

    const clickSubmit = (event) => {
        event.preventDefault();
        window.location.href = default_url + "user/address-book/add";
    }

    const showAlert = () => {
        if (error) {
            store.addNotification({
                message: dict[error],
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        } else if (success) {
            store.addNotification({
                message: dict["address_has_been_deleted"],
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true,
                    showIcon: true
                }
            });
        }
    }

    const showAddresses = () => {
        return (
            data.map((address, i) => {
                return handleAddress(address, i);
            })
        )
    }

    const noItemsMsg = () => {
        return (
            <h1 style={{textAlign:"start"}}>{dict['no_addresses_found']}</h1>
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict['address_book']}</h3>
                <hr />
                {data.length === 0 && noItemsMsg()}
                {showAddresses()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_address"]}</button>
            </div>
        )
    }

    return (
        <UserDashboard
            active="2"
            data={form}
        />
    )
}

export default AddressBook;