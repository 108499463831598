import React, { useState } from "react";
import { default_url } from "../index"
import { dict } from "../dictionary"
import {createWholesale} from "./apiCore"
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { store } from 'react-notifications-component';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

function Wholesale() {
    const classes = useStyles();
    const [values, setValues] = useState({
        businessName: "",
        ownerName: "",
        businessType: "",
        country: "",
        mobile: "",
        email: "",
        website: "",
        error: null,
        success: false
    });
    const [loading, setLoading] = useState(false);

    const { businessName, ownerName, businessType, country, mobile, email, website, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        createWholesale({ businessName, ownerName, businessType, country, mobile, email, website })
            .then(data => {
                setLoading(false);
                if (data.error) {
                    console.log(data.error);
                    setValues({ ...values, error: data.error, success: false});
                } else {
                    setValues({
                        businessName: "",
                        ownerName: "",
                        businessType: "",
                        country: "",
                        mobile: "",
                        email: "",
                        website: "",
                        error: null,
                        success: true
                    });
                }
            })
    }

    const showError = () => {
        if (!error) {
            return;
        }
        store.addNotification({
            message: error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true,
                showIcon: true
            }
        })
        setValues({ ...values, error: "" });
    }

    const showSuccess = () => {
        if (!success) {
            return;
        }
        store.addNotification({
            message: "تم تقديم الطلب بنجاح، سيتم التواصل معكم في اقرب وقت",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                showIcon: true
            }
        })
        setValues({ ...values, success: false });
    }

    const types = ['اسواق مركزية وشعبية', 'متجر الكتروني', 'مطاعم ومقاهي', 'مشاريع صغيرة', 'اخرى']


    const typeSelector = () => {
        return (
            <select onChange={handleChange("businessType")} name="businessType" className="inputField form-control" value={businessType}>
                <option value=""></option>
                {
                    types.map(t => {
                        return <option value={t}>{t}</option>
                    })
                }
            </select>
        )
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const showWholesaleForm = () => {
        return (
            <form >
                {showError()}
                {showSuccess()}
                <h6 style={{ float: default_url === "/en/" ? "right" : "left", fontSize: "12px", color: "red", marginTop: "-5px" }}>{dict["required"]}</h6>
                <div>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["business_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("businessName")} type="text" value={businessName} className="inputField form-control" id="business_name" placeholder={dict["business_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["owner_name"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("ownerName")} type="text" value={ownerName} className="inputField form-control" id="owner_name" placeholder={dict["owner_name"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["business_type"]} <span className="redStar">*</span></label>
                    {typeSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["country"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("country")} type="text" value={country} className="inputField form-control" id="country" placeholder={dict["country"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["mobile"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("mobile")} type="text" value={mobile} className="inputField form-control" id="mobile" placeholder={dict["mobile"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["email"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("email")} type="text" value={email} className="inputField form-control" id="email" placeholder={dict["email"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["website"]}</label>
                    <input onChange={handleChange("website")} type="text" value={website} className="inputField form-control" id="website" placeholder={dict["website"]}></input>
                    <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["send_request"]}</button>
                </div>
            </form>

        )

    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <div className="container-fluid footerTagsContainer" dir={default_url === "/en/" ? "rtl" : "rtl"} >
            {showBackdrop()}
            <div className="footerTagsTextContainer">
                <h1 style={{ fontSize: "20px", fontWeight: "900", textAlign: 'center' }}>{dict['wholesale']}</h1>
                <br />
                {showWholesaleForm()}
            </div>
        </div>
    )
}

export default Wholesale;