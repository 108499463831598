import React, { useState, useEffect, Fragment } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AdminDashboard from "../../AdminDashboard";
import { isAuthenticated } from "../../../../auth"
import { updateCategoryPosition } from "../../apiAdmin";
import { listCities } from "../../../apiCore";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import { Link, useHistory } from "react-router-dom";


const Cities = (props) => {
    let history = useHistory();
    const [data, setData] = useState([{}]);
    const [sorting, setSorting] = useState({
        active: false,
        error: "",
        success: false
    });
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: ""
    });
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }
    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        window.location.href = (default_url + "admin/cities/delete?city_id=" + deletedItem.id)
    }

    useEffect(() => {
        async function fetchData() {
            const cities = await listCities()
                .then(res => {
                    setData(res);
                })
        }
        fetchData();
    }, []);

    const { user, token } = isAuthenticated();

    const clickSubmit = (event) => {
        event.preventDefault();
        history.push(default_url + "admin/cities/create")
        // window.location.href = default_url + "admin/categories/create"
    }

    const showAlert = () => {
        if (props.location.state) {
            let msg = ""
            switch (props.location.state.prevPath) {
                case "create": msg = props.location.state.error ? props.location.state.error : dict["new_city_has_been_created"]; break;
                case "delete": msg = props.location.state.error ? props.location.state.error : dict["city_has_been_deleted"]; break;
                case "edit": msg = props.location.state.error ? props.location.state.error : dict["city_has_been_updated"]; break;
                default: msg = "";
            }
            return (
                <div
                    className={"alert " + ((props.location.state && props.location.state.error) ? "alert-danger" : "alert-info") + " alert-dismissible fade show"}
                    role="alert"
                >
                    <h2 style={{ textAlign: "start" }}>{msg}</h2>
                    <button type="button" className="close alertCloseBtn" data-dismiss="alert" aria-label="Close" style={{ left: default_url == "/ar/" ? "0" : "auto", right: default_url == "/ar/" ? "auto" : "0" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_city"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + "?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد حذف " + deletedItem.name + "؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const allCities = () => {
        return (
            data && data.length > 0 &&
            data.sort((a, b) => a.arName - b.arName).map((item, index) => {
                return (
                    <div key={index} className={"dataContainer " + (index === data.length - 1 ? "lastDataContainer" : "")}>
                        <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right" }}>{item.enName + " | " + item.arName}</span>
                        <a className="dataContainerAction"
                            href="#"
                            onClick={handleShow}
                            name={item.enName + (item.arName != null ? " | " + item.arName : "")}
                            id={item._id}
                            style={{ float: default_url == "/en/" ? "right" : "left" }}
                        >{dict["delete"]}</a>
                        {popUp()}
                        <Link className="dataContainerAction" to={default_url + "admin/cities/edit?city_id=" + item._id} style={{ float: default_url == "/en/" ? "right" : "left" }}>{dict["edit"]}</Link>
                    </div>
                )
            })
        )
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showAlert()}
                <h3 className="formContainerTitle">{dict["cities_list"]}</h3>
                {allCities()}
                <button onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["add_new_city"]}</button>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="222"
            data={form}
            categories={data}
            subcategories={[]}
        />
    )
}

export default Cities;