import { API } from "../config.js"
import { isValidToken, setSession } from '../utils/jwt';

export const signup = (user) => {
    return fetch(`${API}/signup`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }, credentials: 'include',
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const signin = (user) => {
    return fetch(`${API}/signin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }, credentials: 'include',
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const authenticate = (data, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem('jwt', JSON.stringify(data));
        setSession(data.token);
        next();
    }
}

export const singout = (next) => {
    if (typeof window !== "undefined") {
        localStorage.removeItem('jwt');
        setSession(null);
        next();
        return fetch(`${API}/signout`, {
            method: "GET",
            credentials: 'include',
        })
            .then(response => {
                console.log("signout", response);
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export const isAuthenticated = () => {
    if (typeof window === undefined) {
        return false;
    }
    if (localStorage.getItem('accessToken')) {
        if (isValidToken(localStorage.getItem("accessToken"))) {
            return JSON.parse(localStorage.getItem("jwt"));
        }
        return false;
    } else {
        return false;
    }
}

// const isValidToken = (accessToken) => {
//     if (!accessToken) {
//         return false;
//     }
//     const decoded = jwtDecode(accessToken);
//     const currentTime = Date.now() / 1000;

//     return decoded.exp > currentTime;
// };

