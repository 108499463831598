import React from 'react'
import { dict } from '../../../dictionary';

function ProductViewPrice(props){
    const fixLength = (num) => {
        return (Math.round((num) * 1000) / 1000).toFixed(3)
    }
    return(
        <div style={{marginTop: "10px", textAlign:"start"}}>
            <span className="productViewPrice" style={{textDecoration:props.haveDiscount && "line-through"}}>{fixLength(props.price) + " " + dict["kd"]}</span>
            {props.haveDiscount && <span className="productViewDiscountPrice">{fixLength(props.discountPrice) + " " + dict["kd"]}</span>}
        </div>
    )
}

export default ProductViewPrice;