import React, { useState, useEffect } from "react";
import AdminDashboard from "../../AdminDashboard"
import { isAuthenticated } from "../../../../auth"
import { updateList, replaceAllProductsInList } from "../../apiAdmin"
import { getList, listProductsByFilters, listCategories, listSubcategoriesByCategory } from "../../../apiCore";
import { default_url } from "../../../../index"
import { dict } from "../../../../dictionary";
import * as qs from 'query-string';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ListCheckBox from "./ListCheckBox";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { store } from 'react-notifications-component';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function UpdateList() {
    const classes = useStyles();
    const [values, setValues] = useState({
        _id: "",
        arName: "",
        enName: "",
        products: [],
        order: "",
        active: null,
        showCurrentProducts: false,
        addMoreProducts: false,
        replaceCurrentProducts: false
    });
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();
    const [subcategories, setSubcategories] = useState([]);
    const [subcategory, setSubcategory] = useState();
    const [allProducts, setAllProducts] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [deletedItem, setDeletedItem] = useState({
        name: "",
        id: "",
        success: false
    });
    const [show, setShow] = useState();
    const [formValues, setFormValues] = useState({
        formError: null,
        redirectToReferrer: false,
        formData: ""
    });
    const [loading, setLoading] = useState(false);

    const { formData, redirectToReferrer, formError } = formValues;
    const { user, token } = isAuthenticated();
    const { _id, arName, enName, products, order, active, showCurrentProducts, addMoreProducts, replaceCurrentProducts } = values;

    const parsed = qs.parse(window.location.search);
    const listId = parsed.list_id;
    if (!listId) {
        window.location.href = default_url + "admin/lists";
    }

    const loadList = () => {
        getList(listId).then(res => {
            if (res.error) {
                window.location.href = default_url + "admin/lists";
            } else {
                if (!res) {
                    window.location.href = default_url + "admin/lists";
                }
                setValues({ _id: res._id, arName: res.arName, enName: res.enName, products: res.products, order: res.order, active: res.active });
            }
        })
    }

    const loadCategories = () => {
        listCategories().then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setCategories(res);
            }
        })
    }

    const loadSubcategories = (category) => {
        listSubcategoriesByCategory(category).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                if (res.length === 0) {
                    loadProducts({ category: category });
                }
                setSubcategories(res);
            }
        })
    }

    const loadProducts = (filters) => {
        listProductsByFilters(0, 10000000, filters).then(res => {
            if (res.error) {
                console.log(res.error);
            } else {
                setAllProducts(res.data);
            }
        })
    }

    useEffect(() => {
        loadList();
        loadCategories();
        setFormValues({ ...formValues, formData: new FormData() });
    }, [success]);

    const handleShow = (event) => {
        setShow(true);
        setDeletedItem({ name: event.target.name, id: event.target.id })
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleDelete = (event) => {
        event.preventDefault();
        handleClose();
        for (let i = 0; i < products.length; i++) {
            if (products[i]._id == deletedItem.id) {
                products.splice(i, 1);
            }
        }
    }

    const popUp = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{dict["deleting_product"]}</Modal.Title>
                </Modal.Header>
                {
                    default_url == "/en/" && <Modal.Body style={{ textAlign: "start" }}>{"Are you sure you want to delete " + deletedItem.name + " from " + enName + " list?"}</Modal.Body>
                }
                {
                    default_url == "/ar/" && <Modal.Body style={{ textAlign: "start" }}>{"هل انت متأكد تريد ازالة المنتج " + deletedItem.name + " من قائمة " + arName + "؟"}</Modal.Body>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {dict["close"]}
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>
                        {dict["delete"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const handleFormChange = name => event => {
        for (let i = 0; i < event.target.files.length; i++) {
            formData.set(`products`, event.target.files[i]);
        }
    };

    const handleChange = name => event => {
        if (name === "category") {
            let selectedCategory = event.target.value;
            if (selectedCategory) {
                loadSubcategories(selectedCategory);
            } else {
                setSubcategories([]);
            }
            setCategory(selectedCategory);
        } else if (name === "subcategory") {
            let selectedSubcategory = event.target.value;
            if (selectedSubcategory) {
                console.log(selectedSubcategory);
                loadProducts({ category: category, subcategory: selectedSubcategory });
            } else {
                setAllProducts([]);
            }
            setSubcategory(selectedSubcategory);
        } else if (name == "showCurrentProducts") {
            setValues({ ...values, showCurrentProducts: !showCurrentProducts, addMoreProducts: false, replaceCurrentProducts: false });
        } else if (name == "addMoreProducts") {
            setValues({ ...values, addMoreProducts: !addMoreProducts, showCurrentProducts: false, replaceCurrentProducts: false });
        } else if (name == "replaceCurrentProducts") {
            setValues({ ...values, replaceCurrentProducts: !replaceCurrentProducts, addMoreProducts: false, showCurrentProducts: false });
        } else {
            setValues({ ...values, [name]: event.target.value });
        }
        setError(null);
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let productsArr = [];
        for (let i = 0; i < products.length; i++) {
            productsArr.push(products[i]);
        }
        for (let i = 0; i < productsList.length; i++) {
            productsArr.push({ _id: productsList[i].product });
        }
        let list = { _id: _id, arName: arName, enName: enName, active: active, products: productsArr, order: order }
        updateList(list, user._id, token).then(res => {
            if (res.error) {
                setError(res.error);
                setSuccess(false);
                setLoading(false);
            } else {
                setError(null);
                setSuccess(true);
                setProductsList([]);
                setSubcategory(null);
                setCategory("");
                setSubcategories([]);
                setAllProducts([]);
                setValues({ ...values, addMoreProducts: false, showCurrentProducts: false, replaceCurrentProducts: false });
                setLoading(false);
            }
        })
    }


    const activeSelector = () => {
        return (
            <select onChange={handleChange("active")} className="inputField form-control" value={active}>
                <option value={null}></option>
                <option value={true}>{dict['yes']}</option>
                <option value={false}>{dict['no']}</option>
            </select>
        )
    }

    const showError = () => {
        return (
            <div className="alert alert-danger" style={{ display: error ? "" : "none", textAlign: 'start' }}>
                {error}
            </div>
        )
    }

    const showSuccess = () => {
        if (success) {
            return (
                <div className="alert alert-info" style={{ textAlign: 'start' }}>
                    {dict['list_has_been_edited']} <a href={default_url + "admin/lists"} style={{ fontSize: "18px" }}>{dict["go_back_to_lists"]}</a>
                </div>
            )
        }
    }

    const showBackdrop = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const showFields = () => {
        if (showCurrentProducts) {
            return (
                showProducts()
            )
        }
        if (addMoreProducts) {
            return addProducts()
        }
        if (replaceCurrentProducts) {
            return replaceProducts()
        }
    }

    const showProducts = () => {
        if (products && products.length) {
            return (
                products.map((item, index) => {
                    return (
                        <div key={index} className={"dataContainer " + (index === products.length - 1 ? "lastDataContainer" : "")}>
                            <span className="dataContainerNumber" style={{ float: default_url == "/en/" ? "left" : "right" }}>{index + 1}</span>
                            <span className="dataContainerTitle" style={{ float: default_url == "/en/" ? "left" : "right" }}>{item.itemCode}</span>
                            <a className="dataContainerAction"
                                href="#"
                                onClick={handleShow}
                                name={item.itemCode}
                                id={item._id}
                                style={{ float: default_url == "/en/" ? "right" : "left" }}
                            >{dict["delete"]}</a>
                            {popUp()}
                        </div>
                    )
                })
            )
        } else {
            return (
                <h1 style={{ textAlign: "start" }}>{dict["no_products_found"]}</h1>
            )
        }
    }

    const addProducts = () => {
        return addNewProductForm();
    }

    const addNewProductForm = () => {
        return (
            <form>
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["category"]} <span className="redStar">*</span></label><br />
                {categoriesSelector()}
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["subcategory"]} <span className="redStar">*</span></label><br />
                {subcategories.length === 0 ? disabledSubcategoriesSelector() : enabledSubcategoriesSelector()}
                {allProducts.length >= 0 && <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["products"]} <span className="redStar">*</span></label>}<br />
                {
                    allProducts.length > 0 &&
                    <ListCheckBox
                        products={allProducts}
                        setCheckedProducts={list => {
                            setCheckedProducts(list);
                        }}
                    />
                }
            </form>
        )
    }

    const setCheckedProducts = (list) => {
        let newList = [];
        list.map(i => {
            newList.push({ product: i });
        });
        setProductsList(newList);
    }

    const categoriesSelector = () => {
        return (
            <select onChange={handleChange("category")} className="inputField form-control" value={category}>
                <option value={null}></option>
                {
                    categories.sort((a, b) => a.position - b.position).map((category, index) => {
                        return (
                            <option key={index} value={category._id}>{category.enName + " | " + category.arName}</option>
                        )
                    })
                }
            </select>
        )
    }


    const disabledSubcategoriesSelector = () => {
        return (
            <select onChange={handleChange("subcategory")} className="inputField form-control" value={subcategory} disabled>
                <option value={null}></option>
                {
                    subcategories.sort((a, b) => a.position - b.position).map((subcategory, index) => {
                        return (
                            <option key={index} value={subcategory._id}>{default_url === "/en/" ? subcategory.enName : subcategory.arName ? subcategory.arName : subcategory.enName}</option>
                        )
                    })
                }
            </select>
        )
    }

    const enabledSubcategoriesSelector = () => {
        return (
            <select onChange={handleChange("subcategory")} className="inputField form-control" value={subcategory}>
                <option value={null}></option>
                {
                    subcategories.sort((a, b) => a.position - b.position).map((subcategory, index) => {
                        return (
                            <option key={index} value={subcategory._id}>{default_url === "/en/" ? subcategory.enName : subcategory.arName ? subcategory.arName : subcategory.enName}</option>
                        )
                    })
                }
            </select>
        )
    }

    const replaceProducts = () => {
        return (
            <form >
                {multipleProducts()}
                <button onClick={clickSubmitReplace} type="submit" className="dashboardBtn">{dict["replace"]}</button>
            </form>
        )
    }

    const multipleProducts = () => {
        return (
            <div className="multipleCategoriesForm">
                <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["excel_file"]} <span className="redStar">*</span></label>
                <input onChange={handleFormChange("excel")} type="file" multiple className="inputField form-control" name="excel" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
            </div>
        )
    }

    const clickSubmitReplace = (event) => {
        event.preventDefault();
        setLoading(true);
        replaceAllProductsInList(formData, listId ,user._id, token)
            .then(data => {
                setLoading(false);
                if (data.error) {
                    setFormValues({ ...formValues, redirectToReferrer: false });
                    setError(error);
                } else {
                    setSuccess(true)
                    setValues({...values, replaceCurrentProducts: false})
                    setFormValues({...formValues, formData: null})
                }
            })
    }

    const arStyleRequiredStart = {
        left: "30px"
    }
    const enStyleRequiredStart = {
        right: "30px"
    }
    const arStylelabel = {
        float: "right"
    }
    const enStylelabel = {
        float: "left"
    }

    const form = () => {
        return (
            <div className="userProfileDataContainer">
                {showBackdrop()}
                {showError()}
                {showSuccess()}
                <h3 className="formContainerTitle">{dict["update_list"]}</h3>
                <h6 className="requiredStar requiredStarUserDashbord" style={default_url == "/en/" ? enStyleRequiredStart : arStyleRequiredStart}>{dict["required"]}</h6>
                <form >
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["list_name_in_arabic"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("arName")} type="text" value={arName} className="inputField form-control" id="arName" placeholder={dict["list_name_in_arabic"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["list_name_in_english"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("enName")} type="text" value={enName} className="inputField form-control" id="enName" placeholder={dict["list_name_in_english"]}></input>
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["active"]} <span className="redStar">*</span></label><br />
                    {activeSelector()}
                    <label className="inputLabel" style={default_url == "/en/" ? enStylelabel : arStylelabel}>{dict["order"]} <span className="redStar">*</span></label>
                    <input onChange={handleChange("order")} type="number" value={order} className="inputField form-control" id="amount" placeholder={dict["order"]}></input>
                    <div className="form-check checkBoxUserDashboard">
                        <input onChange={handleChange("showCurrentProducts")} type="checkbox" checked={showCurrentProducts} className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["show_current_products"]}</label>
                    </div>
                    <div className="form-check checkBoxUserDashboard">
                        <input onChange={handleChange("addMoreProducts")} type="checkbox" checked={addMoreProducts} className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["add_more_products"]}</label>
                    </div>
                    <div className="form-check checkBoxUserDashboard">
                        <input onChange={handleChange("replaceCurrentProducts")} type="checkbox" checked={replaceCurrentProducts} className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" for="exampleCheck1">{dict["replace_current_products"]}</label>
                    </div>
                    <br />
                    {showFields()}
                    <button style={{display: replaceCurrentProducts ? "none" : ""}} onClick={clickSubmit} type="submit" className="dashboardBtn">{dict["save"]}</button>
                </form>
            </div>
        )
    }

    return (
        <AdminDashboard
            active="7"
            data={form}
        />
    )
}

export default UpdateList;