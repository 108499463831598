import React, { Fragment, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { default_url } from "./index"
import PrivateRoute from "./auth/PrivateRoute"
import AdminRoute from "./auth/AdminRoute"
import StorageRoute from "./auth/StorageRoute"
import Header from "./components/Header";
import MainBar from "./components/MainBar";
import Category from "./components/Category"
import Footer from "./components/Footer"
import SignIn from "./components/user/SignIn"
import SignUp from "./components/user/SignUp"
import AccountInformation from "./components/user/user dashboard compenents/AccountInformation"
import AdminInfo from "./components/admin/admin dashboard components/account info/AdminInfo"
import Products from "./components/Products"
import ProductView from "./components/ProductView";
import Activate from "./components/Activate"
import AddCategory from "./components/admin/admin dashboard components/catogery/AddCategory"
import Categories from "./components/admin/admin dashboard components/catogery/Categories";
import DeleteCategory from "./components/admin/admin dashboard components/catogery/DeleteCategory";
import UpdateCategory from "./components/admin/admin dashboard components/catogery/UpdateCategory";
import Subcategories from "./components/admin/admin dashboard components/subcategory/Subcategories";
import AddSubcategory from "./components/admin/admin dashboard components/subcategory/AddSubcategory";
import DeleteSubcategory from "./components/admin/admin dashboard components/subcategory/DeleteSubcategory";
import UpdateSubcategory from "./components/admin/admin dashboard components/subcategory/UpdateSubcategory";
import ProductsAdminView from "./components/admin/admin dashboard components/product/ProductsAdminView";
import AddProduct from "./components/admin/admin dashboard components/product/AddProduct";
import UpdateProduct from "./components/admin/admin dashboard components/product/UpdateProduct";
import DeleteProduct from "./components/admin/admin dashboard components/product/DeleteProduct";
import Cart from "./components/Cart";
import CheckOut from "./components/CheckOut";
import Result from "./Result";
import ThankYou from "./components/ThankYou";
import OrderAdmin from "./components/admin/admin dashboard components/order/OrderAdmin";
import NewArrivalAdmin from "./components/admin/admin dashboard components/new arrival/NewArrivalAdmin";
import AddToNewArrival from "./components/admin/admin dashboard components/new arrival/AddToNewArrival";
import OffersAdmin from "./components/admin/admin dashboard components/offers/OffersAdmin";
import AddToOffers from "./components/admin/admin dashboard components/offers/AddToOffers";
import AdsAdmin from "./components/admin/admin dashboard components/ads/AdsAdmin";
import AddNewAd from "./components/admin/admin dashboard components/ads/AddNewAd";
import CouponsAdmin from "./components/admin/admin dashboard components/coupons/CouponsAdmin";
import AddNewCoupon from "./components/admin/admin dashboard components/coupons/AddNewCoupon";
import UpdateCoupon from "./components/admin/admin dashboard components/coupons/UpdateCoupon";
import Compare from "./components/Compare";
import Wishlist from "./components/Wishlist";
import AddressBook from "./components/user/user dashboard compenents/AddressBook";
import AddNewAddress from "./components/user/user dashboard compenents/address book components/AddNewAddress";
import UpdateAddress from "./components/user/user dashboard compenents/address book components/UpdateAddress";
import UserOrders from "./components/user/user dashboard compenents/UserOrders";
import UserWishlist from "./components/user/user dashboard compenents/UserWishlist";
import UserReviews from "./components/user/user dashboard compenents/UserReviews";
import MoreSettings from "./components/admin/admin dashboard components/more settings/MoreSettings";
import Failed from "./components/Failed";
import UploadImages from "./components/admin/admin dashboard components/images/UploadImages";
import SearchProducts from "./components/SearchProducts";
import ForgetPassword from "./components/ForgetPassword";
import ResetPassword from "./components/ResetPassword";
import ViewUsers from "./components/admin/admin dashboard components/users/ViewUsers";
import GiftsAdmin from "./components/admin/admin dashboard components/gifts/GiftsAdmin"
import AddNewGift from "./components/admin/admin dashboard components/gifts/AddNewGift"
import StorageInfo from "./components/storage/storage dashboard components/StorageInfo";
import OrderStorage from "./components/storage/storage dashboard components/OrderStorage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Delivery from "./components/Delivery";
import ReturnAndExchange from "./components/ReturnAndExchange";
import Warranty from "./components/Warranty";
import UpdateManyProducts from "./components/admin/admin dashboard components/product/UpdateManyProducts";
import ReplaceNewArrivals from "./components/admin/admin dashboard components/new arrival/ReplceNewArrivals"
import UpdateGift from "./components/admin/admin dashboard components/gifts/UpdateGift";
import DeleteMultipleProducts from "./components/admin/admin dashboard components/product/DeleteMultipleProducts";
import ExportOrders from "./components/admin/admin dashboard components/order/ExportOrders";
import ReplaceOffers from "./components/admin/admin dashboard components/offers/ReplaceOffers";
import ListsAdmin from "./components/admin/admin dashboard components/lists/ListsAdmin";
import AddList from "./components/admin/admin dashboard components/lists/AddList";
import UpdateList from "./components/admin/admin dashboard components/lists/UpdateList";
import AnnouncementBar from "./components/AnnouncementBar";
import WholesaleAdmin from "./components/admin/admin dashboard components/wholesale/WholesaleAdmin";
import Wholesale from "./components/Wholesale";
import Feedback from "./components/Feedback";
import FeedbackAdmin from "./components/admin/admin dashboard components/feedback/FeedbackAdmin";
import ContactUs from "./components/ContactUs";
import DistributorsAdmin from "./components/admin/admin dashboard components/distributors/DistributorsAdmin";
import AddDistributor from "./components/admin/admin dashboard components/distributors/AddDistributor";
import UpdateDistributor from "./components/admin/admin dashboard components/distributors/UpdateDistributor";
import Distributors from "./components/Distributors";
import BlogAdmin from "./components/admin/admin dashboard components/blog/BlogAdmin";
import AddNewBlog from "./components/admin/admin dashboard components/blog/AddNewBlog";
import UpdateBlog from "./components/admin/admin dashboard components/blog/UpdateBlog";
import Blog from "./components/Blog";
import ColorsAdmin from "./components/admin/admin dashboard components/colors/ColorsAdmin";
import AddNewColor from "./components/admin/admin dashboard components/colors/AddNewColor";
import UpdateColor from "./components/admin/admin dashboard components/colors/UpdateColor";
import AddToCollections from "./components/admin/admin dashboard components/collections/AddToCollections";
import CollectionsAdmin from "./components/admin/admin dashboard components/collections/CollectionsAdmin";
import UpdateCollection from "./components/admin/admin dashboard components/collections/UpdateCollection";
import Collections from "./components/Collections";
import UserPoints from "./components/user/user dashboard compenents/UserPoints";
import Subcategory from "./components/Subcategory";
import List from "./components/List";
import ActivatePoints from "./components/ActivatePoints";
import ReplaceDistributors from "./components/admin/admin dashboard components/colors/ReplaceDistributors";
import Cities from "./components/admin/admin dashboard components/city/Cities";
import AddCity from "./components/admin/admin dashboard components/city/AddCity";
import UpdateCity from "./components/admin/admin dashboard components/city/UpdateCity";
import DeleteCity from "./components/admin/admin dashboard components/city/DeleteCity";
import Areas from "./components/admin/admin dashboard components/area/Areas";
import AddArea from "./components/admin/admin dashboard components/area/AddArea";
import UpdateArea from "./components/admin/admin dashboard components/area/UpdateArea";
import DeleteArea from "./components/admin/admin dashboard components/area/DeleteArea";
import CheckOut3 from "./components/CheckOut3";
import GiftTypes from "./components/admin/admin dashboard components/gift type/GiftTypes";
import AddGiftType from "./components/admin/admin dashboard components/gift type/AddGiftType";
import UpdateGiftType from "./components/admin/admin dashboard components/gift type/UpdateGiftType";
import DeleteGiftType from "./components/admin/admin dashboard components/gift type/DeleteGiftType";
import GiftItems from "./components/admin/admin dashboard components/gift item/GiftItems";
import AddGiftItem from "./components/admin/admin dashboard components/gift item/AddGiftItem";
import UpdateGiftItem from "./components/admin/admin dashboard components/gift item/UpdateGiftItem";
import DeleteGiftItem from "./components/admin/admin dashboard components/gift item/DeleteGiftItem";
import AboutUs from "./components/AboutUs";
import Home from "./components/Home";

function Routes() {

    return (
        <BrowserRouter>
            <ReactNotification />
            <AnnouncementBar />
            <Header />
            <MainBar />
            <Category />
            <Switch>
                <Route path={`${default_url}`} exact component={Home} />
                <Route path={`${default_url}signin`} exact component={SignIn} />
                <Route path={`${default_url}signup`} exact component={SignUp} />
                <Route path={`${default_url}forgetpassword`} exact component={ForgetPassword} />
                <Route path={`${default_url}resetpassword/:token`} exact component={ResetPassword} />
                {/*Private routes*/}
                <PrivateRoute path={`${default_url}user/account-information`} exact component={AccountInformation} />
                <PrivateRoute path={`${default_url}user/address-book`} exact component={AddressBook} />
                <PrivateRoute path={`${default_url}user/address-book/add`} exact component={AddNewAddress} />
                <PrivateRoute path={`${default_url}user/address-book/edit`} exact component={UpdateAddress} />
                <PrivateRoute path={`${default_url}user/orders`} exact component={UserOrders} />
                <PrivateRoute path={`${default_url}user/wishlist`} exact component={UserWishlist} />
                <PrivateRoute path={`${default_url}user/reviews`} exact component={UserReviews} />
                <PrivateRoute path={`${default_url}user/points`} exact component={UserPoints} />
                {/*Storage routes*/}
                <StorageRoute path={`${default_url}storage/account-information`} exact component={StorageInfo} />
                <StorageRoute path={`${default_url}storage/orders`} exact component={OrderStorage} />
                {/*Admin routes*/}
                <AdminRoute path={`${default_url}admin/account-information`} exact component={AdminInfo} />
                <AdminRoute path={`${default_url}admin/categories`} exact component={Categories} />
                <AdminRoute path={`${default_url}admin/categories/create`} exact component={AddCategory} />
                <AdminRoute path={`${default_url}admin/categories/edit`} exact component={UpdateCategory} />
                <AdminRoute path={`${default_url}admin/categories/delete`} exact component={DeleteCategory} />
                <AdminRoute path={`${default_url}admin/subcategories`} exact component={Subcategories} />
                <AdminRoute path={`${default_url}admin/subcategories/create`} exact component={AddSubcategory} />
                <AdminRoute path={`${default_url}admin/subcategories/delete`} exact component={DeleteSubcategory} />
                <AdminRoute path={`${default_url}admin/subcategories/edit`} exact component={UpdateSubcategory} />
                <AdminRoute path={`${default_url}admin/products`} exact component={ProductsAdminView} />
                <AdminRoute path={`${default_url}admin/products/create`} exact component={AddProduct} />
                <AdminRoute path={`${default_url}admin/products/update-many`} exact component={UpdateManyProducts} />
                <AdminRoute path={`${default_url}admin/products/delete-many`} exact component={DeleteMultipleProducts} />
                <AdminRoute path={`${default_url}admin/products/edit`} exact component={UpdateProduct} />
                <AdminRoute path={`${default_url}admin/products/delete`} exact component={DeleteProduct} />
                <AdminRoute path={`${default_url}admin/orders`} exact component={OrderAdmin} />
                <AdminRoute path={`${default_url}admin/orders/export`} exact component={ExportOrders} />
                <AdminRoute path={`${default_url}admin/new-arrival`} exact component={NewArrivalAdmin} />
                <AdminRoute path={`${default_url}admin/new-arrival/add`} exact component={AddToNewArrival} />
                <AdminRoute path={`${default_url}admin/new-arrival/replace`} exact component={ReplaceNewArrivals} />
                <AdminRoute path={`${default_url}admin/offers`} exact component={OffersAdmin} />
                <AdminRoute path={`${default_url}admin/offers/add`} exact component={AddToOffers} />
                <AdminRoute path={`${default_url}admin/offers/replace`} exact component={ReplaceOffers} />

                <AdminRoute path={`${default_url}admin/lists`} exact component={ListsAdmin} />
                <AdminRoute path={`${default_url}admin/lists/add`} exact component={AddList} />
                <AdminRoute path={`${default_url}admin/lists/edit`} exact component={UpdateList} />

                <AdminRoute path={`${default_url}admin/cities`} exact component={Cities} />
                <AdminRoute path={`${default_url}admin/cities/create`} exact component={AddCity} />
                <AdminRoute path={`${default_url}admin/cities/edit`} exact component={UpdateCity} />
                <AdminRoute path={`${default_url}admin/cities/delete`} exact component={DeleteCity} />

                <AdminRoute path={`${default_url}admin/areas`} exact component={Areas} />
                <AdminRoute path={`${default_url}admin/areas/create`} exact component={AddArea} />
                <AdminRoute path={`${default_url}admin/areas/edit`} exact component={UpdateArea} />
                <AdminRoute path={`${default_url}admin/areas/delete`} exact component={DeleteArea} />

                <AdminRoute path={`${default_url}admin/gift-types`} exact component={GiftTypes} />
                <AdminRoute path={`${default_url}admin/gift-types/create`} exact component={AddGiftType} />
                <AdminRoute path={`${default_url}admin/gift-types/edit`} exact component={UpdateGiftType} />
                <AdminRoute path={`${default_url}admin/gift-types/delete`} exact component={DeleteGiftType} />

                <AdminRoute path={`${default_url}admin/gift-items`} exact component={GiftItems} />
                <AdminRoute path={`${default_url}admin/gift-items/create`} exact component={AddGiftItem} />
                <AdminRoute path={`${default_url}admin/gift-items/edit`} exact component={UpdateGiftItem} />
                <AdminRoute path={`${default_url}admin/gift-items/delete`} exact component={DeleteGiftItem} />

                <AdminRoute path={`${default_url}admin/ads`} exact component={AdsAdmin} />
                <AdminRoute path={`${default_url}admin/ads/add`} exact component={AddNewAd} />
                <AdminRoute path={`${default_url}admin/coupons`} exact component={CouponsAdmin} />
                <AdminRoute path={`${default_url}admin/coupons/add`} exact component={AddNewCoupon} />
                <AdminRoute path={`${default_url}admin/coupons/edit`} exact component={UpdateCoupon} />
                <AdminRoute path={`${default_url}admin/upload-images`} exact component={UploadImages} />
                <AdminRoute path={`${default_url}admin/users`} exact component={ViewUsers} />
                <AdminRoute path={`${default_url}admin/gifts`} exact component={GiftsAdmin} />
                <AdminRoute path={`${default_url}admin/gifts/add`} exact component={AddNewGift} />
                <AdminRoute path={`${default_url}admin/gifts/edit`} exact component={UpdateGift} />
                <AdminRoute path={`${default_url}admin/wholesale`} exact component={WholesaleAdmin} />
                <AdminRoute path={`${default_url}admin/feedbacks`} exact component={FeedbackAdmin} />
                <AdminRoute path={`${default_url}admin/distributors`} exact component={DistributorsAdmin} />
                <AdminRoute path={`${default_url}admin/distributors/add`} exact component={AddDistributor} />
                <AdminRoute path={`${default_url}admin/distributors/replace`} exact component={ReplaceDistributors} />
                {/* <AdminRoute path={`${default_url}admin/distributors/edit`} exact component={UpdateDistributor} /> */}
                <AdminRoute path={`${default_url}admin/blog`} exact component={BlogAdmin} />
                <AdminRoute path={`${default_url}admin/blog/add`} exact component={AddNewBlog} />
                <AdminRoute path={`${default_url}admin/blog/edit`} exact component={UpdateBlog} />
                <AdminRoute path={`${default_url}admin/colors`} exact component={ColorsAdmin} />
                <AdminRoute path={`${default_url}admin/colors/add`} exact component={AddNewColor} />
                <AdminRoute path={`${default_url}admin/colors/edit`} exact component={UpdateColor} />
                <AdminRoute path={`${default_url}admin/collections`} exact component={CollectionsAdmin} />
                <AdminRoute path={`${default_url}admin/collection/add`} exact component={AddToCollections} />
                <AdminRoute path={`${default_url}admin/collections/edit`} exact component={UpdateCollection} />
                <AdminRoute path={`${default_url}admin/more-settings`} exact component={MoreSettings} />
                {/**/}
                <Route path={`${default_url}:category/subcategories`} exact component={Subcategory} />
                <Route path={`${default_url}products/:category`} exact component={Products} />
                <Route path={`${default_url}products/:category/:subcategory`} exact component={Products} />
                <Route path={`${default_url}products/:category/:subcategory/:product`} exact component={ProductView} />
                <Route path={`${default_url}show-list/:listId`} exact component={List} />
                <Route path={`${default_url}users/activate/:userId/:token`} exact component={Activate} />
                <Route path={`${default_url}search/products`} exact component={SearchProducts} />
                <Route path={`${default_url}cart`} exact component={Cart} />
                <Route path={`${default_url}checkout`} exact component={CheckOut} />
                {/* <Route path={`${default_url}checkout3`} exact component={CheckOut3} /> */}
                <Route path={`${default_url}result`} exact component={Result} />
                <Route path={`${default_url}thankyou`} exact component={ThankYou} />
                <Route path={`${default_url}failed`} exact component={Failed} />
                <Route path={`${default_url}compare`} exact component={Compare} />
                <Route path={`${default_url}wishlist`} exact component={Wishlist} />
                <Route path={`${default_url}wholesale`} exact component={Wholesale} />
                <Route path={`${default_url}feedback`} exact component={Feedback} />
                <Route path={`${default_url}points`} exact component={ActivatePoints} />
                <Route path={`${default_url}contact-us`} exact component={ContactUs} />
                <Route path={`${default_url}distributors`} exact component={Distributors} />
                <Route path={`${default_url}blog`} exact component={Blog} />
                <Route path={`${default_url}collections`} exact component={Collections} />
                <Route path={`${default_url}privacy-policy`} exact component={PrivacyPolicy} />
                <Route path={`${default_url}terms-and-conditions`} exact component={TermsAndConditions} />
                <Route path={`${default_url}delivery`} exact component={Delivery} />
                <Route path={`${default_url}return-and-exchange`} exact component={ReturnAndExchange} />
                <Route path={`${default_url}warranty`} exact component={Warranty} />
                <Route path={`${default_url}about-us`} exact component={AboutUs} />
                <Route path={`${default_url}*`} render={() => <h1>Not Found!</h1>} />
            </Switch>
            <Footer />
        </BrowserRouter>
    )
}

export default Routes;

